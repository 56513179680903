import { useState } from "react";
import { useQuery, useMutation, gql, useReactiveVar } from '@apollo/client';
import { Button, Popover, Avatar, Space, Card, Modal, Row, Col, Tooltip, Badge, Drawer, Progress, Statistic, Switch, message, Tag, Divider } from 'antd';
import { InfoOutlined, TeamOutlined, UserOutlined, QuestionOutlined, HistoryOutlined,BulbOutlined } from '@ant-design/icons';
import client from '../Apollo'
import { useNavigate } from 'react-router-dom';
import { sections, documentation } from "./Documentation";
import moment from 'moment';
import TeamsModal from "./teams/TeamsModal";
import AnalysisCard from "./analysis/AnalysisCard";
import CacheProgress from "./CacheProgress";
import ProcessesProgress from "./ProcessesProgress";
import { processesLoading, processesLoadingProgress, processesClient, preloadingActive } from "../Apollo";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { createOptimisticResponse } from "../components/createOptimisticResponse";
import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';

//import { pollInterval } from "..";

import { CORE_USER_PROFILE_FIELDS, CORE_BACKGROUND_TASK_FIELDS } from "./fragments";

//import { json } from "stream/consumers";

const GET_USER_PROFILE = gql`
    ${CORE_USER_PROFILE_FIELDS}
    query userProfile {  
        userProfile {
            ...CoreUserProfileFields
        }  
    }
`;

const GET_BACKGROUND_TASKS = gql`
    ${CORE_BACKGROUND_TASK_FIELDS}
    query backgroundTasks {
        backgroundTasks {
            ...CoreBackgroundTaskFields
        }
    }
`;


const UPDATE_USER_PROFILE = gql`
    ${CORE_USER_PROFILE_FIELDS}
    mutation updateUserProfile($input: UpdateUserProfileInputType!) {
        updateUserProfile( input:$input) {
            userProfile {
                ...CoreUserProfileFields
            }
        }
    }
`;


interface HeaderTableInputType {
    helpContent?: any
    readonly?: boolean
}

const defaultProps: HeaderTableInputType = {
    helpContent: <div>Help content is not available for this page.</div>,
    readonly: false
}
function HeaderTable(props: HeaderTableInputType) {

    const [helpModalVisible, setHelpModalVisible] = useState(false)
    const [analysisModalVisible, setAnalysisModalVisible] = useState(false)
    const [aboutPopupVisible, setAboutPopupVisible] = useState(false)
    const [teamsPopupVisible, setTeamsPopupVisible] = useState(false)
    const [selectedDocumentId, setSelectedDocumentId] = useState(0)
    const [backgroundTaskVisible, setBackgroundTaskVisible] = useState(false)
    const [outputMessageVisible, setOutputMessageVisible] = useState(false)
    const [outputMessageContent, setOutputMessageContent] = useState("")
    const [outputParameters, setOutputParameters] = useState("")
    const [localDataStorageModalVisible, setLocalDataStorageModalVisible] = useState(false) 

    const processesLoadingValue = useReactiveVar(processesLoading);
    const processesLoadingProgressValue = useReactiveVar(processesLoadingProgress);
    const processesClientValue = useReactiveVar(processesClient);
    const preloadingActiveValue = useReactiveVar(preloadingActive);

    let outputMessageContent_split = outputMessageContent.split("\n");

    // remove empty strings
    outputMessageContent_split = outputMessageContent_split.filter(function (el) {
        return el != "";
    });

    const navigate = useNavigate()

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_USER_PROFILE, 
        { 
            fetchPolicy: 'cache-and-network',
        }
    );

    // refetch the background tasks every 5 seconds if backgroundTaskVisible is true
    const { loading: queryLoading2, error: queryError2, data: queryData2 } = useQuery(GET_BACKGROUND_TASKS , 
        { 
            pollInterval: backgroundTaskVisible ? 1000 : 10000 
        }
    );

    //const { loading: queryLoading2, error: queryError2, data: queryData2 } = useSubscription(BACKGROUND_TASKS_SUBSCRIPTION);

    const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
        //onCompleted(data) { console.log(data) },
        refetchQueries: [{ query: GET_USER_PROFILE }],
        onError(error) { message.error(error.message) },
    });

    let icon: any = (<UserOutlined />)

    // replace the placeholder with the user avatar if available
    if (!queryLoading && !queryError && queryData.userProfile.avatarB64 != "") {
        const image_src = 'data:image/png;base64,' + queryData.userProfile.avatarB64;
        icon = (<img src={image_src} />)
    }

    if (queryLoading && !queryData) {
        return <div></div>
    }

    const onLogout = (values: any) => {
        
        // perform the logout mutation
        client.clearStore()
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')
        navigate('/')
        //logout()
                
    }

    const onProfile = (values: any) => {
        navigate('/profile')
    }

    const onSettings = (values: any) => {
        navigate('/settings')
    }

    const showHelpModal = () => {
        setHelpModalVisible(true);
    };

    const onClose = () => {
        setHelpModalVisible(false);
    };

    const showBackgroundTask = () => {
        setBackgroundTaskVisible(true);
    }

    const showAboutPopup = () => {
        setAboutPopupVisible(true);
    }

    const showTeamsPopup = () => {
        setTeamsPopupVisible(true);
    }

    function onChangeLightDarkMode(checked: boolean) {

        let input = {
            darkMode: checked
        }

        const optimisticResponse = createOptimisticResponse(
            client, 
            CORE_USER_PROFILE_FIELDS,
            queryData?.userProfile.id,
            { darkMode: checked },
            'CoreUserProfileFields'
        );

        updateUserProfile({ variables: { input: input },
            optimisticResponse: {
                __typename: "Mutation",
                updateUserProfile: {
                    __typename: "UpdateUserProfilePayload",
                    userProfile: {
                        __typename: "UserProfileType",
                        ...optimisticResponse
                    }
                }
            }
        });
    }

    let teamName = "No team selected"
    if (queryData?.userProfile?.selectedTeam?.name) {
        teamName = queryData?.userProfile?.selectedTeam?.name
    }

    const popoverContent = (
        <Card style={{ minWidth: 150 }} >
            <Space direction="vertical">
                <Avatar size={100} icon={icon}></Avatar>
                <div>User: {sessionStorage.getItem('username')}</div>
                {/* add the light / dark mode switch here */}

                <br />
                <div>Dark mode:</div>
                <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={queryData?.userProfile.darkMode}
                    onChange={onChangeLightDarkMode}
                    data-testid="darkModeSwitch"
                />

                <br />
                <a onClick={onProfile} data-testid="profileLink">Profile</a>
                {/*<a onClick={onSettings}>Settings</a>*/}
                <a onClick={onLogout} data-testid="logoutLink">Logout</a>

            </Space>
        </Card>
    );

    const isTeamReadOnly = queryData?.userProfile?.selectedTeam?.userPermissions === "read"
    let isAnalysisReadOnly = queryData?.userProfile?.selectedAnalysis?.userPermissions === "read"


    return (

        <div key="1">

            <Space>

                <div onClick={showTeamsPopup} style={{ cursor: 'pointer' }} key="1">
                    
                        { isTeamReadOnly ? 
                            <div>
                                
                                <Tooltip title="Teams">
                                    <Button type="default" icon={<TeamOutlined />} id="teamsButton" data-testid="teamsButton">Team: {teamName}</Button> 
                                </Tooltip>
                                <Tag color="orange" style={{height:30, paddingTop:5, paddingBottom:5, marginLeft:2}} title="You have read-only access to this team.">Read-only</Tag>
                                
                                    
                            </div>
                            : 
                            <Tooltip title="Teams">
                                <Button type="default" icon={<TeamOutlined />} id="teamsButton" data-testid="teamsButton">Team: {teamName}</Button> 
                            </Tooltip>
                            }
                    
                </div>

                <div onClick={() => setAnalysisModalVisible(true)} style={{ cursor: 'pointer' }} key="2">
                    
                    { isAnalysisReadOnly ? 
                        <div>
                            
                            <Tooltip title="Analyses">
                                <Button type="default" icon={<BulbOutlined /> } id="analysisButton" data-testid="analysisButton">Analysis: {queryData?.userProfile?.selectedAnalysis?.name}</Button>
                            </Tooltip>
                            <Tag color="orange" style={{height:30, paddingTop:5, paddingBottom:5, marginLeft:2}} title="You have read-only access to this analysis.">Read-only</Tag>
                        </div>
                        :

                        <Tooltip title="Analyses">
                            <Button type="default" icon={<BulbOutlined /> } id="analysisButton" data-testid="analysisButton">Analysis: {queryData?.userProfile?.selectedAnalysis?.name}</Button>
                        </Tooltip>
                    }

                </div>

                <div key="103" onClick={() => setLocalDataStorageModalVisible(true)} style={{ cursor: 'pointer' }}>
                    
                    {(processesLoadingValue) && (
                        <div>
                            <Tooltip title={'Loading processes for analysis'}>
                                <Progress type="circle" size={29} percent={Math.round(processesLoadingProgressValue)} status="active" />
                            </Tooltip>
                        </div>
                    )}

                    { (!processesLoadingValue && !preloadingActiveValue) && (
                        <div data-testid="processes-loaded-status">
                            <Tooltip title={'Processes loaded for analysis: ' + processesClientValue.length}>
                                <Progress type="circle" size={25} percent={100} status="success" />
                            </Tooltip>
                        </div>
                    )}

                    { (preloadingActiveValue && !processesLoadingValue) && (
                        <div>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin /> } />
                        </div>
                    )}
                    
                </div>

                {/* show a help text when mouse goes over it */}
                <div onClick={showBackgroundTask} style={{ cursor: 'pointer' }} data-testid="backgroundTasksButton" key="3">
                    <Tooltip title="Background Tasks">
                        <Badge count={queryData2 && queryData2.backgroundTasks.filter((task: any) => task.status === "RUNNING").length} >
                            <Button shape="circle" icon={<HistoryOutlined />} data-testid="backgroundTasksButton" />
                        </Badge>

                    </Tooltip>

                </div>

                <div onClick={showHelpModal} style={{ cursor: 'pointer' }} data-testid="helpButton" key="4">
                    <Tooltip title="Documentation">
                        <Button shape="circle" icon={<QuestionOutlined onClick={showHelpModal} />} data-testid="helpButton" />
                    </Tooltip>
                </div>

                <div onClick={showAboutPopup} style={{ cursor: 'pointer' }} data-testid="aboutButton" key="5">
                    <Tooltip title="About">
                        <Button shape="circle" icon={<InfoOutlined />} />
                    </Tooltip>
                </div>

                <div key="6">
                    <Popover placement="bottomLeft" content={popoverContent} >
                        <Button type="primary" shape="circle" icon={<UserOutlined />} data-testid="userButton" />
                    </Popover>
                </div>

            </Space>

            {/* <Dropdown overlay=""><Button type="primary" shape="circle" icon={<UserOutlined />} /></Dropdown>*/}

            <Modal
                title="Documentation"
                open={helpModalVisible}
                width={"80%"}
                onCancel={onClose}
                footer={null}
            >
                <div>

                    <Row>

                        <Col span={6} style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>

                            { /* loop through the sections and for each section list the articles from documentation */}
                            {sections.map((section: any, index: any) => (
                                <div key={index}>
                                    <h3>{section.title}</h3>
                                    <ul>
                                        {/* sort the documents based on id in increasing order */}
                                        {documentation.filter((article: any) => article.sectionId === section.sectionId).sort((a: any, b: any) => a.id - b.id).map((article: any, index: any) => (
                                            
                                                <div>
                                                { article.subsection != null && article.subsection == true && 
                                                    <div style={{marginLeft:20 }}>
                                                        <Button type="link" onClick={() => setSelectedDocumentId(article.id)} data-testid={"articleButton" + article.title}>{article.title}</Button>
                                                    </div>
                                                }

                                                { (article.subsection == null || article.subsection == false) && 
                                                    <li key={index}>
                                                        <div style={{marginLeft:0}}>
                                                            <Button type="link" onClick={() => setSelectedDocumentId(article.id)} data-testid={"articleButton" + article.title}>{article.title}</Button>
                                                        </div>
                                                    </li>
                                                }
                                                </div>


                                                
                                        ))}
                                    </ul>
                                </div>
                            ))}

                        </Col>

                        <Col span={18} style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>

                            <div style={{ marginRight: 20, marginLeft: 20 }}>

                                { /* display the selected article */}
                                {documentation.filter((article: any) => article.id === selectedDocumentId).map((article: any, index: any) => (
                                    <div key={index} >
                                        <h1>{article.title}</h1>
                                        <div>{article.content}</div>
                                    </div>
                                ))}

                            </div>

                        </Col>


                    </Row>



                </div>

                {/* props.helpContent */}

            </Modal>

            <Drawer
                title="Background Tasks"
                placement="right"
                closable={true}
                onClose={() => setBackgroundTaskVisible(false)}
                open={backgroundTaskVisible}
                key="1"
            >
                <div>

                    { /* display the background tasks */}
                    {queryData2 && queryData2.backgroundTasks.map((task: any, index: any) => (
                        <div key={index} >
                            <Card style={{ marginBottom: 5 }}>
                                <h4>{task.taskName}</h4>
                                { (task.progress != 0 && (task.status == "RUNNING" || task.status == "FAILED" || task.status == "COMPLETED")) &&
                                    <Progress percent={task.progress} status={task.status === "FAILED" ? "exception" : task.status === "COMPLETED" ? "success" : "active"} />
                                }

                                { (task.progress != 0 && task.status == "WARNING") &&
                                    <div>
                                        <Progress percent={task.progress} status="success" strokeColor={{ '0%': 'orange', '100%': 'orange' }} trailColor="orange" /> 
                                        <Tag color="orange" style={{marginTop:10, marginBottom:10}} title="Background task completed with warnings">Warning</Tag>
                                    </div>
                                }

                                
                                { (task.progress == 0 && task.status == "RUNNING") &&
                                    <Spin size="small" style={{marginBottom:10}}/>
                                }

                                

                                {/* show the status */}

                                {/* show the statusMessage if available */}
                                {task.statusMessage && <div style={{ fontSize: 10, color: 'grey' }}>{task.statusMessage}</div>}

                                {/* show the start time and convert from UTC to local timezone - use this format: 03-Dec-2020 10:57:01*/}
                                <div style={{ fontSize: 10, color: 'grey' }}>Started: {moment.utc(task.creationDate).local().format('DD-MMM-YYYY HH:mm:ss')}</div>

                                {/* run duration in X minutes and Y seconds: task.runDuration is in seconds */}
                                <div style={{ fontSize: 10, color: 'grey' }}>Duration: {Math.floor(task.runDuration / 60)} minutes and {Math.round(task.runDuration % 60)} seconds</div>

                                {/* if outputMessage is available, show a button to display it in a modal */}
                                {task.outputMessage && <Button type="link" style={{ fontSize: 10, marginLeft: -15 }} onClick={() => { setOutputMessageVisible(true); setOutputMessageContent(task.outputMessage); setOutputParameters(task.outputParameters) }} data-testid={"outputMessageButton" + task.taskName}>Output message</Button>}

                            </Card>
                        </div>
                    ))}


                </div>
            </Drawer>

            <Modal
                title="Output:"
                open={outputMessageVisible}
                width={"70%"}
                onCancel={() => setOutputMessageVisible(false)}
                footer={null}
            >

                <div style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>

                    {/* display each outputParamets as a Statistic element - outPutParameters is an object */}


                    {outputParameters && Object.values(JSON.parse(outputParameters)).map((parameter: any, index: any) => (
                        <Card style={{ marginBottom: 10 }} key={index}>
                            <Statistic title={parameter.name} value={parameter.value} key={index} suffix={parameter.unit} />
                        </Card>

                    ))}
                    { }

                    <Card title="Messages:">
                        {outputMessageContent_split.map((item, i) => (
                            <div key={i}>
                                <p>{item}</p>
                            </div>
                        ))}
                    </Card>
                </div>

            </Modal>

            <Modal
                title="About"
                open={aboutPopupVisible}
                onCancel={() => setAboutPopupVisible(false)}
                footer={null}
            >
                <div>
                    <p></p>
                    <p>Bioprocess Intelligence</p>
                    <p>Version 2.1.11</p>
                    {/*<p>&copy; 2024 WisdomEngine GmbH</p>*/}
                </div>
            </Modal>

            <TeamsModal isOpen={teamsPopupVisible} setIsOpen={setTeamsPopupVisible} />

            <Modal
                title="Analysis"
                open={analysisModalVisible}
                onCancel={() => setAnalysisModalVisible(false)}
                footer={null}
                width={"80%"}
                destroyOnClose={true}
                
            >
                <div style={{ height: 'calc(100vh - 400px)', overflowY: 'auto' }}>
                    <AnalysisCard />
                </div>
            </Modal>

            <Modal
                title="Local data storage"
                open = {localDataStorageModalVisible}
                onCancel = {() => setLocalDataStorageModalVisible(false)}
                footer = {null}
            >
                <div>
                    {(processesLoadingValue && !preloadingActiveValue) && (
                        <div>
                            <p>Loading analysis processes...</p>
                            <Progress percent={Math.round(processesLoadingProgressValue)} status="active" />
                            <Divider />
                        </div>
                    )}
                </div>

                <div key="101">
                    <ProcessesProgress />
                    <Divider />
                </div>

                <div key="102">
                    <CacheProgress />
                </div>


            </Modal>



        </div>




    )

}

HeaderTable.defaultProps = defaultProps

export default HeaderTable



