import { Card, Collapse, Steps} from 'antd';
import PageHeader from '../components/PageHeader';
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from "../components/useWindowDimensions";
import ReactGA from 'react-ga';


const { Panel } = Collapse;
const { Meta } = Card;
const { Step } = Steps;

function Report() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/report/');

    const { height, width } = useWindowDimensions();

    return(  
      <div>

        <PageHeader
          title="Reports"
          extra={<HeaderExtra/>}
        />

        <Card style={{height:height-130, overflow:"auto"}}>

        </Card>


          
      </div>
    )
  }
  
  export default Report;
