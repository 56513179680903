import React, {useState, useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Card , Select, Button, Form, Spin, Modal, message, Radio} from 'antd';
const {Option} = Select;

const COPY_KPI = gql`
    mutation copyKPI ($input:CopyKPIInputType!) {  
        copyKPI(input:$input) {
            analysis {  
                id
                name
                kpiSet {
                    id
                    name
                    x
                    y
                    f
                    units
                }
            }
        }
    }
`;

const GET_KPIS= gql`
  query KPIs ($analysisId:ID!) {
    KPIs (analysisId:$analysisId) {
      id
      name
      x
      y
      f
      units
    }
    userProfile {
        selectedTeam {
            id
        }
    }
  }
`

const ALL_ANALYSES = gql`
query analyses ($teamId: ID!) {
    analyses (teamId:$teamId) {
        id
        name
        team {
            id
            name
        }
        owner {
            id
            username
        }
        kpiSet {
            id
            name
            description
            x
            y
            f
            units
        }
    }
}`;

const GET_KPI_TABLE = gql`
    query kpiTable ($input:KpiTableInputType!) {  
        kpiTable(input:$input) {
            values
            columns
            columnIds
            columnUnits
            index
            json
        }
    }
`;

interface ImportKPIsInputType {
    analysis: any
    selectedTeamId: string
    visible: boolean
    setVisible: any
};

// default props
ImportKPIs.defaultProps = {
    selectedTeamId: "",
    visible: false,
};

function ImportKPIs(props:ImportKPIsInputType) {

    const [isSelectAll, setIsSelectAll] = useState(true);
    const [sourceAnalysisId, setSourceAnalysisId] = useState("");
    const [selectedKPIIds, setSelectedKPIIds] = useState([] as any);
    const [KPISet, setKPISet] = useState([] as any);

    // loop over analysis.processes and create an array with the ids
    let processIds:any = []
    props.analysis.processes.forEach((process: any) => {
        processIds.push(process.id)
    })

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery( ALL_ANALYSES, {variables:{'teamId':props.selectedTeamId }});
    
    const [copyMutation] = useMutation(COPY_KPI, {
        refetchQueries: [ 
            { query: GET_KPIS, variables: { 'analysisId': props.analysis.id } },
            { query: GET_KPI_TABLE, variables: { 'input': { 'analysisId':props.analysis.id, 'processIds': processIds } } },
        ],
        onCompleted(data) { message.success( "KPIs were imported successfully.") } ,
        onError(error) {message.error(error.message)}, 
    });


    // useEffect to set the KPISet when the queryData changes
    useEffect(() => {
        if (queryData) {
            // find the source analysis
            let sourceAnalysis = queryData.analyses.find((analysis:any) => analysis.id == sourceAnalysisId);
            setKPISet(sourceAnalysis?.kpiSet);
        }
    }, [queryData, sourceAnalysisId]);
 
    const formLayout = {
        labelCol: { span: 6 },
    };

    function handleCancel() {
        props.setVisible(false);
    }

    function onChangeIsSelectAll(e:any) {
        setIsSelectAll(e.target.value);
    }

    function handleAnalysisChange(value:any) {
        setSourceAnalysisId(value);
        setSelectedKPIIds([]);
    }

    // copy the data standards
    function handleOk() {

        // if select all is true, then set the selectedKPIIds to all the KPI ids
        let input = {}

        if (isSelectAll == true) {
            input = {
                targetAnalysisId: props.analysis.id,
                kpiIds: KPISet.map((kpi:any) => kpi.id),    
            }
        }

        // if select all is false, then set the selectedKPIIds to the selectedKPIIds
        if (isSelectAll == false) {
            input = {
                targetAnalysisId: props.analysis.id,
                kpiIds: selectedKPIIds,
            }
        }
        

        // perofrm the mutation
        copyMutation({ variables: { input: input } });
        
        // close the modal
        props.setVisible(false);
    }

    if (queryError) return ( 
        <Card>Error: {queryError.message} </Card>
    );

    if (queryLoading) return (
        <div></div>
    );

    // create the options for the analysis select
    // each analysis is an option. the team of each analysis is the label
    
    let analysisOptions:any = [];

    // get the teams
    let teams:any = [];
    for (let i=0; i<queryData.analyses.length; i++) {
        // if teams is defined
        if (queryData.analyses[i].team != null) {
            if (!teams.includes(queryData.analyses[i].team.name)) {
                teams.push(queryData.analyses[i].team.name);
            }
        }
    }

    // create a Personal team option
    teams.push("Personal");

    for (let i=0; i<teams.length; i++) {
        // get the analyses for each team
        let analyses:any = [];
        for (let j=0; j<queryData.analyses.length; j++) {
            // if the team is Personal, then only add the analyses that do not have a team
            if (teams[i] == "Personal") {
                if (queryData.analyses[j].team == null) {
                    // if the analysis is not the target analysis, add it to the options
                    if (queryData.analyses[j].id != props.analysis.id) {
                        analyses.push(queryData.analyses[j]);
                    }
                }
            }

            // if the team is not Personal, then only add the analyses that have the team
            if (teams[i] != "Personal") {
                if (queryData.analyses[j].team != null) {
                    // if the analysis is not the target analysis, add it to the options
                    if (queryData.analyses[j].id != props.analysis.id) {
                        analyses.push(queryData.analyses[j]);
                    }
                }
            }
            
        }

        // create the options for each team
        let teamOptions:any = [];
        for (let j=0; j<analyses.length; j++) {
            teamOptions.push({ label: analyses[j].name, value: analyses[j].id });
        }
        analysisOptions.push({ label: 'Team: ' + teams[i], options: teamOptions });
    }

    
    return (
        <Modal 
            title= "Import KPIs"
            open={ props.visible } 
            onOk = { handleOk }
            onCancel = { handleCancel }
            data-testid="importKPIsModal"
            destroyOnClose = {true}

            footer={[
                <Button type="primary" form="importKPIsForm" key="submit" htmlType="submit" onClick={handleOk} data-testid="submitImportKPIsModal">
                    Submit
                </Button>
                ]}
            >
            <br/>
            <Form
                {...formLayout}  
                id = "importKPIsForm"
            > 

                <Form.Item label="Analysis:" name="analysis" rules={[{ required: true}]} >
                    <Select
                        showSearch
                        onChange={handleAnalysisChange}
                        value={sourceAnalysisId || undefined}
                        data-testid="analysisSelect"
                        placeholder="Select an analysis"
                        optionLabelProp="label"
                        options = {analysisOptions}
                        filterOption={(input, option:any) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                        

                </Form.Item>
                

                <Form.Item label="Import:" required >
                    <Radio.Group value={isSelectAll} onChange={onChangeIsSelectAll} defaultValue={true} data-testid="importRadioGroup">
                        <Radio value={true} data-testid="allEntries">All KPIs</Radio>
                        <Radio value={false} data-testid="selectedEntries">Selected KPIs</Radio>
                    </Radio.Group>
                </Form.Item>
                

                {isSelectAll == false && 
                    <Form.Item label="KPIs:" required >
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select KPIs"
                            value={selectedKPIIds || undefined}
                            onChange={value => setSelectedKPIIds(value)}
                            // empty the selected data standards when the selectedDataStandardIds is empty
                            onClear={() => setSelectedKPIIds([])}
                            data-testid="KPISelect"
                            optionLabelProp="label"
                            showSearch
                            optionFilterProp='children'

                        >
                            {KPISet?.map((kpi:any) => (
                                <Option key={kpi.id} value={kpi.id} label={kpi.name} title={kpi.name} data-testid={"KPIOption-" + kpi.name}>
                                    { kpi.units != null &&
                                        kpi.name + " [" + kpi.units + "]" 
                                    }
                                    { kpi.units == null &&
                                        kpi.name 
                                    }

                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                }
            </Form>
        </Modal>
    )


}

export default ImportKPIs;
