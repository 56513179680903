import { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Collapse, Button, Space, Switch, InputNumber, Card, Spin, message, Select, Form , Input, Modal} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import SelectKPIs from './SelectKPIs';
import SelectProcesses from './SelectProcesses';
//import SelectKPIset from './SelectKPIset';
import { pollInterval } from '../..';

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;



const GET_STAT_MODEL = gql`
    query statModel ($id:ID!) {  
        statModel(id:$id) {
            id
            name
            description
            modelfunction
            analysis {
                id
                userPermissions
            }
            processes {
                id
                name
            }
            analysis {
                id
                name
                processes {
                    id
                    name
                }
                kpiSet {
                    id
                    name
                }
            }

            normalization
            
            mlrCoefficients
            mlrCoefficientsNames
            mlrCoefficientsErrors
            mlrResultsSummary
            mlrScore
            yPred
            yPredNames
            y
            yName

            pcaComponents
            pcaScores
            pcaLoadings
            pcaScoresNames
            pcaLoadingsNames
            pcaVarianceRatio
            xNames
            
        }
    }
`;

const UPDATE_STAT_MODEL = gql`
    mutation updateStatModel($input: UpdateStatModelInputType!) {
        updateStatModel( input:$input) {
            recomputed
            statModel {
                id
                name
                description
                processes {
                    id
                    name
                }
                analysis {
                    id
                    name
                    processes {
                        id
                        name
                    }
                    kpiSet {
                        id
                        name
                    }
                }
                normalization

                mlrCoefficients
                mlrCoefficientsNames
                mlrCoefficientsErrors
                mlrScore
                mlrResultsSummary
                yPred
                yPredNames
                y
                yName

                pcaComponents
                pcaScores
                pcaLoadings
                pcaScoresNames
                pcaLoadingsNames
                pcaVarianceRatio
                xNames
                
            }
        }
    }
`;

interface ModelMenuInputType {
    modelId: string
    recompute: Function
    axes: any
    setAxes: Function
}




function ModelMenu(props: ModelMenuInputType) {

    const [recomputeNeeded, setRecomputeNeeded] = useState(false);
    const [normalization, setNormalization] = useState(false);
    const [pcaComponents, setPcaComponents] = useState(2);
    const [selectedKPIs, setSelectedKPIs] = useState([]);
    //const [selectedProcesses, setSelectedProcesses] = useState([]);
    const [selectedY, setSelectedY] = useState("");
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"" } );
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [modalId, setModalId] = useState("");
    const [kpiList, setKpiList] = useState([]); // list of available KPIs / features

    const { loading: queryLoading, error: queryError, data } = useQuery(GET_STAT_MODEL, { variables: { id: props.modelId } , 
        //pollInterval:pollInterval 
    })

    const [updateStatModel, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(UPDATE_STAT_MODEL, {
        refetchQueries: [ { query: GET_STAT_MODEL ,  variables: { id: props.modelId } } ], 
        onCompleted(data) { 
            // if recomputed is true, then show the message
            if (data.updateStatModel.recomputed) {
                message.success("Model was computed successfully.")
            }
        },
        onError(error) { message.error(error.message) },
    });

    // set initial values using useEffect
    useEffect(() => {
        if (data) {
            setNormalization(data.statModel.normalization);
            setPcaComponents(data.statModel.pcaComponents);
            setSelectedKPIs(data.statModel.xNames);

            // get the ids of data.processes in an array
            // let processIds = data.statModel.processes.map((process: any) => process.id);
            // setSelectedProcesses(processIds);
            setSelectedY(data.statModel.yName);
        }
    }
    , [data]);

    // create the list of availaible KPIs / features
    useEffect(() => {

        if (!data) {
            return;
        }
        
        let kpiListLocal:any = [];

        // add the entries in data.statModel.analysis.kpiSet
        for (let i = 0; i < data.statModel.analysis.kpiSet.length; i++) {
            kpiListLocal.push({
                id: data.statModel.analysis.kpiSet[i].name,
                name: data.statModel.analysis.kpiSet[i].name
            });
        }
        
        // if the modelfunction is MLR
        if (data.statModel.modelfunction === "MLR") {
            // add the interaction terms
            for (let i = 0; i < data.statModel.analysis.kpiSet.length; i++) {
                for (let j = 0; j < data.statModel.analysis.kpiSet.length; j++) {
                    kpiListLocal.push({
                        id: data.statModel.analysis.kpiSet[i].name + " * " + data.statModel.analysis.kpiSet[j].name,
                        name: data.statModel.analysis.kpiSet[i].name + " * " + data.statModel.analysis.kpiSet[j].name,
                    });
                }
            }
        }
        setKpiList(kpiListLocal);
    }
    , [data]);



    const formLayout = {
        labelCol: { span: 5 },
    };

    if (queryError) return (
        <Card>Error: {queryError.message} </Card>
    );

    if (queryLoading) return (
        <div style={{ overflow: 'auto', width: '220px' }} >
            <Card><Spin /></Card>
        </div>

    );

    const handleEdit = (input:any) => {
        
        setModalInitialValues({ 
            name:input.name,
            description:input.description
        })
        
        setModalId(input.id)
        setEditModalVisible(true)
    } 

    const handleEditModalOk = (values: any) => {
    
    
        let input = {
            id: modalId,
            name : values['name'], 
            description: values['description']
        };

        updateStatModel({ variables: { input } });
        setEditModalVisible(false);
    }


    
    const handleEditModalCancel = (values: any) => {
        setEditModalVisible(false);
    };


    // get selectedProcesses from the ids of the processes in the model
    const selectedProcesses = data.statModel.processes.map((process: any) => process.id);

    function setSelectedProcesses(selectedProcesses: any) {

        let input = {
            id: props.modelId,
            processIds: selectedProcesses,
            recompute: false,
        }
        updateStatModel({ variables: { input } });
    }
    
    function onChangeNormalization(checked: boolean) {
        setNormalization(checked);
        setRecomputeNeeded(true);
    }

    function onChangePcaComponents(value: number | null) {
        if (value != null) {
            setPcaComponents(value!);
            setRecomputeNeeded(true);
        }
    }

    function onChangeSelectedY(value: string) {
        setSelectedY(value);
        setRecomputeNeeded(true);
    }

    function recompute() {


        // if model function is PCA
        if (data.statModel.modelfunction === "PCA") {
            let input = {
                id: props.modelId,
                normalization: normalization,
                pcaComponents: pcaComponents,
                xNames: selectedKPIs,
                processIds: selectedProcesses,
                recompute: true
            }
            updateStatModel({ variables: { input } }); 
            
        }

        // if model function is MLR
        if (data.statModel.modelfunction === "MLR") {
            let input = {
                id: props.modelId,
                normalization: normalization,
                xNames: selectedKPIs,
                yName: selectedY,
                processIds: selectedProcesses,
                recompute: true
            }
            updateStatModel({ variables: { input } });
            
        }

        setRecomputeNeeded(false);
    }

    function onChangeXaxis(value: string) {
        props.setAxes([value, props.axes[1], props.axes[2]])
    }

    function onChangeYaxis(value: string) {
        props.setAxes([props.axes[0], value, props.axes[2]])
    }

    function onChangeZaxis(value: string) {
        props.setAxes([props.axes[0], props.axes[1], value])
    }

    // create the list of availaible KPIs / features
    /*
    function createKPIList() {
        let kpiList = [];

        // add the entries in data.statModel.analysis.kpiSet
        for (let i = 0; i < data.statModel.analysis.kpiSet.length; i++) {
            kpiList.push({
                id: data.statModel.analysis.kpiSet[i].name,
                name: data.statModel.analysis.kpiSet[i].name
            });
        }
        
        // if the modelfunction is MLR
        if (data.statModel.modelfunction === "MLR") {
            // add the interaction terms
            for (let i = 0; i < data.statModel.analysis.kpiSet.length; i++) {
                for (let j = 0; j < data.statModel.analysis.kpiSet.length; j++) {
                    kpiList.push({
                        id: data.statModel.analysis.kpiSet[i].name + " * " + data.statModel.analysis.kpiSet[j].name,
                        name: data.statModel.analysis.kpiSet[i].name + " * " + data.statModel.analysis.kpiSet[j].name,
                    });
                }
            }
        }

        return kpiList;
    }
    */

    return (

        <div style={{ overflow: 'auto', width: '220px' }} >


            <Collapse defaultActiveKey={["1", "2", "3", "4", "3", "5", "6", "7"]}  >

                <Panel header={"Model"} key="1" data-testid="model-panel">

                    <div style={{ marginBottom: 5 }}>
                        Type: {data.statModel.modelfunction}
                    </div>
                          
                    <div style={{ marginBottom: 5 }}>
                        Name: {data.statModel.name}
                        <Button type="primary" ghost onClick={() => handleEdit(data.statModel)} icon={<EditOutlined />} size="small" style={{ marginLeft: 5 }} data-testid="edit-button" />
                    </div>

                    <br />
                    {/* center the button */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        
                        { !mutationLoading &&
                            <Button 
                                type="primary" 
                                style={{ marginBottom: 10 }} 
                                onClick={recompute} 
                                disabled={!recomputeNeeded || data.statModel.analysis.userPermissions == "read"}
                                data-testid="recompute-button"
                            >
                                Compute
                            </Button>
                        }
                        
                        { mutationLoading &&
                            <Spin style={{marginBottom:10}} tip="Computing..." />
                        }

                    </div>

                </Panel>


                <Panel header={"Selected processes (" + selectedProcesses.length + ")"} key="2" data-testid="process-panel">
                    <SelectProcesses
                        modelId={props.modelId}
                        availableProcesses={data.statModel.analysis.processes}
                        selectedProcesses={selectedProcesses}
                        setSelectedProcesses={setSelectedProcesses}
                        setRecomputeNeeded={setRecomputeNeeded}
                        editable = {data.statModel.analysis.userPermissions != "read"}
                    />
                </Panel>

                {/*
                <Panel header={"KPI set "} key="3" data-testid="kpi-set-panel">
                    <SelectKPIset topicId={data.statModel.topic.id} selectedKPIDefinition={data.statModel.id} setSelectedKPIDefinition={setSelectedKPIDefinition} />
                </Panel>
                */}

                <Panel header="KPIs (X)" key="4" data-testid="kpi-panel">
                    
                    <SelectKPIs
                        modelId={props.modelId}
                        availableKPIs={kpiList}
                        selectedKPIs={selectedKPIs}
                        setSelectedKPIs={setSelectedKPIs}
                        setRecomputeNeeded={setRecomputeNeeded}
                        buttonLabel={"Select X variables"}
                        editable = {data.statModel.analysis.userPermissions != "read"}
                    />
                    

                </Panel>

                { /* if modelfunction is MLR */}
                {data.statModel.modelfunction === "MLR" &&
                    <Panel header={"Target variable (y)"} key="7" data-testid="y-panel">

                        {/* create an antd dropdown to select one KPI */}
                        <Select 
                            style={{ marginLeft: 20, width: 150 }} 
                            onChange={onChangeSelectedY} 
                            defaultValue={data.statModel.yName} 
                            disabled = {data.statModel.analysis.userPermissions == "read"}
                            showSearch
                            optionFilterProp='title'
                            data-testid="ySelect"

                        >
                            {data.statModel.analysis.kpiSet.map((kpi: any) => {
                                return (
                                    <Option key={kpi.name} value={kpi.name} title={kpi.name}>{kpi.name}</Option>
                                )
                            }
                            )}
                        </Select>

                    </Panel>
                }

                <Panel header="Model options" key="6" data-testid="model-options-panel">

                <Space direction="vertical">

                    <Space>
                        <Switch checked={normalization} style={{ marginRight: 15 }} onClick={onChangeNormalization} disabled = {data.statModel.analysis.userPermissions == "read"} />
                        <div>Scale X</div>
                    </Space>

                    { /* if modelfunction is PCA */}
                    {data.statModel.modelfunction === "PCA" &&

                        <Space>
                            <InputNumber size="small" value={pcaComponents} min={2} style={{ width: 60 }} onChange={onChangePcaComponents} disabled = {data.statModel.analysis.userPermissions == "read"} data-testid="pca-components-input"/>
                            <div>PCA components</div>
                        </Space>

                    }


                </Space>

                </Panel>

                { /* if modelfunction is PCA */}
                {data.statModel.modelfunction === "PCA" &&
                    <Panel header="Axes" key="5" data-testid="axes-panel">

                        <Space direction="vertical">
                            <Space >

                                <div style={{ marginLeft: 10 }}>X-axis:</div>
                                <Select value={props.axes[0]} size="small" style={{ width: 100 }} onChange={onChangeXaxis} data-testid="xSelect">

                                    {/* for loop between 0 and number of pcaComponents */}
                                    {Array.from(Array(data.statModel.pcaComponents).keys()).map(i => (
                                        <Option key={i} value={i}>PC{i + 1}</Option>
                                    ))}

                                </Select>
                            </Space>

                            <Space>
                                <div style={{ marginLeft: 10 }}>Y-axis:</div>
                                <Select value={props.axes[1]} size="small" style={{ width: 100 }} onChange={onChangeYaxis} data-testid="ySelect">
                                    {/* for loop between 0 and number of pcaComponents */}
                                    {Array.from(Array(data.statModel.pcaComponents).keys()).map(i => (
                                        <Option key={i} value={i}>PC{i + 1}</Option>
                                    ))}
                                </Select>
                            </Space>
                            <Space>
                                <div style={{ marginLeft: 10 }}>Z-axis:</div>
                                <Select value={props.axes[2]} size="small" style={{ width: 100 }} onChange={onChangeZaxis} data-testid="zSelect">
                                    {/* for loop between 0 and number of pcaComponents */}
                                    <Option key={-1} value={-1}>---</Option>
                                    {Array.from(Array(data.statModel.pcaComponents).keys()).map(i => (
                                        <Option key={i} value={i}>PC{i + 1}</Option>
                                    ))}
                                </Select>
                            </Space>
                        </Space>
                    </Panel>
                }

                

            </Collapse>

            <Modal 
                title= { "Edit model" }
                open={ isEditModalVisible } 
                onOk = { handleEditModalOk }
                onCancel = { handleEditModalCancel }
                destroyOnClose = {true}
                footer={[
                    <Button type="primary" form="VariableForm" key="submit" htmlType="submit" data-testid="submit-button">
                        Submit
                    </Button>
                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "VariableForm"
                    onFinish={handleEditModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <br/>
                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <Input maxLength={200} showCount data-testid="name-input" />
                    </Form.Item>

                    <Form.Item label="Description:" name="description" rules={[{ required: false }]} >
                        <TextArea maxLength={1000} showCount data-testid="description-textarea" />
                    </Form.Item>

                </Form>

            </Modal>


        </div>
    )
}

export default ModelMenu;