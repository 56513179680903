import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Skeleton, Card, Collapse, Steps} from 'antd';
import useWindowDimensions from '../useWindowDimensions';

import AnalysisTable from './AnalysisTable';


//const { Panel } = Collapse;
//const { Meta } = Card;
//const { Step } = Steps;

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            darkMode
            selectedTeam {
                id
                name
                userPermissions
            }
            selectedAnalysis {
                id
                processes {
                    id
                }

                kpiAnalysisView {
                    id 
                }
                processExplorerView {
                    id
                }
            }
        }
    }
`

function AnalysisCard() {


    const { height, width } = useWindowDimensions();
    const [activeTabKey, setActiveTabKey] = useState<string>('team');

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_USER_PROFILE);

    const tabList:any = [
        {
          key: 'team',
          tab: 'Team analyses', 
        },
        {
          key: 'personal',
          tab: 'Personal analyses',
        }
      ];

    
    if (queryLoading) return (
        <div>
            <Skeleton active />
        </div>
    )

    if (queryError) return (
        <div>
            <p>Error: {queryError.message}</p>
        </div>
    );

    const contentList: Record<string, React.ReactNode> = {
        team: (
          <div>
            <AnalysisTable teamId={queryData.userProfile.selectedTeam.id}/>
          </div>
        ),
        
        personal: (
          <div>
            <AnalysisTable teamId={queryData.userProfile.selectedTeam.id} analysisType='personal'/>
          </div>
        ),
    };

    
    

    return(  
      <div>

        <Card 
          style={{overflow:"auto"}}
          tabList={tabList} 
          onTabChange={key => {
            setActiveTabKey(key);
          }}
          activeTabKey={activeTabKey}
          
        >
          {contentList[activeTabKey]}
        </Card>
          
      </div>
    )
  }
  
  export default AnalysisCard;
