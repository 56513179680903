import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, useReactiveVar, gql } from '@apollo/client';
import { Card, Space, Popconfirm, Button, Form, Skeleton, Modal, Input, message, Select, Tabs, Popover } from 'antd';
import DeleteModal from '../DeleteModal';

import { processExplorerSelectedViewPageVar, kpiAnalysisSelectedViewPageVar } from '../../Apollo';

//import { selectedViewId } from '../../Apollo'

const { Option } = Select;

export const GET_VIEWS = gql`
    query selectedViews {
        userProfile {
            id
            selectedAnalysis {
                id
                userPermissions

                processExplorerView {
                    id

                    viewpageSet {   
                        id
                        name
                    }
                }

                kpiAnalysisView {
                    id

                    viewpageSet {   
                        id
                        name
                    }
                }
            }
        }
    }
`
const CREATE_VIEW_PAGE = gql`
    mutation createViewPage ($input: CreateViewPageInputType!) {
        createViewPage( input:$input) {
            processExplorerView {
                id
                viewpageSet {
                    id
                    name
                }
            }
        }
    }
`;

const UPDATE_VIEW_PAGE = gql`
    mutation updateViewPage ($input: UpdateViewPageInputType!) {
        updateViewPage( input:$input) {
            processExplorerView {
                id
                viewpageSet {
                    id
                    name
                }
            }
        }
    }
`;

const DELETE_VIEW_PAGE = gql`
    mutation deleteViewPage ($id: ID!) {
        deleteViewPage( id:$id) {
            id
            ok
        }
    }
`;


const objectName = "Page";

interface ViewPageProps {
    pageName: string;
    analysis: any
    setUpdateViewLoading: Function;
    loading: boolean;
}

function ViewPage(props: ViewPageProps) {



    // ----------------------------------------------------------------------------------------
    // states

    const [isViewPageModalVisible, setViewPageModalVisible] = useState(false);
    const [viewPageModalInitialValues, setViewPageModalInitialValues] = useState({ name: "" });
    const [viewPageModalTitel, setViewPageModalTitel] = useState("");
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [tabToDelete, setTabToDelete] = useState('');
    
    const processExplorerViewSelectedViewPage = useReactiveVar(processExplorerSelectedViewPageVar);
    const kpiAnalysisViewSelectedViewPage = useReactiveVar(kpiAnalysisSelectedViewPageVar);
    const inputRef = useRef<any>(null);
    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_VIEWS, 
        {
            //fetchPolicy: 'cache-and-network',
        }
    );

    const [createViewPageMutation, { loading: createViewPageLoading }] = useMutation(CREATE_VIEW_PAGE, {
        refetchQueries: [{ query: GET_VIEWS , variables: { page:props.pageName } }],
        onCompleted(data) { message.success(objectName + " was added successfully.") },
        onError(error) { message.error(error.message) },
    });

    const [updateViewPageMutation] = useMutation(UPDATE_VIEW_PAGE, {
        //refetchQueries: [{ query: GET_VIEWS , variables: { page:props.pageName } }],
        onCompleted(data) { 
            props.setUpdateViewLoading(false);
            message.success(objectName + " was edited successfully.") 
        },
        onError(error) { 
            props.setUpdateViewLoading(false);
            message.error(error.message) 
        },
    });

    const [deleteViewPageMutation] = useMutation(DELETE_VIEW_PAGE, {
        refetchQueries: [{ query: GET_VIEWS , variables: { page:props.pageName } }],
        onCompleted(data) { message.success(objectName + " was deleted successfully.") },
        onError(error) { message.error(error.message) },
    });

    // ----------------------------------------------------------------------------------------

    // set the selected view page reactive variables 
    // if it is not set, then set it to the id of the first view page
    // also if the id of the selected view page is not in the list of view pages, then set the selected view page to the first view page

    if (props.pageName == "ProcessExplorer") {
        
        if (processExplorerViewSelectedViewPage == "" || queryData?.userProfile?.selectedAnalysis?.processExplorerView?.viewpageSet.find((viewPage: any) => viewPage.id == processExplorerViewSelectedViewPage) == null) {
            // if length of view pages is greater than 0, then set the selected view page to the first view page
            if (queryData?.userProfile?.selectedAnalysis?.processExplorerView?.viewpageSet.length > 0) {
                processExplorerSelectedViewPageVar(queryData.userProfile.selectedAnalysis.processExplorerView.viewpageSet[0].id)
            }
        }
    } else if (props.pageName == "KPIAnalysis") {
        if (kpiAnalysisViewSelectedViewPage == "" || queryData?.userProfile?.selectedAnalysis?.kpiAnalysisView?.viewpageSet.find((viewPage: any) => viewPage.id == kpiAnalysisViewSelectedViewPage) == null) {
            // if length of view pages is greater than 0, then set the selected view page to the first view page
            if (queryData?.userProfile?.selectedAnalysis?.kpiAnalysisView?.viewpageSet.length > 0) {
                kpiAnalysisSelectedViewPageVar(queryData.userProfile.selectedAnalysis.kpiAnalysisView.viewpageSet[0].id)
            }
        }
    }
    
    
    // use effect to set the inputRef in focus when the modal is opened
    useEffect(() => {
        if (isViewPageModalVisible) {
            setTimeout(() => {
                inputRef.current?.focus();
              }, 0);
        }
    }, [isViewPageModalVisible])

    // ----------------------------------------------------------------------------------------

    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );


    let selectedView:any = {};

    let tabActiveKey = '';
    if (props.pageName == "ProcessExplorer") {
        selectedView = queryData?.userProfile?.selectedAnalysis?.processExplorerView;
        tabActiveKey = processExplorerViewSelectedViewPage;
    } else if (props.pageName == "KPIAnalysis") {
        selectedView = queryData?.userProfile?.selectedAnalysis?.kpiAnalysisView;
        tabActiveKey = kpiAnalysisViewSelectedViewPage;
    }

    if ((queryLoading && !queryData) || selectedView == null || props.loading) return (
        <Card style={{ width: '100%' , marginLeft: 10, height: 60, overflow:"hidden"}} data-testid="view-page-loading">
            <Skeleton active/>
        </Card>
    );
    
    // ----------------------------------------------------------------------------------------

    const showViewPageModal = (values: any) => {
        setViewPageModalTitel("Add page")
        setViewPageModalInitialValues({ name: "" })
        setViewPageModalVisible(true)
    }


    const handleViewPageModalOk = (values: any) => {

        // adding a new view page
        if (viewPageModalTitel == "Add page") {

            let input = {
                viewId: selectedView.id,
                name: values['name'],
            };

            // perform the mutation to create the new view
            createViewPageMutation({ variables: { input: input } });


        } else {

            let input = {
                id: selectedView.viewpageSet.find((viewPage: any) => viewPage.id == tabActiveKey).id,
                name: values['name']
            };

            props.setUpdateViewLoading(true);
            updateViewPageMutation({ variables: { input: input } });
        }

        setViewPageModalVisible(false);
    };

    const handleViewPageModalCancel = (values: any) => {
        setViewPageModalVisible(false);
    };

    const handleViewPageEdit = (input: any) => {

        setViewPageModalTitel("Edit page")

        setViewPageModalInitialValues({
            name: selectedView.viewpageSet.find((viewPage: any) => viewPage.id == tabActiveKey).name
        })

        setViewPageModalVisible(true)
    }

    const handleViewPageDelete = () => {

        // if selected tab is the one to be deleted, then set the selected tab to the first tab in the list that is not the one to be deleted
        if (tabActiveKey == tabToDelete) {
            if (selectedView.viewpageSet.length > 1) {
                let newActiveKey = selectedView.viewpageSet.find((viewPage: any) => viewPage.id != tabToDelete).id
                onViewPageChange(newActiveKey)
            }
        }

        deleteViewPageMutation({ variables: { id: tabToDelete } })
    }

    const tabOnChange = (activeKey: string) => {
        // if the tab is already selected, open the edit modal
        if (props.pageName == "ProcessExplorer") {
            if (activeKey == processExplorerViewSelectedViewPage) {
                handleViewPageEdit({})
            }
        }
        if (props.pageName == "KPIAnalysis") {
            if (activeKey == kpiAnalysisViewSelectedViewPage) {
                handleViewPageEdit({})
            }
        }

        onViewPageChange(activeKey)
    }


    function onViewPageChange(viewPageId: string) {

        if (props.pageName == "ProcessExplorer") {
            processExplorerSelectedViewPageVar(viewPageId)
        } else if (props.pageName == "KPIAnalysis") {
            kpiAnalysisSelectedViewPageVar(viewPageId)
        }
    }

    const formLayout = {
        labelCol: { span: 4 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 12 },
    };

    // create a list of tab items from the view pages
    let tabItems = selectedView.viewpageSet.map((viewPage: any) => {
        return {
            key: viewPage.id,
            label: viewPage.name,
        }
    })

    const viewPageDropdownContent = (
       
            <Space direction="vertical">
                <Button size="small" type="link" onClick={showViewPageModal} data-testid='add-view-page-button' >Add</Button>
                <Button size="small" type="link" onClick={handleViewPageEdit} data-testid='rename-view-page-button'>Rename</Button>
                <Popconfirm title="Delete view?" onConfirm={handleViewPageDelete} >
                    <Button size="small" type="link" data-testid='delete-view-page-button'>Delete</Button>
                </Popconfirm>
            </Space>
      
    )


    return (

        <div style={{ width: '100%'}} >

            <Tabs
                type="editable-card"
                tabPosition='bottom'
                onChange={tabOnChange}
                onTabClick={(key, event) => { 
                    // if the tab is already selected, open the edit modal
                    if (key == tabActiveKey) {
                        if (queryData?.userProfile?.selectedAnalysis?.userPermissions != null && queryData?.userProfile?.selectedAnalysis?.userPermissions != "read") {
                            handleViewPageEdit({})
                        }
                    }
                }}

                activeKey={ tabActiveKey }
                onEdit={(targetKey, action) => {
                    if (queryData?.userProfile?.selectedAnalysis?.userPermissions != null && queryData?.userProfile?.selectedAnalysis?.userPermissions != "read") {
                        if (action == 'add') {
                            showViewPageModal({})
                        } else if (action == 'remove') {
                            // which tab to remove
                            let viewPageId:any = ''
                            // if targetKey is string
                            viewPageId = targetKey
                            // ask the user to confirm the delete
                            // open the confirm dialog
                            setTabToDelete(viewPageId)
                            setDeleteModalVisible(true)

                            // perform the delete mutation
                            //deleteViewPageMutation({ variables: { id: viewPageId } })
                        
                        }
                    }
                }
                }
                items={tabItems}
                style={{marginLeft:10, marginRight:5, marginTop:-15}}
                size='small'
                contentEditable={false}
                
            />

            <Modal
                title={viewPageModalTitel}
                data-testid='add-view-page-modal'
                open={isViewPageModalVisible}
                onOk={handleViewPageModalOk}
                onCancel={handleViewPageModalCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="primary" form="ViewForm" key="submit" htmlType="submit" id="view-page-modal-submit" data-testid='view-page-modal-submit'>
                        Submit
                    </Button>
                ]}
            >
                <Form
                    {...formLayout}
                    id="ViewForm"
                    onFinish={handleViewPageModalOk}
                    initialValues={viewPageModalInitialValues}
                >
                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <Input maxLength={30} data-testid='view-page-name-input' ref={inputRef} />
                    </Form.Item>

                </Form>

            </Modal>

            <DeleteModal
                objectName='page'
                open={deleteModalVisible}
                setOpen={setDeleteModalVisible}
                onOk={handleViewPageDelete}
            />

            <Modal
                title="Loading..."
                open={createViewPageLoading}
                footer={null}
                closable={false}
            >
                <Skeleton active />
            </Modal>


        </div>
    );
}


export default ViewPage;

