import React, {useState, useEffect} from 'react';
import { Card, Spin, Modal, message, Radio, Slider, Row, Col} from 'antd';
import MediaPlot from './MediaPlot';

interface MediaDisplayInputType {
    media: any
    visible: any
    setVisible: Function
};

function MediaDisplay(props:MediaDisplayInputType) {

    

    function onOk() {
        props.setVisible(false);
    }

    function onCancel() {
        props.setVisible(false);
    }

    if (props?.media === undefined) {
        return (
            <Modal
                title="Media"
                open={props.visible}
                onOk={onOk}
                onCancel={onCancel}
                width={1000}
                footer={null}
                destroyOnClose={true}
            >
                <Spin />
            </Modal>
        );
    }
    

    return (
        <div>
             <Modal
                title={"Media: " + props.media.name}
                open={props.visible}
                onCancel = {onCancel}    
                onOk = {onOk}
                width = {800}
            >
                <MediaPlot media={props.media}/>
                

            </Modal>
            
        </div>
    )

}

export default MediaDisplay;