
import {useState} from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useQuery, useMutation, gql , useApolloClient} from '@apollo/client';
import {Card, Form, Button, Upload, Radio, Tooltip, message, Spin, Input, Skeleton, Collapse, Select} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TopicUsers from '../../components/topics/TopicUsers';
import DeleteModal from '../DeleteModal';
import Modal from 'antd/es/modal/Modal';
import { useNavigate } from 'react-router-dom';
import {createOptimisticResponse} from '../createOptimisticResponse'

import { CORE_TOPIC_FIELDS } from '../fragments';
const { Panel } = Collapse;

const { TextArea } = Input;

const GET_TOPIC = gql`
    ${CORE_TOPIC_FIELDS}
    query topic ($id:ID!) {
        topicById(id:$id) {
            ...CoreTopicFields
        }

        userProfile {
            id
            admin
        }

    }
`;

const READ_TOPICS = gql`
    ${CORE_TOPIC_FIELDS}
      query Topics {  
        topics {
            ...CoreTopicFields
        }
      }
`;

const UPDATE_TOPIC = gql`
    ${CORE_TOPIC_FIELDS}
    mutation updateTopic($input: UpdateTopicInputType!) {
        updateTopic( input:$input) {
            topic {
                ...CoreTopicFields
            }
        }
    }
`;

const DELETE_TOPIC = gql`
    mutation deleteTopic($id: ID!) {
        deleteTopic( id:$id) {
            id
            ok
        }
    }
`;


interface TopicSettingsInputType {
    topicId:number,
}

function TopicSettings(props:TopicSettingsInputType) {

    const navigate = useNavigate();

    // ----------------------------------------------------------------------------------------
    // states

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    // ----------------------------------------------------------------------------------------
    // queries

    const { loading, error, data } = useQuery(GET_TOPIC , {
        variables:{id:props.topicId},
        fetchPolicy: "cache-and-network"
    });

    // ----------------------------------------------------------------------------------------
    // mutations

    const [updateTopic] = useMutation(UPDATE_TOPIC, {
        
        onCompleted: (data) => {
            //message.success("Collection settings updated");
        },
        onError: (error) => {
            message.error("Error updating collection settings: " + error.message);
        }
    });

    const [deleteMutation, {loading:deleteMutationLoading, error:deleteMutationError}] = useMutation(DELETE_TOPIC, {
        refetchQueries: [ { query: READ_TOPICS } ],
        onCompleted(data) { 
            message.success("Deleting the collection is running in the background.")
            navigate("/collections")
        },
        onError(error) {message.error(error.message)}, 
    });

    // ----------------------------------------------------------------------------------------
    // functions


    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

    function handleNameDescriptionChange(values:any) {

        
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_FIELDS, 
            props.topicId, 
            {name: values.name, description: values.description, teamDataStandardsEnabled: values.teamDataStandardsEnabled, organizationDataStandardsEnabled: values.organizationDataStandardsEnabled},
            'CoreTopicFields'
        );
    

        updateTopic({variables:{input:{id:props.topicId, name:values.name, description:values.description, teamDataStandardsEnabled:values.teamDataStandardsEnabled, organizationDataStandardsEnabled:values.organizationDataStandardsEnabled}},
            
            optimisticResponse: {
                __typename: "Mutation",
                updateTopic: {
                    __typename: "UpdateTopicPayload",
                    topic: {
                        __typename: "TopicType",
                        id: props.topicId,
                        ...optimisticResponse
                    }
                }
            }, 
            onCompleted: (data) => {
                message.success("Collection settings updated");
            }
        });
    }

    function handleDeleteTopic() {
        setDeleteModalOpen(true);
    }

    // ----------------------------------------------------------------------------------------
    // render

    const layout = {
        labelCol: { span: 3 },
        //wrapperCol: { span: 12 },
    };

    const tailLayout = {
        wrapperCol: { offset: 3, span: 12 },
    };
    
    if (loading && !data) {
        return (
            <Card title="Settings">
                <Spin />
            </Card>
        )
    }

    if (error) return ( 
        <Card>Error: {error.message} </Card>
    );


    if (data.topicById.owner.username != sessionStorage.getItem('username') && data.userProfile.admin === false) {
        return (
            <Card>
                <p>You do not have permission to edit the settings of this topic. </p>
                <p>Please contact the owner of the topic: {data.topicById.owner.firstName + " " + data.topicById.owner.lastName} ({data.topicById.owner.email})</p>
            </Card>
        )
    }

    return (
        <div>

        <Collapse accordion defaultActiveKey={["1"]}>

            <Panel header="General" key="1">
                <div>
                    <Form
                    {...layout}  
                    id = "TopicsForm"
                    onFinish={handleNameDescriptionChange}
                    initialValues={{ 
                        name: data.topicById.name,
                        description: data.topicById.description,
                        teamDataStandardsEnabled: data.topicById.teamDataStandardsEnabled,
                        organizationDataStandardsEnabled: data.topicById.organizationDataStandardsEnabled
                        
                    }}

                    > 
                        <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                            <Input maxLength={50} data-testid="topic-name" showCount/>
                        </Form.Item>

                        <Form.Item label="Description:" name="description" rules={[{ required: false }]} >
                            <TextArea maxLength={200} data-testid="topic-description" showCount rows={6} />
                        </Form.Item>

                        <Form.Item label="Team lexicon enabled:" name="teamDataStandardsEnabled">
                            <Select defaultValue={data.topicById.teamDataStandardsEnabled} style={{ width: 120 }} >
                                <Select.Option value={true}>Yes</Select.Option>
                                <Select.Option value={false}>No</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Organisation lexicon enabled:" name="organizationDataStandardsEnabled" >
                            <Select defaultValue={data.topicById.organizationDataStandardsEnabled} style={{ width: 120 }} >
                                <Select.Option value={true}>Yes</Select.Option>
                                <Select.Option value={false}>No</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" data-testid="submit-button">Save</Button>
                        </Form.Item>

                    </Form>
                </div>
            </Panel>

            <Panel header="Delete" key="2">
                <div>
                    <Form {...layout} name="nest-messages" >
                        <Form.Item name="delete">
                            <p>Deleting a collection will remove all data and cannot be undone. </p>
                            <Button type="primary" danger onClick={() => handleDeleteTopic()} data-testid="delete-button">Delete</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Panel>

        </Collapse>

        <DeleteModal 
        open={deleteModalOpen} 
        setOpen={setDeleteModalOpen} 
        objectName={'collection "' + data.topicById.name + '"'}
        onOk={() => deleteMutation({variables:{id:props.topicId}})}
        //redirect_url="/collections"
        />

        <Modal
        open={deleteMutationLoading}
        footer={null}
        closable={false}
        title="Deleting"
        >
        <Skeleton active/>
        </Modal>

        </div>

    );
}

export default TopicSettings;