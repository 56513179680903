import {useEffect} from 'react';
import { useQuery, gql } from '@apollo/client';
import { Space, Spin, Select, Card } from 'antd';
import { pollInterval } from '../..';

const { Option } = Select;

const READ_TOPICS = gql`
      query Topics ($teamId:ID!) {
        topics (teamId:$teamId) {
            id
            name
            description
        }
      }
`;


interface SelectTopicInputType {
    selectedTopicId: string
    setSelectedTopic: Function
    teamId: string
}

function SelectTopic(props: SelectTopicInputType) {

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(READ_TOPICS, { 
        variables: { teamId: props.teamId },
        //fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    function onTopicChange(input: any) {
        props.setSelectedTopic(input)
    }

    // set the initial topic
    useEffect(() => {
        if (queryData && queryData?.topics?.length > 0) {
            props.setSelectedTopic(queryData.topics[0].id)
        }
    },[queryData]);

    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );

    if (queryLoading) return (
        <Card><Spin /></Card>
    );

    return (
        <div style={{ width: '100%' }} >

            <Space direction="vertical" size="small" style={{ width: '100%' }} >

                <Select style={{ width: '100%' }} onChange={onTopicChange} value={props.selectedTopicId} placeholder="Please select" data-testid="select-topic">

                    {queryData.topics.map((topic: any) => { return (<Option value={topic.id} key={topic.id} > {topic.name} </Option>) })}

                </Select>

            </Space>
        </div>
    );

}

export default SelectTopic;