import React, {useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Space, Spin, Select, Card } from 'antd';
import {globalSelectedTopic } from '../../Apollo';
import { pollInterval } from '../..';

const { Option } = Select;

const READ_TOPICS = gql`
      query Topics ($teamId:ID!) {
        topics (teamId:$teamId) {
            id
            name
            description
            userPermissions
        }
      }
`;

interface ImportTopicInputType {
    setTopic:Function
    setTopicName:Function
    setProcess:Function
    topic:string
    teamId:string
}

function ImportTopic(props: ImportTopicInputType) {

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(READ_TOPICS, { 
        variables: { teamId: props.teamId },
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });



    function onTopicChange(input: any) {
        //globalSelectedTopic(input)
        props.setTopic(input)
        let topic = queryData.topics.find((topic:any)=> topic.id == input )
        props.setTopicName(topic.name)
        props.setProcess("")
    }

    // useEffect to set the topic to the first topic in the list when the list of topics is loaded
    /*
    useEffect(() => {
        if (queryData && queryData?.topics?.length > 0) {
            props.setTopic(queryData.topics[0].id)
            props.setTopicName(queryData.topics[0].name)
        }
    }, [queryData]);
    */
    
    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );

    if (queryLoading && !queryData) return (
        <Space direction="vertical" size="small" style={{ width: '100%' }} >
            <Select
                style={{ width: '100%' }} 
                placeholder="Loading collections..." 
                disabled 
                data-testid="select-collection"
            >
            </Select>
        </Space>
    );

    return (
        <div style={{ width: '100%' }} >

            <Space direction="vertical" size="small" style={{ width: '100%' }} >

                <Select 
                    style={{ width: '100%' }} 
                    onChange={onTopicChange} 
                    value={props.topic} 
                    placeholder="Select a collection" 
                    data-testid="select-collection" 
                    showSearch 
                    optionFilterProp="title" 
                    filterOption={(input, option:any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {/* should inlcude "read-write" or "owner" permissions */}
                    {queryData.topics.filter((topic:any)=> topic.userPermissions.includes("read and write") || topic.userPermissions.includes("owner")).map((topic:any) => (
                        <Option key={topic.id} value={topic.id} title={topic.description}>{topic.name}</Option>
                    ))}

                </Select>

            </Space>
        </div>
    );

}

export default ImportTopic;