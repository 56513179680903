import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Form, Spin, Modal, Input, message, InputNumber, Card, Row, Col, Select} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const CREATE_MIXTURE_ACTION = gql`
    mutation createMixtureAction($input: CreateMixtureActionInputType!) {
        createMixtureAction( input:$input) {
            mixture {
                id
                name
                description
                mixtureactionSet {
                    id
                    order
                    description
                    compound {
                        id
                        name
                        formula
                    }
                    undefinedIngredient {
                        id
                        name
                    }
                    mixture {
                        id
                        name
                    } 
                    amount
                    amountUnits
                }
            }
        }
    }
`;

const READ_MIXTURE = gql`
    query mixture ($topicId:ID!) {  
        mixture(topicId:$topicId) {
            id
            name
            description
            topic {
                id
                compoundSet {
                    id
                    name
                    formula
                    casNumber
                }
            }
            mixtureactionSet {
                id
                order
                description
                compound {
                    id
                    name
                    formula
                }
                undefinedIngredient {
                    id
                    name
                }
                mixture {
                    id
                    name
                }
                amount
                amountUnits
            }
        }
    }
`;



const CREATE = CREATE_MIXTURE_ACTION;

const objectName = "Mixture action";

interface MixtureActionsInputType {
    mixture: any
};

function MixtureActions(props:MixtureActionsInputType) {


    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { action:"", actionId:"", compoundId:"", undefinedIngredientId:"", amount:"", unit:"" , comment:""} );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState();
    const [action, setAction] = useState("");
    const [selectedMixtureAction , setSelectedMixtureAction] = useState("");
    
    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    //const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {variables:{id:props.mixtureId}});

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ_MIXTURE, variables: { topicId: props.mixture.topic.id } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    // same mutation as create only different success message
    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ_MIXTURE, variables: { topicId: props.mixture.topic.id } } ], 
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    

    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add mixture action")
        setModalInitialValues({ action:"", actionId:"", compoundId:"", undefinedIngredientId:"", amount:"", unit:"", comment:"" } )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Add mixture action") {
    
            if (action == "add_compound") {

                let input = {
                    id: props.mixture.id,
                    action : "add_compound", 
                    compoundId : values["compoundId"],
                    amount : values["amount"],
                    unit : values["unit"],
                };
                
                createMutation( { variables: { input: input  } } );

            } else if (action == "add_undefined_ingredient") {
                
                let input = {
                    id: props.mixture.id,
                    action : "add_undefined_ingredient", 
                    undefinedIngredientId : values["undefinedIngredientId"],
                    amount : values["amount"],
                    unit : values["unit"],
                };
                
                createMutation( { variables: { input: input  } } );
    
            } else if (action == "fill_to_mass") {
                    
                    let input = {
                        id: props.mixture.id,
                        action : "fill_to_mass",
                        compoundId : values["compoundId"],
                        amount : values["amount"],
                        unit : values["unit"],
                    };
                    
                    createMutation( { variables: { input: input  } } );
    
            } else if (action == "add_comment") {
                        
                        let input = {
                            id: props.mixture.id,
                            action : "add_comment",
                            comment : values["comment"],
                        };
                        
                        createMutation( { variables: { input: input  } } );
        
            }
            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                description : values['description'],
            };
    
            //updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit mixture action")
        
        //setModalInitialValues({ 
        //    name:input.name,
        //    description:input.description 
        //})
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    function handleDelete() {
        let input = {
            id: props.mixture.id,
            actionId: selectedMixtureAction,
            action : "remove_action",
        };
        updateMutation( { variables: { input: input  } } );
    }

    function handleMoveDown() {
        let input = {
            id: props.mixture.id,
            actionId: selectedMixtureAction,
            action : "move_down",
        };
        
        updateMutation( { variables: { input: input  } } );
    }

    function handleMoveUp() {
        let input = {
            id: props.mixture.id,
            actionId: selectedMixtureAction,
            action : "move_up",
        };
        
        updateMutation( { variables: { input: input  } } );
    }

    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
    };

    const columns = [
        {
            title: 'Order',
            dataIndex: 'order',
            editable: false,
            width: '10%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: false,
        },
        {
            title: 'Ingredient',
            //dataIndex: ['compound', 'name'],
            editable: false,
            render: (text:any, record:any) => {
                if (record.compound) {
                    return record.compound.name
                } else if (record.undefinedIngredient) {
                    return record.undefinedIngredient.name
                }
            }
        },
        {
            title: 'Amount',
            //dataIndex: 'amount',
            editable: false,
            render: (text:any, record:any) => {
                // round to 3 decimals
                if (record.amount) {
                    return Math.round(record.amount * 1000) / 1000
                }
                
            }
        },
        {
            title: 'Units',
            dataIndex: 'amountUnits',
            editable: false,
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
          setSelectedMixtureAction(selectedRows[0].id)
        }
    };

    function onActionChange(value:any) {
        setAction(value)
    }

    return (

            <Card title="Mixture actions">
                 <Row>
                    <Col span={4} >
                        <Space direction="vertical">
                            <Button
                                className="button_sidebar"
                                onClick = {  showModal  } 
                                data-testid = "add-mixture-action"                           
                            >
                                Add
                            </Button>
                            {/*<Button onClick = {  handleEdit  } className="button_sidebar">Edit</Button>*/}
                            
                            <Popconfirm title="Delete?" onConfirm={handleDelete} >
                                <Button className="button_sidebar" data-testid="delete-mixture-action">Delete</Button>
                            </Popconfirm>

                            <Button onClick = {  handleMoveUp  } className="button_sidebar" data-testid="move-up-mixture-action">Move up</Button>
                            <Button onClick = {  handleMoveDown  } className="button_sidebar" data-testid="move-down-mixture-action">Move down</Button>
                        </Space>

                    </Col>
                    <Col span={20} >
                    
                    <div style={{height:400, overflow:"auto"}}>
                    
                        <Table 
                            //loading={queryLoading}
                            dataSource={props.mixture.mixtureactionSet}
                            columns = { columns } 
                            bordered
                            rowKey={record => record.id} 
                            size="small"
                            pagination={false}
                            rowSelection={{
                                type: "radio",
                                ...rowSelection,
                            }}
                        />
                    </div>
                    </Col>
                </Row>

                            
                <Modal 
                    title= { modalTitel }
                    open={ isModalVisible } 
                    onOk = { handleModalOk }
                    onCancel = { handleModalCancel }
                    destroyOnClose = {true}
                    data-testid = "mixture-actions-modal"
                    width={800}
                    footer={[
                        <Button type="primary" form="MixtureActionsForm" key="submit" htmlType="submit" data-testid="submit-mixture-actions">
                            Submit
                        </Button>
                        ]}
                >
                    <Form
                        {...formLayout}  
                        id = "MixtureActionsForm"
                        onFinish={handleModalOk} 
                        initialValues={ modalInitialValues }
                    > 
                        
                        <Form.Item label="Action:" name="action" rules={[{ required: true }]}>
                            <Select onChange={onActionChange} data-testid="action-select" >
                                <Select.Option value="add_compound">Create a compound</Select.Option>
                                <Select.Option value="add_undefined_ingredient">Create an undefined ingredient</Select.Option>
                                <Select.Option value="fill_to_mass">Fill to mass</Select.Option>
                                <Select.Option value="add_comment">Add instructions</Select.Option>

                            </Select>
                        </Form.Item>

                        { action == "add_compound" &&
                            <div>
                                <Form.Item label="Compound:" name="compoundId" rules={[{ required: true }]}>
                                    {/* search by compound name */}
                                    <Select showSearch optionFilterProp="children" data-testid="compound-select">
                                        {props.mixture.topic.compoundSet.map((compound:any) => (
                                            <Select.Option value={compound.id}>{compound.name}</Select.Option>
                                        ))}
                                    
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Amount:" name="amount" rules={[{ required: true }]}>
                                    <InputNumber data-testid="amount-input"/>
                                </Form.Item>

                                <Form.Item label="Units:" name="unit" rules={[{ required: true }]}>
                                    <Select data-testid="unit-select">
                                        <Select.Option value="g">g</Select.Option>
                                        <Select.Option value="mg">mg</Select.Option>
                                        <Select.Option value="kg">kg</Select.Option>
                                        <Select.Option value="t">t</Select.Option>
                                    </Select>
                                </Form.Item>

                            </div>
                        }

                        { action == "add_mixture" &&

                            <div>
                                <Form.Item label="Mixture:" name="mixtureId" rules={[{ required: true }]}>
                                    <Select showSearch optionFilterProp="children" data-testid="mixture-select">
                                        {props.mixture.topic.mixtureSet.map((mixture:any) => (
                                            <Select.Option value={mixture.id}>{mixture.name}</Select.Option>
                                        ))}

                                    </Select>
                                </Form.Item>

                                <Form.Item label="Amount:" name="amount" rules={[{ required: true }]}>
                                    <InputNumber data-testid="amount-input"/>
                                </Form.Item>

                                <Form.Item label="Units:" name="unit" rules={[{ required: true }]}>
                                    <Select data-testid="unit-select">
                                        <Select.Option value="g">g</Select.Option>
                                        <Select.Option value="mg">mg</Select.Option>
                                        <Select.Option value="kg">kg</Select.Option>
                                        <Select.Option value="t">t</Select.Option>
                                    </Select>
                                </Form.Item>

                            </div>
                        }
                        
                        { action == "add_undefined_ingredient" &&

                            <div>
                                <Form.Item label="Ingredient:" name="undefinedIngredientId" rules={[{ required: true }]}>
                                    <Select showSearch optionFilterProp="children" data-testid="undefined-ingredient-select">
                                        {props.mixture.topic.undefinedingredientSet.map((undefinedIngredient:any) => (
                                            <Select.Option value={undefinedIngredient.id}>{undefinedIngredient.name}</Select.Option>
                                        ))}

                                    </Select>
                                </Form.Item>

                                <Form.Item label="Amount:" name="amount" rules={[{ required: true }]}>
                                    <InputNumber data-testid="amount-input"/>
                                </Form.Item>

                                <Form.Item label="Units:" name="unit" rules={[{ required: true }]}>
                                    <Select data-testid="unit-select">
                                        <Select.Option value="g">g</Select.Option>
                                        <Select.Option value="mg">mg</Select.Option>
                                        <Select.Option value="kg">kg</Select.Option>
                                        <Select.Option value="t">t</Select.Option>
                                    </Select>
                                </Form.Item>

                            </div>
                        }

                        { action == "fill_to_mass" &&
                            <div>
                                 <Form.Item label="Compound:" name="compoundId" rules={[{ required: true }]}>
                                    <Select showSearch optionFilterProp="children" data-testid="compound-select">
                                        {props.mixture.topic.compoundSet.map((compound:any) => (
                                            <Select.Option value={compound.id}>{compound.name}</Select.Option>
                                        ))}
                                    
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Target mass:" name="amount" rules={[{ required: true }]}>
                                    <InputNumber data-testid="amount-input"/>
                                </Form.Item>

                                <Form.Item label="Units:" name="unit" rules={[{ required: true }]}>
                                    <Select data-testid="unit-select">
                                        <Select.Option value="g">g</Select.Option>
                                        <Select.Option value="mg">mg</Select.Option>
                                        <Select.Option value="kg">kg</Select.Option>
                                        <Select.Option value="t">t</Select.Option>
                                    </Select>
                                </Form.Item>

                            </div>
                        }

                        { action == "add_comment" &&
                            <div>
                                <Form.Item label="Comment:" name="comment" rules={[{ required: true }]}>
                                    <Input.TextArea data-testid="comment-input"/>
                                </Form.Item>
                            </div>
                        }


                    </Form>

                </Modal>
            </Card>

        );
    }


export default MixtureActions;

