import {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Form, Skeleton, Modal, Input, message, Popover, Empty, Row, Col, Card} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import MediaActions from './MediaActions';
import MediaDisplay from './MediaDisplay';
import MediaPlot from './MediaPlot';
import { PlusOutlined } from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import { CORE_COMPOUND_FIELDS, CORE_MEDIA_ACTION_FIELDS, CORE_MEDIA_FIELDS, CORE_MIXTURE_FIELDS, CORE_UNDEFINED_INGREDIENT_FIELDS } from '../fragments';
import ImportMedia from './ImportMedia';

const { TextArea } = Input;


const CREATE_MEDIA = gql`
    ${CORE_MEDIA_FIELDS}
    mutation createMedia($input: CreateMediaInputType!) {
        createMedia( input:$input) {
            media {
                ...CoreMediaFields
            }
        }
    }
`;

const UPDATE_MEDIA = gql`
    ${CORE_MEDIA_FIELDS}
    mutation updateMedia($input: UpdateMediaInputType!) {
        updateMedia( input:$input) {
            media {
                ...CoreMediaFields
            }
        }
    }
`;

const DELETE_MEDIA = gql`
    mutation deleteMedia($id: ID!) {
        deleteMedia( id:$id) {
            id
            ok
        }
    }
`;

const READ_MEDIA = gql`

    ${CORE_COMPOUND_FIELDS}
    ${CORE_MEDIA_ACTION_FIELDS}
    ${CORE_MIXTURE_FIELDS}
    ${CORE_UNDEFINED_INGREDIENT_FIELDS}
    ${CORE_MEDIA_FIELDS}

    query media ($topicId:ID!) {  
        media(topicId:$topicId) {
            ...CoreMediaFields
            
            topic {
                id
                compoundSet {
                    ...CoreCompoundFields
                }
                mixtureSet {
                    ...CoreMixtureFields
                }
                undefinedingredientSet {
                    ...CoreUndefinedIngredientFields
                }
            }

            mediaactionSet {
                ...CoreMediaActionFields
            }
        }

        topicById(id:$topicId) {
            id
            userPermissions
        }

        userProfile {
            id
            selectedTeam {
                id
            }
        }
    }
   
`;

const CREATE = CREATE_MEDIA;
const READ = READ_MEDIA;
const UPDATE = UPDATE_MEDIA;
const DELETE = DELETE_MEDIA;

const objectName = "Media";

interface MediaTableInputType {
    topicId: string
};

function MediaTable(props:MediaTableInputType) {


    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState("");
    const [selectedMediaId, setSelectedMediaId] = useState("");
    const [mediaDisplayModalVisible , setMediaDisplayModalVisible] = useState(false);
    const [importModalVisible, setImportModalVisible] = useState(false);
    
    const { width, height } = useWindowDimensions();

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {
        variables:{topicId:props.topicId},
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE , {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ], 
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ], 
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------
    // if no topic is provided, return empty
    
    if (props.topicId == undefined || props.topicId == "") {
        return (
            <div>
                <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Please select a topic first."/>
            </div>
        )
    }
    
    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add media")
        setModalInitialValues({ name:"", description:"" } )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Add media") {
    
            let input = {
                name : values['name'], 
                description : values['description'],
                topicId:props.topicId
            };
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                description : values['description'],
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit media")
        
        setModalInitialValues({ 
            name:input.name,
            description:input.description 
        })
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
    }
    
    function showMediaDisplayModal(e:any) {
        setSelectedMediaId(e.id);
        setMediaDisplayModalVisible(true);
    }

    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
    };

    const tailLayout = {
        wrapperCol: { offset: 3, span: 12 },
    };


    const columns = [

        {
            title: 'Name',
            //dataIndex: 'name',
            editable: true,
            width: '30%',
            render: (text:any, record:any) => (
                <Button type="link" onClick={()=> handleEdit(record)} data-testid={"media-name-" + record.name} >{record.name}</Button>
            ),
          },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '5%',

            render: (text:String, record:any) => (
                
                    <Popover content={(
                        <Space direction="vertical">
                        <Button type="link" onClick={()=> handleEdit(record)} disabled = { queryData.topicById.userPermissions == "read" } data-testid={"edit-media-" + record.name}>
                            Edit
                        </Button>
                        
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} disabled = { queryData.topicById.userPermissions == "read" }>
                            <Button type="link" disabled = { queryData.topicById.userPermissions == "read" } data-testid={"delete-media-" + record.name}>
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>
                    )}>
                    <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"media-actions-button-" + record.name}>
                        <EllipsisOutlined />
                    </Button>
                </Popover>

                
            ),
        },
    ]

    if (queryLoading){
        return (
            <Skeleton active/>
        )
    }
    if (queryError) {
        return (
            <div></div>
        )
    }

    let modalWidth = 1400;
    let modalTop = 20
    if (modalTitel == "Add media") {
        modalWidth = 800;
        modalTop = 100;
    }
    
    return (

        <div>
            
                <Button
                    type = "primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    style = {{ marginBottom: 16 }}
                    onClick = {  showModal  } 
                    data-testid = "add-media"
                    disabled = { queryData.topicById.userPermissions == "read" }
                    title = { queryData.topicById.userPermissions == "read" ? "You don't have permission to add a media." : "" }

                >
                    Create a media
                </Button>

                
                <Button 
                    style = {{ marginBottom: 16, marginLeft: 10 }}
                    onClick = { () => setImportModalVisible(true) }
                    disabled = { queryData.topicById.userPermissions == "read" }
                    data-testid = "import-media"
                    title = { queryData.topicById.userPermissions == "read" ? "You don't have permission to import a media." : "" }
                >
                    Import
                </Button>
                
                
                <Table 
                    loading={queryLoading}
                    dataSource={queryData?.media}
                    columns = { columns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    pagination={false}
                    scroll={{ y: height-550 }}
                />

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                data-testid = "media-modal"
                width={modalWidth}
                style={{ top: modalTop }}
                footer={[
                    <Button type="primary" form="MediaForm" key="submit" htmlType="submit" data-testid="submit-media" disabled = { queryData.topicById.userPermissions == "read" } title= { queryData.topicById.userPermissions == "read" ? "You don't have permission to edit the media." : "" }>
                        Submit
                    </Button>
                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "MediaForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <Input 
                            maxLength={30}
                            showCount
                            data-testid = "media-name-input"
                            disabled = { queryData.topicById.userPermissions == "read" }
                        />

                    </Form.Item>

                    <Form.Item label="Description:" name="description" rules={[{ required: false }]}>
                        <TextArea 
                            maxLength={1000} 
                            showCount 
                            rows={1}
                            data-testid = "media-description-input" 
                            disabled = { queryData.topicById.userPermissions == "read" }
                        />
                    </Form.Item>

                </Form>
                
                { modalTitel == "Edit media" && 
                    <Row>
                        <Col span={14}>
                            <MediaActions media = { queryData.media.find((media:any) => media.id == modalId) } editable={queryData.topicById.userPermissions != "read"} />
                        </Col>
                        <Col span={10}>
                            <Card title="Media composition">
                                <MediaPlot media = { queryData.media.find((media:any) => media.id == modalId) } />
                            </Card>
                        </Col>
                        
                    </Row>
                }   

            </Modal>
            
            <MediaDisplay media = { queryData.media.find((media:any) => media.id == selectedMediaId) } visible={mediaDisplayModalVisible} setVisible={setMediaDisplayModalVisible} />

            <ImportMedia open={importModalVisible} setOpen={setImportModalVisible} importType='media' targetTopicId={props.topicId} />
        
        </div>

    );
}


export default MediaTable;

