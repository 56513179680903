import { useMutation, gql } from '@apollo/client';
import { Modal , Input, Button, Form, message} from 'antd';
import MomentDatePicker from './MomentDatePicker';
const { TextArea } = Input;


const CREATE_PROCESS = gql`
    mutation createProcess($input:CreateProcessInputType!) {
        createProcess( input:$input )
        {
            process {
                id
                name
                description
                startTime
            }
        }
    }
`;

const GET_PROCESSES = gql`
    query processes($topicId:ID, $cursor:ID, $limit:Int, $filter:Boolean) {
        processes (topicId:$topicId, cursor:$cursor, limit:$limit, filter:$filter) {
            processes {
                id
                name
                description
                startTime
            }
        }
        topicById(id:$topicId) {
            id
            numProcesses
        }
    } 
`;

interface AddProcessInputType {
    topicId:number
    isModalVisible:boolean
    setModalVisible:Function
};

function AddProcess(props:AddProcessInputType) {
    
    const [addProcess, { loading, error }] = useMutation(CREATE_PROCESS, {
        refetchQueries: 'active',
        onCompleted(data) { message.success( "Process was created successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    const onFinish = (values: any) => {
        

        let processData = {
            name : values['name'], 
            description : values['description'],
            startTime : values['startTime'],
            topicId: props.topicId
        };

        addProcess( { variables: { input: processData  } } );
        props.setModalVisible(false)
    };
     
    const onFinishFailed = (errorInfo: any) => {
        //console.log('Failed:', errorInfo);
    };

    const handleModalCancel = (values: any) => {
        props.setModalVisible(false);
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12},
    };
    
    const tailLayout = {
        wrapperCol: { offset: 8, span: 12 },
    };
  
    return (

            <Modal 
                title= 'Create a new process'
                open={ props.isModalVisible } 
                //onOk = { }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                footer = {[]}
                /*footer={[
                    <Button type="primary" form="AddProcessForm" key="submit" htmlType="submit">
                        Submit
                    </Button>
                    ]}
                */
                >
        
        <Form 
            {...formLayout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
          
            <Form.Item
                label="Name:"
                name="name"
                rules={[
                    { required: true, message: 'Please enter the process name.' }, 
                    { max: 50, message: 'The process name cannot be longer than 50 characters.' },
                    // it should not start or end with whitespace
                    { pattern: /^\S(.*\S)?$/, message: 'The process name cannot start or end with whitespace.' }
                ]} 
            >

                <Input maxLength={50} showCount data-testid="name"/>
            </Form.Item>

            <Form.Item
                label="Description:"
                name="description"
                rules={[{ required: false }]} 
            >

                <TextArea maxLength={1000} showCount data-testid="description"/>
            </Form.Item>

            <Form.Item 
                label="Start Time:"
                name="startTime"
                rules={[{ required: true }]} 
            >
                <MomentDatePicker
                    showTime = {true}
                    data-testid="startTime"
                />

            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button 
                    type="primary" 
                    htmlType="submit"
                    data-testid="submit"
                >
                    Submit
                </Button>
            </Form.Item>
  
        </Form>
      </Modal>
    );
}
  
  export default AddProcess