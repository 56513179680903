
import { Space,Typography } from "antd";
import {Link} from "react-router-dom";
const { Title } = Typography;


interface PageHeaderInputType {
    title?: any
    subTitle?: any
    breadcrumb?: any
    extra?: any
    helpContent?: any
}


function PageHeader(props: PageHeaderInputType) {

    // align the title in the center horizontally
    return ( 
        <div style={{padding:15}}>
            <Typography>

                <div>
                    {props?.breadcrumb?.routes.map((route:any) => {
                        if (route.path == "") {
                            return (
                                <span style={{color:"grey"}} key={route.path}>
                                    {route.breadcrumbName}
                                </span>
                            )
                        }

                        return (
                            <Link to={"/" + route.path} key={route.path} style={{color:"grey"}}>
                                {route.breadcrumbName}
                                <span style={{padding:5}}>/</span>
                            </Link>
                        )
                    })}
                </div>

                <Space direction="horizontal" size="large" style={{width:"100%",justifyContent:"space-between"}}>
                    <Title level={4} style={{marginTop:0, minWidth:300}}>{props.title}</Title>
                    <div style={{padding:5}}>
                        {props.extra}
                    </div>
                </Space>
            </Typography>
        </div>
    )

}

export default PageHeader



