import {Modal, Table, Button, Space, Slider, InputNumber, message} from 'antd';
import {useMemo, useState} from 'react';
import {useMutation, gql} from '@apollo/client';
import useWindowDimensions from '../useWindowDimensions';

const UPDATE_VIEW = gql`
    mutation updateProcessExplorerView($input: UpdateProcessExplorerViewInputType!) {
        updateProcessExplorerView( input:$input) {
            processExplorerView {
                id
                processesForDisplay {
                    id
                }
            }
        }
    }
`;

interface ProcessesAdvancedSelectProps {
    analysis:any
    isOpen:boolean
    setOpen:Function
    view_id:string
    processesForDisplay:any
    processes:any
}

function ProcessesAdvancedSelect(props: ProcessesAdvancedSelectProps) {

    const { height, width } = useWindowDimensions();
    const [selectedProcesses, setSelectedProcesses] = useState<React.Key[]>([]);

    const [filteredInfo, setFilteredInfo] = useState<any>({});
    const [sortedInfo, setSortedInfo] = useState<any>({});

    const availableParameters = props.analysis.uniqueParams
    const availableMetadata = props.analysis.uniqueMeta

    const [updateView] = useMutation(UPDATE_VIEW, {
        onError(error) { message.error(error.message) },
    });

    const parameterColumns = useMemo(() => {
        let columns = [];
    
        for (let param of availableParameters) {
          const parameterValues = props.processes?.map((record:any) => {
            let parameter = record.parameterSet.find((parameter:any) => parameter.name === param);
            return parameter ? parameter.value : null;
          }).filter((value:any) => value !== null).map((value:any) => parseFloat(value));
    
          const uniqueValues = [...new Set(parameterValues)];
    
          if (uniqueValues.length > 1) {
            const minValue = Math.min(...parameterValues);
            const maxValue = Math.max(...parameterValues);
    
            columns.push({
              title: param,
              render: (text:any, record:any) => {
                let parameter = record.parameterSet.find((parameter:any) => parameter.name === param);
                return parameter ? parameter.value?.toFixed(2) + " " + parameter.units : null;
              },
              key: param,
              sorter: (a:any, b:any) => {
                let paramA = a.parameterSet.find((parameter:any) => parameter.name === param);
                let paramB = b.parameterSet.find((parameter:any) => parameter.name === param);
                if (paramA && paramB) {
                  return paramA.value - paramB.value;
                }
                return 0;
              },
              filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
                <div style={{ padding: 8 }}>
                  <Space direction="vertical">
                    <Slider
                      min={minValue}
                      max={maxValue}
                      range
                      value={selectedKeys[0] || [minValue, maxValue]}
                      onChange={value => setSelectedKeys([value])}
                    />
                    <InputNumber
                      min={minValue}
                      max={maxValue}
                      value={selectedKeys[0] ? selectedKeys[0][0] : minValue}
                      onChange={value => setSelectedKeys([[value, selectedKeys[0] ? selectedKeys[0][1] : maxValue]])}
                    />
                    <InputNumber
                      min={minValue}
                      max={maxValue}
                      value={selectedKeys[0] ? selectedKeys[0][1] : maxValue}
                      onChange={value => setSelectedKeys([[selectedKeys[0] ? selectedKeys[0][0] : minValue, value]])}
                    />
                    <Button
                      type="primary"
                      onClick={() => confirm()}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Filter
                    </Button>
                    <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                      Reset
                    </Button>
                  </Space>
                </div>
              ),
              onFilter: (value:any, record:any) => {
                let parameter = record.parameterSet.find((parameter:any) => parameter.name === param);
                if (!parameter) return false;
                return parameter.value >= value[0] && parameter.value <= value[1];
              }
            });
          } else {
            columns.push({
              title: param,
              render: (text:any, record:any) => {
                let parameter = record.parameterSet.find((parameter:any) => parameter.name === param);
                return parameter ? parameter.value?.toFixed(2) + " " + parameter.units : null;
              },
              key: param,
              sorter: (a:any, b:any) => {
                let paramA = a.parameterSet.find((parameter:any) => parameter.name === param);
                let paramB = b.parameterSet.find((parameter:any) => parameter.name === param);
                if (paramA && paramB) {
                  return paramA.value - paramB.value;
                }
                return 0;
              },
            });
          }
        }
        return columns;
    }, [props.processes, availableParameters]);


    const metadataColumns = useMemo(() => {
        let columns = [];
    
        for (let meta of availableMetadata) {
          const metadataValues = props.processes?.map((record:any) => {
            let metadata = record.metadataSet.find((metadata:any) => metadata.name === meta);
            return metadata ? metadata.value : null;
          }).filter((value:any) => value !== null);
    
          const uniqueValues = [...new Set(metadataValues)];
    
          columns.push({
            title: meta,
            render: (text:any, record:any) => {
              let metadata = record.metadataSet.find((metadata:any) => metadata.name === meta);
              return metadata ? metadata.value : null;
            },
            key: meta,
            filters: uniqueValues.map(value => ({ text: value, value })),
            onFilter: (value:any, record:any) => {
              let metadata = record.metadataSet.find((metadata:any) => metadata.name === meta);
              return metadata ? metadata.value === value : false;
            }
          });
        }
        return columns;
      }, [props.processes, availableMetadata]);

    let tableColumns:any = [
        
        { 
            title: 'Process name', 
            dataIndex: 'name', 
            key: 'name' ,
            width: 200,
            fixed: 'left',
        },
        {
            title: 'Parameters',
            children: parameterColumns,
        },
        {
            title: 'Metadata',
            children: metadataColumns,
        }
    ]
    
    // use effect to set the selected processes
    useMemo(() => {
        // selected processes is in this format: [ "1716", "1718"]
        setSelectedProcesses(props.processesForDisplay.map((process:any) => process.id))

    }, [props.processesForDisplay])


    function selectAllProcesses() {
        setSelectedProcesses(props.processes.map((process:any) => process.id))
    }

    function selectNoProcesses() {
        setSelectedProcesses([])
    }

    function onChange(pagination:any, filters:any, sorter:any) {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      };
    
      const clearFilters = () => {
        setFilteredInfo({});
      };
    
      const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
      };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedProcesses(newSelectedRowKeys);
    };

    const rowSelection:any = {
        type: "checkbox",
        selectedRowKeys: selectedProcesses,
        onChange: onSelectChange,
    };

    function changeSelectedProcessesForDisplay() {
        // update the view
        let input = {
            // processesForDisplay expects a list of ids
            'processesForDisplay': selectedProcesses.map(process => process.toString()),
            'id': props.view_id
        }

        updateView({ variables: { input: input } , optimisticResponse: {
            __typename: "Mutation",
            updateProcessExplorerView: {
                __typename: "UpdateProcessExplorerViewPayload",
                processExplorerView: {
                    __typename: "ProcessExplorerViewType",
                    id: props.view_id,
                    processesForDisplay: selectedProcesses.map(process => ({
                        __typename: "ProcessType",
                        id: process
                    })),
                }
            }
        } });
    }


    return (
        <Modal
            title={"Select processes: " + selectedProcesses.length + " selected"}
            open={props.isOpen}
            onOk={
                () => {
                    changeSelectedProcessesForDisplay();
                    props.setOpen(false);
                }
            }
            onCancel={() => props.setOpen(false)}
            width={"80%"}
            
        >   
            <div style={{minHeight: height - 400}}>
                <Space direction="horizontal" style={{marginBottom: "15px"}}>
                    {/*
                    <Button 
                    >
                        Select table columns
                    </Button>
                    */}

                    {/*
                    <Button
                        onClick={clearFilters}
                    >
                        Clear all filters
                    </Button>
                    */}
                    

                    <Button
                        onClick={selectNoProcesses}
                        data-testid="clear-selection"
                    >
                        Clear selection
                    </Button>
                </Space>

                <Table
                    dataSource={props.processes}
                    columns={tableColumns}
                    size="small"
                    bordered
                    onChange={onChange}
                    pagination={false}
                    scroll={{ y: height - 400 , x: (parameterColumns.length + metadataColumns.length + 1) * 200}}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    

                    //clicking on a row should select the row
                    onRow={(record) => {
                        return {
                        onClick: () => {
                            const newSelectedProcesses = selectedProcesses.includes(record.id)
                            ? selectedProcesses.filter(id => id !== record.id)
                            : [...selectedProcesses, record.id];
            
                            setSelectedProcesses(newSelectedProcesses);
                        },
                        onMouseEnter: event => {
                            event.currentTarget.style.cursor = "pointer"
                        },
                        onMouseLeave: event => {
                            event.currentTarget.style.cursor = "default"
                        },
                        };
                        }
                    }
                />
            </div>

        </Modal>
    )
}

export default ProcessesAdvancedSelect;