import { useQuery, gql, useReactiveVar} from '@apollo/client';
import { Collapse , Skeleton , Card}  from 'antd';
import ProcessExplorerXaxis from './ProcessExplorerXaxis';
import ProcessExplorerPlotOptions from './ProcessExplorerPlotOptions'
import SelectProcesses from './SelectProcesses'
import { leftMenuOpenVar } from '../../Apollo';

const { Panel } = Collapse;

const GET_SELECTED_PROCESSES = gql`
    query selectedProcesses($analysisId:ID!) {
        selectedProcesses(analysisId:$analysisId) {
            id
            name
        }
        analysis(id:$analysisId) {
            id
            name
            uniqueVars
        }
    }
`;


interface ProcessExplorerMenuInputType {
    view: any
    analysis:any
    loading: boolean
    processes:any
    selectedProcesses:any
    setLoading: Function
}

function ProcessExplorerMenu(props:ProcessExplorerMenuInputType) {
    
    const leftMenuOpen = useReactiveVar(leftMenuOpenVar);
    const { loading:queryLoading2, error:queryError2, data:queryData2 } = useQuery(GET_SELECTED_PROCESSES , 
        {
            variables: { analysisId: props?.analysis?.id },
            fetchPolicy: 'cache-and-network',
        });


    if ( (queryLoading2 && !queryData2) || props.loading || props.view == null) {
        return (
            <div style={{  overflow:'auto' , width: '220px'}} data-testid="process-explorer-menu-loading">
                <Card  >
                    <Skeleton paragraph={{ rows: 22 }} active />
                </Card>
            </div>
        )
    }

    if (queryError2) {
        <div>Error: {queryError2.message} </div>
    }

    let nProcesses = 0;
    if (queryData2) {
        nProcesses = queryData2?.selectedProcesses.length
    }

    return (
         
        <div style={{  height:"calc(100vh - 130px)", overflow:'auto' , width: '220px'}} data-testid="process-explorer-menu" className='thin-scrollbar'>
            
            

            <Collapse 
                defaultActiveKey={leftMenuOpen} 
                onChange={(key:any) => {
                    leftMenuOpenVar(key)
                }}
                size="small" 
            >

                {/*
                <Panel header="Analysis" key="1">
                    <SelectAnalysis />
                </Panel>
                */}

                
                <Panel header={ "Analysis processes (" + nProcesses + ")"} key="1">
                
                    <SelectProcesses 
                        view={ props.view } 
                        displaySelectProcesses={true} 
                        processes={props.processes} 
                        analysis={props.analysis} 
                        loading={props.loading}
                    />
                    <div data-testid="number-of-selected-processes" style={{display:"none"}}>{nProcesses}</div>

                </Panel>
                
                <Panel header="X-axis" key="2">
                    <ProcessExplorerXaxis 
                        analysis={props.analysis}
                        uniqueVars = {queryData2?.analysis.uniqueVars}
                        processes = {props.processes}
                        selectedProcesses = {props.selectedProcesses}
                    />
                </Panel>

                <Panel header="Options" key="3">
                    <ProcessExplorerPlotOptions 
                        analysis={props.analysis} 
                    />
                </Panel>

                {/*
                <Panel header="KPIs" key="5">
                    
                </Panel>
                */}
                

            </Collapse>
        </div>
    )   
}

export default ProcessExplorerMenu;