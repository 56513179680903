import { useState } from 'react';
import { Card, Collapse, Steps, Skeleton} from 'antd';
import { useQuery, gql } from '@apollo/client';
import PageHeader from '../PageHeader';
import HeaderExtra from "../HeaderExtra";
import ReactGA from 'react-ga';
import useWindowDimensions from '../useWindowDimensions';
import KPITable from './KPITable';
import KPIViewTable from './KPIViewTable';

const { Panel } = Collapse;
const { Meta } = Card;
const { Step } = Steps;

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            selectedAnalysis {
                id
                name
                userPermissions
                processes {
                    id
                    name
                }
            }
        }
    }
`

function KPIs() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/kpis/');
    
    const { height, width } = useWindowDimensions();

    const { loading:queryLoading1, error:queryError1, data:queryData1 } = useQuery(GET_USER_PROFILE);

    const [activeTabKey, setActiveTabKey] = useState<string>('kpis');

    if (queryError1) return ( 
      <Card>Error: {queryError1.message} </Card>
    );

    if (queryLoading1) return (
        <Card><Skeleton active/></Card>
    );
      
    const tabList:any = [
      {
        key: 'kpis',
        tab: 'KPIs', 
      },
      {
        key: 'kpi_table',
        tab: 'KPI Table',
      }
    ];



    const contentList: Record<string, React.ReactNode> = {
      kpis: (
        <div>
          <KPITable analysis={queryData1.userProfile.selectedAnalysis}/>
        </div>
      ),
      
      kpi_table: (
        <div>
          <KPIViewTable analysis={queryData1.userProfile.selectedAnalysis} processes={queryData1.userProfile.selectedAnalysis.processes}/>
        </div>
      ),
    };

    const routes = [
      {
        path: '',
        breadcrumbName: "Analysis: " + queryData1?.userProfile.selectedAnalysis.name ,
      }
    ];


    return(  
      <div>

        <PageHeader
          title="KPIs"
          extra={<HeaderExtra/>}
          breadcrumb={{ routes: routes }}
        />

        <Card 
          style={{height:height-130, overflow:"auto"}}
          tabList={tabList} 
          onTabChange={key => {
            setActiveTabKey(key);
          }}
          activeTabKey={activeTabKey}
          
        >
          {contentList[activeTabKey]}
        </Card>
          

    </div>
    )
  }
  
  export default KPIs;
