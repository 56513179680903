import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card, Space, Popconfirm, Button, Form, Skeleton, Modal, message, Tooltip, Select, Input, Switch , AutoComplete} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import { pollInterval } from '../..';

const { Option } = Select;

const GET_TEAM_USERS = gql`
    query teamUsers($id:ID!) {
        teamUsers(id:$id) {
            id
            username
            email
            firstName
            lastName
            permissions
            isActive
            isAdmin
        }
        organizationUsers {
            id
            username
            firstName
            lastName
        }
    }
`;

const ADD_USER_TO_TEAM = gql`
    mutation addUserToTeam($input: AddUserToTeamInputType!) {
        addUserToTeam( input:$input) {
            ok
        }
    }
`;

const REMOVE_USER_FROM_TEAM = gql`
    mutation removeUserFromTeam($input: RemoveUserFromTeamInputType!) {
        removeUserFromTeam( input:$input) {
            ok
        }
    }
`;

const UPDATE_USER_TEAM_PERMISSIONS = gql`
    mutation updateUserTeamPermissions($input: UpdateUserTeamPermissionsInputType!) {
        updateUserTeamPermissions( input:$input) {
            ok
        }
    }
`;

const objectName = "Team member";

interface TeamUsersInputType {
    teamId: string
};

function TeamUsers(props:TeamUsersInputType) {

    // window dimensions
    const { height, width } = useWindowDimensions();

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { username:"", permissions:""} );

    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);

    const [modalTitel, setModalTitel] = useState('');
    const [modalId, setModalId] = useState<number>();
    const [isModalAdd, setModalAdd] = useState<boolean>(true);


    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery( GET_TEAM_USERS, { variables: { id: props.teamId } , 
        //pollInterval: pollInterval, 
    } );


    const [addMutation, { loading: addMutationLoading, error: addMutationError }] = useMutation(ADD_USER_TO_TEAM, {
        refetchQueries: [{ query:  GET_TEAM_USERS, variables: { id: props.teamId } }],
        onCompleted(data) { message.success(objectName + " was added successfully.") },
        onError(error) { message.error(error.message) },
    });


    const [removeMutation] = useMutation(REMOVE_USER_FROM_TEAM, {
        refetchQueries: [{ query:  GET_TEAM_USERS, variables: { id: props.teamId } }],
        onCompleted(data) { message.success(objectName + " was removed successfully.") },
        onError(error) { message.error(error.message) },
    });

    const [updateMutation] = useMutation(UPDATE_USER_TEAM_PERMISSIONS, {
        refetchQueries: [{ query:  GET_TEAM_USERS, variables: { id: props.teamId } }],
        onCompleted(data) { message.success(objectName + " was updated successfully.") },
        onError(error) { message.error(error.message) },
    });

    // ----------------------------------------------------------------------------------------

    const showModal = (values:any) => {
        setModalTitel("Add a team member")
        setModalVisible(true)
        setModalAdd(true)
    }
    
    const handleModalOk = (values: any) => {
    
        if (isModalAdd) {
    
            let input = {
                userName : values['username'], 
                teamId : props.teamId,
                permission : values['permissions'],
            };
                
            addMutation( { variables: { input: input  } } );        
            setModalVisible(false);
        
        } else {

            let input = {
                userId : modalId, 
                teamId : props.teamId,
                permission : values['permissions'],
            };
                
            updateMutation( { variables: { input: input  } } );        
            setModalVisible(false);
        }

        // reset
        setModalId(undefined)
        setModalAdd(true)
        setModalInitialValues( { username:"", permissions:""} )


    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    
    const handleRemove = (id:Number) => {   
        let input = {
            userId : id, 
            teamId : props.teamId,
        };
        
        removeMutation( { variables: { input: input  } } )
    } 

    const handleEdit = (id:number) => {
        setModalTitel("Edit team member permissions")
        setModalId(id)
        setModalAdd(false)
        setModalInitialValues( { username:queryData.teamUsers.find( (user:any) => user.id == id).username, permissions:queryData.teamUsers.find( (user:any) => user.id == id).permissions } )
        setModalVisible(true)
    }

    const onSearch = (searchText: string) => {

        var organizationUsers = []
        for (const user of queryData.organizationUsers) {
            organizationUsers.push( { 'key': user.id , 'value': user.firstName + ' ' +  user.lastName + ' (' + user.username + ')'} )
        }

        //setOptions(
          //!searchText ? [] : organizationUsers,
        //);
    }

    const formLayout = {
        labelCol: { span: 6 },
    };

    const tailLayout = {
        wrapperCol: { offset: 6, span: 12 },
    };


    const TableColumns = [
        {
            title: 'User Name',
            dataIndex: 'username',
            editable: false,
            width: '30%',
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            editable: false,
            width: '30%',
            render : (text:string, record:any) => (
                <span>{record.firstName} {record.lastName}</span>
            )
        },

        {
            title: 'Permissions',
            dataIndex: 'permissions',
            editable: false,
            width: '20%',
        },
        
        {
            title: 'Actions',
            key: 'actions',
            width: '20%',
            render: (text:String, record:any) => (
                <div>
                    <Space size={"small"}>
                        {/* Button for editing */}
                        <Button type="link" onClick={() => handleEdit(record.id)} data-testid={"edit-button-" + record.username}>Edit</Button>

                        <Popconfirm title="Are you sure you want to remove this user?" onConfirm={() => handleRemove(record.id)}>
                            <Button type="link" data-testid={"remove-button-" + record.username}>Remove</Button>
                        </Popconfirm>
                    </Space>
                </div>
            ),
        },
    ]

    if (queryLoading){
        return (
            <Card title="Team members">
                <Skeleton active/>
            </Card>
        )
    
    }
    if (queryError) return ( 
        <Card>Error: {queryError.message} </Card>
    );

    return (

        <div>
            <Card title="Team members">
                
                <Button
                    style = {{ marginBottom: 16 }}
                    onClick = {  showModal  } 
                    type = "primary"
                    shape='round'
                    icon={<PlusOutlined />}  
                    data-testid="add-team-member-button"                         
                >
                    Add a team member
                </Button>
                
                <Table 
                    loading={queryLoading}
                    dataSource={queryData.teamUsers}
                    columns={ TableColumns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    pagination={false}
                />

            </Card>
                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                footer={[
                    <Button type="primary" form="TeamUserForm" key="submit" htmlType="submit" data-testid="modal-submit-button">
                        Submit
                    </Button>
                    ]}
            >
                <br/>
                <Form
                    {...formLayout}  
                    id = "TeamUserForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <Form.Item label="User:" name="username" rules={[{ required: true, message: 'Please enter a username.' }]} >
                        
                        <AutoComplete
                            showSearch
                            style={{width:200}}
                            placeholder="Username"
                            optionFilterProp="children"
                            onSearch={onSearch}
                            disabled={!isModalAdd}
                            data-testid="auto-complete-username"
                            
                        >
                            {queryData.organizationUsers.map( (user:any) => { return <Option key={user.username} value={user.username}> {user.firstName + ' ' + user.lastName + ' (' + user.username + ')'}  </Option> })} 
        
                        </AutoComplete>

                    </Form.Item>

                    <Form.Item label="Permissions:" name="permissions" rules={[{ required: true, message: 'Please select a permission.' }]} >
                        <Select
                            style={{width:200}}
                            placeholder="Select a permission"
                            allowClear
                            defaultValue="read and write"
                            data-testid="select-permission"
                        >
                            <Option value="read">read</Option>
                            <Option value="read and write">read and write</Option>
                            { !isModalAdd && <Option value="owner">owner</Option>}
                        </Select>

                    </Form.Item>

                    </Form>

            </Modal>
        </div>

    );
}

export default TeamUsers;

