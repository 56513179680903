import { useState } from 'react';
import { Card, Input, Tabs, Skeleton, Collapse} from 'antd';
import PageHeader from '../components/PageHeader';
import {useParams} from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import ProcessTablePaginated from '../components/topics/ProcessTablePaginated'
import HeaderExtra from "../components/HeaderExtra";
import TopicFiles from '../components/topics/TopicFiles';
//import KPITable from "../components/kpi_analysis/KPITable";
//import KPIDefinitionTable from "../components/kpi_analysis/KPIDefinitionTable";
import useWindowDimensions from "../components/useWindowDimensions";
//import { UploadOutlined } from '@ant-design/icons';
//import CalculatorSetTable from '../components/calculators/CalculatorSetTable';
import DataStandardTable from '../components/topics/DataStandardTable';
//import CalculatorSaveTable from '../components/calculators/CalculatorSaveTable';
//import MediaTabs from '../components/media/MediaTabs';
import TopicSettings from '../components/topics/TopicSettings';

import ReactGA from 'react-ga';

import { CORE_TOPIC_FIELDS } from '../components/fragments';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const GET_TOPIC = gql`
    ${CORE_TOPIC_FIELDS}
    query Topic ($id:ID!) {  
        topicById(id:$id) {
            ...CoreTopicFields
        }

        userProfile {
            id
            admin
        }
    }
`;


function Topic() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    
    // get the url parameters from react router
    let params:any = useParams();

    const [selectedCalculatorSet, setSelectedCalculatorSet] = useState("")
    const [selectedKPIDefinition, setSelectedKPIDefinition] = useState("")
    const [selectedCalculatorSave, setSelectedCalculatorSave] = useState("")

    // window dimensions
    const { height, width } = useWindowDimensions();

    // apollo hooks
    const { loading, error, data } = useQuery(GET_TOPIC , {variables:{id:params.id}});
    //onst [updateProcess] = useMutation(UPDATE_PROCESS);
    
    //ReactGA.pageview('/topic/' + params.id );

    /* ************************************************************* */

    if (error) return ( 
        <Card>Error: {error.message} </Card>
    );

    if (loading) return (
        <Card><Skeleton active/></Card>
    );

 
    {/* ******************************************************************************** */}
    {/* ******************************************************************************** */}

    const routes = [

        {
            path: 'collections',
            breadcrumbName: "Team: " + data.topicById.team.name ,
        },
        {
          path: '',
          breadcrumbName: 'Collection: ' + data.topicById.name ,
        }
      ];

    const layout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 8 },
    };
    
    return(  

        <div>

            <PageHeader
                title={data.topicById.name} 
                subTitle={ data.topicById.description }
                breadcrumb={{routes}}
                extra={ <HeaderExtra/> }
            />
            
            <Card style={{height: height - 155}}>
                <Tabs defaultActiveKey="1" type="card">

                    {/*<TabPane tab="Dashboard" key="1">
                    </TabPane>
                    */}
                    
                    <TabPane tab={"Processes (" + data.topicById.numProcesses + ")"} key="1" data-testid="processes-tab">
                        <ProcessTablePaginated topicId={params.id}/>
                    </TabPane>

                    {/*
                    <TabPane tab="Calculators" key="2" style={{height:height-280 , overflow:"auto"}}>
                        <Collapse defaultActiveKey={['1','2']} >
                            <Panel header="Calculator sets" key="1">

                                <CalculatorSetTable
                                    topicId={params.id}
                                    selectedCalculatorSet={selectedCalculatorSet}
                                    setSelectedCalculatorSet={setSelectedCalculatorSet}
                                />

                            </Panel>

                            <Panel header="Calculator set calculators" key="2">

                                <CalculatorSaveTable
                                    topicId={params.id}
                                    calculatorSetId={selectedCalculatorSet}
                                    selectedCalculatorSave={selectedCalculatorSave}
                                    setSelectedCalculatorSave={setSelectedCalculatorSave}
                                />
                            </Panel>
                        </Collapse>

                    </TabPane>
                    */}

                    {/*
                    <TabPane tab="Media" key="5" style={{height:height-280 , overflow:"auto"}}>
                        <MediaTabs topicId={params.id}/>
                    </TabPane>
                    */}

                    {/*
                    <TabPane tab="KPIs" key="3" style={{height:height-280 , overflow:"auto"}}>

                        <Row>
                            <Col span={12}>
                                <Card title="KPI sets:" style={{height:500, overflow:"auto"}}>
                                    <KPIDefinitionTable topicId={params.id} setSelectedKPIDefinition={setSelectedKPIDefinition}/>
                                </Card>
                            </Col>
                            
                            <Col span={12}>
                                <Card title="KPIs:" style={{height:500, overflow:"auto"}}>
                                    <KPITable selectedKPIDefinition= {selectedKPIDefinition} />
                                </Card>
                            </Col>
                        </Row>

                    </TabPane>


                    <TabPane tab="Lexicon" key="4">

                    </TabPane>
                    */}

                    <TabPane tab="Lexicon" key="6" style={{height:height-280 , overflow:"auto"}} data-testid="lexicon-tab">
                        <DataStandardTable topicId={params.id} editable={data.topicById.team.userPermissions != "read"} />
                    </TabPane>

                
                    <TabPane tab="Files" key="8" style={{height:height-280 , overflow:"auto"}} data-testid="files-tab">
                        <TopicFiles topicId={params.id} />
                    </TabPane>
                    
                    
                    { /* only owner can see settings */}

                    { (data.topicById.userPermissions == "owner" || data.userProfile.admin == true) && 
                    <TabPane tab="Settings" key="7" style={{height:height-280 , overflow:"auto"}} data-testid="settings-tab">
                        <TopicSettings topicId={params.id} />
                    </TabPane>
                    }
                    
                    

                </Tabs>
            </Card>

        </div>
    )

}

export default Topic;