import React from "react";
import { useQuery, gql, useReactiveVar } from '@apollo/client';
import PageHeader from "../PageHeader";
import HeaderExtra from "../HeaderExtra";
import KPIAnalysisMenu from "./KPIAnalysisMenu";
import ProcessExplorerSidebar from "../process_explorer/ProcessExplorerSidebar";
import KPIAnalysisPlots from "./KPIAnalysisPlots";
import ViewPage from "../process_explorer/ViewPage";
import { processesClient, processesLoading, processesLoadingProgress } from '../../Apollo';


import { CORE_ANALYSIS_FIELDS, CORE_PROCESS_FIELDS, CORE_METADATA_FIELDS, EXTENDED_PROCESS_FIELDS } from "../fragments";
import { CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS, CORE_VIEW_FIELDS } from "../fragments";

//import ReactGA from 'react-ga';

const GET_CLIENT_VARS = gql`
    query ClientVars{
        processExplorerSidebarVisible @client
    }
`;

export const GET_USER_PROFILE = gql`

    ${CORE_ANALYSIS_FIELDS}
    ${CORE_PROCESS_FIELDS}
    ${CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS}
    ${CORE_VIEW_FIELDS}

    query userProfile {
        userProfile {
            id
            selectedAnalysis {
              ...CoreAnalysisFields
              
              uniqueVars
              uniqueParams
              uniqueMeta

              processes {
                id
                name
              }

              kpiAnalysisView {
                ...CoreViewFields

                viewpageSet {
                    ...CoreKpiAnalysisViewPageFields
                }
              }
            }
        }
    }
`

function KPIAnalysis() {

  //ReactGA.pageview('/kpi-analysis/');
  //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });

  const { data: clientVars } = useQuery(GET_CLIENT_VARS);
  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_USER_PROFILE,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [selectedSidebarProcess, setSelectedSidebarProcess] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const processesClientVar = useReactiveVar(processesClient);
  const processesLoadingVar = useReactiveVar(processesLoading);
  const processesLoadingProgressVar = useReactiveVar(processesLoadingProgress);

  if (queryError) return (
    <div>Error: {queryError.message} </div>
  );

  const routes = [
    {
      path: '',
      breadcrumbName: "Analysis: " + queryData?.userProfile?.selectedAnalysis?.name,
    }
  ];

  const processes = processesClientVar;

  //const processes = queryData1?.userProfile.selectedAnalysis.processes;

  return (
    <div>

      <PageHeader
        title="KPI Analysis"
        extra={<HeaderExtra />}
        breadcrumb={{ routes }}
      />

      <div style={{ display: 'flex' }}>

        <div style={{ flex: '0 0 220px' }}>

          <KPIAnalysisMenu
            selectedView={queryData?.userProfile?.selectedAnalysis?.kpiAnalysisView}
            loading={(queryLoading && !queryData) || loading || processesLoadingVar}
            setLoading={setLoading}
            processes={processes}
            analysis={queryData?.userProfile?.selectedAnalysis}
          />

        </div>

        <div style={{ overflow: 'auto', width: "100%" }} >

          <KPIAnalysisPlots
            view={queryData?.userProfile?.selectedAnalysis?.kpiAnalysisView}
            processes={processes}
            loading={(queryLoading && !queryData) || loading || processesLoadingVar}
            setSelectedSidebarProcess={setSelectedSidebarProcess}
            analysis={queryData?.userProfile?.selectedAnalysis}
          />

          <ViewPage
            analysis={queryData?.userProfile?.selectedAnalysis}
            pageName="KPIAnalysis"
            setUpdateViewLoading={setLoading}
            loading={(queryLoading && !queryData) || loading}
          />

        </div>

        {clientVars.processExplorerSidebarVisible &&
          <div style={{ flex: '0 0 350px' }}>
            <ProcessExplorerSidebar
              loading={(queryLoading && !queryData) || loading || processesLoadingVar}
              view={queryData?.userProfile.selectedAnalysis.kpiAnalysisView}
              processes={processes}
              selectedSidebarProcess={selectedSidebarProcess}
              setSelectedSidebarProcess={setSelectedSidebarProcess}
              analysis={queryData?.userProfile?.selectedAnalysis}
            />
          </div>
        }

      </div>
    </div>
  )
}

export default KPIAnalysis;