import {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Form, Skeleton, Modal, Input, message, Popover, Empty} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import MixtureActions from './MixtureActions';
import useWindowDimensions from '../useWindowDimensions';
import { CORE_COMPOUND_FIELDS, CORE_MIXTURE_FIELDS, CORE_UNDEFINED_INGREDIENT_FIELDS } from '../fragments';
import ImportMedia from './ImportMedia';
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;


const CREATE_MIXTURE = gql`
    ${CORE_MIXTURE_FIELDS}
    mutation createMixture($input: CreateMixtureInputType!) {
        createMixture( input:$input) {
            mixture {
                ...CoreMixtureFields
            }
        }
    }
`;

const UPDATE_MIXTURE = gql`
    ${CORE_MIXTURE_FIELDS}
    mutation updateMixture($input: UpdateMixtureInputType!) {
        updateMixture( input:$input) {
            mixture {
                ...CoreMixtureFields
            }
        }
    }
`;

const DELETE_MIXTURE = gql`
    mutation deleteMixture($id: ID!) {
        deleteMixture( id:$id) {
            id
            ok
        }
    }
`;

const READ_MIXTURES = gql`
    
    ${CORE_MIXTURE_FIELDS}
    ${CORE_COMPOUND_FIELDS}
    ${CORE_UNDEFINED_INGREDIENT_FIELDS}

    query mixtures ($topicId:ID!) {  
        mixtures(topicId:$topicId) {
            ...CoreMixtureFields
            topic {
                id
                compoundSet {
                    ...CoreCompoundFields
                }
                mixtureSet {
                    ...CoreMixtureFields
                }
                undefinedingredientSet {
                    ...CoreUndefinedIngredientFields
                }
            }
            mixtureactionSet {
                id
                order
                description
                compound {
                    ...CoreCompoundFields
                }
                undefinedIngredient {
                    ...CoreUndefinedIngredientFields
                }
                mixture {
                    ...CoreMixtureFields
                }
                amount
                amountUnits
            }
        }
        topicById(id:$topicId) {
            id
            userPermissions
        }
    }
`;


const READ_MEDIA = gql`
    query media ($topicId:ID!) {  
        media(topicId:$topicId) {
            id
            name
            description
            topic {
                id
                compoundSet {
                    id
                    name
                    formula
                    casNumber
                }
                mixtureSet {
                    id
                    name
                }
                undefinedingredientSet {
                    id
                    name
                    description
                }
            }
            mediaactionSet {
                id
                order
                description
                compound {
                    id
                    name
                    formula
                }
                undefinedIngredient {
                    id
                    name
                }
                mixture {
                    id
                    name
                }
                amount
                amountUnits
            }
        }
    }
`;


const CREATE = CREATE_MIXTURE;
const READ = READ_MIXTURES;
const UPDATE = UPDATE_MIXTURE;
const DELETE = DELETE_MIXTURE;

const objectName = "Mixture";

interface MixtureTableInputType {
    topicId: string
};

function MixtureTable(props:MixtureTableInputType) {


    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState("");
    const [selectedMixtureId, setSelectedMixtureId] = useState("");
    const { width, height } = useWindowDimensions();
    const [importModalVisible, setImportModalVisible] = useState(false);
    

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {
        variables:{topicId:props.topicId},
        //fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ 
            { query: READ, variables: { topicId: props.topicId } }, 
            { query: READ_MEDIA, variables: { topicId: props.topicId } }
        ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE , {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ], 
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ 
            { query: READ, variables: { topicId: props.topicId } }, 
            { query: READ_MEDIA, variables: { topicId: props.topicId } } 
        ], 
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------
    // if no topic is provided, return empty
    
    if (props.topicId == undefined || props.topicId == "") {
        return (
            <div>
                <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Please select a topic first."/>
            </div>
        )
    }

    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add mixture")
        setModalInitialValues({ name:"", description:"" } )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Add mixture") {
    
            let input = {
                name : values['name'], 
                description : values['description'],
                topicId:props.topicId
            };
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                description : values['description'],
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit mixture")
        
        setModalInitialValues({ 
            name:input.name,
            description:input.description 
        })
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
    } 

    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
    };

    const tailLayout = {
        wrapperCol: { offset: 3, span: 12 },
    };


    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
            width: '30%',
          },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '5%',

            render: (text:String, record:any) => (
                
                    <Popover content={(
                        <Space direction="vertical">
                        <Button type="link" onClick={()=> handleEdit(record)} disabled={ queryData.topicById.userPermissions == "read" } data-testid={"edit-mixture-" + record.name}>
                            Edit
                        </Button>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} disabled={ queryData.topicById.userPermissions == "read" }>
                            <Button type="link" disabled={ queryData.topicById.userPermissions == "read" } data-testid={"delete-mixture-" + record.name}>
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>
                    )}>
                    <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"mixture-actions-button-" + record.name}>
                        <EllipsisOutlined />
                    </Button>
                </Popover>

                
            ),
        },
    ]

    if (queryLoading){
        return (
            <Skeleton active/>
        )
    }
    if (queryError) {
        return (
            <p>Error: {queryError.message}</p>
        )
    }
    
    return (

        <div>
            
                <Button
                    type = "primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    style = {{ marginBottom: 16 }}
                    onClick = {  showModal  } 
                    data-testid = "add-mixture"
                    disabled = { queryData.topicById.userPermissions == "read" }
                    title = { queryData.topicById.userPermissions == "read" ? "You don't have permission to add a mixture." : "" }  
                                          
                >
                    Create a mixture
                </Button>

                <Button 
                    style = {{ marginBottom: 16, marginLeft: 10 }}
                    onClick = { () => setImportModalVisible(true) }
                    data-testid = "import-mixture"
                    disabled = { queryData.topicById.userPermissions == "read" }
                    title = { queryData.topicById.userPermissions == "read" ? "You don't have permission to import a mixture." : "" }
                >
                    Import
                </Button>
                
                <Table 
                    loading={queryLoading}
                    dataSource={queryData?.mixtures}
                    columns = { columns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    pagination={false}
                    scroll = {{ y: height - 550 }}
                />

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                data-testid = "mixture-modal"
                width={1000}
                footer={[
                    <Button type="primary" form="MixtureForm" key="submit" htmlType="submit" data-testid="submit-mixture">
                        Submit
                    </Button>
                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "MixtureForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <Input 
                            maxLength={30}
                            showCount
                            data-testid = "mixture-name-input"
                        />

                    </Form.Item>

                    <Form.Item label="Description:" name="description" rules={[{ required: false }]}>
                        <TextArea 
                            maxLength={1000} 
                            showCount 
                            rows={2}
                            data-testid = "mixture-description-input" 
                        />
                    </Form.Item>

                </Form>
                
                { modalTitel == "Edit mixture" && 
                    <MixtureActions mixture = { queryData.mixtures.find((mixture:any) => mixture.id == modalId) } />
                }
                
            </Modal>

            <ImportMedia open={importModalVisible} setOpen={setImportModalVisible} importType='mixture' targetTopicId={props.topicId} />

        </div>

    );
}


export default MixtureTable;

