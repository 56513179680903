import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Skeleton, message, Popover, Alert, Input} from 'antd';
import { LinkOutlined , SearchOutlined} from '@ant-design/icons';
import AddParameter from './calculators/AddParameter';
import useWindowDimensions from './useWindowDimensions';
import { pollInterval } from "..";

import { CORE_PARAMETER_FIELDS, CORE_PROCESS_FIELDS } from './fragments';

const DELETE_PARAMETER = gql`
    mutation deleteParameter($id: ID!) {
        deleteParameter( id:$id) {
            id
            ok
        }
    }
`;

const GET_PROCESS_PARAMETER = gql`
    ${CORE_PARAMETER_FIELDS}
    ${CORE_PROCESS_FIELDS}
    query process ($id:ID!) {  
        process(id:$id) {
            ...CoreProcessFields
            topic {
                id
                userPermissions
            }
            parameterSet {
                ...CoreParameterFields
            }
        }
    }
`;

const READ = GET_PROCESS_PARAMETER;
const DELETE = DELETE_PARAMETER;

const objectName = "Parameter";

interface ParameterTableInputType {
    processId:number | string,
    showDescription?:boolean
    tableHeightOffset?:number
};

function ParameterTable(props:ParameterTableInputType) {

    const { height, width } = useWindowDimensions();

    let tableHeightOffset = 370;
    if (props.tableHeightOffset != null) {
        tableHeightOffset = props.tableHeightOffset;
    }

    // ----------------------------------------------------------------------------------------
    // states

    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { scope:"process", name:"", value:0, units:"", description:"" } );
    const [modalMode, setModalMode] = useState("add");
    const [modalId, setModalId] = useState();
    const [isEditDisabled, setIsEditDisabled] = useState(false);

    const [nameSearchText, setNameSearchText] = useState("");
    const [descriptionSearchText, setDescriptionSearchText] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery( READ , {
        variables:{id:props.processId},
        //pollInterval: 3000,
        fetchPolicy: 'cache-and-network'
    } );

    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [{ query: READ, variables: { id: props.processId } }],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------
    
    
    const handleEdit = (input:any) => {

        setModalMode("edit")
        setModalInitialValues({ 
            scope:"process",
            name:input.name,
            value:input.value,
            units:input.units,
            description:input.description
        })
        
        setIsEditDisabled(input.calculator != null)
        setModalId(input.id)
        setIsAddEditModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
    }

    const handleSearch = (selectedKeys:any, confirm:any) => {
        confirm();
        setNameSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        setNameSearchText('');
    };

    const handleSearchDescription = (selectedKeys:any, confirm:any) => {
        confirm();
        setDescriptionSearchText(selectedKeys[0]);
    };

    const handleResetDescription = (clearFilters:any) => {
        clearFilters();
        setDescriptionSearchText('');
    };

    // return a list of unique units
    const getUnitFilters = () => {
        let uniqueUnits:any = []
        let unitFilters:any = []
        queryData?.process.parameterSet.forEach((parameter:any) => {
            if (parameter.units != null) {
                if (!uniqueUnits.includes(parameter.units)) {
                    uniqueUnits.push(parameter.units)
                    unitFilters.push( {text: parameter.units, value: parameter.units} )
                }
            }
        })
        // sort unitFilters alphabetically without considering the case

        return unitFilters.sort((a:any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
    }

    // for filters generate a list of unique calculator names
    const getCalculatorFilters = () => {
        let uniqueCalculators:any = []
        let calculatorFilters:any = []
        queryData?.process.parameterSet.forEach((parameter:any) => {
            if (parameter.calculator != null) {
                if (!uniqueCalculators.includes(parameter.calculator.name)) {
                    uniqueCalculators.push(parameter.calculator.name)
                    calculatorFilters.push( {text: parameter.calculator.name, value: parameter.calculator.name} )
                }
            }
        })
        // sort calculatorFilters alphabetically without considering the case
        return calculatorFilters.sort((a:any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
    }


    // if props.tableColumns is not set, use default columns
    let columns = [
        {
            title: 'Name',
            //dataIndex: 'name',
            //width: '20%',
            render: (text:any, record:any) => (
                // link only for parameters that are not calculated
                <span>{record.name}</span>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="parameter-name-search"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="parameter-name-search-ok"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleReset(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="parameter-name-search-reset"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
          },
        {
            title: 'Value',
            //dataIndex: 'value',
            render: (text:any, record:any) => {
                // if the value has any decimal places, round it to 3 decimal places
                if (record.value % 1 != 0) {
                    return record.value.toFixed(3);
                } else {
                    return record.value;
                }
            }

        },
        {
            title: 'Units',
            dataIndex: 'units',
            filters: getUnitFilters(),
            onFilter: (value: any, record: any) =>  record.units == value,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search description"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchDescription(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="parameter-description-search"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchDescription(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="parameter-description-search-ok"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleResetDescription(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="parameter-description-search-reset"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value: any, record: any) => {
                if (record.description == null) {
                    return false
                }
                return record.description.toLowerCase().includes(value.toLowerCase())
            },
        },
        {
            title: 'Type',
            editable: false,
            render: (text:any, record:any) => {
                if (record.calculator == null) {
                    return "Measured";
                } else {
                    return "Calculated";
                }
            },
            filters: [
                {
                    text: 'Calculated',
                    value: 'Calculated',
                },
                {
                    text: 'Measured',
                    value: 'Measured',
                },
            ],
            onFilter: (value: any, record: any) =>  {
                if (record.calculator == null) {
                    return "Measured" == value;
                } else {
                    return "Calculated" == value;
                }
            }
        },
        {
            title: 'Calculator',
            dataIndex: ['calculator', 'name'],
            editable: false,
            //width: '20%',
            
            filters: getCalculatorFilters(),
            onFilter: (value: any, record: any) =>  record?.calculator?.name == value,
            
        },
        {
            title: 'Lexicon',
            name: 'Lexicon',
            key: 'lexicon',
            width: "5%",
            render : (text:any, record:any) => {
                if (record.dataStandard == null) {
                    return null
                } else {

                    let level = record.dataStandard.level
                    if (record.dataStandard.level == "Topic") {
                        level = "Collection"
                    }

                    return (
                        <Popover content={(
                            <Space direction="vertical">
                                <p>{record.dataStandard.name} ({level})</p>
                            </Space>
                        )}>
                            <Button size="small" type="link" style={{paddingLeft:20 }} data-testid={"parameter-lexicon-button-" + record.name} >
                                <LinkOutlined />
                            </Button>
                        </Popover>
                    )
                }
            },
            filters: [
                {
                    text: 'Lexicon',
                    value: 'Lexicon',
                },
            ],
            onFilter: (value: any, record: any) =>  {
                if (record.dataStandard == null) {
                    return false
                } else {
                    return true
                }
            }
        },
        /*  
        {
            title: 'Actions',
            key: 'actions',
            //fixed: 'right',
            width: "5%",
            render: (text:String, record:any) => (

                // if the parameter is a calculator parameter, don't show the delete button
                record.calculator == null ?
                
                <Popover content={(
                    <Space direction="vertical">
                    <Button type="link" onClick={()=> handleEdit(record)} data-testid="edit-parameter">
                        Edit
                    </Button>
                    <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                        <Button type="link" data-testid="delete-parameter">Delete</Button>
                    </Popconfirm>
                </Space>
                )}>
                <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"parameter-actions-button-" + record.name} >
                    <EllipsisOutlined />
                </Button>
            </Popover>
            :
            <Popover 
                content={(
                    <p>Cannot modify or delete a calculator parameter directly.</p>
                )}>
                <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"parameter-actions-button-" + record.name} >
                    <EllipsisOutlined />
                </Button>
            </Popover>
            

            
        ),
        },
        */
    ]

    // remove description column
    if (props.showDescription == false) {
            columns.splice(3,1)
            columns.splice(3,1)
            columns.splice(3,1)
            columns.splice(3,1)
    }

    // if props.tableColumns is set, use it to return columns which have the same dataindex as the props.tableColumns

            
    {/*
    if (queryLoading){
        return (
            <Skeleton active />
        )
    }*/}

    if (queryError) {
        return (
            <Alert
                message="Error"
                description={queryError.message}
                type="error"
                showIcon
            />  
        )
    }

    return (

        <div>
            <AddParameter
                processId={props.processId}
                topicId = {queryData?.process.topic.id}
                parameterId = {modalId}

                isModalVisible={isAddEditModalVisible}
                setIsModalVisible={setIsAddEditModalVisible}
                modalInitialValues={modalInitialValues}
                setModalInitialValues={setModalInitialValues}
                mode = {modalMode}
                setMode = {setModalMode}
                // disable the edit if the parameter has a calculator
                editDisabled = {isEditDisabled}
                setEditDisabled = {setIsEditDisabled}
                userPermissions={queryData?.process.topic.userPermissions}
                
            />
            <Table 
                loading={queryLoading && !queryData}
                dataSource={queryData?.process?.parameterSet}
                columns={ columns } 
                bordered
                rowKey={record => record.id} 
                size="small"
                pagination={false}
                scroll={{ y: height - tableHeightOffset }}
                onRow={(record, rowIndex) => {
                    return {
                      
                        onClick: event => {handleEdit(record)}, // click row

                      // change the mouse cursor to pointer when hovering over the row
                      onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                      onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                    };
                  }
                }
                
                
            />
        </div>
    )

}

export default ParameterTable;

