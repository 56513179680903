import React, {useState} from 'react';
import { useQuery, useMutation, useLazyQuery, gql } from '@apollo/client';
import { Table, Space, Button, Form, Modal, Input, message, Alert, Skeleton} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {ExperimentOutlined, FieldStringOutlined , SearchOutlined} from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import DeleteModal from '../DeleteModal';
import { processesClient, processExplorerSelectedViewPageVar, selectedAnalysis, globalSelectedTopic } from '../../Apollo';
import { useNavigate } from 'react-router-dom';
import CopyAnalysis from './CopyAnalysis';
//import { pollInterval } from "../..";
import { CORE_ANALYSIS_FIELDS , EXTENDED_PROCESS_FIELDS, CORE_USER_PROFILE_FIELDS} from '../fragments';

//import { createOptimisticResponse } from '../createOptimisticResponse';
//import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';



const { TextArea } = Input;

const CREATE_ANALYSIS = gql`
    mutation createAnalysis ($input: CreateAnalysisInputType!) {
        createAnalysis (input:$input) {
            analysis {
                id
                name
                description
                owner {
                    id
                    username
                }
                team {
                    id
                    name
                }
            }
        }
    }
`;

const UPDATE_ANALYSIS = gql`
    mutation updateAnalysis ($input: UpdateAnalysisInputType!) {
        updateAnalysis (input:$input) {
            analysis {
                id
                name
                description
                owner {
                    id
                    username
                }
                team {
                    id
                    name
                }
            }
        }
    }
`;

const DELETE_ANALYSIS = gql`
    mutation deleteAnalysis($id: ID!) {
        deleteAnalysis( id:$id) {
            id
            ok
        }
    }
`;

export const GET_ANALYSES = gql`
    query analyses ($teamId: ID!) {
        analyses (teamId:$teamId) {
            id
            name
            description
            nProcesses
            kpiSet {
                id
            }
            statmodelSet {
                id
            }
            owner {
                id
                username
            }
            team {
                id
                name
            }
        }
        userProfile {
            id
            selectedAnalysis {
                id
                processes {
                    id
                }
                kpiAnalysisView {
                    id 
                }
                processExplorerView {
                    id
                }
            }
        }
    }
`;

const UPDATE_USER_PROFILE = gql`
    mutation updateUserProfile($input: UpdateUserProfileInputType!) {
        updateUserProfile( input:$input) {
            userProfile {
                id
                selectedAnalysis{
                    id
                    name
                    processes {
                        id
                        lastUpdated
                    }
                }
            }
        }
    }
`;

const CREATE = CREATE_ANALYSIS;
const READ = GET_ANALYSES;
const UPDATE = UPDATE_ANALYSIS;
const DELETE = DELETE_ANALYSIS;

const objectName = "Analysis";

interface AnalysisTableInputType {
    //tableColumns?:any
    teamId:number
    analysisType?:string
};

// default value for AnalysisTableInputType
AnalysisTable.defaultProps = {
    analysisType: "team"
}


function AnalysisTable(props:AnalysisTableInputType) {

    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();
    
    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [copyModalOpen, setCopyModalOpen] = useState(false);

    const [nameSearchText, setNameSearchText] = useState("");
    const [descriptionSearchText, setDescriptionSearchText] = useState("");
    
    //const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {
        variables:{teamId:props.teamId}, 
        fetchPolicy: "cache-and-network",
        // pollInterval: 10000,
        //notifyOnNetworkStatusChange: false,
    });

    
    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ, variables: { teamId: props.teamId } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE , {
        refetchQueries: [ { query: READ, variables: { teamId: props.teamId } } ],
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ, variables: { teamId: props.teamId }}],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
  
    const [updateUserProfile, { loading: updateUserProfileLoading, error: updateUserProfileError }] = useMutation(UPDATE_USER_PROFILE, {
        refetchQueries: [ { query: READ, variables: { teamId: props.teamId } } ],
        onError: (error) => {
            message.error(error.message);
        },
        onCompleted: (data) => {
            message.success("Selected analysis updated");

            //console.log("Current analysis: ", data.updateUserProfile.userProfile.selectedAnalysis.id)
            selectedAnalysis(data.updateUserProfile.userProfile.selectedAnalysis.id)

            // clear the existing process cache
            //processesClient([])

            // navigate to the Process Explorer
            //navigate("/process-explorer");

        }
    });

    // ----------------------------------------------------------------------------------------
    // functions

    const showModal = (values:any) => {
        setModalTitel("Add")
        setModalInitialValues({ name:"", description:""} )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
        let input:any = {}
        // adding
        if (modalTitel == "Add") {

            if (props.analysisType == "team") {
                input = {
                    name : values['name'], 
                    description : values['description'],
                    teamId: props.teamId,
                };
            } else {
                input = {
                    name : values['name'],
                    description : values['description'],
                };
            }
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
            

            input = {
                id: modalId,
                name : values['name'], 
                description : values['description'],
            };
        
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    function handleEdit() {
        setModalTitel("Edit")
        setModalVisible(true)
    }
    
    function handleCopy() {
        setCopyModalOpen(true )
    }
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
        setModalVisible(false);
    } 


    const handleSearch = (selectedKeys:any, confirm:any) => {
        confirm();
        setNameSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        setNameSearchText('');
    };

    const handleSearchDescription = (selectedKeys:any, confirm:any) => {
        confirm();
        setDescriptionSearchText(selectedKeys[0]);
    };

    const handleResetDescription = (clearFilters:any) => {
        clearFilters();
        setDescriptionSearchText('');
    };

    function loadSelectedAnalysis() {
        // perform the mutation to update the selected analysis in the user profile
        let selectedAnalysisId = selectedRowKeys[0]

        // if the selected analysis is the same as the current analysis, then do nothing
        if (selectedAnalysisId == queryData.userProfile.selectedAnalysis.id) {
            message.info("The selected analysis is already loaded")
            return
        }

        // set the processExplorerSelectedViewPageVar to ""
        processExplorerSelectedViewPageVar("")
        
        //console.log(selectedAnalysisId)
        updateUserProfile({ variables: { input: { selectedAnalysisId: selectedAnalysisId } },
            /*
            optimisticResponse: {
                __typename: "Mutation",
                updateUserProfile: {
                    __typename: "UpdateUserProfilePayload",
                    userProfile: {
                        __typename: "UserProfileType",
                        id: queryData.userProfile.id,
                        selectedAnalysis: {
                            __typename: "AnalysisType",
                            id: selectedAnalysisId
                            
                        }
                    }
                }
            }*/
        });

        //console.log("New analysis selected: ", selectedAnalysisId)
        
        // clear the existing process cache
        //processesClient([])

        // navigate to the Process Explorer
        //navigate("/process-explorer");

    }

    const formLayout = {
        labelCol: { span: 5 },
    };

    let columns = [
        {
            title: 'Name',
            //dataIndex: 'name',
            editable: true,
            width: '20%',
            render: (text:any, record:any) => (
                <div title={record.id}>{record.name}</div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="name-search-input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="name-search-ok-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleReset(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="name-search-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '60%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search description"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchDescription(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="description-search-input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchDescription(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="description-search-ok-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleResetDescription(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="description-search-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                if (record.description == null) {
                    return false
                }
                return record.description.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Processes',
            dataIndex: 'nProcesses',
            width: '10%',
        },
        {
            title: 'KPIs',
            width: '10%',
            render : (text:any, record:any) => (
                <div>{record.kpiSet.length}</div>
            )
        },
        {
            title: 'Models',
            width: '10%',
            render : (text:any, record:any) => (
                <div>{record.statmodelSet.length}</div>
            )
        }

        /*{
            title: 'Owner',
            dataIndex: ['owner', 'username'],
            width: '10%',
        }*/
    ]

    if (queryLoading && !queryData){
        return (
            <div><Skeleton active /></div>
        )
    }

    if (queryError) {
        (
            <Alert
                message="Error"
                description={queryError.message}
                type="error"
                showIcon
            />  
        )
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection:any = {
        type: "radio",
        selectedRowKeys,
        onChange: onSelectChange,
        
    };

    return (

        <div>

                <Button 
                    type="primary"
                    shape="round" 
                    icon={<PlusOutlined />}
                    onClick = {  showModal  }
                    data-testid = "add-analysis-button"
                    style={{marginBottom:20}}
                >
                    Create a new analysis
                </Button>

                <Button 
                    type="default"
                    //icon={<PlusOutlined />}
                    onClick = {  loadSelectedAnalysis }
                    data-testid = "load-analysis-button"
                    style={{marginBottom:20, marginLeft:10}}
                    // enable if there is one row selected
                    disabled={selectedRowKeys.length == 0}
                >
                    Select analysis
                </Button>

                <Button
                    type="default"
                    onClick={ handleEdit }
                    data-testid="edit-analysis-button"
                    style={{ marginBottom: 20, marginLeft: 10 }}
                    // enable if there is one row selected
                    disabled={selectedRowKeys.length == 0}
                >
                    Edit
                </Button>

                
                <Button
                    type="default"
                    onClick={ handleCopy }
                    data-testid="copy-analysis-button"
                    style={{ marginBottom: 20, marginLeft: 10 }}
                    // enable if there is one row selected
                    disabled={selectedRowKeys.length == 0}
                >
                    Copy
                </Button>
                

                <Button 
                    type="default" 
                    data-testid="delete-analysis-button" 
                    title="Delete the analysis" 
                    onClick={() => setOpenDeleteModal(true)}
                    style={{ marginBottom: 20, marginLeft: 10 }}
                    disabled={selectedRowKeys.length == 0}
                >
                    Delete
                </Button>
                
                <div style={{marginBottom:20 , marginRight:10}}>
                    <Table 
                        loading={queryLoading && !queryData}
                        // if analysisType is team, then show only the analyses that belong to the team i.e. team is not null
                        // if analysisType is personal, then show only the analysis where team is null
                        
                        dataSource={queryData?.analyses.filter((analysis:any) => {
                            if (props.analysisType == "team") {
                                return analysis.team != null
                            } else {
                                return analysis.team == null
                            }
                        })}

                        //dataSource={queryData.analyses}
                        scroll={{ y: height - 650 }}
                        columns = { columns } 
                        bordered
                        rowKey={record => record.id}
                        rowSelection={rowSelection}
                        size="small"
                        pagination={false}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    // set the initial values of the modal
                                    setModalId(record.id)
                                    setModalInitialValues({
                                        name:record.name,
                                        description:record.description,
                                    })

                                    // Toggle selection for radio type
                                    const newSelectedRowKeys:any = selectedRowKeys.includes(record.id)
                                    ? []
                                    : [record.id];
                            
                                    setSelectedRowKeys(newSelectedRowKeys);

                                }, // click row
                                // change the mouse cursor to pointer when hovering over the row
                                onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                                onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                            };
                        }}
                    />
                </div>

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                data-testid = "analysis-modal"
                width={600}
                footer={[
                    <Button type="primary" form="AnalysisForm" key="submit" htmlType="submit" data-testid="submit-analysis-button">
                        Submit
                    </Button>
                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "AnalysisForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <Form.Item 
                            label="Name:" 
                            name="name" 
                            rules={[
                                { required: true, message: 'Please enter a name.' }, 
                                { max: 200, message: 'Name must be 200 characters or less.' },
                                //{ pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/, message: 'Name must start with a letter or underscore and can only contain letters, numbers, and underscores.' }
                            ]} 
                            validateFirst={true}
                            validateTrigger={"onBlur"}
                        >
                        <Input 
                            maxLength={200}
                            showCount
                            data-testid = "analysis-name-input"
                        />

                    </Form.Item>

                    <Form.Item
                        label="Description:"
                        name="description"
                        rules={[
                            { max: 10000, message: 'Description must be 10000 characters or less.' },
                        ]}
                    >
                        <TextArea
                            rows={6}
                            maxLength={10000}
                            showCount
                            data-testid = "analysis-description-input"
                        />
                    </Form.Item>


                </Form>

            </Modal>
            
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                objectName={objectName}
                onOk={() => handleDelete(modalId)}
            />

            <CopyAnalysis
                open={copyModalOpen}
                setOpen={setCopyModalOpen}
                selectedAnalysis={ queryData.analyses.find((analysis:any) => analysis.id == selectedRowKeys[0]) }
            />

            <Modal 
                footer = {null}
                closable = {false}
                open = {updateUserProfileLoading}
                title="Loading analysis..."
            >
                <Skeleton active/>    
            </Modal>

        </div>

    );
    

}

export default AnalysisTable;

