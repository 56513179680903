import React, {useState, useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Form, Skeleton, Modal, Input, message, Popover, Radio, Empty} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import ImportMedia from './ImportMedia';

const { TextArea } = Input;

const CREATE_COMPOUND = gql`
    mutation createCompound($input: CreateCompoundInputType!) {
        createCompound( input:$input) {
            compound {
                id
                name
                formula
                casNumber
                molecularWeight
            }
        }
    }
`;

const UPDATE_COMPOUND = gql`
    mutation updateCompound($input: UpdateCompoundInputType!) {
        updateCompound( input:$input) {
            compound {
                id
                name
                formula
                casNumber
                molecularWeight
                isMolecule
                compoundmoleculeSet {
                    id
                    molecule {
                        id
                        name
                        formula
                    }
                    stoichiometry
                }
            }
        }
    }
`;

const DELETE_COMPOUND = gql`
    mutation deleteCompound($id: ID!) {
        deleteCompound( id:$id) {
            id
            ok
        }
    }
`;

const READ_COMPOUNDS = gql`
    query compounds ($topicId:ID!) {  
        compounds(topicId:$topicId) {
            id
            name
            formula
            casNumber
            molecularWeight
            isMolecule
            compoundmoleculeSet {
                id
                molecule {
                    id
                    name
                    formula
                }
                stoichiometry
            }
        }
        topicById(id:$topicId) {
            id
            userPermissions
        }
    }
`;

const GET_ALL_TOPIC_COMPOUNDS = gql`
    query allTopicCompounds {
        allTopicCompounds {
            id
            name
            formula
            casNumber
            molecularWeight
            isMolecule
            
            topic {
                id
                name
                team {
                    id
                    name
                }
            }
        }
    }
`;

const IMPORT_COMPOUND = gql`
    mutation importCompound($input: ImportCompoundInputType!) {
        importCompound( input:$input) {
            compound {
                id
                name
                formula
                casNumber
                molecularWeight
            }
        }
    }
`;


const READ_MEDIA = gql`
    query media ($topicId:ID!) {  
        media(topicId:$topicId) {
            id
            name
            description
            topic {
                id
                compoundSet {
                    id
                    name
                    formula
                    casNumber
                    molecularWeight
                    isMolecule
                    
                }
                mixtureSet {
                    id
                    name
                }
                undefinedingredientSet {
                    id
                    name
                    description
                }
            }
            mediaactionSet {
                id
                order
                description
                compound {
                    id
                    name
                    formula
                }
                undefinedIngredient {
                    id
                    name
                }
                mixture {
                    id
                    name
                }
                amount
                amountUnits
            }
        }
    }
`;


const CREATE = CREATE_COMPOUND;
const READ = READ_COMPOUNDS;
const UPDATE = UPDATE_COMPOUND;
const DELETE = DELETE_COMPOUND;

const objectName = "Compound";

interface CompoundTableInputType {
    topicId: string
};

function CompoundTable(props:CompoundTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", formula:"", casNumber:""} );
    const [moleculeModalInitialValues, setMoleculeModalInitialValues] = useState( { name:"", formula:"", stoichiometry:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState("");
    const [isImportCompound, setImportCompound] = useState(0);
    const [importCompoundSearch, setImportCompoundSearch] = useState("");
    const [importCompoundId, setImportCompoundId] = useState("");
    const [molecules, setMolecules] = useState<any>([]);
    const [isMoleculeModalVisible, setMoleculeModalVisible] = useState(false);
    const {height, width} = useWindowDimensions()
    const [importModalVisible, setImportModalVisible] = useState(false);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {
        variables:{topicId:props.topicId},
        //fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });
    const { loading:allTopicCompoundsLoading, error: allTopicCompoundsError, data: allTopicCompoundsData } = useQuery(GET_ALL_TOPIC_COMPOUNDS);

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ 
            { query: READ, variables: { topicId: props.topicId } },
            { query: READ_MEDIA, variables: { topicId: props.topicId } } 
        ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError } ] = useMutation(UPDATE, {
        refetchQueries: [ 
            { query: READ, variables: { topicId: props.topicId } } ,
            { query: READ_MEDIA, variables: { topicId: props.topicId } }
        ], 
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation, { loading: deleteMutationLoading, error: deleteMutationError } ] = useMutation(DELETE, {
        refetchQueries: [ 
            { query: READ, variables: { topicId: props.topicId } },
            { query: READ_MEDIA, variables: { topicId: props.topicId } }
        ], 
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    const [importMutation, { loading: importMutationLoading, error: importMutationError } ] = useMutation(IMPORT_COMPOUND, {
        refetchQueries: [ 
            { query: READ, variables: { topicId: props.topicId } },
            { query: READ_MEDIA, variables: { topicId: props.topicId } }
         ],
        onCompleted(data) { message.success( objectName + " was imported successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    // ----------------------------------------------------------------------------------------
    // use effect to set the value of molecules using the compoundmoleculeSet of the compound when the edit button of compound is clicked
    /*
    useEffect(() => {
        if (queryData?.compounds != undefined) {
            queryData?.compounds.map((compound:any) => {
                if (compound.isMolecule == stubFalse) {
                    setMolecules(compound.compoundmoleculeSet)
                }
            })
        }
    }, [queryData])
    */
    
    // ----------------------------------------------------------------------------------------
    // if no topic is provided, return empty
    
    if (props.topicId == undefined || props.topicId == "") {
        return (
            <div>
                <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Please select a topic first."/>
            </div>
        )
    }
    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add compound");
        setModalInitialValues({ name:"", formula:"", casNumber:"" });
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Add compound") {

            if (isImportCompound == 0) {
    
                let input = {
                    name : values['name'], 
                    formula : values['formula'],
                    casNumber: values['casNumber'],
                    topicId : props.topicId,
                    isMolecule: false,
                    molecules: molecules,
                    
                };
                
                createMutation( { variables: { input: input  } } );
            } else {
                let input = {
                    id : importCompoundId,
                    topicId : props.topicId,
                    
                };
                
                importMutation( { variables: { input: input  } } );
            }

            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                formula : values['formula'],
                casNumber: values['casNumber'],
                isMolecule: false,
                molecules: molecules,
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
        setMolecules([]);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
        setMolecules([]);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit compound")
        setImportCompound(0);
        
        setModalInitialValues({ 
            name:input.name,
            formula:input.formula, 
            casNumber:input.casNumber,
        })
        
        setModalId(input.id)

        // set the molecules using the compoundmoleculeSet of the compound
        // loop through molecules of the compound and add them to the molecules state

        let compound = queryData?.compounds.find((compound:any) => compound.id == input.id)

        let moleculeList:any = []
        for (let molecule of compound.compoundmoleculeSet) {
            moleculeList.push({name: molecule.molecule.name, formula: molecule.molecule.formula, stoichiometry: molecule.stoichiometry})
        }
        setMolecules(moleculeList)


        setModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
    } 

    function onChangeImportCompound(input:any) {
        setImportCompound(input.target.value);
    }

    function handleImportCompoundsSearch(input:any) {
        setImportCompoundSearch(input.target.value);
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setImportCompoundId(selectedRows[0].id)
        }
    };

    function handleMoleculeModalOk(values:any) {

        // remove any empty lines
        let valuesCopy = values
        for (let key in valuesCopy) {
            if (valuesCopy[key] == "") {
                delete valuesCopy[key]
            }
        }

        // add the molecule to the list of molecules in the state
        setMolecules([...molecules, valuesCopy])
        setMoleculeModalVisible(false);

    }

    function handleMoleculeModalCancel(values:any) {
        setMoleculeModalVisible(false);
        
    }


    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 3, span: 12 },
    };

    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            width: '30%',
          },
        {
            title: 'Formula',
            dataIndex: 'formula',
            editable: false,
        },
        {
            title: 'Cas number',
            dataIndex: 'casNumber',
            editable: false,
        },
        {
            title: 'Molecular Weight [g/mol]',
            //dataIndex: 'molecularWeight',
            editable: false,
            render: (text: any, record: any) => (
                <span>
                    {record?.molecularWeight?.toFixed(2)}
                </span>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '5%',

            render: (text:String, record:any) => (
                
                    <Popover content={(
                        <Space direction="vertical">
                        <Button type="link" onClick={()=> handleEdit(record)} disabled={queryData.topicById.userPermissions == "read"} data-testid={"edit-compound-" + record.name}>
                            Edit
                        </Button>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} disabled={queryData.topicById.userPermissions == "read"} >
                            <Button type="link" disabled={queryData.topicById.userPermissions=="read"} data-testid={"delete-compound-" + record.name}>Delete</Button>
                        </Popconfirm>
                    </Space>
                    )}>
                    <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"compound-actions-button-" + record.name}>
                        <EllipsisOutlined />
                    </Button>
                </Popover>
            ),
        },
    ]

    const importCompoundColumns = [

        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            width: '40%',
          },
        {
            title: 'Formula',
            dataIndex: 'formula',
            editable: false,
            width: '40%',
        },
        {
            title: 'Cas number',
            dataIndex: 'casNumber',
            editable: false,
            width: '20%',
        },
    ]

    const moleculeColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
          },
        {
            title: 'Formula',
            dataIndex: 'formula',
            editable: false,
        },
        {
            title: 'Stoichiometry',
            dataIndex: 'stoichiometry',
            editable: false,
        }, 
        {
            title: 'Actions',
            key: 'actions',

            render: (text:String, record:any) => (
                <Space size="middle">
                    <Button
                        type="link" 
                        data-testid={"delete-molecule-" + record.name}
                        onClick={() => setMolecules(molecules.filter((molecule:any) => molecule.name != record.name))}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ]

    if (queryError) {
        return <div>Error loading compounds.</div>
    }

    if (allTopicCompoundsError) {
        return <div>Error loading compounds.</div>
    }

    if (queryLoading || allTopicCompoundsLoading){
    return (
        <Skeleton active/>
    )}

    // create the datasource for the table that contains compounds to be imported
    let importCompounds = allTopicCompoundsData?.allTopicCompounds.filter((compound:any) => compound?.topic == null || compound.topic.id != props.topicId)
    // filter the compounds to be imported based on the search input by name, formula, and cas number
    if (importCompoundSearch != "") {
        importCompounds = importCompounds?.filter((compound:any) => compound.name.toLowerCase().includes(importCompoundSearch.toLowerCase()) || compound.formula.toLowerCase().includes(importCompoundSearch.toLowerCase()) || compound.casNumber.toLowerCase().includes(importCompoundSearch.toLowerCase()))
    }

    // sort the compounds by name
    importCompounds = importCompounds?.sort((a:any, b:any) => a.name.localeCompare(b.name))
    // remove the duplicates
    importCompounds = importCompounds?.filter((compound:any, index:any, self:any) => index === self.findIndex((t:any) => (t.name === compound.name && t.formula === compound.formula && t.casNumber === compound.casNumber)))

    // TODO: remove the compounds that are already in the topic
    
    return (

        <div>
                <Space direction="horizontal" style={{ width: '100%' }}>
                    <Button
                        type = "primary"
                        shape="round"
                        icon={<PlusOutlined />}
                        style = {{ marginBottom: 16 }}
                        onClick = {  showModal  } 
                        data-testid = "add-compound"
                        disabled = { queryData.topicById.userPermissions == "read"}
                        title = { queryData.topicById.userPermissions == "read" ? "You do not have permission to create a compounds." : "" }                       
                    >
                        Create a compound
                    </Button>

                    <Button 
                        style = {{ marginBottom: 16 }}
                        onClick = { () => setImportModalVisible(true) }
                        data-testid = "import-compound"
                        disabled = { queryData.topicById.userPermissions == "read"}
                        title = { queryData.topicById.userPermissions == "read" ? "You do not have permission to import compounds." : "" }
                    >
                        Import
                    </Button>

                </Space>
                
                <Table 
                    loading={queryLoading}
                    dataSource={queryData?.compounds}
                    columns = { columns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    pagination={false}
                    scroll={{ y: height-550 }}
                />

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                data-testid = "compound-modal"
                width={800}
                footer={[
                    <Button type="primary" form="CompoundForm" key="submit" htmlType="submit" data-testid="submit-compound">
                        Submit
                    </Button>
                    ]}
            >
                <div style={{ minHeight: 250 }}>
                
                    <Form
                        {...formLayout}  
                        id = "CompoundForm"
                        onFinish={handleModalOk} 
                        initialValues={ modalInitialValues }
                    > 
                        {/* create a radio asking if new or import */}
                        { modalTitel == "Add compound" &&
                            <Form.Item label="Source:" required >
                                <Radio.Group value={isImportCompound} onChange={onChangeImportCompound}>
                                    <Radio value={0}>Create a new compound</Radio>
                                    <Radio value={1}>Compound library</Radio>
                                </Radio.Group>
                            </Form.Item>
                        }

                        { isImportCompound == 0 && 
                            <div>
                                <Form.Item label="Compound name:" name="name" rules={[{ required: true, message: 'Please enter a name for the compound.' }]} >
                                    <Input 
                                        maxLength={30}
                                        showCount
                                        data-testid = "compound-name-input"
                                    />

                                </Form.Item>

                                <Form.Item label="Chemical formula:" name="formula" rules={[{ required: true, message: 'Please enter the chemical formula, e.g. C6H12O6.' }]} >
                                    <Input 
                                        maxLength={100} 
                                        showCount 
                                        data-testid = "compound-formula-input" 
                                    />
                                </Form.Item>

                                <Form.Item label="CAS number:" name="casNumber" rules={[{ required: false}]} >
                                    <Input
                                        maxLength={100}
                                        showCount
                                        data-testid = "compound-cas-number-input"
                                    />
                                </Form.Item>

                                    <div>
                                        <Button 
                                            type="default" 
                                            onClick={() => {setMoleculeModalVisible(true)}} 
                                            data-testid="add-molecule"
                                            style={{ marginBottom: 16 }}
                                        >
                                            Add molecule
                                        </Button>

                                        <Table 
                                            dataSource={molecules}
                                            columns = { moleculeColumns } 
                                            bordered
                                            rowKey={record => record.id} 
                                            size="small"
                                            pagination={false}
                                        />

                                        <Modal 
                                            title= "Add molecule"
                                            open={ isMoleculeModalVisible } 
                                            onOk = { handleMoleculeModalOk }
                                            onCancel = { handleMoleculeModalCancel }
                                            destroyOnClose = {true}
                                            data-testid = "molecule-modal"
                                            width={800}
                                            footer={[
                                                <Button type="primary" form="MoleculeForm" key="submit" htmlType="submit" data-testid="submit-molecule">
                                                    Submit
                                                </Button>
                                                ]}
                                        >
                                            <Form
                                                {...formLayout}  
                                                id = "MoleculeForm"
                                                onFinish={handleMoleculeModalOk} 
                                                initialValues={ moleculeModalInitialValues }
                                            > 
                                                <Form.Item label="Molecule name:" name="name" rules={[{ required: true, message: 'Please enter a name for the molecule.' }]} >
                                                    <Input 
                                                        maxLength={30}
                                                        showCount
                                                        data-testid = "molecule-name-input"
                                                    />
                                                </Form.Item>

                                                <Form.Item label="Chemical formula:" name="formula" rules={[{ required: true, message: 'Please enter the chemical formula, e.g. C6H12O6.' }]} >
                                                    <Input 
                                                        maxLength={100} 
                                                        showCount 
                                                        data-testid = "molecule-formula-input" 
                                                    />
                                                </Form.Item>

                                                <Form.Item label="Stoichiometry:" name="stoichiometry" rules={[{ required: true, message: 'Please enter the stoichiometry.' }]} >
                                                    <Input 
                                                        maxLength={10} 
                                                        showCount 
                                                        data-testid = "molecule-stoichiometry-input" 
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </Modal>

                                    </div>
                                

                               
                            </div>
                            }

                            { isImportCompound == 1 &&
                                <div>
                                    {/* add a search field */}
                                    <Form.Item label="Search:" name="search" rules={[{ required: false}]} >
                                        <Input
                                            maxLength={100}
                                            data-testid = "compound-search-input"
                                            onChange={handleImportCompoundsSearch}
                                        />
                                    </Form.Item>
                                    <div style = {{ height:400, overflowY: 'auto' }}>
                                        <Table 
                                            loading={allTopicCompoundsLoading}
                                            dataSource={importCompounds}
                                            columns = { importCompoundColumns }
                                            bordered
                                            rowKey={record => record.id}
                                            size="small"
                                            pagination={false}
                                            rowSelection={{
                                                type: "radio",
                                                ...rowSelection,
                                            }}
                                        />
                                    </div>
                                </div>
                            }


                    </Form>
                </div>

            
            </Modal>
            <Modal 
                title="Loading..."
                closable={false}
                footer={null}
                open={createMutationLoading || updateMutationLoading || deleteMutationLoading || importMutationLoading}
                >
                <Skeleton active/>
            </Modal>

            <ImportMedia open={importModalVisible} setOpen={setImportModalVisible} importType='compound' targetTopicId={props.topicId} />

        </div>

    );
    }


export default CompoundTable;

