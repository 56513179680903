import PageHeader from '../components/PageHeader';
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from "../components/useWindowDimensions";
import ReactGA from 'react-ga';
import AnalysisCard from '../components/analysis/AnalysisCard';

function Analyses() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/analyses/');

    const { height, width } = useWindowDimensions();


    return(  
      <div>

        <PageHeader
          title="Analyses"
          extra={<HeaderExtra/>}
        />

        <AnalysisCard/>
          
      </div>
    )
  }
  
  export default Analyses;
