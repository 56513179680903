import React, {useState, useEffect} from 'react';

import { useQuery, useMutation, gql } from '@apollo/client';

import { Select, Form, Modal, message, Skeleton, Radio, Input} from 'antd';

 
const {Option} = Select;

// gets also all of the topics
const ALL_TEAMS= gql`
    query allTeams {
        teams {
            id
            name
            analysisSet {
                id
                name
                team {
                    id
                    name
                }
            }
        }
        userProfile {
            id
            selectedAnalysis {
                id
            }
            selectedTeam {
                id
            }
        }
    }
`

// mutation to copy an analysis
const COPY_ANALYSIS = gql`
    mutation copyAnalysis($input: CopyAnalysisInputType!) {
        copyAnalysis(input:$input) {
            analysis {
                id
                name
            }
        }
    }
`

const GET_ANALYSES = gql`
    query analyses ($teamId: ID!) {
        analyses (teamId:$teamId) {
            id
            name
            description
            nProcesses
            kpiSet {
                id
            }
            statmodelSet {
                id
            }
            owner {
                id
                username
            }
            team {
                id
                name
            }
        }
    }
`;

interface CopyAnalysisProps {
    selectedAnalysis:any
    setOpen: Function
    open: boolean
}


function CopyAnalysis(props: CopyAnalysisProps) {

    const [copyProcessSelection, setCopyProcessSelection] = useState(true);
    const [destination, setDestination] = useState<"team" | "personal">("team");
    const [selectedTeam, setSelectedTeam] = useState<any>("");
    const [newAnalysisName, setNewAnalysisName] = useState<any>("");

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery( ALL_TEAMS , { 
        fetchPolicy: 'cache-and-network',
    });


    const [copyMutation] = useMutation(COPY_ANALYSIS, {
        refetchQueries: [ 
            { query: GET_ANALYSES, variables: { teamId: queryData?.userProfile?.selectedTeam?.id } }
        ],
        onCompleted(data) { message.success("Analysis was copied successfully.") },
        onError(error) {message.error(error.message)},
        
    });

    if (props.selectedAnalysis == null) {
        return null;
    }

    if (queryLoading && !queryData) {
        return (
            <Modal title="Copy Analysis to" open={props.open} onOk={() => props.setOpen(false)} onCancel={() => props.setOpen(false)}>
                <Skeleton active />
            </Modal>
        )
    }

    function handleOk(values: any) {

        let analysisName = newAnalysisName;
        if (analysisName == "") {
            analysisName = props.selectedAnalysis.name + " (copy)";
        }


        let targetTeamId = queryData.userProfile.selectedTeam.id
        
        if (destination == "team" && selectedTeam != "" ) {
            targetTeamId = selectedTeam;
        } else if (destination == "personal") {
            targetTeamId = null;
        }

        let input = {
            analysisId: props.selectedAnalysis.id,
            target: destination,
            targetTeamId: targetTeamId,
            newAnalysisName: analysisName,
            copyProcessSelection: copyProcessSelection
        }
        
        console.log(input)
        copyMutation({variables: { input: input }})
        props.setOpen(false)
    }

    const formLayout = {
        labelCol: { span: 10 },
    };


    return (
        <Modal 
            title="Copy Analysis to" 
            open={props.open } 
            onOk={handleOk}
            onCancel={() => {
                setDestination("team")
                props.setOpen(false)
            }} 
            destroyOnClose={true}
            
        >

        <Form
            {...formLayout}  
            id = "CalculatorSetImport"
            onFinish={handleOk}
        > 
        
        {/* radio to select between personal and team analysis */}

        <Form.Item
            label="Destination:"
            name="destination"
            rules={[{ required: true, message: 'Please select a destination.' }]}
            style={{marginTop:20}}
        >

            <Radio.Group onChange={(e) => setDestination(e.target.value)} value={destination} defaultValue={"team"}>
                <Radio value="team">Team analyses</Radio>
                <Radio value="personal">Personal analyses</Radio>
            </Radio.Group>

        </Form.Item>

        
        {destination == "team" &&

        <Form.Item
            label="Team:"
            name="team"
            rules={[{ required: true, message: 'Please select a team.' }]}
        >
            
            <Select 
                showSearch 
                style={{ width: 200 }}
                placeholder="Select a team"
                optionFilterProp="children"
                defaultValue={queryData.userProfile.selectedTeam.id}
                value={selectedTeam}
                onChange={(value) => setSelectedTeam(value)}
                //filterOption={(input, option) =>
                //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                //}

            >
                {queryData.teams.map((team:any) => (
                    <Option key={team.id} value={team.id}>{team.name}</Option>
                ))}
            </Select>
            
        </Form.Item>

        }

        <Form.Item
            label="Copy process selection:"
            name="copyProcessSelection"
            rules={[{ required: true, message: 'Please select a process selection.' }]}
        >
                
                <Radio.Group onChange={(e) => setCopyProcessSelection(e.target.value)} value={copyProcessSelection} defaultValue={true}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>

        </Form.Item>
        
        <Form.Item 
            label="Name:"
            name="newAnalysisName"
            rules={[{ required: true, message: 'Please enter the new analysis name.' }]}
        >
            <Input maxLength={50} showCount data-testid="newAnalysisName" value={newAnalysisName} onChange={(e) => setNewAnalysisName(e.target.value)} defaultValue={props.selectedAnalysis.name + " (copy)"} />
        </Form.Item>
            
            
        


        </Form>
            
        </Modal>
    )

}

export default CopyAnalysis;