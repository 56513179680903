import { ApolloClient, ApolloLink, InMemoryCache , concat, split, makeVar, Observable} from "@apollo/client";
//import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from "apollo-upload-client";
import { setItem, getItem } from "./storage";
import { gql } from "@apollo/client";
import { EXTENDED_PROCESS_FIELDS_WITH_DATA } from "./components/fragments";


// settings for the newer graphql-ws library (seems to not work with the django channgels setup)
//import { createClient } from 'graphql-ws';
//import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

// settings for the older subscriptions-transport-ws library
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities';


type ID = string | number;
type Int = number;
type Float = number;

export const darkModeVar = makeVar<boolean>(false);
export const selectedCalculator = makeVar("")
export const selectedViewId = makeVar("")
export const processExplorerSidebarVisible = makeVar(true)
export const selectedSidebarTabVar = makeVar("")
export const infoSidebarOpenVar = makeVar(["1","2","3"])
export const leftMenuOpenVar = makeVar(["1","2","3"])

export const globalSelectedTopic = makeVar("")
export const calculatorsSelectedProcess = makeVar("")
export const selectedTeam = makeVar("")
export const kpiAnalysisNumberOfPlots = makeVar(4)

export const processesClient = makeVar<any[]>([])

export const processExplorerSelectedViewPageVar = makeVar("")
export const kpiAnalysisSelectedViewPageVar = makeVar("")

export const processExplorerSelectedProcesses = makeVar<any[]>([])
export const kpiAnalysisSelectedProcesses = makeVar<any[]>([])

export const processesLoading = makeVar(false);
export const processesLoadingProgress = makeVar(0);
export const preloadingActive = makeVar(false);
export const selectedAnalysis = makeVar("")
export const calculatorSetCardCollapsed = makeVar(false);
export const calculatorsSelectedTab = makeVar("1");



const FETCH_PROCESS_LAST_UPDATED = gql`
  query FetchProcessLastUpdated($id: ID!) {
    process(id: $id) {
      id
      lastUpdated
    }
  }
`;

const FETCH_PROCESS_QUERY = gql`
  ${EXTENDED_PROCESS_FIELDS_WITH_DATA}
  query FetchProcess($id: ID!) {
    process(id: $id) {
      ...ExtendedProcessFieldsWithData
    }
  }
`;

let client:any

export const cache: InMemoryCache = new InMemoryCache({ 
  typePolicies: {
    
    ParameterType: {
      keyFields: false,
    },

    MetaDataType: {
      keyFields: false,
    },

    Query: {
      fields: {

        processExplorerSelectedProcesses: {
          read() {
            return processExplorerSelectedProcesses();
          }
        },

        kpiAnalysisSelectedProcesses: {
          read() {
            return kpiAnalysisSelectedProcesses();
          }
        },

        calculatorSetCardCollapsed : {
          read () {
            return calculatorSetCardCollapsed();
          }
        },

        selectedAnalysis: {
          read () {
            return selectedAnalysis();
          }
        },

        preloadingActive: {
          read () {
            return preloadingActive();
          }
        },

        processesLoading: {
          read () {
            return processesLoading();
          }
        },

        processesLoadingProgress: {
          read () {
            return processesLoadingProgress();
          }
        },


        leftMenuOpenVar: {
          read () {
            return leftMenuOpenVar();
          }
        },

        infoSidebarOpenVar: {
          read () {
            return infoSidebarOpenVar();
          }
        },

        selectedSidebarTabVar: {
          read () {
            return selectedSidebarTabVar();
          }
        },

        processExplorerSelectedViewPageVar: {
          read () {
            return processExplorerSelectedViewPageVar();
          }
        },

        kpiAnalysisSelectedViewPageVar: {
          read () {
            return kpiAnalysisSelectedViewPageVar();
          }
        },
        
        darkMode: {
          read () {
            return darkModeVar();
          }
        },

        processesClient: {
          read () {
            return processesClient();
          }
        },

        calculatorsSelectedProcess: {
          read() {
            return calculatorsSelectedProcess()
          }
        },

        processExplorerSidebarVisible: {
          read() {
            return processExplorerSidebarVisible()
          }
        },
        
        selectedTeam: {
          read() {
            return selectedTeam()
          }
        },

        selectedCalculator: {
          read() {
            return selectedCalculator()
          }
        },

        selectedViewId: {
          read() {
            return selectedViewId()
          }
        },

        globalSelectedTopic: {
          read() {
            return globalSelectedTopic()
          }
        },

        kpiAnalysisNumberOfPlots: {
          read() {
            return kpiAnalysisNumberOfPlots()
          }
        },

        /*
        process:{
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ProcessType',
              id: args!.id,
            });
          }
        },*/


        /*
        process: {
          keyArgs: ["id"],

          async read(existing, { args, toReference }: any) {

            // Use the globally defined client instance directly
            //if (!client) {
            //  throw new Error("Apollo client is not initialized yet");
            //}
            
            const { id } = args;

            // Function to fetch and update if needed
            const fetchAndUpdate = async (currentLastUpdated: Date | null) => {
              
              const { data: lastUpdatedData } = await client.query({
                query: FETCH_PROCESS_LAST_UPDATED,
                variables: { id },
                fetchPolicy: 'network-only',
              });

              const serverLastUpdated = new Date(lastUpdatedData.process.lastUpdated);

              if (!currentLastUpdated || serverLastUpdated > currentLastUpdated) {
                // Fetch the full data
                const { data: fetchedProcessData } = await client.query({
                  query: FETCH_PROCESS_QUERY,
                  variables: { id },
                  fetchPolicy: 'network-only',
                });

                // Update Apollo cache and local storage
                cache.writeQuery({
                  query: FETCH_PROCESS_QUERY,
                  variables: { id },
                  data: { process: fetchedProcessData.process },
                });

                await setItem(`process-${id}`, fetchedProcessData.process);

                return toReference({
                  __typename: 'ProcessType',
                  id: fetchedProcessData.process.id,
                  ...fetchedProcessData.process,
                });
              }

              return existing || toReference({
                __typename: 'ProcessType',
                id,
                ...lastUpdatedData.process,
              });
            };

            // Step 1: Check Apollo cache
            if (existing && existing.lastUpdated) {
              // Store the valid cached data in local storage
              await setItem(`process-${id}`, existing);

              fetchAndUpdate(new Date(existing.lastUpdated));
              
              return existing;
            }

            // Step 2: Check local storage
            const storedProcess: any = await getItem(`process-${id}`);
            if (storedProcess && storedProcess.lastUpdated) {
              const processReference = toReference({
                __typename: 'ProcessType',
                id: storedProcess.id,
                ...storedProcess,
              });

              fetchAndUpdate(new Date(storedProcess.lastUpdated));
              return processReference;
            }

            // If no data in cache or local storage, trigger network fetch
            fetchAndUpdate(null);
            return undefined;
          },
          
          async merge(existing, incoming, { args }:any) {
            const { id } = args;

            // Update local storage
            await setItem(`process-${id}`, incoming);

            // Merge data into cache
            return incoming;
          },
          
        },*/

        processes: {

          // cashe based on following arguments
          keyArgs: ['topicId', 'ids','filter'],

          merge(existing, incoming, { readField }) {

            let processes = existing ? { ...existing.processes } : {};

            incoming.processes.forEach((process: any) => {

              let idx:any = readField("id", process);
              processes[ idx ] = process;
              // order processes by name
              //processes[ idx ].name = readField("name", process);
              //processes = Object.values(processes).sort((a:any, b:any) => a.name.localeCompare(b.name));

            });

            return {
              totalCount: incoming.totalCount,
              processes,
            };

          },
          read(existing) {

            if (existing) {

              let processes = Object.values(existing.processes)
              return {
                totalCount: existing.totalCount,
                processes,
              };

            }

          },

          /*merge(existing = [], incoming) {

            // combine the lists and only keep unique items
            return [...existing, ...incoming.filter( (item:any) => !existing.some( (item2:any) => item2.id == item.id))];
            
            //return [...existing, ...incoming];
            
            // return unique items in both lists exisitng and incoming - use typescript type declarations
            //return [...existing, ...incoming.filter( (item:any) => !existing.some( (item2:any) => item2.id === item.id))];
            

          },*/

        },

      }
    }
  }
});


const authMiddleware = new ApolloLink((operation, forward) => {

  // add the authorization to the headers
  operation.setContext({
    headers: {
      Authorization: sessionStorage.getItem('token') || "", // "" used to be null - which one is correct?
    }
  });

  return forward(operation);
})


const delayLink = new ApolloLink((operation, forward) => 
  new Observable(observer => {
    setTimeout(() => {
      const subscription = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });

      // Cleanup function
      return () => subscription.unsubscribe();
    }, 1000); // 3-second delay
  })
);

/*
const wsLink = new WebSocketLink(

  new SubscriptionClient("ws://localhost:8008/graphql", 
  {
      reconnect: false,
      connectionParams: {
        token: sessionStorage.getItem('token'),
        userPermissions: sessionStorage.getItem('user'),
      }

  })
);
*/

let httpLink = createUploadLink({
  uri: 'http://localhost:8008/graphql',
});

if (process.env.NODE_ENV == 'production') {
  httpLink = createUploadLink({
    uri:'https://bioprocessintelligence.com/graphql/',
  });
}


// Combine the links
export const combinedLink = ApolloLink.from([
  //delayLink,
  authMiddleware,
  httpLink
]);

/*
// Using the split function to send data to either the WebSocket link or HTTP link based on the kind of operation
let link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink, // Use WebSocket Link for subscriptions
  combinedLink // Use HTTP Link for queries and mutations
);
*/

// Create the Apollo Client
client = new ApolloClient({
  link: combinedLink,
  cache: cache
});

export default client;