import { Card, Collapse, Steps, Skeleton} from 'antd';
import { gql, useQuery } from '@apollo/client';
import PageHeader from '../components/PageHeader';
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from "../components/useWindowDimensions";
import ModelTable from "../components/modeling_toolbox/ModelTable";

const { Panel } = Collapse;
const { Step } = Steps;

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            selectedAnalysis {
              id
              name
            }
        }
    }
`

function Modeling() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/modeling/');

    const { height, width } = useWindowDimensions();

    const { loading, error, data } = useQuery(GET_USER_PROFILE);

    if (error) return ( 
        <Card>Error: {error.message} </Card>
    );

    if (loading) return (
        <Card><Skeleton active/></Card>
    );

    const routes = [
      {
        path: '',
        breadcrumbName: "Analysis: " + data.userProfile.selectedAnalysis.name ,
      }
    ];

    return(  
      <div>

        <PageHeader
          title="Models"
          extra={<HeaderExtra/>}
          breadcrumb={{ routes: routes }}
        />

        <Card style={{height:height-130, overflow:"scroll"}}>
          <ModelTable analysisId={data.userProfile.selectedAnalysis.id}/>
        </Card>

        {/*
        <Card style={{height:height-130, overflow:"auto"}}>
            <Collapse defaultActiveKey={["1", "2"]}  >

                <Panel header="Create a new model" key="1">
                    <CreateModel/>

                </Panel>

                <Panel header="Existing models" key="2">
                    <ModelTable/>
                </Panel>

            </Collapse>
        </Card>
        */}


          
      </div>
    )
  }
  
  export default Modeling;
