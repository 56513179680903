import React, {useState, useEffect} from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, Skeleton, message, Radio, Slider, Row, Col} from 'antd';
import Plot from 'react-plotly.js';
import { pollInterval } from '../..';

const GET_MEDIUM = gql`
    query Medium ($id:ID!) {
        medium(id:$id) {
            id
            name
            description

            compoundListMassFraction
            compoundListMolality
            compoundMassFraction
            compoundMolality

            moleculeListMassFraction
            moleculeListMolality
            moleculeMassFraction
            moleculeMolality

            elementListMassFraction
            elementListMolality
            elementMassFraction
            elementMolality
        }
        userProfile {
            id
            darkMode
        }
    }
`;

interface MediaPlotInputType {
    media: any
};

function MediaPlot(props:MediaPlotInputType) {

    const { loading, error, data } = useQuery(GET_MEDIUM , {variables:{id:props?.media?.id}, 
        //pollInterval: pollInterval,
    });

    const [compoundMoleculeElement, setCompoundMoleculeElement] = useState("compound");
    const [massFractionOrMolality, setMassFractionOrMolality] = useState("massFraction");

    // create a sliderValue state variable with type any
    const [sliderValue, setSliderValue] = useState<any>([0, 1000]);

    // useEffect to set the slider value to the maximum value
    let maxSliderValue = 1000;
    useEffect(() => {
        //setSliderValue([0, maxSliderValue]);
    }, [data]);
 

    /* ############################# LOADING AND ERROR ############################# */

    if (loading || !data) {
        return (
            <Skeleton active />
        )
    }

    if (error) {
        message.error(error.message);
    }

    //################################################################################
    

    let compoundList = data.medium.compoundListMassFraction;
    if (massFractionOrMolality === "molality") {
        compoundList = data.medium.compoundListMolality;
    }

    let compoundValues = data.medium.compoundMassFraction;
    if (massFractionOrMolality === "molality") {
        compoundValues = data.medium.compoundMolality;
    }

    // multiply the values by 1000 to get g/kg or mmol/kg
    compoundValues = compoundValues.map((value:any) => value*1000);

    //let maxSliderValue = 1000
    if (compoundValues.length > 0) {
        maxSliderValue = compoundValues.reduce((a:any, b:any) => Math.max(a, b));
    }

    
    // exclude items from the list which are outside the slider range
    let compoundListFiltered = [];
    let compoundValuesFiltered = [];
    for (let i = 0; i < compoundList.length; i++) {
        if (compoundValues[i] >= sliderValue[0] && compoundValues[i] <= sliderValue[1]) {
            compoundListFiltered.push(compoundList[i]);
            compoundValuesFiltered.push(compoundValues[i]);
        }
    }

    //compoundList = compoundListFiltered;
    //compoundValues = compoundValuesFiltered;
    

    
    let compoundPlotTitle = 'Mass Fraction [g/kg]'
    if (massFractionOrMolality === "molality") {
        compoundPlotTitle = 'Molality [mmol/kg]'
    }

    

    /* ############################# MOLECULES ############################# */

    let moleculeList = data.medium.moleculeListMassFraction;
    if (massFractionOrMolality === "molality") {
        moleculeList = data.medium.moleculeListMolality;
    }

    let moleculeValues = data.medium.moleculeMassFraction;
    if (massFractionOrMolality === "molality") {
        moleculeValues = data.medium.moleculeMolality;
    }

    // multiply by 1000 to get a g/kg or mmol/kg
    moleculeValues = moleculeValues.map((value:any) => value*1000);
    
    if (compoundMoleculeElement === "molecule") {
        if (moleculeValues.length > 0) {
            maxSliderValue = moleculeValues.reduce((a:any, b:any) => Math.max(a, b));
        }
    }

    // exclude items from the list which are outside the slider range
    let moleculeListFiltered = [];
    let moleculeValuesFiltered = [];
    for (let i = 0; i < moleculeList.length; i++) {
        if (moleculeValues[i] >= sliderValue[0] && moleculeValues[i] <= sliderValue[1]) {
            moleculeListFiltered.push(moleculeList[i]);
            moleculeValuesFiltered.push(moleculeValues[i]);
        }
    }

    //moleculeList = moleculeListFiltered;
    //moleculeValues = moleculeValuesFiltered;
    

    /* ############################# ELEMENTS ############################# */

    let elementList = data.medium.elementListMassFraction;
    if (massFractionOrMolality === "molality") {
        elementList = data.medium.elementListMolality;
    }

    let elementValues = data.medium.elementMassFraction;
    if (massFractionOrMolality === "molality") {
        elementValues = data.medium.elementMolality;
    }

    // do not exclude water from the list of elements and mass fractions

    // multiply by 1000 to get a g/kg or mmol/kg
    elementValues = elementValues.map((value:any) => value*1000);

    if (compoundMoleculeElement === "element") {
        if (elementValues.length > 0) {
            maxSliderValue = elementValues.reduce((a:any, b:any) => Math.max(a, b));
            //setMaxSliderValue(elementValues.reduce((a:any, b:any) => Math.max(a, b)));
        }
    }

    // exclude items from the list which are outside the slider range
    let elementListFiltered = [];
    let elementValuesFiltered = [];
    for (let i = 0; i < elementList.length; i++) {
        if (elementValues[i] >= sliderValue[0] && elementValues[i] <= sliderValue[1]) {
            elementListFiltered.push(elementList[i]);
            elementValuesFiltered.push(elementValues[i]);
        }
    }

    //elementList = elementListFiltered;
    //elementValues = elementValuesFiltered;

    // round up to the nearest 10
    maxSliderValue = Math.ceil(maxSliderValue*1.2/100)*100;



    /* ############################# PLOT ############################# */

    let plotConfig:any = {
        displaylogo: false,
        responsive: true,
        toImageButtonOptions: {
          format: 'svg', // one of png, svg, jpeg, webp
          filename: 'Media plot - ' + props?.media?.name,
          scale: 1 // Multiply title/legend/axis/canvas sizes by this factor

        }}
   
    // set the background color of the plot
    let plotBgColor = "white"
    let axisColor = "#141414"
    let dataPointsColor = "navy"
    if (data.userProfile?.darkMode) {
        plotBgColor = "#141414"
        axisColor = "white"
        dataPointsColor = "cyan"
    }

    function onSwitchMassFractionMolality(value:any) {
        setMassFractionOrMolality(value.target.value);
        setSliderValue([0, maxSliderValue]);
    }

    function onSwitchCompoundMoelculeElement(value:any) {
        setCompoundMoleculeElement(value.target.value);
        setSliderValue([0, maxSliderValue]);
    }


    return (
        <div>
                {/*Radio to select between mass fraction and molality*/}
                <Radio.Group onChange={onSwitchMassFractionMolality} value={massFractionOrMolality}>
                    <Radio.Button value="massFraction">Mass Fraction</Radio.Button>
                    <Radio.Button value="molality">Molality</Radio.Button>
                </Radio.Group>

                <br /><br/>

                {/*Radio to select between compound, molecule, or element*/}
                <Radio.Group onChange={onSwitchCompoundMoelculeElement} value={compoundMoleculeElement}>
                    <Radio.Button value="compound">Compound</Radio.Button>
                    <Radio.Button value="molecule">Molecule</Radio.Button>
                    <Radio.Button value="element">Element</Radio.Button>
                </Radio.Group>
                
                <br/>

                
                

                <Card style={{marginTop:20}}>
                    <Row>
                        
                        {/*Slider to set the range of values*/}
                        {/*
                        <Col span={2}>
                            <Slider
                                range
                                vertical
                                min={0}
                                max={maxSliderValue}
                                step={10}
                                defaultValue={sliderValue}
                                tipFormatter={value => `${value}`}
                                onChange={(value) => {
                                    setSliderValue(value);
                                }}
                            />
                        </Col>
                        */}

                        <Col span={22}>
                    
                            {/* create a plotly bar plot showing the compoundMassFraction for each compound in compoundList */}
                            {compoundMoleculeElement === "compound" &&
                                <Plot
                                    style={{width: "100%", height: "100%"}}
                                    data={[
                                        {
                                            x: compoundList,
                                            y: compoundValues,
                                            type: 'bar',
                                            //marker: {color: dataPointsColor},
                                            // color of the labels
                                            //marker: {color: 'grey'},
                                            //labels: compoundList,
                                            //values: compoundValues,
                                            //type: 'pie',
                                            
                                        }
                                    ]}
                                    layout={ {
                                        autosize: true,
                                        height: 350,
                                        margin: { t: 20, b: 50, l: 80, r: 0 },
                                        //title: compoundPlotTitle,
                                        paper_bgcolor: plotBgColor,
                                        plot_bgcolor: plotBgColor,
                                        legend: {
                                            borderwidth: 1,
                                            bordercolor: axisColor,
                                            font: {
                                                color: axisColor
                                            }
                                        },
                                        yaxis: {
                                            title: compoundPlotTitle,
                                            automargin: true,
                                            color: axisColor,
                                            // set the range of the y axis to be the same as the slider
                                            //range: [sliderValue[0], sliderValue[1]]
                                        },
                                        xaxis: {
                                            automargin: true,
                                            color: axisColor,
                                        },
                                        
                                    }}
                                    config={plotConfig}
                                />
                            }

                            {/* create a plotly bar plot showing the moleculeMassFraction for each molecule in moleculeList */}
                            {compoundMoleculeElement === "molecule" &&
                                <Plot
                                    style={{width: "100%", height: "100%"}}
                                    data={[
                                        {
                                            x: moleculeList,
                                            y: moleculeValues,
                                            type: 'bar',
                                            //marker: {color: 'grey'},
                                        }
                                    ]}
                                    layout={ {
                                        autosize: true,
                                        height: 350,
                                        margin: { t: 20, b: 50, l: 80, r: 0 },
                                        //title: compoundPlotTitle,
                                        paper_bgcolor: plotBgColor,
                                        plot_bgcolor: plotBgColor,
                                        yaxis: {
                                            title: compoundPlotTitle,
                                            automargin: true,
                                            color: axisColor,
                                            
                                            //range: [sliderValue[0], sliderValue[1]]
                                        },
                                        xaxis: {
                                            automargin: true,
                                            color: axisColor,
                                        },
                                        legend: {
                                            borderwidth: 1,
                                            bordercolor: axisColor,
                                            font: {
                                                color: axisColor
                                            }
                                        },
                                    }}
                                    config={plotConfig}
                                />
                            }

                            {/* create a plotly bar plot showing the elementMassFraction for each element in elementList */}
                            {compoundMoleculeElement === "element" &&

                                <Plot
                                    style={{width: "100%", height: "100%"}}
                                    data={[
                                        {
                                            x: elementList,
                                            y: elementValues,
                                            type: 'bar',
                                            //marker: {color: 'grey'},
                                        }
                                    ]}
                                    layout={ {
                                        autosize: true,
                                        height: 350,
                                        margin: { t: 20, b: 50, l: 80, r: 0 },
                                        //title: compoundPlotTitle,
                                        paper_bgcolor: plotBgColor,
                                        plot_bgcolor: plotBgColor,
                                        yaxis: {
                                            title: compoundPlotTitle,
                                            automargin: true,
                                            color: axisColor,
                                            //range: [sliderValue[0], sliderValue[1]]
                                        },
                                        xaxis: {
                                            automargin: true,
                                            color: axisColor,
                                        },
                                        legend: {
                                            borderwidth: 1,
                                            bordercolor: axisColor,
                                            font: {
                                                color: axisColor
                                            }
                                        },
                                    }}
                                    config={plotConfig}
                                />
                            }
                        </Col>
                        </Row>
                </Card>
            
        </div>
    )

}

export default MediaPlot;