import React, { useState, useEffect } from 'react';
import { Select, Card , Skeleton } from 'antd';
import Plot from 'react-plotly.js';
import useWindowDimensions from '../useWindowDimensions';

const { Option } = Select;


interface PCAPlotInputType {
  statModel:any
  loading:boolean
  axes:any
  darkMode:boolean
}

function PCAPlot(props: PCAPlotInputType) {

  const { height, width } = useWindowDimensions();

  const [plotWidth, setPlotWidth] = useState("48%")
  const [plotHeight, setPlotHeight] = useState(height-160)
  const [plotFractiom, setPlotFraction] = useState(0.8)

  // adjust the plotWidth and plotHeight parameters based on number of plots in the view
  useEffect(() => {
   
      setPlotHeight( height - 160  )
    
  })

  let xaxis_number = props.axes[0]+1
  let yaxis_number = props.axes[1]+1
  let zaxis_number = props.axes[2]+1

    // set the background color of the plot
    let plotBgColor = "white"
    let axisColor = "black"
    let dataPointsColor = "black"
    if (props.darkMode) {
        plotBgColor = "#141414"
        axisColor = "white"
        dataPointsColor = "grey"
    }

  return (

    <div>

    <Card title = "Loadings" style={{ width: plotWidth, float: 'left',  marginLeft: 10, marginBottom: 10, height: plotHeight }}>

      { props.loading && 
        <Skeleton paragraph={{ rows: 6 }} active />
      }

      
        { !props.loading && 

        <div>
       
          { props.axes[2] == -1 && 

            <Plot
              data={[
                {
                    x: props.statModel.pcaLoadings[ props.axes[0] ],
                    y: props.statModel.pcaLoadings[ props.axes[1] ],
                    type: 'scatter',
                    mode: 'markers',
                    text: props.statModel.pcaLoadingsNames,
                    marker: {color: 'red', size: 15},
                }]}
              useResizeHandler={false}
              style={{ width: "100%" }}

              layout={
                {
                  autosize: true,
                  height: plotFractiom * plotHeight,
                  margin: {
                    l: 50,
                    r: 20,
                    b: 40,
                    t: 40
                  },
                  plot_bgcolor: plotBgColor,
                  paper_bgcolor: plotBgColor,
                  xaxis: {
                    title: "PC" + xaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[0] ] * 100 )  + "%)",
                    mirror:true,
                    linewidth:1,
                    color: axisColor,
                    ticks: 'inside',
                    tickformat: '.3',
                  },
                  yaxis: {
                    title: "PC" + yaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[1] ] * 100 )  + "%)",
                    mirror:true,
                    linewidth:1,
                    color: axisColor,
                    ticks: 'inside',
                    tickformat: '.3',
                    //zeroline:false,
                  },
                  legend: {
                    borderwidth: 1,
                  },

                }}
              config={{
                displaylogo: false,
                responsive: true,
                toImageButtonOptions: {
                  format: 'svg', // one of png, svg, jpeg, webp
                  filename: 'PCA Loadings Plot',
                  scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                },
              }}
            />

          } 

          {/* ******************************************************************************** */}
          {/* 3d Plot */}

          { props.axes[2] != -1 &&
            <div>
              <Plot

                data={[
                  {
                      x: props.statModel.pcaLoadings[ props.axes[0] ],
                      y: props.statModel.pcaLoadings[ props.axes[1] ],
                      z: props.statModel.pcaLoadings[ props.axes[2] ],
                      text: props.statModel.pcaLoadingsNames,
                      marker: {color: 'red', size: 4},
                      type: 'scatter3d',
                      mode: 'markers',
                  }
                ]}
               
                layout={
                  {
                    autosize: true,
                    height: plotFractiom * plotHeight,
                    margin: {
                      l: 10,
                      r: 10,
                      b: 10,
                      t: 10
                    },
                    plot_bgcolor: plotBgColor,
                    paper_bgcolor: plotBgColor,
                    scene: {
                      xaxis: {
                        title: "PC" + xaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[0] ] * 100 )  + "%)",
                        linewidth:1,
                        color: axisColor,
                      },
                      yaxis: {
                        title: "PC" + yaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[1] ] * 100 )  + "%)",
                        linewidth:1,
                        mirror:true,
                        color: axisColor,
                        ticks: 'inside',
                        tickformat: '.3',

                      },
                      zaxis: {
                        title: "PC" + zaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[2] ] * 100 )  + "%)",
                        linewidth:1,
                        mirror:true,
                        color: axisColor,
                        ticks: 'inside',
                        tickformat: '.3',
                      },
                  }

                  }
                }
                config={{
                  displaylogo: false,
                  responsive: true,
                  toImageButtonOptions: {
                    format: 'svg', // one of png, svg, jpeg, webp
                    filename: 'PCA Loadings Plot',
                    scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                  },
                }}

                useResizeHandler={false}
                style={{ width: "100%" }}
              />

            </div>
          }

          </div>

        }
 

    </Card>

<Card title = "Scores" style={{ width: plotWidth, float: 'left' , marginLeft: 10, marginBottom: 10, height: plotHeight }}>

  { props.loading && 
    <Skeleton paragraph={{ rows: 6 }} active />
  }

  { !props.loading &&

    <div>

    { props.axes[2] == -1 && 

    <Plot

      data={[
        {
            x: props.statModel.pcaScores.map(function(value:any,index:any) { return value[ props.axes[0] ]}), // get column 0
            y: props.statModel.pcaScores.map(function(value:any,index:any) { return value[ props.axes[1] ]}), // get column 1
            text: props.statModel.pcaScoresNames,
            type: 'scatter',
            mode: 'markers',
            marker: {color: dataPointsColor, size: 10},
        }]}
      useResizeHandler={false}
      style={{ width: "100%" }}
      //onHover={plotOnHover}

      layout={
        {
          autosize: true,
          height: plotFractiom * plotHeight,


          margin: {
            l: 50,
            r: 20,
            b: 40,
            t: 40
          },
          plot_bgcolor: plotBgColor,
          paper_bgcolor: plotBgColor,
          xaxis: {
            title: "PC" + xaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[0] ] * 100 )  + "%)",
            //range: [props.view?.xaxisMin, props.view?.xaxisMax],
            mirror:true,
            linewidth:1,
            color: axisColor,
            ticks: 'inside',
            tickformat: '.3',
          },
          yaxis: {
            // TODO: add support for y-axis range
            title: "PC" + yaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[1] ] * 100 )  + "%)",
            mirror:true,
            linewidth:1,
            color: axisColor,
            ticks: 'inside',
            tickformat: '.3',
            //zeroline:false,
          },
          

          //showlegend: props.view?.showLegend,
          legend: {
            borderwidth: 1,
          },
          //plot_bgcolor:"#141414",
          //paper_bgcolor:"#141414",


        }}
      config={{
        displaylogo: false,
        responsive: true,
        toImageButtonOptions: {
          format: 'svg', // one of png, svg, jpeg, webp
          filename: 'PCA Scores Plot',
          scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        },
      }}
    />
    }

    {/* ******************************************************************************** */}
    {/* 3d Plot */}

    { props.axes[2] != -1 &&
     <div>
        <Plot

          data={[
            {
                x: props.statModel.pcaScores.map(function(value:any,index:any) { return value[ props.axes[0] ]}), // get column 0
                y: props.statModel.pcaScores.map(function(value:any,index:any) { return value[ props.axes[1] ]}), // get column 0
                z: props.statModel.pcaScores.map(function(value:any,index:any) { return value[ props.axes[2] ]}), // get column 0
                text: props.statModel.pcaScoresNames,
                marker: {color: 'navy', size: 4},
                type: 'scatter3d',
                mode: 'markers',
            }
          ]}
          
          layout={
            {
              autosize: true,
              height: plotFractiom * plotHeight,
              margin: {
                l: 10,
                r: 10,
                b: 10,
                t: 10
              },
              plot_bgcolor: plotBgColor,
              paper_bgcolor: plotBgColor,
              scene: {
                xaxis: {
                  title: "PC" + xaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[0] ] * 100 )  + "%)",
                  //mirror:true,
                  linewidth:1,
                  color: axisColor,
                  ticks: 'inside',
                  tickformat: '.3',
                  //zeroline:false,
                },
                yaxis: {
                  title: "PC" + yaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[1] ] * 100 )  + "%)",
                  mirror:true,
                  linewidth:1,
                  color: axisColor,
                  ticks: 'inside',
                  tickformat: '.3',
                  //zeroline:false,
                },
                zaxis: {
                  title: "PC" + zaxis_number  + " (" + Math.floor( props.statModel.pcaVarianceRatio[ props.axes[2] ] * 100 )  + "%)",
                  mirror:true,
                  linewidth:1,
                  color: axisColor,
                  ticks: 'inside',
                  tickformat: '.3',
                  //zeroline:false,
                },
            }

            }
          }
          config={{
            displaylogo: false,
            responsive: true,
            toImageButtonOptions: {
              format: 'svg', // one of png, svg, jpeg, webp
              filename: 'PCA Scores Plot',
              scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
            },
          }}

          useResizeHandler={false}
          style={{ width: "100%" }}
        />

      </div>

    }


    </div>

    
    }


    

</Card>

</div>

  )

}

export default PCAPlot;
