import React, {useState} from 'react';
import {Link} from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import Plot from 'react-plotly.js';
import { Table, Card , Space, Popconfirm, Button, Spin, message, Row, Col} from 'antd';


const { Column, ColumnGroup } = Table;

const GET_PROCESSES = gql`
    query processes ($topicId:ID){  
        processes (topicId:$topicId) {
            processes {
                id
                name
                description
                lastUpdated
                startTime
                topic {
                    id
                    name
                }
            }
        }
    } 
`;


function DashboardProcessTable() {
    
    const { loading, error, data } = useQuery(GET_PROCESSES, {variables: { topicId:"" }});
  
    const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        editable: false,
        width: '20%',
        render: (text:String, record:any) => (
            <Link to={'/processes/process/'+ record.id } >{text}</Link>),
    },
    {
        title: 'Topic',
        dataIndex: ['topic','name'],
        editable: false,
    },
    {
        title: 'Last Updated',
        dataIndex: 'lastUpdated',
        editable: true,
        render: (text:String, record:any) => (
                <div>
                    {(() => {
                        if (record.lastUpdated != null) {
                            let d = new Date(record.lastUpdated);
                            return d.toUTCString() 
                        }
                    })()}
                </div>
        ),
    },
    ];

    
    if (error) return ( 
        <Card>Error: {error.message} </Card>
    );

    if (loading) return (
        <Card><Spin/></Card>
    );

    var plotData:any = [
        {
          values: [112, 454, 65, 544],
          labels: ["CHO", "Topic 1", "Topic 2", "Topic 3"],
          type: "pie",
        },
      ];
      

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Card title="Overview">


                    <Plot
                        data={plotData}
                        useResizeHandler={false}
                        style={{ width: "100%" }}
                        layout={
                        {
                            autosize: true,
                            height:350,
                            margin: {
                            l: 50,
                            r: 20,
                            b: 40,
                            t: 40
                            },

                            xaxis: {
                            title: "",
                            //range: [props.view.xaxisMin, props.view.xaxisMax],
                            //mirror:true,
                            //linewidth:0.5,
                            //color: "#808080",
                            },
                            yaxis: {
                            // TODO: add support for y-axis range
                            //title: props.view.yaxis[props.id] + " [" + yUnits + "]",
                            //mirror:true,
                            //linewidth:0.5,
                            //color:"#808080",
                            

                            },
                            //showlegend: props.view.showLegend,
                            legend: {
                            borderwidth: 1,
                            },
                            //plot_bgcolor:"#141414",
                            //paper_bgcolor:"#141414",


                        }}
                        config={{
                        displaylogo: false,
                        responsive: true,
                        toImageButtonOptions: {
                            format: 'svg', // one of png, svg, jpeg, webp
                            filename: 'Process_Explorer_Image',
                            scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                        },
                        }}
                        />

                    </Card>
                </Col>
                <Col span={12}>
                <Card >

                    <Table 
                        dataSource={data.processes.processes}
                        columns={columns} 
                        bordered 
                        rowKey={record => record.id}
                        />

                    </Card>
                </Col>
            </Row>
            
            
        </div>
    );
}
export default DashboardProcessTable;