
// Import the resize observer error handling code
//import './components/resizeObserverErrorHandler';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });

export const pollInterval = 1000 * 60 * 60 * 24 * 365; // 5 seconds



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

