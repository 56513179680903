import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Form, Spin, Modal, Input, message, Alert} from 'antd';
import { pollInterval } from '../..';

const { TextArea } = Input;

const CREATE_DATA_STANDARD_OTHER_NAME = gql`
    mutation createDataStandardOtherName($input: CreateDataStandardOtherNameInputType!) {
        createDataStandardOtherName( input:$input) {
            dataStandardOtherName {
                id
                name
                dataStandard {
                    id
                    name
                }
            }
        }
    }
`;

const READ_DATA_STANDARD = gql`
    query dataStandard($id: ID!) {
        dataStandard(id: $id) {
            id
            name
            type
            description
            datastandardothernameSet {
                id
                name
            }
        }
    }
`;

const UPDATE_DATA_STANDARD_OTHER_NAME = gql`
    mutation updateDataStandardOtherName($input: UpdateDataStandardOtherNameInputType!) {
        updateDataStandardOtherName( input:$input) {
            dataStandardOtherName {
                id
                name
            }
        }
    }
`;

const DELETE_DATA_STANDARD_OTHER_NAME = gql`
    mutation deleteDataStandardOtherName($id: ID!) {
        deleteDataStandardOtherName(id: $id) {
            ok
        }
    }
`;


const objectName = "Other name";

interface DataStandardOtherNameInputType {
    dataStandardId: any
    editable : boolean
};

function DataStandardOtherName(props:DataStandardOtherNameInputType) {


    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:""} );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState();
    
    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ_DATA_STANDARD , {variables:{id:props.dataStandardId}, 
        fetchPolicy: 'cache-and-network',
    });

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE_DATA_STANDARD_OTHER_NAME , {
        refetchQueries: [ { query: READ_DATA_STANDARD, variables: { id: props.dataStandardId } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError } ] = useMutation(UPDATE_DATA_STANDARD_OTHER_NAME, {
        refetchQueries: [ { query: READ_DATA_STANDARD, variables: { id: props.dataStandardId } } ], 
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    const [deleteMutation, { loading: deleteMutationLoading, error: deleteMutationError } ] = useMutation(DELETE_DATA_STANDARD_OTHER_NAME, {
        refetchQueries: [ { query: READ_DATA_STANDARD, variables: { id: props.dataStandardId } } ], 
        onCompleted(data) { message.success( objectName + " was deleted successfully.") } ,
        onError(error) {message.error(error.message)},
    });


    
    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add other name");
        setModalInitialValues({ name:"" } )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Add other name") {

                // if values['name'] is a comma separated list, create multiple other names
                if (values['name'].includes(",")) {
                    let otherNames = values['name'].split(",");

                    // remove any whitespaces before or after the comma but keep whitespaces inside the name
                    otherNames = otherNames.map((otherName:any) => otherName.trim());
                    
                    otherNames.forEach( (otherName:any) => {
                        let input = {
                            dataStandardId: props.dataStandardId,
                            name : otherName, 
                        };
                        createMutation( { variables: { input: input  } } );
                    })
                } else {
                    let input = {
                        dataStandardId: props.dataStandardId,
                        name : values['name'], 
                    };
                    createMutation( { variables: { input: input  } } );
                }
    
            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit other name")
        
        setModalInitialValues({ 
            name:input.name,
        })
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    function handleDelete(id:any) {

        deleteMutation( { variables: { id: id  } } );
    }

    
    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
    };

    const columns = [
        {
            title: 'Name',
            //dataIndex: 'name',
            editable: false,
            width: '80%',
            render: (text: any, record: any) => (
                <Button type="link" onClick={() => handleEdit(record)} disabled={!props.editable} data-testid={"data-standard-other-name-" + record.name}>{record.name}</Button>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Button type="link" onClick={() => handleEdit(record)} disabled={!props.editable} data-testid={"edit-data-standard-other-name-" + record.name}>Edit</Button>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)} disabled={!props.editable}>
                        <Button type="link" danger disabled={!props.editable} data-testid={"delete-data-standard-other-name-" + record.name}>Delete</Button>
                    </Popconfirm>
                </Space>

            ),
        },

    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
          //setSelectedMediaAction(selectedRows[0].id)
        }
    };

    if (queryLoading) return <Spin />;
    if (queryError) return <p>Error : {queryError.message}</p>;


    return (

            <div>
                <Button onClick={showModal} style={{marginBottom:20}} data-testid="add-alternative-name" disabled={!props.editable}>Add other name</Button>
                  
                    <div style={{height:500, overflow:"auto"}}>
                    
                        <Table 
                            loading={queryLoading}
                            dataSource={props.dataStandardId ? queryData.dataStandard.datastandardothernameSet : []}
                            columns = { columns } 
                            bordered
                            rowKey={record => record.id} 
                            size="small"
                            pagination={false}
                        />
                    </div>
                    
                

                            
                <Modal 
                    title= { modalTitel }
                    open={ isModalVisible } 
                    onOk = { handleModalOk }
                    onCancel = { handleModalCancel }
                    destroyOnClose = {true}
                    data-testid = "media-actions-modal"
                    width={800}
                    footer={[
                        <Button type="primary" form="DataStandardOtherNamesForm" key="submit" htmlType="submit" data-testid="add-other-name-submit" disabled={!props.editable}>
                            Submit
                        </Button>
                        ]}
                >
                    <Form
                        {...formLayout}  
                        id = "DataStandardOtherNamesForm"
                        onFinish={handleModalOk} 
                        initialValues={ modalInitialValues }
                    > 
                        <br/>
                        <Form.Item 
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input a name!' }]}
                        >
                            <Input data-testid="data-standard-other-name-input"/>
                        </Form.Item>

                        {/* info regarding adding multiple other names by comma separated list */}
                        <Alert
                            message="Add multiple other names using a comma separated list"
                            type="info"
                            showIcon
                            style={{marginBottom:20}}
                        />

                    </Form>

                </Modal>
        </div>

        );
    }


export default DataStandardOtherName;

