import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Select, Table, Card, Space, Popconfirm, Button, Form, Spin, Modal, Input, message, Row, Col, InputNumber, Alert, AutoComplete } from 'antd';
//import { pollInterval } from '../..';
import { CORE_CALC_SAVE_INTERFACE_FIELDS } from '../fragments';
const { TextArea } = Input;
const { Option } = Select;


const CREATE_CALC_SAVE_INTERFACE = gql`
    ${CORE_CALC_SAVE_INTERFACE_FIELDS}
    mutation createCalcSaveInterface($input: CreateCalcSaveInterfaceInputType!) {
        createCalcSaveInterface( input:$input) {
            calcSaveInterface {
                ...CalcSaveInterfaceFields
            }
        }
    }
`;

const UPDATE_CALC_SAVE_INTERFACE = gql`
    ${CORE_CALC_SAVE_INTERFACE_FIELDS}
    mutation updateCalcSaveInterface($input: UpdateCalcSaveInterfaceInputType!) {
        updateCalcSaveInterface( input:$input) {
            calcSaveInterface {
                ...CalcSaveInterfaceFields
            }
        }
    }
`;

const DELETE_CALC_SAVE_INTERFACE = gql`
    mutation deleteCalcSaveInterface($id: ID!) {
        deleteCalcSaveInterface( id:$id) {
            id
            ok
        }
    }
`;

const GET_CALC_SAVE_INTERFACE = gql`
    ${CORE_CALC_SAVE_INTERFACE_FIELDS}
    query calcSaveInterfaceTable ($id:ID!) {
        calcSaveInterfacesByCalculatorId(id:$id) {
                ...CalcSaveInterfaceFields
        } 
    }
`;

const GET_UNIQUES = gql`
    query calcSaveInterfaceTable ($topicId:ID) {
        uniqueVars(topicId:$topicId)
        uniqueParams(topicId:$topicId)
        uniqueMeta(topicId:$topicId)
    }
`;

const CREATE = CREATE_CALC_SAVE_INTERFACE;
const READ = GET_CALC_SAVE_INTERFACE;
const UPDATE = UPDATE_CALC_SAVE_INTERFACE;
const DELETE = DELETE_CALC_SAVE_INTERFACE;

const objectName = "Interface";

interface CalcSaveInterfaceTableInputType {
    topicId: String,
    calcId: String,
    selectedCalcInterface: any,
    setSelectedCalcInterface: Function,
};

function CalcSaveInterfaceTable(props: CalcSaveInterfaceTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [isModalAdd, setModalAdd] = useState(true); // is the modal in add or edit state

    const [isTypeParameter, setTypeParameter] = useState(false);
    const [isTypeVariable, setTypeVariable] = useState(false);
    const [isTypeOption, setTypeOption] = useState(false);
    const [isTypeMetaData, setTypeMetaData] = useState(false);

    const [isDirectionInput, setDirectionInput] = useState(false);

    const [modalInitialValues, setModalInitialValues] = useState({ name: "", description:"", interfaceType: "", direction: "", required: "", altNames: [], units: "", valueFloat: null, valueFloatMin:null, valueFloatMax:null, valueChar: "" });
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState();
    const [interfaceName, setInterfaceName] = useState("");
    const [nOutputs, setNOutputs] = useState(0);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(READ, { variables: { id: props.calcId  } , 
        fetchPolicy: "cache-and-network",
    });
    const { loading: queryLoading2, error: queryError2, data: queryData2 } = useQuery(GET_UNIQUES, { variables: { topicId: props.topicId } , 
        //pollInterval: pollInterval, 
    });


    const [createMutation] = useMutation(CREATE, {
        refetchQueries: [{ query: READ, variables: { id: props.calcId } }],
        onCompleted(data) { message.success(objectName + " was added successfully.") },
        onError(error) { message.error(error.message) },
    });

    const [updateMutation] = useMutation(UPDATE, {
        onCompleted(data) { message.success(objectName + " was edited successfully.") },
        onError(error) { message.error(error.message) },
    });

    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [{ query: READ, variables: { id: props.calcId } }],
        onCompleted(data) { message.success(objectName + " was deleted successfully.") },
        onError(error) { message.error(error.message) },
    });

    // ----------------------------------------------------------------------------------------

    // useEffect to set the selected calcInterface to empty the first time the component is rendered
    React.useEffect(() => {
        props.setSelectedCalcInterface("")
    }, []);

    // use effect to set the number of defined output interfaces
    React.useEffect(() => {
        if (queryData) {
            setNOutputs(queryData?.calcSaveInterfacesByCalculatorId.filter((calcInterface: any) => calcInterface.direction == "OUTPUT").length)
        }
    }, [queryData]);

    const showModal = (values: any) => {
        setModalTitel("Add interface")
        setInterfaceName("")
        setTypeParameter(false)
        setTypeVariable(false)
        setTypeOption(false)
        setTypeMetaData(false)
        setDirectionInput(false)
        
        setModalInitialValues({ name: "", description:"",interfaceType: "", direction: "", required: "", altNames: [], units: "", valueFloat: null, valueFloatMin:null, valueFloatMax:null, valueChar: "" })
        setModalAdd(true)
        setModalVisible(true)
    }

    const handleModalOk = (values: any) => {

        // check if interfaceName is valid
        if (!isInterfaceNameValid(interfaceName)) {
            message.error("Invalid name.")
            return
        }

        // adding
        if (modalTitel == "Add interface") {

            let input = {
                name: interfaceName,
                description: values['description'],
                calculatorId: props.calcId,
                interfaceType: values['interfaceType'],
                direction: values['direction'],
                required: false,
                altNames: values['altNames'],
                units: values['units'],
                valueFloat: values['valueFloat'],
                valueFloatMin: values['valueFloatMin'],
                valueFloatMax: values['valueFloatMax'],
                valueChar: values['valueChar'],
            };

            // if direction is output and type is metadata, display error message
            if (input.direction == "output" && input.interfaceType == "metadata") {
                message.error("Metadata can only be used as input.")
                return
            }

            createMutation({ variables: { input: input } });

            // editing
        } else {

            let input = {
                id: props.selectedCalcInterface,
                name: interfaceName,
                description: values['description'],
                interfaceType: values['interfaceType'],
                direction: values['direction'],
                required: false,
                altNames: values['altNames'],
                units: values['units'],
                valueFloat: values['valueFloat'],
                valueFloatMin: values['valueFloatMin'],
                valueFloatMax: values['valueFloatMax'],
                valueChar: values['valueChar']
            };

            updateMutation({ variables: { input: input } });
        }

        setModalVisible(false);
    };

    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };

    const handleEdit = () => {

        setModalTitel("Edit interface")


        // get the selected calcInterface
        const calcInterface = queryData.calcSaveInterfacesByCalculatorId.find((calcInterface: any) => calcInterface.id == props.selectedCalcInterface);

        setModalInitialValues({
            name: calcInterface.name,
            description: calcInterface.description,
            interfaceType: calcInterface.interfaceType.toLowerCase(),
            direction: calcInterface.direction.toLowerCase(),
            required: calcInterface.required,
            altNames: calcInterface.altNames,
            units: calcInterface.units,
            valueFloat: calcInterface.valueFloat,
            valueFloatMin: calcInterface.valueFloatMin,
            valueFloatMax: calcInterface.valueFloatMax,
            valueChar: calcInterface.valueChar,
        })
        setInterfaceName(calcInterface.name)
        setModalAdd(false)

        handleTypeChange(calcInterface.interfaceType.toLowerCase())
        handleDirectionChange(calcInterface.direction.toLowerCase())

        setModalVisible(true)
    }

    const handleDelete = () => {

        // get the selected calcInterface
        const calcInterface = queryData.calcSaveInterfacesByCalculatorId.find((calcInterface: any) => calcInterface.id == props.selectedCalcInterface);

        deleteMutation({ variables: { id: calcInterface.id } })
    }

    const handleTypeChange = (type: string) => {
        if (type == 'parameter') {
            setTypeParameter(true)
            setTypeVariable(false)
            setTypeOption(false)
            setTypeMetaData(false)
        } else if (type == 'variable') {
            setTypeParameter(false)
            setTypeVariable(true)
            setTypeOption(false)
            setTypeMetaData(false)
        } else if (type == 'option') {
            setTypeParameter(false)
            setTypeVariable(false)
            setTypeOption(true)
            setTypeMetaData(false)
        } else if (type == 'metadata') {
            setTypeParameter(false)
            setTypeVariable(false)
            setTypeOption(false)
            setTypeMetaData(true)
        }

    }

    const handleDirectionChange = (direction: string) => {
        
        // reset the interface type 
        //setTypeParameter(false)
        //setTypeVariable(false)
        //setTypeOption(false)
        //#setTypeMetaData(false)



        if (direction == 'input') {
            setDirectionInput(true)
        } else {
            setDirectionInput(false)
        }
    }

    const formLayout = {
        labelCol: { span: 8 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 12 },
    };

    const TableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            //editable: true,
            //width: '30%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Units',
            dataIndex: 'units',
            //editable: true,
            //width: '20%',
        },
        {
            title: 'I/O',
            dataIndex: 'direction',
            //editable: true,
            //width: '25%',
        },
        {
            title: 'Type',
            dataIndex: 'interfaceType',
            //editable: true,
            //width: '25%',
        }
    ]

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            props.setSelectedCalcInterface(selectedRows[0].id)
        }
    };

    function onChangeInterfaceNameAutoComplete(e: any) {
        setInterfaceName(e)
    }

    function onChangeInterfaceNameInput(e: any) {
        setInterfaceName(e.target.value)
    }

    function isInterfaceNameValid(name:string) {
        if (name == "") {
            return false
        } else {
            // Name must start with a letter or underscore and can only contain letters, numbers, and underscores.
            const regex = /^[a-zA-Z_][a-zA-Z0-9_]*$/
            return regex.test(name)
        }
    }


    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );

    if (queryError2) return (
        <div>Error: {queryError2.message} </div>
    );

    if ((queryLoading && !queryData) || queryLoading2) return (
        <Card><Spin /></Card>
    );

    return (

        <div style={{ minWidth: 600 }}>
            <Card title="Calculator interfaces" >
                <Row>
                    <Col span={2} >
                        <Space direction="vertical">
                            <Button className="button_sidebar" onClick={showModal} data-testid="add-calc-interface"> Add </Button>
                            <Button className="button_sidebar" onClick={handleEdit} disabled={props.selectedCalcInterface == ""} data-testid="edit-calc-interface"> Edit </Button>

                            <Popconfirm title="Delete interface?" onConfirm={handleDelete} disabled={props.selectedCalcInterface == ""}>
                                <Button className="button_sidebar"  disabled={props.selectedCalcInterface == ""} style={{width:100}} data-testid="delete-calc-interface"> Delete </Button>
                            </Popconfirm>

                        </Space>
                    </Col>
                    <Col span={1} ></Col>
                    <Col span={21}>
                        { nOutputs == 0 &&
                            <Alert message="Warning: No output interfaces have been defined." type="warning" showIcon style={{marginBottom:10}}/>
                        }
                        <Table
                            loading={queryLoading}
                            dataSource={queryData.calcSaveInterfacesByCalculatorId}
                            columns={TableColumns}
                            bordered
                            rowKey={record => record.id}
                            size="small"
                            pagination={false}
                            //scroll={{ x:150, y: 500 }}
                            rowSelection={{
                                type: "radio",
                                ...rowSelection,
                            }}
                        />
                    </Col>
                </Row>

            </Card>

            <Modal
                title={modalTitel}
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="primary" form="CalcInterfaceForm" key="submit" htmlType="submit" data-testid="submit-calc-interface">
                        Submit
                    </Button>
                ]}
            >
                <Form
                    {...formLayout}
                    id="CalcInterfaceForm"
                    onFinish={handleModalOk}
                    initialValues={modalInitialValues}
                >

                    <Form.Item label="Direction:" name="direction" rules={[{ required: true }]}>
                        <Select onChange={handleDirectionChange} style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="direction-select" >
                            <Option value="input">Input</Option>
                            <Option value="output">Output</Option>
                        </Select>
                        
                        {/*<Tooltip title="The interface can be an input or an output.">
                            <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                        </Tooltip>*/}
                    </Form.Item>

                    <Form.Item label="Type:" name="interfaceType" rules={[{ required: true }]}>
                        <Select onChange={handleTypeChange} style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="type-select">
                            <Option value="variable">Variable</Option>
                            <Option value="parameter">Parameter</Option>
                            { isDirectionInput &&
                                <Option value="metadata" disabled={ !isDirectionInput }>Metadata</Option>
                            }
                        </Select>
                        
                        {/*<Tooltip title="The type of the interface can be a variable, parameter, or metadata.">
                            <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                        </Tooltip>*/}

                    </Form.Item>

                    <Form.Item label="Name:" required >

                        { isTypeVariable && isDirectionInput &&
                            <div>
                                <AutoComplete
                                    showSearch
                                    style={{float:"left" , width:'calc(100% - 30px)'}}
                                    value = {interfaceName}
                                    maxLength={100}
                                    onChange={onChangeInterfaceNameAutoComplete}
                                    options={queryData2.uniqueVars.map((item: any) => ({ value: item }))}
                                    data-testid="name-input"
                                    filterOption={(inputValue, option:any) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                >
                                    {queryData2.uniqueVars.map((item: any) => (
                                        <Option key={item} value={item}>{item}</Option>
                                    ))}
                                </AutoComplete>
                                
                                {/*<Tooltip title="The name of the variable.">
                                    <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                                </Tooltip>*/}
                            
                            </div>
                        }
                        { isTypeParameter && isDirectionInput &&
                            <div>
                                <AutoComplete
                                    style={{float:"left" , width:'calc(100% - 30px)'}}
                                    value = {interfaceName}
                                    onChange={onChangeInterfaceNameAutoComplete}
                                    maxLength={100}
                                    data-testid="name-input"
                                    options={queryData2.uniqueParams.map((item: any) => ({ value: item }))}
                                    filterOption={(inputValue, option:any) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                >
                                    {queryData2.uniqueParams.map((item: any) => (
                                        <Option key={item} value={item}>{item}</Option>
                                    ))}
                                </AutoComplete>
                                {/*<Tooltip title="The name of the parameter.">
                                        <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                                </Tooltip>*/}
                            </div>
                        }
                        
                        { isTypeMetaData && isDirectionInput &&
                            <div>
                                <AutoComplete
                                        style={{float:"left" , width:'calc(100% - 30px)'}}
                                        value = {interfaceName}
                                        onChange={onChangeInterfaceNameAutoComplete}
                                        maxLength={100}
                                        data-testid="name-input"
                                        options={queryData2.uniqueMeta.map((item: any) => ({ value: item }))}
                                        filterOption={(inputValue, option:any) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                >
                                    {queryData2.uniqueMeta.map((item: any) => (
                                        <Option key={item} value={item}>{item}</Option>
                                    ))}
                                </AutoComplete>
                                {/*<Tooltip title="The name of the metadata.">
                                                <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                                </Tooltip>*/}
                            </div>
                       }

                        { !isDirectionInput &&
                            <div>
                                <Input maxLength={100} 
                                    style={{float:"left" , width:'calc(100% - 30px)'}}
                                    value = {interfaceName}
                                    onChange={onChangeInterfaceNameInput}
                                    data-testid="name-input"
                                />
                                {/*<Tooltip title="The name of the output. The generated variable or parameter will have this name.">
                                    <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                                </Tooltip>*/}
                            </div>
                        }

                        { isDirectionInput && !isTypeMetaData && !isTypeParameter && !isTypeVariable &&
                            <div>
                                <Input maxLength={100} 
                                    style={{float:"left" , width:'calc(100% - 30px)'}}
                                    value = {interfaceName}
                                    onChange={onChangeInterfaceNameInput}
                                    data-testid="name-input"
                                />
                                {/*<Tooltip title="The name of the interface.">
                                    <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                                </Tooltip>*/}
                            </div>
                        }

                        { isInterfaceNameValid(interfaceName) || interfaceName =="" ?
                            <div></div>
                            :
                            <div style={{color:'red'}}>Name must start with a letter or underscore and can only contain letters, numbers, and underscores.</div>
                        }

                    </Form.Item>
                        
                    <Form.Item label="Description:" name="description" rules={[{ required: false }]} >
                        <TextArea maxLength={1000} style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="description-input"/>
                        {/*<Tooltip title="A description of the interface.">
                            <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                        </Tooltip>*/}
                    </Form.Item>
                    
                    { !isTypeMetaData &&
                    <Form.Item label="Units:" name="units" rules={[{ required: false }]}>
                        <Input maxLength={20} style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="units-input"/>
                    </Form.Item>
                    }

                    <Form.Item label="Other names:" name="altNames" rules={[{ required: false }]} style={ isTypeOption || !isDirectionInput ? { display: 'none'} : {display:''}} >
                        <TextArea maxLength={1000} style={{float:"left" , width:'calc(100% - 30px)'}}  data-testid="altNames-input"/>
                        {/*<Tooltip title="A comma-separated list of alternative names for the interface. If a process has a variable or parameter that matches one of these names, it will be used as the input.">
                            <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                        </Tooltip>*/}
                    </Form.Item>
                    
                    <Form.Item label="Min:" name="valueFloatMin"  style={ (!isTypeVariable || isDirectionInput) ? { display: 'none'} : {display:''}} >
                            <InputNumber style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="valueFloatMin-input"/>
                            {/*<Tooltip title="The minimum allowed value of the output variable. Values smaller than this will be dropped from the output.">
                                <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                            </Tooltip>*/}
                    </Form.Item>

                    <Form.Item label="Max:" name="valueFloatMax" style={ (!isTypeVariable || isDirectionInput) ? { display: 'none'} : {display:''}} >
                            <InputNumber style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="valueFloatMax-input"/>
                            {/*<Tooltip title="The maximum value of the output variable. Values larger than this will be dropped from the output.">
                                <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                            </Tooltip>*/}
                    </Form.Item>

                    <Form.Item label="Fallback value:" name="valueFloat" rules={[{ required: false }]} style={ (!isTypeParameter && !isTypeVariable)|| !isDirectionInput ? { display: 'none'} : {display:''}} >
                            <InputNumber style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="valueFloat-input"/>
                            {/*<Tooltip title="Fallback value if the parameter or variable is not found in the process.">
                                <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                            </Tooltip>*/}
                    </Form.Item>
                    
                    {/*
                    <Form.Item label="Value:" name="valueChar" rules={[{ required: false }]} style={ isTypeParameter || isTypeVariable  || isTypeMetaData || !isDirectionInput ? { display: 'none'} : {display:''}} >
                        <Input maxLength={200} style={{float:"left" , width:'calc(100% - 30px)'}} />
                        {/*<Tooltip title="The value of an option.">
                            <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                        </Tooltip>
                    </Form.Item>
                    */}

                </Form>

            </Modal>

        </div>

    );
}



export default CalcSaveInterfaceTable;