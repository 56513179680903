import { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, Collapse, Steps, Skeleton, Form} from 'antd';
import PageHeader from '../components/PageHeader';
import HeaderExtra from "../components/HeaderExtra";
import ReactGA from 'react-ga';
import MediaTable from "../components/media/MediaTabs";
import SelectTopic from '../components/topics/SelectTopic';
import useWindowDimensions from '../components/useWindowDimensions';
import { CORE_MEDIA_FIELDS } from '../components/fragments';

const { Panel } = Collapse;
const { Meta } = Card;
const { Step } = Steps;

export const GET_USER_PROFILE = gql`
    ${CORE_MEDIA_FIELDS}
    query userProfile {
        userProfile {
            id
            selectedTeam {
                id
                name
                topicSet {
                    id
                    name
                    mediaSet {
                        ...CoreMediaFields
                    }
                
                }
            }
        }
    }
`

function Media() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/media/');
    const { height, width } = useWindowDimensions();

    const [selectedTopicId, setSelectedTopic] = useState("")

    const { loading, error, data } = useQuery(GET_USER_PROFILE, { fetchPolicy: "cache-and-network" });

    if (error) return ( 
        <Card>Error: {error.message} </Card>
    );

    if (loading && !data) return (
        <Card><Skeleton active/></Card>
    );

    const routes = [
      {
        path: '',
        breadcrumbName: "Team: " + data.userProfile.selectedTeam.name ,
      }
    ];

    return(  
      <div>

        <PageHeader
          title="Media"
          extra={<HeaderExtra/>}
          breadcrumb={{ routes: routes }}
        />

        <div >

            <Card style={{ marginBottom: 10 }}>
              <Form.Item label="Select a collection" style={{ marginBottom: 0, marginTop:10, marginRight:10 }}>
                <div style={{ width: 200 }}>
                  <SelectTopic selectedTopicId={selectedTopicId } setSelectedTopic={setSelectedTopic} teamId = {data.userProfile.selectedTeam.id}/>
                </div>
              </Form.Item>
            </Card>


          <div style={{  width: "100%" , height:height-230, overflow:"hidden"}}>
            <MediaTable topicId={selectedTopicId}/>
          </div>

          
      </div>
    </div>
    )
  }
  
  export default Media;
