import {useState, useEffect, useMemo} from 'react';
import SelectTopic from '../topics/SelectTopic';
import { Modal, Skeleton, Select, Form, message } from 'antd';
import { gql, useQuery , useMutation} from '@apollo/client';
import { CORE_MEDIA_FIELDS, CORE_MIXTURE_FIELDS, CORE_COMPOUND_FIELDS, CORE_UNDEFINED_INGREDIENT_FIELDS } from '../fragments';

const { Option } = Select;

const GET_TEAMS = gql`

    ${CORE_MEDIA_FIELDS}
    ${CORE_MIXTURE_FIELDS}
    ${CORE_COMPOUND_FIELDS}
    ${CORE_UNDEFINED_INGREDIENT_FIELDS}


    query GetTeams {
        teams {
            id
            name
            topicSet {
                id
                name
                mediaSet {
                    ...CoreMediaFields
                }
                mixtureSet {
                    ...CoreMixtureFields
                }
                compoundSet {
                    ...CoreCompoundFields
                }

                undefinedingredientSet {
                    ...CoreUndefinedIngredientFields
                }

                
            }
        }

        userProfile {
            id
            selectedTeam {
                id
            }
        }
    }
`;

const IMPORT_MEDIA = gql`

    ${CORE_MEDIA_FIELDS}
    ${CORE_MIXTURE_FIELDS}
    ${CORE_COMPOUND_FIELDS}
    ${CORE_UNDEFINED_INGREDIENT_FIELDS}

    mutation ImportMedia( $input: ImportMediaInputType! ) {
        importMedia( input: $input ) {
            ok
            topic {
                id
                mediaSet {
                    ...CoreMediaFields
                }
                mixtureSet {
                    ...CoreMixtureFields
                }
                compoundSet {
                    ...CoreCompoundFields
                }
                undefinedingredientSet {
                    ...CoreUndefinedIngredientFields
                }
            }
        }
    }
`;


interface ImportMediaInputType {
    open: boolean
    setOpen: Function
    importType: string
    targetTopicId: string

};

function ImportMedia(props:ImportMediaInputType) {

    let objectName = "Media"
    if (props.importType === "mixture") {
        objectName = "Mixture"
    } else if (props.importType === "compound") {
        objectName = "Compound"
    } else if (props.importType === "undefinedingredient") {
        objectName = "Undefined Ingredient"
    }

    const { data, loading, error } = useQuery(GET_TEAMS);
    
    const [importMedia] = useMutation(IMPORT_MEDIA, {
        onCompleted(data) { message.success( objectName + " was imported successfully.") } ,
        refetchQueries: 'active',
    }
    );
    
    
    const [selectedTopicId, setSelectedTopic] = useState("")
    const [selectedObjectId, setSelectedObject] = useState("")

    // use effect to set the selected topic to the first topic in the list
    // when the data is loaded
    useEffect(() => {
        if (data?.teams && data.teams.length > 0 && data.teams[0].topicSet && data.teams[0].topicSet.length > 0) {
            setSelectedTopic(data.teams[0].topicSet[0].id)
        }
    }
    , [data])

    const selectedTeam = data?.teams.filter((team:any) => team.id === data.userProfile.selectedTeam.id)[0]
    const selectedTopic = selectedTeam?.topicSet.filter((topic:any) => topic.id === selectedTopicId)[0]
    const mediaSet = selectedTopic?.mediaSet

    if (loading ) return (
        <Modal
            title={"Import " + objectName}
            open={props.open}
            onOk={() => props.setOpen(false)}
            onCancel={() => props.setOpen(false)}
            //width={800}
        >
            <Skeleton active />
        </Modal>
    )

    if (error) return (
        <Modal
            title={"Import " + objectName}
            open={props.open}
            onOk={() => props.setOpen(false)}
            onCancel={() => props.setOpen(false)}
            //width={800}
        >
            <p>Error : {error.message}</p>
        </Modal>
    )

    // create the options for the topic select
    // each topic is an option. the team of each topic is the group
    
    let topicOptions:any = [];

    // get the teams
    const teams = data.teams.map((team:any) => team.name);

    for (let i=0; i<teams.length; i++) {

        // create the options for each team
        let teamOptions:any = [];
        for (let j=0; j< data.teams[i].topicSet.length; j++) {
            teamOptions.push({ label: data.teams[i].topicSet[j].name, value: data.teams[i].topicSet[j].id });
        }
        
        topicOptions.push({ label: 'Team: ' + teams[i], options: teamOptions });
    }

    let options:any = []
    if (props.importType === "media") {
        options = mediaSet?.map((media:any) => {
            return { label: media.name, value: media.id }
        })
    } else if (props.importType === "mixture") {
        options = selectedTopic?.mixtureSet.map((mixture:any) => {
            return { label: mixture.name, value: mixture.id }
        })
    } else if (props.importType === "compound") {
        options = selectedTopic?.compoundSet.map((compound:any) => {
            return { label: compound.name, value: compound.id }
        })
    } else if (props.importType === "undefinedingredient") {
        options = selectedTopic?.undefinedingredientSet.map((undefinedingredient:any) => {
            return { label: undefinedingredient.name, value: undefinedingredient.id }
        })
    }

    function handleImport() {
        let input = {
            targetTopicId: props.targetTopicId,
            objectId: selectedObjectId,
            objectType: props.importType
        }

        importMedia({variables: { input: input }})
        props.setOpen(false)
    }

    return (
        <Modal
            title={"Import " + objectName}
            open={props.open}
            onOk={handleImport}
            onCancel={() => props.setOpen(false)}
            //width={800}
        >

            <br/>

            <Form
                layout="horizontal"
                name="basic"
                labelCol={{ span: 8 }}
                //wrapperCol={{ span: 16 }}
            >

            <Form.Item
                label="Source Collection"
                name="topic"
                //rules={[{ required: true, message: 'Please select a collection' }]}
            >

                <Select
                    showSearch
                    style={{ width: "100%" , marginBottom: "20px"}}
                    placeholder="Select a collection"
                    onChange={(value) => {setSelectedTopic(value); setSelectedObject("")}}
                    options = {topicOptions} 
                    //onChange={handleTopicChange}
                    value={selectedTopicId}
                    data-testid="topicSelect"
                    filterOption = {(input, option:any) =>
                        option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                        
            />

            </Form.Item>

            <Form.Item
                label={objectName}
                name={props.importType}
                //rules={[{ required: true, message: 'Please select a media' }]}
            >

                <Select
                    showSearch
                    style={{ width: "100%" , marginBottom: "20px"}}
                    placeholder={"Select a " + objectName}
                    onChange={(value) => setSelectedObject(value)}
                    options = { options }
                    //value={selectedMediaId}
                    data-testid = {props.importType + "-select"}
                    filterOption = {(input, option:any) =>
                        option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } 
                    value={selectedObjectId}
                />

            </Form.Item>

            </Form>

            
            

        </Modal>
    )

}

export default ImportMedia