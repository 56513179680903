import { useState , useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Card, Button, Form, Skeleton, Modal, Input, message, Select , Alert, AutoComplete} from 'antd';
import { pollInterval } from '../..';

import { CORE_KPI_FIELDS, CORE_KPI_TABLE_FIELDS } from '../fragments';

const { Option } = Select;


const GET_SELECTED_PROCESSES = gql`
    query selectedProcesses ($analysisId:ID!) {  
        selectedProcesses (analysisId:$analysisId) {
            id
            name
        }

        uniqueVars (analysisId:$analysisId)
        uniqueParams (analysisId:$analysisId)
        uniqueMeta (analysisId:$analysisId)
    }
    
`;


const GET_KPIS = gql`
    ${CORE_KPI_FIELDS}
    query KPIs ($analysisId:ID!) {
        KPIs (analysisId:$analysisId) {
            ...CoreKPIFields
        }
    }
`

const CREATE_KPI = gql`
    ${CORE_KPI_FIELDS}
    mutation createKPI($input: CreateKPIInputType!) {
        createKPI( input:$input) {
            kpi {
                ...CoreKPIFields
            }
        }
    }
`;

const UPDATE_KPI = gql`
    ${CORE_KPI_FIELDS}
    mutation updateKPI($input: UpdateKPIInputType!) {
        updateKPI( input:$input) {
            kpi {
                ...CoreKPIFields
            }
        }
    }
`;

const GET_KPI_TABLE = gql`
    ${CORE_KPI_TABLE_FIELDS}
    query kpiTable ($input:KpiTableInputType!) {  
        kpiTable(input:$input) {
            ...CoreKPITableFields
        }
    }
`;

const CREATE = CREATE_KPI;

const objectName = "KPI";

interface KPIAnalysisAddKPIInputType {
    view?: any
    analysis: any
    addModalOpen?: boolean
    setAddModalOpen?: any
    editModalOpen?: boolean
    setEditModalOpen?: any
    modalId?: string
    KPIs: any
}

function KPIAnalysisAddKPI(props: KPIAnalysisAddKPIInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [xSelectVisible, setXSelectVisible] = useState(false)
    const [ySelectVisible, setYSelectVisible] = useState(false)
    const [unitsVisible, setUnitsVisible] = useState(false)
    const [selectedKPIFunction, setSelectedKPIFunction] = useState("")
    const [selectedYVariable, setSelectedYVariable] = useState("")
    const [selectedXVariable, setSelectedXVariable] = useState("")
    const [suggestedKPIName, setSuggestedKPIName] = useState("")
    const [formInitialValues, setFormInitialValues] = useState({})
    const [loadingModalTitle, setLoadingModalTitle] = useState("")


    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations
    const { loading: queryLoading1, error: queryError1, data: queryData1 } = useQuery(GET_SELECTED_PROCESSES, { variables: { 'analysisId': props.analysis.id } , 
        fetchPolicy: 'cache-and-network'

    })

    // put the ids of selected processes in an array
    const selectedProcesses = queryData1 ? queryData1.selectedProcesses.map((process:any) => process.id) : [];

    //const { loading: queryLoading2, error: queryError2, data: queryData2 } = useQuery(GET_KPI_TABLE , { variables: { 'input': { 'kpiDefinitionId': props.selectedKpiDefinition, 'processIds': selectedProcesses } } })

    // loop over analysis.processes and create an array with the ids
    let processIds:any = []
    props.analysis.processes.forEach((process: any) => {
        processIds.push(process.id)
    })


    const [createMutation, { loading: createMutationLoading, error: createMutationError }] = useMutation(CREATE, {
        refetchQueries: [
            { query: GET_KPIS , variables: { 'analysisId': props.analysis.id } },
            { query: GET_KPI_TABLE, variables: { 'input': { 'analysisId':props.analysis.id, 'processIds': processIds } } },
        ],

        onCompleted(data) { message.success(objectName + " was added successfully.")  },
        onError(error) { message.error(error.message) },
    });

    // update mutation
    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError }] = useMutation(UPDATE_KPI, {
        refetchQueries: [
            { query: GET_KPIS, variables: { 'analysisId': props.analysis.id } },
            { query: GET_KPI_TABLE, variables: { 'input': { 'analysisId':props.analysis.id, 'processIds': processIds } } },
        ],

        onCompleted(data) { message.success(objectName + " was updated successfully.") },
        onError(error) { message.error(error.message) },
    });


    // ----------------------------------------------------------------------------------------
    // use effect to set the suggested KPI name
    useEffect(() => {
        if (selectedKPIFunction == "interp") {
            setSuggestedKPIName(selectedYVariable + "_at_" + selectedXVariable)
        } else {
            setSuggestedKPIName(selectedYVariable + "_" + selectedKPIFunction)
        }
        setSuggestedKPIName(selectedKPIFunction)
        //console.log(selectedKPIFunction)
    }, [selectedKPIFunction, selectedYVariable, selectedXVariable])

    // use effect to set the form initial values
    useEffect(() => {
        
        // get the initial values from the query using the modalId
        if (props.editModalOpen) {
            props.setAddModalOpen(false)
            let initialValues = props.KPIs.find((kpi:any) => kpi.id == props.modalId)
            setFormInitialValues(
                {
                    name: initialValues.name,
                    x: initialValues.x,
                    y: initialValues.y,
                    function: initialValues.f,
                    units: initialValues.units,
                    interpXValue: initialValues.interpXValue
                }
            )

            setSelectedKPIFunction(initialValues.f)
            setSelectedYVariable(initialValues.y)
            setSelectedXVariable(initialValues.x)
            
        }


    }, [props.editModalOpen])

    // useEffect to set the visibility of x, y and units based on the selected KPI function
    useEffect(() => {
        switch (selectedKPIFunction) {
            case 'max':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                break

            case 'min':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                break

            case 'start':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                break

            case 'end':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                break

            case 'interp':
                setXSelectVisible(true)
                setYSelectVisible(true)
                setUnitsVisible(true)
                break

            case 'metadata':
                setXSelectVisible(false)
                setYSelectVisible(false)
                setUnitsVisible(false)
                break

            case 'parameter':
                setXSelectVisible(false)
                setYSelectVisible(false)
                setUnitsVisible(true)
                break

        }
    }, [selectedKPIFunction])

    // use effect to set the form initial values if the mode is add
    useEffect(() => {
        if (props.addModalOpen) {
            
            props.setEditModalOpen(false)
            setSelectedKPIFunction("")
            setSelectedYVariable("")
            setSelectedXVariable("")
            setXSelectVisible(false)
            setYSelectVisible(false)
            setUnitsVisible(false)

            setFormInitialValues(
                {
                    name: "",
                    x: "",
                    y: "",
                    function: "",
                    units: "",
                    interpXValue: "",
                }
            )
        }
    }, [props.addModalOpen])


    // ----------------------------------------------------------------------------------------
    // functions

    function onNameChange(value: any) {
        setSuggestedKPIName(value)
    }

    function onYVariableSelect(value: any) {
        setSelectedYVariable(value)
        if (selectedKPIFunction == "interp") {
            setSuggestedKPIName(value + "_at_" + selectedXVariable)
        } else {
            setSuggestedKPIName(value + "_" + selectedKPIFunction)
        }
    }

    function onXVariableSelect(value: any) {
        setSelectedXVariable(value)
        if (selectedKPIFunction == "interp") {
            setSuggestedKPIName(selectedYVariable + "_at_" + value)
        } else {
            setSuggestedKPIName(value + "_" + selectedKPIFunction)
        }
    }

    const handleModalOk = (values: any) => {

        //console.log(values)
        //console.log(clientVars.kpiAnalysisSelectedKpiDefinition)

        if (props.addModalOpen) {

            let input = {
                //kpiDefinitionId: props.view.selectedKpiDefinition.id,
                name: values.name,
                x: values.x,
                y: values.y,
                f: values.function,
                units: values.units,
                interpXValue: values.interpXValue,
                analysisId: props.analysis.id,
            };

            setLoadingModalTitle("Creating KPI...")

            // perform the mutation to create
            createMutation({ variables: { input: input } });

            props.setAddModalOpen(false)
            props.setEditModalOpen(false)

        } else if (props.editModalOpen) {

            let input = {
                id: props.modalId,
                name: values.name,
                x: values.x,
                y: values.y,
                f: values.function,
                units: values.units,
                interpXValue: values.interpXValue,
            };

            setLoadingModalTitle("Updating KPI...")

            // perform the mutation to edit
            updateMutation({ variables: { input: input } });

            props.setEditModalOpen(false)
            props.setAddModalOpen(false)
        }
    };

    // ----------------------------------------------------------------------------------------


    const handleModalCancel = (values: any) => {
        props.setAddModalOpen(false)
        props.setEditModalOpen(false)
    };

    const formLayout = {

        labelCol: { span: 6 },
    };

    const tailLayout = {
        wrapperCol: { offset: 6, span: 12 },
    };


    if (queryError1) return (
        <div>Error 1: {queryError1.message} </div>
    );

    /*
    if (queryError2) return (
        <div>Error 2: {queryError2.message} </div>
    );
    */

    if (queryLoading1 && !queryData1) return (
        <div></div>
    );

    //console.log(queryData2)


    if (queryData1.kpiAnalysisSelectedKpiDefinition == "") {
        return (<div>Select a KPI set.</div>)
    }

    let uniqueVars = queryData1.uniqueVars;

    /*
    function onFunctionSelect(value: any) {
        switch (value) {
            case 'max':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                setSelectedKPIFunction('max')
                break

            case 'min':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                setSelectedKPIFunction('min')
                break

            case 'start':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                setSelectedKPIFunction('start')
                break

            case 'end':
                setXSelectVisible(false)
                setYSelectVisible(true)
                setUnitsVisible(true)
                setSelectedKPIFunction('end')
                break

            case 'interp':
                setXSelectVisible(true)
                setYSelectVisible(true)
                setUnitsVisible(true)
                setSelectedKPIFunction('interp')
                break

            case 'metadata':
                setXSelectVisible(false)
                setYSelectVisible(false)
                setUnitsVisible(false)
                setSelectedKPIFunction('metadata')
                break

            case 'parameter':
                setXSelectVisible(false)
                setYSelectVisible(false)
                setUnitsVisible(true)
                setSelectedKPIFunction('parameter')
                break

        }
    }*/

    function onFunctionSelect(value: any) {
        setSelectedKPIFunction(value)
    }

    return (

        <div style={{ width: '100%' }} >

            <Modal
                title={ props.addModalOpen ? "Add KPI" : "Edit KPI"}
                open={props.addModalOpen || props.editModalOpen}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="primary" form="KPIForm" key="submit" htmlType="submit" data-testid="submit-button">
                        Submit
                    </Button>
                ]}
            >

                {/* if the number of selected processes is zero, show a warning */}
                {selectedProcesses.length == 0 && 
                    <div>
                        <Alert message="Warning: No processes have been selected for the KPI Analysis View. To get suggestions for variable names, select some processes first." type="warning" />
                        <br />
                    </div>        
                }
                
                <br />

                <Form
                    {...formLayout}
                    id="KPIForm"
                    onFinish={handleModalOk}
                    initialValues={formInitialValues}
                >

                    <Form.Item label="Function:" name="function" rules={[{ required: true, message: 'Please specify a function.' }]} >
                        <Select onSelect={onFunctionSelect} data-testid="function-select">
                            <Option value="max">Max</Option>
                            <Option value="min">Min</Option>
                            <Option value="start">Start</Option>
                            <Option value="end">End</Option>
                            <Option value="interp">Interpolation</Option>
                            <Option value="metadata">Metadata</Option>
                            <Option value="parameter">Parameter</Option>
                            
                        </Select>
                    </Form.Item>

                    <Form.Item label="Y-Variable:" name="y" rules={[{ required: false }]} style={!ySelectVisible ? { display: 'none' } : { display: '' }}>
                        
                        <AutoComplete
                            onChange={onYVariableSelect}
                            showSearch
                            filterOption={(input, option:any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Y-Variable"
                            optionFilterProp="children"
                            data-testid="y-variable-select"
                        >
                            <Option key="ProcessTime" value="ProcessTime">ProcessTime</Option>
                            {uniqueVars.map((uniqueVar: any) => { return (<Option key={uniqueVar} value={uniqueVar}>{uniqueVar}</Option>) })}
        
                        </AutoComplete>

                    </Form.Item>

                    <Form.Item label="X-Variable:" name="x" rules={[{ required: false }]} style={!xSelectVisible ? { display: 'none' } : { display: '' }}>

                        <AutoComplete
                            onChange={onXVariableSelect}
                            showSearch
                            filterOption={(input, option:any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Y-Variable"
                            optionFilterProp="children"
                            //onSearch={onSearch}
                            className='my-dropdown-class'
                            data-testid="x-variable-select"
                        >
                            <Option key="ProcessTime" value="ProcessTime">ProcessTime</Option>
                            {uniqueVars.map((uniqueVar: any) => { return (<Option key={uniqueVar} value={uniqueVar}>{uniqueVar}</Option>) })}
        
                        </AutoComplete>


                    </Form.Item>

                    { selectedKPIFunction == "interp" &&
                        <Form.Item label="Value of X:" name="interpXValue" rules={[{ required: true, message: 'Please specify a value for X.' }]}>
                            <Input data-testid="interp-x-value" />
                        </Form.Item>
                    }

                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <AutoComplete
                            onChange={onNameChange}
                            showSearch
                            placeholder="Name"
                            optionFilterProp="children"
                            filterOption={(input, option:any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            className='my-dropdown-class'
                            //value={suggestedKPIName}
                            data-testid="kpi-name-select"
                        >
                            {/* if type is parameter or metadata, then show the unique parameters or metadata */}
                            {selectedKPIFunction == "parameter" &&
                                queryData1.uniqueParams.map((uniqueParam: any) => { return (<Option key={uniqueParam} value={uniqueParam}>{uniqueParam}</Option>) })
                            }
                            {selectedKPIFunction == "metadata" &&
                                queryData1.uniqueMeta.map((uniqueMeta: any) => { return (<Option key={uniqueMeta} value={uniqueMeta}>{uniqueMeta}</Option>) })
                            }

                            {/* if type is max, min, start, end, or interp, then show the unique variables */}
                        </AutoComplete>

                    </Form.Item>

                    <Form.Item label="Output units:" name="units" rules={[{ required: false }]} style={!unitsVisible ? { display: 'none' } : { display: '' }} >
                        <Input maxLength={30} data-testid="units-input" />
                    </Form.Item>


                </Form>

            </Modal>

            <Modal 
                title={ loadingModalTitle }
                open={createMutationLoading || updateMutationLoading} footer={null} closable={false} >
                    <Skeleton active/>
            </Modal>

        </div>
    );
}


export default KPIAnalysisAddKPI;

