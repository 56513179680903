import React, { useState, useEffect } from 'react';
import { Progress, Modal, Button, Tooltip } from 'antd';
import localforage from 'localforage';
import { gql, useQuery } from '@apollo/client';
import { processesClient } from '../Apollo';

const MAX_PROCESSES = 5000; // Maximum number of processes considered

const ProcessesProgress = () => {
  const [processCount, setProcessCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const countProcesses = async () => {
      localforage.config({
        name: 'storage'
      });

      try {
        const keys = await localforage.keys();
        setProcessCount(keys.length);
      } catch (error) {
        console.error('Failed to count processes:', error);
      }
    };

    countProcesses();
    const interval = setInterval(countProcesses, 10000); // Update every 10 seconds
    return () => clearInterval(interval);
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const clearStorage = async () => {
    await localforage.clear();
    setProcessCount(0);
    processesClient([]);
    console.log('Storage cleared successfully');
  };

  return (
    <div>
      
          <p>Number of processes in local storage: {processCount} / {MAX_PROCESSES}</p>
          <Progress
            percent={Math.round((processCount / MAX_PROCESSES) * 100)}
            status="active"
            //style={{ width: 400}}
          />

          <br/>

          <Button key="clear" onClick={clearStorage} style={{marginTop:10, marginBottom:10}}>
            Clear process storage
          </Button>

      
    </div>
  );
};

export default ProcessesProgress;
