import React from 'react';
import { Select, Card , Spin, Modal, message} from 'antd';
import { useQuery, gql } from '@apollo/client';
import VariablePlot from './VariablePlot';

const GET_VARIABLE = gql`
    query Variable ($id:ID!) {  
        variableById(id:$id) {
            id
            name
            description
            vartype
            time
            data
            units
            process {
                id
                name
            }
        }
    }
`;

interface VariableModalProps {
    isVariableModalVisible: any
    setIsVariableModalVisible: Function
    variableId:string
}

function VariableModal(props:VariableModalProps) {

    const { loading, error, data } = useQuery(GET_VARIABLE , {variables:{id:props.variableId}});


    function onOk() {
        props.setIsVariableModalVisible(false);
    }

    function onCancel() {
        props.setIsVariableModalVisible(false);
    }

    if (loading) {
        return (
            <Modal
                title="Variable"
                open={props.isVariableModalVisible}
                onOk={onOk}
                onCancel={onCancel}
            >
                <Spin />
            </Modal>
        )
    }

    if (error) {
        return(
            <div></div>
        )
    }

    if (data && data.variableById) {
    return (

        <Modal
            title={data.variableById.name + " [" + data.variableById.units + "]"}
            open={props.isVariableModalVisible}
            onCancel = {onCancel}    
            onOk = {onOk}
            width = {800}
            destroyOnClose={true}
            footer={null}   
        >

            <VariablePlot id = {props.variableId} /> 

        </Modal>
        
    )
    } else {
        return (
            <Modal
                title="Variable"
                open={props.isVariableModalVisible}
                onOk={onOk}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={null}
            >
                <Spin />
            </Modal>
        )
    }

}

export default VariableModal;