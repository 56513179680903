import React, { useState, useEffect, useCallback } from 'react';
import { Progress, Modal, Button, Tooltip } from 'antd';
import { useApolloClient } from '@apollo/client';
import _ from 'lodash';

const MAX_MEMORY_MB = 200; // Maximum memory in MB

const CacheProgress = () => {
  const client = useApolloClient();
  const [percentage, setPercentage] = useState(0);
  const [usedMemory, setUsedMemory] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const calculateCacheSize = useCallback(_.debounce(async () => {
    const cacheContent = client.cache.extract();
    const cacheSizeString = JSON.stringify(cacheContent);
    const cacheSizeBytes = new TextEncoder().encode(cacheSizeString).length;
    const cacheSizeMB = cacheSizeBytes / (1024 * 1024); // Convert bytes to MB
    const usedPercentage = (cacheSizeMB / MAX_MEMORY_MB) * 100;
    setPercentage(usedPercentage);
    setUsedMemory(cacheSizeMB);
  }, 500), [client]);  // Debounce the calculation

  useEffect(() => {
    calculateCacheSize();
    const interval = setInterval(calculateCacheSize, 10000); // Update every 10 seconds
    return () => {
      clearInterval(interval);
      calculateCacheSize.cancel();  // Cancel the debounced function on unmount
    };
  }, [calculateCacheSize]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const clearCache = () => {
    client.cache.reset();
    setPercentage(0);
    setUsedMemory(0);
    console.log('Cache cleared successfully');
  };

  return (
    <div>
        <p>Other memory usage: {usedMemory.toFixed(1)} / {MAX_MEMORY_MB} MB</p>
          <Progress
            percent={Math.min(100, parseFloat(percentage.toFixed(0)))}
            status="active"
            style={{ marginBottom: 10 }}
          />
          <Button key="clear" onClick={clearCache}>
            Clear other cache
          </Button>

    </div>
  );
};

export default CacheProgress;
