import React, {useState, useEffect} from "react";
// redirect
import { useNavigate } from 'react-router-dom';

import { Modal, Alert, Button } from 'antd';

interface DeleteModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    objectName: string;
    onOk: () => void;
    redirect_url?: string;
}

function DeleteModal(props: DeleteModalProps) {

    const navigate = useNavigate();

    function onOk() {
        props.onOk();
        props.setOpen(false);
        if (props.redirect_url) {
            navigate(props.redirect_url);
        }
    }


    return (
        <Modal
            // always be on top of other modals
            zIndex={10000}  
            open={props.open}
            onOk={onOk}
            onCancel={ () => props.setOpen(false) }
            closable={false}
            //okText="Yes, delete!"
            //cancelText="No, get me out of here!"
            //okButtonProps={{ danger: true }}
            width={"40%"}
            footer={[
                <Button key="back" onClick={() => props.setOpen(false)} data-testid="cancel-delete">
                    No, get me out of here!
                </Button>,
                <Button key="submit" onClick={onOk} data-testid="confirm-delete" type="primary" danger>
                    Yes, delete!
                </Button>,
            ]}
        
    >
        <Alert
            message={"Are you sure you want to delete the " + props.objectName + "?"}
            description={(
                <div>
                    <br/>
                    <p>This action cannot be undone and will delete all data associated with the {props.objectName}.</p>
                </div>
                )}
            type="warning"
            showIcon
            style={{marginBottom:20}}
        />
    

    </Modal>
    )

}

export default DeleteModal;