// FIXME: adjust the status of inputs/outputs that are not variables, e.g. options (display OK if option is set correctly instead of NA)

import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Select, Table, Card , Space, Popconfirm, Button, Form, Spin, Modal, Input, message, Row, Col, InputNumber, AutoComplete, Alert} from 'antd';
import { CORE_CALC_INTERFACE_FIELDS , EXTENDED_PROCESS_FIELDS} from '../fragments';

const {TextArea} = Input;
const {Option} = Select;

const CREATE_CALC_INTERFACE = gql`
    ${CORE_CALC_INTERFACE_FIELDS}
    mutation createCalcInterface($input: CreateCalcInterfaceInputType!) {
        createCalcInterface( input:$input) {
            calcInterface {
                ...CalcInterfaceFields
            }
        }
    }
`;

const UPDATE_CALC_INTERFACE  = gql`
    ${CORE_CALC_INTERFACE_FIELDS}
    mutation updateCalcInterface($input: UpdateCalcInterfaceInputType!) {
        updateCalcInterface( input:$input) {
            calcInterface {
                ...CalcInterfaceFields
            }
        }
    }
`;

const DELETE_CALC_INTERFACE  = gql`
    mutation deleteCalcInterface($id: ID!) {
        deleteCalcInterface( id:$id) {
            id
            ok
        }
    }
`;

const GET_CALC_INTERFACE  = gql`
    ${CORE_CALC_INTERFACE_FIELDS}
    ${EXTENDED_PROCESS_FIELDS}
    query calcInterfacesByCalculatorId ($id:ID! , $processId:ID!) {  

        calcInterfacesByCalculatorId(id:$id) {
            ...CalcInterfaceFields
        }

        process(id:$processId) {
            ...ExtendedProcessFields
        }
    }
`;

const CREATE = CREATE_CALC_INTERFACE ;
const READ = GET_CALC_INTERFACE ;
const UPDATE = UPDATE_CALC_INTERFACE ;
const DELETE = DELETE_CALC_INTERFACE ;

const objectName = "Interface";

interface CalcInterfaceTableInputType {
    calcId:Number,
    processId:String,
    selectedCalcInterface:any,
    setSelectedCalcInterface:Function,
};

function CalcInterfaceTable(props:CalcInterfaceTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [isModalAdd, setModalAdd] = useState(true); // is the modal in add or edit state

    const [isTypeParameter, setTypeParameter] = useState(false);
    const [isTypeVariable, setTypeVariable] = useState(false);
    const [isTypeOption, setTypeOption] = useState(false);
    const [isTypeMetaData, setTypeMetaData] = useState(false);

    const [isDirectionInput, setDirectionInput] = useState(false);

    const [modalInitialValues, setModalInitialValues] = useState( { name:"", interfaceType:"", direction:"", required:"",altNames:[],units:"", valueFloat:null, valueFloatMin:null, valueFloatMax:null , valueChar:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState(); 
    const [interfaceName, setInterfaceName] = useState("");

    const [nOutputs, setNOutputs] = useState(0);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {variables:{id:props.calcId , processId: props.processId } , fetchPolicy: 'cache-and-network'});

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ, variables: { id: props.calcId , processId: props.processId }}],
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE, {
        onCompleted(data) { message.success(objectName +  " was edited successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ, variables: { id: props.calcId , processId: props.processId }}],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------
    
    // useEffect to set the selected calcInterface to empty the first time the component is rendered
    React.useEffect(() => {
        props.setSelectedCalcInterface("")
    }, []);

    // use effect to set the number of defined output interfaces
    React.useEffect(() => {
        if (queryData) {
            setNOutputs(queryData.calcInterfacesByCalculatorId.filter( (c:any) => c.direction == "OUTPUT").length)
        }
    }, [queryData]);

    const showModal = (values:any) => {
        setModalTitel("Add interface")

        setInterfaceName("") 
        setTypeParameter(false)
        setTypeVariable(false)
        setTypeOption(false)
        setTypeMetaData(false)
        setDirectionInput(false)


        setModalInitialValues({ name:"", interfaceType:"", direction:"", required:"",altNames:[],units:"", valueFloat:null , valueFloatMin:null, valueFloatMax:null, valueChar:""} )
        setModalAdd(true)
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {

        // check if interfaceName is valid
        if (!isInterfaceNameValid(interfaceName)) {
            message.error("Invalid name.")
            return
        }
    
        // adding
        if (modalTitel == "Add interface") {
    
            let input = {
                name: interfaceName,
                calculatorId: props.calcId, 
                interfaceType : values['interfaceType'],
                direction : values['direction'],
                required: false,
                altNames: values['altNames'],
                units: values['units'],
                valueFloat: values['valueFloat'],
                valueFloatMin: values['valueFloatMin'],
                valueFloatMax: values['valueFloatMax'],
                valueChar: values['valueChar'],
            };

            // if direction is output and type is metadata, display error message
            if (input.direction == "output" && input.interfaceType == "metadata") {
                message.error("Metadata can only be used as input.")
                return
            }
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: props.selectedCalcInterface,
                name: interfaceName, 
                interfaceType : values['interfaceType'],
                direction : values['direction'],
                required: false,
                altNames: values['altNames'],
                units: values['units'],
                valueFloat: values['valueFloat'],
                valueFloatMin: values['valueFloatMin'],
                valueFloatMax: values['valueFloatMax'],
                valueChar: values['valueChar']
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
        
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = () => {
        setModalTitel("Edit interface")

        // get the selected calcInterface
        const calcInterface = queryData.calcInterfacesByCalculatorId.find( (c:any) => c.id == props.selectedCalcInterface )
        
        setModalInitialValues({ 
            name:calcInterface.name,
            interfaceType: calcInterface.interfaceType.toLowerCase(),
            direction:calcInterface.direction.toLowerCase(),
            required:calcInterface.required,
            altNames:calcInterface.altNames,
            units:calcInterface.units,
            valueFloat:calcInterface.valueFloat, 
            valueFloatMin:calcInterface.valueFloatMin,
            valueFloatMax:calcInterface.valueFloatMax,
            valueChar:calcInterface.valueChar,
        })
        
        setInterfaceName(calcInterface.name)
        setModalAdd(false)

        handleTypeChange(calcInterface.interfaceType.toLowerCase())
        handleDirectionChange(calcInterface.direction.toLowerCase())

        setModalVisible(true)
    } 
    
    const handleDelete = () => {
        
        // get the selected calcInterface
        const calcInterface = queryData.calcInterfacesByCalculatorId.find( (c:any) => c.id == props.selectedCalcInterface )
        
        deleteMutation({ variables: { id: calcInterface.id  } } )
    }

    const handleTypeChange = (type:string) => {
        if (type == 'parameter') {
            setTypeParameter(true)
            setTypeVariable(false)
            setTypeOption(false)
            setTypeMetaData(false)
        } else if (type == 'variable') {
            setTypeParameter(false)
            setTypeVariable(true)
            setTypeOption(false)
            setTypeMetaData(false)
        } else if (type == 'option') {
            setTypeParameter(false)
            setTypeVariable(false)
            setTypeOption(true)
            setTypeMetaData(false)
        } else if (type == 'metadata') {
            setTypeParameter(false)
            setTypeVariable(false)
            setTypeOption(false)
            setTypeMetaData(true)
        }

    }

    const handleDirectionChange = (direction:string) => {

        // reset the interface type 
        //setTypeParameter(false)
        //setTypeVariable(false)
        //setTypeOption(false)
        //setTypeMetaData(false)


        if (direction == 'input') {
            setDirectionInput(true)
        } else {
            setDirectionInput(false)
        }
    }

     const formLayout = {
        labelCol: { span: 8 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 12 },
    };

    const TableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
            width: '25%',
        },
        {
            title: 'Units',
            dataIndex: 'units',
            editable: true,
            width: '15%',
        },
        {
            title: 'I/O',
            dataIndex: 'direction',
            editable: true,
            width: '20%',
        },
        {
            title: 'Type',
            dataIndex: 'interfaceType',
            editable: true,
            width: '25%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            editable: true,
            width: '15%',
        }
    ]

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
          //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          props.setSelectedCalcInterface(selectedRows[0].id)
        }
      };

    function onChangeInterfaceNameAutoComplete(e: any) {
        setInterfaceName(e)
    }

    function onChangeInterfaceNameInput(e: any) {
        setInterfaceName(e.target.value)
    }

    function isInterfaceNameValid(name:string) {
        if (name == "") {
            return false
        } else {
            // Name must start with a letter or underscore and can only contain letters, numbers, and underscores.
            const regex = /^[a-zA-Z_][a-zA-Z0-9_]*$/
            return regex.test(name)
        }
    }


    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );

    if (queryLoading) return (
        <Card><Spin /></Card>
    );

        return (

            <div style={{minWidth:600}}>
                <Card title="Calculator interfaces" style={{minHeight:450}}> 
                    <Row>
                        <Col span={2} >
                            <Space direction="vertical">
                                <Button className="button_sidebar" onClick = {  showModal  } data-testid="add-calc-interface"> Add </Button>
                                <Button className="button_sidebar" onClick = { handleEdit } disabled={ props.selectedCalcInterface == "" } data-testid="edit-calc-interface"> Edit </Button>
                                
                                <Popconfirm title="Delete interface?" onConfirm={ handleDelete } disabled={ props.selectedCalcInterface == "" } >
                                    <Button className="button_sidebar" disabled= { props.selectedCalcInterface == "" } style={{width:100}} data-testid="delete-calc-interface"> Delete </Button>
                                </Popconfirm>

                            </Space>
                        </Col>
                        <Col span={1} ></Col>
                        <Col span={21}>
                            { nOutputs == 0 &&
                            <Alert message="Warning: No output interfaces have been defined." type="warning" showIcon style={{marginBottom:10}}/>
                            }
                            <Table 
                                loading={queryLoading}
                                dataSource={queryData?.calcInterfacesByCalculatorId}
                                columns={ TableColumns } 
                                bordered
                                rowKey={record => record.id} 
                                size="small"
                                pagination={false}
                                //scroll={{ x:150, y: 500 }}
                                rowSelection={{
                                    type: "radio",
                                    ...rowSelection,
                                }}
                                
                                
                            />
                        </Col>
                    </Row>
                </Card>
                            
                <Modal 
                    title= { modalTitel }
                    open={ isModalVisible } 
                    onOk = { handleModalOk }
                    onCancel = { handleModalCancel }
                    destroyOnClose = {true}
                    footer={[
                        <Button type="primary" form="CalcInterfaceForm" key="submit" htmlType="submit" data-testid="submit-calc-interface">
                            Submit
                        </Button>
                        ]}
                >
                    <Form
                        {...formLayout}  
                        id = "CalcInterfaceForm"
                        onFinish={handleModalOk} 
                        initialValues={ modalInitialValues }
                    > 

                        <Form.Item label="Direction:" name="direction" rules={[{ required: true }]}>
                            <Select onChange = { handleDirectionChange } data-testid="direction-select">
                                <Option value="input">Input</Option>
                                <Option value="output">Output</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Type:" name="interfaceType" rules={[{ required: true }]}>
                            <Select onChange = { handleTypeChange } data-testid="type-select">
                                <Option value="variable">Variable</Option>
                                <Option value="parameter">Parameter</Option>
                                
                                    <Option value="metadata" disabled={ !isDirectionInput }>Metadata</Option>
                                
                                {/*<Option value="option" disabled={ !isDirectionInput } >Option</Option>*/}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Name:" required >
                            
                            { isTypeVariable && isDirectionInput &&
                                <AutoComplete
                                    showSearch
                                    value = {interfaceName}
                                    maxLength={200}
                                    onChange={onChangeInterfaceNameAutoComplete}
                                    options={queryData.process.variableSet.map((item: any) => ({ value: item.name }))}
                                    filterOption={(inputValue, option:any) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                    data-testid="name-input"
                                >
                                    {queryData.process.variableSet.map((item: any) => (
                                        <Option key={item.name} value={item.name}>{item.name}</Option>
                                    ))}
                                </AutoComplete> 
                            }
                            { isTypeParameter && isDirectionInput &&
                            
                                <AutoComplete
                                    showSearch
                                    value = {interfaceName}
                                    onChange={onChangeInterfaceNameAutoComplete}
                                    maxLength={200}
                                    options = {queryData.process.parameterSet.map((item: any) => ({ value: item.name }))}
                                    filterOption={(inputValue, option:any) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                    data-testid="name-input"
                                >
                                    {queryData.process.parameterSet.map((item: any) => (
                                        <Option key={item.name} value={item.name}>{item.name}</Option>
                                    ))}
                                </AutoComplete>
                            }
                            
                            { isTypeMetaData && isDirectionInput &&
                            
                            <AutoComplete
                                showSearch
                                value = {interfaceName}
                                onChange={onChangeInterfaceNameAutoComplete}
                                maxLength={200}
                                options = {queryData.process.metadataSet.map((item: any) => ({ value: item.name }))}
                                filterOption={(inputValue, option:any) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                data-testid="name-input"
                            >
                                {queryData.process.metadataSet.map((item: any) => (
                                    <Option key={item.name} value={item.name}>{item.name}</Option>
                                ))}
                            </AutoComplete>
                            }
                            
                            { !isDirectionInput  &&
                                <Input maxLength={200} 
                                    value = {interfaceName}
                                    onChange={onChangeInterfaceNameInput}
                                    data-testid="name-input"
                                />
                            }

                            
                            { isDirectionInput && !isTypeMetaData && !isTypeParameter && !isTypeVariable &&
                                <Input maxLength={200} 
                                    value = {interfaceName}
                                    onChange={onChangeInterfaceNameInput}
                                    data-testid="name-input"
                                />
                            }

                            { isInterfaceNameValid(interfaceName) || interfaceName =="" ?
                                <div></div>
                                :
                                <div style={{color:'red'}}>Name must start with a letter or underscore and can only contain letters, numbers, and underscores.</div>
                            }

                        </Form.Item>
                        
                        { !isTypeMetaData &&
                            <Form.Item label="Units:" name="units" rules={[{ required: false }]} >
                                <Input maxLength={20} data-testid="units-input" />
                            </Form.Item>
                        }

                        <Form.Item label="Other names:" name="altNames" rules={[{ required: false }]} style={ isTypeOption || !isDirectionInput ? { display: 'none'} : {display:''}} >
                            <TextArea maxLength={1000} data-testid="altNames-input" />
                        </Form.Item>
                        

                        <Form.Item label="Min:" name="valueFloatMin"  style={ (!isTypeVariable || isDirectionInput) ? { display: 'none'} : {display:''}} >
                            <InputNumber style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="valueFloatMin-input" />
                            {/*<Tooltip title="The minimum allowed value of the output variable. Values smaller than this will be dropped from the output.">
                                <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                            </Tooltip>*/}
                    </Form.Item>

                    <Form.Item label="Max:" name="valueFloatMax" style={ (!isTypeVariable || isDirectionInput) ? { display: 'none'} : {display:''}} >
                            <InputNumber style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="valueFloatMax-input" />
                            {/*<Tooltip title="The maximum value of the output variable. Values larger than this will be dropped from the output.">
                                <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                            </Tooltip>*/}
                    </Form.Item>

                    <Form.Item label="Fallback value:" name="valueFloat" rules={[{ required: false }]} style={ (!isTypeParameter && !isTypeVariable) || !isDirectionInput ? { display: 'none'} : {display:''}} >
                            <InputNumber style={{float:"left" , width:'calc(100% - 30px)'}} data-testid="valueFloat-input" />
                            {/*<Tooltip title="Fallback value if the parameter or variable is not found in the process.">
                                <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                            </Tooltip>*/}
                    </Form.Item>
                    
                    {/*
                    <Form.Item label="Value:" name="valueChar" rules={[{ required: false }]} style={ isTypeParameter || isTypeVariable  || isTypeMetaData || !isDirectionInput ? { display: 'none'} : {display:''}} >
                        <Input maxLength={200} style={{float:"left" , width:'calc(100% - 30px)'}} />
                        {/*<Tooltip title="The value of an option.">
                            <InfoCircleOutlined style={{ float:"right", font:"30px", marginTop:"10px"}} />
                        </Tooltip>
                    </Form.Item>
                    */}

                    </Form>

                </Modal>

            </div>

        );
    }



export default CalcInterfaceTable;