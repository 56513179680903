import React , {useState} from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Card, Steps, Button, message, Form, Radio, Input, Spin , Result} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import SelectTopic from "./SelectTopic";
import spectroscopy from '../images/spectroscopy.png'
import cube from '../../images/cube.png'
import mlr from '../../images/mlr.png'
import metabolic from '../../images/metabolic.png'
import hybrid from '../../images/hybrid.png'
import ml from '../../images/ml.png'
import pca from '../../images/pca.png'

const { TextArea } = Input;

const { Step } = Steps;
const { Meta } = Card;


const PROCESSES = gql`
  query processes ($topicId:ID) {
    processes (topicId:$topicId) {
      processes {
        id
        name
      }
    }
  }
`;

const CREATE_STAT_MODEL = gql`
    mutation createStatModel($input: CreateStatModelInputType!) {
        createStatModel( input:$input) {
            statModel {
                id
                name
                description
                modelfunction
            }
        }
    }
`;

const GET_STAT_MODELS = gql`
    query statModels ($analysisId:ID!) {
        statModels (analysisId:$analysisId) {
            id
            name
            description
            modelfunction
        }
        userProfile {
            selectedAnalysis {
                id
                userPermissions
            }
        }
    }
`;

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            selectedAnalysis {
                id
                processes {
                    id
                    name
                }
            }
        }
    }
`

interface Props {
  setOpen: any
  analysisId: string
}

function CreateModel(props:Props) {


  // page number of the wizard
  const [current, setCurrent] = useState(0);
  const [modelFunction, setModelFunction] = useState("");
  const [modelName, setModelName] = useState("");
  const [modelDescription, setModelDescription] = useState("");
  //const [topic, setTopic] = useState("");
  //const [topicName, setTopicName] = useState("");
  //const   [selectedKPIDefinition, setSelectedKPIDefinition] = useState("");

  
  // TODO: do we need this?
  //const { loading:queryLoading, error: queryError, data: queryData } = useQuery(PROCESSES, { variables: { topicId: topic }} ) ;
  const { loading:queryLoading1, error:queryError1, data:queryData1 } = useQuery(GET_USER_PROFILE);

  const [mutate, { loading, error }] = useMutation(CREATE_STAT_MODEL, {
    refetchQueries: [ { query:  GET_STAT_MODELS , variables: { analysisId: props.analysisId } } ],
    onCompleted(data) { 
      //message.success( "Process data was imported successfully")
      setCurrent(current+1) 
    } ,
    onError(error) {message.error(error.message)},
  });


  // perform the muation

  function create() {

    /*
    if (selectedKPIDefinition=="" || selectedKPIDefinition==null || selectedKPIDefinition==undefined) {
      message.error("Please select a KPI definition.")
      return
    }
    */

    let input = {
      name: modelName,
      description: modelDescription,
      modelfunction: modelFunction,
      analysisId: queryData1.userProfile.selectedAnalysis.id,
      //topicId: topic,
      //kpiDefinitionId: selectedKPIDefinition,
    };

    mutate({ variables: { input: input  } });

  }

  const next = () => {
    
    if (current==1) {
      
      /*if (topic=="") {
        message.error("Please select a topic.")
        return
      }*/

      if (modelName=="") {
        message.error("Please fill out model name")
        return
      }

    }

    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function onClickPCA() {
    setModelFunction("PCA")
    setCurrent(current + 1);
  }

  function onClickMLR() {
    setModelFunction("MLR")
    setCurrent(current + 1);
  }

  function onClickPLS() {
    setModelFunction("PLS")
    setCurrent(current + 1);
  }

  const firstPage = (
    <Card>

      {/*
      <Card
          hoverable = {true}
          style={{ width: 250, height:320 , float:"left" , marginRight:10, marginBottom:10 }}
          cover={<img alt="" src={ml} style={{height:120, width:120, marginLeft:60, marginTop:30}}/>}
      >
          <Meta title="Machine learning" description="Train statistical models to predict bioprocess characteristics." />
      
      </Card>

      <Card
          hoverable = {true}
          style={{ width: 250, height:320 , float:"left" , marginRight:10, marginBottom:10 }}
          cover={<img alt="" src={hybrid} style={{height:140, width:190, marginLeft:30, marginTop:10}}/>}
      >
          <Meta title="Hybrid modeling" description="Generate a hybrid bioprocess model by combining machine learning and fist-principles." />

      </Card>

      
      */}

      <Card
          hoverable = {true}
          onClick={onClickMLR}
          style={{ width: 270, height:450 , float:"left" , marginRight:10, marginBottom:10 }}
          cover={<img alt="" src={mlr} style={{width:150, marginLeft:30, marginTop:10, padding:20 }}/>}
      >
          <Meta title="MLR" description="Multiple Linear Regression (MLR) is a statistical technique that models the relationship between a single dependent variable and multiple independent variables by fitting a linear equation to observed data." />
      
      </Card>
      
      <Card
          hoverable = {true}
          onClick={onClickPCA}
          style={{ width: 270, height:450 , float:"left" , marginRight:10, marginBottom:10 }}
          cover={<img alt="" src={pca} style={{width:120, marginTop:30, marginBottom:30, marginLeft:60}}/>}
      >
          <Meta title="PCA" description="Principal Component Analysis (PCA) is a dimensionality-reduction method that transforms a large set of variables into a smaller one that still contains most of the information in the large set by identifying the principal components." />

      </Card>

      {/*
      
      <Card
          hoverable = {true}
          onClick={onClickPLS}
          style={{ width: 270, height:450 , float:"left" , marginRight:10, marginBottom:10 }}
          cover={<img alt="" src={mlr} style={{width:150, marginLeft:30, marginTop:10, padding:20}}/>}
      >
          <Meta title="PLS" description="Partial Least Squares (PLS) is a multivariate statistical method that models relationships between input variables and outputs by projecting them onto a new space of latent variables that maximizes the covariance between the two." />

      </Card>

      */}
      
      <Card
          hoverable = {false}
          style={{ width: 270, height:450 , float:"left" , marginRight:10, marginBottom:10 , opacity:"50%"}}
          cover={<img alt="" src={metabolic} style={{height:130, width:80, marginLeft:90, marginTop:40, marginBottom:10}}/>}
      >
          <Meta title="Bioprocess simulation" description="Simulate a bioprocess process using first-principles." />

      </Card>
      

      {/*
      <Card
          hoverable = {true}
          style={{ width: 250, height:320 , float:"left" , marginRight:10, marginBottom:10 }}
          cover={<img alt="" src={spectroscopy} style={{height:100, width:170, marginTop:40, marginBottom:10, marginLeft:40}}/>}
      >
          <Meta title="Spectroscopy" description="Calibrate multivariate spectroscopy models e.g. Raman, NIR, MIR,..." />

      </Card>
      */}
    </Card>
  );

  const formLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 8},
  };


  function onChangeModelName(input:any) {
    setModelName(input.target.value)
  }

  function onChangeModelDescription(input:any) {
    setModelDescription(input.target.value)
  } 

  const secondPage = (
    <Card>
      <Form
        {...formLayout}
        labelAlign="left"
      > 

        {/*
        <Form.Item label="Topic" required>
          <SelectTopic setTopic={setTopic} setTopicName={setTopicName } topic={topic}/>
        </Form.Item>
        */}
        
          <Form.Item label="Model name" required rules={[{ required: true }]} >
            <Input value={modelName} onChange={onChangeModelName} data-testid="input-name">
            </Input>
          </Form.Item>

          <Form.Item label="Model description" >
            <TextArea value={modelDescription} onChange={onChangeModelDescription} data-testid="input-description" />
          </Form.Item>
        
      </Form>
    </Card>

  )

  
  const fourthPage = (
    <Card>
      <Form {...formLayout} labelAlign="left" >
        
        {/*
        <Form.Item label = "KPI set:" required> 
          <SelectKPIset topicId = {topic} selectedKPIDefinition={selectedKPIDefinition} setSelectedKPIDefinition={setSelectedKPIDefinition}/>
        </Form.Item>
        */}
        
      
      </Form>
    </Card>
  );
  

  const fifthPage = (
    <div>

      <Result
        status="success"
        title="Model was created successfully!"
      />

    </div>
  );

  const steps = [
    {
      title: 'Model type',
      content: firstPage,
    },
    {
      title: 'Model info',
      content: secondPage,
    },
    /*
    {
      title: 'Processes',
      content: fourthPage,
    },
    */
    {
      title: 'Done',
      content: fifthPage,
    },
  ];

  if (queryLoading1) return <Spin size="large" />;
  if (queryError1) return <p>Error: {queryError1.message}</p>;

  return(  

    <div>


        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        <br/>
        

        <div style={{minHeight:300}}>{steps[current].content}</div>
        
        <br/>


        <div className="steps-action">

          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()} data-testid="button-previous">
              Previous
            </Button>
          )}

          {current < 1 && current != 0 && (
            <Button type="primary" onClick={() => next()} data-testid="button-next">
              Next
            </Button>
          )}
          {current === 1 && (
            <Button type="primary" onClick={ create } data-testid="button-create">
              Create model
            </Button>
          )}
          {current === 2 && (
            <Button type="default" onClick={() => props.setOpen(false)} data-testid="button-close">
              Close
            </Button>
          )}

        </div>
           
    </div>

  )
}

export default CreateModel;

