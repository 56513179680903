// TODO: add a column that shows the status of the calculator (e.g. success/fail)


import React, {useState} from 'react';
import { useQuery, useMutation, useLazyQuery, gql, useReactiveVar } from '@apollo/client';
import { Table, Card , Select, Space, Popconfirm, Button, Form, Skeleton, Modal, Input, message, Row, Col, Collapse , Tooltip} from 'antd';
import { CloseCircleOutlined  , CheckCircleOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import CalcInterfaceTable from './CalcInterfaceTable';
import EditCalculatorInterfaces from './EditCalculatorInterfaces';
import useWindowDimensions from '../useWindowDimensions';
import { pollInterval } from '../..';
import { EXTENDED_PROCESS_FIELDS, CORE_CALCULATOR_FIELDS } from '../fragments';
import { calculatorsSelectedProcess } from '../../Apollo';

const {TextArea} = Input
const {Option} = Select;
const { Panel } = Collapse;

const CREATE_CALCULATOR = gql`
    ${CORE_CALCULATOR_FIELDS}
    mutation createCalculator($input: CreateCalculatorInputType!) {
        createCalculator( input:$input) {
            calculator {
                ...CoreCalculatorFields
            }
        }
    }
`;

const UPDATE_CALCULATOR = gql`
    ${CORE_CALCULATOR_FIELDS}
    mutation updateCalculator($input: UpdateCalculatorInputType!) {
        updateCalculator( input:$input) {
            calculator {
                ...CoreCalculatorFields
            }
        }
    }
`;

const MOVE_UP_CALCULATOR = gql`
    ${CORE_CALCULATOR_FIELDS}
    mutation moveUpCalculator($id: ID!) {
        moveUpCalculator( id:$id) {
            calculator {
                ...CoreCalculatorFields
            }
        }
    }
`;

const MOVE_DOWN_CALCULATOR = gql`
    ${CORE_CALCULATOR_FIELDS}
    mutation moveDownCalculator($id: ID!) {
        moveDownCalculator( id:$id) {
            calculator {
                ...CoreCalculatorFields
            }
        }
    }
`;

const DELETE_CALCULATOR = gql`
    mutation deleteCalculator($id: ID!) {
        deleteCalculator( id:$id) {
            id
            ok
        }
    }
`;

const CALCULATE_CALCULATOR = gql`
    mutation calculateCalculator($id: ID!) {
        calculateCalculator( id:$id) {
            id
            ok
            message
        }
    }
`;

const GET_CALCULATOR = gql`
    ${CORE_CALCULATOR_FIELDS}
    query Process ($id:ID!) {  
        process(id:$id) {
            id
            name
            topic {
                id
                userPermissions
            }
            calculatorSet {
                ...CoreCalculatorFields
            }
        }
    }
`;

const GET_PROCESS_VARPARS = gql`
    ${EXTENDED_PROCESS_FIELDS}
    query Process ($id:ID!) {  
        process(id:$id) {
            ...ExtendedProcessFields
        }
    }
`;

const GET_CALC_INTERFACE  = gql`
    query calcInterfacesByCalculatorId ($id:ID!) {  
        calcInterfacesByCalculatorId(id:$id) {
            id
            status
        }
    }
`;

const CREATE = CREATE_CALCULATOR;
const READ = GET_CALCULATOR;
const UPDATE = UPDATE_CALCULATOR;
const DELETE = DELETE_CALCULATOR;

const objectName = "Calculator";

interface CalculatorTableInputType {
    selectedCalculator:Number,
    setSelectedCalculator:Function,
};

function CalculatorTable(props:CalculatorTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states
    const { height, width } = useWindowDimensions();
    const [isModalVisible, setModalVisible] = useState(false);
    const [isModalAdd, setModalAdd] = useState(true);
    const [isExpressionVisible, setExpressionVisible] = useState(false);
    const [selectedCalcInterface, setSelectedCalcInterface] = useState()
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"", calcfunction:"", expression:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalWidth, setModalWidth] = useState("50%");
    const [calcFunction, setCalcFunction] = useState(""); // the calcfunction being edited
    const [activeTableKey, setActiveTableKey] = useState(["2"]);

    const [newEditVisible, setNewEditVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const calculatorsSelectedProcessValue = useReactiveVar(calculatorsSelectedProcess);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {variables:{id:calculatorsSelectedProcessValue}, 
        fetchPolicy: "cache-and-network",
    });
    
    // get the same thing with a lazy query
    const [getCalculator, { loading:queryLoading2, error: queryError2, data: queryData2 }] = useLazyQuery(READ , {variables:{id:calculatorsSelectedProcessValue}});

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ, variables: { id: calculatorsSelectedProcessValue } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE, {
        onCompleted(data) { message.success(objectName + " was edited successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    const [moveUpMutation] = useMutation(MOVE_UP_CALCULATOR, {
        refetchQueries: [ { query: READ, variables: { id: calculatorsSelectedProcessValue } } ], 
        onError(error) {message.error(error.message)}, 
    });

    const [moveDownMutation] = useMutation(MOVE_DOWN_CALCULATOR, {
        refetchQueries: [ { query: READ, variables: { id: calculatorsSelectedProcessValue } } ], 
        onError(error) {message.error(error.message)}, 
    });


    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ, variables: { id: calculatorsSelectedProcessValue }},{query: GET_PROCESS_VARPARS, variables:{id:calculatorsSelectedProcessValue}}],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    const [calculateMutation] = useMutation(CALCULATE_CALCULATOR, {
        refetchQueries:[
            {query: GET_PROCESS_VARPARS, variables:{id:calculatorsSelectedProcessValue}},
            {query:GET_CALC_INTERFACE, variables:{id:props.selectedCalculator}},
            { query: READ, variables: { id: calculatorsSelectedProcessValue } },
        ],
        onCompleted(data) { 
     
            if (data.calculateCalculator.ok == true) {
                message.success(data.calculateCalculator.message)
            } else {
                message.error(data.calculateCalculator.message)
            }
        },
        onError(error) {message.error(error.message)}, 
    });
   
    // ----------------------------------------------------------------------------------------

    const showModal = (values:any) => {
        setModalTitel("Create a calculator")
        setModalInitialValues({ name:"", description:"" , calcfunction:"", expression:""} )
        setModalVisible(true)
        setModalWidth("40%")
        setModalAdd(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (isModalAdd) {
    
            let input = {
                name : values['name'], 
                description : values['description'],
                calcfunction: values['calcfunction'],
                expression : values['expression'],
                processId:calculatorsSelectedProcessValue
            };
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: props.selectedCalculator,
                name : values['name'], 
                description : values['description'],
                expression : values['expression'],
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = () => {
        
        // get the selected calculator
        const calc = queryData.process.calculatorSet.find( (c:any) => c.id == props.selectedCalculator )

        setModalInitialValues({ 
            name:calc.name,
            description:calc.description, 
            calcfunction:calc.calcfunction,
            expression:calc.expression 
        })
        
        setModalAdd(false) // modal is not in add mode

        if ( calc.calcfunction == 'calc_math' ) {
            setExpressionVisible(true)
        } else {
            setExpressionVisible(false)
        }
        setModalWidth("80%")
        setCalcFunction(calc.calcfunction)
        setModalTitel("Edit calculator: " + calc.calcfunction )

        if (calc.calcfunction == "calc_math") {
            setModalVisible(true)
        } else {
            setNewEditVisible(true)
        }

        
    } 
    
    const handleDelete = () => {   
        deleteMutation({ variables: { id: props.selectedCalculator  } } )
        props.setSelectedCalculator(-1) // to empty the calcinterface table
    } 

    const handleCalculate = () => {   
        calculateMutation({ variables: { id: props.selectedCalculator  } } )
    } 

    const handleMoveUp = () => {   
        moveUpMutation( { variables: { id: props.selectedCalculator  } } );
    } 

    const handleMoveDown = () => {
        moveDownMutation( { variables: { id: props.selectedCalculator  } } );
    } 

    const handleCalcFunctionChange = (calcfunction:string) => {
        if (calcfunction == 'calc_math') {
            setExpressionVisible(true)
        } else {
            setExpressionVisible(false)
        }
        
    }

    const handleEnable = () => {
        let input = {
            id: props.selectedCalculator,
            enabled: true
        };
        updateMutation( { variables: { input: input  } } );
    }

    const handleDisable = () => {
        let input = {
            id: props.selectedCalculator,
            enabled: false
        };
        updateMutation( { variables: { input: input  } } );
    }

    const formLayout = {
        labelCol: { span: 4 },
        //wrapperCol: { span: 8 },
    };




    const ProcessCalculatorsTableColumns = [
        {
            title: 'Order',
            dataIndex: 'order',
            editable: true,
            width: '10%',
        },
        {
            title: 'Enabled',
            width: '10%',

            render : (text:String, record:any) => (
                <div>
                    {
                        record.enabled == false && 
                        <CloseOutlined style={{color: 'red' , fontSize: '1.4em' , marginLeft:10}} title="Disabled"/>

                    }
                    {
                        record.enabled == true && 
                        <CheckOutlined style={{color: 'green' , fontSize: '1.4em' , marginLeft:10}} title="Enabled"/>
                    }
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
            //width: '40%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
            //width: '40%',
        },
        {
            title: 'Function',
            dataIndex: 'calcfunction',
            editable: true,
            //width: '30%',
        },
        {
            title: 'Level',
            dataIndex: 'level',
            editable: false,
            width: '15%',
            render: (text:String, record:any) => (
                <div>
                    { record.level == 'PROCESS' && 'Process' }
                    { record.level == 'TOPIC' && 'Collection' } 
                </div>
            ),
        },
        {
            title: 'Status',
            width: '5%',
            render : (text:String, record:any) => (
                <div>
                    {
                        // if record.message is "not calculated" or contains "error" then show error icon - make the icon bold


                        (record.message == "Not calculated" || record.message.includes("error")|| record.message.includes("Error")) && 
                        <Tooltip title={record.message}><CloseCircleOutlined style={{color: 'red' , fontSize: '1.4em', marginLeft:10}} /></Tooltip> 
                    }
                    {
                        // if record.message is "calculated" then show ok icon - make the icon larger
                        record.message == "Calculated successfully" &&
                        <Tooltip title={record.message}><CheckCircleOutlined style={{color: 'green', fontSize: '1.4em' , marginLeft:10}} /></Tooltip>
                        
                    }
                </div>
            ),
        },
    ]

    const TopicCalculatorsTableColumns = [
        {
            title: 'Order',
            dataIndex: 'order',
            editable: true,
            width: '10%',
        },
        {
            title: 'Enabled',
            width: '10%',

            render : (text:String, record:any) => (
                <div>
                    {
                        record.enabled == false && 
                        <CloseOutlined style={{color: 'red' , fontSize: '1.4em' , marginLeft:10}} title="Disabled"/>

                    }
                    {
                        record.enabled == true && 
                        <CheckOutlined style={{color: 'green' , fontSize: '1.4em' , marginLeft:10}} title="Enabled"/>
                    }
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
            //width: '40%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
            //width: '40%',
        },
        {
            title: 'Function',
            dataIndex: 'calcfunction',
            editable: true,
            //width: '30%',
        },
        {
            title: 'Calculator set',
            dataIndex: ['calculatorSave', 'calculatorSet', 'name'],
            //width: '30%',
        },
        {
            title: 'Level',
            dataIndex: 'level',
            editable: false,
            width: '15%',
            render: (text:String, record:any) => (
                <div>
                    { record.level == 'PROCESS' && 'Process' }
                    { record.level == 'TOPIC' && 'Collection' } 
                </div>
            ),
        },
        {
            title: 'Status',
            width: '5%',
            render : (text:String, record:any) => (
                <div>
                    {
                        // if record.message is "not calculated" or contains "error" then show error icon
                        (record.message == "Not calculated" || record.message.includes("error")|| record.message.includes("Error")) && 
                        <Tooltip title={record.message}><CloseCircleOutlined style={{color: 'red' , fontSize: '1.4em' , marginLeft:10}} /></Tooltip> 
                    }
                    {
                        // if record.message is "calculated" then show ok icon - make the icon larger
                        record.message == "Calculated successfully" &&
                        <Tooltip title={record.message}><CheckCircleOutlined style={{color: 'green', fontSize: '1.4em' , marginLeft:10}} /></Tooltip>
                        
                    }
                </div>
            ),
        },
    ]


    const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (selectedRows.length > 0) {
        props.setSelectedCalculator(selectedRows[0].id);
        }
    }
    };
    
    // returning and empty table for the case that the processId prop is not set
    if (calculatorsSelectedProcessValue == "" || calculatorsSelectedProcessValue == null) {
        return(
            <Card>
                <Table
                    columns={ ProcessCalculatorsTableColumns } 
                    bordered
                    size="small"
                />
            </Card>
        )
    }

    if ((queryLoading && !queryData) || queryLoading2 || calculatorsSelectedProcessValue == "" || !queryData) return (
        <Card><Skeleton active /></Card>
    );
    
    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );

    if (queryError2) return (
        <div>Error: {queryError2.message} </div>
    );
    const editable = queryData.process.topic.userPermissions != "read";

    return (

        <div style={{width:"99.5%", overflow:"hidden"}}>
   
            <Collapse activeKey={activeTableKey} accordion 
                onChange={(key:any) => {setActiveTableKey(key)}}
                
                /*onChange={(key:any) => {
                    if (key.length > 0) {
                        setActiveTableKey(key)
                    } else {
                        if (activeTableKey[0] == "1") {
                            setActiveTableKey(["2"])
                        } else {
                            setActiveTableKey(["1"])
                        }
                    
                    }
                    //setActiveTableKey(key)
                }}*/
            >
        
                <Panel header="Process Calculators" key="1" data-testid="process-calculator-panel">

                    <div style={{ display: 'flex', height:height-500}} >
                        <div style={{ flex: '0 0 90px' }}>
                            <Space direction="vertical">
                                <Button onClick = {  showModal  } className="button_sidebar" title="Create a Calculator" data-testid="create-calculator" disabled={!editable}>Create</Button>
                                <Button onClick = {  handleEdit  } className="button_sidebar" title="Edit the Calculator" data-testid="edit-calculator" disabled={props.selectedCalculator == -1 || !editable}>Edit</Button> 
                                
                                <Popconfirm title="Delete calculator?" onConfirm={handleDelete}  disabled={props.selectedCalculator == -1 || !editable}>
                                    <Button className="button_sidebar"  title="Delete the Calculator" data-testid="delete-calculator" disabled={props.selectedCalculator == -1 || !editable} style={{width:100}}>Delete</Button>
                                </Popconfirm>
                                
                                <Button onClick = { handleCalculate } className="button_sidebar" title="Calculate the Calculator" data-testid="calculate-calculator" disabled={props.selectedCalculator == -1 || !editable}>Calculate</Button>
                                <Button onClick = {  handleMoveUp  } className="button_sidebar" title="Move up the Calculator" data-testid="move-up-calculator" disabled={props.selectedCalculator == -1 || !editable}>Move up</Button>
                                <Button onClick = {  handleMoveDown  } className="button_sidebar" title="Move down the Calculator" data-testid="move-down-calculator" disabled={props.selectedCalculator == -1 || !editable}>Move down</Button>
                                <Button  className="button_sidebar"  onClick = { handleEnable }  data-testid="enable-calc" title="Enable the Calculator Set"  disabled={props.selectedCalculator == -1 || !editable}> Enable </Button>
                        <Button  className="button_sidebar"  onClick = {  handleDisable  }  data-testid="disable-calc" title="Disable the Calculator Set"  disabled={props.selectedCalculator == -1 || !editable}> Disable </Button>
                            </Space>
                        </div>

                        <div style={{ marginLeft: 10, width: "100%", overflow:"scroll"}}>

                            <Table 
                                loading={queryLoading && !queryData}
                                dataSource={queryData.process.calculatorSet.filter( (row:any) => row.level == "PROCESS") } 
                                columns={ ProcessCalculatorsTableColumns } 
                                bordered
                                rowKey={record => record.id} 
                                size="small"
                                pagination={false}
                                scroll={{ y: height-550 }}
                                rowSelection={{
                                    type: "radio",
                                    ...rowSelection,
                                }}
                                style={{minWidth:500}}

                                onRow={(record:any, rowIndex:any) => {
                                    return {
                                    onClick: event => {
                                        // Toggle selection for radio type
                                        const newSelectedRowKeys:any = selectedRowKeys.includes(record.id)
                                        ? []
                                        : [record.id];
                                
                                        setSelectedRowKeys(newSelectedRowKeys);
                                        if (newSelectedRowKeys.length > 0) {
                                            props.setSelectedCalculator(newSelectedRowKeys[0]);
                                        } else {
                                            props.setSelectedCalculator(-1);
                                        }
                                    },

                                    onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"},
                                    onMouseLeave: event => {event.currentTarget.style.cursor = "default"},
                                    };
                                }}
                            />

                        </div>
                    </div>
                            
                </Panel>
            
                <Panel header="Collection calculators" key="2" data-testid="collection-calculator-panel">
                    <div style={{ display: 'flex' , height:height-500}}>
                       
                        <div style={{ marginLeft: 10, width: "100%", overflow:"scroll"}}>
                            <Table 
                                loading={queryLoading && !queryData}
                                dataSource={queryData.process.calculatorSet.filter( (row:any) => row.level == "TOPIC") } 
                                columns={ TopicCalculatorsTableColumns } 
                                bordered
                                rowKey={record => record.id} 
                                size="small"
                                pagination={false}
                                //scroll={{ x:150, y: 500 }}
                                
                            />
                        </div>
                    </div>  

                </Panel>
                
            </Collapse>

            
            
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                width = {modalWidth}
                style={{ top: 20 }}
                footer={[
                    <Button type="primary" form="CalculatorForm" key="submit" htmlType="submit" data-testid="modal-submit-button">
                        Submit
                    </Button>
                    ]}
            >
                
                <Form

                    {...formLayout}  
                    id = "CalculatorForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <br/>
                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <Input maxLength={200} data-testid="name-input" />
                    </Form.Item>

                    <Form.Item label="Description:" name="description" rules={[{ required: false }]}>
                        <TextArea maxLength={10000} rows={1} data-testid="description-input" />
                    </Form.Item>

                    { isModalAdd &&
                    <Form.Item label="Function:" name="calcfunction" rules={[{ required: isModalAdd }]}  >

                        <Select onChange = { handleCalcFunctionChange } data-testid="calcfunction-select" >
                            
                            <Option value="calc_math">Generic calculator (calc_math)</Option>
                            <Option value="calc_differentiation">Differentiation (calc_differentiation)</Option>
                            <Option value="calc_integration">Integration (calc_integration)</Option>
                            <Option value="calc_uptake_production">Physiological uptake / production (calc_uptake_production)</Option>
                            <Option value="calc_evaporation">Evaporation (calc_evaporation)</Option>
                            <Option value="calc_offgas">Fermentation offgas analysis (calc_offgas)</Option>
                            <Option value="calc_reference_time">Reference time (calc_reference_time)</Option>
                            
                            <Option value="calc_simulation" disabled={true} >Fermentation simulation (calc_simulation)</Option>
                           
                            
                        </Select>

                    </Form.Item>
                    }

                    { !isModalAdd && 
                        <Form.Item label="Expression" name="expression" rules={[{ required: false }]} style={ !isExpressionVisible ? { display: 'none'} : {display:''}} >
                            <TextArea maxLength={100000} data-testid="expression-input" />
                        </Form.Item>
                    }   
                    
                </Form>
                { !isModalAdd && calcFunction == "calc_math" &&
                    <CalcInterfaceTable 
                            calcId={props.selectedCalculator}
                            processId={calculatorsSelectedProcessValue}
                            selectedCalcInterface={selectedCalcInterface}
                            setSelectedCalcInterface={setSelectedCalcInterface}
                    />
                }

            </Modal>

            <EditCalculatorInterfaces
                        calcId={props.selectedCalculator}
                        calcType="Calculator"
                        calcfunction={calcFunction}
                        visible={newEditVisible}
                        setVisible={setNewEditVisible}
            />

        </div>

    );
    

}

export default CalculatorTable;

