import localforage from 'localforage';

const storage = localforage.createInstance({
  name: 'storage',
  storeName: 'keyvaluepairs',
});

export async function setItem(key:string, value:any) {
  await storage.setItem(key, value);
}

export async function getItem(key:string) {
  return await storage.getItem(key);
}

export async function removeItem(key:string) {
  await storage.removeItem(key);
}

export default storage;



// Utility to get the size of an object in bytes
function getSizeInBytes(obj: any): number {
  return new Blob([JSON.stringify(obj)]).size;
}

// Utility to log the size of Apollo cache and local storage
export async function logCacheAndStorageSize(cache: any) {
  // Calculate the size of the Apollo cache
  const cacheData = cache.extract();
  const cacheSizeInBytes = getSizeInBytes(cacheData);
  const cacheSizeInMB = cacheSizeInBytes / 1024 / 1024;

  // number of objects of type ProcessTypes in the cache
  const numberOfCacheProcesses = Object.keys(cacheData.ROOT_QUERY).filter((key) => key.includes('ProcessType')).length;

  console.log(`Apollo Cache Size: ${cacheSizeInMB.toFixed(2)} MB`);
  console.log(`Number of Processes in Apollo Cache: ${numberOfCacheProcesses}`);

  // Calculate the size of the local storage
  const localStorageInstance = localforage.createInstance({
    name: 'storage',
    storeName: 'keyvaluepairs',
  });

  let localStorageSizeInBytes = 0;
  let numberOfLocalStorageProcesses = 0;

  await localStorageInstance.iterate((value: any) => {
    localStorageSizeInBytes += getSizeInBytes(value);
    numberOfLocalStorageProcesses += 1;
  });

  const localStorageSizeInMB = localStorageSizeInBytes / 1024 / 1024;

  console.log(`Local Storage Size: ${localStorageSizeInMB.toFixed(2)} MB`);
  console.log(`Number of Processes in Local Storage: ${numberOfLocalStorageProcesses}`);
}

