import React from "react";
import { Upload, Form,Input, Button, Card , Table, Tabs} from 'antd';
import PageHeader from "../components/PageHeader";
import {Link} from "react-router-dom";
import HeaderExtra from "../components/HeaderExtra";
import { UploadOutlined } from '@ant-design/icons';
import useWindowDimensions from "../components/useWindowDimensions";


import ReactGA from 'react-ga';

const { TabPane } = Tabs;

function Support() {

  //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
  //ReactGA.pageview('/Support/');

  // window dimensions
  const { height, width } = useWindowDimensions();

  const columns = [
    {
        title: 'Subject',
        dataIndex: 'subject',
        editable: true,
        width: '40%',
        render: (text:String, record:any) => (
            <Link to={'/processes/process/'+ record.id } >{text}</Link>),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      editable: true,
    },
    ];

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };

    return(  
      <div>
        <PageHeader
          title="Support & Feedback"
          extra={<HeaderExtra/>}
        />
        <Card style={{minHeight:height-130}}>

        <Tabs>

          <TabPane tab="Support requests" key="1" data-testid="support-tab">

            <Card title="Support requests">

              <Button 
                style = {{ marginBottom: 16 }}
                disabled={true}
                data-testid="create-ticket-button"
              >
                Create a support ticket
              </Button>

              <Table 
                //dataSource={data.processes}
                columns={columns} 
                bordered 
                //rowKey={record => record.id}
                        />

            </Card>
          </TabPane>
          
          <TabPane tab="Feedback" key="2" data-testid="feedback-tab">
            <Card title="Feedback and feature requests">
              <Form {...layout} name="nest-messages" >

                <Form.Item name={['user', 'introduction']} label="Comment">
                  <Input.TextArea data-testid="comment-input" disabled={true} />
                </Form.Item>

                <Form.Item name='files' label="Screenshots / images"> 
                  <Upload>
                    <Button 
                      icon={<UploadOutlined />} 
                      disabled={true} 
                      data-testid="upload-button"
                    >
                        Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item wrapperCol={{  }}>
                  <Button type="primary" htmlType="submit" disabled={true} data-testid="submit-button">
                  Send
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </TabPane>
          </Tabs>

          
        </Card>
       
      </div>
    )
  }
  
  export default Support;