// TODO: add function to delete all calculators in the topic

import { useState, useEffect } from "react";
import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { Card, Tabs, Skeleton, Collapse, Form, Button } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined , ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import PageHeader from "../PageHeader";
import HeaderExtra from "../HeaderExtra";
import CalculatorTab from "./CalculatorTab";
import { globalSelectedTopic, calculatorsSelectedProcess, calculatorSetCardCollapsed , calculatorsSelectedTab} from "../../Apollo";
import useWindowDimensions from "../useWindowDimensions";
import CalculatorSetTable from "./CalculatorSetTable";
import CalculatorSaveTable from './CalculatorSaveTable';
import VariableTable from "../variable/VariableTable";
import ParameterTable from "../ParameterTable"
import MetaDataTable from "../MetaDataTable";
import CalculatorsTopic from "./CalculatorsTopic";
import CalculatorsProcess from "./CalculatorsProcess";
import { CORE_CALCULATOR_SET_FIELDS, CORE_CALCULATOR_SAVE_FIELDS, CORE_TOPIC_FIELDS, CORE_CALC_SAVE_INTERFACE_FIELDS } from "../fragments";


const { Meta } = Card;
const { TabPane } = Tabs;
const { Panel } = Collapse;

export const GET_USER_PROFILE = gql`
    
    ${CORE_CALCULATOR_SET_FIELDS}
    ${CORE_CALCULATOR_SAVE_FIELDS}
    ${CORE_TOPIC_FIELDS}
    ${CORE_CALC_SAVE_INTERFACE_FIELDS}

    query userProfile {
        userProfile {
            id
            selectedTeam {
                id
                name
                topicSet {
                  ...CoreTopicFields
                  calculatorsetSet {
                    ...CoreCalculatorSetFields
                    calculatorsaveSet {
                      ...CoreCalculatorSaveFields
                      calcsaveinterfaceSet {
                        ...CalcSaveInterfaceFields
                      }
                    }
                  }
                }
            }
        }
    }
`

function Calculators() {

  //ReactGA.pageview('/calculators/');
  //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });

  const { loading:userProfileLoading, error:userProfileError, data:userProfileData } = useQuery(GET_USER_PROFILE , {
    fetchPolicy: "cache-and-network"
  });


  const [selectedCalculatorSet, setSelectedCalculatorSet] = useState("")
  const [selectedCalculatorSave, setSelectedCalculatorSave] = useState("2")

  const globalSelectedTopicValue = useReactiveVar(globalSelectedTopic)
  const calculatorsSelectedProcessValue = useReactiveVar(calculatorsSelectedProcess)
  const calculatorSetCardCollapsedValue = useReactiveVar(calculatorSetCardCollapsed)
  const calculatorsSelectedTabValue = useReactiveVar(calculatorsSelectedTab)


  // window dimensions
  const { height, width } = useWindowDimensions();

  //console.log("Global selected topic: ", globalSelectedTopicValue)
  //console.log("Selected team: ", userProfileData?.userProfile?.selectedTeam)

  // use Effect: when the topic is changed, reset the selected calculator set
  useEffect(() => {
    setSelectedCalculatorSet("")
  }, [globalSelectedTopicValue])


  if (globalSelectedTopicValue == "" || globalSelectedTopicValue == null ) {
    // set the selected topic to the first topic in the list
    if (userProfileData && userProfileData?.userProfile?.selectedTeam?.topicSet?.length > 0) {
      globalSelectedTopic(userProfileData.userProfile.selectedTeam.topicSet[0].id)
    }
  }

  // selected topic 
  const topic = userProfileData?.userProfile?.selectedTeam?.topicSet?.find((t: any) => t.id == globalSelectedTopicValue)


  if (userProfileError) return (
    <div>Error: {userProfileError.message} </div>
  );

  const routes = [
    {
      path: '',
      breadcrumbName: "Team: " + userProfileData?.userProfile?.selectedTeam?.name ,
    }
  ];


  if ((userProfileLoading && !userProfileData) || topic == undefined) return (
    <div>
      <PageHeader
        title="Calculations"
        extra={<HeaderExtra/>}
        breadcrumb={{ routes }}

      />
      <Card><Skeleton active /></Card>
    </div>

  );

  
  
  return (
    <div>
      <PageHeader
        title="Calculations"
        extra={<HeaderExtra/>}
        breadcrumb={{ routes }}

      />

      <div style={{ display: 'flex' }}>

        <div style={{  width: "100%" , overflow:"scroll"}}>

          
          <Card >
            
            <Tabs defaultActiveKey={calculatorsSelectedTabValue} onChange={(key) => calculatorsSelectedTab(key)} type="card">

              <TabPane tab="Collection" key="1" style={{ height: height - 240 , overflow:"hidden"}} data-testid="collection-tab">

                <Form.Item label="Collection" style={{ marginBottom: 20, marginTop:10, marginRight:10 }}>
                    <CalculatorsTopic teamId={userProfileData.userProfile.selectedTeam.id} />
                </Form.Item>

                  {/*
                  <Collapse accordion defaultActiveKey={["1", "2"]}>

                    <Panel header="Calculator Sets" key="1" data-testid="calculator-set-panel" >

                      <CalculatorSetTable
                        topic = {topic}
                        selectedCalculatorSet={selectedCalculatorSet}
                        setSelectedCalculatorSet={setSelectedCalculatorSet}
                      />

                    </Panel>

                    <Panel header={"Calculators of the Calculator Set"} key="2" data-testid="calculator-save-panel">

                      <CalculatorSaveTable
                        topic={topic}
                        calculatorSetId={selectedCalculatorSet}
                        selectedCalculatorSave={selectedCalculatorSave}
                        setSelectedCalculatorSave={setSelectedCalculatorSave}
                      />

                    </Panel>

                  </Collapse>
                  */}

                  {/* create a div with two divs inside that will be displayed side by side */}
                  <div style={{ display: 'flex' }}>
                  

                    { !calculatorSetCardCollapsedValue ? 
                    <div style={{ width: "42%" }}>
                      <Card style={{height:height-310}} title="Calculator Sets" extra={(<div><Button icon={<ArrowLeftOutlined />} title="Collapse" onClick={() => calculatorSetCardCollapsed(true)} style={{border:"none", background:"none"}} data-testid="collapse-calculator-sets-button"/></div>)}>
                        <CalculatorSetTable
                          topic={topic}
                          selectedCalculatorSet={selectedCalculatorSet}
                          setSelectedCalculatorSet={setSelectedCalculatorSet}
                        />
                      </Card>
                    </div>
                    :
                    <div style={{ width: "50px" }}>
                      <Card style={{height:height-310}} extra={(<div><Button icon={<ArrowRightOutlined />} title="Expand Calculator Sets" onClick={() => calculatorSetCardCollapsed(false)} style={{border:"none", background:"none", marginLeft:-15}} data-testid="expand-calculator-sets-button"/></div>)}>
                      </Card>
                    </div>

                  }

                    <div style={{ width: calculatorSetCardCollapsedValue ? "100%" : "57.5%", marginLeft:5 }}>
                    <Card style={{height:height-310}} title="Calculators" >
                      <CalculatorSaveTable
                        topic={topic}
                        calculatorSetId={selectedCalculatorSet}
                        selectedCalculatorSave={selectedCalculatorSave}
                        setSelectedCalculatorSave={setSelectedCalculatorSave}
                      />
                      </Card>
                    </div>

                  </div>


              </TabPane>

              <TabPane tab="Process" key="2" style={{ height: height - 240 , overflow:"hidden"}} data-testid="process-tab">
                <Form.Item label="Process" style={{ marginBottom: 20, marginTop:4 }}>
                    <CalculatorsProcess/>
                </Form.Item>
                <CalculatorTab processId={calculatorsSelectedProcessValue} topic={topic} />
              </TabPane>

              <TabPane tab="Data" key="3" style={{ height: height - 240 , overflow:"hidden"}} data-testid="data-tab">

                <Form.Item label="Process" style={{ marginBottom: 20, marginTop:10 }}>
                  <CalculatorsProcess/>
                </Form.Item>

                <Collapse accordion defaultActiveKey={["1"]}>

                  <Panel header="Variables" key="1" >
                    <div style={{ height: height - 500, overflow: "hidden" }}>
                      <VariableTable processId={calculatorsSelectedProcessValue} />
                    </div>
                  </Panel>

                  <Panel header="Parameters" key="2">
                    <div style={{ height: height - 500, overflow: "hidden" }}>
                      <ParameterTable processId={calculatorsSelectedProcessValue} />
                    </div>
                  </Panel>

                  <Panel header="Metadata" key="3">
                    <div style={{ height: height - 500, overflow: "hidden" }}>
                      <MetaDataTable processId={calculatorsSelectedProcessValue} />
                    </div>
                  </Panel>
                </Collapse>


              </TabPane>

              {/* belongs to the help section

              <TabPane tab="Calculators" key="4">

                <Row>
                  <Col span={24}>

                    <Card
                      hoverable={false}
                      style={{ width: 250, height: 300, float: "left", marginRight: 10, marginBottom: 10 }}
                      cover={<FunctionOutlined style={{ fontSize: 30, padding: 40 }} />}
                    //onClick={showModal}
                    >
                      <Meta title="Generic calculator" description="Allows for a wide range of user-defined calculations and functions with multiple inputs/outputs and automatic unit conversion." />
                    </Card>

                    <Card
                      hoverable={false}
                      style={{ width: 250, height: 300, float: "left", marginRight: 10, marginBottom: 10 }}
                      cover={<FunctionOutlined style={{ fontSize: 30, padding: 40 }} />}
                    //onClick={showModal}
                    >
                      <Meta title="Differentiation" description="Calculates the derivative of a variable." />
                    </Card>

                    <Card
                      hoverable={false}
                      style={{ width: 250, height: 300, float: "left", marginRight: 10, marginBottom: 10 }}
                      cover={<FunctionOutlined style={{ fontSize: 30, padding: 40 }} />}
                    //onClick={showModal}
                    >
                      <Meta title="Integration" description="Calculates the integral of a variable." />
                    </Card>

                    <Card
                      hoverable={false}
                      style={{ width: 250, height: 300, float: "left", marginRight: 10, marginBottom: 10, opacity: "100%" }}
                      cover={<FunctionOutlined style={{ fontSize: 30, padding: 40 }} />}
                    //onClick={showModal}
                    >
                      <Meta title="Physiological uptake / production rates" description="Calculates the volumetric uptake / production rates of product, cells, or other components in fermentation media using mass-balancing." />
                    </Card>
                  
                    <Tooltip title="Coming soon" placement="bottom">
                      <Card
                        hoverable={false}
                        style={{ width: 250, height: 300, float: "left", marginRight: 10, marginBottom: 10, opacity: "50%" }}
                        cover={<FunctionOutlined style={{ fontSize: 30, padding: 40 }} />}
                      //onClick={showModal}
                      >
                        <Meta title="Offgas analysis" description="Calculates the respiratory rates: oxygen uptake rate (OUR) and CO2 evolution rate (CTR) for fermentation processes." />
                      </Card>
                    </Tooltip>

                  </Col>
                </Row>

              </TabPane>
              */}



            </Tabs>
          </Card>

        </div>


      </div>
    </div>
  )
}

export default Calculators;