//import {useState,  useEffect} from 'react';
import { Spin} from 'antd';
import Plot from 'react-plotly.js';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import { pollInterval } from '../..';

const GET_VARIABLE = gql`
    query Variable ($id:ID!) {  
        variableById(id:$id) {
            id
            name
            description
            vartype
            time
            data
            units
            process {
                id
                name
            }
        }
        userProfile {
            id
            darkMode
        }
    }
`;

interface VariablePlotInputType {
    id:string;
  }

function VariablePlot(props:VariablePlotInputType) {

    const { loading, error, data } = useQuery(GET_VARIABLE , 
        {variables:{id:props.id}, 
        //pollInterval: pollInterval,
    }
    );


    if (loading) return <Spin />;
    if (error) return <p>Error: {error.message}</p>;
    
    let localTime = [];

    // convert time from utc to the local timezone
    if (data && data.variableById) {
        let time = data.variableById.time;        
        for (let i=0; i<time.length; i++) {
            localTime.push(moment.utc(time[i]).local().format('YYYY-MM-DD HH:mm:ss'));
        }
    }

    // set the background color of the plot
    let plotBgColor = "white"
    let axisColor = "#141414"
    let dataPointsColor = "navy"
    if (data?.userProfile.darkMode) {
        plotBgColor = "#141414"
        axisColor = "white"
        dataPointsColor = "cyan"
    }


    return(
        <Plot
            data={[
            {
                x: localTime,
                y: data.variableById.data[0],
                type: 'scatter',
                mode: 'lines+markers',
                line:{color:dataPointsColor},
                marker: {color: dataPointsColor},
            }]}
            layout={ 
            {
                autosize: true,
	            uirevision:'true',
                height: 350,
                plot_bgcolor: plotBgColor,
                paper_bgcolor: plotBgColor,

                margin: {
                    l: 80,
                    r: 20,
                    b: 40,
                    t: 40
                },

                xaxis:{
                    title:"Date",
                    color: axisColor,
                }, 
                yaxis:{
                    title: data.variableById.name + ' [' + data.variableById.units + ']',
                    color: axisColor,
                } 

        }}
        config={{displaylogo: false}}
        />
    )

}

export default VariablePlot