import { useState, useEffect, useMemo, useRef } from 'react';
import { useQuery, gql, useLazyQuery, useReactiveVar, useApolloClient } from '@apollo/client';
import { EXTENDED_PROCESS_FIELDS_WITH_DATA, CORE_ANALYSIS_FIELDS, EXTENDED_PROCESS_FIELDS, CORE_PROCESS_FIELDS } from './fragments';
import { getItem, setItem } from '../storage';
import { processesClient, processesLoading, processesLoadingProgress, preloadingActive, selectedAnalysis } from '../Apollo';
import { CORE_KPI_FIELDS, CORE_KPI_TABLE_FIELDS, CORE_USER_PROFILE_FIELDS } from './fragments';
import { useInterval } from 'usehooks-ts';

// get one process
const GET_PROCESS = gql`
    ${EXTENDED_PROCESS_FIELDS_WITH_DATA}
    query process ($id: ID!) {
        process(id: $id) {
            ...ExtendedProcessFieldsWithData
        }
    }
`;

const GET_USER_PROFILE = gql`

    query preloadUserProfile {

        userProfile {
            id
            selectedAnalysis{
                id
                name
                processes {
                    id
                    lastUpdated
                }
            }
        }
    }   
`;


const GET_USER_PROFILE_EXTENDED = gql`

    ${CORE_ANALYSIS_FIELDS}
    ${CORE_USER_PROFILE_FIELDS}
    ${CORE_PROCESS_FIELDS}

    query preloadUserProfileExteded {

        userProfile {
            ...CoreUserProfileFields
            selectedAnalysis {
                ...CoreAnalysisFields

                uniqueVars
                uniqueParams
                uniqueMeta


                processes {
                    ...CoreProcessFields                 
                }

                processExplorerView {
                    ...CoreViewFields
                    viewpageSet {
                        ...CoreProcessExplorerViewPageFields
                    }
                }

                kpiAnalysisView {
                    ...CoreViewFields
    
                    viewpageSet {
                        ...CoreKpiAnalysisViewPageFields
                    }
                  }
            }
        }
    }   
`;  


const GET_KPIS= gql`

    ${CORE_KPI_FIELDS}

    query KPIs ($analysisId:ID!) {
        KPIs (analysisId:$analysisId) {
            ...CoreKPIFields
        }
    }
`
const GET_KPI_TABLE = gql`
    ${CORE_KPI_TABLE_FIELDS}
    query kpiTable ($input:KpiTableInputType!) {  
        kpiTable(input:$input) {
            ...CoreKPITableFields
        }
    }
`;

interface LoadProcessDataProps {
}

function LoadProcessData(props: LoadProcessDataProps) {

    const debug = false;


    const processesLoadingValue = useReactiveVar(processesLoading);
    const processesLoadingProgressValue = useReactiveVar(processesLoadingProgress);
    const processesClientValue = useReactiveVar(processesClient);
    const selectedAnalysisValue = useReactiveVar(selectedAnalysis);
    const preloadingActiveValue = useReactiveVar(preloadingActive);
    const [checkAndUpdateProcessesRunning, setCheckAndUpdateProcessesRunning] = useState(false);
    const analysisId = useRef(null); // ref used to store the analysis id
    const [restartProcessLoading, setRestartProcessLoading] = useState(false);

    const client = useApolloClient();

    // get the user profile from Apollo
    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_USER_PROFILE, {
        //fetchPolicy: 'network-only',
    });

    // use lazy loading for the extended user profile
    const [lazyLoadingUserProfile, { loading: lazyLoadingUserProfileLoading, data: lazyLoadingUserProfileData }] = useLazyQuery(GET_USER_PROFILE_EXTENDED, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: any) => {
            if (debug) {
                console.log("Finished loading user profile")
            }
        }
    });

    // use lazy loading for the KPIs
    const [loadKPIs, { loading: kpiLoading, data: kpiData }] = useLazyQuery(GET_KPIS, {
        variables: { analysisId: queryData?.userProfile.selectedAnalysis.id },
        //fetchPolicy: 'cache-and-network',
        onCompleted: (data: any) => {
            if (debug) {
                console.log("Finished loading the KPIs")
            }
        }
    });

    // loop over analysis.processes and create an array with the ids
    let processIds: any = [];
    queryData?.userProfile.selectedAnalysis.processes.forEach((process: any) => {
        processIds.push(process.id);
    });

    // lazy load the KPI table
    const [loadKpiTable, { loading: kpiTableLoading, data: kpiTableData }] = useLazyQuery(GET_KPI_TABLE, {
        variables: { input: { analysisId: queryData?.userProfile.selectedAnalysis.id, 'processIds': processIds } },
        //fetchPolicy: 'cache-and-network', // this should be cache-first
        onCompleted: (data: any) => {
            if (debug) {
                console.log("Finished loading the KPIs Table")
            }
        }
    });

    const [loadProcess, { loading: lazyLoading, data: lazyData }] = useLazyQuery(GET_PROCESS, {
        fetchPolicy: 'no-cache',
    });

    const verbose = false;

    
    // use effect to lazy load the user profile once the queryData is loaded and whenever selectedAnalysis changes
    useEffect(() => {
        if (debug) {
            console.log("LoadProcessData 1: useEffect: queryData?.userProfile?.id", queryData?.userProfile?.id, selectedAnalysisValue)
        }

        if (queryData) {
            lazyLoadingUserProfile();
        }

        analysisId.current = queryData?.userProfile?.selectedAnalysis.id;

    }, [queryData?.userProfile?.id, selectedAnalysisValue]);

    // use effect to lazy load the KPIs and KPI Table once the user profile is loaded
    useEffect(() => {
        if (debug) {
            console.log("LoadProcessData 2: useEffect: lazyLoadingUserProfileData", lazyLoadingUserProfileData)
        }
        if (lazyLoadingUserProfileData) {
            loadKPIs();
            loadKpiTable();
        }
    }, [lazyLoadingUserProfileData?.userProfile?.selectedAnalysis?.id]);
    
    

    // use memo to store the lastUpdated of processes
    const lastUpdated = useMemo(() => {
        return queryData?.userProfile.selectedAnalysis.processes.map((process: any) => process.lastUpdated) || [];
    }, [queryData?.userProfile.selectedAnalysis.processes]);

    // get a list of ids, check the local storage, return an array of processes that exist in local storage and an array of ids that do not exist
    // consider the item to be available in cahce if the lastUpdated is the same as the lastUpdated in the queryData1
    
    // fetch a process from the network and update the local storage
    async function fetchProcess(processId: any, retries = 2) {
        return new Promise((resolve, reject) => {
            if (verbose) {
                console.log("Starting load for ID:", processId);
            }

            const attemptFetch = (attemptsLeft: any) => {
                loadProcess({
                    variables: { id: processId },
                    onCompleted: async (data: any) => {
                        if (verbose) {
                            console.log("Process loaded and received for ID:", processId);
                        }

                        const process = data.process;
                        try {
                            await setItem(`process-${process.id}`, JSON.stringify(process));

                            if (verbose) {
                                console.log("Process with id", process.id, "successfully updated in IndexedDB");
                            }
                            resolve(process);
                        } catch (error: any) {
                            console.error("Error updating IndexedDB for ID:", processId, error);
                            reject(new Error(`Failed to update local storage for ID ${processId}: ${error.message}`));
                        }
                    },
                    onError: (error: any) => {
                        console.error("Error on loading process for ID:", processId, error);
                        if (attemptsLeft > 0) {
                            if (verbose) {
                                console.log(`Retrying for ID ${processId}. Attempts left: ${attemptsLeft - 1}`);
                            }

                            attemptFetch(attemptsLeft - 1);
                        } else {
                            reject(new Error(`Network or loading error for ID ${processId}: ${error.message}`));
                        }
                    }
                });
            };

            attemptFetch(retries);
        });
    }

    async function getProcessesFromLocalStorage() {

        const processIds = queryData?.userProfile.selectedAnalysis.processes.map((process: any) => process.id) || [];
        const lastUpdated = queryData?.userProfile.selectedAnalysis.processes.map((process: any) => process.lastUpdated) || [];

        let processes = [];
        let missingProcessIds = [];

        for (let i = 0; i < processIds.length; i++) {
            const processId = processIds[i];
            const processLastUpdated = lastUpdated[i];

            const cachedProcessString: any = await getItem(`process-${processId}`);
            const cachedProcess = cachedProcessString ? JSON.parse(cachedProcessString) : null;

            // check whether cached process has calculatorSet
            

            if (cachedProcess && cachedProcess.lastUpdated === processLastUpdated  && cachedProcess.calculatorSet ) {
                processes.push(cachedProcess);
            } else {
                missingProcessIds.push(processId);
            }
        }

        return { processes, missingProcessIds };
    }

    //console.log("OUTSIDE: queryData analysis id", queryData?.userProfile.selectedAnalysis.id)
    //console.log("OUTSIDE: analysisId ref", analysisId.current)
    

    async function checkAndUpdateProcesses() {

        const debug = false;

        try {

            //console.log("Checking and updating processes")

            // if process loading is already in progress, return
            if (processesLoadingValue || checkAndUpdateProcessesRunning) {
                return;
            }
            
            if (debug) {
                console.log("Checking and updating processes: Starting...")
            }

            setCheckAndUpdateProcessesRunning(true);

            //preloadingActive(true);

            const { processes: initialProcesses, missingProcessIds } = await getProcessesFromLocalStorage();
            const total = missingProcessIds.length;

            if (total > 0) processesLoading(true);

            let loadedProcesses = [];
            for (let i = 0; i < total; i++) {
                const processId = missingProcessIds[i];
                try {
                    const processName = queryData?.userProfile.selectedAnalysis.processes.find((process: any) => process.id === processId)?.name;
                    processesLoadingProgress((i / total) * 100);
                    const process = await fetchProcess(processId);
                    loadedProcesses.push(process);
                    processesLoadingProgress(((i + 1) / total) * 100);
                    
                    // if int he meantime the selecte analysis has changed, break the loop
                    if (queryData?.userProfile.selectedAnalysis.id !== analysisId.current) {
                        if (debug) console.log("Analysis changed, breaking the loop")
                        processesLoading(false);
                        setCheckAndUpdateProcessesRunning(false);
                        setRestartProcessLoading(false);
                        setRestartProcessLoading(true);
                        return;
                        
                    }
                    

                } catch (err) {
                    console.error(err);
                }
            }
            let newProcessesClient = [...initialProcesses, ...loadedProcesses];
            // compare the lastUpdated of the processes in the newProcessesClient with the processesClient
            // if the same processes exist with the same lastUpdated, do not update the processesClient

            let updateNeeded = false;
            if (newProcessesClient.length !== processesClientValue.length) {
                updateNeeded = true;

            } else {
                for (let i = 0; i < newProcessesClient.length; i++) {
                    // find the process in the processesClient
                    const process = processesClientValue.find((p: any) => p.id === newProcessesClient[i].id);
                    // if the process is not found, update is needed
                    if (!process) {
                        updateNeeded = true;
                        break;
                    }
                    // if the process is found, check the lastUpdated
                    if (process.lastUpdated !== newProcessesClient[i].lastUpdated) {
                        updateNeeded = true;
                        break;
                    }
                }
            }
            
            if (!updateNeeded) {
                processesLoading(false);
                //preloadingActive(false);
                setCheckAndUpdateProcessesRunning(false);
                //console.log("No update needed")
                return;
            }

            //console.log("Updating processesClient")
            processesClient(newProcessesClient);
            
            // Update the Apollo cache
            for (let i = 0; i < newProcessesClient.length; i++) {
                const process = newProcessesClient[i];
                const cacheKey = `ProcessType:${process.id}`;
                const cacheData = {
                    ...process,
                    __typename: "ProcessType",
                };
                
                
                client.writeFragment({
                    id: cacheKey,
                    fragment: gql`
                        ${EXTENDED_PROCESS_FIELDS}
                    `,
                    fragmentName: 'ExtendedProcessFields',
                    data: cacheData,
                });
            }
            
            processesLoading(false);
            //preloadingActive(false);
            setCheckAndUpdateProcessesRunning(false);


        
        } catch (err) {
            console.error("Failed to load initial data:", err);
            processesLoading(false);
            setCheckAndUpdateProcessesRunning(false);
        }
    }

    useEffect(() => {
        if (queryData?.userProfile.selectedAnalysis.processes) {
            if (debug) {
                console.log("Checking and updating processs....")
            }
            checkAndUpdateProcesses();
        }
    }, [queryData?.userProfile.selectedAnalysis.processes, queryData?.userProfile.selectedAnalysis.id, selectedAnalysisValue, lastUpdated, restartProcessLoading]);

    useInterval(() => {
        if (debug) {
            console.log("Checking and updating processses every 30 seconds")
        }
        checkAndUpdateProcesses();
    }, 30000);

    useInterval(() => {
        if (queryLoading || (lazyLoadingUserProfileLoading && !lazyLoadingUserProfileData) || (kpiLoading) || (kpiTableLoading)) {
            preloadingActive(true);
        } else {
            preloadingActive(false);
        }
    }, 1000);   

    return <div></div>;
}

export default LoadProcessData;