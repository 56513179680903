import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { parse, DocumentNode, DefinitionNode, SelectionSetNode, FragmentDefinitionNode, FieldNode, FragmentSpreadNode, Kind } from 'graphql';

const isFragmentDefinitionNode = (node: DefinitionNode): node is FragmentDefinitionNode => {
    return node.kind === Kind.FRAGMENT_DEFINITION;
};

const isFieldNode = (node: any): node is FieldNode => {
    return node.kind === Kind.FIELD;
};

const isFragmentSpreadNode = (node: any): node is FragmentSpreadNode => {
    return node.kind === Kind.FRAGMENT_SPREAD;
};

const extractFragmentFields = (fragment: DocumentNode, fragmentName?: string): Record<string, any> => {
    const parsed = parse(fragment.loc?.source.body ?? '');
    const fields: Record<string, any> = {};

    const extractFields = (selectionSet: SelectionSetNode): any => {
        const result: Record<string, any> = {};
        selectionSet.selections.forEach(selection => {
            if (isFieldNode(selection)) {
                const fieldName = selection.name.value;
                result[fieldName] = null; // Initialize with null or a default value
                if (selection.selectionSet) {
                    result[fieldName] = extractFields(selection.selectionSet);
                }
            } else if (isFragmentSpreadNode(selection)) {
                const spreadFragmentName = selection.name.value;
                const fragmentDefinition = parsed.definitions.find(def => isFragmentDefinitionNode(def) && def.name.value === spreadFragmentName) as FragmentDefinitionNode;
                if (fragmentDefinition) {
                    Object.assign(result, extractFields(fragmentDefinition.selectionSet));
                }
            }
        });
        return result;
    };

    const fragmentDefinitions = parsed.definitions.filter(def => isFragmentDefinitionNode(def) && (!fragmentName || def.name.value === fragmentName)) as FragmentDefinitionNode[];

    fragmentDefinitions.forEach(def => {
        Object.assign(fields, extractFields(def.selectionSet));
    });

    return fields;
};

export const createOptimisticResponse = <T extends Record<string, any>>(
    client: ApolloClient<NormalizedCacheObject>,
    fragment: DocumentNode,
    id: string | number,
    values: Partial<T>,
    fragmentName: string // Fragment name is required when there are multiple fragments
): Partial<T> => {
    const fields = extractFragmentFields(fragment, fragmentName);

    const fillValues = (fields: any, values: any, cacheData: any): any => {
        for (const key in fields) {
            if (fields[key] && typeof fields[key] === 'object') {
                fields[key] = fillValues(fields[key], values[key] || {}, cacheData[key] || {});
            } else if (values[key] !== undefined) {
                fields[key] = values[key];
            } else if (cacheData[key] !== undefined) {
                fields[key] = cacheData[key];
            }
        }
        return fields;
    };

    const fragmentDef = fragment.definitions.find(def => def.kind === Kind.FRAGMENT_DEFINITION && (def as FragmentDefinitionNode).name.value === fragmentName) as FragmentDefinitionNode;
    const typeName = fragmentDef.typeCondition.name.value;
    const cacheData = client.readFragment<T>({
        id: `${typeName}:${id}`,
        fragment,
        fragmentName,
    });

    return fillValues(fields, values, cacheData || {});
};