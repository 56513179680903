import { useState, useEffect } from 'react';
import { Space, Card, Skeleton, Switch, Tooltip } from 'antd';
import Plot from 'react-plotly.js';
import { useQuery, gql, useReactiveVar } from '@apollo/client';
import KPIAnalysisSelectKPI from './KPIAnalysisSelectKPI';
import useWindowDimensions from '../useWindowDimensions';
import { kpiAnalysisSelectedViewPageVar } from '../../Apollo';
import { CORE_KPI_TABLE_FIELDS } from '../fragments';

const GET_KPI_TABLE = gql`
    ${CORE_KPI_TABLE_FIELDS}
    query kpiTable ($input:KpiTableInputType!) {  
        kpiTable(input:$input) {
            ...CoreKPITableFields
        }
        userProfile {
            id
            darkMode
          }
    }
`;

interface KPIAnalysisPlotInputType {
    id: number
    view: any
    processes:any
    setSelectedSidebarProcess?:Function
    analysis:any
    loading: boolean
}

function KPIAnalysisPlot(props: KPIAnalysisPlotInputType) {

    const [xAxisSelection, setXAxisSelection] = useState("")
    const [yAxisSelection, setYAxisSelection] = useState("")

    const [plotWidth, setPlotWidth] = useState("32%")
    const [plotHeight, setPlotHeight] = useState(400)
    const [plotFractiom, setPlotFraction] = useState(0.8)

    const [dataFull, setDataFull] = useState([]) // data to hold the entire kpiTable values
    const [data, setData] = useState([]) // data to hold the kpiTable values corrsponding to the selected processes for display
    const [processIdsForDisplay, setProcessIdsForDisplay] = useState<number[]>([]) // process ids for the processes to be displayed in the plot
    const [processIndexForDisplay, setProcessIndexForDisplay] = useState<string[]>([]) // process ids for the processes to be displayed in the plot

    const { height, width } = useWindowDimensions();
    const kpiAnalysisViewSelectedViewPage = useReactiveVar(kpiAnalysisSelectedViewPageVar);
    const viewPage = props.analysis.kpiAnalysisView.viewpageSet.find( (viewPage:any) => viewPage.id == kpiAnalysisViewSelectedViewPage );

    // state variables for box plot notches
    const [isNotched, setNotched] = useState(false)

    // loop over props selectedProcesses and create an array with the ids
    let processIds:any = []
    props?.processes.forEach((process: any) => {
        processIds.push(process.id)
    })

    const { loading: queryLoading2, error: queryError2, data: queryData2 } = useQuery(GET_KPI_TABLE,
        {
            variables: { 'input': {  'processIds': processIds , 'analysisId': props.analysis.id} }, 
            fetchPolicy: 'cache-and-network',
        }
    );

    // adjust the plotWidth and plotHeight parameters based on number of plots in the view
    useEffect(() => {

        const viewPage = props.analysis.kpiAnalysisView.viewpageSet.find( (viewPage:any) => viewPage.id == kpiAnalysisViewSelectedViewPage );
        let numplots = viewPage?.kpiAnalysisNumplots;

        if (numplots == undefined) {
            numplots=4
        }

        if (numplots >= 5) {
            setPlotWidth("32%")
            setPlotHeight( (height/2) - 98  )
            setPlotFraction(0.8)
        } else if (numplots == 4) {
            setPlotWidth("48.5%")
            setPlotHeight( (height/2) - 98 )
            setPlotFraction(0.8)
        } else if (numplots == 3) {
            setPlotWidth("32%")
            setPlotHeight(height - 185)
            setPlotFraction(0.9)
        } else if (numplots == 2) {
            setPlotWidth("48.5%")
            setPlotHeight(height - 185)
            setPlotFraction(0.9)
        } else {
            setPlotWidth("98%")
            setPlotHeight(height - 185 )
            setPlotFraction(0.9)
        }

        if (width < 1100) {
            setPlotWidth("98%")
        } else if (width >= 1100 && width < 1400) {
            setPlotWidth("48.5%")
        }

    })

    // use Effect to set the dataFull state variable
    useEffect(() => {
        if (queryData2 == undefined || queryData2.kpiTable == null) return
        const value = JSON.parse(queryData2.kpiTable.json)
        setDataFull(value.data)
    }, [queryData2])

    // use Effect to set the ProcessIdsForDisplay state variable
    useEffect(() => {
        setProcessIdsForDisplay(props.view.processesForDisplay.map((process: any) => process.id))
    }, [props.view.processesForDisplay])

    // use Effect to set the data state variable
    useEffect(() => {
        // If queryData2 is undefined, exit the function early
        if (queryData2 == undefined || queryData2.kpiTable == null) return;
    
        // Convert processIdsForDisplay to a Set for efficient lookup
        const processIdsForDisplaySet = new Set(processIdsForDisplay);
    
        // Map over each id in queryData2.kpiTable.processIds
        // If the id is in processIdsForDisplaySet, return its index
        // If the id is not in processIdsForDisplaySet, return -1
        // This results in an array of indices of the ids in queryData2.kpiTable.processIds that are also in processIdsForDisplay
        // The -1 values are then removed from the array using the filter function
        const processIdsIndices = queryData2.kpiTable.processIds
            .map((id: number, index: number) => processIdsForDisplaySet.has(id) ? index : -1)
            .filter((index: number) => index !== -1);
    
        // Set the data state variable
        // Filter dataFull to only include the items whose index is in processIdsIndices
        setData(dataFull.filter((item: any, index: number) => processIdsIndices.includes(index)));

        // processIndexForDisplay is an array of strings containing the process names for the processes to be displayed in the plot
        setProcessIndexForDisplay(processIdsIndices.map((index: number) => queryData2.kpiTable.index[index]))
    
        // The useEffect hook will run whenever processIdsForDisplay or dataFull changes
    }, [processIdsForDisplay, dataFull]);

    if ((queryLoading2 && !queryData2) || props.loading) {
        return (
            <Card style={{ height: plotHeight, marginLeft: 10, marginBottom: 10 }}>
                <Skeleton paragraph={{ rows: 6 }} active />
            </Card>)
    }

    if (queryError2) return (
        <div> 
            <p>Error: {queryError2.message}</p>
        </div>
    );

    // set the background color of the plot
    let plotBgColor = "white"
    let axisColor = "black"

    if (queryData2?.userProfile?.darkMode) {
        plotBgColor = "#141414"
        axisColor = "white"
    }
    
    // return an empty plot if there is no data
    if ( queryData2.kpiTable == null || queryData2.kpiTable == undefined ) {
        return (
            <Card style={{ height: plotHeight, marginLeft: 10, marginBottom: 10 }} bordered={false}>
              
              <Plot
                data-testid = {"kpi-analysis-plot-" + props.id}
                useResizeHandler={false}
                style={{ width: "100%" }}
                data={[
                    {
                        x: [],
                        y: [],
                        type: 'scatter',
                        mode: 'markers',
                    }
                ]}
                layout={
                    {
                        autosize: true,
                        height: plotFractiom * plotHeight,
                        margin: {
                            l: 60,
                            r: 3,
                            b: 40,
                            t: 20
                        },
                        paper_bgcolor: plotBgColor,
                        plot_bgcolor: plotBgColor,
                        xaxis: {
                            title: "",
                            mirror:false,
                            linewidth:1,
                            color:axisColor,
                            ticks: 'inside',
                            tickformat: '.3',
                            zeroline:false,
                            tickcolor: axisColor,
                            linecolor: axisColor,
                            //gridcolor: "#cccccc",
                        },
                        yaxis: {
                            title: "",
                            mirror:false,
                            linewidth:1,
                            color:axisColor,
                            ticks: 'inside',
                            tickformat: '.3',
                            zeroline:false,
                            tickcolor: axisColor,
                            linecolor: axisColor,
                            //gridcolor: "#cccccc",
                        },
                        legend: {
                            borderwidth: 1,
                        },
                        
                    }}
                config={{
                    displaylogo: false,
                    responsive: true,
                    toImageButtonOptions: {
                        format: 'svg', // one of png, svg, jpeg, webp
                        filename: '',
                        scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                    }
                }}
            />

            </Card>)
    }

    //console.log("queryData2.kpiTable", queryData2.kpiTable)

    /////////////////////////////////////////////////////////////////
    

    //const x_idx = queryData.kpiTable.columnIds.findIndex((id: any) => (id == xAxisSelection))
    //const y_idx = queryData.kpiTable.columnIds.findIndex((id: any) => (id == yAxisSelection))

    const x_idx = queryData2.kpiTable.columns.findIndex((name: string) => (name == xAxisSelection))
    const y_idx = queryData2.kpiTable.columns.findIndex((name: string) => (name == yAxisSelection))

    // function to get the column using column index
    const arrayColumn = (arr: any, n: number) => arr.map((x: any) => x[n]);

    let x_data:any = []
    let x_axis_label = ""

    if (x_idx != -1) {
        x_data = arrayColumn(data, x_idx)
    }

    let y_data:any = []
    let y_axis_label = ""

    if (y_idx != -1) {
        y_data = arrayColumn(data, y_idx)
    }



    //const x_categorical = x_data.some(isNaN)
    //const y_categorical = y_data.some(isNaN)

    // determine if any elements of x_data is of type string
    const x_categorical = x_data.some((element: any) => typeof element === 'string') || x_data.some(isNaN)
    const y_categorical = y_data.some((element: any) => typeof element === 'string') || y_data.some(isNaN)

    // ###############################################################
    // why are we doing the following lines of code?
    // for cases where the variable is categorical, but the string contains digits, plotly interprets the values as numbers
    // this is not what we want, so we convert the values to strings and the only way to force plotly to do this is to
    // add parentheses around the values. This is a hack, but it works.

    // if x_data is categorical, then we need to convert it to a string
    if (x_categorical) {
        // if the item in x_data contains digits only, dots, and commas only then add space to the string
        // make sure not to convert null values
        x_data = x_data.map((item: any) => {
            if (item != null && item.toString().match(/^[0-9.,]+$/)) {
                return "(" + item.toString() + ")"
            } else {
                return item
            }
        })
    }

    // if y_data is categorical, then we need to convert it to a string
    if (y_categorical) {
        // if the item in y_data contains digits, dots, and commas, only, then add space to the string
        // make sure not to convert null values
        y_data = y_data.map((item: any) => {
            if (item != null && item.toString().match(/^[0-9.,]+$/)) {
                return "(" + item.toString() + ")"
            } else {
                return item
            }
        })
    }

    // ###############################################################

    let plotData: any = []
    let notchDisplay
    let plotTitle = ""

    let colorVector:any = []
    let defaultColor = '#0072BD'

    // create an object containing process ids and colors
    const processColorMap = props.view.processcolorSet.reduce((acc: any, curr: any) => {
        acc[curr.process.id] = curr.color
        return acc
    } , {})

    // loop through the processes and assign a color to each one
    for (let i = 0; i < queryData2.kpiTable.processIds.length; i++) {
        
        // if processColorMap contains the process id, use the color in the map
        if (processColorMap[queryData2.kpiTable.processIds[i]]) {
            colorVector.push(processColorMap[queryData2.kpiTable.processIds[i]])
        } else {
            colorVector.push(defaultColor)
        }
    }

    // names of selected processes
    let selectedProcessIds = processIdsForDisplay
    let selectedProcessNames = selectedProcessIds.map((id: any) => queryData2.kpiTable.index[queryData2.kpiTable.processIds.findIndex((pid: any) => pid == id)])

    // find the most frequent color
    const mostFrequentColor = colorVector.reduce((a: any, b: any) => {
        return colorVector.filter( (c:any) => c === a).length > colorVector.filter( (c:any) => c === b).length ? a : b
    } , colorVector[0])

    
    // if both x and y variables are selected
    if (x_idx != -1 && y_idx != -1) {

        if (!x_categorical) {

            plotData.push({
                x: x_data,
                y: y_data,
                id: processIdsForDisplay,
                type: "scatter",
                mode: "markers",
                marker: {
                    size: 12,
                    color: colorVector,
                },
                text: processIndexForDisplay,
                
            })

        }

        // box plot if x is categorical
        if (x_categorical) {

            let categoryCounts:any = {};

            // Iterate over the data to populate categoryCounts
            x_data.forEach((category:any, index:any) => {
                // Check if the corresponding y_data value is not missing (is a number)
                if (y_data[index] !== null && y_data[index] !== undefined && !isNaN(y_data[index])) {

                    if (!categoryCounts[category]) {
                    categoryCounts[category] = 1;
                    } else {
                    categoryCounts[category]++;
                    }
                }
            });
            
            // adding the count for each category
            let modifiedXData = x_data.map((x:any) => `${x}\n(N=${categoryCounts[x]})`);
            

            plotData.push({
                x: modifiedXData,
                y: y_data,
                id: processIdsForDisplay,
                type: "box",
                text: selectedProcessNames, // vector of process names
                boxpoints: 'all',
                notched: isNotched,
                marker: {
                    color: mostFrequentColor,
                },
                //name: process.name,
            })

            // we need to perform a non-parametric test to determine if the means of the two groups are different
            // we use the Kruskal-Wallis H test
            

            notchDisplay = (

                <div style={{ marginRight: 10 }}  >
                    <Tooltip title="notched">
                        <Switch style={{ marginLeft: 5 }} size="small" defaultChecked={isNotched} onChange={onNotchChange} data-testid={"kpi-analysis-plot-notched" + props.id } />
                    </Tooltip>

                </div>

            )

        }

        if (queryData2.kpiTable.columnUnits[x_idx] != null) {
            x_axis_label = queryData2.kpiTable.columns[x_idx] + " [" + queryData2.kpiTable.columnUnits[x_idx] + "]"
        } else {
            x_axis_label = queryData2.kpiTable.columns[x_idx]
        }

        if (queryData2.kpiTable.columnUnits[y_idx] != null) {
            y_axis_label = queryData2.kpiTable.columns[y_idx] + " [" + queryData2.kpiTable.columnUnits[y_idx] + "]"
        } else {
            y_axis_label = queryData2.kpiTable.columns[y_idx]
        }
    }

    // if x variable is not selected & y variable is selected

    if (x_idx == -1 && y_idx != -1) {

        // bar plot if y is not categorical

        if (!y_categorical) {

            plotData.push({
                x: processIndexForDisplay,
                y: y_data,
                type: "bar",
                marker: {
                    color: mostFrequentColor,
                },
            })

            // calculate the mean and standard deviation of the y_data (exclude empty values)
            const y_for_mean = y_data.filter((item: any) => item != null)
            if (y_for_mean.length > 0) {
                const mean = y_for_mean.reduce((a: any, b: any) => a + b) / y_for_mean.length
                const standardDeviation = Math.sqrt(y_for_mean.map((x: any) => Math.pow(x - mean, 2)).reduce((a: any, b: any) => a + b) / y_for_mean.length)

                let units = queryData2.kpiTable.columnUnits[y_idx]
                if (units == null) {
                    units = ""
                }

                let N = y_for_mean.length

                // add the mean and standard deviation to the plot title using the plus minus symbol
                plotTitle = "Mean: " + mean.toFixed(2) + " \u00B1 " + standardDeviation.toFixed(2) + " " + units + " (N=" + N + ")"
            }


        // pie chart if y is categorical
        } else {


            var counts: any = {}

            for (var i = 0; i < y_data.length; i++) {
                counts[y_data[i]] = 1 + (counts[y_data[i]] || 0);
            }

            plotData.push({
                labels: Object.keys(counts),
                values: Object.values(counts),
                type: "pie",
            })

        }

        if (queryData2.kpiTable.columnUnits[y_idx] != null) {
            y_axis_label = queryData2.kpiTable.columns[y_idx] + " [" + queryData2.kpiTable.columnUnits[y_idx] + "]"
        } else {
            y_axis_label = queryData2.kpiTable.columns[y_idx]
        }

    }

    if (x_idx != -1 && y_idx == -1) {
        plotData.push({
            x: x_data,
            //y: y_data,
            type: "histogram",
            //mode: "markers",
            //text: queryData.kpiTable.index,
            marker: { 
                color: mostFrequentColor,
            },
            //name: 'process.name',
        })

        if (queryData2.kpiTable.columnUnits[x_idx] != null) {
            x_axis_label = queryData2.kpiTable.columns[x_idx] + " [" + queryData2.kpiTable.columnUnits[x_idx] + "]"
        } else {
            x_axis_label = queryData2.kpiTable.columns[x_idx]
        }
    }

    function onNotchChange(value: any) {
        setNotched(value)
    }

    // function to handle onClick of the plot
    function plotOnClick(value: any) {
        
        if (props.setSelectedSidebarProcess) {
            if (value.points[0].data.id) {
                props.setSelectedSidebarProcess(value.points[0].data.id[value.points[0].pointIndex])
            }
        }
    }

    //console.log("plotData", plotData)

    return (
        <Card style={{ height: plotHeight, marginLeft: 10, marginBottom: 10 }}>

            <Space>
                <div style={{ marginRight: 10 }}>x: <KPIAnalysisSelectKPI placeholder="x-axis" setSelection={setXAxisSelection} axisType='x' plotId={props.id} analysis={props.analysis} /></div>
                <div style={{ marginRight: 10 }}>y: <KPIAnalysisSelectKPI placeholder="y-axis" setSelection={setYAxisSelection} axisType='y' plotId={props.id} analysis={props.analysis} /></div>

                {notchDisplay}

            </Space>
            <Plot
                data-testid = {"kpi-analysis-plot-" + props.id}
                data={plotData}
                useResizeHandler={false}
                style={{ width: "100%" , marginTop:0}}
                //onHover={plotOnHover}
                onClick = {plotOnClick}
                

                layout={
                    {
                        autosize: true,
                        title: {
                            text: plotTitle,
                            font: {
                                size: 12,
                            },
                            xanchor: 'right',
                            x: 0.95,
                        },
                        
                        height: plotFractiom * plotHeight,
                        margin: {
                            l: 100,
                            r: 10,
                            //    b: 40,
                            t: 30
                        },
                        plot_bgcolor: plotBgColor,
                        paper_bgcolor: plotBgColor,

                        xaxis: {
                            title: x_axis_label,
                            //range: [props.view.xaxisMin, props.view.xaxisMax],
                            mirror:false,
                            linewidth:1,
                            color:axisColor,
                            ticks: 'inside',
                            tickformat: '.3',
                            zeroline:false,
                            tickcolor: axisColor,
                            linecolor: axisColor,
                            //tickangle: -90
                            //gridcolor: "#cccccc",
                        },
                        yaxis: {
                            title: {
                                text: y_axis_label,
                                standoff: 20,
                            },
                            //title: props.view.yaxis[props.id] + " [" + yUnits + "]",
                            mirror:false,
                            linewidth:1,
                            color:axisColor,
                            ticks: 'inside',
                            tickformat: '.3',
                            zeroline:false,
                            tickcolor: axisColor,
                            linecolor: axisColor,
                            //gridcolor: "#cccccc",
                        },
                        //showlegend: true,
                        legend: {
                            borderwidth: 1,
                            bordercolor: axisColor,
                            font: {
                                color: axisColor
                            }
                        },
                    }}
                config={{
                    displaylogo: false,
                    responsive: true,
                    toImageButtonOptions: {
                        format: 'svg', // one of png, svg, jpeg, webp
                        filename: 'KPI_Analysis_Image',
                        scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                    }
                }}
            />


        </Card>
    )

}

export default KPIAnalysisPlot