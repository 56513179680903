
import { Collapse, Card, Skeleton } from 'antd';
import { useReactiveVar } from '@apollo/client';
import SelectProcesses from '../process_explorer//SelectProcesses'
import KPIAnalysisOptions from './KPIAnalysisOptions';
import useWindowDimensions from '../useWindowDimensions';
import { leftMenuOpenVar } from '../../Apollo';

const { Panel } = Collapse;

interface KPIAnalysisMenuInputType {
    selectedView: any;
    loading: boolean;
    setLoading: Function;
    processes: any;
    analysis: any;
}

function KPIAnalysisMenu(props:KPIAnalysisMenuInputType) {

    const leftMenuOpen = useReactiveVar(leftMenuOpenVar);

    const { height, width } = useWindowDimensions();

    if ( props.loading) {
        return (
            <div style={{  overflow:'auto' , width: '220px'}} data-testid="kpi-analysis-menu-loading">
                <Card style={{minHeight: height - 130}} >
                    <Skeleton paragraph={{ rows: 22 }} active />
                </Card>
            </div>
        )
    }
   
    return (
         
        <div style={{  height:"calc(100vh - 130px)", overflow:'auto' , width: '220px'  }}  >

            <Collapse 
                defaultActiveKey={ leftMenuOpen }
                    onChange={(key:any) => {
                        leftMenuOpenVar(key)
                    }
                }
                size="small"
            >
                
                <Panel header={ "Analysis processes (" + props.processes.length + ")"} key="1" data-testid="kpi-analysis-menu-processes">
                    <SelectProcesses 
                        view={ props.selectedView }
                        displaySelectProcesses={true} 
                        selectAllProcesses={true}
                        analysis={props.analysis} 
                        processes={props.processes}
                        loading={props.loading}
                    />
                </Panel>

                <Panel header="Options" key="3" data-testid="kpi-analysis-menu-options" >
                    <KPIAnalysisOptions analysis={props.analysis} />
                </Panel>
                
            </Collapse>
        </div>
    )   
}

export default KPIAnalysisMenu;