import React, { FunctionComponent } from 'react';
import {Route,Navigate} from "react-router-dom";

export const ProtectedRoute: FunctionComponent<any> = ({ children }) => {
    return isAuthenticated() ? (
        
        children
        
    ) : (
        <Navigate to="/login" />
    );
}

type ValidToken = string;
type ExpiredToken = 'Expired token'
type NullToken = '' | null

export type JwtTokenType = (ValidToken | ExpiredToken | NullToken )
export const isNullToken = (token: JwtTokenType) : boolean => {
    return (token === '' || token === null)
}

export const isExpiredToken = (token: JwtTokenType) : boolean => {
    return token === "Expired token"
}

export const isAuthenticated = () : boolean => {
    let token = sessionStorage.getItem('token')
    return !(isExpiredToken(token) || isNullToken(token));
}


