import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Table, Skeleton } from 'antd';
import useWindowDimensions from '../useWindowDimensions';

const GET_TASKS_AND_WORKERS = gql`
  query GetTasksAndWorkers {
    celeryTasks {
      id
      name
      worker
    }
    celeryWorkers {
      hostname
      availability
      tasksProcessed
      uptime
    }
  }
`;

interface Task {
  id: string;
  name: string;
  worker: string;
}

interface Worker {
    hostname: string;
    availability: boolean;
    tasksProcessed: number;
    uptime: number;
}

const CeleryTable: React.FC = () => {

  const { height, width } = useWindowDimensions();

  const { data, loading, error } = useQuery(GET_TASKS_AND_WORKERS, { 
    pollInterval:5000,
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data) return <Skeleton active />;
  if (error) return <p>Error: {error.message}</p>;

  const taskColumns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Worker', dataIndex: 'worker', key: 'worker' },
  ];

  const workerColumns = [
    { title: 'Hostname', dataIndex: 'hostname', key: 'hostname' },
    { title: 'Availability', dataIndex: 'availability', key: 'availability', render: (availability: boolean) => (availability ? 'Yes' : 'No') },
    { title: 'Tasks Processed', dataIndex: 'tasksProcessed', key: 'tasksProcessed' },
    { title: 'Uptime', dataIndex: 'uptime', key: 'uptime' },
  ];

  return (
    <div>
      
      <Table 
        dataSource={data.celeryTasks} 
        columns={taskColumns} 
        //rowKey="id" 
        size="small" 
        scroll={{ y: height - 300 }} 
        pagination={false}
      />

      
    </div>
  );
};

export default CeleryTable;
