import ProcessExplorerPlot from './ProcessExplorerPlot'
import {Skeleton, Row, Col, Card} from 'antd';
import useWindowDimensions from '../useWindowDimensions';
import { processExplorerSelectedViewPageVar } from '../../Apollo';
import { useReactiveVar } from '@apollo/client';

interface ProcessExplorerPlotsInputType {
    processes:any
    analysis:any
    loading:boolean
    setSelectedSidebarProcess:Function
    selectedView:any
    selectedProcesses:any
    processFetchingComplete:boolean
}

function ProcessExplorerPlots (props:ProcessExplorerPlotsInputType) {

    const processExplorerSelectedViewPage = useReactiveVar(processExplorerSelectedViewPageVar);

    //const view = queryData1.processExplorerViews.find( (view:any)=>( view.id == clientVars.selectedViewId) ) 
    const viewPage = props?.selectedView?.viewpageSet.find( (viewpage:any)=>( viewpage.id == processExplorerSelectedViewPage) )

    const { height, width } = useWindowDimensions();

    const skeleton = (<Skeleton paragraph={{ rows: 6 }} active />)
    
    const boxHeight = height / 2 - 95;

    if (props.loading || viewPage == undefined) return (

        <div data-testid="process-explorer-plots-loading">
            <Row>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}>{skeleton}</Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}>{skeleton}</Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}>{skeleton}</Card>
                </Col>
            </Row>
            <Row >
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}>{skeleton}</Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}>{skeleton}</Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}>{skeleton}</Card>
                </Col>
            </Row>
        </div>

    );

    
    if (width<1200) {
    
        // for screens that are not wide - putting plots into one columns
        const plots = []
        for (var i=0 ; i < viewPage.numplots; i++) {
            plots.push(
                <ProcessExplorerPlot key={i} id={i} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess}  loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
            )
        }

        return(
            <Row style={{height:height-160, overflow:'scroll'}}>
                <Col span={24}>
                    {plots}
                </Col>
            </Row>
        )


    }

    if (viewPage.numplots == 1) {
        return(
            <Row>
                <Col span={24}>
                    <ProcessExplorerPlot key={0} id={0} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={ props.loading}  processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
            </Row>
        )
    } else if (viewPage.numplots == 2) {
        return (
            <Row>
                <Col span={12}>
                    <ProcessExplorerPlot key={0} id={0} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
                <Col span={12}>
                    <ProcessExplorerPlot key={1} id={1} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
            </Row>
        )
    } else if (viewPage.numplots == 3) {
        return (
            <Row>
                <Col span={8}>
                    <ProcessExplorerPlot key={0} id={0} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
                <Col span={8}>
                    <ProcessExplorerPlot key={1} id={1} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
                <Col span={8}>
                    <ProcessExplorerPlot key={1} id={2} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
            </Row>
        )
    } else if (viewPage.numplots == 4) {
        return (
            <div>
                <Row>
                    <Col span={12}>
                        <ProcessExplorerPlot key={0} id={0} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                    </Col>
                    <Col span={12}>
                        <ProcessExplorerPlot key={1} id={1} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                    </Col>
                </Row>
                <Row>
                <Col span={12}>
                    <ProcessExplorerPlot key={2} id={2} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
                <Col span={12}>
                    <ProcessExplorerPlot key={3} id={3} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
            </Row>
        </div>
        )
    } else {

        return (
            <div>
                <Row >
                    <Col span={8}>
                        <ProcessExplorerPlot key={0} id={0} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                    </Col>
                    <Col span={8}>
                        <ProcessExplorerPlot key={1} id={1} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                    </Col>
                    <Col span={8}>
                    <ProcessExplorerPlot key={2} id={2} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
                </Row>
                <Row >
                <Col span={8}>
                    <ProcessExplorerPlot key={3} id={3} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
                <Col span={8}>
                    <ProcessExplorerPlot key={4} id={4} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                </Col>
                <Col span={8}>
                    { viewPage.numplots == 6 &&
                        <ProcessExplorerPlot key={5} id={5} processData={props.processes} setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processFetchingComplete={props.processFetchingComplete} analysis={props.analysis}/>
                    }
                    
                </Col>
            </Row>
        </div>
        )

    }

}

export default ProcessExplorerPlots