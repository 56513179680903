import { useEffect } from 'react';
import { Space, Switch, InputNumber, message, Skeleton} from 'antd';
import { useQuery, useMutation, gql , useReactiveVar} from '@apollo/client';
import { processExplorerSidebarVisible, processExplorerSelectedViewPageVar } from '../../Apollo';
import useWindowDimensions from "../useWindowDimensions";
import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';
import { createOptimisticResponse } from '../createOptimisticResponse';

import { CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS } from '../fragments';

const UPDATE_VIEW_PAGE = gql`
    ${CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS }
    mutation updateViewPage ($input: UpdateViewPageInputType!) {
        updateViewPage( input:$input) {
            viewPage {
                ...CoreProcessExplorerViewPageFields
            }
        }
    }
`;

export const GET_CLIENT_DATA = gql`
    query {
        processExplorerSidebarVisible @client
    }   
`

interface ProcessExplorerPlotOptionsInputType {
  analysis:any
}
  
function ProcessExplorerPlotOptions(props:ProcessExplorerPlotOptionsInputType) {

    const { height, width } = useWindowDimensions();
    const { data: clientData } = useQuery(GET_CLIENT_DATA);
    const processExplorerSelectedViewPage = useReactiveVar(processExplorerSelectedViewPageVar);
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

      
    const [ updateViewPage ] = useMutation(UPDATE_VIEW_PAGE, {
        onError(error) {message.error(error.message)},
        //update: updateCacheAfterViewUpdate,
    });



   // useEffect to set sidebar visible to false is width is less than 1200
    useEffect(() => {
        if (width < 1200) {
            processExplorerSidebarVisible(false)
        }
    }, [width])

    let selectedViewPage = props.analysis.processExplorerView.viewpageSet.find((viewpage:any) => viewpage.id === processExplorerSelectedViewPage)

    if (selectedViewPage === undefined) {
        return (<div><Skeleton active/></div>)
    }
    
    function onChangeLegend(legendValue:boolean) {


        let input = {
            id: selectedViewPage.id,
            showLegend: legendValue
        }

        const optimisticResponse = createOptimisticResponse(
            client,
            CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
            selectedViewPage.id,
            {showLegend: legendValue},
            "CoreProcessExplorerViewPageFields"
        )

        // perform the mutation to update the value of the Y-Axis in the view
        // get an optimistic response to update the cache
        updateViewPage( {
            variables: { input: input  } ,
            optimisticResponse: {
                updateViewPage: {
                    __typename: 'Mutation',
                    viewPage: {
                        __typename: 'ViewPageType',
                        ...optimisticResponse
                    }
                }
            }
        } );
    }


    function onChangeNumplots(numplots:number | null) {

        if (numplots === null) {
            return
        }

        // if number is between 1 and 6
        if (numplots >= 1 && numplots <= 6) {
            let input = {
                id: selectedViewPage.id,
                numplots: numplots
            }

            const optimisticResponse = createOptimisticResponse(
                client,
                CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
                selectedViewPage.id,
                {numplots: numplots},
                "CoreProcessExplorerViewPageFields"
            )

            // perform the mutation to update the value of the Y-Axis in the view
            // get an optimistic response to update the cache
            updateViewPage( {
                variables: { input: input  } ,
                optimisticResponse: {
                    updateViewPage: {
                        __typename: 'Mutation',
                        viewPage: {
                            __typename: 'ViewPageType',
                            ...optimisticResponse
                        }
                    }
                }
            } );

        }
    }


    function toggleSidebarVisible() {
      processExplorerSidebarVisible(!clientData.processExplorerSidebarVisible)
    }

    return(
        <div>
            <Space direction="vertical">
                
                <Space>
                    <Switch onChange={toggleSidebarVisible} checked={ clientData.processExplorerSidebarVisible } data-testid="sidebar-checkbox"/>
                    <div>Sidebar</div>
                </Space>
                
                <Space>
                    <Switch onChange={onChangeLegend} checked={ selectedViewPage.showLegend } data-testid="legend-checkbox"/>
                    <div>Legends</div>
                </Space>

                <Space>
                        <InputNumber size="small" value={ selectedViewPage.numplots } onChange={onChangeNumplots} min={1} max={6} data-testid="number-of-plots"/>
                        <div>N plots</div>
                </Space>
            </Space>

        </div>
        )
}


export default ProcessExplorerPlotOptions;
