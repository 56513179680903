import React from "react";
import { useState } from "react";
import { Card , Statistic, Space, Skeleton} from 'antd';
import PageHeader from "../components/PageHeader";
import { useQuery, gql } from '@apollo/client';
import HeaderExtra from "../components/HeaderExtra";
import { LineChartOutlined , BulbOutlined , TeamOutlined, UserOutlined} from '@ant-design/icons';
import OrganizationUsers from "../components/organization/OrganizationUsers";
import OrganizationLogs from "../components/organization/OrganizationLogs";
import useWindowDimensions from "../components/useWindowDimensions";
import DataStandardTable from "../components/topics/DataStandardTable";
import CeleryTable from "../components/admin/CeleryTable";

const GET_USER_PROFILE = gql`
    query userProfile {  
        userProfile {
            id
            admin
            organization {
              name
            }
        }
       
        adminStats {
            totalUsers
            totalProcesses
            totalTopics
            totalTeams
        }
    }
`;

function Admin() {

  const [value, setValue] = useState(1);
  const [activeTabKey, setActiveTabKey] = useState<string>('users');
  //const theme = useReactiveVar([]);

  const { loading:queryLoading, error:queryError, data:queryData } = useQuery(GET_USER_PROFILE, {
      fetchPolicy: "cache-and-network"
  });

  function onChange (e:any) {
      setValue(e.target.value);
  };

  //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
  //ReactGA.pageview('/import/');

  // window dimensions
  const { height, width } = useWindowDimensions();

  if (queryLoading && !queryData) {
      return (<Skeleton active/>)
  }

  if (queryError) {
      return (<p>Error : unable to get the user profile</p>)
  }

  let tabList:any = [
    {
      key: 'stats',
      tab: 'Stats',
    },
    {
      key: 'users',
      tab: 'Users',

    },
    {
      key: 'lexicon',
      tab: 'Lexicon',
    },
    {
      key: 'logs',
      tab: 'Logs',
    },
    {
      key: 'backgroundTasks',
      tab: 'Running background tasks',
    }
   ];

  let contentList: Record<string, React.ReactNode> = {
    stats: (

      <Space direction="horizontal">
        <Card style={{width:200,float:"left", marginRight:10}}>
          <Statistic
                title="Active users"
                value={queryData.adminStats.totalUsers}
                precision={0}
                prefix={<UserOutlined />}
                suffix=""
          />
        </Card>
        
        <Card style={{width:200,float:"left", marginRight:10}}>
          <Statistic
                title="Teams"
                value={queryData.adminStats.totalTeams}
                precision={0}
                prefix={<TeamOutlined />}
                suffix=""
          />
        </Card>

        <Card style={{width:200,float:"left", marginRight:10}}>
          <Statistic
                title="Collections"
                value={queryData.adminStats.totalTopics}
                precision={0}
                prefix={<BulbOutlined />}
                suffix=""
          />
        </Card>

        <Card style={{width:200,float:"left", marginRight:10}}>
          <Statistic
                title="Processes"
                value={queryData.adminStats.totalProcesses}  
                precision={0}
                prefix={<LineChartOutlined />}
                suffix=""
          />
        </Card>

      </Space>

    ),
    users: (
        
        <div style={{height:height - 258 , overflow:"auto" }} >
          <OrganizationUsers />
        </div>
        
    ),

    logs: (
        <div style={{height:height - 258 , overflow:"auto"}}>
          <OrganizationLogs />
        </div>
    ),
    lexicon: (
        <div style={{height:height - 200 , overflow:"auto"}}>
          <DataStandardTable organization={true} teamId="" topicId=""/>
        </div>
    ),
    backgroundTasks: (
        <div style={{height:height - 475 , overflow:"auto"}}>
          <CeleryTable />
        </div>
    )
  };

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return(  

    <div>

      <PageHeader
        //onBack={() => window.history.back()}
        title={ "Admin Center: " + queryData.userProfile.organization.name}
        extra={<HeaderExtra/>}
      />

      { queryData.userProfile.admin &&

          <Card
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={key => {
                onTabChange(key);
            }}
            style={{ height: height - 130 }}
            
          >
            {contentList[activeTabKey]}

          </Card>

      } 
      { !queryData.userProfile.admin &&
        <Card style={{minHeight:height-130}}>
          <p>You are not an administrator of this organization.</p>
        </Card>
      }

    </div>
  )
}
  
export default Admin;