import { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import VariableModal from '../variable/VariableModal';
import { Table, Spin, Button} from 'antd';

const READ_DATA_STANDARD = gql`
    query dataStandard($id: ID!) {
        dataStandard(id: $id) {
            id
            name
            type
            description
            variableSet {
                id
                name
                units
                process {
                    id
                    name
                    topic {
                        id
                        name
                        team {
                            id
                            name
                        }
                    }
                }
            }
            parameterSet {
                id
                name
                value
                units
                process {
                    id
                    name
                }
            }
            metadataSet {
                id
                name
                value
                process {
                    id
                    name
                }
            }
        }
    }
`;



interface DataStandardAssociatedInputType {
    dataStandardId: any
};

function DataStandardAssociated(props:DataStandardAssociatedInputType) {


    // ----------------------------------------------------------------------------------------
    // states

    const [variableModalVisible, setVariableModalVisible] = useState(false);
    const [variableModalId, setVariableModalId] = useState("")

    
    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ_DATA_STANDARD , {variables:{id:props.dataStandardId}});

    if (queryLoading) return <Spin />;
    if (queryError) return <p>Error : {queryError.message}</p>;

    function showVariableModal(record:any) {
        setVariableModalId(record.id)
        setVariableModalVisible(true);
    }

    let N_processes = 0;
    let columns:any = [];
    let tableDataSource:any = [];

    if (queryData.dataStandard.type === "variable") {
        tableDataSource = queryData.dataStandard.variableSet;
        N_processes = queryData.dataStandard.variableSet.length;

        columns = [
            {
                title: 'Processes (' + N_processes + ')',
                dataIndex: ['process', 'name'],
            },
            {
                title: 'Collection',
                dataIndex: ['process', 'topic', 'name'],
            },
            {
                title: 'Team',
                dataIndex: ['process', 'topic', 'team', 'name'],
            },
            {
                title: 'Variable',
                dataIndex: 'name',
                render: (text:any, record:any) => (
                    <div>
                        <Button type="link" onClick={() => showVariableModal(record)} data-testid={"variable-modal-button-" + record.id}>{text}</Button>
                    </div>
                ),
            },
            {
                title: 'Units',
                dataIndex: 'units',
            },
        ];
    } else if (queryData.dataStandard.type === "parameter") {
        tableDataSource = queryData.dataStandard.parameterSet;
        N_processes = queryData.dataStandard.parameterSet.length;
        columns = [
            {
                title: 'Processes (' + N_processes + ')',
                dataIndex: ['process', 'name'],
            },
            {
                title: 'Parameter',
                dataIndex: 'name',
            },
            {
                title: 'Value',
                dataIndex: 'value',
            },
            {
                title: 'Units',
                dataIndex: 'units',
            },
        ];
    } else if (queryData.dataStandard.type === "metadata") {
        tableDataSource = queryData.dataStandard.metadataSet;
        N_processes = queryData.dataStandard.metadataSet.length;
        columns = [
            {
                title: 'Processes (' + N_processes + ')',
                dataIndex: ['process', 'name'],
            },
            {
                title: 'Metadata',
                dataIndex: 'name',
            },
            {
                title: 'Value',
                dataIndex: 'value',
            },
        ];
    }

    return (

            <div>
                    <div style={{height:500, overflow:"auto"}}>
                    
                        <Table 
                            loading={queryLoading}
                            dataSource={props.dataStandardId ? tableDataSource : []}
                            columns = { columns } 
                            bordered
                            rowKey={record => record.id} 
                            size="small"
                            pagination={false}
                        />
                    </div>
                    { queryData.dataStandard.type === "variable" && 
        
                    <VariableModal variableId={variableModalId} isVariableModalVisible={variableModalVisible} setIsVariableModalVisible={setVariableModalVisible} />
                    }
            </div>

        );
    }


export default DataStandardAssociated;

