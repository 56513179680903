import React from 'react';
import { Button , message, Radio, Space, Select} from 'antd';
import { useMutation, gql } from '@apollo/client';
import { CirclePicker } from 'react-color';
import { CORE_VIEW_FIELDS } from '../fragments';

const UPDATE_PROCESS_COLOR = gql`
    ${CORE_VIEW_FIELDS}
    mutation updateProcessColor($input: UpdateProcessColorInputType!) {
        updateProcessColor( input:$input) {
            processExplorerView {
                ...CoreViewFields
            }
        
        }
    }
`;

const UPDATE_PROCESS_EXPLORER_VIEW = gql`
    ${CORE_VIEW_FIELDS}
    mutation updateProcessExplorerView($input: UpdateProcessExplorerViewInputType!) {
        updateProcessExplorerView( input:$input) {
            processExplorerView {
                ...CoreViewFields
            }
        }
    }
`;

interface ProcessColorProps {
    process: any
    view: any
    analysis: any
    processes: any
}

function ProcessColor(props: ProcessColorProps) {

    const [colorChangeScope, setColorChangeScope] = React.useState("1");
    const [selectedMetaData, setSelectedMetaData] = React.useState(""); 
    const [selectedMetaDataValue, setSelectedMetaDataValue] = React.useState("");
    const [groupByMeta, setGroupByMeta] = React.useState("0");
 
    const [updateColor] = useMutation(UPDATE_PROCESS_COLOR, 
        {
            onCompleted: (data) => {
                message.success("Colors updated");
            } ,
            onError: (error) => {
                message.error(error.message);
            }
        }
    );

    const [updateProcessExplorerView] = useMutation(UPDATE_PROCESS_EXPLORER_VIEW,
        {
            onCompleted: (data) => {
                message.success("Metadata grouping updated");
            } ,
            onError: (error) => {
                message.error(error.message);
            }
        }
    );

    // use Effect to set the colorChangeScope to 3 if the view has groupByMetaData set to true
    React.useEffect(() => {
        if (props.view.groupByMetaData) {
            setColorChangeScope("3")

            // set the selected metadata to the one in the view
            setSelectedMetaData(props.view.metaDataForGrouping)

        } else {
            setColorChangeScope("1")
        }
    }
    , [props.view.groupByMetaData])


    let processes = props.processes;

    // loop through all processes and all metadata of each processes and create a list of unique metadata names
    const metadataNames:any = [];
    for (let i = 0; i < processes.length; i++) {
        for (let j = 0; j < processes[i].metadataSet.length; j++) {
            if (!metadataNames.includes(processes[i].metadataSet[j].name)) {
                metadataNames.push(processes[i].metadataSet[j].name);
            }
        }
    }

    // add an empty to the beginning of the list
    metadataNames.unshift("");

    const metaDataOptions = metadataNames.map((item:any) => <Select.Option key={item} value={item}>{item}</Select.Option>);

    // loop through processes then loop through metadata of each process and create a list of unique metadata values of the selected metadata
    let uniqueMetadataValues: any = []
    for (let i = 0; i < processes.length; i++) {
        for (let j = 0; j < processes[i].metadataSet.length; j++) {
            const metadataName = processes[i].metadataSet[j].name;
            const metadataValue = processes[i].metadataSet[j].value;
            if (metadataName === selectedMetaData) {
                if (!uniqueMetadataValues.includes(metadataValue)) {
                    uniqueMetadataValues.push(metadataValue);
                }
            }
        }
    }

    const metaDataValuesOptions = uniqueMetadataValues.map((item:any) => <Select.Option key={item} value={item}>{item}</Select.Option>);

    function onColorChange(color:any) {
        // perform the mutation
        let input = {}

        if (colorChangeScope == "2") {

            input = {
                color: color.hex,
                processId: "all",
                viewId: props.view.id
            }
        } else if  (colorChangeScope == "1") {
            input = {
                color: color.hex,
                processId: props.process.id,
                viewId: props.view.id
            }
        /*} else if (colorChangeScope == "3") {
            input = {
                color: color.hex,
                processId: "",
                viewId: props.view.id,
                metaDataName: selectedMetaData,
                metaDataValue: selectedMetaDataValue
            }
        */
        }

        updateColor({variables: {input: input}})
    }

    function onCheckBoxChange(e:any) {
        setColorChangeScope(e.target.value)
    }

    function restoreMetaGrouping() {

        let input2 = {
            id: props.view.id,
            groupByMetaData: false,
            metaDataForGrouping: ""
        }
        updateProcessExplorerView({variables: {input: input2}})

    }

    function restoreDefaultColors() {

        let input = {
            color:"",
            processId: "all",
            viewId: props.view.id
        }

        updateColor({variables: {input: input}})
    }

    function onMetaDataChange(e:string) {

        // if the selected metadata is empty, do nothing
        if (e == "") {
            return
        }

        //setSelectedMetaData(e)
        let input = {
            id: props.view.id,
            groupByMetaData: true,
            metaDataForGrouping: e
        }
        updateProcessExplorerView({variables: {input: input}})
    }

    function onMetaDataValueChange(e:string) {
        setSelectedMetaDataValue(e)
    }

    function onChangeGroupByMeta(e:any) {
        setGroupByMeta(e.target.value)
    }
 
    return ( 
        <div>

        {/*
        <Radio.Group value={groupByMeta} onChange={onChangeGroupByMeta}>
            <Radio value="0">Do not group processes</Radio>
            <Radio value="1">Group processes by metadata</Radio>
        </Radio.Group>

        { groupByMeta == "1" &&
            <div>
                <Select style={{ width: 140 , marginTop:20}}  onChange={onMetaDataChange} >
                    {metaDataOptions}
                </Select>
            </div>
        }
        */}


        { groupByMeta == "0" &&
            <div>
                
                {/* Show a checkbox to select between all processes in the view or the one selected one */}
                <Space direction= "vertical">

                    <Radio.Group value={colorChangeScope} onChange={onCheckBoxChange}>

                        <Radio 
                            value="1" 
                            disabled={props.view?.groupByMetaData}
                            title ={ props.view?.groupByMetaData ? "Restore default colors to enable this option" : "" }
                        > 
                            Change color for the selected process
                        </Radio>

                        <Radio 
                            value="2" 
                            disabled={props.view?.groupByMetaData}
                            title ={ props.view?.groupByMetaData ? "Restore default colors to enable this option" : "" }
                        >   
                            Change color for all processes in the view
                        </Radio>
                        <Radio 
                            value="3"
                        >
                            Group processes by metadata
                        </Radio>

                    </Radio.Group>

                    {colorChangeScope == "3" &&

                        <Space direction="horizontal">
                            <Select style={{ width: "260px" , marginTop:10}}  onChange={onMetaDataChange} value={props.view.metaDataForGrouping} data-testid="select-meta-data">
                                {metaDataOptions}
                            </Select>
                            
                            {/*
                            <Select style={{ width: 140 }} onChange={onMetaDataValueChange}>
                                {metaDataValuesOptions}
                            </Select>
                            */}

                        </Space>
                    }

                </Space>
                
                
                { (colorChangeScope == "1" || colorChangeScope == "2") &&
                <div style={{marginTop:30}}>
                    <CirclePicker 
                                width='280' 
                                circleSize={ 35 }
                                colors = {[
                                            '#4D4D4D', '#999999', '#F5F5F5', '#F44E3B', '#FE9200', '#FCDC00', 
                                            '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', 
                                            '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', 
                                            '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', 
                                            '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', 
                                            '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E', 
                                            '#0072BD', '#D95319', '#EDB120', '#7E2F8E', '#77AC30', '#4DBEEE', 
                                            '#A2142F', '#00FF00', '#FF0000', '#0000FF', '#FFFF00', '#00FFFF',
                                            '#21A0D2', '#004A96', '#65AC1E', '#00793A', '#F39500', '#C50022', 
                                            
                                        ]} 
                                onChangeComplete= {onColorChange}
                                    
                    />
                    
                </div>
                }

                { (colorChangeScope == "1" || colorChangeScope == "2") &&
                    <Button type="link" style={{marginTop:30}} onClick={restoreDefaultColors} data-testid="restore-default-colors-button">Restore default colors</Button>
                }

                { colorChangeScope == "3" &&
                    <Button type="link" style={{marginTop:30}} onClick={restoreMetaGrouping} data-testid="restore-meta-grouping-button">Restore metadata grouping</Button>
                }
            

            </div>
        }
        </div>
    )

}

export default ProcessColor;