import {useEffect} from 'react';
import { useReactiveVar } from '@apollo/client';
import { Card, Tabs , Select, Skeleton, Empty, Collapse, Button} from 'antd';
import useWindowDimensions from '../useWindowDimensions';
import ProcessColor from './ProcessColor';
import ProcessDescription from './ProcessDescription';
import ParameterTable from '../ParameterTable';
import MetaDataTable from '../MetaDataTable';
import NoteTable from '../NoteTable';
import ProcessExplorerSidebarCalculatorsProps from './ProcessExplorerSidebarCalculators';

import { selectedSidebarTabVar, infoSidebarOpenVar } from "../../Apollo";
import { info } from 'console';

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

interface ProcessExplorerSidebarInputType {
    processes:any
    selectedSidebarProcess:String
    setSelectedSidebarProcess:Function
    view : any
    loading: boolean
    analysis: any
}

function ProcessExplorerSidebar(props:ProcessExplorerSidebarInputType) {

    const selectedSidebarTab = useReactiveVar(selectedSidebarTabVar);
    const infoSidebarOpen = useReactiveVar(infoSidebarOpenVar);
    
    const { height, width } = useWindowDimensions();


    
    // useEffect to set the selected process to the first in the list if there is no selected process
    // only set it the first time
    
    useEffect(() => {
        if (props?.processes?.length  > 0) {
            props.setSelectedSidebarProcess(props.processes[0]?.id) // set the selected process to the first in the list
        } else {
            props.setSelectedSidebarProcess("")
        }
    }
    , [])

    
    // useEffect to set the selected process to the first of the processesForDisplay selection
    useEffect(() => {
        if (props?.view?.processesForDisplay?.length > 0) {
            props.setSelectedSidebarProcess(props.view.processesForDisplay[0]?.id) // set the selected process to the first in the list
        } else {
            props.setSelectedSidebarProcess("")
        }
    }
    , [props?.view?.processesForDisplay])
    


    function OnChangeProcessSelect(value:any) {
        props.setSelectedSidebarProcess(value)
    }

    if (props.loading) {
        return(
        <Card style={{ minHeight: height - 130 , marginLeft:10}} data-testid="sidebar-loading">
            <Skeleton paragraph={{ rows: 22 }} active />
        </Card>
        )
    }

    let process = props?.processes?.find((process: any) => process.id == props?.selectedSidebarProcess)
   
    // if process is undefined
    if (process == undefined) {
        return(
        <Card style={{ minHeight: height - 130 , marginLeft:10}}>
            
            <Empty 
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No process Selected" 
            />
            
        </Card>
        )
    }
    
    return (
         
        <Card size="small" style={{ height: height - 130 , marginLeft:10 , width:350 , overflowX:"auto" }} data-testid="sidebar-content">
        

        
        <Select
            //mode="multiple"
            //allowClear
            style={{ width: '100%', marginBottom:10 }}
            placeholder="Process"
            onChange={ OnChangeProcessSelect }
            value = {props.selectedSidebarProcess}
            data-testid = "sidebar-process-select"
        >
             {props.processes.map( (process:any) => { return <Option key={process.id} value={process.id} data-testid="sidebar-process-select-item"> {process.name} </Option> })} 

        </Select>

            <Tabs 
                defaultActiveKey={selectedSidebarTab} 
                onChange={(key:string) => selectedSidebarTabVar(key)}
                //style={{ height: height - 200 , overflowX:"auto" }}
                data-testid="sidebar-tabs"
            >
                <TabPane tab="Metadata" key="1" data-testid="metadata-tab">
                    <div style={{ height: height - 290 , overflowX:"auto" }}>
                        <MetaDataTable processId={process?.id} maxValueDisplayWidth={"100px"} tableColumns = {["Name","Value", "Actions"]} tableHeightOffset={400}/>
                    </div>
                </TabPane>

                <TabPane tab="Parameters" key="2" data-testid="parameters-tab">
                    <div style={{height: height-290 ,overflowX:"auto"}}>
                        <ParameterTable processId={process?.id} showDescription={false} tableHeightOffset={400}/>
                    </div>
                </TabPane>
                <TabPane tab="Info" key="3" data-testid="info-tab">
                    <div style={{ height: height - 290 , overflowX:"auto" }}>
                        <Collapse 
                            accordion
                            defaultActiveKey={infoSidebarOpen} 
                            onChange={(key:any) => {
                                infoSidebarOpenVar(key)
                            }}
                            size="small"
                        >
                            <Panel header="Process information" key="1" data-testid="process-info-panel">
                                <ProcessDescription processId={process.id} />
                            </Panel>

                            <Panel header="Calculators" key="2" data-testid="process-calculators-panel">
                                <ProcessExplorerSidebarCalculatorsProps process={process}/>
                            </Panel>

                            <Panel header="Process notes" key="3" data-testid="process-notes-panel">
                                <NoteTable processId={process?.id} tableHeightOffset={500}/>
                            </Panel>
                        </Collapse>
                        
                        
                    </div>
                </TabPane>
                <TabPane tab="Color" key="4" data-testid="color-tab">
                    <div style={{ height: height - 290  }}>
                        <ProcessColor process = {process} view = { props.view } analysis = {props.analysis} processes={props.processes}/>
                    </div>
                </TabPane>
            

            </Tabs>
            

            {/*
            <Collapse accordion defaultActiveKey={["1"]} size='small' >
                
                <Panel header="Process information" key="1" data-testid="process-info-panel">
                    <ProcessDescription processId={process.id} />
                </Panel>

                <Panel header="Process notes" key="2" data-testid="process-notes-panel">
                    <NoteTable processId={process?.id} tableHeightOffset={550}/>
                </Panel>

                <Panel header="Metadata" key="3" data-testid="metadata-panel">
                    <MetaDataTable processId={process?.id} maxValueDisplayWidth={"100px"} tableColumns = {["Name","Value", "Actions"]} tableHeightOffset={550}/>
                </Panel>

                <Panel header="Parameters" key="4" data-testid="parameters-panel">
                    <ParameterTable processId={process?.id} showDescription={false} tableHeightOffset={550}/>
                </Panel>

                <Panel header="Color" key="5" data-testid="color-panel">
                    <ProcessColor process = {process} view = { props.view } analysis = {props.analysis}/>
                </Panel>

                 
            </Collapse>
            */}
        </Card>
    )   
}

export default ProcessExplorerSidebar;