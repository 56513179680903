import React, {useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Space, Spin, Select, Card } from 'antd';
import {globalSelectedTopic } from '../../Apollo';
import { pollInterval } from '../..';
import {CORE_CALCULATOR_SET_FIELDS, CORE_CALCULATOR_SAVE_FIELDS} from '../fragments';
const { Option } = Select;

const READ_TOPICS = gql`
    ${CORE_CALCULATOR_SET_FIELDS}
    ${CORE_CALCULATOR_SAVE_FIELDS}
      query Topics ($teamId:ID!) {
        topics (teamId:$teamId) {
            id
            name
            description
            calculatorsetSet {
                ...CoreCalculatorSetFields
                calculatorsaveSet {
                    ...CoreCalculatorSaveFields
                }
            }
        }
      }
`;

const GET_CLIENT_VARS = gql`
    query ClientVars{
        globalSelectedTopic @client
    }
`;

interface CalculatorsTopicInputType {
    teamId: string
}

function CalculatorsTopic(props: CalculatorsTopicInputType) {

    const { data:clientVars } = useQuery(GET_CLIENT_VARS);
    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(READ_TOPICS, { 
        variables: { teamId: props.teamId },
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    function onTopicChange(input: any) {
        globalSelectedTopic(input)
    }

    // set the selected topic to the first topic in the list
    useEffect(() => {
        if (queryData && queryData.topics.length > 0 && clientVars.globalSelectedTopic == "" ) {
            globalSelectedTopic(queryData.topics[0].id)
        }
    }, [queryData]);

    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );

    if (queryLoading && !queryData) return (
        <Space direction="vertical" size="small" style={{ width: '100%' }} >
            <Select
                style={{ width: 300 }} 
                loading
            >
            </Select>
        </Space>
    );

    return (
        <div style={{ width: '100%' }} >

            <Space direction="vertical" size="small" style={{ width: '100%' }} >

                <Select 
                    style={{ width: 300 }}
                    onChange={onTopicChange}
                    value={clientVars.globalSelectedTopic}
                    placeholder="Please select"
                    showSearch
                    optionFilterProp='title'
                    data-testid='topic-select'
                >
                    {queryData.topics.map((topic: any) => { return (<Option value={topic.id} key={topic.id} title={topic.name}>{topic.name}</Option>) })}

                </Select>

            </Space>
        </div>
    );

}

export default CalculatorsTopic;