import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Form, Modal, Input, message, Popover, Radio, Select, Tooltip, Typography, Alert, Skeleton} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import {SearchOutlined} from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import useWindowDimensions from './useWindowDimensions';
import { CORE_METADATA_FIELDS, CORE_PROCESS_FIELDS } from './fragments';

const { TextArea } = Input;

const CREATE_METADATA = gql`
    ${CORE_METADATA_FIELDS}
    ${CORE_PROCESS_FIELDS}
    mutation createMetaData($input: CreateMetaDataInputType!) {
        createMetaData( input:$input) {
            process {
                ...CoreProcessFields
                metadataSet {
                    ...CoreMetadataFields
                }
            }
        }
    }
`;

const UPDATE_METADATA = gql`
    ${CORE_METADATA_FIELDS}
    ${CORE_PROCESS_FIELDS}
    mutation updateMetaData($input: UpdateMetaDataInputType!) {
        updateMetaData( input:$input) {
            process {
                ...CoreProcessFields
                metadataSet {
                    ...CoreMetadataFields
                }
            }
        }
    }
`;

const DELETE_METADATA = gql`
    mutation deleteMetaData($id: ID!) {
        deleteMetaData( id:$id) {
            id
            ok
        }
    }
`;

const GET_PROCESS_METADATA = gql`
    ${CORE_METADATA_FIELDS}
    ${CORE_PROCESS_FIELDS}
    query process ($id:ID!) {  
        process(id:$id) {
            ...CoreProcessFields
            metadataSet {
                ...CoreMetadataFields
            }
        }
        
    }
`;

const CREATE = CREATE_METADATA;
const READ = GET_PROCESS_METADATA;
const UPDATE = UPDATE_METADATA;
const DELETE = DELETE_METADATA;

const objectName = "Metadata";

interface MetaDataTableInputType {
    processId:number | string,
    tableColumns?:any,
    maxValueDisplayWidth?:string,
    tableHeightOffset?:number
};

function MetaDataTable(props:MetaDataTableInputType) {

    const { height, width } = useWindowDimensions();

    // set a default value for maxValueDisplayLength
    let maxValueDisplayWidth = "100%";
    if (props.maxValueDisplayWidth !== undefined) {
        maxValueDisplayWidth = props.maxValueDisplayWidth;
    }

    // set a default value for tableHeightOffset
    let tableHeightOffset = 370;
    if (props.tableHeightOffset !== undefined) {
        tableHeightOffset = props.tableHeightOffset;
    }

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"", value:"",type:"string", mediaId:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState(0);
    const [metaType, setMetaType] = useState("string");
    const [nameSearchText, setNameSearchText] = useState("");
    const [valueSearchText, setValueSearchText] = useState("");
    const [descriptionSearchText, setDescriptionSearchText] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {
        variables:{id:props.processId},
        //pollInterval: 3000,
        fetchPolicy: 'cache-and-network'
    });

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [{ query: READ, variables: { id: props.processId } }],
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError } ] = useMutation(UPDATE, {
        //refetchQueries: [{ query: READ, variables: { id: props.processId } }],
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation, { loading: deleteMutationLoading, error: deleteMutationError } ] = useMutation(DELETE, {
        refetchQueries: [{ query: READ, variables: { id: props.processId } }],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add")
        setModalInitialValues({ name:"", description:"", value:"", type:"string", mediaId:""} )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
        let input:any = {}
        // adding
        if (modalTitel == "Add") {

            if (metaType == "string") {
                 input = {
                    name : values['name'], 
                    description : values['description'],
                    value : values['value'],
                    
                    processId:props.processId,
                };
            } else if (metaType == "media") {

                input = {
                    name : values['name'], 
                    description : values['description'],
                    mediaId : values['mediaId'],
                    processId:props.processId,
                };
            }
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
            if (metaType == "string") {

                input = {
                    id: modalId,
                    name : values['name'], 
                    description : values['description'],
                    value : values['value'],
                    processId:props.processId
                };
            
            } else if (metaType == "media") {
                
                input = {
                    id: modalId,
                    name : values['name'],
                    description : values['description'],
                    mediaId : values['mediaId'],
                    processId:props.processId, 
                    value: null
                };
            }
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit")
        setMetaType(input.type)
        
        setModalInitialValues({ 
            name:input.name,
            description:input.description,
            value:input.value ,
            type: input.type,
            mediaId: input.valueMedia ? input.valueMedia.id : ""
        })
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
        setModalVisible(false);
    } 

    const handleMetaTypeChange = (e:any) => {
        setMetaType(e.target.value);
    }

    const handleSearch = (selectedKeys:any, confirm:any) => {
        confirm();
        setNameSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        setNameSearchText('');
    };

    const handleSearchValue = (selectedKeys:any, confirm:any) => {
        confirm();
        setValueSearchText(selectedKeys[0]);
    };

    const handleResetValue = (clearFilters:any) => {
        clearFilters();
        setValueSearchText('');
    };

    const handleSearchDescription = (selectedKeys:any, confirm:any) => {
        confirm();
        setDescriptionSearchText(selectedKeys[0]);
    };

    const handleResetDescription = (clearFilters:any) => {
        clearFilters();
        setDescriptionSearchText('');
    };

    const formLayout = {
        labelCol: { span: 6 },
    };

    let columns = [
        {
            title: 'Name',
            //dataIndex: 'name',
            editable: true,
            //width: '20%',
            render: (text:any, record:any) => (
                <div>{record.name}</div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="metadata-name-input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="metadata-name-search-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleReset(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="metadata-name-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
          },
        {
            title: 'Value',
            //dataIndex: 'value',
            editable: false,
            //width: '40%',
            render: (text:String, record:any) => {
                // if the string is too long, show only the first 50 characters
                return (
                    <Typography.Text ellipsis={true} style={{width:maxValueDisplayWidth}}>
                        {record.value }
                    </Typography.Text>
                )
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search value"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchValue(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="metadata-value-input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchValue(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="metadata-value-search-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleResetValue(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="metadata-value-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                if (record.value == null) {
                    return false;
                }
                return record.value.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            //width: '30%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search description"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchDescription(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="metadata-description-input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchDescription(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="metadata-description-search-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleResetDescription(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="metadata-description-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                if (record.description == null) {
                    return false
                }
                return record.description.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Type',
            width: '10%',
            //dataIndex: 'type',
            editable: false,
            render: (text:String, record:any) => {
                if (record.type == "media") {
                    return (
                        <Tooltip title="Media">
                            Media
                        </Tooltip>
                    )
                } else if (record.type == "string") {
                    return (
                        <Tooltip title="String">
                            String
                        </Tooltip>
                    )
                }
            },
            filters: [
                {
                    text: 'Media',
                    value: 'media',
                },
                {
                    text: 'String',
                    value: 'string',
                },
            ],
            onFilter: (value: any, record: any) =>  record.type == value,
        },
        {
            title: 'Lexicon',
            name: 'Lexicon',
            key: 'lexicon',
            width: "5%",
            render : (text:any, record:any) => {
                if (record.dataStandard == null) {
                    return null
                } else {

                    let level = record.dataStandard.level
                    if (record.dataStandard.level == "Topic") {
                        level = "Collection"
                    }

                    return (
                        <Popover content={(
                            <Space direction="vertical">
                                <p>{record.dataStandard.name} ({level})</p>
                            </Space>
                        )}>
                            <Button size="small" type="link" style={{paddingLeft:20 }} data-testid={"metadata-lexicon-button-" + record.name} >
                                <LinkOutlined />
                            </Button>
                        </Popover>
                    )
                }
            },
            filters: [
                {
                    text: 'Lexicon',
                    value: 'Lexicon',
                },
            ],
            onFilter: (value: any, record: any) =>  {
                if (record.dataStandard == null) {
                    return false
                } else {
                    return true
                }
            }
        },
        /* 
        {
            title: 'Actions',
            key: 'actions',
            width: '10%',

            render: (text:String, record:any) => (

                    
                    <Popover content={(
                        <Space direction="vertical">
                        <Button type="link" onClick={()=> handleEdit(record)} data-testid="edit-metadata">
                            Edit
                        </Button>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                            <Button type="link" data-testid="delete-metadata">Delete</Button>
                        </Popconfirm>
                    </Space>
                    )}>
                    <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"metadata-actions-button-" + record.name}>
                        <EllipsisOutlined />
                    </Button>
                </Popover>
                

                
            ),
        },
        */
    ]

    // if props.tableColumns is set, use it to filter the columns
    if (props.tableColumns) {
        columns = columns.filter((column:any) => props.tableColumns.includes(column.title))
    }


    /*if (queryLoading){
        return (
            <Skeleton active />
        )
    }*/

    if (queryError) {
        (
            <Alert
                message="Error"
                description={queryError.message}
                type="error"
                showIcon
            />  
        )
    }

    return (

        <div>
                <Button
                    type="primary"
                    style = {{ marginBottom: 16 }}
                    onClick = {  showModal  } 
                    data-testid = "add-metadata-button"
                    disabled = { queryData?.process?.topic?.userPermissions == "read"}
                    title = { queryData?.process?.topic?.userPermissions == "read" ? "You do not have permission to create a metadata." : "Create a metadata"}                          
                    shape="round"
                    icon={<PlusOutlined />}
                >
                    Create a metadata
                </Button>
                
                <div style={{marginBottom:20 , marginRight:10}}>
                    <Table 
                        loading={queryLoading && !queryData}
                        dataSource={queryData?.process?.metadataSet}
                        columns = { columns } 
                        bordered
                        rowKey={record => record.id} 
                        size="small"
                        pagination={false}
                        scroll={{ y: height - tableHeightOffset }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {handleEdit(record)}, // click row
                                // change the mouse cursor to pointer when hovering over the row
                                onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                                onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                            };
                        }}
                    />
                </div>

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                data-testid = "metadata-modal"
                footer={[
                    // if the modal is in edit mode, add a delete button to the footer
                    modalTitel == "Edit" ?
                        <Popconfirm title="Are you sure you want to delete this metadata?" onConfirm={() => handleDelete(modalId)} disabled = { queryData?.process?.topic?.userPermissions == "read"}>
                            <Button 
                                type="primary" 
                                ghost 
                                data-testid="delete-metadata-button" 
                                disabled = { queryData?.process?.topic?.userPermissions == "read"}
                                title = { queryData?.process?.topic?.userPermissions == "read" ? "You do not have permission to delete metadata." : "Delete metadata"}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                        : null
                    ,
                    <Button 
                        type="primary" 
                        form="MetaDataForm" 
                        key="submit" 
                        htmlType="submit" 
                        data-testid="submit-metadata-button"
                        disabled = { queryData?.process?.topic?.userPermissions == "read"}
                        title = { queryData?.process?.topic?.userPermissions == "read" ? "You do not have permission to edit metadata." : "Edit metadata"}
                    >
                        Submit
                    </Button>
                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "MetaDataForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <Form.Item 
                            label="Name:" 
                            name="name" 
                            rules={[
                                { required: true, message: 'Please enter a name.' }, 
                                { max: 200, message: 'Name must be 200 characters or less.' },
                                { pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/, message: 'Name must start with a letter or underscore and can only contain letters, numbers, and underscores.' }
                            ]} 
                            validateFirst={true}
                            validateTrigger={"onBlur"}
                        >
                        <Input 
                            maxLength={200}
                            //showCount
                            data-testid = "metadata-name-input"
                        />

                    </Form.Item>

                    <Form.Item label="Description:" name="description" rules={[{ required: false }]}>
                        <TextArea 
                            maxLength={1000} 
                            showCount 
                            rows={2}
                            data-testid = "metadata-description-input"
                        />
                    </Form.Item>

                    {/* Radio to select meta type: string or media */}
                    <Form.Item label="Type:" name="type" rules={[{ required: true, message: 'Please select a type.' }]} >
                        <Radio.Group onChange={handleMetaTypeChange}>
                            <Radio value="string">String</Radio>
                            <Radio value="media">Media</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {/* If meta type is string, show a text area */}
                    { metaType == "string" &&

                        <Form.Item label="Value:" name="value" rules={[{ required: false }]}>
                            <TextArea 
                                maxLength={1000} 
                                showCount 
                                rows={6}
                                data-testid = "metadata-value-input" 
                            />
                        </Form.Item>
                    }

                    {/* If meta type is media, show a select dropdown to select the media */}
                    { metaType == "media" &&
                        <Form.Item label="Media:" name="mediaId" rules={[{ required: false }]}>
                            <Select
                                showSearch
                                placeholder="Select a media"
                                optionFilterProp="children"
                                data-testid = "metadata-media-select"
                            >
                                {queryData?.process?.topic?.mediaSet.map((media:any) => (
                                    <Select.Option value={media.id} key={media.id}>{media.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }

                </Form>

            </Modal>
            <Modal 
                title="Loading..."
                open={createMutationLoading || updateMutationLoading || deleteMutationLoading}
                footer={null}
                closable={false}
            >
                <Skeleton active />
            </Modal>
        </div>

    );
    

}

export default MetaDataTable;

