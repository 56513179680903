import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, Collapse, Steps, Skeleton} from 'antd';
import PageHeader from '../components/PageHeader';
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from "../components/useWindowDimensions";
import ReactGA from 'react-ga';
import DataStandardTable from '../components/topics/DataStandardTable';


const { Panel } = Collapse;
const { Meta } = Card;
const { Step } = Steps;

const GET_USER_PROFILE = gql`
    query userProfile {  
        userProfile {
            id
            avatarB64
            darkMode
            selectedTeam {
                id
                name
                userPermissions
            }
        }  
    }
`;

function Lexicon() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/lexicon/');

    const { height, width } = useWindowDimensions();

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_USER_PROFILE);

    if (queryLoading) return (

        <div>
            <Skeleton active />
        </div>
    )

    if (queryError) return (
        <div>
            <p>Error : unable to get the user profile</p>
        </div>
    )

    const routes = [
      {
        path: '',
        breadcrumbName: "Team: " + queryData.userProfile.selectedTeam.name ,
      }
    ];


    return(  
      <div>

        <PageHeader
          title="Lexicon"
          extra={<HeaderExtra/>}
          breadcrumb={{ routes }}
        />

        <Card style={{height:height-130, overflow:"auto"}}>
          <DataStandardTable organization={false} teamId={queryData.userProfile.selectedTeam.id} editable = {queryData.userProfile.selectedTeam.userPermissions != "read" ? true : false} />
        </Card>


          
      </div>
    )
  }
  
  export default Lexicon;
