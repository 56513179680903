import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card , Space, Popconfirm, Button, Form, Skeleton, Modal, message, AutoComplete, Select} from 'antd';
import moment from 'moment';
const { Option } = Select;

const GET_TOPIC_USERS = gql`
    query topicFiles($topicId:ID!) {  
        topicFiles(topicId:$topicId) {
            id
            name
            description
            lastUpdated
            creationDate
        }
    }
`;

const GET_ORGANIZATION_USERS = gql`
    query {  
        organizationUsers {
            id
            name
            description
        }
    }
`;

const ADD_USER = gql`
    mutation ($input:AddUserToTopicInputType!) {  
        addUserToTopic (input:$input) {
            ok
        }
    }
`;

const REMOVE_USER = gql`
    mutation removeUserFromTopic($input: RemoveUserFromTopicInputType!) {
        removeUserFromTopic( input:$input) {
            ok
        }
    }
`;

const objectName = "Files";

interface TopicFilesInputType {
    topicId:number,
};

function TopicFiles(props:TopicFilesInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    //const [modalInitialValues, setModalInitialValues] = useState();

    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);

    const [modalTitel, setModalTitel] = useState('');
    const [modalId, setModalId] = useState();
    

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading1, error: queryError1, data: queryData1 } = useQuery(GET_TOPIC_USERS , { variables:{topicId:props.topicId} });

    const [addMutation, { loading: addMutationLoading, error: addMutationError } ] = useMutation( ADD_USER, {
        refetchQueries: [ { query: GET_TOPIC_USERS, variables:{id:props.topicId} } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [removeMutation] = useMutation( REMOVE_USER, {
        refetchQueries: [ { query: GET_TOPIC_USERS, variables:{id:props.topicId} }],
        onCompleted(data) { message.success(objectName +  " was removed successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add a file")
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        let input = {
            userName : values['username'], 
            topicId : props.topicId,
        };
            
        addMutation( { variables: { input: input  } } );        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    
    const handleRemove = (id:Number) => {   
        let input = {
            userId : id, 
            topicId : props.topicId,
        };
        
        removeMutation( { variables: { input: input  } } )
    } 


    const formLayout = {
        labelCol: { span: 4 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 12 },
    };


    const TableColumns = [
        {
            title: 'File name',
            dataIndex: 'name',
            editable: false,
            //width: '30%',
        },
        /*{
            title: 'Description',
            dataIndex: 'description',
            editable: false,
            //width: '30%',

        },*/
        {
            title: 'Last modified',
            dataIndex: 'lastUpdated',
            editable: false,
            render : (text:String, record:any) => (
                record.lastUpdated != null ? moment.utc(record.lastUpdated).local().format('DD.MM.YYYY HH:mm:ss') : null
            ),

        },
        /*{
            title: 'Actions',
            key: 'actions',
            width: '10%',
            render: (text:String, record:any) => (
                <div>
                    <Space>
                        <Popconfirm title="Remove?" onConfirm={() => handleRemove(record.id)} >
                            <Button type="link" data-testid={"remove-button-" + record.name} >
                                Remove
                            </Button>
                        </Popconfirm>

                        <Button type="link" data-testid={"reimport-button-" + record.name} >
                            Re-import
                        </Button>
                    </Space>
                </div>
            ),
        },*/
    ]

    if (queryLoading1){
        return (
            <Skeleton active/>
        )
    
    }
    if (queryError1) return ( 
        <Card>Error: {queryError1.message} </Card>
    );


    return (

        <div>
                {/*
                <Button
                    style = {{ marginBottom: 16, marginRight: 5 }}
                    onClick = {  showModal  } 
                    data-testid="upload-file"                           
                >
                    Upload a file
                </Button>
                */}
                
                <Table 
                    loading={queryLoading1}
                    dataSource={queryData1.topicFiles}
                    columns={ TableColumns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    //pagination={false}
                />

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                footer={[
                    <Button type="primary" form="TeamFilesForm" key="submit" htmlType="submit" data-testid="submit-button">
                        Submit
                    </Button>
                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "TeamFilesForm"
                    onFinish={handleModalOk} 
                > 
                   

                    </Form>

            </Modal>
        </div>

    );
}

export default TopicFiles;

