import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import {  Form, Input, Card, Button, Tabs, message} from 'antd';
import { useNavigate } from 'react-router-dom';
import client from '../Apollo'
import logo from '../images/logo.png'

import ReactGA from 'react-ga';


const { TabPane } = Tabs;

const LOGIN = gql`
    mutation ($username: String!, $password: String!) {
        tokenAuth(username:$username, password:$password) {
            token
            payload
        }
    }
`;

function Login() {

    ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/login/');
    
    const [username, setUsername] = useState(sessionStorage.getItem('username'));
    const navigate = useNavigate()
    
    const [login, { loading, error }] = useMutation(LOGIN,
        {
          onCompleted(data) {
            if (data) {
              sessionStorage.setItem('token', 'JWT ' + data.tokenAuth.token as string);
              sessionStorage.setItem('username', data.tokenAuth.payload.username as string);
              setUsername(sessionStorage.getItem('username'));
              ReactGA.event({ category: 'Login', action: 'Success' });
              navigate('/collections');
              //window.location.reload();
              
            }
          },
          onError(error) {
              message.error(error.message)
              ReactGA.event({ category: 'Login', action: 'Failed' });
            },
        });

      

    const formLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 24},
    };
    
    const tailLayout = {
        wrapperCol: { offset: 4, span: 24 },
    };

    const onLogin = (values: any) => {
        let username = values['username'];
        let password = values['password'];
        const out = login({ variables: { username, password } });
        client.resetStore()
    };
     

    return (
        <Form 
            {...formLayout} 
            onFinish={onLogin}
            
        >
            <Form.Item 
                    label="Username:"
                    name="username"
                    rules={[{ required: true, message: 'Please enter your username.' }]} 
            >
                <Input />
            </Form.Item>
            <Form.Item 
                    label="Password:"
                    name="password"
                    rules={[{ required: true, message: 'Please enter your password.' }]} 
            >
                <Input.Password placeholder="" />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">Login</Button>
            </Form.Item>

        </Form>
                            
              
    )

}

export default Login
