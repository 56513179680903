// TODO: add a column that shows the status of the calculator (e.g. success/fail)


import React, {useState} from 'react';
import { useQuery, useMutation, gql, useReactiveVar } from '@apollo/client';
import { Table, Card , Select, Space, Button, Form, Skeleton, Modal, Input, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import CalcSaveInterfaceTable from './CalcSaveInterfaceTable';
import CopyCalculatorSaveModal from './CopyCalculatorSaveModal';
import EditCalculatorInterfaces from './EditCalculatorInterfaces';
import DeleteModal from '../DeleteModal';
import useWindowDimensions from '../useWindowDimensions';

//import { pollInterval } from '../..';
import { CORE_CALCULATOR_SAVE_FIELDS , EXTENDED_PROCESS_FIELDS} from '../fragments';

const {TextArea} = Input
const {Option} = Select;

const CREATE_CALCULATOR_SAVE = gql`
    ${CORE_CALCULATOR_SAVE_FIELDS}
    mutation createCalculatorSave($input: CreateCalculatorSaveInputType!) {
        createCalculatorSave( input:$input) {
            calculatorSave {
                ...CoreCalculatorSaveFields
            }
        }
    }
`;

const UPDATE_CALCULATOR_SAVE = gql`
    ${CORE_CALCULATOR_SAVE_FIELDS}
    mutation updateCalculatorSave($input: UpdateCalculatorSaveInputType!) {
        updateCalculatorSave( input:$input) {
            calculatorSave {
                ...CoreCalculatorSaveFields
            }
        }
    }
`;

const MOVE_UP_CALCULATOR_SAVE = gql`
    ${CORE_CALCULATOR_SAVE_FIELDS}
    mutation moveUpCalculatorSave($id: ID!) {
        moveUpCalculatorSave( id:$id) {
            calculatorSave {
                ...CoreCalculatorSaveFields
            }
        }
    }
`;

const MOVE_DOWN_CALCULATOR_SAVE = gql`
    ${CORE_CALCULATOR_SAVE_FIELDS}
    mutation moveDownCalculatorSave($id: ID!) {
        moveDownCalculatorSave( id:$id) {
            calculatorSave {
                ...CoreCalculatorSaveFields
            }
        }
    }
`;

const DELETE_CALCULATOR_SAVE = gql`
    mutation deleteCalculatorSave($id: ID!) {
        deleteCalculatorSave( id:$id) {
            id
            ok
        }
    }
`;

const GET_CALCULATOR_SET = gql`
    ${CORE_CALCULATOR_SAVE_FIELDS}
    query calculatorSet ($id:ID!) {  
        calculatorSet(id:$id) {
            id
            name
            topic {
                id
                userPermissions
            }
            calculatorsaveSet {
                ...CoreCalculatorSaveFields
            }
        }
    }
`;

const GET_PROCESS_VARPARS = gql`
    ${EXTENDED_PROCESS_FIELDS}
    query process ($id:ID!) {  
        process(id:$id) {
            ...ExtendedProcessFields
        }
    }
`;

const CREATE = CREATE_CALCULATOR_SAVE;
const READ = GET_CALCULATOR_SET;
const UPDATE = UPDATE_CALCULATOR_SAVE;
const DELETE = DELETE_CALCULATOR_SAVE;

const objectName = "Calculator";

interface CalculatorSaveTableInputType {
    topic:any,
    calculatorSetId:String,
    selectedCalculatorSave:String,
    setSelectedCalculatorSave:Function,
};

function CalculatorSaveTable(props:CalculatorSaveTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [isCopyModalVisible, setCopyModalVisible] = useState(false);
    const [isModalAdd, setModalAdd] = useState(true);
    const [isExpressionVisible, setExpressionVisible] = useState(false);
    const [selectedCalcInterface, setSelectedCalcInterface] = useState(-1);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"", calcfunction:"", expression:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalWidth, setModalWidth] = useState("40%");
    const [calcFunction, setCalcFunction] = useState(""); // the calcfunction being edited
    const [newEditVisible, setNewEditVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    
    const {height, width} = useWindowDimensions();

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    /*
    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , 
        {variables:{id:props.calculatorSetId},
        //pollInterval: pollInterval,
        }
    );*/

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ, variables: { id:props.calculatorSetId } } ], 
        onCompleted(data) { 
            
            message.success( "Calculator was added successfully.") 
            //handleEdit(data.createCalculatorSave.calculatorSave)
        } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE, {
        onCompleted(data) { message.success(objectName + " was edited successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    const [moveUpMutation] = useMutation(MOVE_UP_CALCULATOR_SAVE, {
        refetchQueries: [ { query: READ, variables: { id: props.calculatorSetId } } ], 
        onError(error) {message.error(error.message)}, 
    });

    const [moveDownMutation] = useMutation(MOVE_DOWN_CALCULATOR_SAVE, {
        refetchQueries: [ { query: READ, variables: { id: props.calculatorSetId } } ], 
        onError(error) {message.error(error.message)}, 
    });

    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ, variables: { id: props.calculatorSetId }},{query: GET_PROCESS_VARPARS, variables:{id:props.calculatorSetId}}],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    // use effect to set the selectedCalculatorSave to "" the first time component is rendered
    React.useEffect(() => {
        props.setSelectedCalculatorSave("")
    }, [])

    // find the calculator set
    const calculatorSet = props.topic.calculatorsetSet.find( (calcSet:any) => calcSet.id == props.calculatorSetId );

    // ----------------------------------------------------------------------------------------
    
    // returning and empty table for the case that the calculatorSetId prop is not set
    /*if (props.calculatorSetId == "") {
        return(
            <Card>
                <Table
                    dataSource={[]}
                    bordered
                    size='small'
                />
            </Card>
        )
    }*/

    /*
    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );
*/

    /*
    if (!calculatorSet ) return (
        <Card><Skeleton active /></Card>
    );
    */
    

    // ----------------------------------------------------------------------------------------
    const showModal = (values:any) => {
        setModalTitel("Create a new Calculator")
        setModalInitialValues({ name:"", description:"" , calcfunction:"", expression:""} )
        setModalVisible(true)
        setModalWidth("600px")
        setModalAdd(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Create a new Calculator") {
    
            let input = {
                name : values['name'], 
                description : values['description'],
                calcfunction: values['calcfunction'],
                expression : values['expression'],
                calculatorSetId : props.calculatorSetId
            };
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: props.selectedCalculatorSave,
                name : values['name'], 
                description : values['description'],
                expression : values['expression'],
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (calc?:any) => {

        if (calc == null) {
            // find the calculator in the list
            calc = calculatorSet.calculatorsaveSet.find( (calc:any) => calc.id == props.selectedCalculatorSave );
        } 

        console.log(calc)

        if (calc == null) {
            return;
        }
        
        setModalInitialValues({ 
            name:calc.name,
            description:calc.description, 
            calcfunction:calc.calcfunction,
            expression:calc.expression 
        })
        
        setModalAdd(false) // modal is not in add mode

        if ( calc.calcfunction == 'calc_math' ) {
            setExpressionVisible(true)
        } else {
            setExpressionVisible(false)
        }

        setModalWidth("80%")
        setCalcFunction(calc.calcfunction)
        setModalTitel("Edit the Calculator: " + calc.calcfunction )
        if (calc.calcfunction == "calc_math") {
            setModalVisible(true)
        } else {
            setNewEditVisible(true)
        }
    } 
    
    const handleDelete = () => {   

        // find the calculator in the list
        const calc = calculatorSet.calculatorsaveSet.find( (calc:any) => calc.id == props.selectedCalculatorSave );

        if (calc == null) {
            return;
        }

        deleteMutation({ variables: { id: props.selectedCalculatorSave  } } )
        props.setSelectedCalculatorSave(-1) // to empty the calcinterface table
    } 

    const handleCalculate = () => {   
        //calculateMutation({ variables: { id: props.selectedCalculatorSave  } } )
    } 

    const handleMoveUp = () => {

        // find the calculator in the list
        const calc = calculatorSet.calculatorsaveSet.find( (calc:any) => calc.id == props.selectedCalculatorSave );

        if (calc == null) {
            return;
        }

        moveUpMutation( { variables: { id: props.selectedCalculatorSave  } } );
    } 

    const handleMoveDown = () => {

        // find the calculator in the list
        const calc = calculatorSet.calculatorsaveSet.find( (calc:any) => calc.id == props.selectedCalculatorSave );

        if (calc == null) {
            return;
        }

        moveDownMutation( { variables: { id: props.selectedCalculatorSave  } } );
    } 

    const handleCopy = () => {
        // find the calculator in the list
        const calc = calculatorSet.calculatorsaveSet.find( (calc:any) => calc.id == props.selectedCalculatorSave );

        if (calc == null) {
            return;
        }

        setCopyModalVisible(true)
        //copyMutation( { variables: { id: props.selectedCalculatorSave  } } );
    } 

    const handleCalcFunctionChange = (calcfunction:string) => {
        if (calcfunction == 'calc_math') {
            setExpressionVisible(true)
        } else {
            setExpressionVisible(false)
        }
    }

    function handleEnable() {
        // find the calculator in the list
        const calc = calculatorSet.calculatorsaveSet.find( (calc:any) => calc.id == props.selectedCalculatorSave );

        if (calc == null) {
            return;
        }

        let input = {
            id: props.selectedCalculatorSave,
            name: calc.name,
            enabled: true
        };

        updateMutation( { variables: { input: input  } } );
    }

    function handleDisable() {
        // find the calculator in the list
        const calc = calculatorSet.calculatorsaveSet.find( (calc:any) => calc.id == props.selectedCalculatorSave );

        if (calc == null) {
            return;
        }

        let input = {
            id: props.selectedCalculatorSave,
            name: calc.name,
            enabled: false
        };

        updateMutation( { variables: { input: input  } } );
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
          setSelectedRowKeys(newSelectedRowKeys);
          if (selectedRows.length > 0) {
            props.setSelectedCalculatorSave(selectedRows[0].id);
          }
        }
      };

    const formLayout = {
        labelCol: { span: 4 },
        //wrapperCol: { span: 8 },
    };



    const TableColumns = [
        {
            title: 'Order',
            dataIndex: 'order',
            editable: true,
            width: '10%',
        },
        {
            title: 'Enabled',
            width: '10%',

            render : (text:String, record:any) => (
                <div>
                    {
                        record.enabled == false && 
                        <CloseOutlined style={{color: 'red' , fontSize: '1.4em' , marginLeft:10}} title="Disabled"/>

                    }
                    {
                        record.enabled == true && 
                        <CheckOutlined style={{color: 'green' , fontSize: '1.4em' , marginLeft:10}} title="Enabled"/>
                    }
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
            width: '20%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
            width: '30%',
        },
        {
            title: 'Function',
            dataIndex: 'calcfunction',
            editable: true,
        },
        
    ]

    const editable = props.topic.userPermissions != "read"

    return (

            <div >

                <div style={{ display: 'flex'}}>
                    <div style={{ flex: '0 0 90px' }}>
                        <Space direction="vertical">
                            <Button onClick = {  showModal  } className="button_sidebar" title="Create a new Calculator" data-testid="create-collection-calculator" disabled={props.calculatorSetId == "" || !editable}>Create</Button>
                            <Button onClick = {  () => handleEdit()  } className="button_sidebar" title="Edit the Calculator" data-testid="edit-collection-calculator" disabled={props.calculatorSetId == "" || props.selectedCalculatorSave == "" || !editable}>Edit</Button>
                            <Button onClick = {  handleCopy  } className="button_sidebar" title="Copy the Calculator to another Calculator Set" data-testid="copy-collection-calculator" disabled={props.calculatorSetId == "" || props.selectedCalculatorSave == "" || !editable}>Copy</Button>
                            <Button className="button_sidebar" onClick={() => setDeleteModalVisible(true)} title="Delete the Calculator" data-testid="delete-collection-calculator" disabled={props.calculatorSetId == "" || props.selectedCalculatorSave == "" || !editable}>Delete</Button>
                            <Button onClick = {  handleMoveUp  } className="button_sidebar" title="Move up the Calculator" data-testid="move-up-collection-calculator" disabled={props.calculatorSetId == "" || props.selectedCalculatorSave == "" || !editable}>Move up</Button>
                            <Button onClick = {  handleMoveDown  } className="button_sidebar" title="Move down the Calculator" data-testid="move-down-collection-calculator" disabled={props.calculatorSetId == "" || props.selectedCalculatorSave == "" || !editable}>Move down</Button>
                            <Button  className="button_sidebar"  onClick = { handleEnable }  data-testid="enable-collection-calc" title="Enable the Calculator Set" disabled={props.calculatorSetId == "" || props.selectedCalculatorSave == "" || !editable}> Enable </Button>
                        <Button  className="button_sidebar"  onClick = {  handleDisable  }  data-testid="disable-collection-calc" title="Disable the Calculator Set" disabled={props.calculatorSetId == "" || props.selectedCalculatorSave == "" || !editable}> Disable </Button>

                        </Space>
                    </div>
                    <div style={{ marginLeft: 5, width: "100%", overflow:"scroll" }}>


                            <Table 
                                //loading={queryLoading}
                                dataSource={calculatorSet?.calculatorsaveSet}
                                columns={ TableColumns } 
                                bordered
                                rowKey={record => record.id} 
                                size="small"
                                pagination={false}
                                scroll={{ y: height - 460 }}
                                rowSelection={{
                                    type: "radio",
                                    ...rowSelection,
                                }}
                                style={{marginLeft:10, minWidth: 500}}
                                onRow={(record:any, rowIndex:any) => {
                                    return {
                                    onClick: event => {
                                        // Toggle selection for radio type
                                        const newSelectedRowKeys:any = selectedRowKeys.includes(record.id)
                                        ? []
                                        : [record.id];
                                
                                        setSelectedRowKeys(newSelectedRowKeys);

                                        if (newSelectedRowKeys.length > 0) {
                                            props.setSelectedCalculatorSave(newSelectedRowKeys[0]);
                                        } else {
                                            props.setSelectedCalculatorSave("");
                                        }

                                        //props.setSelectedCalculatorSave(record.id);
                                    },
                                    onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"},
                                    onMouseLeave: event => {event.currentTarget.style.cursor = "default"},
                                    };
                                }}
                            />
                        
                    </div>            
                </div>
                    
                <Modal 
                    title= { modalTitel }
                    open={ isModalVisible } 
                    onOk = { handleModalOk }
                    onCancel = { handleModalCancel }
                    destroyOnClose = {true}
                    width = {modalWidth}
                    style={{ top: 20 }}
                    footer={[
                        <Button key="back" onClick={handleModalCancel} data-testid="cancel-collection-calculator">
                            Cancel
                        </Button>,

                        <Button type="primary" form="CalculatorForm" key="submit" htmlType="submit" data-testid="submit-collection-calculator">
                            Submit
                        </Button>
                        ]}
                >
                    
                    <br/>
                    <Form

                        {...formLayout}  
                        id = "CalculatorForm"
                        onFinish={handleModalOk} 
                        initialValues={ modalInitialValues }
                    > 

                        <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                            <Input maxLength={200} showCount data-testid="name"/>
                        </Form.Item>

                        <Form.Item label="Description:" name="description" rules={[{ required: false }]}>
                            <TextArea maxLength={10000} showCount data-testid="description"/>
                        </Form.Item>

                        { isModalAdd &&
                        <Form.Item label="Function:" name="calcfunction" rules={[{ required: isModalAdd }]}  >

                            <Select 
                                disabled={ !isModalAdd } 
                                onChange = { handleCalcFunctionChange } 
                                data-testid="calcfunction-select"
                            >
                                
                                <Option value="calc_math">Generic calculator (calc_math)</Option>
                                <Option value="calc_differentiation">Differentiation (calc_differentiation)</Option>
                                <Option value="calc_integration">Integration (calc_integration)</Option>
                                <Option value="calc_uptake_production">Physiological uptake / production (calc_uptake_production)</Option>
                                <Option value="calc_evaporation">Evaporation (calc_evaporation)</Option>
                                <Option value="calc_offgas">Fermentation offgas analysis (calc_offgas)</Option>
                                <Option value="calc_reference_time">Reference time (calc_reference_time)</Option>
                                
                                <Option value="calc_simulation" disabled={true} >Fermentation simulation (calc_simulation)</Option>
                                

                                

                            </Select>

                        </Form.Item>
                        }

                        { !isModalAdd  && 
                            <Form.Item label="Expression" name="expression" rules={[{ required: false }]} style={ !isExpressionVisible ? { display: 'none'} : {display:''}} >
                                <TextArea maxLength={100000} data-testid="expression"/>
                            </Form.Item>
                        }

                        { /* add the CalcInterfaceTable */ }
                        
                        { !isModalAdd  && 
                            <CalcSaveInterfaceTable
                                topicId={calculatorSet?.topic?.id}
                                calcId={props.selectedCalculatorSave}
                                selectedCalcInterface={selectedCalcInterface}
                                setSelectedCalcInterface={setSelectedCalcInterface}
                            />
                        }

                    </Form>
                    
                </Modal>
                
                { calculatorSet && props.selectedCalculatorSave != "" && props.selectedCalculatorSave != ""  && 
                    <CopyCalculatorSaveModal 
                        topicId= {calculatorSet.topic?.id}
                        isCopyModalVisible={isCopyModalVisible} 
                        setCopyModalVisible={setCopyModalVisible}
                        calculatorSetId={ props.calculatorSetId }
                        selectedCalculatorSave={ props.selectedCalculatorSave }  
                    />
                }

                {calculatorSet && props.selectedCalculatorSave != "" && props.selectedCalculatorSave != "" &&
                    <EditCalculatorInterfaces
                            calcId={props.selectedCalculatorSave}
                            calcType="CalculatorSave"
                            calcfunction={calcFunction}
                            visible={newEditVisible}
                            setVisible={setNewEditVisible}
                    />
                }

                <DeleteModal
                    open={deleteModalVisible}
                    setOpen={setDeleteModalVisible}
                    onOk={handleDelete}
                    objectName={"Calculator"}
                />
            
            </div>
            
            
            
    );
    

}

export default CalculatorSaveTable;

