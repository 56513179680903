import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card , Space, Popconfirm, Button, Form, Skeleton, Modal, Input, message, InputNumber} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateModel from "./CreateModel";
import DeleteModal from '../DeleteModal';
import { pollInterval } from '../..';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;


const CREATE_STAT_MODEL = gql`
    mutation createStatModel($input: CreateStatModelInputType!) {
        createStatModel( input:$input) {
            statModel {
                id
                name
                description
                modelfunction
            }
        }
    }
`;

const UPDATE_STAT_MODEL = gql`
    mutation updateStatModel($input: UpdateStatModelInputType!) {
        updateStatModel( input:$input) {
            statModel {
                id
                name
                description
                modelfunction
            }
        }
    }
`;

const DELETE_STAT_MODEL = gql`
    mutation deleteStatModel($id: ID!) {
        deleteStatModel( id:$id) {
            id
            ok
        }
    }
`;

const GET_STAT_MODELS = gql`
    query statModels ($analysisId:ID!) {
        statModels (analysisId:$analysisId) {
            id
            name
            description
            modelfunction
        }
        userProfile {
            selectedAnalysis {
                id
                userPermissions
            }
        }
    }
`;

const CREATE = CREATE_STAT_MODEL;
const READ = GET_STAT_MODELS;
const UPDATE = UPDATE_STAT_MODEL;
const DELETE = DELETE_STAT_MODEL;

const objectName = "Model";

interface ModelTableInputType {
    analysisId: string
};

function ModelTable(props:ModelTableInputType) {

    const navigate = useNavigate();

    // get the url parameters from react router
    // let params:any = useParams(); // not needed anymore

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [isAddModalVisible, setAddModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ, { variables: { analysisId: props.analysisId }, 
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval 
    });

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ , variables: { analysisId: props.analysisId } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError } ] = useMutation(UPDATE, {
        refetchQueries: [ { query: READ , variables: { analysisId: props.analysisId } } ],
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ , variables: { analysisId: props.analysisId } } ],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Create a model")
        setModalInitialValues({ name:"", description:"" } )
        setAddModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Create a model") {
    
            let input = {
                name : values['name'],
                description: values['description'],
                //processId:props.processId
            };
            
            //createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                description: values['description']
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };

    const handleAddModalCancel = (values: any) => {
        setAddModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit model")
        
        setModalInitialValues({ 
            name:input.name,
            description:input.description
        })
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
    } 

    const formLayout = {
        labelCol: { span: 5 },
    };

    const tailLayout = {
        wrapperCol: { offset: 5, span: 12 },
    };


    const TableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            width: '20%',
            render: (text:String, record:any) => (
                <Link to={'/model/'+ record.id } >{text}</Link>),
        },
        {
            title: 'Type',
            width: '20%',
            dataIndex: 'modelfunction',
            editable: false,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: false,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '20%',
            render: (text:String, record:any) => (

                
                <div>
                    <Space>
                        <Button type="link" onClick={()=> handleEdit(record)} disabled = {queryData.userProfile.selectedAnalysis.userPermissions == "read"} data-testid="edit-button">
                            Edit
                        </Button>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} disabled = {queryData.userProfile.selectedAnalysis.userPermissions == "read"}>
                            <Button type="link" disabled = {queryData.userProfile.selectedAnalysis.userPermissions == "read"} data-testid="delete-button">
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>
                </div>

                
            ),
        },
    ]

    if (queryError) return ( 
        <Card>Error: {queryError.message} </Card>
     );
 
     if (queryLoading && !queryData) return (
        <Card><Skeleton active/></Card>
     );

    return (

        <div>
            
            <Button
                style = {{ marginBottom: 30 }}
                onClick = {  showModal  } 
                type = "primary"
                icon={<PlusOutlined />}
                shape='round'
                disabled = {queryData.userProfile.selectedAnalysis.userPermissions == "read"}
                title = {queryData.userProfile.selectedAnalysis.userPermissions == "read" ? "You don't have permission to add a model to this analysis" : ""}
                data-testid="add-button"
            >
                Create a model
            </Button>

            {/* button for import */}
            {/*
            <Button
                style = {{ marginBottom: 16, marginLeft: 16 }}
                //onClick = {  showModal  } 
                disabled={true}
                title="coming soon"
                data-testid = "import-button"
            >
                Import model from another analysis
            </Button>
            */}
                
            <Table 
                loading={(queryLoading && !queryData)}
                dataSource={queryData.statModels}
                columns={ TableColumns } 
                bordered
                rowKey={record => record.id} 
                size="small"
                pagination={false}


                /*
                onRow={(record, rowIndex) => {
                    return {
                      
                        onClick: event => { handleEdit(record) },

                      // change the mouse cursor to pointer when hovering over the row
                      onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                      onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                    };
                  }
                }
                */
            />

            
                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                footer={[
                    <Button type="primary" form="VariableForm" key="submit" htmlType="submit" data-testid="submit-button">
                        Submit
                    </Button>
                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "VariableForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <br/>
                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <Input maxLength={200} data-testid="name-input"/>
                    </Form.Item>

                    <Form.Item label="Description:" name="description" rules={[{ required: false }]} >
                        <TextArea maxLength={1000} data-testid="description-textarea"/>
                    </Form.Item>

                </Form>

            </Modal>

            <Modal 
                title= "Create a model"
                open={ isAddModalVisible } 
                onCancel = { handleAddModalCancel }
                destroyOnClose = {true}
                footer = {null}
                width={"70%"}
            >
                <CreateModel setOpen={ setAddModalVisible } analysisId={props.analysisId} />
                
            </Modal>

            <DeleteModal
                open = { isDeleteModalVisible }
                setOpen={ setDeleteModalVisible }
                onOk = { () => {}}
                objectName = { "model" }
                
            />


        </div>

    );
}



export default ModelTable;

