import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { useQuery, useMutation, gql, useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';
import { Table, Card , Alert, Space, Skeleton, Button, Spin, message, Collapse, Tag, Input, Modal, Form, Progress, AutoComplete, Select, Checkbox, Slider} from 'antd';
import { SearchOutlined , PlusOutlined, CloseOutlined} from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import AddProcess from '../AddProcess';
import moment from 'moment';
//import { pollInterval } from '../..';
import {createOptimisticResponse} from '../createOptimisticResponse'

import { EXTENDED_PROCESS_FIELDS, CORE_TOPIC_USER_CONFIGS_FIELDS } from '../fragments';

const { Panel } = Collapse;



const { Column, ColumnGroup } = Table;

const GET_PROCESSES = gql`
    ${EXTENDED_PROCESS_FIELDS}
    ${CORE_TOPIC_USER_CONFIGS_FIELDS}

    query processes($topicId:ID, $cursor:ID, $limit:Int, $filter:Boolean) {
        processes (topicId:$topicId, cursor:$cursor, limit:$limit, filter:$filter) {
            processes {
                ...ExtendedProcessFields
            }
            totalCount
        }

        processesByTopicId(topicId:$topicId) {
            id
        }

        uniqueMeta(topicId:$topicId)
        uniqueParams(topicId:$topicId)
        
        topicUserConfigs(id:$topicId) {
            ...CoreTopicUserConfigsFields
        }

        topicById(id:$topicId) {
            id
            numProcesses
            userPermissions
        }
    } 
`;

const UPDATE_TOPIC_USER_CONFIGS = gql`
    ${CORE_TOPIC_USER_CONFIGS_FIELDS}
    mutation updateTopicUserConfigs ($input:UpdateTopicUserConfigsInputType!) {  
        updateTopicUserConfigs(input:$input) {
            ok
            topicUserConfigs {
                ...CoreTopicUserConfigsFields
            }
        }
    }
`;


const DELETE_PROCESSES = gql`
    mutation deleteProcesses($ids: [ID]!) {
        deleteProcesses(ids: $ids) {
            ids
            ok
        }
    }
`;

const GET_SELECTED_PROCESSES = gql`
    query selectedProcesses($pageName:String!) {  
        selectedProcesses {
            id
            name
        }
    }
`;

interface ProcessTablePaginatedInputType {
    topicId?:number,
    tableBottomOffset?:number,
};

function ProcessTablePaginated(props:ProcessTablePaginatedInputType) {

    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

    let topicId:any = null
    if (props.topicId) {
        topicId = props.topicId
    }

    let tableBottomOffset = 350
    if (props.tableBottomOffset) {
        tableBottomOffset = props.tableBottomOffset
    }

    const { height, width } = useWindowDimensions();

    const [isModalVisible, setModalVisible] = useState(false);
    
    const [isModalInputVisible, setModalInputVisible] = useState(false); // for metadata
    const [isModalInputVisibleParameters, setModalInputVisibleParameters] = useState(false); // for parameters

    const [modalInputValue, setModalInputValue] = useState(""); // for metadata
    const [modalInputValueParameters, setModalInputValueParameters] = useState(""); // for parameters
    
    const [nameSearchText, setNameSearchText] = useState("");
    const [metaFilters, setMetaFilters] = useState( [ {'id':'1', 'name':'', 'condition':'', 'value':''} ] );
    
    //const [selectedTopicId, setSelectedTopicId] = useState("");
    //const [processFilters, setProcessFilters] = useState({});

    const [pageSize, setPageSize] = useState(10); // Number of data items in the table

    const [isEditColumnsModalVisible, setEditColumnsModalVisible] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [processes, setProcesses] = useState<any>([]);
    const [deletedProcesses, setDeletedProcesses] = useState<any>([]);

    
    // Get the processesmetaFilters add a refetch 
    const { loading, error, data, refetch, fetchMore } = useQuery(GET_PROCESSES, {
        variables: { topicId:topicId , cursor:0, limit:pageSize, filter:true },
        fetchPolicy: "cache-and-network",
        //pollInterval: 5000,
        notifyOnNetworkStatusChange: false,

        onCompleted(data) {
            //console.log('data', data.processes.processes)
        }

        
        //notifyOnNetworkStatusChange: false, 
    });
    
    const [deleteProcesses, {loading:loadingDeleteProcesses, error:errorDeleteProcesses}] = useMutation(DELETE_PROCESSES , {
        /*refetchQueries: [ 
            { query:  GET_PROCESSES, variables: { topicId:topicId , cursor:0, limit:pageSize , filter:true} },
            { query:  GET_SELECTED_PROCESSES, variables: { pageName: "ProcessExplorer" }}, 
            { query:  GET_SELECTED_PROCESSES, variables: { pageName: "KPIAnalysis" }}, 
        ], */
        onCompleted(data) { 
            message.success( "Deleting processes will run in the background") 
            // add the ids of deleted processes to the deletedProcesses array
            setDeletedProcesses([...deletedProcesses, ...data.deleteProcesses.ids])
        } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateTopicUserConfigs] = useMutation(UPDATE_TOPIC_USER_CONFIGS , {
        //refetchQueries: [ { query:  GET_PROCESSES, variables: { topicId:topicId }}  ], 
        onCompleted(data) { 
            refetch();
            //message.success( "Configs updated successfully");
        } ,
        onError(error) {message.error(error.message)},
    });

    // use effect to set the value of nameSearchText and metaFilters
    useEffect(() => {
        if (data && data.topicUserConfigs) {
            setNameSearchText(data.topicUserConfigs.processTableFilterProcessNameContains);
            //console.log(data.topicUserConfigs)
            setMetaFilters(data.topicUserConfigs.processTableFilterMetaDataNames.map( (name:string, index:number) => {
                return {
                    'id': (index + 1).toString(),
                    'name': name,
                    'condition': data.topicUserConfigs.processTableFilterMetaDataConditions[index],
                    'value': data.topicUserConfigs.processTableFilterMetaDataValues[index],
                }
            }));
        }
    }, [data]);

    // useEffect to set the local value of processes
    useEffect(() => {
        if (data && data.processes && data.processes.processes) {
           
            // create a copy of data.processes
            let processesLocal = data.processes.processes.slice(0);

            // remove any processes whose id is not one of the ids of the processes in processesByTopicId
            // this is needed to remove processes that have been deleted (remember we have a merge function in Apollo.tsx that merges the cache with the new data from the server regardless of delete - so this is needed)
            processesLocal = processesLocal.filter( (process:any) => data?.processesByTopicId.find( (p:any) => p.id == process.id ) != null )


            // sort processes by name
            processesLocal.sort( (a:any, b:any) => a.name.localeCompare(b.name) )
            setProcesses(processesLocal);
        }
    }, [data, deletedProcesses]);


    if (error) {

        return(
            <Card>
                <Alert
                    message="Error"
                    description={error.message}
                    type="error"
                    showIcon
                />
            </Card>

        )
    }
    
    
    if (loading && !data) return (
        <Card>
            { topicId != null && topicId != 0 &&
            <div>
                <Button
                    style = {{ marginBottom: 16 }}
                    data-testid="create-new-process"                         
                >
                    Create a new process
                </Button>

                <Button
                    style = {{ marginBottom: 16, marginLeft:10 }}   
                    data-testid="edit-columns"                
                >
                    Edit columns
                </Button>
            </div>
            }

        <Table 
            loading={{ indicator: <Spin/>} }
            bordered
            locale={{emptyText:"Loading"}} 
            />
        </Card>
    );
    

    

    const handleClickCreateNewProcess = (value:any) => {
        setModalVisible(true);
    }

    function handleTagClose(value:string) {
        //setMetaData(  metaData.filter(tag => tag != value) )
        const newMeta = data.topicUserConfigs.processTableMetaData.filter( (tag:string) => tag != value)
        
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableMetaData: newMeta},
            'CoreTopicUserConfigsFields'
        );

        updateTopicUserConfigs({variables:{'input': {'processTableMetaData' : newMeta , 'id': topicId }  }, 
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })

        setModalInputValue("")
        setModalInputVisible(false)
    }

    function handleTagCloseParameters(value:string) {
        //setMetaData(  metaData.filter(tag => tag != value) )
        const newParams = data.topicUserConfigs.processTableParameters.filter( (tag:string) => tag != value)

        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableParameters: newParams},
            'CoreTopicUserConfigsFields'
        );


        updateTopicUserConfigs({variables:{'input': {'processTableParameters' : newParams , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })

        setModalInputValueParameters("")
        setModalInputVisibleParameters(false)
    }

    function renderFunc(record:any, meta:string) {
        var metaObject = record.metadataSet.find( (c:any) => c.name == meta )
        if(metaObject != null ){   
            return metaObject['value']
        } else {
            return ''
        }
    }

    function renderFuncParameters(record:any, parameter:string) {
        var parameterObject = record.parameterSet.find( (c:any) => c.name == parameter )
        if(parameterObject != null ){
            let value = parameterObject['value']
            // round to 3 decimal places
            if( !isNaN(value) ){
                value = Math.round(value * 1000) / 1000
            }
            return value + ' ' + parameterObject['units']
        } else {
            return ''
        }
    }

    // function to create filters for meta data columns
    function createFilters(meta:string) {
        // for each process in processes find the meta data value
        // if the value is not in the filter list, add it
        var uniqueMetaValues:any = []
        var filters:any = []
        processes.forEach( (process:any) => {
            var metaObject = process.metadataSet.find( (c:any) => c.name == meta )
            if(metaObject != null ){
                if( !uniqueMetaValues.includes(metaObject['value']) ){
                    uniqueMetaValues.push(metaObject['value'])
                    filters.push( {text: metaObject['value'], value: metaObject['value']} )
                }
            }
        })
        return filters
    }


    // creating the columns for the table and the tags for the modal for meta data columns
    var metaDataFields:any = []
    var modalTags:any = []

    for (const meta of data.topicUserConfigs.processTableMetaData) {

        modalTags.push(
            <Tag closable id={meta} onClose={() => handleTagClose(meta)}>
                {meta}
            </Tag>
        )

        metaDataFields.push(
            {
                title: meta,
                editable: true,
                filterSearch: true,
                //filters: createFilters(meta),
                onFilter: (value: string, record:any) => renderFunc(record, meta) === value,
                render: (text:String, record:any) => (
                    renderFunc(record, meta)
                ),
            }
        )
    }

    // creating the columns for the table and the tags for the modal for parameter columns
    var parameterFields:any = []
    var modalTagsParameters:any = []

    for (const parameter of data.topicUserConfigs.processTableParameters) {

        modalTagsParameters.push(
            <Tag closable id={parameter} onClose={() => handleTagCloseParameters(parameter)}>
                {parameter}
            </Tag>
        )

        parameterFields.push(
            {
                title: parameter,
                editable: false,
                //filterSearch: true,
                //filters: createFilters(meta),
                //onFilter: (value: string, record:any) => renderFunc(record, parameter) === value,
                render: (text:String, record:any) => (
                    renderFuncParameters(record, parameter)
                ),
            }
        )
    }

  
    const columns_1:any = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
            //width: '15%',
            render: (text:String, record:any) => (
                
                <Link to={'/collections/collection/'+ props.topicId + '/process/'+ record.id } >{text}</Link> 

            ),
            
            /*sorter: (a:any, b:any) => a.name.localeCompare(b.name),*/
            sortDirections: ['ascend', 'descend'],
            /*filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleReset(clearFilters)} 
                            size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>

            ),*/
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
        }
    ]
    
    const columns_2:any = [
        {    
            title: 'Description',
            width: '10%',
            //dataIndex: ,
            editable: false,
            render: (text:String, record:any) => (
                <div>
                    <p>{record.description}</p>
                </div>
            ),
        },
        
        {
            title: 'Data Quality',
            //width: '15%',
            dataIndex: 'qualityScore',
            editable: false,
            /*sorter: (a:any, b:any) => a.qualityScore - b.qualityScore,*/
            sortDirections: ['ascend', 'descend'],
            render : (text:String, record:any) => (
                <Progress percent={record.qualityScore} size="small" /> 
            ),
            
            /*render: (text:String, record:any) => (
                    <div>
                        <Tooltip title="All requirements satisfied"><CheckCircleOutlined style={{color:"green", fontSize:16, fontWeight:"bold"}} /></Tooltip>   
                    </div>
            ),*/

        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            width: 180,
            editable: false,
            render : (text:String, record:any) => (
                // convert from utc to local time
                record.startTime != null ? moment.utc(record.startTime).local().format('DD.MM.YYYY HH:mm:ss') : null
            ),

        },
        {
            title: 'Last Modified',
            //dataIndex: 'lastUpdated',
            width: 180,
            editable: false,
            render : (text:String, record:any) => (
                // convert from utc to local time
                record.lastUpdated != null ? moment.utc(record.lastUpdated).local().format('DD.MM.YYYY HH:mm:ss') : null
            ),
        },
        /*{
            title: 'Actions',
            key: 'actions',
            width: '5%',
            render: (text:String, record:any) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure you want to delete this process?" onConfirm={() => handleDelete(record.id)} data-testid="delete-process">
                        <a>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },*/
    ];

    var columns = columns_1
    
    // if data.topicUserConfigs.processTableDisplayMetaData is true, add the meta data columns
    if (data.topicUserConfigs.processTableDisplayMetaData) {
        columns = columns.concat(metaDataFields)
    }

    // if data.topicUserConfigs.processTableDisplayParameters is true, add the parameter columns
    if (data.topicUserConfigs.processTableDisplayParameters) {
        columns = columns.concat(parameterFields)
    }

    // add the other columns
    columns = columns.concat(columns_2)
    
    //const expandable = { expandedRowRender: record  => <p>{record.description}</p> };

    // if data.topicUserConfigs.processTableDisplayDataQuality is false, remove the data quality column
    if (!data.topicUserConfigs.processTableDisplayDataQuality) {
        // find the index of the data quality column
        var index = columns.findIndex((x:any) => x.title === "Data Quality")
        // remove the data quality column
        columns.splice(index, 1)
    }

    // if data.topicUserConfigs.processTableDisplayDescription is false, remove the description column
    if (!data.topicUserConfigs.processTableDisplayDescription) {
        // find the index of the description column
        var index = columns.findIndex((x:any) => x.title === "Description")
        // remove the description column
        columns.splice(index, 1)
    }

    // if data.topicUserConfigs.processTableDisplayStartTime is false, remove the start time column
    if (!data.topicUserConfigs.processTableDisplayStartTime) {
        // find the index of the start time column
        var index = columns.findIndex((x:any) => x.title === "Start Time")
        // remove the start time column
        columns.splice(index, 1)
    }

    // if data.topicUserConfigs.processTableDisplayLastModified is false, remove the last modified column
    if (!data.topicUserConfigs.processTableDisplayLastModified) {
        // find the index of the last modified column
        var index = columns.findIndex((x:any) => x.title === "Last Modified")
        // remove the last modified column
        columns.splice(index, 1)
    }
    
    function handleClickEditColumns(value:any) {
        setEditColumnsModalVisible(true)
    }

    function handleEditColumnsModalCancel(value:any) {
        setEditColumnsModalVisible(false)
    }

    function handleEditColumnsModalOnFinish(value:any) {
        setEditColumnsModalVisible(false)
    }

    function showModalInput(value:any) {
        setModalInputVisible(true)
    }

    function showModalInputParameters(value:any) {
        setModalInputVisibleParameters(true)
    }

    function handleModalInputConfirm(e:any) {
        
        if (modalInputValue=="") {
            setModalInputVisible(false)
            return
        }

        var newMeta = data.topicUserConfigs.processTableMetaData.concat([modalInputValue])

        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableMetaData: newMeta},
            'CoreTopicUserConfigsFields'
        );

        updateTopicUserConfigs({variables:{'input': {'processTableMetaData' : newMeta , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
        setModalInputValue("")
        setModalInputVisible(false)
    }

    function handleModalInputConfirmParameters(e:any) {

        if (modalInputValueParameters=="") {
            setModalInputVisibleParameters(false)
            return
        }

        var newParam = data.topicUserConfigs.processTableParameters.concat([modalInputValueParameters])

        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableParameters: newParam},
            'CoreTopicUserConfigsFields'
        );

        updateTopicUserConfigs({variables:{'input': {'processTableParameters' : newParam , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
        setModalInputValueParameters("")
        setModalInputVisibleParameters(false)
    }



    function handleModalInputChange(e:any) {
        setModalInputValue(e)
    }

    function handleModalInputChangeParameters(e:any) {
        setModalInputValueParameters(e)
    }

    const handleNameSearchChange = (e:any) => {
        //setNameSearchText(e.target.value);
        //return
        
        if (e.target.value == '' ) {
            //setNameSearchText(e.target.value);
            const optimisticResponse = createOptimisticResponse<any>(
                client, 
                CORE_TOPIC_USER_CONFIGS_FIELDS,
                topicId,
                {processTableFilterProcessNameContains: ''},
                'CoreTopicUserConfigsFields'
            );

            updateTopicUserConfigs({variables:{'input': {'processTableFilterProcessNameContains' : '' , 'id': topicId }  },
                optimisticResponse: {
                    __typename: "Mutation",
                    updateTopicUserConfigs: {
                        __typename: "UpdateTopicUserConfigsPayload",
                        ok: true,
                        topicUserConfigs: {
                            __typename: "TopicUserConfigsType",
                            ...optimisticResponse
                        }
                    }
                }
            })

        } else {
            setNameSearchText(e.target.value);
        }
    };

    const handleNameSearchSubmit = (value:any) => {
        
        //setProcessFilters({processNameContains: nameSearchText});
        // perform the mutation to update topicUserConfigs
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableFilterProcessNameContains: nameSearchText},
            'CoreTopicUserConfigsFields'
        );

        updateTopicUserConfigs({variables:{'input': {'processTableFilterProcessNameContains' : nameSearchText , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    };

    // handlePagination is called when the user changes the page size or selects a different page
    function handlePagination(page:any, pageSize:any) {
        if (page != 1) {
            // the cursor for the fetchMore is the id of the last process in the current page
            var cursor = processes[processes.length-1].id
            fetchMore({ variables: { cursor:cursor } })
        }
    }

    function handleLoadMore() {
        // the cursor for the fetchMore is the id of the last process in the current page
        var cursor = processes[processes.length-1].id
        //console.log('cursor', cursor    )
        fetchMore({ variables: { cursor:cursor } })
    }

    function handleRemoveFilter(id:any) {
        setMetaFilters( metaFilters.filter( (filter:any) => filter.id != id ) )
    }

    function handleAddFilter() {
        // add a new filter to the metaFilters array
        let new_id:any = metaFilters.length + 1
        setMetaFilters( [...metaFilters, {id: new_id.toString() , name: '', condition:'', value: ''}] )
    }

    function handleFilterMetaDataNameChange(name:any, id:any) {
        let newMetaFilters = metaFilters.map( (filter:any) => {
            if (filter.id == id) {
                filter.name = name
            }
            return filter
        })
        setMetaFilters(newMetaFilters)
    }

    function handleFilterMetaDataConditionChange(condition:any, id:any) {
        let newMetaFilters = metaFilters.map( (filter:any) => {
            if (filter.id == id) {
                filter.condition = condition
            }
            return filter
        })
        setMetaFilters(newMetaFilters)
    }

    function handleFilterMetaDataValueChange(value:any, id:any) {
        let newMetaFilters = metaFilters.map( (filter:any) => {
            if (filter.id == id) {
                filter.value = value
            }
            return filter
        })
        setMetaFilters(newMetaFilters)
    }

    function handleFilterMetaDataSubmit() {
        let metaDataNames = metaFilters.map( (filter:any) => filter.name )
        let metaDataConditions = metaFilters.map( (filter:any) => filter.condition )
        let metaDataValues = metaFilters.map( (filter:any) => filter.value )
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableFilterMetaDataNames: metaDataNames, processTableFilterMetaDataConditions: metaDataConditions, processTableFilterMetaDataValues: metaDataValues},
            'CoreTopicUserConfigsFields'
        );

        // perform the mutation to update topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableFilterMetaDataNames' : metaDataNames , 'processTableFilterMetaDataConditions' : metaDataConditions , 'processTableFilterMetaDataValues' : metaDataValues , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
        
        // append the new filters to the existing filters without loosing the existing filters
        /*setProcessFilters({...processFilters, metaDataNames: metaDataNames, metaDataConditions: metaDataConditions, metaDataValues: metaDataValues})
        */
    }

    function handleOnClickDisplayDescription(value:any) {
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableDisplayDescription: value.target.checked},
            'CoreTopicUserConfigsFields'
        );

        // perform a mutation to update the topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableDisplayDescription' : value.target.checked , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    }

    function handleOnClickDisplayParameters(value:any) {
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableDisplayParameters: value.target.checked},
            'CoreTopicUserConfigsFields'
        );
        // perform a mutation to update the topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableDisplayParameters' : value.target.checked , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    }

    function handleOnClickDisplayMetaData(value:any) {
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableDisplayMetaData: value.target.checked},
            'CoreTopicUserConfigsFields'
        );
        // perform a mutation to update the topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableDisplayMetaData' : value.target.checked , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    }

    function handleOnClickDisplayDataQuality(value:any) {
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableDisplayDataQuality: value.target.checked},
            'CoreTopicUserConfigsFields'
        );

        // perform a mutation to update the topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableDisplayDataQuality' : value.target.checked , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    }

    function handleOnClickDisplayStartTime(value:any) {
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableDisplayStartTime: value.target.checked},
            'CoreTopicUserConfigsFields'
        );
        // perform a mutation to update the topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableDisplayStartTime' : value.target.checked , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    }

    function handleOnClickDisplayLastModified(value:any) {
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableDisplayLastModified: value.target.checked},
            'CoreTopicUserConfigsFields'
        );

        // perform a mutation to update the topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableDisplayLastModified' : value.target.checked , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    }

    function handleFilterDataQualityScoreChange(value:any) {
        const optimisticResponse = createOptimisticResponse<any>(
            client, 
            CORE_TOPIC_USER_CONFIGS_FIELDS,
            topicId,
            {processTableFilterDqsMin: value[0], processTableFilterDqsMax: value[1]},
            'CoreTopicUserConfigsFields'
        );

        // perform the mutation to update topicUserConfigs
        updateTopicUserConfigs({variables:{'input': {'processTableFilterDqsRange' : value , 'id': topicId }  },
            optimisticResponse: {
                __typename: "Mutation",
                updateTopicUserConfigs: {
                    __typename: "UpdateTopicUserConfigsPayload",
                    ok: true,
                    topicUserConfigs: {
                        __typename: "TopicUserConfigsType",
                        ...optimisticResponse
                    }
                }
            }
        })
    
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

      const hasSelected = selectedRowKeys.length > 0;

    function handleClickDeleteProcesses() {
        //console.log('Processes to delete', selectedRowKeys)
        // open the confirm delete modal
        setOpenConfirmDeleteModal(true)
        //history.push('/processes/new')
    }

    function handleConfirmDeleteModalOk() {
        // perform the mutation to delete the processes
        deleteProcesses({variables:{'ids' : selectedRowKeys }})
        // close the confirm delete modal
        setOpenConfirmDeleteModal(false)
        // clear the selected row keys
        setSelectedRowKeys([])
    }

    const formLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20},
    };
    
    const tailLayout = {
        wrapperCol: { offset: 4, span: 20 },
    };

    return (

        <div>
            { topicId != null &&
            <div>
                <Button key={1}
                    style = {{ marginBottom: 16 }}
                    onClick = {  handleClickCreateNewProcess  }
                    type="primary"
                    shape="round" 
                    icon={<PlusOutlined />}
                    disabled = { data.topicById.userPermissions == "read"}
                    title = { data.topicById.userPermissions == "read" ? "You do not have permission to create processes" : "" }  
                    data-testid="create-new-process"                       
                >
                    Create a new process
                </Button>

                <Button key={2}
                    style = {{ marginBottom: 16, marginLeft:10 }}
                    onClick = {  handleClickEditColumns  }  
                    data-testid="edit-columns"                         
                >
                    Edit columns
                </Button>

                <Button key={3}
                    style = {{ marginBottom: 16, marginLeft:10 }}
                    onClick = {  handleClickDeleteProcesses  }
                    disabled = { !hasSelected || data.topicById.userPermissions == "read"}
                    title = { data.topicById.userPermissions == "read" ? "You do not have permission to delete processes" : "" }
                    data-testid="delete-processes"
                >
                    Delete processes
                </Button>
            
            </div>

            }
            <div style={{ display: 'flex'}} key={3}>

                <div style={{ flex: '0 0 220px'}}>
                    <div style={{height:height-tableBottomOffset, overflow:"auto", width:"100%"}}>
                        <Collapse defaultActiveKey={["1","2","3","4"]}  >
                            {/* Show it only if the props topic has not been set */ }
                            { topicId==null &&
                            <Panel header="Topic" key="0">
                                {/*<SelectTopic selectedTopicId={selectedTopicId } setSelectedTopic={setSelectedTopicId}/>*/}
                            </Panel>
                            }

                            <Panel header="Display columns" key="1" data-testid="display-columns-panel">
                                <Space direction="vertical">
                                    <Checkbox checked={data.topicUserConfigs.processTableDisplayMetaData} onChange={handleOnClickDisplayMetaData} data-testid="display-metadata"> Metadata</Checkbox>
                                    <Checkbox checked={data.topicUserConfigs.processTableDisplayParameters} onChange={handleOnClickDisplayParameters} data-testid="display-parameters"> Parameters</Checkbox>
                                    <Checkbox checked={data.topicUserConfigs.processTableDisplayDescription} onChange={handleOnClickDisplayDescription} data-testid="display-description"> Description</Checkbox>
                                    <Checkbox checked={data.topicUserConfigs.processTableDisplayDataQuality} onChange={handleOnClickDisplayDataQuality} data-testid="display-data-quality"> Data Quality</Checkbox>
                                    <Checkbox checked={data.topicUserConfigs.processTableDisplayStartTime} onChange={handleOnClickDisplayStartTime} data-testid="display-start-time"> Start Time</Checkbox>
                                    <Checkbox checked={data.topicUserConfigs.processTableDisplayLastModified} onChange={handleOnClickDisplayLastModified} data-testid="display-last-modified"> Last Modified</Checkbox>
                                </Space>
                            </Panel>

                            <Panel header="Search by Name" key="2" data-testid="search-by-name-panel">
                                <Input.Search 
                                    placeholder="Name & description"
                                    onSearch={handleNameSearchSubmit}
                                    //onSubmit={handleNameSearchSubmit}
                                    enterButton 
                                    allowClear
                                    
                                    onChange={handleNameSearchChange}
                                    value={nameSearchText }
                                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                                    data-testid="search-by-name"
                                />
                            </Panel>

                            <Panel header="Filter by Data Quality" key="3" data-testid="filter-by-data-quality-score-panel">
                                <Slider
                                    range
                                    min={0}
                                    max={100}
                                    defaultValue={[data.topicUserConfigs.processTableFilterDqsMin, data.topicUserConfigs.processTableFilterDqsMax]}
                                    marks={{
                                        0: '0',
                                        100: '100',
                                    }}
                                    onAfterChange={handleFilterDataQualityScoreChange}
                                    
                                    data-testid="filter-by-data-quality-score"
                                />
                            </Panel>

                            <Panel header="Filter by metadata" key="4" data-testid="filter-by-metadata-panel">
                                <Collapse>

                                    { metaFilters.map((filter:any) => (

                                        <Panel header={"Filter " + filter.id } key={'filter-meta-' + filter.id } extra={<CloseOutlined onClick={() => handleRemoveFilter(filter.id)} data-testid={"filter-meta-" + filter.id + "-remove"} />}>
                                            <Space size="middle" direction='vertical' style={{ width: "100%" }}>
                                                <AutoComplete
                                                    id = {filter.id}
                                                    style={{ width: "100%" }}
                                                    placeholder="Metadata"
                                                    options={
                                                        // loop through the entries in data.uniqueMeta
                                                        data.uniqueMeta.map((meta:any) => (
                                                            {value: meta}
                                                        ))
                                                    }
                                                    //onChange={handleFilterMetaDataNameChange}
                                                    onChange={(value, id) => handleFilterMetaDataNameChange(value, filter.id)}
                                                    value={filter.name}
                                                    data-testid={"filter-meta-" + filter.id + "-name"}
                                                />
                                                <Select 
                                                    id = {filter.id}
                                                    style={{ width: "100%" }}
                                                    placeholder="Condition"
                                                    onChange={(value, id) => handleFilterMetaDataConditionChange(value, filter.id)}
                                                    value={filter.condition}
                                                    data-testid={"filter-meta-" + filter.id + "-condition"}
                                                >
                                                    <Select.Option value="eq" data-testid={"filter-meta-" + filter.id + "-condition-eq"}>Equals</Select.Option>
                                                    <Select.Option value="neq" data-testid={"filter-meta-" + filter.id + "-condition-neq"}>Not equals</Select.Option>
                                                    <Select.Option value="contains" data-testid={"filter-meta-" + filter.id + "-condition-contains"}>Contains</Select.Option>
                                                    <Select.Option value="not_contains" data-testid={"filter-meta-" + filter.id + "-condition-not_contains"}>Not contains</Select.Option>
                                                </Select>
                                                
                                                <AutoComplete
                                                    id = {filter.id}
                                                    style={{ width: "100%" }}
                                                    placeholder="Value" 
                                                    onChange={(value, id) => handleFilterMetaDataValueChange(value, filter.id)}
                                                    value={filter.value}
                                                    data-testid={"filter-meta-" + filter.id + "-value"}
                                                />
                                            </Space>
                                        </Panel>
                                    ))}

                                </Collapse>
                                <Space size="middle" style={{marginTop:10}}>
                                    <Button key={1}
                                        onClick={handleAddFilter}
                                        data-testid="filter-meta-add"
                                    >
                                        Add filter
                                    </Button>
                                    <Button type="primary" key={2} 
                                        onClick={handleFilterMetaDataSubmit}
                                        data-testid="filter-meta-apply"
                                    >
                                        Apply
                                    </Button>
                                </Space>
                            </Panel>

                            {/*<Panel header="Filter by parameters" key="4">
                            </Panel>
                            */}

                        </Collapse>
                    </div>
                </div>
                
                
                <div style={{ width: '100%', overflow: 'hidden', marginLeft:10 }}>

                    <Table 
                        dataSource={processes}
                        columns={columns} 
                        bordered 
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        footer={() => (
                            <div style={{textAlign:"center"}}>
                                { /* if number of processes is less than total count, show load more button */}
                                {processes.length < data.processes.totalCount && (
                                    <Button type="link"
                                        style = {{ marginBottom: 16 }}
                                        onClick = {  handleLoadMore }
                                        data-testid="load-more"
                                    >
                                        Load more
                                    </Button>
                                )}
                                <div>{processes.length} of {data.processes.totalCount}</div>
                            </div>
                        )}
                        scroll={{ y: height - 450 , x: 'max-content'}}

                        /*pagination={{ 
                            responsive:true,
                            total: processes.totalCount, 
                            pageSize: 10 , 
                            showSizeChanger:false, 
                            position:["bottomCenter"] , 
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` , 
                            defaultCurrent:1 , 
                            defaultPageSize:10 , 
                            pageSizeOptions: ['10', '20', '30', '40'] , 
                            showQuickJumper:true,
                            onChange: (page, pageSize) => {
                                handlePagination(page, pageSize)
                            }

                        }}*/

                        //onChange={handleTableChange}
                        //pagination={false}
                        //scroll={ {y: height - 400 } }
                        
                    />
                </div>
                
            </div>
            
            <AddProcess 
                topicId={topicId}
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
            />
        
            <Modal 
                        title= 'Edit columns'
                        open={ isEditColumnsModalVisible } 
                        //onOk = { }
                        onCancel = { handleEditColumnsModalCancel }
                        destroyOnClose = {true}
                        footer = {[]}
                        width={"40%"}
                        /*footer={[
                            <Button type="primary" form="AddProcessForm" key="submit" htmlType="submit">
                                Submit
                            </Button>
                            ]}
                        */
                    >
            
            <Form 
                {...formLayout}
                onFinish={handleEditColumnsModalOnFinish}
                //onFinishFailed={onFinishFailed}
            >
            
                <Form.Item
                    label="Metadata:"
                    name="metadata"
                >
                    {modalTags}

                    {isModalInputVisible ? (
          
                        <AutoComplete
                            //ref={modalInputValue}
                            //type="text"
                            size="small"
                            style={{ width: 150 }}
                            value={modalInputValue}
                            onChange={handleModalInputChange}
                            onBlur={handleModalInputConfirm}
                            //onPressEnter={handleModalInputConfirm}
                            data-testid="modal-input"
                            options={
                                // loop through the entries in data.uniqueMeta
                                data.uniqueMeta.map((meta:any) => (
                                    {value: meta}
                                ))
                            }
                        />
                    ) : (
                        <Tag onClick={ showModalInput } className="site-tag-plus" data-testid="modal-input-plus">
                            <PlusOutlined /> New
                        </Tag>
                    )}

                </Form.Item>
                <Form.Item
                    label="Parameters:"
                    name="parameters"
                >
                    {modalTagsParameters}

                    {isModalInputVisibleParameters ? (

                        <AutoComplete
                            //ref={modalInputValue}
                            //type="text"
                            size="small"
                            style={{ width: 150 }}
                            value={modalInputValueParameters}
                            onChange={handleModalInputChangeParameters}
                            onBlur={handleModalInputConfirmParameters}
                            data-testid="modal-input-parameters"
                            //onPressEnter={handleModalInputConfirm}
                            options={
                                // loop through the entries in data.uniqueMeta
                                data.uniqueParams.map((meta:any) => (
                                    {value: meta}
                                ))
                            }
                        />
                    ) : (
                        <Tag onClick={ showModalInputParameters } className="site-tag-plus" data-testid="modal-input-plus-parameters">
                            <PlusOutlined /> New
                        </Tag>
                    )}


                </Form.Item>

            </Form>
            </Modal>

            <Modal
                
                open={openConfirmDeleteModal}
                onOk={handleConfirmDeleteModalOk}
                onCancel={ () => setOpenConfirmDeleteModal(false) }
                okText="Yes, delete!"
                cancelText="No, get me out of here!"
                okButtonProps={{ danger: true }}
                width={"40%"}
                closable={false}
                
            >
                { /* if only one process is selected, show the name of the process in the alert message */}
                { selectedRowKeys.length === 1 && 
                    <Alert
                        message={"Are you sure you want to delete the process " + data.processes.processes.filter((process:any) => process.id === selectedRowKeys[0])[0].name + "?"}
                        description={(
                            <div>
                                <br/>
                                <p>This action cannot be undone and will delete all the data associated with the process.</p>
                            </div>
                            )}
                        type="warning"
                        showIcon
                        style={{marginBottom:20}}
                    />
                }
                { /* if more than one process is selected, show the number of processes in the alert message */}
                {selectedRowKeys.length > 1 && 
                    <Alert
                        message={"Are you sure you want to delete " + selectedRowKeys.length + " processes?"}
                        description={(
                            <div>
                                <br/>
                                <p>This action cannot be undone and will delete all the data associated with the processes.</p>
                            </div>
                            )}
                        type="warning"
                        showIcon
                        style={{marginBottom:20}}
                    />
                }
               

            </Modal>
            <Modal
                title="Deleting process(es)"
                open={loadingDeleteProcesses}
                footer={null}
                closable={false}
            >
                <Skeleton active/>
            </Modal>

        </div>
    );
}

export default ProcessTablePaginated;