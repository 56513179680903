import React, { useState, useEffect } from 'react';
import { Select, Card , Skeleton, Button , Modal} from 'antd';
import Plot from 'react-plotly.js';
import useWindowDimensions from '../useWindowDimensions';
import { error } from 'console';

const { Option } = Select;


interface MLRPlotInputType {
  statModel:any
  loading:boolean
  darkMode:boolean
}

function MLRPlot(props: MLRPlotInputType) {

  const { height, width } = useWindowDimensions();

  const [plotWidth, setPlotWidth] = useState("48%")
  const [plotHeight, setPlotHeight] = useState(height-160)
  const [plotFractiom, setPlotFraction] = useState(0.8)
  const [fitSummaryVisible, setFitSummaryVisible] = useState(false)

  // adjust the plotWidth and plotHeight parameters based on number of plots in the view
  useEffect(() => {
   
      setPlotHeight( height - 160  )
    
  })

  // create an array by concatenating both y and yPred arrays
  const y = props.statModel.y.concat(props.statModel.yPred)

  // get the maximum and minimum of array y and multiply by a factor to be able to use as the range of the plot
  const yMax = Math.max(...y)*1.1
  const yMin = Math.min(...y)*1.1

  let errorBarsVisible = false
  // if the props.statModel.mlrCoefficientsErrors is not only None, then set errorBarsVisible to true
  if (props.statModel.mlrCoefficientsErrors.filter((x:any) => x !== null).length > 0) {
    errorBarsVisible = true
  }

    // set the background color of the plot
    let plotBgColor = "white"
    let axisColor = "black"
    let barColor = "cyan"
    let errorColor ="black"
    let dataPointsColor = "black"
    if (props.darkMode) {
        plotBgColor = "#141414"
        axisColor = "white"
        dataPointsColor = "grey"
        barColor = "grey"
        errorColor = "white"
    }

  return (

    <div>

    <Card title = "Coefficients" 
      style={{ width: plotWidth, float: 'left',  marginLeft: 10, marginBottom: 10, height: plotHeight }}
      extra={<Button type="link" onClick={() => setFitSummaryVisible(true)} data-testid="summary-button">Summary</Button>}
    >

      { props.loading && 
        <Skeleton paragraph={{ rows: 6 }} active />
      }
      
      { !props.loading && 
        <div>

            <Plot
              data={[
                {
                    x: props.statModel.mlrCoefficientsNames,
                    y: props.statModel.mlrCoefficients,
                    type: 'bar',
                    mode: 'markers',
                    //text: props.statModel.pcaLoadingsNames,
                    marker: {color: barColor, size: 15},
                    
                    error_y: {
                      type: 'data',
                      array: props.statModel.mlrCoefficientsErrors,
                      visible: errorBarsVisible,
                      thickness: 0.5,
                      color: errorColor,
                  
                    },
                }]}
              useResizeHandler={false}
              style={{ width: "100%" }}
              

              layout={
                {
                  autosize: true,
                  height: plotFractiom * plotHeight,
                  margin: {
                    l: 50,
                    r: 20,
                    b: 80,
                    t: 40
                  },
                  
                  plot_bgcolor: plotBgColor,
                  paper_bgcolor: plotBgColor,

                  xaxis: {
                    //mirror:true,
                    linewidth:1,
                    color: axisColor,

                  },
                  yaxis: {
                    title: "Coefficients",
                    linewidth:1,
                    color: axisColor,
                  },
                  legend: {
                    borderwidth: 1,
                  },

                }}
              config={{
                displaylogo: false,
                responsive: true,
                toImageButtonOptions: {
                  format: 'svg', // one of png, svg, jpeg, webp
                  filename: 'MLR coefficients plot',
                  scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                },
              }}
            />

        </div>
      }
 

    </Card>

<Card title = "Predictions" style={{ width: plotWidth, float: 'left' , marginLeft: 10, marginBottom: 10, height: plotHeight }}>

  { props.loading && 
    <Skeleton paragraph={{ rows: 6 }} active />
  }

  { !props.loading &&

    <div>

      <Plot
      data={
        [
        {
            x: props.statModel.y,
            y: props.statModel.yPred,
            type: 'scatter',
            mode: 'markers',
            text: props.statModel.yPredNames,
            marker: {color: dataPointsColor, size: 15},
        }, 
        {
          x: [0, yMax],
          y: [0, yMax],
          type: 'scatter',
          mode: 'lines',
          line: {
            color: 'grey',
            width: 1,
            dash: 'dashdot'
          }
        }

      ]}
      useResizeHandler={false}
      style={{ width: "100%" }}

      layout={
        {
          autosize: true,
          height: plotFractiom * plotHeight,
          margin: {
            l: 50,
            r: 20,
            b: 80,
            t: 40
          },
          plot_bgcolor: plotBgColor,
          paper_bgcolor: plotBgColor,
          xaxis: {
            title: props.statModel.yName + " (actual)",
            linewidth:0.5,
            color: axisColor,
            //range:[yMin, yMax],
          },
          yaxis: {
            title: props.statModel.yName + " (predicted)",
            linewidth:0.5,
            color: axisColor,
            //range:[yMin, yMax],
          },
          //disable the legend
          showlegend: false,
          annotations: [
            {
              x: 0.5,
              y: 1.05,
              xref: 'paper',
              yref: 'paper',
              text: 'R<sup>2</sup> = ' + props.statModel.mlrScore?.toFixed(2) + " (N=" + props.statModel.y.length + ")",
              showarrow: false,
              font: {
                family: 'Arial, sans-serif',
                size: 14,
                color: 'grey',
              },
            },
          ],
          /*shapes: [
            {
              type: 'line',
              yref: 'paper',
              xref:  'paper',
              x0: 0,
              y0: 0,
              x1: 1,
              y1: 1,
              line: {
                color: 'black',
                width: 1,
                dash: 'dashdot'
              }
            }
          ]*/



        }}
      config={{
        displaylogo: false,
        responsive: true,
        toImageButtonOptions: {
          format: 'svg', // one of png, svg, jpeg, webp
          filename: 'MLR predictions plot',
          scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        },
      }}
      />

    </div>

    
    }

</Card>

<Modal 
  title="Ordinary Least Squares (OLS) Regression Results"
  open={fitSummaryVisible} 
  onOk={() => setFitSummaryVisible(false)} 
  onCancel={() => setFitSummaryVisible(false)}
  width={900}
  footer={[
    <Button key="back" onClick={() => setFitSummaryVisible(false)} data-testid="close-modal">
      Close
    </Button>,
  ]}
>
  {/* make sure to display tabs and newlines correctly */}
  <pre style={{whiteSpace: 'pre-wrap'}}>{props.statModel.mlrResultsSummary}</pre>
</Modal>

</div>

  )

}

export default MLRPlot;
