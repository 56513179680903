import {useState} from 'react';
import { useMutation, gql } from '@apollo/client';
import { message, Form, Button, Space} from 'antd';
import { Link } from "react-router-dom";
import SelectAnalysis from '../analysis/SelectAnalysis';
import { useNavigate } from "react-router-dom";

const SET_SELECTED_PROCESSES = gql`
    mutation setSelectedProcesses ($input:SetSelectedProcessesInputType!) {   
        setSelectedProcesses(input:$input) {
            selectedProcesses  {
                id
                name
            }
        }
    }
`;

const UPDATE_USER_PROFILE = gql`
    mutation updateUserProfile ($input:UpdateUserProfileInputType!) {   
        updateUserProfile(input:$input) {
            userProfile {
                id
                selectedAnalysis {
                    id
                }
            }
        }
    }
`;
                    
interface AddProcessesToAnalysisProps {
    teamId: string
    processIds: string[]
}

function AddProcessestoAnalysis(props: AddProcessesToAnalysisProps) {

    const navigate = useNavigate();

    const [analysisType, setAnalysisType] = useState("existing")
    const [analysisId, setAnalysisId] = useState("")

    const [setSelectedProcesses] = useMutation(SET_SELECTED_PROCESSES, {
        onError(error) { message.error(error.message) },
        onCompleted(data) {
            message.success("Processes were added to the analysis.")
        }
    });

    const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
        onError(error) { message.error(error.message) },
        onCompleted(data) {
            //message.success("Analysis was updated.")
            navigate("/process-explorer")
        }
    });


    function addImportedProcessestoAnalysis() {
        let input = {
            'processIds': props.processIds,
            'analysisId': analysisId,
            'modeIsAdd': true,

        }

        setSelectedProcesses({ variables: { input: input } })
    }

    function openAnalysis() {
        // set the analysis as the selected analysis
        let input = {
            'selectedAnalysisId': analysisId
        }
        updateUserProfile({ variables: { input: input } })
    }

    const formLayout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 6 },
    };


    return (
        <div>
            <Form {...formLayout} labelAlign="left" >
            
            {/*
              <Form.Item label="New or existing analysis:" >
                <Radio.Group value={analysisType} onChange={onChangeAnalysisType} data-testid="analysis-radio-group">
                  <Radio value="existing" data-testid="existing-analysis-radio">Add to an existing analysis</Radio>
                  <Radio value="new" data-testid="new-analysis-radio">Create a new analysis</Radio>
                </Radio.Group>
              </Form.Item>
            */}

              {analysisType == "existing" &&
                <Form.Item label="Analysis:">
                  <SelectAnalysis 
                    teamId = {props.teamId}
                    analysisId={analysisId}
                    setAnalysisId={setAnalysisId}
                  />
                </Form.Item>
              }
  
              <Form.Item label="Add to analysis">
                <Space direction="horizontal">
                  <Button type="default" data-testid="add_to-analysis" onClick={addImportedProcessestoAnalysis}>1: Add process(es) to analysis</Button>
                  
                  
                  
                    <Button type="default" data-testid="analysis-link-button" onClick={openAnalysis}>2: Open analysis in Process Explorer</Button>
                  
                  

                </Space>
              </Form.Item>


            </Form>

        </div>
    );
}

export default AddProcessestoAnalysis;