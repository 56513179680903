import { useState } from 'react';
import { Card, Input, Tabs, Skeleton} from 'antd';
import PageHeader from "../components/PageHeader";
import {useParams} from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from "../components/useWindowDimensions";
import PCAPlot from '../components/modeling_toolbox/PCAPlot';
import MLRPlot from '../components/modeling_toolbox/MLRPlot';
import ModelMenu from '../components/modeling_toolbox/ModelMenu';

import ReactGA from 'react-ga';


const { TextArea } = Input;
const { TabPane } = Tabs;

const GET_STAT_MODEL = gql`
    query statModel ($id:ID!) {  
        statModel(id:$id) {
            id
            name
            description
            modelfunction

            mlrCoefficients
            mlrCoefficientsNames
            mlrCoefficientsErrors
            mlrResultsSummary
            mlrScore
            yPred
            yPredNames
            y
            yName

            pcaScores
            pcaLoadings
            pcaScoresNames
            pcaLoadingsNames
            pcaVarianceRatio
            kpiDefinition {
                id
                name
                kpiSet {
                    id
                    name
                }
            }
        }
        userProfile {
            id
            darkMode
            selectedAnalysis {
                id
                name
            }
        }
    }
`;





function Model() {

    ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    
    // get the url parameters from react router
    let params:any = useParams();
    
    const [axes, setAxes] = useState([0, 1, -1]); // x, y, z corresponds to the order of components in the PCA plot

    // window dimensions
    const { height, width } = useWindowDimensions();

    // apollo hooks
    const { loading, error, data } = useQuery(GET_STAT_MODEL , 
        {variables:{id:params.id},
        fetchPolicy: "cache-and-network",
    });
    

    /* ************************************************************* */

    if (error) return ( 
        <Card>Error: {error.message} </Card>
    );

    if (loading && !data) return (
        <Card><Skeleton active/></Card>
    );

 
    {/* ******************************************************************************** */}
    {/* ******************************************************************************** */}

    const routes = [
        {
            path: '',
            breadcrumbName: 'Analysis: ' + data.userProfile.selectedAnalysis.name + ' / '
        },
        {
          path: 'modeling',
          breadcrumbName: 'Modeling',
        },
        {
          path: '',
          breadcrumbName: data.statModel.name ,
        }
      ];

    const layout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 8 },
    };

    function recompute () {
        //updateStatModel({variables:{id:params.id}});
    }


    
    return(  

        <div>
            <PageHeader
                title={data.statModel.name}
                subTitle={ data.statModel.description }
                breadcrumb={{routes}}
                extra={ <HeaderExtra/> }
            />

            <div style={{ display:'flex' }}>

                <div style = {{ flex:'0 0 220px' }}>
                    <div style={{height:height-165 , overflow:"auto"}} >
                        <ModelMenu modelId = { params.id } recompute = {recompute} axes = {axes} setAxes = {setAxes} />
                    </div>
                    
                </div>

                <div style={{ overflow:'auto' , width:"100%"}} >

                    { /* if the modelfunction is pca */}
                    { data.statModel.modelfunction === 'PCA' &&
                        <PCAPlot 
                            statModel = {data.statModel} 
                            loading={loading && !data} 
                            axes = {axes} 
                            darkMode = {data?.userProfile.darkMode}
                        />
                    }

                    { /* if the modelfunction is mlr */}
                    { (data.statModel.modelfunction === 'MLR' || data.statModel.modelfunction === 'PLS') &&
                        <div>
                            <MLRPlot 
                                statModel = {data.statModel}
                                loading={loading && !data}
                                darkMode = {data?.userProfile.darkMode}
                            />
                        </div>
                    }


                </div>


            </div>

        </div>
    )

}

export default Model;