import {useState, useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Card , Select, Button, Form, Skeleton, Modal, message, Radio, Upload, Alert } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { pollInterval } from '../..';
import { CORE_DATA_STANDARD_FIELDS } from '../fragments';

const {Option} = Select;

const COPY_DATA_STANDARDS = gql`
    mutation copyDataStandards($input: CopyDataStandardsInputType!){
        copyDataStandards(input:$input) {
            ok
        }
    }
`;

const ALL_TOPICS = gql`
    ${CORE_DATA_STANDARD_FIELDS}
    query topics {
        topics {
            id
            name
            datastandardSet {
                ...CoreDataStandardFields
            }
            team {
                id
                name
            }
        }
        teams {
            id
            name
            datastandardSet {
                ...CoreDataStandardFields
            }
        }
        userProfile {
            id
            organization {
                id
                name
                datastandardSet {
                    ...CoreDataStandardFields
                }
            }
        }
    }
`

const GET_DATA_STANDARDS = gql`
    ${CORE_DATA_STANDARD_FIELDS}
    query dataStandards($topicId: ID, $teamId: ID, $organization: Boolean) {
        dataStandards(topicId: $topicId, teamId: $teamId, organization: $organization) {
            ...CoreDataStandardFields
            team {
                id
                owner {
                    id
                    username
                }
            }
        }
    }
`;

interface CopyDataStandardsInputType {
    targetTopicId?:String,
    targetTeamId?:String,
    isTargetOrganization?:Boolean,
    visible:boolean,
    setVisible:Function
};

// default props
CopyDataStandards.defaultProps = {
    targetTopicId: "",
    targetTeamId: "",
    isTargetOrganization: false,
};

// type for source can be either "topic" or "team" or "organization" or "yaml"
type Source = "topic" | "team" | "organization" | "yaml";

function CopyDataStandards(props:CopyDataStandardsInputType) {

    const [isSelectAll, setIsSelectAll] = useState(true);
    const [sourceTopicId, setSourceTopicId] = useState<string | undefined>();
    const [sourceTeamId, setSourceTeamId] = useState<string | undefined>();
    const [source, setSource] = useState<Source>("topic");
    const [selectedDataStandardIds, setSelectedDataStandardIds] = useState([] as any);
    const [dataStandardSet, setDataStandardSet] = useState([] as any);
    const [yamlFile, setYamlFile] = useState<any>("");

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery( ALL_TOPICS , { 
        fetchPolicy: "cache-and-network",
        pollInterval: pollInterval, 
    } );
    
    //const {loading: copyLoading, error: copyError, data: copyData} = useQuery( GET_DATA_STANDARDS, { variables: { topicId:props.targetTopicId, teamId:props.targetTeamId, organization:props.isTargetOrganization } } );

    // add loading
    const [copyMutation, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(COPY_DATA_STANDARDS, {
        refetchQueries: [ 
            { query: GET_DATA_STANDARDS, variables: { topicId:props.targetTopicId, teamId:props.targetTeamId, organization:props.isTargetOrganization } },
            { query: ALL_TOPICS }
        ],
        onCompleted(data) { 
            message.success( "Import of lexicon entries is running in the background.")
            // close the modal
            props.setVisible(false); 
        } ,
        onError(error) {message.error(error.message)}, 
    });

    // use Effect to set  the dataStandardSet
    useEffect(() => {
        if (queryData) {
            let dataStandards:any = [];
            if (source == "topic") {
                for (let i=0; i<queryData.topics.length; i++) {
                    if (queryData.topics[i].id == sourceTopicId) {
                        dataStandards = queryData.topics[i].datastandardSet;
                    }
                }
            } else if (source == "team") {
                for (let i=0; i<queryData.teams.length; i++) {
                    if (queryData.teams[i].id == sourceTeamId) {
                        dataStandards = queryData.teams[i].datastandardSet;
                    }
                }
            } else if (source == "organization") {
                dataStandards = queryData.userProfile.organization.datastandardSet;
            }
            setDataStandardSet(dataStandards);
        }
    }, [queryData, source, sourceTopicId, sourceTeamId])

    // use Effect to set the data standard ids
    useEffect(() => {
        if (isSelectAll == true) {
            let dataStandardIds:any = [];
            for (let i=0; i<dataStandardSet.length; i++) {
                dataStandardIds.push(dataStandardSet[i].id);
            }
            setSelectedDataStandardIds(dataStandardIds);
        } else {
            setSelectedDataStandardIds(null);
        }
    }, [isSelectAll, dataStandardSet])
 
    const formLayout = {
        labelCol: { span: 6 },
    };

    function handleCancel() {
        props.setVisible(false);
        setSelectedDataStandardIds(null);
        setSourceTopicId(undefined);
        setSourceTeamId(undefined);
        setSource("topic");
        setIsSelectAll(true);
    }

    function handleTopicChange(value:any) {
        setSourceTopicId(value);
        setSelectedDataStandardIds(null);
    }

    function handleTeamChange(value:any) {
        setSourceTeamId(value);
        setSelectedDataStandardIds(null);
    }

    function handleSourceChange(value:any) {
        setSourceTopicId(undefined);
        setSourceTeamId(undefined);
        setSource(value.target.value);
        setSelectedDataStandardIds(null);
        
    }

    function onChangeIsSelectAll(e:any) {
        setIsSelectAll(e.target.value);
    }

    function onSelectYamlFile(value: any) {
        setYamlFile(value.file);
    }

    // copy the data standards
    function handleOk() {

        let input:any = {};

        if (props.targetTopicId != "") {

            input = {
                targetTopicId: props.targetTopicId,
                dataStandardIds: selectedDataStandardIds,
                yamlFile: yamlFile,
            };

        } else if (props.targetTeamId != "") {
                
            input = {
                targetTeamId: props.targetTeamId,
                dataStandardIds: selectedDataStandardIds,
                yamlFile: yamlFile,
            };
    
         } else if (props.isTargetOrganization != false) {
                    
            input = {
                targetOrganization: props.isTargetOrganization,
                dataStandardIds: selectedDataStandardIds,
                yamlFile: yamlFile,
            };
        }

        // perofrm the mutation
        copyMutation({ variables: { input: input } });
        
        // close the modal
        //props.setVisible(false);
    }


    if (queryError) return ( 
        <Card>Error: {queryError.message} </Card>
    );

    if (queryLoading && !queryData) return (
        <Card><Skeleton active/></Card>
    );

    // create the options for the topic select
    // each queryData.topics is an option. the team of each topic is the group
    
    let topicOptions:any = [];

    // get the teams
    let teams:any = [];
    for (let i=0; i<queryData.topics.length; i++) {
        if (!teams.includes(queryData.topics[i].team.name)) {
            teams.push(queryData.topics[i].team.name);
        }
    }

    for (let i=0; i<teams.length; i++) {
        // get the topics for each team
        let topics:any = [];
        for (let j=0; j<queryData.topics.length; j++) {
            if (queryData.topics[j].team.name == teams[i]) {
                // if the topic is not the target topic, add it to the options
                if (queryData.topics[j].id != props.targetTopicId) {
                    topics.push(queryData.topics[j]);
                }
            }
        }
        // create the options for each team
        let teamOptions:any = [];
        for (let j=0; j<topics.length; j++) {
            teamOptions.push({ label: topics[j].name, value: topics[j].id });
        }
        
        topicOptions.push({ label: 'Team: ' + teams[i], options: teamOptions });
    }

    return (
        <Modal 
            title= "Import lexicon entries"
            open={ props.visible } 
            onOk = { handleOk }
            onCancel = { handleCancel }
            data-testid="importLexiconEntriesModal"
            destroyOnClose = {true}
            width = {600}

            footer={[
                <Button 
                    type="primary" 
                    form="CalculatorSaveCopy" 
                    key="submit" 
                    htmlType="submit" 
                    onClick={handleOk} 
                    data-testid="submitImportLexiconEntriesModal" 
                    disabled={ (source == "topic" && sourceTopicId == undefined) || (source == "team" && sourceTeamId == undefined) || (isSelectAll == false && selectedDataStandardIds == null) }
                >
                    Submit
                </Button>
                ]}
            >

            {mutationLoading && <Skeleton active/>}
            {mutationError && <Card>Error: {mutationError.message}</Card>}
            {!mutationLoading && 

            <div>
                <br/>
                <Alert message="The import of lexicon entries will overwrite existing entries with the same name. Other names will also be overwritten." type="warning" showIcon />
                <br/>

                <Form
                    {...formLayout}  
                    id = "CalculatorSaveCopy"
                > 
                    <Form.Item label="Source:" name="source-0" rules={[{ required: true}]} >
                        <Radio.Group value={source} onChange={handleSourceChange} defaultValue={"topic"} data-testid="sourceRadioGroup">
                            <Radio value="topic" data-testid="topicRadio">Collection</Radio>
                            <Radio value="team" data-testid="teamRadio">Team</Radio>
                            <Radio value="organization" data-testId="organizationRadio">Organization</Radio>
                            <Radio value="yaml" data-testId="yamlRadio">YAML file</Radio>
                        </Radio.Group>
                    </Form.Item>

                    { source == "topic" &&
                    <Form.Item label="From collection:" name="topic-source" rules={[{ required: true}]} >
                        <Select 
                            showSearch
                            options = {topicOptions} 
                            onChange={handleTopicChange}
                            value={sourceTopicId || undefined }
                            data-testid="topicSelect"
                            filterOption = {(input, option:any) =>
                                option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        
                        />
                    </Form.Item>
                    }

                    { source == "team" &&
                    <Form.Item label="From team:" name="team-source" rules={[{ required: true}]} >
                        <Select
                            showSearch
                            onChange={handleTeamChange}
                            value={sourceTeamId || undefined}
                            data-testid="teamSelect"
                            filterOption={(input, option:any) =>
                                option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        
                        >
                            {/* queryData.teams.map( (team:any) => { return <Option key={team.id} value={team.id}> {team.name} </Option> })*/}
                            {/* do not show the team as a source if it is the same as the target team */}
                            {queryData.teams.map( (team:any) => { if (team.id != props.targetTeamId) { return <Option key={team.id} value={team.id}> {team.name} </Option> } })}

                        </Select>

                    </Form.Item>
                    }

                    { source != "yaml" &&
                        <Form.Item label="Import:" required >
                            <Radio.Group value={isSelectAll} onChange={onChangeIsSelectAll} defaultValue={isSelectAll} data-testid="importRadioGroup">
                                <Radio value={true} data-testid="allEntries">All entries</Radio>
                                <Radio value={false} data-testid="selectedEntries">Selected entries</Radio>
                            </Radio.Group>
                        </Form.Item>
                    }
                    

                    {(isSelectAll == false && source != "yaml") && 
                        <Form.Item label="Lexicon entries:" required >
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select entries"
                                value={selectedDataStandardIds || undefined}
                                onChange={value => setSelectedDataStandardIds(value)}
                                // empty the selected data standards when the selectedDataStandardIds is empty
                                onClear={() => setSelectedDataStandardIds(null)}
                                data-testid="lexiconEntriesSelect"
                                optionLabelProp="label"
                                // search
                                showSearch
                                filterOption={(input, option:any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }

                            >
                                {dataStandardSet.map((dataStandard:any) => (
                                    <Option key={dataStandard.id} value={dataStandard.id} label={dataStandard.name} title={dataStandard.name} data-testid={"lexiconEntryOption-" + dataStandard.name}>
                                        {dataStandard.name + " (" + dataStandard.type + ")"}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }

                    {source == "yaml" &&
                        <Form.Item label="YAML file:" required>
                            <Upload 
                                type="select" 
                                accept='.yaml' 
                                maxCount={1} 
                                customRequest={onSelectYamlFile}
                                showUploadList={{
                                    showDownloadIcon: false,
                                    showRemoveIcon: true,
                                    showPreviewIcon: false, 
                                  }}

                                // in beforeUpload set the status to done 
                                beforeUpload={(file) => {
                                    setYamlFile(file);
                                    return false;
                                }}
                                data-testid="select-file-upload"
                            >
                                <Button icon={<UploadOutlined />} data-testid="select-file-button">Select a .yaml file</Button>
                            </Upload>
                        </Form.Item>
                    }

                </Form>
            </div>

            }

        </Modal>
    )


}

export default CopyDataStandards;
