import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Alert , Space, Popconfirm, Button, Form, Modal, Input, message, Tabs, Popover} from 'antd';
import { SearchOutlined, LinkOutlined} from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import VariablePlot from './VariablePlot';
import { pollInterval } from '../..';

import { CORE_VARIABLE_FIELDS, CORE_PROCESS_FIELDS } from '../fragments';

const { TabPane } = Tabs;

const CREATE_VARIABLE = gql`
    ${CORE_VARIABLE_FIELDS}
    mutation createVariable($input: CreateVariableInputType!) {
        createVariable( input:$input) {
            variable {
                ...CoreVariableFields
            }
        }
    }
`;

const UPDATE_VARIABLE = gql`
    ${CORE_VARIABLE_FIELDS}
    mutation updateVariable($input: UpdateVariableInputType!) {
        updateVariable( input:$input) {
            variable {
                ...CoreVariableFields
            }
        }
    }
`;

const DELETE_VARIABLE = gql`
    mutation deleteVariable($id: ID!) {
        deleteVariable( id:$id) {
            id
            ok
        }
    }
`;

const GET_PROCESS_VARIABLE = gql`
    ${CORE_PROCESS_FIELDS}
    ${CORE_VARIABLE_FIELDS}
    query Process ($id:ID!) {  
        process(id:$id) {
            ...CoreProcessFields
            variableSet {
                ...CoreVariableFields
            }
        }
    }
`;

const CREATE = CREATE_VARIABLE;
const READ = GET_PROCESS_VARIABLE;
const UPDATE = UPDATE_VARIABLE;
const DELETE = DELETE_VARIABLE;

const objectName = "Variable";

interface VariableTableInputType {
    processId:number | string,
};

function VariableTable(props:VariableTableInputType) {

    // get the url parameters from react router
    // let params:any = useParams(); // not needed anymore

    const { height, width } = useWindowDimensions();

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [isVariableModalVisible, setVariableModalVisible] = useState(false);
    const [variableModalVariableId, setVariableModalVariableId] = useState("");
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", units:"", description:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState("0");
    const [nameSearchText, setNameSearchText] = useState("");
    const [descriptionSearchText, setDescriptionSearchText] = useState("");
    const [isVariableEditable, setIsVariableEditable] = useState(true);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , 
        {
            variables:{id:props.processId}, 
            //pollInterval: 3000,
            fetchPolicy: 'cache-and-network'
        })

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ, variables: { id: props.processId } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError } ] = useMutation(UPDATE_VARIABLE, {
        refetchQueries: [ { query: READ, variables: {id: props.processId  } } ], 
        onCompleted(data) { message.success( "Updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ, variables: { id: props.processId }}],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------

        // if queryData is null, then return an empty table
        if (queryData == undefined || queryData == null || queryData.process == undefined || queryData.process == null) {
            return (
                <Table columns={[]} dataSource={[]} />
            )
        }
    
        if (queryError) {
            return (
                <Alert
                    message="Error"
                    description={queryError.message}
                    type="error"
                    showIcon
                />  
            )
        }
    
    const showModal = (values:any) => {
        setModalTitel("Add")
        setModalInitialValues({ name:"", units:"", description:"" } )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Add") {
    
            let input = {
                name : values['name'],
                units: values['units'],
                description: values['description'],
                processId:props.processId
            };
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                units: values['units'],
                description: values['description']
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit")
        
        setModalInitialValues({ 
            name:input.name,
            units:input.units,
            description:input.description
        })

        // if the variable is calculated or associated with a data standard, then it cannot be edited
        if (input.vartype == "calculated") {
            setIsVariableEditable(false)
        } else {
            setIsVariableEditable(true)
        }
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:String) => {   
        deleteMutation({ variables: { id: id  } } )
        setModalVisible(false);
    } 

    function showVariableModal(record:any) {
        setVariableModalVariableId(record.id)
        setVariableModalVisible(true);
    }

    const handleSearch = (selectedKeys:any, confirm:any) => {
        confirm();
        setNameSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        setNameSearchText('');
    };

    const handleSearchDescription = (selectedKeys:any, confirm:any) => {
        confirm();
        setDescriptionSearchText(selectedKeys[0]);
    };

    const handleResetDescription = (clearFilters:any) => {
        clearFilters();
        setDescriptionSearchText('');
    };

    const formLayout = {
        labelCol: { span: 4 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 12 },
    };

    // return a list of unique units
    const getUnitFilters = () => {
        let uniqueUnits:any = []
        let unitFilters:any = []
        queryData?.process.variableSet.forEach((variable:any) => {
            if (variable.units != null) {
                if (!uniqueUnits.includes(variable.units)) {
                    uniqueUnits.push(variable.units)
                    unitFilters.push( {text: variable.units, value: variable.units} )
                }
            }
        })
        // sort unitFilters alphabetically without considering the case

        return unitFilters.sort((a:any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
    }

    // for filters generate a list of unique calculator names
    const getCalculatorFilters = () => {
        let uniqueCalculators:any = []
        let calculatorFilters:any = []
        queryData?.process.variableSet.forEach((variable:any) => {
            if (variable.calculator != null) {
                if (!uniqueCalculators.includes(variable.calculator.name)) {
                    uniqueCalculators.push(variable.calculator.name)
                    calculatorFilters.push( {text: variable.calculator.name, value: variable.calculator.name} )
                }
            }
        })
        // sort calculatorFilters alphabetically without considering the case
        return calculatorFilters.sort((a:any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
    }

    const TableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            //width: '30%',
            render: (text:String, record:any) => (
                <div>
                    {/* <Link to={'/processes/process/' +  props.processId  + '/variable/'+ record.id } >{text}</Link> */}
                    <div>{text}</div>
                </div>
            ),
            
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="parameter-name-search"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="parameter-name-search-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleReset(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="parameter-name-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Units',
            dataIndex: 'units',
            editable: false,
            //width: '20%',
            filters: getUnitFilters(),
            onFilter: (value: any, record: any) =>  record.units == value,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            //width: '30%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search description"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchDescription(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="parameter-description-search"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchDescription(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="parameter-description-search-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleResetDescription(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="parameter-description-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value: any, record: any) => {
                if (record.description == null) {
                    return false
                }
                return record.description.toLowerCase().includes(value.toLowerCase())
            },
        },
        {
            title: 'Type',
            dataIndex: 'vartype',
            editable: false,
            //width: '20%',
            filters: [
                {
                    text: 'Calculated',
                    value: 'Calculated',
                },
                {
                    text: 'Measured',
                    value: 'Measured',
                },
            ],
            // he record.vartype is all lowercase, so we need to convert the value to lowercase before comparing
            onFilter: (value: any, record: any) =>  record.vartype.toLowerCase() == value.toLowerCase(),
            render : (text:any, record:any) => {
                if (record.vartype == "measured") {
                    return (
                        <div>Measured</div>
                    )
                } else if (record.vartype == "calculated") {
                    return (
                        <div>Calculated</div>
                    )
                } else {
                    return (
                        <div>Unknown</div>
                    )
                }
            }
        },
        {
            title: 'Calculator',
            dataIndex: ['calculator', 'name'],
            editable: false,
            //width: '20%',
            
            filters: getCalculatorFilters(),
            onFilter: (value: any, record: any) =>  record?.calculator?.name == value,
            
        },
        {
            title: 'Lexicon',
            name: 'Lexicon',
            key: 'lexicon',
            width: "5%",
            render : (text:any, record:any) => {
                if (record.dataStandard == null) {
                    return null
                } else {

                    let level = record.dataStandard.level
                    if (record.dataStandard.level == "Topic") {
                        level = "Collection"
                    }

                    return (
                        <Popover content={(
                            <Space direction="vertical">
                                <p>{record.dataStandard.name} ({level})</p>
                            </Space>
                        )}>
                            <Button size="small" type="link" style={{paddingLeft:20 }} data-testid={"parameter-lexicon-button-" + record.name} >
                                <LinkOutlined />
                            </Button>
                        </Popover>
                    )
                }
            },
            filters: [
                {
                    text: 'Lexicon',
                    value: 'Lexicon',
                },
            ],
            onFilter: (value: any, record: any) =>  {
                if (record.dataStandard == null) {
                    return false
                } else {
                    return true
                }
            }
        },
        /*  
        {
            title: 'Actions',
            key: 'actions',
            width: '10%',
            render: (text:String, record:any) => (

                
                <div>

                    { record.vartype=="measured"  &&  // only measured variables should be editable

                        <Space>
                            <Button type="link" onClick={()=> handleEdit(record)} >
                                Edit
                            </Button>
                            <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                                <a>Delete</a>
                            </Popconfirm>
                        </Space>
                    }
                </div>

                
            ),
        },
        */
    ]




    return (

        <div>
                {/*
                <Button
                    style = {{ marginBottom: 16 }}
                    onClick = {  showModal  }                            
                >
                    Create a variable
                </Button>
                */}
                
                <Table 
                    loading={queryLoading && !queryData}
                    dataSource={queryData.process.variableSet}
                    columns={ TableColumns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    pagination={false}
                    scroll={{ y: height-350 }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {handleEdit(record)},
                            // change the mouse cursor to pointer when hovering over the row
                            onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                            onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                        };
                    }}
                />
                        
            <Modal 
                //title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                
                /*footer={[
                    <Button type="primary" form="VariableForm" key="submit" htmlType="submit">
                        Submit
                    </Button>
                    ]}
                */
               footer = {null}
                width={750}
            >

                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Variable data" key="1" data-testid="variable-data-tab">

                        <VariablePlot id = {modalId}/>

                    </TabPane>
                    
                    
                    <TabPane tab="Edit variable" key="2" data-testid="variable-edit-tab">

                

                        <Form
                            {...formLayout}  
                            id = "VariableForm"
                            onFinish={handleModalOk} 
                            initialValues={ modalInitialValues }
                        > 
                            <Form.Item 
                                    label="Name:" 
                                    name="name" 
                                    rules={[
                                        { required: true, message: 'Please enter a name.' }, 
                                        { max: 200, message: 'Name must be 200 characters or less.' },
                                        { pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/, message: 'Name must start with a letter or underscore and can only contain letters, numbers, and underscores.' }
                                    ]} 
                                    validateFirst={true}
                                    validateTrigger={"onBlur"}
                                >
                                <Input maxLength={200} disabled={!isVariableEditable} title={isVariableEditable ? "" : "The variable cannot be edited because either it is calculated or it is associated with a data standard."} data-testid="variable-name-input"/>
                            </Form.Item>

                            <Form.Item label="Units:" name="units" rules={[{ required: true, message: 'Please enter a unit.' }]} >
                                <Input maxLength={200}  disabled={!isVariableEditable} data-testid="variable-units-input"/>
                            </Form.Item>

                            <Form.Item label="Description:" name="description" rules={[{ required: false }]} >
                                <Input maxLength={1000} showCount  disabled={!isVariableEditable} data-testid="variable-description-input"/>
                            </Form.Item>

                        </Form>

                        {/* Create a delete and submit button at the end of this tab and make them aligh right */}
                        <div style={{textAlign: 'right'}}>
                            
                            <Popconfirm title="Are you sure you want to delete this variable?" onConfirm={() => handleDelete(modalId)} disabled={!isVariableEditable || queryData.process.topic.userPermissions == "read"}>
                                <Button 
                                    type="primary" 
                                    ghost 
                                    style={{marginLeft: 10}} 
                                    title="Delete this variable"
                                    disabled={!isVariableEditable || queryData.process.topic.userPermissions == "read"}
                                    data-testid="variable-delete-button"
                                >
                                    Delete
                                </Button>
                            </Popconfirm>

                            <Button type="primary" form="VariableForm" key="submit" htmlType="submit" onClick={handleModalOk} style={{marginLeft: 10}} disabled={!isVariableEditable || queryData.process.topic.userPermissions == "read"} data-testid="variable-submit-button">
                                Submit
                            </Button>
                            
                        </div>

                        

                    </TabPane>
                    
                </Tabs>
            </Modal>
            {/*
            <VariableModal variableId={variableModalVariableId} isVariableModalVisible={isVariableModalVisible} setIsVariableModalVisible={setVariableModalVisible} />
            */}

        </div>

    );
}



export default VariableTable;

