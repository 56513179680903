import  React, {useState, useEffect} from "react";
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import { UserOutlined, RobotOutlined , DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import HeaderExtra from "../components/HeaderExtra";
import { Skeleton , Card, Avatar, Button, Space, Input, message , Popconfirm, Tooltip}  from 'antd';
import PageHeader from "../components/PageHeader";
import { pollInterval } from "..";
import ReactMarkdown from 'react-markdown';

const  { TextArea } = Input;

const GET_CHATS = gql`
    query chats {
        chats {
            id
            title
            references
            creationDate
        }
        userProfile {
            id
            darkMode
        }
    }
    
`;

const GET_CHAT = gql`
    query chat($id: ID!) {
        chat(id: $id) {
            id
            title
            references
            messageSet {
                id
                message
                userType
                timestamp
                reviewer
            }
        }
    }
`;

const ADD_MESSAGE_TO_CHAT = gql`
mutation addMessageToChat($input: AddMessageToChatInputType!) {
    addMessageToChat(input: $input) {
        chat {
            id
            title
            references
            messageSet {
                id
                message
                timestamp
                userType
                reviewer
            }
        }
    }
}
`;

const DELETE_CHAT = gql`
mutation deleteChat($id: ID!) {
    deleteChat(id: $id) {
        ok
    }
}
`;
                

function AI() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });

    //const { height, width } = useWindowDimensions();
    const [selectedChat, setSelectedChat] = useState(0);
    const [userMessage, setUserMessage] = useState("");

    const { loading:queryLoading, error:queryError, data:queryData } = useQuery(GET_CHATS);

    // use a lazy query to get the chat messages
    const [getChat, { loading:queryChatLoading, error:queryChatError, data:queryChatData }] = useLazyQuery(GET_CHAT, { pollInterval: pollInterval });

    // mutation to add a message to a chat
    const [addMessageToChatMutation, { loading:mutationLoading, error:mutationError, data:mutationData }] = useMutation(ADD_MESSAGE_TO_CHAT, 
        {
            // refetch
            refetchQueries: [
                { query: GET_CHATS },
            ],
        }
    );

    // mutation to delete a chat
    const [deleteChatMutation, { loading:deleteMutationLoading, error:deleteMutationError, data:deleteMutationData }] = useMutation(DELETE_CHAT,
        {
            // refetch
            refetchQueries: [
                { query: GET_CHATS },
            ],
            onCompleted: () => {
                setSelectedChat(0);
            }
        }, 
        
    );

    // use effect to get the chat messages when the selected chat changes
    useEffect(() => {
        if (selectedChat === 0) {
            return
        }
        getChat({ variables: { id: selectedChat } });
    }, [selectedChat]);

    // use effect to scroll to the bottom of the chat when the messages change
    useEffect(() => {
        if (selectedChat === 0) {
            return
        }
        var element = document.getElementById("2-1");
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }, [queryChatData]);

    // use effect to select the first chat when a new chat is added
    useEffect(() => {
        if (queryData && queryData.chats.length > 0) {
            setSelectedChat(queryData.chats[0].id);
        }
    }, [queryData]);


    function onChangeSelectedChat(id: any) {
        //console.log(id);
        setSelectedChat(id);
    }

    if (queryError) {
            message.error("Unable to get the chats")
    }

    if (queryLoading) {
        return (
            <Skeleton active />
        )
    }
  
    function onSubmitMessage() {
        if (selectedChat === 0) {
            addMessageToChatMutation({ variables: { input: { message: replaceNumbersWithPubIds(userMessage, referenceMapping) } } });
        } else {
            addMessageToChatMutation({ variables: { input: { chatId: selectedChat, message: replaceNumbersWithPubIds(userMessage, referenceMapping) } } });
        }
        setUserMessage("");
    }

    let aIChatBackground = "#f9f9f9"
    if (queryData?.userProfile.darkMode) {
        aIChatBackground = "#1a1a1a"
    }

    interface Reference {
        pub_id: string;
        text: string;
    }
    
    const createReferenceMapping = (references?: string): Record<string, number> => {
        const referenceMap: Record<string, number> = {};
    
        if (references) {
            const referenceLines = references.split("\n\n");
    
            referenceLines.forEach((line, index) => {
                const pubIdMatch = line.match(/pub_id=(\d+)/);
                if (pubIdMatch) {
                    const pubId = pubIdMatch[1];
                    referenceMap[pubId] = index + 1;
                }
            });
        }
    
        return referenceMap;
    };
    
    const referenceMapping = queryChatData?.chat?.references ? createReferenceMapping(queryChatData.chat.references) : {};
    
    const replacePubIdsWithNumbers = (message?: string, referenceMap?: Record<string, number>): string => {
        if (!message || !referenceMap) {
            return message || '';  // Return the original message or an empty string if unavailable
        }
    
        return message.replace(/(\[)?pub_id=(\d+)(\])?/g, (_, openBracket, pubId) => {
            const referenceNumber = referenceMap[pubId];
            // Always return the reference number enclosed in brackets, regardless of original format
            if (referenceNumber !== undefined) {
                return `[${referenceNumber}]`;
            } else {
                // If not found in the referenceMap, return the original match enclosed in brackets
                return `[pub_id=${pubId}]`;
            }
        });
    };

    // function to replace numbers e.g. [2] with references e.g. [pub_id=102]
    const replaceNumbersWithPubIds = (message?: string, referenceMap?: Record<string, number>): string => {
        if (!message || !referenceMap) {
            return message || '';  // Return the original message or an empty string if unavailable
        }
    
        return message.replace(/\[(\d+)\]/g, (_, referenceNumber) => {
            const pubId = Object.entries(referenceMap).find(([_, number]) => number === parseInt(referenceNumber))?.[0];
            return pubId !== undefined ? `[pub_id=${pubId}]` : `[${referenceNumber}]`;
        });
    }
    

    const renderReferences = (references?: string, referenceMap?: Record<string, number>): string => {
        if (!references || !referenceMap) {
            return '';  // Return an empty string if references or referenceMap are unavailable
        }
    
        const referenceLines = references.split("\n\n");
    
        const formattedReferences = referenceLines.map((line, index) => {
            return line.replace(/pub_id=(\d+)/, (match, pubId) => {
                return `${referenceMap[pubId]}.`;
            });
        });
    
        return formattedReferences.join('\n\n');  // Join all references into a single string
    };
   
    /*
    let test_data = "Additionally, pH levels can range from 3 to 10, but values closer to 6 are most commonly recommended for lipid biosynthesis [pub_id=102]. The optimal temperature for lipid biosynthesis is 30°C [pub_id=103].";
    let test_references = "pub_id=102: Smith et al. 2020\n\npub_id=103: Johnson et al. 2021";
    let test_reference_mapping = createReferenceMapping(test_references);
    let test_result = replacePubIdsWithNumbers(test_data, test_reference_mapping);
    let test_result2 = renderReferences(test_references, test_reference_mapping);
    console.log(test_result);
    console.log(test_result2);
    */
    
    return(  

        <div>
            
            <PageHeader
                //onBack={() => window.history.back()}
                title="Biotech Research Assistant"
                extra={ <HeaderExtra/> }
            />

            <div style={{ display:'flex' }}>

                
                <div style = {{ flex:'0 0 400px' }} id="1">
                    <div style={{height: 'calc(100vh - 125px)'}}>
                        <Card title="Chats" style={{ marginRight:20 , marginBottom:10, height:'calc(100vh - 125px)' }} size="small">
                            {/* Button to create a new chat */}

                            <Button type="text" style={{ marginBottom:10 , width: 370 }} onClick={() => { setSelectedChat(0) }} data-testid="new-chat-button">New Chat</Button>

                            <div style={{ overflow:"scroll" , height:'calc(100vh - 250px)' }}>
                            {/* List of chats */}
                                { queryData.chats.length > 0 &&
                                    queryData.chats.map((chat: any) => (
                                        <Card id={chat.id} size="small" style={{marginBottom:5}} hoverable onClick={() => onChangeSelectedChat(chat.id)} >
                                            <Space direction="horizontal">
                                                <div style={{ width: 300, overflow:"hidden" , textOverflow:"ellipsis" , whiteSpace:"nowrap" }}>{chat.title}</div>
                                                <Popconfirm title="Delete?" onConfirm={() => { deleteChatMutation({ variables: { id: chat.id } }) }} okText="Yes" cancelText="No">
                                                    <Button type="text" icon={<DeleteOutlined />} style={{ float:'right', color:"#ccc" }} data-testid={"delete-chat-button-" + chat.id} />
                                                </Popconfirm>
                                            </Space>
                                        </Card>
                                    ))
                                }
                            </div>

                        </Card>

                    </div>
                    
                </div>

                <div style={{ overflow:'auto' , width:"100%"}} id="2">
                    <Card  style={{ marginRight:20 , height:'calc(100vh - 125px)'  }} size="small">

                        {/* scroll to move to the end of the div when the user clicks on a chat */}
                        <div style={{ height: 'calc(100vh - 250px)', overflow: "scroll" }} id="2-1">
                            
                            {selectedChat !== 0 && queryChatData && queryChatData.chat && queryChatData.chat.messageSet.length > 0 &&
                                queryChatData.chat.messageSet.map((message: any) => (
                                <div key={message.id}>
                                    {message.userType === "human" && (
                                    <div style={{ padding: 10, marginBottom: 5 }} id={message.id}>
                                        <Space direction="horizontal">
                                        <Avatar icon={<UserOutlined />} />
                                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                                            <ReactMarkdown>
                                                { replacePubIdsWithNumbers(message.message, referenceMapping) }
                                            </ReactMarkdown>
                                        </div>
                                        </Space>
                                    </div>
                                    )}
                                    {message.userType === "ai" && (
                                        <div style={{ padding: 10, marginBottom: 5, backgroundColor: aIChatBackground }} id={message.id}>
                                        <Space direction="horizontal">
                                            <Avatar icon={<RobotOutlined />} />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                <ReactMarkdown>
                                                    { replacePubIdsWithNumbers(message.message, referenceMapping) }
                                                </ReactMarkdown>
                                                {message.reviewer && (
                                                    <>
                                                        {(() => {
                                                            // Parse the reviewer JSON string
                                                            const reviewerData = message.reviewer ? JSON.parse(message.reviewer) : null;
                                                            return reviewerData && reviewerData.overall_response ? (
                                                                <>
                                                                    <Space>
                                                                        {/* Accuracy Icon */}
                                                                        <Tooltip title={ replacePubIdsWithNumbers(reviewerData.evaluation_criteria.accuracy.justification, referenceMapping) }>
                                                                            {reviewerData.evaluation_criteria.accuracy.rating === "Approved" ? (
                                                                                <CheckCircleOutlined style={{ color: 'green', fontSize: '16px' }} />
                                                                            ) : (
                                                                                <CloseCircleOutlined style={{ color: 'red', fontSize: '16px' }} />
                                                                            )}
                                                                        </Tooltip>
                    
                                                                        {/* Relevance Icon */}
                                                                        <Tooltip title={ replacePubIdsWithNumbers(reviewerData.evaluation_criteria.relevance.justification, referenceMapping) }>
                                                                            {reviewerData.evaluation_criteria.relevance.rating === "Approved" ? (
                                                                                <CheckCircleOutlined style={{ color: 'green', fontSize: '16px' }} />
                                                                            ) : (
                                                                                <CloseCircleOutlined style={{ color: 'red', fontSize: '16px' }} />
                                                                            )}
                                                                        </Tooltip>
                    
                                                                        {/* Supported by References Icon */}
                                                                        <Tooltip title={ replacePubIdsWithNumbers(reviewerData.evaluation_criteria.supported_by_references.justification, referenceMapping) }>
                                                                            {reviewerData.evaluation_criteria.supported_by_references.rating === "Approved" ? (
                                                                                <CheckCircleOutlined style={{ color: 'green', fontSize: '16px' }} />
                                                                            ) : (
                                                                                <CloseCircleOutlined style={{ color: 'red', fontSize: '16px' }} />
                                                                            )}
                                                                        </Tooltip>
                                                                    </Space>
                                                                    <p style={{ color: "grey", fontSize:'12px' }}>
                                                                        <strong>Justification:</strong> { replacePubIdsWithNumbers(reviewerData.overall_response.justification, referenceMapping) }
                                                                    </p>
                                                                </>
                                                            ) : null;
                                                        })()}
                                                    </>
                                                )}
                                            </div>
                                        </Space>
                                    </div>
                                    )}
                                    
                                </div>
                                ))
                            }
                            {mutationLoading && <Skeleton active />}
                        </div>
                        

                        <div style={{ display:'flex' , flexDirection:'row' , justifyContent:'flex-start' , alignItems:'center' , marginTop:10 }} id="2-2">
                            
                                <Input placeholder="Type a message" width={500} onChange={(e) => setUserMessage(e.target.value)} value={userMessage} onPressEnter={onSubmitMessage} data-testid="input-message"/>
                                <Button type="primary" style={{ marginLeft:10 }} onClick={onSubmitMessage} data-testid="send-button">Send</Button>
                            
                        </div>

                        <div id="2-3">
                            
                                <p style={{color:"grey"}}>The assistant may produce inaccurate information about data, people, places, or facts. Use with caution.</p>
                            
                        </div>


                        
                    </Card>

                </div>

                <div style={{ flex:'0 0 400px' }} id="3">
                    <Card title="References" style={{ height:'calc(100vh - 125px)' }} size="small">
                    <div style={{ height: 'calc(100vh - 250px)', overflow: "scroll" }} id="2-3">
                        {/* references is a text field */}
                        <ReactMarkdown>
                            {queryChatData && queryChatData.chat && queryChatData.chat.id === selectedChat && 
                                renderReferences(queryChatData.chat.references, referenceMapping)
                            }
                        </ReactMarkdown>
                    </div>
                    </Card>
                </div>


            </div>

        </div>
        
    )

}

export default AI;