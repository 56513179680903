import React, {useState} from 'react';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card , Space, Skeleton, Button, Form, Modal, Input, message, Alert, Select} from 'antd';
import DeleteModal from '../DeleteModal';
import ImportCalculatorSetModal from './ImportCalculatorSetModal';
//import { pollInterval } from '../..';
import { CORE_CALCULATOR_SET_FIELDS , CORE_CALCULATOR_SAVE_FIELDS} from '../fragments';
import useWindowDimensions from '../useWindowDimensions';

//import Highlighter from 'react-highlight-words';
//import { SearchOutlined } from '@ant-design/icons';
const {Option} = Select;
const { TextArea } = Input;

const CREATE_CALCULATOR_SET = gql`
    ${CORE_CALCULATOR_SET_FIELDS}
    mutation createCalculatorSet($input: CreateCalculatorSetInputType!) {
        createCalculatorSet( input:$input) {
            calculatorSet {
                ...CoreCalculatorSetFields
            }
        }
    }
`;

const UPDATE_CALCULATOR_SET = gql`
    ${CORE_CALCULATOR_SET_FIELDS}
    mutation updateCalculatorSet($input: UpdateCalculatorSetInputType!) {
        updateCalculatorSet( input:$input) {
            calculatorSet {
                ...CoreCalculatorSetFields
            }
        }
    }
`;

const DELETE_CALCULATOR_SET = gql`
    mutation deleteCalculatorSet($id: ID!) {
        deleteCalculatorSet( id:$id) {
            id
            ok
        }
    }
`;

const GET_CALCULATOR_SETS = gql`
    ${CORE_CALCULATOR_SET_FIELDS}
    query CalculatorSetsByTopicId ($topicId:ID!) {  
        calculatorSetsByTopicId(topicId:$topicId) {
            ...CoreCalculatorSetFields
        }
    }
`;

const CALCULATE_ALL_TOPIC_CALCULATORS = gql`
    mutation calculateAllTopicCalculators($input: CalculateAllTopicCalculatorsInputType!) {
        calculateAllTopicCalculators( input:$input) {
            ok
        }
    }
`;

const ENABLE_CALCULATOR_SET = gql`
    mutation enableCalculatorSet($input: EnableCalculatorSetInputType!) {
        enableCalculatorSet( input:$input) {
            ok
        }
    }
`;

const DISABLE_CALCULATOR_SET = gql`
    mutation disableCalculatorSet($input: DisableCalculatorSetInputType!) {
        disableCalculatorSet( input:$input) {
            ok
        }
    }
`;

const MOVE_UP_CALCULATOR_SET = gql`
    ${CORE_CALCULATOR_SET_FIELDS}
    mutation moveUpCalculatorSet($input: MoveUpCalculatorSetInputType!) {
        moveUpCalculatorSet( input:$input) {
            ok
            topic {
                id
                calculatorsetSet {
                    ...CoreCalculatorSetFields
                }
            }

        }
    }
`;

const MOVE_DOWN_CALCULATOR_SET = gql`
    ${CORE_CALCULATOR_SET_FIELDS}
    mutation moveDownCalculatorSet($input: MoveDownCalculatorSetInputType!) {
        moveDownCalculatorSet( input:$input) {
            ok
            topic {
                id
                calculatorsetSet {
                    ...CoreCalculatorSetFields
                }
            
            }
        }
    }
`;


// gets also all of the topics
const ALL_CALCULATOR_SETS = gql`
    ${CORE_CALCULATOR_SET_FIELDS}
    ${CORE_CALCULATOR_SAVE_FIELDS}
    query calculatorSetsByTopicId($topicId:ID!) {
        calculatorSetsByTopicId(topicId:$topicId) {
            ...CoreCalculatorSetFields
            topic {
                id
                name
            }
        }

        topicById(id:$topicId) {
            id
            name
            userPermissions
            needsCalculation
        }

        topics {
            id
            name
            calculatorsetSet {
                ...CoreCalculatorSetFields
                calculatorsaveSet {
                    ...CoreCalculatorSaveFields
                }
            }
        }
    }
`

const CREATE = CREATE_CALCULATOR_SET;
const READ = GET_CALCULATOR_SETS;
const UPDATE = UPDATE_CALCULATOR_SET;
const DELETE = DELETE_CALCULATOR_SET;

const objectName = "Calculator Set";

interface CalculatorSetTableInputType {
    topic:any
    selectedCalculatorSet:String,
    setSelectedCalculatorSet:Function,

};

function CalculatorSetTable(props:CalculatorSetTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [isImportModalVisible, setImportModalVisible] = useState(false);

    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const { height, width } = useWindowDimensions();
    

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    /*
    //const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {variables:{topicId:props.topic.id}});
    const { loading:queryLoading1, error: queryError1, data: allCalculatorSets } = useQuery( ALL_CALCULATOR_SETS , 
        {variables:{topicId:props.topic.id } , 
        //fetchPolicy: 'cache-and-network'
    });*/

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: ALL_CALCULATOR_SETS , variables:{topicId:props.topic.id}} ],
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE, {
        refetchQueries: [ { query: ALL_CALCULATOR_SETS , variables:{topicId:props.topic.id}} ],
        onCompleted(data) { message.success(objectName +  " was updated successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: ALL_CALCULATOR_SETS , variables:{topicId:props.topic.id}} ],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    const [calculateAllTopicCalculators] = useMutation(CALCULATE_ALL_TOPIC_CALCULATORS, {
        onCompleted(data) { message.success("Calculations will be performed in the background.") },
        onError(error) {message.error(error.message)},
    });

    const [enableMutation] = useMutation(ENABLE_CALCULATOR_SET, {
        refetchQueries: [ { query: ALL_CALCULATOR_SETS , variables:{topicId:props.topic.id}} ],
        onCompleted(data) { message.success(objectName +  " was enabled successfully.") },
        onError(error) {message.error(error.message)},
    });

    const [disableMutation] = useMutation(DISABLE_CALCULATOR_SET, {
        refetchQueries: [ { query: ALL_CALCULATOR_SETS , variables:{topicId:props.topic.id}} ],
        onCompleted(data) { message.success(objectName +  " was disabled successfully.") },
        onError(error) {message.error(error.message)},
    });

    const [moveUpMutation] = useMutation(MOVE_UP_CALCULATOR_SET, {
        //refetchQueries: [ { query: GET_CALCULATOR_SETS , variables:{topicId:props.topic.id}} ],
        onCompleted(data) { 
            message.success(objectName +  " was moved up successfully.") 
        },
        onError(error) {message.error(error.message)},
    });

    const [moveDownMutation] = useMutation(MOVE_DOWN_CALCULATOR_SET, {
        //refetchQueries: [ { query: GET_CALCULATOR_SETS , variables:{topicId:props.topic.id}} ],
        onCompleted(data) { 
            message.success(objectName +  " was moved down successfully.") 
        },
        onError(error) {message.error(error.message)},
    });


    // ----------------------------------------------------------------------------------------
    // functions

    const showModal = (values:any) => {
        setModalTitel("Create a new Calculator Set")
        setModalInitialValues({ name:"", description:"" } )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Create a new Calculator Set") {
    
            let input = {
                name : values['name'], 
                description: values['description'],
                topicId:props.topic.id
            };
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                description: values['description']
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {

        setModalTitel("Edit the Calculator Set")
        setModalInitialValues({ name:input.name, description:input.description } )

        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:String) => {   
        deleteMutation({ variables: { id: id  } } )
        props.setSelectedCalculatorSet("")
    } 

    const handleCopy = (values:any) => {   
        setImportModalVisible(true)
    } 
    
    const handleEnable =(values:any) => {
        let input = {
            calculatorSetId: props.selectedCalculatorSet,
        };
        enableMutation({ variables: { input: input  } } )
    }

    const handleDisable =(values:any) => {
        let input = {
            calculatorSetId: props.selectedCalculatorSet,
        };
        disableMutation({ variables: { input: input  } } )
    }

    const handleMoveUp =(values:any) => {
        let input = {
            calculatorSetId: props.selectedCalculatorSet,
        };
        moveUpMutation({ variables: { input: input  } } )
    }

    const handleMoveDown =(values:any) => {
        let input = {
            calculatorSetId: props.selectedCalculatorSet,
        };
        moveDownMutation({ variables: { input: input  } } )
    }

    const handleCalculateAll =(values:any) => {
        let input = {
            topicId: props.topic.id,
        };
        calculateAllTopicCalculators({ variables: { input: input  } } )
    }

    const formLayout = {
        labelCol: { span: 6 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 12 },
    };


    const TableColumns = [
        {
            title: 'Order',
            dataIndex: 'order',
            editable: false,
            width: '15%',
        },
        {
            title: 'Enabled',
            width: '15%',

            render : (text:String, record:any) => (
                <div>
                    {
                        record.enabled == false && 
                        <CloseOutlined style={{color: 'red' , fontSize: '1.4em' , marginLeft:10}} title="Disabled"/>

                    }
                    {
                        record.enabled == true && 
                        <CheckOutlined style={{color: 'green' , fontSize: '1.4em' , marginLeft:10}} title="Enabled"/>
                    }
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            //width: '20%',
        },  
        {
            title: 'Description',
            dataIndex: 'description',
            editable: false,

        },
        
        /*{
            title: 'Active',
            dataIndex: 'defaultForTopic',
            render: (text:String, record:any) => (
                <div>
                    { record.defaultForTopic == true && <CheckOutlined /> } 
                </div>
            ),
        },*/  
        /*{
            title: 'Actions',
            key: 'actions',
            width: '10%',
            render: (text:String, record:any) => (
                <div>
                    <Space>
                        <Button type="link" onClick={()=> handleEdit(record)}>
                            Edit
                        </Button>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                            <a>Delete</a>
                        </Popconfirm>
                    </Space>
                </div>
            ),
        },*/
    ]

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
          setSelectedRowKeys(newSelectedRowKeys);
          if (selectedRows.length > 0) {
            props.setSelectedCalculatorSet(selectedRows[0].id);
          }
        }
    };


    /*

    if (queryError1) return ( 
        <Card>Error: {queryError1.message} </Card>
    );
 
    if (queryLoading1) return (
    <Card><Skeleton active/></Card>
    );
    
    */

    const editable = props.topic.userPermissions != "read"

    return (

        <div>
        
        { /* props.topic.needsCalculation &&
            <div>
                <Alert message="Calculations need to be updated: once you are done with modifications to the collection-wide calculators, use the 'Calculate all' button to apply the calculations to the entire collection." type="warning" showIcon style={{width: "100%"}}/>
                <br/>
            </div>

        */}
            
            <div style={{ display: 'flex'}}>

                <div style={{ flex: '0 0 90px' }}>
                    
                    <Space direction="vertical" >

                        
                        <Button disabled={!editable} className="button_sidebar" onClick = {  handleCalculateAll  } data-testid="calculate-all-calc-set" title="Calculate all processes of the Collection" type={props.topic.needsCalculation ? "primary" : "default"}>Calculate all</Button>
                        <Button  disabled={!editable} className="button_sidebar" onClick = {  showModal  } data-testid="create-calc-set" title="Create a new Calculator Set">Create </Button>
                        <Button  disabled={!editable} className="button_sidebar"  onClick = {  handleCopy  } data-testid="import-calc-set" title="Import a Calculator Set from another Team / Collection"> Import </Button>
                        <Button className="button_sidebar"  onClick = {  ()=>handleEdit(props.topic.calculatorsetSet.find((c:any) => c.id == props.selectedCalculatorSet)) }  data-testid="edit-calc-set" title="Edit the Calculator Set" disabled={props.selectedCalculatorSet == "" || !editable}> Edit </Button>
                        <Button className="button_sidebar"  onClick = { handleEnable }  data-testid="enable-calc-set" title="Enable the Calculator Set" disabled={props.selectedCalculatorSet == "" || !editable}> Enable </Button>
                        <Button className="button_sidebar"  onClick = {  handleDisable  }  data-testid="disable-calc-set" title="Disable the Calculator Set" disabled={props.selectedCalculatorSet == "" || !editable}> Disable </Button>
                        <Button className="button_sidebar"  onClick = { ()=> setDeleteModalVisible(true) }  data-testid="delete-calc-set" title="Delete the Calculator Set" disabled={props.selectedCalculatorSet == "" || !editable}> Delete </Button>
                        <Button className="button_sidebar"  onClick = {  handleMoveUp  } data-testid="move-up-calc-set" title="Move up the Calculator Set" disabled={props.selectedCalculatorSet == "" || !editable}> Move up </Button>
                        <Button className="button_sidebar"  onClick = {  handleMoveDown  } data-testid="move-down-calc-set" title="Move down the Calculator Set" disabled={props.selectedCalculatorSet == "" || !editable}> Move down </Button>
                        

                    </Space>
                </div>
               
                <div style={{ marginLeft: 5, width: "100%", overflow:"scroll" }}>
                    <Table 
                        //loading={queryLoading1}
                        dataSource={ props.topic.calculatorsetSet }
                        columns={ TableColumns } 
                        bordered
                        rowKey={record => record.id} 
                        size="small"
                        pagination={false}
                        scroll={{ y: height - 460 }}
                        rowSelection={{
                            type: "radio",
                            ...rowSelection,
                        }}
                        style={{marginLeft:10, minWidth:400}}
                        onRow={(record:any, rowIndex:any) => {
                            return {
                              onClick: event => {
                                // Toggle selection for radio type
                                const newSelectedRowKeys:any = selectedRowKeys.includes(record.id)
                                  ? []
                                  : [record.id];
                          
                                setSelectedRowKeys(newSelectedRowKeys);
                                if (newSelectedRowKeys.length > 0) {
                                    props.setSelectedCalculatorSet(record.id);
                                } else {
                                    props.setSelectedCalculatorSet("");
                                }
                                
                              },
                              onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"},
                              onMouseLeave: event => {event.currentTarget.style.cursor = "default"},
                            };
                          }}
                    />
                
                </div>

            </div>

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                
                footer={[
                    <Button type="primary" form="CalculatorSetForm" key="submit" htmlType="submit" data-testid="submit">
                        Submit
                    </Button>
                    ]}
            >
                <br/>
                <Form
                    {...formLayout}  
                    id = "CalculatorSetForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name.' }]} >
                        <Input maxLength={200} showCount data-testid="name"/>
                    </Form.Item>

                    <Form.Item label="Description:" name="description" rules={[{ required: false  }]}>
                        <TextArea maxLength={10000} showCount data-testid="description"/>
                    </Form.Item>

                </Form>
                

            </Modal>
            
            <ImportCalculatorSetModal
                topicId={props.topic.id}
                isImportModalVisible={isImportModalVisible}
                setImportModalVisible={setImportModalVisible}
            />


            <DeleteModal
                open={deleteModalVisible}
                setOpen={setDeleteModalVisible}
                onOk={() => handleDelete(props.selectedCalculatorSet)}
                objectName={"Calculator Set"}

            />

        </div>
    )

}



export default CalculatorSetTable;

