import { useQuery, gql } from '@apollo/client';
import { Skeleton, Card}  from 'antd';
import PageHeader from '../components/PageHeader';
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from "../components/useWindowDimensions";
import ReactGA from 'react-ga';
import TeamUsers from '../components/teams/TeamUsers';

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            admin
            user {
                id
                username
            }
            selectedTeam {
                id
                name
                owner {
                  id
                  username
                }
            }
        }
    }
`

function Settings() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/settings/');

    const { loading, error, data } = useQuery(GET_USER_PROFILE,
        { fetchPolicy: "cache-and-network" }
    );
    
    const { height, width } = useWindowDimensions();

    const routes = [
        {
          path: '',
          breadcrumbName: "Team: " + data?.userProfile?.selectedTeam.name ,
        }
      ];

    if (loading && !data) return (
        <div>
            <PageHeader
                title="Settings"
                extra={<HeaderExtra/>}
                breadcrumb={{ routes }}
            />
            <Skeleton active />
        </div>
    )

    if (error) return (
        <div>
            <PageHeader
                title="Settings"
                extra={<HeaderExtra/>}
                breadcrumb={{ routes }}
            />
            <p>Error: {error.message}</p>
        </div>
    )

    // if the user is not the owner of the team, display an error message
    if (data.userProfile.selectedTeam.owner.id !== data.userProfile.user.id && data.userProfile.admin === false) {
        return (
            <div>
                <PageHeader
                    title="Settings"
                    extra={<HeaderExtra/>}
                    breadcrumb={{ routes }}
                />
                <Card>
                  <div><p>You must be the owner of a team to view the team settings.</p></div>
                </Card>
            </div>
        )
    }

    return(  
      <div>

        <PageHeader
          title="Settings"
          extra={<HeaderExtra/>}
          breadcrumb={{ routes }}
        />

        <TeamUsers teamId={data.userProfile.selectedTeam.id}/>
          
      </div>
    )
  }
  
  export default Settings;
