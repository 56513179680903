import {useState} from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { Table , Space, Spin, message, Modal, Button, Skeleton} from 'antd';
import useWindowDimensions from '../useWindowDimensions';

import { CORE_KPI_TABLE_FIELDS } from '../fragments';

const GET_KPI_TABLE = gql`
    ${CORE_KPI_TABLE_FIELDS}
    query kpiTable ($input:KpiTableInputType!) {  
        kpiTable(input:$input) {
            ...CoreKPITableFields
        }
    }
`;

const GET_KPI_TABLE_XLSX = gql`
    query kpiTableXlsx ($input:KpiTableInputType!) {
        kpiTableXlsx(input:$input)
    }
`;

interface KPIViewTableInputType {
    processes:any
    loading?: boolean
    analysis: any
};

function KPIViewTable(props:KPIViewTableInputType) {

    // set the default for the loading prop to false
    //props.loading = props.loading || false

    const { height, width } = useWindowDimensions();

    // ----------------------------------------------------------------------------------------
    // states
   
    const [isModalVisible, setIsModalVisible] = useState(false);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    // loop over props selectedProcesses and createa an array with the ids
    let processIds:any = []
    props?.processes.forEach((process: any) => {
        processIds.push(process.id)
    })

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_KPI_TABLE,
        { 
            variables: { 'input': { 'analysisId':props.analysis.id, 'processIds': processIds } } ,
            fetchPolicy: "cache-and-network",
        }
    );
    

    // use a lazy query to get the xlsx file
    const [getKpiTableXlsx, { loading: queryLoadingXlsx, error: queryErrorXlsx, data: queryDataXlsx }] = useLazyQuery(GET_KPI_TABLE_XLSX,
        { variables: { 'input': { 'analysisId':props.analysis.id, 'processIds': processIds } },
        onCompleted(data) { 
            // get the base64 encoded xlsx file
            const base64 = data.kpiTableXlsx
            // download the file
            const link = document.createElement('a');
            link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + base64;
            link.setAttribute('download', 'kpi_table.xlsx');
            // simulate click
            const event = new MouseEvent('click');
            link.dispatchEvent(event);
         } ,

        onError(error) {message.error(error.message)},
        
    });
    

    if ((queryLoading && !queryData) || props.loading) return (
        <div>
            <Skeleton active/>
        </div>
    )

    if (queryError) return (
        <div>Error: {queryError.message}</div>
    );

    if (queryData.kpiTable == null) return (
        <div>
            
        </div>
    )


    // ----------------------------------------------------------------------------------------
    // functions

    // preparing the data to be displayed in a table

    const value = JSON.parse(queryData.kpiTable.json)
    const data = value.data
    const units = queryData.kpiTable.columnUnits

    let tableData:any = []
    let i=0

    
    let col_names = value.columns

    // loop through data
    data.forEach((row: any) => {

        let row_data:any = []
        let j=0

        // add all cols to one object which row_data
        row.forEach((col: any) => {

            let col_name = col_names[j]

            // if col is a float, round it to 3 decimals 
            if (typeof col === 'number') {
                col = Math.round(col * 100) / 100


                // if col is larger than 1000 convert it to scientific notation
                if (col > 1000) {
                    col = col.toExponential(3)
                }
            }
            

            row_data.push(
            // the key should be the value of col_name
            // the value should be the value of col
                {
                    
                    [col_name]: col,
                }
            )
            j++
        })
        // append 'key' and 'process' to the row_data
        row_data.push(
            {
                'key': value.index[i],
                'process': value.index[i],
            }
        )
        // row_data has multiple objects, so we need to merge them
        row_data = Object.assign({}, ...row_data)
        // push the row_data to the tableData
        tableData.push(row_data)
        i++
    }
    )
    

    // table columns
    const columns = [
        {
            title: 'Process',
            dataIndex: 'process',
            width: "200px",
            render : (text: any, record: any) => {
                return (
                    <Space size="middle">
                        <b>{record.process}</b>
                    </Space>
                )
            }
        },
    ]

    // loop through the column names and add them to the columns
    i=0
    col_names.forEach((col_name: any) => {
        let col_units = ''
        if (units[i] != null) {
            col_units = " (" + units[i] + ")"
        }

        columns.push(
            {
                title: col_name + col_units,
                dataIndex: col_name,
                width: "150px",
                render : (text: any, record: any) => {
                    return (
                        <Space size="middle">
                            {record[col_name]}
                        </Space>
                    )
                }

            }
        )
        i++
    })

    function handleDownload() {
        
        let input = {
            'analysisId':props.analysis.id,
            'processIds': processIds
        }

        getKpiTableXlsx({ variables: { 'input': input } })
    }

    // 

    return(
        <div>
            
            <Button 
                type="primary" 
                onClick={() => handleDownload()} 
                style={{ marginBottom: 20 }} 
                data-testid="download-xlsx-button"
            >
                Download as XLSX
            </Button>
            
            <Table
                dataSource={tableData}
                columns={columns}
                //columns={queryData.kpiTable.columns}
                pagination={false}
                scroll={{ y: height - 400 }}
            />
        

            <Modal 
                title="Loading"
                open={queryLoadingXlsx}
                footer = {[]}
            >
                <Skeleton active />
            </Modal>

        </div>
    )


}

export default KPIViewTable;

