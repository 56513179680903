import {Skeleton, Row, Col, Card} from 'antd';
import useWindowDimensions from '../useWindowDimensions';
import KPIAnalysisPlot from './KPIAnalysisPlot';
import { kpiAnalysisSelectedViewPageVar } from '../../Apollo';
import { useReactiveVar } from '@apollo/client';


interface KPIAnalysisPlotsInputType {
    loading:boolean
    processes:any
    setSelectedSidebarProcess:Function
    view:any
    analysis:any
}

function KPIAnalysisPlots (props:KPIAnalysisPlotsInputType) {

    const { height, width } = useWindowDimensions();
    const kpiAnalysisViewSelectedViewPage = useReactiveVar(kpiAnalysisSelectedViewPageVar);
    const boxHeight = height / 2 - 90;

    if (props.loading ) return (

        <div>
            <Row>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}><Skeleton paragraph={{ rows: 6 }} active /></Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}><Skeleton paragraph={{ rows: 6 }} active /></Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}><Skeleton paragraph={{ rows: 6 }} active /></Card>
                </Col>
            </Row>
            <Row >
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}><Skeleton paragraph={{ rows: 6 }} active /></Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}><Skeleton paragraph={{ rows: 6 }} active /></Card>
                </Col>
                <Col span={8}>
                    <Card style={{marginLeft:10, marginBottom:10, height:boxHeight}}><Skeleton paragraph={{ rows: 6 }} active /></Card>
                </Col>
            </Row>
        </div>

    );

    const viewPage = props.analysis.kpiAnalysisView.viewpageSet.find( (viewPage:any) => viewPage.id == kpiAnalysisViewSelectedViewPage );
    let nPlots = viewPage?.kpiAnalysisNumplots;
    if (nPlots === undefined) {
        nPlots = 4;
    }
    
    if (width<1200) {
    
        // for screens that are not wide - putting plots into one columns
        const plots = []
        for (var i=0 ; i < nPlots; i++) {
            plots.push(
                <KPIAnalysisPlot key={i} id={i} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess}  loading={props.loading} processes={props.processes} analysis={props.analysis}/>
            )
        }

        return(
            <Row style={{height:height-110, overflow:'scroll'}}>
                <Col span={24}>
                    {plots}
                </Col>
            </Row>
        )


    }

    if (nPlots == 1) {
        return(
            <Row>
                <Col span={24}>
                    <KPIAnalysisPlot key={0} id={0} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={ props.loading} processes={props.processes} analysis={props.analysis}/>
                </Col>
            </Row>
        )
    } else if (nPlots == 2) {
        return (
            <Row>
                <Col span={12}>
                    <KPIAnalysisPlot key={0} id={0} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                </Col>
                <Col span={12}>
                    <KPIAnalysisPlot key={1} id={1} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                </Col>
            </Row>
        )
    } else if (nPlots == 3) {
        return (
            <Row>
                <Col span={8}>
                    <KPIAnalysisPlot key={0} id={0} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                </Col>
                <Col span={8}>
                    <KPIAnalysisPlot key={1} id={1} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                </Col>
                <Col span={8}>
                    <KPIAnalysisPlot key={1} id={2} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                </Col>
            </Row>
        )
    } else if (nPlots == 4) {
        return (
            <div>
                <Row>
                    <Col span={12}>
                        <KPIAnalysisPlot key={0} id={0} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                    <Col span={12}>
                        <KPIAnalysisPlot key={1} id={1} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <KPIAnalysisPlot key={2} id={2} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                    <Col span={12}>
                        <KPIAnalysisPlot key={3} id={3} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                </Row>
            </div>
        )
    } else {

        return (
            <div>
                <Row >
                    <Col span={8}>
                        <KPIAnalysisPlot key={0} id={0} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                    <Col span={8}>
                        <KPIAnalysisPlot key={1} id={1} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                    <Col span={8}>
                        <KPIAnalysisPlot key={2} id={2} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                </Row>
                <Row >
                    <Col span={8}>
                        <KPIAnalysisPlot key={3} id={3} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                    <Col span={8}>
                        <KPIAnalysisPlot key={4} id={4} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                    </Col>
                    <Col span={8}>
                        { nPlots == 6 &&
                            <KPIAnalysisPlot key={5} id={5} view= {props.view}  setSelectedSidebarProcess={props.setSelectedSidebarProcess} loading={props.loading} processes={props.processes} analysis={props.analysis}/>
                        }
                        
                    </Col>
                </Row>
        </div>
        )

    }


    



}

export default KPIAnalysisPlots