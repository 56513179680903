import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Space, Popconfirm, Button, Form, Skeleton, Modal, Input, message, Popover, Empty} from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import ImportMedia from './ImportMedia';



const { TextArea } = Input;

const CREATE_UNDEFINED_INGREDIENT = gql`
    mutation createUndefinedIngredient($input: CreateUndefinedIngredientInputType!) {
        createUndefinedIngredient( input:$input) {
            undefinedIngredient {
                id
                name
                description
            }
        }
    }
`;

const UPDATE_UNDEFINED_INGREDIENT = gql`
    mutation updateUndefinedIngredient($input: UpdateUndefinedIngredientInputType!) {
        updateUndefinedIngredient( input:$input) {
            undefinedIngredient {
                id
                name
                description
            }
        }
    }
`;

const DELETE_UNDEFINED_INGREDIENT = gql`
    mutation deleteUndefinedIngredient($id: ID!) {
        deleteUndefinedIngredient( id:$id) {
            id
            ok
        }
    }
`;

const READ_UNDEFINED_INGREDIENTS = gql`
    query undefinedIngredients ($topicId:ID!) {  
        undefinedIngredients(topicId:$topicId) {
            id
            name
            description
        }
        topicById(id:$topicId) {
            id
            userPermissions
        }
    }
`;

const GET_ALL_TOPIC_UNDEFINED_INGREDIENTS = gql`
    query allTopicUndefinedIngredients {
        allTopicUndefinedIngredients {
            id
            name
            description
            topic {
                id
                name
                team {
                    id
                    name
                }
            }
        }
    }
`;

const IMPORT_UNDEFINED_INGREDIENT = gql`
    mutation importUndefinedIngredient($input: ImportUndefinedIngredientInputType!) {
        importUndefinedIngredient( input:$input) {
            undefinedIngredient {
                id
                name
                description
            }
        }
    }
`;

const CREATE = CREATE_UNDEFINED_INGREDIENT;
const READ = READ_UNDEFINED_INGREDIENTS;
const UPDATE = UPDATE_UNDEFINED_INGREDIENT;
const DELETE = DELETE_UNDEFINED_INGREDIENT;

const objectName = "Undefined Ingredient";

interface UndefinedIngredientTableInputType {
    topicId: string
};

function UndefinedIngredientTable(props:UndefinedIngredientTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState( { name:"", description:"" } );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState("");
    const [isImportUndefinedIngredient, setImportUndefinedIngredient] = useState(0);
    const [importUndefinedIngredientSearch, setImportUndefinedIngredientSearch] = useState("");
    const [importUndefinedIngredientId, setImportUndefinedIngredientId] = useState("");
    const {height, width} = useWindowDimensions()
    const [importModalVisible, setImportModalVisible] = useState(false);
    

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ , {
        variables:{topicId:props.topicId},
        //fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    const { loading:allTopicUndefinedIngredientsLoading, error: allTopicUndefinedIngredientsError, data: allTopicUndefinedIngredientsData } = useQuery(GET_ALL_TOPIC_UNDEFINED_INGREDIENTS);

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE, {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ], 
        onCompleted(data) { message.success( objectName + " was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE , {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ], 
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ], 
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    const [importMutation] = useMutation(IMPORT_UNDEFINED_INGREDIENT, {
        refetchQueries: [ { query: READ, variables: { topicId: props.topicId } } ],
        onCompleted(data) { message.success( objectName + " was imported successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    // ----------------------------------------------------------------------------------------
    // if no topic is provided, return empty
    
    if (props.topicId == undefined || props.topicId == "") {
        return (
            <div>
                <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Please select a topic first."/>
            </div>
        )
    }
    
    // ----------------------------------------------------------------------------------------

    
    const showModal = (values:any) => {
        setModalTitel("Add undefined ingredient");
        setModalInitialValues({ name:"", description:""  } )
        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
    
        // adding
        if (modalTitel == "Add undefined ingredient") {

            if (isImportUndefinedIngredient == 0) {
    
                let input = {
                    name : values['name'], 
                    description : values['description'],
                    topicId : props.topicId,
                    
                };
                
                createMutation( { variables: { input: input  } } );

            } else {
                let input = {
                    id : importUndefinedIngredientId,
                    topicId : props.topicId,
                    
                };
                
                importMutation( { variables: { input: input  } } );
            }

            
        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                description: values['description'],
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit undefined ingredient")
        setImportUndefinedIngredient(0);
        
        setModalInitialValues({ 
            name:input.name,
            description:input.description,
        })
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
    } 

    function onChangeImportUndefinedIngredient(input:any) {
        setImportUndefinedIngredient(input.target.value);
    }

    function handleImportUndefinedIngredientsSearch(input:any) {
        setImportUndefinedIngredientSearch(input.target.value);
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setImportUndefinedIngredientId(selectedRows[0].id)
        }
    };


    const formLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 3, span: 12 },
    };


    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            width: '30%',
          },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: false,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '5%',

            render: (text:String, record:any) => (
                
                    <Popover content={(
                        <Space direction="vertical">
                        <Button type="link" onClick={()=> handleEdit(record)} data-testid="edit-undefined-ingredient" disabled={queryData.topicById.userPermissions == "read"}>
                            Edit
                        </Button>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} disabled={queryData.topicById.userPermissions == "read"}>
                            <Button type="link" data-testid="delete-undefined-ingredient" disabled={queryData.topicById.userPermissions == "read"}>
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>
                    )}>
                    <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"undefined-ingredient-actions-button-" + record.name}>
                        <EllipsisOutlined />
                    </Button>
                </Popover>
            ),
        },
    ]

    const importUndefinedIngredientColumns = [

        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            width: '40%',
          },
        {
            title: 'Description',
            dataIndex: 'description',
            editable: false,
            width: '40%',
        },
    ]

    if (queryError) {
        return <div>Error loading undefined ingredient.</div>
    }

    if (allTopicUndefinedIngredientsError) {
        return <div>Error loading undefined ingredients.</div>
    }

    if (queryLoading || allTopicUndefinedIngredientsLoading){
        return (
            <Skeleton active/>
        )
    }

    // create the datasource for the table that contains undefinedIngredients to be imported
    let importUndefinedIngredients = allTopicUndefinedIngredientsData?.allTopicUndefinedIngredients.filter((undefinedIngredient:any) => undefinedIngredient?.topic == null || undefinedIngredient.topic.id != props.topicId)
    // filter the undefinedIngredients to be imported based on the search input by name, formula, and cas number
    if (importUndefinedIngredientSearch != "") {
        importUndefinedIngredients = importUndefinedIngredients?.filter((undefinedIngredient:any) => undefinedIngredient.name.toLowerCase().includes(importUndefinedIngredientSearch.toLowerCase()) || undefinedIngredient.formula.toLowerCase().includes(importUndefinedIngredientSearch.toLowerCase()) || undefinedIngredient.casNumber.toLowerCase().includes(importUndefinedIngredientSearch.toLowerCase()))
    }

    // sort the undefinedIngredients by name
    importUndefinedIngredients = importUndefinedIngredients?.sort((a:any, b:any) => a.name.localeCompare(b.name))
    // remove the duplicates
    importUndefinedIngredients = importUndefinedIngredients?.filter((undefinedIngredient:any, index:any, self:any) => index === self.findIndex((t:any) => (t.name === undefinedIngredient.name && t.formula === undefinedIngredient.formula && t.casNumber === undefinedIngredient.casNumber)))

    return (

        <div>
                <Space direction="horizontal" style={{ width: '100%' }}>
                    <Button
                        type="primary"
                        shape="round"
                        icon={<PlusOutlined />}
                        style = {{ marginBottom: 16 }}
                        onClick = {  showModal  } 
                        data-testid = "add-undefined-ingredient"
                        disabled = { queryData.topicById.userPermissions == "read"}
                        title = { queryData.topicById.userPermissions == "read" ? "You don't have permission to create an undefined ingredients." : "" }                          
                    >
                        Create an undefined ingredient
                    </Button>

                    <Button 
                        style = {{ marginBottom: 16}}
                        onClick = { () => setImportModalVisible(true) }
                        data-testid = "import-undefined-ingredient"
                        disabled = { queryData.topicById.userPermissions == "read"}
                        title = { queryData.topicById.userPermissions == "read" ? "You don't have permission to import undefined ingredients." : "" }
                    >
                        Import
                    </Button>

                </Space>
                
                <Table 
                    loading={queryLoading}
                    dataSource={queryData?.undefinedIngredients}
                    columns = { columns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    pagination={false}
                    scroll={{ y: height-550 }}
                />

                        
            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                data-testid = "undefined-ingredient-modal"
                width={800}
                footer={[
                    <Button type="primary" form="UndefinedIngredientForm" key="submit" htmlType="submit" data-testid="submit-undefined-ingredient">
                        Submit
                    </Button>
                    ]}
            >
                <div style={{ minHeight: 150 }}>
                
                    <Form
                        {...formLayout}  
                        id = "UndefinedIngredientForm"
                        onFinish={handleModalOk} 
                        initialValues={ modalInitialValues }
                    > 
                        {/* create a radio asking if new or import */}
                        {/*
                        { modalTitel == "Add undefined ingredient" &&
                            <Form.Item label="Source:" required >
                                <Radio.Group value={isImportUndefinedIngredient} onChange={onChangeImportUndefinedIngredient}>
                                    <Radio value={0}>Create a new undefined ingredient</Radio>
                                    <Radio value={1}>Import undefined ingredient</Radio>
                                </Radio.Group>
                            </Form.Item>
                        }
                        */}

                        { isImportUndefinedIngredient == 0 && 
                            <div>
                                <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'Please enter a name for the undefined ingredient.' }]} >
                                    <Input 
                                        maxLength={30}
                                        showCount
                                        data-testid = "undefined-ingredient-name-input"
                                    />
                                </Form.Item>

                                <Form.Item label="Description:" name="description" rules={[{ required: false }]} >
                                    <TextArea
                                        maxLength={1000}
                                        showCount
                                        rows={3}
                                        data-testid = "undefined-ingredient-description-input"
                                    />
                                </Form.Item>

                            </div>
                            }

                            { isImportUndefinedIngredient == 1 &&
                                <div>
                                    {/* add a search field */}
                                    <Form.Item label="Search:" name="search" rules={[{ required: false}]} >
                                        <Input
                                            maxLength={100}
                                            data-testid = "undefined-ingredient-search-input"
                                            onChange={handleImportUndefinedIngredientsSearch}
                                        />
                                    </Form.Item>
                                    <Table 
                                        loading={allTopicUndefinedIngredientsLoading}
                                        dataSource={importUndefinedIngredients}
                                        columns = { importUndefinedIngredientColumns }
                                        bordered
                                        rowKey={record => record.id}
                                        size="small"
                                        pagination={false}
                                        rowSelection={{
                                            type: "radio",
                                            ...rowSelection,
                                        }}
                                    />
                                </div>
                            }


                    </Form>
                </div>

            
            </Modal>
            <ImportMedia open={importModalVisible} setOpen={setImportModalVisible} importType='undefinedingredient' targetTopicId={props.topicId} />
        </div>

    );
    }


export default UndefinedIngredientTable;

