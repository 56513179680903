import {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Popconfirm, Button, message, Alert, Input, Modal, Form} from 'antd';
import MomentDatePicker from './MomentDatePicker';
import useWindowDimensions from './useWindowDimensions';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { CORE_NOTE_FIELDS, CORE_PROCESS_FIELDS } from './fragments';


const { TextArea } = Input;

const CREATE_NOTE = gql`
    ${CORE_PROCESS_FIELDS}
    ${CORE_NOTE_FIELDS}
    mutation createNote ($input: CreateNoteInputType!) {  
        createNote (input:$input) {
            process {
                ...CoreProcessFields
                noteSet {
                    ...CoreNoteFields
                }
            }
        }
    }
`;

const DELETE_NOTE = gql`
    mutation deleteNote($id: ID!) {
        deleteNote( id:$id) {
            id
            ok
        }
    }
`;

const GET_PROCESS_NOTES = gql`
    ${CORE_PROCESS_FIELDS}
    ${CORE_NOTE_FIELDS}
    query process ($id:ID!) {  
        process(id:$id) {
            ...CoreProcessFields
            noteSet {
                ...CoreNoteFields
            }
        }
    }
`;

const UPDATE_NOTE = gql`
    ${CORE_PROCESS_FIELDS}
    ${CORE_NOTE_FIELDS}
    mutation updateNote ($input: UpdateNoteInputType!) {
        updateNote (input:$input) {
            process {
                ...CoreProcessFields
                noteSet {
                    ...CoreNoteFields
                }
            }
        }
    }
`;


const READ = GET_PROCESS_NOTES;
const DELETE = DELETE_NOTE;

const objectName = "Note";

interface NoteTableInputType {
    processId:number,
    showDescription?:boolean
    tableHeightOffset?:number
};

function NoteTable(props:NoteTableInputType) {

    const { height, width } = useWindowDimensions();

    let tableHeightOffest = 370;
    if (props.tableHeightOffset) {
        tableHeightOffest = props.tableHeightOffset;
    }

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState(0);
    const [modalInitialValues, setModalInitialValues] = useState( {  note:"" , processTime:moment(), user:"" } );
    const [modalMode, setModalMode] = useState("add");

    //const [nameSearchText, setNameSearchText] = useState("");
    //const [descriptionSearchText, setDescriptionSearchText] = useState("");
    //const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery( READ , {
        variables:{id:props.processId},
        //pollInterval: 3000,
        fetchPolicy: 'cache-and-network'
    } );

    const [deleteMutation] = useMutation(DELETE, {
        refetchQueries: [{ query: READ, variables: { id: props.processId } }],
        onCompleted(data) { message.success(objectName +  " was deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    // mutation to create a note
    const [createMutation] = useMutation(CREATE_NOTE, {
        refetchQueries: [{ query: READ, variables: { id: props.processId } }],
        onCompleted(data) { message.success(objectName +  " was created successfully.") },
        onError(error) {message.error(error.message)},
    });

    // mutation to update a note
    const [updateMutation] = useMutation(UPDATE_NOTE, {
        refetchQueries: [{ query: READ, variables: { id: props.processId } }],
        onCompleted(data) { message.success(objectName +  " was updated successfully.") },
        onError(error) {message.error(error.message)},
    });

    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {

        setModalTitel("Add")
        setModalMode("add")

        setModalInitialValues({ 
            note:"", 
            processTime: moment(),
            user:"" })

        setModalVisible(true)
    }
    
    const handleModalOk = (values: any) => {
        let input:any = {}
        let processTime = undefined
        if (values['processTime'] != null) {
            processTime = values['processTime'].toISOString()
        }

        // adding
        if (modalTitel == "Add") {

            input = {
                processTime: processTime,
                note: values['note'],
                processId:props.processId,
            }
            
            createMutation( { variables: { input: input  } } );
            
        // editing
        } else {

            input = {
                id: modalId,
                processTime: processTime,
                note: values['note'],
            }

            updateMutation( { variables: { input: input  } } );
        }
        
        setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {
        setModalTitel("Edit")
        setModalMode("edit")

        setModalInitialValues({ 

            note:input.note,
            processTime: input.processTime != null ? moment.utc(input.processTime) : moment(),
            user:input.user.username,
        })
        
        setModalId(input.id)
        setModalVisible(true)
    } 
    
    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
        setModalVisible(false);
    } 

    let columns = [
        {
            title: 'Time',
            //dataIndex: 'processTime',
            width: '30%',
            render: (text:any, record:any) => (
                <div>
                    {/* convert to local time */}
                    
                    {record.processTime == null ? "Not specified" : moment.utc(record.processTime).local().format('YYYY-MM-DD HH:mm:ss')}
                    
                </div>
            ),
        },
        {
            title: 'Note',
            width: '70%',
            //dataIndex: 'note',
            render: (text:any, record:any) => {
                return (
                    <div style={{wordBreak:"break-all"}}>
                        {record.note}
                    </div>
                )
            },
            
        },
 
    ]

    // remove description column
    if (props.showDescription == false) {
            columns.splice(3,1)
            columns.splice(3,1)
    }

    // if props.tableColumns is set, use it to return columns which have the same dataindex as the props.tableColumns

    const formLayout = {
        labelCol: { span: 6 },
    };

    if (queryError) {
        return (
            <Alert
                message="Error"
                description={queryError.message}
                type="error"
                showIcon
            />  
        )
    }

    return (

        <div>

            <Button
                type="primary"
                style = {{ marginBottom: 16 }}
                onClick = {  showModal  } 
                data-testid = "add-note-button"
                disabled = { (queryLoading && !queryData) || queryData.process.topic.userPermissions == "read"}
                title = { queryData?.process?.topic?.userPermissions == "read" ? "You don't have permission to edit this process" : "" }   
                shape="round"
                icon={<PlusOutlined />}                 
                >
                    Create a note
            </Button>

            <Table 
                loading={queryLoading && !queryData}
                //style={{ wordBreak: "break-all" }}
                dataSource={queryData?.process?.noteSet}
                columns={ columns } 
                bordered
                rowKey={record => record.id} 
                size="small"
                pagination={false}
                scroll = {{ y: height - tableHeightOffest }}
                onRow = {(record, rowIndex) => {
                    return {
                        onClick: event => {handleEdit(record)}, // click row
                        
                        // change the mouse cursor to pointer when hovering over the row
                        onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                        onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                    };
                    }
                }
            />

            <Modal 
                title= { modalTitel }
                open={ isModalVisible } 
                onOk = { handleModalOk }
                onCancel = { handleModalCancel }
                destroyOnClose = {true}
                data-testid = "note-modal"
                
                // add a delete button to the header next to the close button

                footer={[
                
                    // if the modal is in edit mode, add a delete button to the footer
                    modalTitel == "Edit" ?
                        <Popconfirm title="Are you sure you want to delete this note?" onConfirm={() => handleDelete(modalId)} disabled = { queryData.process.topic.userPermissions == "read"}>
                            <Button type="primary" ghost data-testid="delete-note-button" title="Delete the note">Delete</Button>
                        </Popconfirm>
                        : null
                    ,
                    <Button 
                        type="primary" 
                        form="NoteForm" 
                        key="submit" 
                        htmlType="submit" 
                        data-testid="submit-note-button"
                        disabled = { queryLoading || queryData.process.topic.userPermissions == "read"}
                        title = { queryData?.process?.topic?.userPermissions == "read" ? "You don't have permission to edit this process" : "" }
                    >
                        Submit
                    </Button>

                    ]}
            >
                <Form
                    {...formLayout}  
                    id = "NoteForm"
                    onFinish={handleModalOk} 
                    initialValues={ modalInitialValues }
                > 
                    <Form.Item 
                        label="Time:"
                        name="processTime"
                        rules={[{ required: false }]} 
                    >
                        <MomentDatePicker
                            showTime = {true}
                        />

                    </Form.Item>

                    <Form.Item label="Note:" name="note" rules={[{ required: true }]} >
                    <TextArea 
                        rows={4} 
                        showCount
                        maxLength={10000}
                        data-testid="note-textarea"
                        
                    />
                    </Form.Item>
                    { modalMode=="edit" && 
                        <Form.Item name="user" label="User:" >
                            <Input disabled={true} data-testid="user-input"/>
                        </Form.Item>
                    }

                </Form>

                

            </Modal>

        </div>
    )

}

export default NoteTable;

