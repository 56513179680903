// what it does: this is a select component that allows the user to select a collection (aka topic)

import {useState, useEffect} from 'react';
import { useQuery, gql } from '@apollo/client';
import { Select, Spin} from 'antd';
//import { globalSelectedTopic } from '../Apollo';
//import { pollInterval } from "..";;

const GET_TOPICS = gql`
    query topics {
        topics {
            id
            name
            team {
                id
                name
            }
        }
    }
`;

interface SelectCollectionProps {
    setSelectedCollection: Function
}

function SelectCollection(props: SelectCollectionProps) {

    const [topicOptions, setTopicOptions] = useState<any>([]);

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery( GET_TOPICS , { fetchPolicy: 'network-only' });

    // use effect to create the options for the select
    useEffect(() => {

        let topicOptionsLocal: any = [];

        if (queryData) {

            // each topic is an option and each team is an optgroup

            // get the teams
            let teams: any = [];
            for (let i = 0; i < queryData.topics.length; i++) {
                if (!teams.includes(queryData.topics[i].team.name)) {
                    teams.push(queryData.topics[i].team.name);
                }
            }

            for (let i = 0; i < teams.length; i++) {
                // get the topics for each team
                let topics: any = [];
                for (let j = 0; j < queryData.topics.length; j++) {
                    if (queryData.topics[j].team.name == teams[i]) {
                        topics.push(queryData.topics[j]);
                    }
                }
                // create the options for each team
                let teamOptions: any = [];
                for (let j = 0; j < topics.length; j++) {
                    teamOptions.push({ label: topics[j].name, value: topics[j].id });
                }
                topicOptionsLocal.push({ label: 'Team: ' + teams[i], options: teamOptions });
            }
            setTopicOptions(topicOptionsLocal);
            
        }
    }
    , [queryData]);

    if (queryLoading) return <Spin />;
    if (queryError) return <p>Error: {queryError.message}</p>;

    return (
        <Select
            showSearch
            data-testid='collection-select'
            style={{ width: '100%' }}
            placeholder="Select a collection"
            optionFilterProp="children"
            onChange={(value: any) => props.setSelectedCollection(value)}
            options = {topicOptions}
            filterOption={(input: any, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        />
    )
}

export default SelectCollection;