import  {useState, useEffect} from 'react';
import { useQuery, gql } from '@apollo/client';
import { Spin, Select } from 'antd';
import { pollInterval } from "../..";

const GET_ANALYSES = gql`
    query analyses ($teamId: ID!) {
        analyses (teamId:$teamId) {
            id
            name
            description
            team {
                id
            }
        }
        
    }
`;

interface SelectAnalysisProps {
    teamId: string
    analysisId: string
    setAnalysisId: any
}

function SelectAnalysis(props: SelectAnalysisProps) {

    // ----------------------------------------------------------------------------------------
    // states
    const [analysisOptions, setAnalysisOptions] = useState<any[]>([]); //

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_ANALYSES, {
        variables: {
            teamId: props.teamId
        },
        //pollInterval: pollInterval,
    });

    // useEffect to set the analysis options
    useEffect(() => {
        if (queryData) {    
            let analyses:any = []
            // first get team and personal analyses
            let team_analyses = []
            let personal_analyses = []

                team_analyses = queryData?.analyses.filter((analysis: any) => analysis.team?.id != null).map((analysis: any) => ({
                label: analysis.name,
                value: analysis.id,
                //description: analysis.description,
                }))
                personal_analyses = queryData?.analyses.filter((analysis: any) => analysis.team?.id == null).map((analysis: any) => ({
                label: analysis.name,
                value: analysis.id,
                //description: analysis.description,
                }))

            analyses.push({'label': 'Team analyses', 'options': team_analyses})
            analyses.push({'label': 'Personal analyses', 'options': personal_analyses})
            setAnalysisOptions(analyses)
        }
    }, [queryData])

    if (queryError) {
        return (<p>Error : unable to get the analyses</p>)
    }

    if (queryLoading) {
        return (<Spin />)
    }

    const analyses = queryData.analyses;

    // ----------------------------------------------------------------------------------------
    // handlers

    const handleSelectAnalysis = (analysisId: string) => {
        props.setAnalysisId(analysisId)
    }

    // ----------------------------------------------------------------------------------------
    // render

    return (

        <div style={{ width: '100%' }} >
            <Select 
                style={{ width: '100%' }} 
                placeholder="Select an analysis" 
                options={analysisOptions}
                onSelect={handleSelectAnalysis}
                value={props.analysisId}
                data-testid='analysis-select'
            />

        </div>
    )

}

export default SelectAnalysis;

