import React from "react";
import { Card, Collapse} from 'antd';
import MediaTable from "./MediaTable";
import CompoundTable from "./CompoundTable";
import MixtureTable from "./MixtureTable";
import UndefinedIngredientTable from "./UndefinedIngredientTable";

const { Panel } = Collapse;
const { Meta } = Card;


interface MediaTabsInputType {
    topicId: string
}

function MediaTabs(props: MediaTabsInputType) {


    return(  
      <div>


            <Collapse defaultActiveKey={["1"]} accordion>

                <Panel header="Media" key="1" data-testid="media-panel">
                    <MediaTable topicId={props.topicId}/>
                </Panel>
                <Panel header="Mixtures" key="2" data-testid="mixture-panel">
                  <MixtureTable topicId={props.topicId}/>
                </Panel>
                <Panel header="Compounds" key="3" data-testid="compound-panel">
                    <CompoundTable topicId={props.topicId}/>
                </Panel>
                <Panel header="Undefined ingredients" key="4" data-testid="undefined-ingredient-panel">
                    <UndefinedIngredientTable topicId={props.topicId}/>
                </Panel>

            </Collapse>

          
      </div>
    )
  }
  
  export default MediaTabs;
