

import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card , Select, Space, Popconfirm, Button, Form, Spin, Modal, Input, message, Row, Col, Collapse} from 'antd';
const {Option} = Select;


const COPY_CALCULATOR_SAVE = gql`
    mutation copyCalculatorSave($input: CopyCalculatorSaveInputType!){
        copyCalculatorSave(input:$input) {
            calculatorSave {
                id
                name
                description
                expression
            }
        }
    }
`;


const ALL_CALCULATOR_SETS = gql`
    query calculatorSets {
        calculatorSets {
            id
            name
            description
            defaultForTopic
            topic {
                id
                name
            }
            calculatorsaveSet {
                id
                order
                name
                calcfunction
            }
        }
    }
`


interface CopyCalculatorSaveModalInputType {
    topicId:String,
    calculatorSetId:String,
    selectedCalculatorSave:String,
    isCopyModalVisible:boolean,
    setCopyModalVisible:Function
};

function CopyCalculatorSaveModal(props:CopyCalculatorSaveModalInputType) {

    const { loading:queryLoading, error: queryError, data: allCalculatorSets } = useQuery( ALL_CALCULATOR_SETS ); 

    const [copyMutation] = useMutation(COPY_CALCULATOR_SAVE, {
        refetchQueries: [ { query: ALL_CALCULATOR_SETS } ], 
        onCompleted(data) { message.success( "Calculator was copied successfully.") } ,
        onError(error) {message.error(error.message)}, 
    });

    const handleCopyModalCancel = () => {
        props.setCopyModalVisible(false)
    }

    const handleCopyModalOk = (values:any) => {
        
        let input = {
            id: props.selectedCalculatorSave,
            calculatorSetId: values["calculator_set"]
        }

        copyMutation( { variables: { input: input  } } );

        props.setCopyModalVisible(false)
    }

    const formLayout = {
        labelCol: { span: 6 },
    };

    if (queryError) return ( 
        <Card>Error: {queryError.message} </Card>
    );

    if (queryLoading) return (
        <Card><Spin/></Card>
    );

    // the topic of the selected calculator save
    //const topic = allCalculatorSets.calculatorSets.find( (calculatorSet:any) => calculatorSet.id == props.calculatorSetId ).topic
    
        
    return (
        <Modal 
            title= "Copy Calculator to another Calculator Set"
            open={ props.isCopyModalVisible } 
            onOk = { handleCopyModalOk }
            onCancel = { handleCopyModalCancel }
            destroyOnClose = {true}

            footer={[
                <Button type="primary" form="CalculatorSaveCopy" key="submit" htmlType="submit" data-testid="copy-calculator-submit">
                    Submit
                </Button>
                ]}
            >
            <br/>

            <Form
                {...formLayout}  
                id = "CalculatorSaveCopy"
                onFinish={handleCopyModalOk} 
            > 
                <Form.Item label="Calculator set:" name="calculator_set" rules={[{ required: true}]} >
                    <Select showSearch /*onChange={handleSelectTopic} */ data-testid="calculator-set-select" >
                        { allCalculatorSets.calculatorSets.filter( (calculatorSet:any) => { return calculatorSet.topic.id == props.topicId } ).map( (calculatorSet:any) => { return ( <Option key={calculatorSet.id} value={calculatorSet.id}>{calculatorSet.name}</Option>) } ) }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CopyCalculatorSaveModal;