import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql, useReactiveVar } from '@apollo/client';
import { Space, Spin, message, Select, Card, Transfer, Modal, Button, Form, Skeleton } from 'antd';
import ProcessesAdvancedSelect from './ProcessesAdvancedSelect';
//import { processExplorerSelectedProcesses, kpiAnalysisSelectedProcesses } from '../../Apollo';
import { processesLoading } from '../../Apollo';

const GET_SELECTED_PROCESSES = gql`
    query selectedProcesses ($analysisId:ID!) {
        
        selectedProcesses(analysisId:$analysisId) {
            id
            name
        }

        topics {
            id
            name
            team {
                id
                name
            }
        }

        userProfile {
            id
            darkMode
            selectedAnalysis {
                id
                userPermissions
            }
        }
    }
`;

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            darkMode
            selectedAnalysis {
                id
                name
                uniqueVars
                processes {
                    id
                    name
                }
                kpiAnalysisView {
                    id 
                }
                processExplorerView {
                    id
                }
            }
        }
    }
`

const SET_SELECTED_PROCESSES = gql`
    mutation setSelectedProcesses ($input:SetSelectedProcessesInputType!) {   
        setSelectedProcesses(input:$input) {
            selectedProcesses  {
                id
                name
            }
        }
    }
`;

const UPDATE_VIEW = gql`
    mutation updateProcessExplorerView($input: UpdateProcessExplorerViewInputType!) {
        updateProcessExplorerView( input:$input) {
            processExplorerView {
                id
                processesForDisplay {
                    id
                }
            }
        }
    }
`;

const GET_PROCESSES = gql`
    query Processes ($topicId:ID!) {   
        processesByTopicId(topicId:$topicId) {
            id
            name
            description
            topic {
                id
                name
            }
            metadataSet{
                id
                name
                value
            }
        }
    }
`;

interface SelectProcessInputType {
    view: any;
    analysis: any;
    displaySelectProcesses: boolean;
    selectAllProcesses?: boolean;
    processes: any;
    loading: boolean;
}

// default value for selectAllProcesses is false
SelectProcesses.defaultProps = {
    selectAllProcesses: false
};

function SelectProcesses(props: SelectProcessInputType) {

    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [selectedTopic, setSelectedTopic] = useState('-1');
    const [selectedMetadata, setSelectedMetadata] = useState('');
    const [selectedMetadataValue, setSelectedMetadataValue] = useState('');
    const [searchText, setSearchText] = useState('');
    const [targetKeys, setTargetKeys] = useState<any>([]);
    const [availableProcesses, setAvailableProcesses] = useState<string[]>([]);
    const [isModalAdvancedSelectVisible, setModalAdvancedSelectVisible] = useState(false);
    const processesLoadingVar = useReactiveVar(processesLoading);

    // metadata for selection of processes for display

    // use a query to get the selected processes
    const { loading: queryLoading1, error: queryError1, data: queryData1 } = useQuery(GET_SELECTED_PROCESSES, {
        variables: { analysisId: props.analysis.id },
        fetchPolicy: "cache-and-network"
    });

    const { loading: queryLoading2, error: queryError2, data: queryData2 } = useQuery(GET_PROCESSES,
        {
            variables: { topicId: selectedTopic },
            fetchPolicy: 'cache-and-network'
        }
    );

    // use a lazy query to get the processes
    //const [getProcesses, { data: queryData2, loading: queryLoading2, error: queryError2 }] = useLazyQuery(GET_PROCESSES, { variables: { topicId: selectedTopic } });

    const [setSelectedProcesses, { loading: mutationLoading }] = useMutation(SET_SELECTED_PROCESSES, {
        refetchQueries: [
            { query: GET_SELECTED_PROCESSES, variables: { analysisId: props.analysis.id } },
            { query: GET_USER_PROFILE }
        ],
        onError(error) { message.error(error.message) },
        onCompleted(data) {
            message.success("Processes updated successfully")
        }
    });

    const [updateView] = useMutation(UPDATE_VIEW, {
        onError(error) { message.error(error.message) },
    });

    // ----------------------------------------------------------------------------------------
    // functions

    // use Effect to set the targetKeys with selected  processes
    useEffect(() => {
        if (queryData1) {
            // create an array of process ids
            const selectedProcesses = queryData1.selectedProcesses.map((process: any) => process.id);
            setTargetKeys(selectedProcesses);
        }

        // if nothing is selected, then select the first process
        //if (queryData2 && queryData2.processes.processes.length > 0 && targetKeys.length === 0) {
        //   setTargetKeys([queryData2.processes.processes[0].id]);
        //}

    }, [queryData1]);

    // use Effect to select all processes if selectAllProcesses is true and there ar no selected processes for display
    useEffect(() => {
        // if user has permission to change the analysis 
        if (queryData1?.userProfile?.selectedAnalysis.userPermissions != "read") {
            if (props.selectAllProcesses && queryData1 && queryData1.selectedProcesses.length > 0 && props.view.processesForDisplay.length === 0) {
                // execute the select all processes function
                selectAllSelectedProcessesForDisplay();
            }
        }
    }, [queryData1]);

    // use effect to set the available processes using the processes prop
    useEffect(() => {
        if (props.processes) {
            // the available processes should be a list of ids
            const availableProcesses = props.processes.map((process: any) => (process.id))
            setAvailableProcesses(availableProcesses)
        }
    }, [props.processes])



    const showModal = (values: any) => {
        setModalVisible(true)
    }

    const handleModalOk = (values: any) => {
        setSelectedProcesses({ variables: { input: { 'processIds': targetKeys, 'analysisId': props.analysis.id } } }) // perform the mutation
        setModalVisible(false);
        setSelectedMetadataValue("")
        setSelectedMetadata("")
        setSelectedTopic("-1")
        setSearchText("")
    };

    const handleModalCancel = (values: any) => {
        setModalVisible(false);
        setSelectedMetadataValue("")
        setSelectedMetadata("")
        setSelectedTopic("-1")
        setSearchText("")
    };


    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    };

    if (queryError1) return (
        <div>Error: {queryError1.message} </div>
    );

    if (queryError2) return (
        <div>Error: {queryError2.message} </div>
    );

    if ((queryLoading1 && !queryData1) || props.view == null || processesLoadingVar || props.loading) return (
        <Card><div data-testid="select-processes-loading"><Skeleton active /></div></Card>
    );

    let filteredProcesses: any = []
    if (queryData2) {
        filteredProcesses = queryData2.processesByTopicId;
    }

    // filter processes based on the selected metadata and metadata value
    // if selectedMetadata is empty, show all processes
    const filteredProcesses2 = filteredProcesses.filter((process: any) => {
        if (selectedMetadata == "") {
            return true;
        } else if (selectedMetadata != "" && selectedMetadataValue === "") {

            // show processes that have a meta data with the selected name
            return process.metadataSet.some((metadata: any) => {
                return metadata.name === selectedMetadata;
            }
            );

        } else {
            for (var i = 0; i < process.metadataSet.length; i++) {
                if (process.metadataSet[i].name === selectedMetadata && process.metadataSet[i].value === selectedMetadataValue) {
                    return true;
                }
            }
            return false;
        }
    }
    );

    // filter processes based on the search text
    const filteredProcesses3 = filteredProcesses2.filter((process: any) => {

        if (searchText === '') {
            return true;
        } else {
            return process.name.toLowerCase().includes(searchText.toLowerCase()) || process.description?.toLowerCase().includes(searchText.toLowerCase());
        }
    }
    );

    let finalProcesses = filteredProcesses3;
    const transferData = []
    for (let i = 0; i < finalProcesses.length; i++) {

        transferData.push({
            key: finalProcesses[i].id,
            title: finalProcesses[i].name,
            description: finalProcesses[i].topic.name,
        });
    }

    // add the already selected processes to the transferData

    for (let i = 0; i < targetKeys.length; i++) {
        const selectedProcess = queryData1?.selectedProcesses?.find((process: any) => process.id == targetKeys[i]);
        if (selectedProcess) {

            transferData.push({
                key: selectedProcess?.id,
                title: selectedProcess?.name,
                description: selectedProcess?.topic?.name,
            });
        }
    }


    // loop through processes - then loop through metadata of each process and create a list of unique metadata names
    const uniqueMetadata: any = []
    for (let i = 0; i < filteredProcesses.length; i++) {
        for (let j = 0; j < filteredProcesses[i].metadataSet.length; j++) {
            const metadataName = filteredProcesses[i].metadataSet[j].name;
            if (!uniqueMetadata.includes(metadataName)) {
                uniqueMetadata.push(metadataName);
            }
        }
    }

    // loop through processes then loop through metadata of each process and create a list of unique metadata values of the selected metadata
    const uniqueMetadataValues: any = []
    for (let i = 0; i < filteredProcesses.length; i++) {
        for (let j = 0; j < filteredProcesses[i].metadataSet.length; j++) {
            const metadataName = filteredProcesses[i].metadataSet[j].name;
            const metadataValue = filteredProcesses[i].metadataSet[j].value;
            if (metadataName === selectedMetadata) {
                if (!uniqueMetadataValues.includes(metadataValue)) {
                    uniqueMetadataValues.push(metadataValue);
                }
            }
        }
    }

    // ----------------------------------------------------------------------------------------
    // create the options for the topic select
    // each topic is an option and each team is an optgroup

    let topicOptions: any = [];

    // get the teams
    let teams: any = [];
    for (let i = 0; i < queryData1.topics.length; i++) {
        if (!teams.includes(queryData1.topics[i].team.name)) {
            teams.push(queryData1.topics[i].team.name);
        }
    }

    for (let i = 0; i < teams.length; i++) {
        // get the topics for each team
        let topics: any = [];
        for (let j = 0; j < queryData1.topics.length; j++) {
            if (queryData1.topics[j].team.name == teams[i]) {
                topics.push(queryData1.topics[j]);
            }
        }
        // create the options for each team
        let teamOptions: any = [];
        for (let j = 0; j < topics.length; j++) {
            teamOptions.push({ label: topics[j].name, value: topics[j].id });
        }
        topicOptions.push({ label: 'Team: ' + teams[i], options: teamOptions });
    }
    // ----------------------------------------------------------------------------------------

    const onSelectChange = (sourceSelectedKeys: any, targetSelectedKeys: any) => {

        //console.log('sourceSelectedKeys:', sourceSelectedKeys);
        //console.log('targetSelectedKeys:', targetSelectedKeys);
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {

        //console.log('targetKeys:', nextTargetKeys);
        //console.log('direction:', direction);
        //console.log('moveKeys:', moveKeys);
        //selectedProcesses(nextTargetKeys)
        //setSelectedProcesses({ variables: { 'processes': nextTargetKeys } }) // perform the mutation
        //sessionStorage.setItem('selectedProcesses', JSON.stringify(nextTargetKeys)); // store to local storage
        setTargetKeys(nextTargetKeys);

    };

    const onMetaDataChange = (value: any) => {
        setSelectedMetadataValue("")
        setSelectedMetadata(value)
    }


    function onChangeSelectedProcessesForDisplay(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedProcesses = Array.from(event.target.selectedOptions, (option) => option.value);
        // update the view
        let input = {
            'processesForDisplay': selectedProcesses,
            'id': props.view.id
        }

        updateView({
            variables: { input: input }, optimisticResponse: {
                __typename: "Mutation",
                updateProcessExplorerView: {
                    __typename: "UpdateProcessExplorerViewPayload",
                    processExplorerView: {
                        __typename: "ProcessExplorerViewType",
                        id: props.view.id,
                        processesForDisplay: selectedProcesses.map(process => ({
                            __typename: "ProcessType",
                            id: process
                        })),
                    }
                }
            }
        });
    }

    function selectAllSelectedProcessesForDisplay() {
        // update the view
        let input = {
            'processesForDisplay': queryData1?.selectedProcesses.map((process: any) => process.id),
            'id': props.view.id
        }

        updateView({
            variables: { input: input }, optimisticResponse: {
                __typename: "Mutation",
                updateProcessExplorerView: {
                    __typename: "UpdateProcessExplorerViewPayload",
                    processExplorerView: {
                        __typename: "ProcessExplorerViewType",
                        id: props.view.id,
                        processesForDisplay: queryData1?.selectedProcesses.map((process: { id: string }) => process.id).map((process: string) => ({
                            __typename: "ProcessType",
                            id: process
                        })),
                    }
                }
            }
        });
    }

    let bgcolor = '#ffffff'
    let borderColor = '#d9d9d9'
    let color = '#000000'
    let selectionColor = '#e2e2e2'
    if (queryData1?.userProfile?.darkMode) {
        bgcolor = '#141414'
        color = '#ffffff'
        selectionColor = '#3b3b3b'
        borderColor = "#3d3d3d"
    }


    let selectedProcessesForDisplayIds = props.view.processesForDisplay.map((process: any) => process.id) || []

    let totalNumberofProcesses = queryData1?.selectedProcesses?.length || 0

    return (
        <Space direction="vertical" style={{ width: '100%' }}>

            <Button
                type="primary"
                onClick={showModal}
                data-testid='load-processes'
                size="small"
                style={{ marginBottom: 10, width: "100%" }}
                disabled={queryData1.userProfile.selectedAnalysis.userPermissions == "read"}
                title={queryData1.userProfile.selectedAnalysis.userPermissions == "read" ? "You do not have permission to add or remove processes in this analysis" : "Add or remove processes in to/from this analysis"}
            >
                Add or remove processes
            </Button>

            {/* while processes are loading show a progress bar that completes when processes are loaded*/}
            { }

            {/* create a select where individual processes can be turned on or off */}
            {props.displaySelectProcesses &&
                <div>
                    <select
                        multiple
                        disabled={queryData1.userProfile.selectedAnalysis.userPermissions == "read"}
                        title={queryData1.userProfile.selectedAnalysis.userPermissions == "read" ? "You do not have permission to change this analysis" : ""}
                        style={{
                            width: '100%',
                            marginBottom: 10,
                            minHeight: 'calc(100vh * 0.25)',
                            backgroundColor: bgcolor,
                            color: color,
                            border: "1px solid",
                            borderColor: borderColor,
                            borderRadius: "5px",
                            overflow: "auto",

                        }}

                        onChange={onChangeSelectedProcessesForDisplay}
                        defaultValue={selectedProcessesForDisplayIds}
                        data-testid='processes-display-select'
                        id="processes-display-select"
                    >

                        {queryData1?.selectedProcesses?.map((process: any) => (
                            <option
                                key={process.id}
                                value={process.id}
                                style={{
                                    backgroundColor: selectedProcessesForDisplayIds.includes(process.id) ? selectionColor : bgcolor,
                                    // make the color different if the process is disabled
                                    color: availableProcesses.includes(process.id) || availableProcesses.length === 0 ? color : "#a6a6a6"
                                }}
                                // if process.id is in availableProcesses state variable then enabled it, otherwise disable it
                                // also make sure that props.processes is defiend, otherwise disabled should be false
                                disabled={
                                    props.processes && !availableProcesses.includes(process.id)
                                }

                            >
                                {process.name}
                            </option>
                        ))}

                    </select>
                    {/*<Button type="link" size="small" onClick={clearSelectedProcessesForDisplay} data-testid='process-select-clear'>Clear</Button>*/}

                    <Button type="link" size="small" onClick={selectAllSelectedProcessesForDisplay} data-testid='process-select-all' disabled={queryData1.userProfile.selectedAnalysis.userPermissions == "read"}>
                        Select all
                    </Button>

                    <Button type="link" size="small" onClick={() => setModalAdvancedSelectVisible(true)} data-testid='process-select-advanced' disabled={queryData1.userProfile.selectedAnalysis.userPermissions == "read"}>
                        Advanced select
                    </Button>

                    {/*<Button type="link" size="small" data-testid='process-select-metadata'>Select using metadata</Button>*/}

                </div>
            }


            <Modal
                title='Add or remove analysis processes'
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                width={800}
                data-testid='select-processes-modal'

                footer={[
                    <Button type="primary" form="SelectProcessesForm" key="submit" htmlType="submit" data-testid="select-processes-ok" >
                        Ok
                    </Button>
                ]}
            >

                <Form
                    {...formLayout}
                    id="SelectProcessesForm"
                    onFinish={handleModalOk}

                >
                    {/* create a dropdown that contains all topics */}
                    <Form.Item
                        label="Collection"
                        name="topic"
                        style={{ marginTop: 20 }}
                    >
                        <Select
                            showSearch
                            data-testid='topic-select'
                            style={{ width: '100%' }}
                            placeholder="Select a collection"
                            optionFilterProp="children"
                            onChange={(value: any) => setSelectedTopic(value)}
                            //value={selectedTopic}
                            //defaultValue={selectedTopic}
                            options={topicOptions}
                            filterOption={(input: any, option: any) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />

                    </Form.Item>

                    {/* create a dropdown for selecting meta data */}
                    <Form.Item
                        label="Metadata"
                        name="metadata"
                    >
                        <Select
                            showSearch
                            data-testid='metadata-select'
                            style={{ width: '100%' }}
                            placeholder="Select a metadata"
                            optionFilterProp="children"
                            onChange={onMetaDataChange}
                            //value={selectedMetadata}
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option key={""} value={""}>{"---"}</Select.Option>
                            {uniqueMetadata.map((metadata: any) => (
                                <Select.Option key={metadata} value={metadata}>{metadata}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* create a dropdown for selecting meta data values */}

                    <Form.Item
                        label="Metadata value"
                        name="metadataValue"
                    >
                        <Select
                            showSearch
                            data-testid='metadata-value-select'
                            style={{ width: '100%' }}
                            placeholder="Select a metadata value"
                            optionFilterProp="children"
                            onChange={(value: any) => setSelectedMetadataValue(value)}
                            //value={selectedMetadataValue}
                            //defaultValue={selectedMetadataValue}
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option key={""} value={""}>{"---"}</Select.Option>
                            {uniqueMetadataValues.map((metadataValue: any) => (
                                <Select.Option key={metadataValue} value={metadataValue}>{metadataValue}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* create a search text field */}
                    {/*
                    <Form.Item
                        label="Search"
                        name="search"
                    >
                        <Input
                            placeholder="Search"
                            data-testid='search-input'
                            onChange={(e: any) => setSearchText(e.target.value)}
                        />

                    </Form.Item>
                    */}


                    <Form.Item></Form.Item>

                    {(queryLoading2 && !queryData2) ? <Skeleton paragraph={{ rows: 11 }} active /> :


                        <Transfer
                            dataSource={transferData}
                            showSearch
                            targetKeys={targetKeys}
                            disabled={queryLoading2 && !queryData2}
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            titles={['Available processes', 'Loaded processes']}

                            render={item => `${item.title}`}
                            listStyle={{
                                width: 350,
                                height: 400,
                            }}
                        />

                    }

                </Form>

            </Modal>

            <Modal
                title='Loading processes'
                open={mutationLoading}
                footer={[]}
            >
                <Skeleton active />
            </Modal>

            <ProcessesAdvancedSelect
                isOpen={isModalAdvancedSelectVisible}
                setOpen={setModalAdvancedSelectVisible}
                analysis={props.analysis}
                view_id={props.view.id}
                processesForDisplay={props.view.processesForDisplay}
                processes = {props.processes}
            />


        </Space>


    )

}

export default SelectProcesses