import { useState, useEffect } from 'react';
import { Card, Skeleton, message, Modal, Progress } from 'antd';
import PageHeader from '../PageHeader';
import { useQuery, gql, useLazyQuery, useApolloClient, useReactiveVar } from '@apollo/client';
import ViewPage from './ViewPage';
import ProcessExplorerMenu from './ProcessExplorerMenu';
import ProcessExplorerPlots from './ProcessExplorerPlots';
import HeaderExtra from '../HeaderExtra';
import ProcessExplorerSidebar from './ProcessExplorerSidebar';
import useWindowDimensions from '../useWindowDimensions';
import { processesClient, processesLoading, processesLoadingProgress } from '../../Apollo';

//import { pollInterval } from '../..';
//import { setItem, getItem } from '../../storage';

import { CORE_ANALYSIS_FIELDS, CORE_PROCESS_FIELDS } from '../fragments';
import { EXTENDED_PROCESS_FIELDS } from '../fragments';
import { CORE_VIEW_FIELDS } from '../fragments';
import { CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS } from '../fragments';
import { EXTENDED_PROCESS_FIELDS_WITH_DATA } from '../fragments';

//import ReactGA from 'react-ga';
//const GA_account = 'UA-219470712-1'


const GET_CLIENT_VARS = gql`
    query ClientVars {
        processExplorerSidebarVisible @client
    }
`;

const GET_USER_PROFILE = gql`

    ${CORE_ANALYSIS_FIELDS}
    ${CORE_VIEW_FIELDS}
    ${CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS}
    ${CORE_PROCESS_FIELDS}

    

    query userProfile {
        userProfile {
            id
            selectedAnalysis {
                ...CoreAnalysisFields
                uniqueVars
                uniqueParams
                uniqueMeta
                processes {
                    ...CoreProcessFields                
                }
                processExplorerView {
                    ...CoreViewFields
                    viewpageSet {
                        ...CoreProcessExplorerViewPageFields
                    }
                }
            }
        }
    }   
`;

// get one process
const GET_PROCESS = gql`
    ${EXTENDED_PROCESS_FIELDS_WITH_DATA}
    query process ($id: ID!) {
        process(id: $id) {
            ...ExtendedProcessFieldsWithData
        }
    }
`;


interface ProcessExplorerInputType {
    display?: boolean
}

// set the default value of display to true
ProcessExplorer.defaultProps = {
    display: true
}

function ProcessExplorer(props: ProcessExplorerInputType) {

    const client = useApolloClient();

    //ReactGA.initialize(GA_account, { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/process-explorer/');

    const { height, width } = useWindowDimensions();

    // get the reactive variables from Apollo
    const { data: clientVars } = useQuery(GET_CLIENT_VARS);

    // get the user profile from Apollo
    const { loading: queryLoading1, error: queryError1, data: queryData1 } = useQuery(GET_USER_PROFILE, {
        fetchPolicy: 'cache-and-network' , 
        //pollInterval: 15000,
    });

    /*
    // use a lazy query to get one process
    const [loadProcess, { loading: lazyLoading, data: lazyData, called, error: lazyError }] = useLazyQuery(GET_PROCESS, {
        fetchPolicy: 'no-cache',
    });
    */


    // -------------------------------------------------------------------------------------------------------------------
    // state variables

    const [selectedSidebarProcess, setSelectedSidebarProcess] = useState("");
    
    const [loading, setLoading] = useState(false);
    //const [progress, setProgress] = useState(0);
    //const [processBeingLoaded, setProcessBeingLoaded] = useState("");

    const [analysisProcessIds, setAnalysisProcessIds] = useState<any[]>([]);
    const [analysisProcessesLastUpdated, setAnalysisProcessesLastUpdated] = useState<any[]>([]);
    const [processFetchingComplete, setProcessFetchingComplete] = useState(false);
    const processesClientVar = useReactiveVar(processesClient);
    const processesLoadingVar = useReactiveVar(processesLoading);
    const processesLoadingProgressVar = useReactiveVar(processesLoadingProgress);

    // -------------------------------------------------------------------------------------------------------------------
    // functions

    const verbose = false;

    // this function compares two arrays for equality 
    // the reason we have this function is because the arrays are objects, and the equality operator === does not work on objects

    function arraysEqual(a: any[], b: any[]) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    // -------------------------------------------------------------------------------------------------------------------
    // useEffect 0 to set the analysisProcessIds and analysisProcessesLastUpdated state variables when the query data changes
    useEffect(() => {

        if (verbose) {
            console.log("Running useEffect 0")
        }

        if (queryData1?.userProfile.selectedAnalysis.processes) {

            const analysisProcessIdsLocal = queryData1.userProfile.selectedAnalysis.processes.map((process: any) => process.id);
            if (!arraysEqual(analysisProcessIdsLocal, analysisProcessIds)) {

                setAnalysisProcessIds(analysisProcessIdsLocal);
                if (verbose) {
                    console.log("analysisProcessIds", analysisProcessIds);
                }
            }

            const analysisProcessesLastUpdatedLocal = queryData1.userProfile.selectedAnalysis.processes.map((process: any) => process.lastUpdated);
            if (!arraysEqual(analysisProcessesLastUpdatedLocal, analysisProcessesLastUpdated)) {
                setAnalysisProcessesLastUpdated(analysisProcessesLastUpdatedLocal);
                if (verbose) {
                    console.log("analysisProcessesLastUpdated", analysisProcessesLastUpdated);
                }
            }
        }

    }, [queryData1?.userProfile.selectedAnalysis.processes]);


    const routes = [
        {
            path: '',
            breadcrumbName: "Analysis: " + queryData1?.userProfile.selectedAnalysis.name,
        }
    ];

    // -------------------------------------------------------------------------------------------------------------------

    if (props.display === false) {
        return null;
    }

    const processes = processesClientVar;
    //const processes = queryData1?.userProfile.selectedAnalysis.processes;

    return (

        <div data-testid='process-explorer'>

            <PageHeader
                title={"Process Explorer"}
                breadcrumb={{ routes }}
                extra={<HeaderExtra />}
            />


            <div style={{ display: 'flex' }}>

                <div style={{ flex: '0 0 220px' }}>
                    <ProcessExplorerMenu
                        view={queryData1?.userProfile.selectedAnalysis.processExplorerView}
                        loading={(queryLoading1 && !queryData1) || loading || processesLoadingVar}
                        processes={processes}
                        //selectedProcesses={queryData1?.userProfile.selectedAnalysis.processes}
                        selectedProcesses={processes}
                        setLoading={setLoading}
                        analysis={queryData1?.userProfile.selectedAnalysis}
                    />
                </div>

                <div style={{ overflow: 'hidden', width: "100%" }} >

                    <ProcessExplorerPlots
                        processes={processes}
                        setSelectedSidebarProcess={setSelectedSidebarProcess}
                        loading={(queryLoading1 && !queryData1) || loading || processesLoadingVar}
                        selectedView={queryData1?.userProfile.selectedAnalysis.processExplorerView}
                        //selectedProcesses={queryData1?.userProfile.selectedAnalysis.processes}
                        selectedProcesses={processes}
                        processFetchingComplete={processFetchingComplete}
                        analysis={queryData1?.userProfile.selectedAnalysis}
                    />

                    <ViewPage
                        pageName="ProcessExplorer"
                        analysis={queryData1?.userProfile.selectedAnalysis}
                        setUpdateViewLoading={setLoading}
                        loading={( queryLoading1 && !queryData1) || loading || processesLoadingVar }
                    />

                </div>


                {clientVars.processExplorerSidebarVisible && !(( queryLoading1 && !queryData1)) &&
                    <div style={{ flex: '0 0 350px' }} >
                        <ProcessExplorerSidebar
                            // filter processesClient to include only those whose IDs are in analysisProcessIds
                            //processes={queryData1?.userProfile.selectedAnalysis.processes}
                            processes={processes}
                            selectedSidebarProcess={selectedSidebarProcess}
                            setSelectedSidebarProcess={setSelectedSidebarProcess}
                            view={queryData1?.userProfile.selectedAnalysis.processExplorerView}
                            loading={( queryLoading1 && !queryData1) || processesLoadingVar}
                            analysis={queryData1?.userProfile.selectedAnalysis}
                        />
                    </div>
                }


                {clientVars.processExplorerSidebarVisible && (( queryLoading1 && !queryData1)) &&
                    <div style={{ flex: '0 0 20%', marginLeft: 10 }} >
                        <Card style={{ height: height - 130 }}><Skeleton paragraph={{ rows: 22 }} active /></Card>
                    </div>
                }

                <Modal
                    title="Loading in processes..."
                    open={false}
                    footer={null}
                    //closable={false}
                    onCancel={() => processesLoading(false)}
                >
                    <p>Please wait...</p>
                    <Progress percent={Math.round(processesLoadingProgressVar)} status="active" style={{ marginBottom: 20 }} />
                </Modal>


            </div>
        </div>
    )
}

export default ProcessExplorer;