import React , {useEffect} from 'react';
import { useQuery, gql } from '@apollo/client';
import { Space, Spin, Select, Card } from 'antd';
import {Process} from '../../models/Process';

const { Option } = Select;

const GET_PROCESSES = gql`
    query processes ($topicId:ID!) {   
        processes (topicId:$topicId) {
            processes {
                id
                name
            }
        }
    }
`;

interface ImportProcessInputType {
    setProcess:Function
    setProcessName:Function
    process:string
    topic:string
}

function ImportProcess(props: ImportProcessInputType) {

    const { loading:queryLoading, error:queryError, data:queryData } = useQuery(GET_PROCESSES , {variables: { topicId:props.topic }});

    const handleProcessSelect = (input:any)=>{
        props.setProcess(input)
        let process = queryData.processes.processes.find((process:any)=> process.id == input )
        props.setProcessName(process.name)
    }

    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );

    if (queryLoading) return (
        <Card><Spin /></Card>
    );

    return (
        <div style={{ width: '100%' }} >

            <Space direction="vertical" size="small" style={{ width: '100%' }} >

                <Select
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onChange={handleProcessSelect}
                    value={ props.process }
                    data-testid="select-process"
                >

                    {queryData.processes.processes.map( (process:Process) => { return <Option key={process.id} value={process.id} data-testid={process.id} title={process.name}>{process.name}</Option> })}

                </Select>

            </Space>
        </div>
    );

}

export default ImportProcess;