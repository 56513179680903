import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card, Skeleton, Select} from 'antd';
import useWindowDimensions from '../useWindowDimensions';
import moment from 'moment';

const { Option } = Select;

const GET_ORGANIZATION_LOGS = gql`
    query {  
        organizationLogs {
            user {
              username
            }
            datetime
            message
        }
    }
`;



const objectName = "User";

interface OrganizationLogsInputType {
};

function OrganizationLogs(props: OrganizationLogsInputType) {

    // window dimensions
    const { height, width } = useWindowDimensions();

    // ----------------------------------------------------------------------------------------
    // states

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_ORGANIZATION_LOGS , {
        fetchPolicy: 'cache-and-network',
    });


    // ----------------------------------------------------------------------------------------

    const TableColumns = [
        {
            title: 'User Name',
            dataIndex: ['user','username'],
            editable: false,
            width: '10%',
        },
        {
            title: 'Datetime',
            dataIndex: 'datetime',
            editable: false,
            width: '10%',
            render : (text:String, record:any) => (
                // convert from utc to local time
                record.datetime != null ? moment.utc(record.datetime).local().format('DD.MM.YYYY HH:mm:ss') : null

            ),
        },
        {
            title: 'Message',
            dataIndex: 'message',
            editable: false,
        },
    ]

    if (queryLoading && !queryData) {
        return (
            <Skeleton active/>
        )
    }

    if (queryError) return (
        <Card>Error: {queryError.message} </Card>
    );

    return (
            
        <Table
            loading={queryLoading && !queryData}
            dataSource={queryData.organizationLogs}
            columns={TableColumns}
            bordered
            rowKey={record => record.id}
            size="small"
            pagination={false}
            scroll={{ y: height - 300 }}

        />
     

    );
}

export default OrganizationLogs;

