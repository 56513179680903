import React, {useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card , Space, Popconfirm, Button, Skeleton, Modal, message, Select, Statistic} from 'antd';
import moment from 'moment';
import { CloseCircleOutlined , CheckCircleOutlined , HourglassOutlined , ExclamationOutlined} from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import { CORE_IMPORT_FIELDS } from '../fragments';

const { Option } = Select;

export const GET_IMPORTS = gql`
    ${CORE_IMPORT_FIELDS}
    query imports($teamId:ID!) {  
        imports(teamId:$teamId) {
            ...CoreImportFields
        }
    }
`;

const REIMPORT = gql`
    mutation reimport($input:ReimportInputType!) {
        reimport(input:$input) {
            ok
        }
    }
`;



interface ImportHistoryTableInputType {
    teamId:number,
};

function ImportHistoryTable(props:ImportHistoryTableInputType) {

    // ----------------------------------------------------------------------------------------
    // states

    const { height, width } = useWindowDimensions();

    const [isModalVisible, setModalVisible] = useState(false);
    const [outputMessageVisible, setOutputMessageVisible] = useState(false);
    const [outputMessageContent, setOutputMessageContent] = useState("");
    const [outputParameters, setOutputParameters] = useState("");

    let outputMessageContent_split = outputMessageContent.split("\n");

    // remove empty strings
    outputMessageContent_split = outputMessageContent_split.filter(function (el) {
        return el != "";
    });
    

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading:queryLoading1, error: queryError1, data: queryData1 } = useQuery(GET_IMPORTS , { variables:{teamId:props.teamId} , 
        fetchPolicy: 'cache-and-network',
        pollInterval: 5000,

    });

    
    const [reimport, { loading: mutationLoading, error: mutationError }] = useMutation(REIMPORT, {
        onCompleted: (data) => {
            message.success("Re-import will run in the background")
        },
        onError: (error) => {
            message.error("Re-import failed")
        }, 
        refetchQueries: [{ query: GET_IMPORTS, variables: { teamId: props.teamId } }],
    });
  

    // ----------------------------------------------------------------------------------------
    
    
    function reimportHandler(record:any) {
        reimport({ variables: { input: { importId: record.id } } })
    }

    function showOutputMessage(record:any) {
        setOutputMessageContent(record.backgroundTask.outputMessage)
        setOutputParameters(record.backgroundTask.outputParameters)
        setOutputMessageVisible(true)
    }
 

    const formLayout = {
        labelCol: { span: 4 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 12 },
    };


    const TableColumns = [

        {
            title: 'Import type',
            dataIndex: 'name',
            editable: false,
            render : (text:String, record:any) => {

                if (record.importType === "EXCEL"){
                    return "Excel"
                } else if (record.importType === "AMBR"){
                    return "AMBR"
                } else if (record.importType === "MAT"){
                    return "Matlab"
                } else {
                    return "Unknown"
                } 
            }
        },
        {
            title: 'Collection',
            dataIndex: ['topic', 'name'],
            editable: false,
            //width: '30%',

        },
        {
            title: 'File(s)',
            editable: false,
            render : (text:String, record:any) => {
                return record.files.map((file:any) => file.name).join(", ");
            }
        },
        {
            title: 'Processes',
            editable: false, 
            render : (text:String, record:any) => {
                return record.processes.map((process:any) => process.name).join(", ");
            }
        },
        {
            title: 'User',
            dataIndex: ['user', 'username'],
            editable: false,
            //width: '30%',
        },
        {
            title: 'Date',
            dataIndex: 'creationDate',
            editable: false,
            render : (text:String, record:any) => (
                record.creationDate != null ? moment.utc(record.creationDate).local().format('DD.MM.YYYY HH:mm:ss') : null
            ),

        },
        {
            title: 'Status',
            dataIndex: ['backgroundTask', 'status'],
            editable: false,
            render : (text:String, record:any) => {
          
                if (record.backgroundTask.status === "COMPLETED"){
                    return <span style={{color: "green"}} title="Completed"><CheckCircleOutlined/></span>

                } else if (record.backgroundTask.status === "FAILED"){
                    return <span style={{color: "red"}} title="Failed"><CloseCircleOutlined /></span>
                } else if (record.backgroundTask.status === "RUNNING"){
                    return <span style={{color: "blue"}} title="Running"><HourglassOutlined /></span>
                } else if (record.backgroundTask.status === "WARNING"){
                    return <span style={{color: "yellow"}} title="Warning"><ExclamationOutlined /></span>
                }
            }
        },
        {
            title : 'Output',
            key: 'output',
            render : (text:String, record:any) => (
                <div>
                    <Space>
                        <Button type="link" onClick={() => showOutputMessage(record)} data-testid={"output-button-" + record.name} >
                            Output
                        </Button>
                    </Space>
                </div>
            ),


        },
        {
            title: 'Actions',
            key: 'actions',
            width: '10%',
            render: (text:String, record:any) => (
                <div>
                    <Space>
                        <Popconfirm title="Re-import?" onConfirm={() => reimportHandler(record)}  >
                            <Button type="link" data-testid={"reimport-button-" + record.name} >
                                Re-import
                            </Button>
                        </Popconfirm>
                    </Space>
                </div>
            ),
        },
    ]

    if (queryLoading1 && !queryData1){
        return (
            <Skeleton active/>
        )
    }

    if (queryError1) return ( 
        <Card>Error: {queryError1.message} </Card>
    );


    return (

        <div>
                <Table 
                    loading={queryLoading1 && !queryData1}
                    dataSource={queryData1.imports}
                    columns={ TableColumns } 
                    bordered
                    rowKey={record => record.id} 
                    size="small"
                    pagination={false}
                    scroll={{ y: height - 300 }}
                />
                <Modal
                    title={'Loading...'}
                    open={mutationLoading}
                    footer = {null}
                    closable = {false}
                >
                    <Skeleton active/>
                </Modal>

                <Modal
                title="Output:"
                open={outputMessageVisible}
                width={"70%"}
                onCancel={() => setOutputMessageVisible(false)}
                footer={null}
            >

                <div style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>

                    {/* display each outputParamets as a Statistic element - outPutParameters is an object */}


                    {outputParameters && Object.values(JSON.parse(outputParameters)).map((parameter: any, index: any) => (
                        <Card style={{ marginBottom: 10 }} key={index}>
                            <Statistic title={parameter.name} value={parameter.value} key={index} suffix={parameter.unit} />
                        </Card>

                    ))}
                    { }

                    <Card title="Messages:">
                        {outputMessageContent_split.map((item, i) => (
                            <div key={i}>
                                <p>{item}</p>
                            </div>
                        ))}
                    </Card>
                </div>

            </Modal>



        </div>

    );
}

export default ImportHistoryTable;

