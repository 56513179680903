import {useState, useEffect} from 'react';
import { Skeleton , Card, Avatar, Modal, Button, Form, Popover, Space, Input, message , Popconfirm, Empty, Select}  from 'antd';
import useWindowDimensions from '../useWindowDimensions';
import { useQuery, useMutation, gql } from '@apollo/client';
import {UsergroupAddOutlined , EllipsisOutlined, SearchOutlined} from '@ant-design/icons';
import DeleteModal from '../DeleteModal';
import { useNavigate } from 'react-router-dom';
import { pollInterval } from '../..';
import { globalSelectedTopic } from '../../Apollo';
import { CORE_TEAM_FIELDS, CORE_TOPIC_FIELDS } from '../fragments';

const GET_TEAMS =   gql`
    ${CORE_TEAM_FIELDS}
    query teams {
        teams {
            ...CoreTeamFields
        }

        me {
            id
        }
        
        userProfile {
            id
            darkMode
            selectedTeam {
                id
            }
        }
    }
`

const CREATE_TEAM = gql`
    mutation createTeam($input: CreateTeamInputType!) {
        createTeam( input:$input) {
            team {
                id
                name
            }
        }
    }
`

const UPDATE_TEAM = gql`
    mutation updateTeam($input: UpdateTeamInputType!) {
        updateTeam( input:$input) {
            team {
                id
                name
            }
        }
    }
`

const DELETE_TEAM = gql`
    mutation deleteTeam($id: ID!) {
        deleteTeam( id:$id) {
            id
            ok
        }
    }
`

const REMOVE_USER_FROM_TEAM = gql`
    mutation removeUserFromTeam($input: RemoveUserFromTeamInputType!) {
        removeUserFromTeam( input:$input) {
            ok
        }
    }
`;


const UPDATE_USER_PROFILE = gql`
    ${CORE_TOPIC_FIELDS}
    mutation updateUserProfile($input: UpdateUserProfileInputType!) {
        updateUserProfile( input:$input) {
            userProfile {
                id
                selectedTeam {
                    id
                    topicSet {
                        ...CoreTopicFields
                    }

                }

            }
        }
    }
`;


interface TeamsModalInputType {
    //onTeamChange: Function
    isOpen: boolean
    setIsOpen: Function
}

function TeamsModal(props:TeamsModalInputType) {

    const [modalInitialValues, setModalInitialValues] = useState( { name:"", privacy:"internal" } );
    const [searchText, setSearchText] = useState("");
    const [selectedTeamId, setSelectedTeamId] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const navigate = useNavigate()

    const { loading, error, data } = useQuery(GET_TEAMS, 
        { 
            fetchPolicy: "cache-and-network",
            //pollInterval: pollInterval,
        }
    );

    // use Effect to set the selectedTeamId to the selectedTeamId in the userProfile
    useEffect(() => {
        if (data && data.userProfile.selectedTeam) {
            setSelectedTeamId(data.userProfile.selectedTeam.id);
        }
    }, [data]);


    const [createTeam] = useMutation(CREATE_TEAM, {
        refetchQueries: [{ query: GET_TEAMS }],
        onError: (error) => {
            message.error(error.message);
        }, 
        onCompleted: (data) => {
            message.success("Team created");
        }
    });


    const [updateTeam] = useMutation(UPDATE_TEAM, {
        refetchQueries: [{ query: GET_TEAMS }],
        onError: (error) => {
            message.error(error.message);
        },
        onCompleted: (data) => {
            message.success("Team updated");
        }
    });

    const [deleteTeam] = useMutation(DELETE_TEAM, {
        refetchQueries: [{ query: GET_TEAMS }],
        onError: (error) => {
            message.error(error.message);
        },
        onCompleted: (data) => {
            message.success("Team deleted");
        }
    });

    const [updateUserProfile, { loading: updateUserProfileLoading }] = useMutation(UPDATE_USER_PROFILE, {
        refetchQueries: [{ query: GET_TEAMS }],
        
        onError: (error) => {
            message.error(error.message);
        },
        
        onCompleted: (data) => {
            message.success("Selected team updated");
            // set the globalSelectedTopic to the first topic in the selected team
            // if the team has topics
            if (data.updateUserProfile.userProfile.selectedTeam.topicSet.length > 0) {
                globalSelectedTopic(data.updateUserProfile.userProfile.selectedTeam.topicSet[0].id);
            } else {
                globalSelectedTopic("");
            }
        }
    });

    const [removeMutation] = useMutation(REMOVE_USER_FROM_TEAM, {
        refetchQueries: [{ query: GET_TEAMS }],
        onCompleted(data) { message.success("Left the team successfully.") },
        onError(error) { message.error(error.message) },
    });
    
    const [isTeamsAddModalVisible, setIsTeamsAddModalVisible] = useState(false);
    const [isAddOrJoinTeam, setIsAddOrJoinTeam] = useState("add");
    const [isAddOrEditTeam, setIsAddOrEditTeam] = useState("add");


    const { height, width } = useWindowDimensions();

    if (loading && !data) {
        return (
            <div>           
            </div>
        )
    }
    if (error) {
        return (
            <div>
                <p>Error: { error.message }</p>
            </div>
        )
    }

    function onChnageSearchText(e:any) {
        setSearchText(e.target.value);
    }

    function clearSearchText() {
        setSearchText("");
    }
    
    // filter teams based on search text
    const filteredTeams = data.teams.filter((team:any) => {
        if (searchText == "") {
            return true;
        } else {
            return team.name.toLowerCase().includes(searchText.toLowerCase());
        }
    });

    // loop through teams and create a card for each
    const teamCards = filteredTeams.map((team:any) => {
        // set background color if team is selected - default should be transparent

        let bgColor = "white";
        if (data?.userProfile.darkMode) {
            bgColor = "#141414"
        }
        
        function onTeamClick() {
            // set the selected team
            setSelectedTeamId(team.id);
        }

        if (team.id === selectedTeamId ) {
            // light blue
            bgColor = "#e6f7ff";
            if (data?.userProfile.darkMode) {
                bgColor = "#242424"
            } 
        }

        

        return(
            <Card 
                key={team.id}
                style={{ width: 300, margin: 10 , backgroundColor: bgColor }}
                hoverable={true}
                size="small"
                onClick={onTeamClick}
                data-testid={"team-card-" + team.id}
            >
                <Card.Meta
                    avatar={<Avatar src= {"https://ui-avatars.com/api/?name=" + team.name[0] + "&background=1890ff&color=fff"} />}
                    title={team.name}
                />
            <div style={{marginTop:0, marginLeft:48}}>
                Permission: {team.userPermissions}
            </div>
            </Card>
        )
    })

    /* ************************************************************ */

    function handleTeamsAdd () {
        setIsAddOrEditTeam("add")
        setIsTeamsAddModalVisible(true)
    }

    function handleDeleteTeam () {
        deleteTeam({variables: {id: selectedTeamId }})
    }

    function handleLeaveTeam () {

        // what is the id of the current user
        let userId = data.me.id

        let input = {
            userId : userId,
            teamId : selectedTeamId,
        };
        
        removeMutation( { variables: { input: input  } } )
    }

    function handleEditTeam () {
        // get the selected team
        const selectedTeam = data.teams.find((team:any) => team.id === selectedTeamId)
        modalInitialValues.name = selectedTeam.name
        //modalInitialValues.privacy = selectedTeam.privacy
        setIsAddOrEditTeam("edit")
        setIsTeamsAddModalVisible(true)
    }

    /* ************************************************************ */
    const handleTeamsAddOk = (values: any) => {

        if (isAddOrEditTeam === "add") {
         
            let input = {
                name: values['name']
            }

            createTeam({variables:{input:input}})

            setIsTeamsAddModalVisible(false)
        } else {
            
            let input = {
                id: selectedTeamId,
                name: values['name']
            }

            updateTeam({variables:{input:input}})
            setIsTeamsAddModalVisible(false)
        }

    }

    /* ************************************************************ */
    function handleTeamsAddCancel () {
        modalInitialValues.name = ""
        setIsTeamsAddModalVisible(false)
    }

    /* ************************************************************ */
    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12},
    }

    /* ************************************************************ */
    function onChangeisAddOrJoinTeam(e:any) {
        //setIsAddOrJoinTeam(e.target.value)
    }
    /* ************************************************************ */

    function onModalOk() {

        // if the selected team is already selected, dont do anything
        if (selectedTeamId === data.userProfile.selectedTeam.id) {
            props.setIsOpen(false)
            return
        }


        // perform the mutation to update the selected team
        updateUserProfile({variables:{input:{selectedTeamId:selectedTeamId}}})
        props.setIsOpen(false)
        // navigate to the collections page
        //navigate('/collections')
        //globalSelectedTopic("")
    }
        
    return(
    
        <div>
            <Modal
                open={props.isOpen}
                onOk={onModalOk}
                onCancel={() => props.setIsOpen(false)}
                closable={false}
                destroyOnClose={true}
                width={430}
                footer = {[

                    <Button key="cancel" onClick={() => props.setIsOpen(false)} data-testid="select-team-modal-cancel">
                        Cancel
                    </Button>,

                    <Button key="submit" type="primary" onClick={onModalOk} data-testid="select-team-modal-submit">
                        Select team
                    </Button>

                ]}
            >
                <Card 
                    title="Teams" 
                    style={{ height: 500}}
                    extra={
                        (
                            <div>
                            <Popover
                                content={
                                    <div>
                                        <Input placeholder="Search teams" prefix={<SearchOutlined />} onChange={onChnageSearchText} style={{width:200}} value={searchText} data-testid="search-teams-input" />
                                    </div>
                                }
                                trigger="click"
                                placement="left"

                            >
                                <Button size="large" type="text" icon={<SearchOutlined />} title="Search" data-testid="search-teams" />
                            </Popover>

                            
                            <Button size="large" type="text" icon={<UsergroupAddOutlined />} onClick={ ()=> handleTeamsAdd()} data-testid="add-team" title="Create a team">
                                
                            </Button>
                    
                            
                            <Popover content={(
                                <Space direction="vertical">

                                    <Button type="link" onClick={()=> handleEditTeam()} data-testid="edit-team">
                                        Edit
                                    </Button>

                                    <Popconfirm title="Are you sure you want to leave the team?" onConfirm={() => handleLeaveTeam()} >
                                        <Button type="link" data-testid="leave-team">Leave</Button>
                                    </Popconfirm>

                                    
                                    <Button type="link" data-testid="delete-team" onClick={() => setDeleteModalOpen(true)}>
                                        Delete
                                    </Button>
                                    
                            </Space>
                            )}>
                            <Button size="large" type="link" style={{paddingLeft:20 }} data-testid="team-actions">
                                <EllipsisOutlined />
                            </Button>
                        </Popover>
                        
                        </div>
                        )
                    }

                    /*
                    actions={[
                        <div><UsergroupAddOutlined key="add" onClick={ ()=> handleTeamsAdd()} /> 
                            <Button  
                                type="link"
                                onClick={ ()=> handleTeamsAdd()}
                                form = "addTeamForm"
                            >
                                Create a team
                            </Button>
                            </div>
                    ]}
                    */
                >
                    { searchText != "" &&
                            <div style={{marginBottom:10}}>
                            <p>Search term: {searchText}
                            <Button type="link" onClick={clearSearchText} data-testid="clear-search-text">Clear</Button>
                            </p>
                            </div>
                        
                    }


                    <div style={{height:height-300, overflow:"auto" }}>
                        { teamCards.length == 0 &&
                            <div>
                                <Empty 
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="No teams found" 
                                />
                            </div>
                        }
                        <div style={{height:400, overflow:"auto" }}>
                            {teamCards}
                        </div>
                    </div>


                    <Modal 
                        title={isAddOrEditTeam === "add" ? "Create a team" : "Edit team"}
                        open={isTeamsAddModalVisible}
                        onOk={handleTeamsAddOk}
                        onCancel={handleTeamsAddCancel}
                        destroyOnClose={true}
                        
                        footer={[
                            <Button 
                                type="primary" 
                                form="addTeamForm"
                                key="submit" 
                                htmlType="submit"
                                data-testid="addTeam-modal-submit" // TODO: this should be renamed to submit to be more consistent - test code needs to be adapted
                            >
                                Submit
                            </Button>
                            ]}
                    >
                        
                        {/* Select a new team or join an existing team */}
                    
                            <Form
                                {...formLayout}
                                labelAlign="left"
                                id = "addTeamForm"
                                onFinish={handleTeamsAddOk}
                                initialValues={modalInitialValues}
                            > 
                                {/*
                                <Form.Item label="Team:" required >
                                <Radio.Group value={isAddOrJoinTeam} onChange={onChangeisAddOrJoinTeam}>
                                    <Radio value={'add'}>Create a new team</Radio>
                                    <Radio value={'join'} disabled={true}>Join an existing team</Radio>
                                </Radio.Group>
                                </Form.Item>
                                */}

                                {isAddOrJoinTeam === "add" &&
                                    <div>
                                        <Form.Item label="Team name:" name="name" required>
                                            <Input placeholder="Enter a team name" data-testid="team-name-input" />
                                        </Form.Item>

                                        {/*
                                        <Form.Item label="Privacy mode:" name="privacy" >
                                            
                                            <Radio.Group>
                                                <Tooltip title="Users from internal and external organizations can be added to the team.">
                                                    <Radio value='external' disabled>External</Radio>
                                                </Tooltip>
                                                <Tooltip title="Users from the same organization can be added to the team.">
                                                    <Radio value='internal'>Internal</Radio>
                                                </Tooltip>
                                            </Radio.Group>
                                            
                                        </Form.Item>
                                        */}

                                    </div>
                                }
                                
                                {isAddOrJoinTeam === "join" &&
                                    <Form.Item label="Search for team:" required rules={[{ required: true }]}>
                                        
                                    </Form.Item>
                                }
                            
                            </Form>
                        


                    </Modal>
                </Card>

            </Modal>

            <DeleteModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                objectName="team"
                onOk={handleDeleteTeam}
            />

            <Modal 
                footer = {null}
                closable = {false}
                open = {updateUserProfileLoading}
                title="Updating the selected team ... "
            >
                <Skeleton active/>    
            </Modal>

        </div>                  

    )
    }

export default TeamsModal;