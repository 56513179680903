import ProcessTablePaginated from '../components/topics/ProcessTablePaginated';
import { gql, useQuery } from '@apollo/client';
import { Card} from 'antd';
import PageHeader from "../components/PageHeader";
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from '../components/useWindowDimensions';
import TeamProcesses from '../components/teams/TeamProcesses';

import ReactGA from 'react-ga';

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            selectedTeam {
              id
            }
        }
    }
`

function Processes() {

  //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
  //ReactGA.pageview('/processes/');

  const { height, width } = useWindowDimensions();
  const { loading, error, data } = useQuery(GET_USER_PROFILE);

  if (error) return (
    <Card>Error: {error.message} </Card>
  );

  if (loading) return (
    <Card>Loading...</Card>
  );

  return(  
    <div >
       <PageHeader
          title="Processes"
          extra={<HeaderExtra/>}
        />
        <Card>
          <TeamProcesses teamId={data.userProfile.selectedTeam.id} tableBottomOffset={280}/>
        </Card>
          
      
      
    </div>
  )
}

export default Processes;