import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, message, Input, Skeleton, Modal, Form    } from 'antd';
import { useMutation, useQuery, gql } from '@apollo/client';
import { CORE_PROCESS_FIELDS } from '../fragments';

import MomentDatePicker from '../MomentDatePicker';


const { TextArea } = Input;



interface ProcessDescriptionProps {
    processId: number
}

const UPDATE_PROCESS = gql`
    ${CORE_PROCESS_FIELDS}
    mutation updateProcess($input: UpdateProcessInputType!) {
        updateProcess( input:$input) {
            process {
                ...CoreProcessFields
            }
        }
    }
`;

const GET_PROCESS_DESCRIPTION = gql`
    ${CORE_PROCESS_FIELDS}
    query process ($id:ID!) {  
        process(id:$id) {
            ...CoreProcessFields
        }
    }
`;

function ProcessDescription(props: ProcessDescriptionProps) {

    const { loading:queryLoading, error: queryError,  data } = useQuery(GET_PROCESS_DESCRIPTION, 
        {
            variables: { id: props.processId },
            fetchPolicy: 'cache-and-network',
        }
    );
    
    const [updateProcess, { loading: mutationLoading }] = useMutation(UPDATE_PROCESS, 
        {   

            onCompleted: (data) => {
                message.success("Process info updated!");
            } ,
            onError: (error) => {message.error(error.message)}
        }
    );


    const [processName, setProcessName] = useState(data?.process?.name);
    const [processDescription, setProcessDescription] = useState(data?.process?.description);
    const [processStartTime, setProcessStartTime] = useState<any>(moment(data?.process?.startTime));
    const [saveNeeded, setSaveNeeded] = useState(false);

   
    useEffect(() => {
        if (data?.process?.description) {
            setProcessDescription(data.process.description);
        } else {
            setProcessDescription("");
        }

        if (data?.process?.name) {
            setProcessName(data.process.name);
        } else {
            setProcessName("");
        }

        if (data?.process?.startTime) {
            setProcessStartTime(moment(data.process.startTime));
        } else {
            setProcessStartTime(undefined);
        }

    } , [data?.process?.description, data?.process?.name, data?.process?.startTime]);
    
    function onChangeProcessDescription(e: any) {
        setProcessDescription(e.target.value);
        setSaveNeeded(true);
    }

    function onChangeProcessName(e: any) {
        setProcessName(e.target.value);
        setSaveNeeded(true);
    }

    function onOkProcessStartTime(e: any) {
        setProcessStartTime(e);

        let input = {
            startTime : e.toISOString(),
            id: props.processId
        };

        updateProcess( { variables: { input: input  } } );


    }

    function saveProcessDescription() {

        if (processName.length == 0) {
            message.error("Process name cannot be empty");
            setProcessName(data.process.name);
            return;
        }

        let input = {
            name : processName,
            description : processDescription,
            id: props.processId
        };
        updateProcess( { variables: { input: input  } } );
        setSaveNeeded(false);
    }

    if (queryLoading && !data) return <div style={{minHeight:250}} ><Skeleton active /></div>;
    if (queryError) return <div>Error: {queryError.message} </div>;

    return (
        <div style={{minHeight:250}} >
            
            <p>Team: {data.process.topic.team.name}</p>
            <p>Collection: {data.process.topic.name}</p>

            <p>Process name:</p>
            <Input value={processName} disabled={false} data-testid="process-name" onChange={onChangeProcessName} />
            { processName.length == 0 ? <div style={{color:"red"}}>Process name cannot be empty</div> : null }

            <p>Process description:</p>
            <TextArea rows={2} value={processDescription} onChange={onChangeProcessDescription} data-testid="process-description" />

            
            <p>Process start time:</p>
            
            <MomentDatePicker
                showTime
                value={processStartTime}
                onChange={onOkProcessStartTime}
                data-testid="process-startTime"
            />
               
            {/*
            <input 
                type="datetime-local" 
                value={processStartTime.format("YYYY-MM-DDTHH:mm")} 
                onChange={(e) => onOkProcessStartTime(moment(e.target.value))} 
                data-testid="process-startTime" 
            />
            */}

            <p>Last updated: {moment(data.process.lastUpdated).format("YYYY-MM-DD HH:mm:ss")}</p>
            
            <Button 
                type={saveNeeded ? "primary" : "default"}
                onClick={saveProcessDescription} 
                style={{marginTop:15}} 
                data-testid="save-process-description">
                    Save
            </Button>

            <Modal
                title="Updating process information..."
                footer = {null}
                closable = {false}
                open = {mutationLoading}
            >
                <Skeleton active/>
            </Modal>
        </div>
    )

}

export default ProcessDescription;