import moment from 'moment';
import { Button, message } from 'antd';
import { useMutation, gql } from '@apollo/client';
import { EXTENDED_PROCESS_FIELDS } from '../fragments';

const CALCULATE_ALL_CALCULATORS = gql`
    mutation calculateAllProcessCalculators($processId: ID!) {
        calculateAllProcessCalculators( processId:$processId) {
            ok
        }
    }
`;

const GET_PROCESS = gql`
    ${EXTENDED_PROCESS_FIELDS}
    query process ($id: ID!) {
        process(id: $id) {
            ...ExtendedProcessFields
        }
    }
`;

const GET_CALC_INTERFACE  = gql`
    query calcInterfaceByCalculatorId ($id:ID!) {  
        calcInterfaceByCalculatorId(id:$id) {
            id
            status
        }
    }
`;

interface ProcessExplorerSidebarCalculatorsProps {
    process: any
}

function ProcessExplorerSidebarCalculators(props: ProcessExplorerSidebarCalculatorsProps) {

    const [calculateAllMutation, { loading: calculateAllLoading, error: calculateAllError }] = useMutation(CALCULATE_ALL_CALCULATORS, {
        refetchQueries:[
            {query: GET_PROCESS, variables:{id:props.process.id}} , 
            //{query:GET_CALC_INTERFACE, variables:{id:selectedCalculator}} // FIXME: query produces error and interfaces do not update
        ],    
        onCompleted(data) { message.success('Calculations were performed successfully.') },
        onError(error) {message.error(error.message)}, 
    });

    const handleCalculateAll = () => {   
        calculateAllMutation({ variables: { processId: props.process.id  } } )
    }


    return (

        <div>
            <p>Calculated: {moment(props.process.calculationDate).format("YYYY-MM-DD HH:mm:ss")}</p>
            <p>Calculation needed: {props.process.calculationNeeded ? "Yes" : "No"}</p>
            <Button 
                type={ props.process.calculationNeeded ? "primary" : "default" }
                size="small" 
                onClick={handleCalculateAll} 
                loading={calculateAllLoading} 
                style={{width:180}}
            >
                Calculate Process
            </Button>
        </div>

    )

}

export default ProcessExplorerSidebarCalculators;