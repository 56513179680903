import { useQuery, useMutation, gql, useReactiveVar } from '@apollo/client';
import { message, Select} from 'antd';
import { kpiAnalysisSelectedViewPageVar } from '../../Apollo';
import {createOptimisticResponse} from '../createOptimisticResponse'
import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';

import { CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS } from '../fragments';

const { Option } = Select;

const GET_KPIS= gql`
    query KPIs ($analysisId:ID!) {
        KPIs (analysisId:$analysisId) {
            id
            name
        }
    }
`


const UPDATE_VIEW_PAGE = gql`
    ${CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS}
    mutation updateViewPage ($input: UpdateViewPageInputType!) {
        updateViewPage( input:$input) {
            viewPage {
                ...CoreKpiAnalysisViewPageFields
            }
        }
    }
`;

const READ = GET_KPIS;

const objectName = "KPI";

interface KPIAnalysisSelectKPIInputType {
    plotId:number
    placeholder:String
    setSelection:Function
    axisType:string
    analysis:any
}

function KPIAnalysisSelectKPI(props:KPIAnalysisSelectKPIInputType) {

    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const kpiAnalysisViewSelectedViewPage = useReactiveVar(kpiAnalysisSelectedViewPageVar);

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ, { variables: { analysisId: props.analysis.id }, 
        //fetchPolicy: 'cache-and-network',
    } ) ;
    
    const [updateViewPage] = useMutation(UPDATE_VIEW_PAGE, {
        onError(error) {message.error(error.message)}, 
    });


    if (queryError) return (
        <div>Error: {queryError.message} </div>
    );



    let viewPage = props.analysis.kpiAnalysisView.viewpageSet.find( (viewPage:any) => viewPage.id == kpiAnalysisViewSelectedViewPage );


    if ((queryLoading && !queryData  || !viewPage) ) return (
        <div style={{float:"right"}}><Select style={{ width: 100 }} size="small" defaultValue={"Loading..."}></Select></div>
    );


    
    function onSelectChange(kpiName:"string") {

        props.setSelection(kpiName)

        let xAxis: any = [...viewPage.kpiAnalysisXaxis]  // create a copy to avoid readonly errors - initialize the y-axis vector using props
        let yAxis: any = [...viewPage.kpiAnalysisYaxis]  // create a copy to avoid readonly errors - initialize the y-axis vector using props
        
        if (props.axisType == 'x') {
            xAxis[props.plotId] = kpiName   // write the selected yaxis name to the correct position of the vector
        } 
        if (props.axisType == 'y') { 
            yAxis[props.plotId] = kpiName   // write the selected yaxis name to the correct position of the vector
        }
        
        // create a variable for mutation input
        let input = {
          id: viewPage.id,
          kpiAnalysisXaxis: xAxis,
          kpiAnalysisYaxis: yAxis,
        }

        
        const optimisticResponseVar = createOptimisticResponse(
            client,
            CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS,
            viewPage.id,
            { kpiAnalysisXaxis: xAxis, kpiAnalysisYaxis: yAxis },
            'CoreKpiAnalysisViewPageFields'
        );

        // perform the mutation to update the value of the Y-Axis in the view
        updateViewPage({ variables: { input: input }, 
            
            
            optimisticResponse: {
                updateViewPage: {
                    __typename: 'Mutation',
                    viewPage: {
                        __typename: 'ViewPageType',
                        ...optimisticResponseVar
                    }
                }      
            }
            
    
        });
    }

    var defaultVal:any = ""
    
    if ((!queryError && !queryLoading) && viewPage != undefined) {      
        
        if (props.axisType == 'x') {
            defaultVal = viewPage.kpiAnalysisXaxis[props.plotId]
        } else if (props.axisType == 'y') { 
            defaultVal = viewPage.kpiAnalysisYaxis[props.plotId]
        }
        props.setSelection(defaultVal)
    }

    return (

        <Select 
            showSearch 
            style={{ width: 120 }}
            onChange={onSelectChange} 
            placeholder={props.placeholder} 
            value={defaultVal} 
            defaultValue={defaultVal} 
            size="small"
            data-testid={"kpi-select-" + props.plotId}
        >
            <Option value="None" key="None" >None</Option>
            { queryData.KPIs.map( (KPI:any) => {return (<Option value={KPI.name} key={KPI.id} title={KPI.name}>{KPI.name}</Option>)} )}
        </Select> 
    );
}


export default KPIAnalysisSelectKPI;

