import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card , Alert, Space, Popconfirm, Button, Spin, message, Collapse, Tag, Input, Modal, Form, Progress, AutoComplete, Select, Checkbox, Slider} from 'antd';
import { SearchOutlined , PlusOutlined, CloseOutlined} from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';
import AddProcess from '../AddProcess';
import { pollInterval } from '../..';
import moment from 'moment';
const { Panel } = Collapse;

const GET_PROCESSES = gql`
    query processes($teamId:ID, $cursor:ID, $limit:Int, $filter:Boolean) {
        processes (teamId:$teamId, cursor:$cursor, limit:$limit, filter:$filter) {
            processes {
                id
                name
                description
                startTime
                lastUpdated
                topic {
                    id
                    name
                }
                metadataSet {
                    id
                    name
                    value
                }
                parameterSet {
                    id
                    name
                    value
                    units
                }
                qualityScore
            }
            totalCount
        }
    } 
`;

const DELETE_PROCESSES = gql`
    mutation deleteProcesses($ids: [ID]!) {
        deleteProcesses(ids: $ids) {
            ids
            ok
        }
    }
`;

interface TeamProcessesInputType {
    teamId?:number,
    tableBottomOffset?:number,
};

function TeamProcesses(props:TeamProcessesInputType) {

    let tableBottomOffset = 350
    if (props.tableBottomOffset) {
        tableBottomOffset = props.tableBottomOffset
    }

    const { height, width } = useWindowDimensions();
    const [nameSearchText, setNameSearchText] = useState("");
    const [metaFilters, setMetaFilters] = useState( [ {'id':'1', 'name':'', 'condition':'', 'value':''} ] );
    const [pageSize, setPageSize] = useState(10); // Number of data items in the table
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    
    // Get the processesmetaFilters add a refetch 
    const { loading, error, data, refetch, fetchMore } = useQuery(GET_PROCESSES, {
        variables: { teamId: props.teamId },
        //fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,    
        notifyOnNetworkStatusChange: false,
        onCompleted(data) { 
            if (initialLoad) {
                setInitialLoad(false)
            }
        },
    });

    const shouldDisplaySpinner = loading && initialLoad;
    
    const [deleteProcesses] = useMutation(DELETE_PROCESSES , {
        refetchQueries: [ 
            { query:  GET_PROCESSES, variables: { teamId:props.teamId , cursor:0, limit:pageSize , filter:true} },
        ], 
        onCompleted(data) { message.success( "Deleted successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    /*
    if (loading) {
        return (
            <Card><Spin/></Card>
        )
    }
    
    if (error) {

        return(
            <Card>
                <Alert
                    message="Error"
                    description={error.message}
                    type="error"
                    showIcon
                />
            </Card>

        )
    }
    */
    

    // create a copy of data.processes
    let processes = data?.processes?.processes?.slice(0);
    // sort processes by name
    processes?.sort( (a:any, b:any) => a.name.localeCompare(b.name) )

  
    const columns_1:any = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
            width: '15%',
            render: (text:String, record:any) => (
                <Link to={'/processes/process/'+ record.id } >{text}</Link>
            ),
            
            sortDirections: ['ascend', 'descend'],
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
        }
    ]
    
    const columns_2:any = [
        {
            title: 'Collection',
            dataIndex: ['topic', 'name'],
            editable: false,
            width: '15%',
            render : (text:String, record:any) => (
                <Link to={'/collections/collection/'+ record.topic.id } >{text}</Link>
            ),
        },
        {    
            title: 'Description',
            width: '20%',
            //dataIndex: ,
            editable: false,
            render: (text:String, record:any) => (
                <div>
                    <p>{record.description}</p>
                </div>
            ),
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            editable: false,
            render : (text:String, record:any) => (
                // convert from utc to local time
                record.startTime != null ? moment.utc(record.startTime).local().format('DD.MM.YYYY HH:mm:ss') : null
            ),

        },
        {
            title: 'Last Modified',
            //dataIndex: 'lastUpdated',
            editable: false,
            render : (text:String, record:any) => (
                // convert from utc to local time
                record.lastUpdated != null ? moment.utc(record.lastUpdated).local().format('DD.MM.YYYY HH:mm:ss') : null
            ),
        },
                {
            title: 'Data Quality',
            width: '15%',
            dataIndex: 'qualityScore',
            editable: false,
            /*sorter: (a:any, b:any) => a.qualityScore - b.qualityScore,*/
            sortDirections: ['ascend', 'descend'],
            render : (text:String, record:any) => (
                <Progress percent={record.qualityScore} size="small" /> 
            ),
        },

    ];

    var columns = columns_1
    

    // add the other columns
    columns = columns.concat(columns_2)
    
    
    
    // handlePagination is called when the user changes the page size or selects a different page
    function handlePagination(page:any, pageSize:any) {
        if (page != 1) {
            // the cursor for the fetchMore is the id of the last process in the current page
            var cursor = processes[processes.length-1].id
            fetchMore({ variables: { cursor:cursor } })
        }
    }

    function handleLoadMore() {
        // the cursor for the fetchMore is the id of the last process in the current page
        var cursor = processes[processes.length-1].id
        //console.log('cursor', cursor    )
        fetchMore({ variables: { cursor:cursor } })
    }

    function handleRemoveFilter(id:any) {
        setMetaFilters( metaFilters.filter( (filter:any) => filter.id != id ) )
    }

    function handleAddFilter() {
        // add a new filter to the metaFilters array
        let new_id:any = metaFilters.length + 1
        setMetaFilters( [...metaFilters, {id: new_id.toString() , name: '', condition:'', value: ''}] )
    }

    function handleFilterMetaDataNameChange(name:any, id:any) {
        let newMetaFilters = metaFilters.map( (filter:any) => {
            if (filter.id == id) {
                filter.name = name
            }
            return filter
        })
        setMetaFilters(newMetaFilters)
    }

    function handleFilterMetaDataConditionChange(condition:any, id:any) {
        let newMetaFilters = metaFilters.map( (filter:any) => {
            if (filter.id == id) {
                filter.condition = condition
            }
            return filter
        })
        setMetaFilters(newMetaFilters)
    }

    function handleFilterMetaDataValueChange(value:any, id:any) {
        let newMetaFilters = metaFilters.map( (filter:any) => {
            if (filter.id == id) {
                filter.value = value
            }
            return filter
        })
        setMetaFilters(newMetaFilters)
    }


    

    function handleOnClickDisplayDataQuality(value:any) {
        // perform a mutation to update the topicUserConfigs
        //updateTopicUserConfigs({variables:{'input': {'processTableDisplayDataQuality' : value.target.checked , 'id': topicId }  }})
    }

    function handleOnClickDisplayStartTime(value:any) {
        // perform a mutation to update the topicUserConfigs
        //updateTopicUserConfigs({variables:{'input': {'processTableDisplayStartTime' : value.target.checked , 'id': topicId }  }})
    }

    function handleOnClickDisplayLastModified(value:any) {
        // perform a mutation to update the topicUserConfigs
        //updateTopicUserConfigs({variables:{'input': {'processTableDisplayLastModified' : value.target.checked , 'id': topicId }  }})
    }

    function handleFilterDataQualityScoreChange(value:any) {

        // perform the mutation to update topicUserConfigs
        //updateTopicUserConfigs({variables:{'input': {'processTableFilterDqsRange' : value , 'id': topicId }  }})
    
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

      const hasSelected = selectedRowKeys.length > 0;

    function handleClickDeleteProcesses() {
        //console.log('Processes to delete', selectedRowKeys)
        // open the confirm delete modal
        setOpenConfirmDeleteModal(true)
        //history.push('/processes/new')
    }

    function handleConfirmDeleteModalOk() {
        // perform the mutation to delete the processes
        deleteProcesses({variables:{'ids' : selectedRowKeys }})
        // close the confirm delete modal
        setOpenConfirmDeleteModal(false)
        // clear the selected row keys
        setSelectedRowKeys([])
    }

    const formLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20},
    };
    
    const tailLayout = {
        wrapperCol: { offset: 4, span: 20 },
    };

    return (

        <div>

            <div>
                <Button key={1}
                    style = {{ marginBottom: 16 }}
                    //onClick = {  handleClickCreateNewProcess  }
                    type="primary"
                    shape="round" 
                    icon={<PlusOutlined />}  
                    data-testid="create-new-process"                        
                >
                    Create a new process
                </Button>

                <Button key={2}
                    style = {{ marginBottom: 16, marginLeft:10 }}
                    onClick = {  handleClickDeleteProcesses  }
                    disabled = { !hasSelected }
                    data-testid="delete-processes"
                >
                    Delete processes
                </Button>

                <Input.Search 
                    placeholder="Search by name & description"
                    //onSearch={handleNameSearchSubmit}
                    enterButton 
                    allowClear
                    
                    //onChange={handleNameSearchChange}
                    value={nameSearchText}
                    style={{ width: 300, marginBottom: 16, marginLeft:10}}
                    data-testid="search-by-name"
                />
            
            </div>

            <div style={{ display: 'flex'}} key={3}>

                {/*
                <div style={{ flex: '0 0 220px'}}>
                    <div style={{height:height-tableBottomOffset, overflow:"auto", width:"100%"}}>
                        <Collapse defaultActiveKey={["1","2","3","4"]}  >

                            <Panel header="Display columns" key="1" data-testid="display-columns-panel">
                                <Space direction="vertical">
                                    <Checkbox  onChange={handleOnClickDisplayDataQuality} data-testid="display-data-quality"> Data Quality</Checkbox>
                                    <Checkbox onChange={handleOnClickDisplayStartTime} data-testid="display-start-time"> Start Time</Checkbox>
                                    <Checkbox  onChange={handleOnClickDisplayLastModified} data-testid="display-last-modified"> Last Modified</Checkbox>
                                </Space>
                            </Panel>

                            <Panel header="Search by Name" key="2" data-testid="search-by-name-panel">
                                <Input.Search 
                                    placeholder="Name & description"
                                    //onSearch={handleNameSearchSubmit}
                                    enterButton 
                                    allowClear
                                    
                                    //onChange={handleNameSearchChange}
                                    value={nameSearchText}
                                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                                    data-testid="search-by-name"
                                />
                            </Panel>

                            <Panel header="Filter by Data Quality" key="3" data-testid="filter-by-data-quality-score-panel">
                                <Slider
                                    range
                                    min={0}
                                    max={100}
                                    //defaultValue={[data.topicUserConfigs.processTableFilterDqsMin, data.topicUserConfigs.processTableFilterDqsMax]}
                                    marks={{
                                        0: '0',
                                        100: '100',
                                    }}
                                    onAfterChange={handleFilterDataQualityScoreChange}
                                    
                                    data-testid="filter-by-data-quality-score"
                                />
                            </Panel>

                            <Panel header="Filter by metadata" key="4" data-testid="filter-by-metadata-panel">
                                <Collapse>


                                </Collapse>
                                <Space size="middle" style={{marginTop:10}}>
                                    <Button key={1}
                                        onClick={handleAddFilter}
                                        data-testid="filter-meta-add"
                                    >
                                        Add filter
                                    </Button>
                                    <Button type="primary" key={2} 
                                        //onClick={handleFilterMetaDataSubmit}
                                        data-testid="filter-meta-apply"
                                    >
                                        Apply
                                    </Button>
                                </Space>
                            </Panel>

                        </Collapse>
                    </div>
                </div>
                */}
                
                <div style={{width:"100%"}}>
                    <Table 
                        dataSource={processes}
                        columns={columns} 
                        bordered 
                        rowKey={record => record.id}
                        pagination={false}
                        scroll={{ y: height-tableBottomOffset }}
                        rowSelection={rowSelection}
                        size='small'
                        loading={shouldDisplaySpinner}
                        
                        
                        /*footer={() => (
                            <div style={{textAlign:"center"}}>
                                
                                {processes.length < data.processes.totalCount && (
                                    <Button type="link"
                                        style = {{ marginBottom: 16 }}
                                        onClick = {  handleLoadMore }
                                        data-testid="load-more"
                                    >
                                        Load more
                                    </Button>
                                )}
                                <div>{processes.length} of {data.processes.totalCount}</div>
                            </div>
                        )}*/

                        /*pagination={{ 
                            responsive:true,
                            total: processes.totalCount, 
                            pageSize: 10 , 
                            showSizeChanger:false, 
                            position:["bottomCenter"] , 
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` , 
                            defaultCurrent:1 , 
                            defaultPageSize:10 , 
                            pageSizeOptions: ['10', '20', '30', '40'] , 
                            showQuickJumper:true,
                            onChange: (page, pageSize) => {
                                handlePagination(page, pageSize)
                            }

                        }}*/

                        //onChange={handleTableChange}
                        //pagination={false}
                        //scroll={ {y: height - 400 } }
                        
                    />
                </div>
                
            </div>
        
        

            <Modal
                
                open={openConfirmDeleteModal}
                onOk={handleConfirmDeleteModalOk}
                onCancel={ () => setOpenConfirmDeleteModal(false) }
                okText="Yes, delete!"
                cancelText="No, get me out of here!"
                okButtonProps={{ danger: true }}
                width={"40%"}
                closable={false}
                
            >
                { /* if only one process is selected, show the name of the process in the alert message */}
                { selectedRowKeys.length === 1 && 
                    <Alert
                        message={"Are you sure you want to delete the process " + data.processes.processes.filter((process:any) => process.id === selectedRowKeys[0])[0].name + "?"}
                        description={(
                            <div>
                                <br/>
                                <p>This action cannot be undone and will delete all the data associated with the process.</p>
                            </div>
                            )}
                        type="warning"
                        showIcon
                        style={{marginBottom:20}}
                    />
                }
                { /* if more than one process is selected, show the number of processes in the alert message */}
                {selectedRowKeys.length > 1 && 
                    <Alert
                        message={"Are you sure you want to delete " + selectedRowKeys.length + " processes?"}
                        description={(
                            <div>
                                <br/>
                                <p>This action cannot be undone and will delete all the data associated with the processes.</p>
                            </div>
                            )}
                        type="warning"
                        showIcon
                        style={{marginBottom:20}}
                    />
                }
               

            </Modal>

        </div>
    );
}

export default TeamProcesses;