import React, {useState, useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table , Space, message, Button, Skeleton, Modal, Alert, Input} from 'antd';
import KPIAnalysisAddKPI from './KPIAnalysisAddKPI';
import ImportKPIs from './ImportKPIs';
import useWindowDimensions from '../useWindowDimensions';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { CORE_KPI_FIELDS, CORE_KPI_TABLE_FIELDS } from '../fragments';

const DELETE_KPIS = gql`
    mutation deleteKPIS ($ids:[ID]!) {
        deleteKPIS(ids:$ids) {
            ids 
            ok
        }
    }
`;

const GET_KPIS= gql`
    ${CORE_KPI_FIELDS}
    query KPIs ($analysisId:ID!) {
        KPIs (analysisId:$analysisId) {
            ...CoreKPIFields
        }

        userProfile {
            selectedTeam {
                id
            }
        }
    }
`
const GET_KPI_TABLE = gql`
    ${CORE_KPI_TABLE_FIELDS}
    query kpiTable ($input:KpiTableInputType!) {  
        kpiTable(input:$input) {
            ...CoreKPITableFields
        }
    }
`;

const READ = GET_KPIS;
const DELETE = DELETE_KPIS;

const objectName = "KPI";

interface KPITableInputType {
    analysis:any
    view?:any
};

function KPITable(props:KPITableInputType) {

    // ----------------------------------------------------------------------------------------
    // states
    
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const { height, width } = useWindowDimensions();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [modalId, setModalId] = useState("0");

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [nameSearchText, setNameSearchText] = useState('');
    const [unitFilters, setUnitFilters] = useState<any>([]);
    const [yFilters, setYFilters] = useState<any>([]);
    const [xFilters, setXFilters] = useState<any>([]);
    const [fFilters, setFFilters] = useState<any>([]);

    const hasSelected = selectedRowKeys.length > 0;

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations


    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(READ, {
        variables:{'analysisId':props.analysis.id },
        fetchPolicy: "cache-and-network"
    });

    // loop over analysis.processes and create an array with the ids
    let processIds:any = []
    props.analysis.processes.forEach((process: any) => {
        processIds.push(process.id)
    })

    const [deleteMutation, {loading:loadingDeleteMutation, error:errorDeleteMutation}] = useMutation(DELETE, {
        refetchQueries: [ 
            { query: READ, variables:{'analysisId':props.analysis.id} } ,
            { query: GET_KPI_TABLE, variables: { 'input': { 'analysisId':props.analysis.id, 'processIds': processIds } } },
        ],
        onCompleted(data) { message.success("Deleted successfully.") },
        onError(error) {message.error(error.message)}, 
    });
    
    // ----------------------------------------------------------------------------------------
    // effects

    // use effect to set filter options for units, x, y, and f
    useEffect(() => {
        
        let units:any = [];
        let y:any = [];
        let x:any = [];
        let f:any = [];

        queryData?.KPIs.forEach((kpi:any) => {
            if (units.indexOf(kpi.units) == -1) {
                if (kpi.units != null) {
                    units.push(kpi.units)
                }
            }
            if (y.indexOf(kpi.y) == -1) {
                if (kpi.y != null) {
                    y.push(kpi.y)
                }
            }
            if (x.indexOf(kpi.x) == -1) {
                if (kpi.x != null) {
                    x.push(kpi.x)
                }
            }

            if (f.indexOf(kpi.f) == -1) {
                if (kpi.f != null) {
                    f.push(kpi.f)
                }
            }
        });
        
        // sort the arrays alphabetically without case sensitivity
        units.sort((a:any, b:any) => a.toLowerCase().localeCompare(b.toLowerCase()));
        y.sort((a:any, b:any) => a.toLowerCase().localeCompare(b.toLowerCase()));
        x.sort((a:any, b:any) => a.toLowerCase().localeCompare(b.toLowerCase()));
        f.sort((a:any, b:any) => a.toLowerCase().localeCompare(b.toLowerCase()));

        setUnitFilters(units);
        setYFilters(y);
        setXFilters(x);
        setFFilters(f);

    }, [queryData]);

    // ----------------------------------------------------------------------------------------
    // functions

    function handleDeleteModalOk() {
        
        deleteMutation({ variables: { ids: selectedRowKeys  } } )
        setOpenDeleteModal(false);
        setSelectedRowKeys([]);
        
    }

    const handleSearch = (selectedKeys:any, confirm:any) => {
        confirm();
        setNameSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        setNameSearchText('');
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    function handleEdit() {
        setEditModalOpen(true);
        setAddModalOpen(false);
    }

    function handleAdd() {
        setAddModalOpen(true);
        setEditModalOpen(false);
    }

    const formLayout = {
        labelCol: { span: 4 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 12 },
    };


    const TableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="name-search-input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="name-search-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleReset(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="name-search-reset"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
        },  
        {
            title: 'Function',
            dataIndex: 'f',
            editable: false,

            filters: fFilters.map((f:any) => {
                return {
                    // capitalize the first letter
                    text: f.charAt(0).toUpperCase() + f.slice(1),
                    value: f,
                }
            }
            ),

            onFilter: (value: any, record: any) =>  record.f.includes(value),
            render : (text:any) => {
                // capitalize the first letter
                return text.charAt(0).toUpperCase() + text.slice(1);
            }
        },
        {
            title: 'Y-variable',
            dataIndex: 'y',
            editable: false,
            filters: yFilters.map((y:any) => {
                return {
                    text: y,
                    value: y,
                }
            }
            ),
            onFilter: (value: any, record: any) =>  record.y == value,
        },
        {
            title: 'X-variable',
            dataIndex: 'x',
            editable: false,
            filters: xFilters.map((x:any) => {
                return {
                    text: x,
                    value: x,
                }
            }
            ),
            onFilter: (value: any, record: any) =>  record.x == value,
        }, 
        {
            title: 'X-value (interp.)',
            dataIndex: 'interpXValue',
            editable: false,
        }, 
        {
            title: 'Units',
            dataIndex: 'units',
            editable: false,
            filters: unitFilters.map((unit:any) => {
                return {
                    text: unit,
                    value: unit,
                }
            }
            ),
            onFilter: (value: any, record: any) =>  record.units == value,
        },           
        /*{
            title: 'Actions',
            key: 'actions',
            //width: '40%',
            render: (text:String, record:any) => (
                <div>
                    <Space>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} disabled = {props.analysis.userPermissions == "read"}>
                            <Button type="link" disabled = {props.analysis.userPermissions == "read"}>Delete</Button>
                        </Popconfirm>
                    </Space>
                </div>
            ),
        },*/
    ]

    if (queryLoading && !queryData) {
        return (
            <Skeleton active/>
        )
    } else {
        return (

            <Space direction="vertical" size="small" style={{ width: '100%' }} >

                    <Space direction="horizontal" size="small" style={{ width: '100%', marginBottom:10 }} >
                        
                        <Button
                            //style={{ marginBottom: 16 }}
                            onClick={handleAdd}
                            type="primary"
                            icon={<PlusOutlined />}
                            shape='round'
                            disabled={props.analysis.userPermissions == "read"}
                            title={props.analysis.userPermissions == "read" ? "You do not have permission to add KPIs to this analysis." : ""}
                            data-testid="add-kpi-button"
                        >
                            Create a KPI
                        </Button>
                        
                        <Button 
                            key={2}
                            type="default" 
                            onClick={() => setIsImportModalOpen(true)}
                            disabled = {props.analysis.userPermissions == "read"}
                            title = {props.analysis.userPermissions == "read" ? "You do not have permission to import KPIs in this analysis" : ""}
                            data-testid="import-kpis-button"
                        >
                            Import KPIs
                        </Button>

                        <Button 
                            key={3}
                            type="default"
                            onClick = {  () => setOpenDeleteModal(true) }
                            disabled = { !hasSelected || props.analysis.userPermissions == "read"}
                            title = {props.analysis.userPermissions == "read" ? "You do not have permission to delete KPIs in this analysis" : ""}   
                            data-testid="delete-kpis-button"
                        >
                            Delete
                        </Button>

                    </Space>

                    <Table 
                        loading={queryLoading && !queryData}
                        dataSource={queryData?.KPIs}
                        columns={ TableColumns }
                        bordered
                        rowKey={record => record.id} 
                        size="small"
                        pagination={false}
                        scroll={{ y: height - 350 }}
                        rowSelection={rowSelection}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {setModalId(record.id); handleEdit();},
                                // change the mouse cursor to pointer when hovering over the row
                                onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                                onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                            };
                        }}
                    />

                    <ImportKPIs visible={isImportModalOpen} setVisible={setIsImportModalOpen} analysis={props.analysis} selectedTeamId={queryData.userProfile.selectedTeam.id} />

                <Modal
                
                    open={openDeleteModal}
                    onOk={handleDeleteModalOk}
                    onCancel={ () => setOpenDeleteModal(false) }
                    okText="Yes, delete!"
                    cancelText="No, get me out of here!"
                    okButtonProps={{ danger: true }}
                    width={"40%"}
                    
                >
                <Alert
                    message={"Are you sure you want to delete " + selectedRowKeys.length + " items?"}
                    description={(
                        <div>
                            <br/>
                            <p>This action cannot be undone. </p>
                        </div>
                        )}
                    type="warning"
                    showIcon
                    style={{marginBottom:20}}
                />
               

                </Modal>

                <KPIAnalysisAddKPI 
                    analysis = {props.analysis} 
                    addModalOpen={addModalOpen} 
                    setAddModalOpen={setAddModalOpen}
                    editModalOpen={editModalOpen}
                    setEditModalOpen={setEditModalOpen}
                    modalId={modalId}
                    KPIs={queryData?.KPIs}
                />

                <Modal title="Deleting KPI  ..." 
                    open={loadingDeleteMutation } footer={null} closable={false} 
                >
                    <Skeleton active/>
                </Modal>

            </Space>

        );
    }

}

export default KPITable;

