import React, {Fragment, useState} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Row, Col, Input, Card, Button, Space, message, Modal, Form, Select, Popconfirm, Radio, Skeleton} from 'antd';
import CalculatorTable from "./CalculatorTable";
import { EXTENDED_PROCESS_FIELDS } from '../fragments';
//import { pollInterval } from '../..';

//const {Option} = Select;
//const {TextArea} = Input


const CALCULATE_ALL_CALCULATORS = gql`
    mutation calculateAllProcessCalculators($processId: ID!) {
        calculateAllProcessCalculators( processId:$processId) {
            ok
        }
    }
`;

const DELETE_ALL_CALCULATORS = gql`
    mutation deleteAllProcessCalculators($processId: ID!) {
        deleteAllProcessCalculators( processId:$processId) {
            ok
        }
    }
`;

const EXPORT_CALCULATORS = gql`
    mutation exportCalculators($input: ExportCalculatorsInputType!) {
        exportCalculators( input:$input) {
            calculatorSet {
                id
                name
                length
            }
        }
    }
`

const IMPORT_CALCULATORS = gql`
    mutation importCalculators($input: ImportCalculatorsInputType!) {
        importCalculators( input:$input) {
            ok
        }
    }
`

// get one process
const GET_PROCESS = gql`
    ${EXTENDED_PROCESS_FIELDS}
    query process ($id: ID!) {
        process(id: $id) {
            ...ExtendedProcessFields
        }
    }
`;

const GET_CALC_INTERFACE  = gql`
    query calcInterfaceByCalculatorId ($id:ID!) {  
        calcInterfaceByCalculatorId(id:$id) {
            id
            status
        }
    }
`;

/*
const MAIN_QUERY = gql`
    query calculatorSets ($processId:ID!) {

        calculatorSets {
            id
            name
            topic {
                id
                name
                userPermissions
            }

            calculatorsaveSet {
                id
                name
                calcfunction
                expression

            }
        }

        topics {
            id
            name
        }

        process(id:$processId) {
            id
            topic {
                id
                userPermissions
            }
        }
    }
`
*/

interface CalculatorTabInputType {
    processId:String,
    topic: any
};

function CalculatorTab(props:CalculatorTabInputType) {
    
    // #############################################################

    const [selectedCalculator, setSelectedCalculator] = useState(-1)
    const [selectedTopic, setSelectedTopic] = useState(-1)
    const [importDestinationText, setImportDestinationText] = useState("")
    const [isCalculatorSetModalVisible, setCalculatorSetModalVisible] = useState(false);
    const [isCalculatorSetModalExport, setCalculatorSetModalExport] = useState(true);
    const [isCalculatorSetModalNameVisible, setCalculatorSetModalNameVisible] = useState(false);

    // #############################################################

    /*
    const { loading:queryLoading1, error: queryError1, data: queryData } = useQuery( MAIN_QUERY , {variables:{processId:props.processId}, 
        //pollInterval: pollInterval
    });
    */

    //const { loading:queryLoading2, error: queryError2, data:  calculatorsQuery } = useQuery(GET_CALCULATOR , {variables:{id:props.processId}});

    const [calculateAllMutation, { loading: calculateAllLoading, error: calculateAllError }] = useMutation(CALCULATE_ALL_CALCULATORS, {
        refetchQueries:[
            {query: GET_PROCESS, variables:{id:props.processId}} , 
            {query:GET_CALC_INTERFACE, variables:{id:selectedCalculator}} // FIXME: query produces error and interfaces do not update
        ],    
        onCompleted(data) { message.success('Calculations were performed successfully.') },
        onError(error) {message.error(error.message)}, 
    });

    const [deleteAllMutation, { loading: deleteAllLoading, error: deleteAllError }] = useMutation(DELETE_ALL_CALCULATORS, {
        refetchQueries:[
            {query: GET_PROCESS, variables:{id:props.processId}}
        ],
        onCompleted(data) { message.success('Calculators were deleted successfully.') },
        onError(error) {message.error(error.message)}, 
    });

    /*
    const [exportCalculators] = useMutation(EXPORT_CALCULATORS, {
        refetchQueries:[
            { query: MAIN_QUERY }
        ],
        onCompleted(data) { message.success('Calculators exported successfully.') },
        onError(error) {message.error(error.message)}, 
    });


    const [importCalculators] = useMutation(IMPORT_CALCULATORS, {
        refetchQueries: [ 
            {query: GET_PROCESS, variables:{id:props.processId}}
        ], 
        onCompleted(data) { message.success('Importing of calculators will run in the background...') },
        onError(error) {message.error(error.message)}, 
    });
    */


    // #############################################################

    /*
    if (queryLoading1 || props.processId == "") return ( 
        <Card><Skeleton active /></Card>
    );
    */

    //console.log("Process id: " + props.processId)

    /*
    if (queryError1) return ( 
       <Card>Error: {queryError1.message}</Card>
    );
    */

    //if (queryError2) return ( 
    //    <Card>Error: {queryError2.message} </Card>
    // );

    

    

    // #############################################################

    const handleCalculateAll = () => {   
        calculateAllMutation({ variables: { processId: props.processId  } } )
    } 

    const handleExportCalculators = () => {
        setCalculatorSetModalExport(true);
        setCalculatorSetModalVisible(true);
    }
 
    const handleImportCalculators = () => {
        setCalculatorSetModalExport(false);
        setCalculatorSetModalNameVisible(false);
        setCalculatorSetModalVisible(true);
    }

    const handleDeleteCalculators = () => {
        deleteAllMutation({ variables: { processId: props.processId  } } )
        setSelectedCalculator(-1) // to empty the calcinterface table
    }

    /*
    const handleModalOk = (values: any) => {
        
        // EXPORT calculators to a calculator set
        if (isCalculatorSetModalExport) {

            var calculatorSetName = values.calculatorSet;
            if (values.calculatorSet == 'new') {
                calculatorSetName = values.name;
            }

            //console.log(values)
            const variables = { 'input': {'processId' : props.processId , 'calculatorSetName': calculatorSetName , 'topicId':values.topic }}
            exportCalculators( { variables: variables });


        // IMPORT calculators from a calculator set into either a process or topic
        } else {

            let variables

            // import into a topic
            if (values.destination == 'topic' ) {
                variables = { 'input':{'topicId' : selectedTopic , 'calculatorSetId':  values.calculatorSet }}

            // import into a process
            } else {
                variables = { 'input':{'processId' : props.processId , 'calculatorSetId':  values.calculatorSet }}
            }
            importCalculators({ variables: variables });
        }
        
        // perform the import mutation
        setCalculatorSetModalVisible(false);
    };
    */
    
    const handleModalCancel = (values: any) => {
        setCalculatorSetModalVisible(false);
    };

    const handleModalOnChange = (values:any) => {
        if (values == 'new') {
            setCalculatorSetModalNameVisible(true);
        } else {
            setCalculatorSetModalNameVisible(false);
        }
    }

    const handleSelectTopic = (values: any) => {
        setSelectedTopic(values);
    };

    const handleImportDestinationChange=(e:any) => {
        setImportDestinationText(e.target.value)
    }
    
    const formLayout = {
        labelCol: { span: 12 },
    };

    const editable = props.topic.userPermissions != "read"

    // #############################################################

    return (
        
        <div >
            
            <div style={{overflow:"scroll" , marginLeft: 5}}>
                <Space>

                    <Button disabled={!editable} onClick={ handleCalculateAll } style = {{ marginBottom: 16 }} title="Calculate all Calculators of the Process" data-testid="calculate-all-process-calculators">Calculate all</Button>
                    
                    <Popconfirm title="Delete all calculators?" onConfirm={handleDeleteCalculators} disabled={!editable}>
                        <Button disabled={!editable} style = {{ marginBottom: 16 }} title="Delete all Calculators of the Process" data-testid="delete-all-process-calculators">Delete all</Button>
                    </Popconfirm>
                    
                    {/*
                    <Button onClick = { handleImportCalculators } style = {{ marginBottom: 16 }}>Import Process Calculators</Button>
                    <Button onClick = { handleExportCalculators } style = {{ marginBottom: 16 }}>Export Process Calculators</Button>
                    */}

                </Space>
            </div>

            <div style={{ marginLeft: 5, width: "100%" }}>
                <CalculatorTable 
                    selectedCalculator={selectedCalculator}
                    setSelectedCalculator={setSelectedCalculator}
                    
                />
            </div>
            
                {/*
                <Modal 
                        title= { isCalculatorSetModalExport ? 'Export calculators' : 'Import calculators' }
                        open={ isCalculatorSetModalVisible } 
                        onOk = { handleModalOk }
                        onCancel = { handleModalCancel }
                        destroyOnClose = {true}
                        footer={[
                            <Button type="primary" form="SelectCalculatorSetForm" key="submit" htmlType="submit" data-testid="submit-button">
                                Submit
                            </Button>
                            ]}
                    >
                        <Form

                            {...formLayout}  
                            id = "SelectCalculatorSetForm"
                            onFinish={handleModalOk} 
                            //initialValues={ modalInitialValues }
                        >   



                            { isCalculatorSetModalExport && 
                                <Form.Item label="Export to Topic:" name="topic" rules={[{ required: true}]} >
                                    <Select showSearch onChange={handleSelectTopic} data-testid="export-topic-select">
                                        { queryData.topics.map( (topic:any) => { return ( <Option key={topic.id} value={topic.id}>{topic.name}</Option>) } )}
                                    </Select>
                                </Form.Item>
                            }
                            
                            { !isCalculatorSetModalExport && 
                                <Form.Item label="Import from Topic:" name="topic" rules={[{ required: true}]} >
                                    <Select showSearch onChange={handleSelectTopic} data-testid="import-topic-select">
                                        { queryData.topics.map( (topic:any) => { return ( <Option key={topic.id} value={topic.id}>{topic.name}</Option>) } )}
                                    </Select>
                                </Form.Item>
                            }

                            { isCalculatorSetModalExport && 
                                <Form.Item label="Export to Calculator Set:" name="calculatorSet" rules={[{ required: true }]}  >
                                    <Select onChange={handleModalOnChange}  showSearch data-testid="export-calculator-set-select">
                                        
                                        <Option key="new" value="new">New</Option>
                            
                                        { queryData.calculatorSets
                                            .filter( (calculatorSet:any) => calculatorSet.topic.id == selectedTopic)
                                            .map( (calculatorSet:any) => { return ( <Option key={calculatorSet.name} value={calculatorSet.name}>{calculatorSet.name}</Option>) } )
                                        }

                                    </Select>
                                </Form.Item>
                            }

                            { !isCalculatorSetModalExport &&
                                <Form.Item label="Import from Calculator Set:" name="calculatorSet" rules={[{ required: true }]}  >
                                    <Select onChange={handleModalOnChange}  showSearch data-testid="import-calculator-set-select">
                            
                                        { queryData.calculatorSets
                                            .filter( (calculatorSet:any) => calculatorSet.topic.id == selectedTopic)
                                            .map( (calculatorSet:any) => { return ( <Option key={calculatorSet.id} value={calculatorSet.id}>{calculatorSet.name}</Option>) } )
                                        }

                                    </Select>   
                                </Form.Item>
                            }


                            
                            
                            { (isCalculatorSetModalExport && isCalculatorSetModalNameVisible)  &&
                                <div>

                                    <Form.Item label="Name:" name="name" rules={[{ required: isCalculatorSetModalNameVisible , message: 'Name is required.' }]} >
                                        <Input maxLength={30} data-testid="export-calculator-set-name" />
                                    </Form.Item>
                                </div>
                            }

                            
                            { !isCalculatorSetModalExport &&  // importing
                            <Fragment>
                                <Form.Item
                                    name="destination"
                                    label="Import to:"
                                    rules={[{ required: true }]}
                                >
                                    <Radio.Group onChange={handleImportDestinationChange}>
                                        <Radio value="process" >Process</Radio>
                                        <Radio value="topic">Topic</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="Destination:">
                                    {importDestinationText == "process" && 
                                        <div>Calculators will be added to the selected process.</div>
                                    } 
                                    {importDestinationText == "topic" && 
                                        <div>Calculators will be added to all processes in the selected topic.</div>
                                    } 
                                </Form.Item>

                            </Fragment>
                            }
                            
                        </Form>
                </Modal>
                */}


                <Modal 
                    title={calculateAllLoading ? "Calculating..." : "Deleting..."} 
                    open={calculateAllLoading || deleteAllLoading} 
                    closable={false} 
                    footer={null}>
                        
                    <Skeleton active />
                </Modal>
        </div>
    );

}

export default CalculatorTab