import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Space, Spin, message, Select, Card, Transfer, Modal, Button, Form } from 'antd';
//import {selectedProcesses } from '../../Apollo';


interface SelectProcessesProps {
    modelId: string
    availableProcesses: any
    setSelectedProcesses: Function
    selectedProcesses:any
    setRecomputeNeeded: Function
    editable: boolean
}

function SelectProcesses(props:SelectProcessesProps) {

    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<any>([]);

    const [targetKeys, setTargetKeys] = useState<any>([]);

    // use effect to set selected keys only once
    
    useEffect(() => {
        setTargetKeys(props.selectedProcesses);
    }
    , [props.selectedProcesses]);
    

    const showModal = (values: any) => {
        setModalVisible(true)
    }

    const handleModalOk = (values: any) => {
        props.setSelectedProcesses(targetKeys)
        props.setRecomputeNeeded(true)
        setModalVisible(false);
    };

    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };

    const formLayout = {
        labelCol: { span: 20 },
    };

    const transferData:any = []
    
    for(let i = 0; i < props.availableProcesses.length; i++){ 
        transferData.push({
            key: props.availableProcesses[i].id,
            title: props.availableProcesses[i].name,
            description: props.availableProcesses[i].name,
          });
    }

    const onSelectChange = (sourceSelectedKeys:any, targetSelectedKeys:any) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
      };
    
    const onChange = (nextTargetKeys:any, direction:any, moveKeys:any) => {
        setTargetKeys(nextTargetKeys);
    };

    
     return (
        <div>

            <Button type="default" onClick={showModal} style={{marginLeft:20}} data-testid="select-processes-button">Select Processes</Button>

            <Modal
                title='Select Processes'
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                width={800}

                footer={[
                    <Button type="primary" form="SelectProcessesForm" key="submit" htmlType="submit" disabled = {!props.editable} data-testid="select-processes-ok-button">
                        Ok
                    </Button>
                ]}
            >

                <Form
                    id="SelectProcessesForm"
                    onFinish={handleModalOk}

                >
                    <Transfer
                        dataSource={transferData}
                        showSearch
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={onChange}
                        onSelectChange={onSelectChange}
                        titles={['Available Processes', 'Selected Processes']}
                        
                        render={item => `${item.title}`}

                        listStyle={{
                            width: 350,
                            height: 400,
                          }}
                    />

                </Form>

            </Modal>

        </div>


    )

}

export default SelectProcesses