import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Space, Spin, message, Select, Card, Transfer, Modal, Button, Form } from 'antd';


interface SelectKPIsProps {
    modelId: string
    availableKPIs: any
    setSelectedKPIs: Function
    selectedKPIs:any
    setRecomputeNeeded: Function
    buttonLabel: string
    editable : boolean
}

function SelectKPIs(props:SelectKPIsProps) {

    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<any>([]);

    //const [targetKeys, setTargetKeys] = useState<any>([]);

    // use effect to set selected keys only once
    useEffect(() => {
        setSelectedKeys(props.selectedKPIs);
    }
    , [props.selectedKPIs]);


    const showModal = (values: any) => {
        setModalVisible(true)
    }

    const handleModalOk = (values: any) => {
        setModalVisible(false);
    };

    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };

    const formLayout = {
        labelCol: { span: 20 },
    };

    const transferData:any = []
    
    for(let i = 0; i < props.availableKPIs.length; i++){ 
        transferData.push({
            key: props.availableKPIs[i].name,
            title: props.availableKPIs[i].name,
            description: props.availableKPIs[i].name,
          });
    }

    const onSelectChange = (sourceSelectedKeys:any, targetSelectedKeys:any) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
      };
    
    const onChange = (nextTargetKeys:any, direction:any, moveKeys:any) => {
    props.setSelectedKPIs(nextTargetKeys)
    props.setRecomputeNeeded(true)
    };

    
     return (
        <div>

            <Button type="default" onClick={showModal} style={{marginLeft:20}} data-testid="select-kpis-button">{props.buttonLabel}</Button>

            <Modal
                title={props.buttonLabel}
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                width={800}

                footer={[
                    <Button type="primary" form="SelectKPIsForm" key="submit" htmlType="submit" disabled = {!props.editable} data-testid="select-kpis-ok-button">
                        Ok
                    </Button>
                ]}
            >

                <Form
                    id="SelectKPIsForm"
                    onFinish={handleModalOk}

                >
                    <Transfer
                        dataSource={transferData}
                        showSearch
                        targetKeys={props.selectedKPIs}
                        //targetKeys={clientVars.selectedProcesses}
                        selectedKeys={selectedKeys}
                        onChange={onChange}
                        onSelectChange={onSelectChange}
                        titles={['Available KPIs', 'Selected KPIs']}
                        
                        render={item => `${item.title}`}

                        listStyle={{
                            width: 350,
                            height: 400,
                          }}
                    />

                </Form>

            </Modal>

        </div>


    )

}

export default SelectKPIs