import React, { Fragment } from "react";
import HeaderExtra from "../components/HeaderExtra";
import { Statistic, Card } from 'antd';
import PageHeader from "../components/PageHeader";
import { DashboardOutlined, FundFilled, ExperimentOutlined, BarChartOutlined, SettingOutlined , LineChartOutlined, FunctionOutlined , BulbOutlined , MailOutlined} from '@ant-design/icons';
import DashboardProcessTable from "../components/dashboard/DashboardProcessTable";

function Dashboard() {

  const helpContent = (
    <p>
      The dashboard should include information that is relevant to you. Shortly, you will be able to customize the displayed statistics and components.
    </p>
  )

  return(

    <div >
      <PageHeader
          title="Dashboard"
          extra={<HeaderExtra/>}
       />
        
        <Card title="Hi Aydin!" style={{ marginBottom:10} }>
          <Card style={{width:200,float:"left", marginRight:10}}>
            <Statistic
              title="Topics"
              value={5}
              precision={0}
              prefix={<BulbOutlined />}
              suffix=""
            />
          </Card>   

          <Card style={{width:200,float:"left", marginRight:10}}>
            <Statistic
              title="Processes"
              value={288}
              precision={0}
              prefix={<ExperimentOutlined />}
              suffix=""
            />
          </Card>  

          <Card style={{width:200,float:"left", marginRight:10}}>
            <Statistic
              title="Max VCD (x1E9)"
              value={5.4}
              precision={2}
              prefix={<LineChartOutlined />}
              suffix=""
            />
          </Card>   

          <Card style={{width:200,float:"left", marginRight:10}}>
            <Statistic
              title="Max Space-time-yield"
              value={2.5}
              precision={1}
              prefix={<LineChartOutlined />}
              suffix=""
            />
          </Card>   

        </Card>
        
        
        <Card title="Latest processes" >
          <DashboardProcessTable/>
        </Card>
        

    </div>

 

  )
}

export default Dashboard;