import { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Table, Card, Space, Popconfirm, Button, Form, Spin, Modal, message, Tooltip, Input, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useWindowDimensions from '../useWindowDimensions';

const GET_ORGANIZATION_USERS = gql`
    query {  
        organizationUsers {
            id
            username
            email
            firstName
            lastName
            isActive
            isAdmin
            lastLogin
        }
        adminStats {
            totalUsers
            totalProcesses
            totalTopics
            totalTeams
        }
        organizationLogs {
            user {
              username
            }
            datetime
            message
        }
    }
`;

const ADD_USER = gql`
    mutation createUser($input: CreateUserInputType!) {
        createUser( input:$input) {
            user {
                id
                username
                email
                firstName
                lastName
                isActive
                isAdmin
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser($input: UpdateUserInputType!) {
        updateUser( input:$input) {
            user {
                id
                username
                email
                firstName
                lastName
                isActive
                isAdmin
            }
        }
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id:$id) {
            ok
        }
    }   
`;

const objectName = "User";

interface OrganizationUsersInputType {
};

function OrganizationUsers(props: OrganizationUsersInputType) {

    // window dimensions
    const { height, width } = useWindowDimensions();

    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [modalInitialValues, setModalInitialValues] = useState({ username: "", firstName: "", lastName: "", email: "", isActive: true, isAdmin: false });
    const [modalTitel, setModalTitel] = useState('');
    const [modalId, setModalId] = useState();
    const [isModalAdd, setModalAdd] = useState(true);


    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_ORGANIZATION_USERS);


    const [addMutation, { loading: addMutationLoading, error: addMutationError }] = useMutation(ADD_USER, {
        refetchQueries: [{ query: GET_ORGANIZATION_USERS }],
        onCompleted(data) { message.success(objectName + " was added successfully.") },
        onError(error) { message.error(error.message) },
    });

    const [updateMutation, { loading: updateMutationLoading, error: updateMutationError }] = useMutation(UPDATE_USER, {
        refetchQueries: [{ query: GET_ORGANIZATION_USERS }],
        onCompleted(data) { message.success(objectName + " was updated successfully.") },
        onError(error) { message.error(error.message) },
    });

    const [deleteMutation] = useMutation(DELETE_USER, {
        refetchQueries: [{ query: GET_ORGANIZATION_USERS }],
        onCompleted(data) { message.success(objectName + " was removed successfully.") },
        onError(error) { message.error(error.message) },
    });


    // ----------------------------------------------------------------------------------------

    const showModal = (values: any) => {
        setModalTitel("Add a user")
        setModalVisible(true)
        setModalAdd(true)
    }

    const handleModalOk = (values: any) => {

        if (isModalAdd) {

            let input = {
                username: values['username'],
                password: values['password'],
                firstName: values['firstName'],
                lastName: values['lastName'],
                email: values['email'],
                isAdmin: values['isAdmin'],
            };

            addMutation({ variables: { input: input } });
            setModalVisible(false);

        } else {
            let input = {}
            // update
            if (values['password'] == "") {
                input = {
                    id: modalId,
                    firstName: values['firstName'],
                    lastName: values['lastName'],
                    email: values['email'],
                    isAdmin: values['isAdmin'],
                    isActive: values['isActive'],
                };
            } else {
                input = {
                    id: modalId,
                    password: values['password'],
                    firstName: values['firstName'],
                    lastName: values['lastName'],
                    email: values['email'],
                    isAdmin: values['isAdmin'],
                    isActive: values['isActive'],
                };
            }
            updateMutation({ variables: { input: input } });
            setModalVisible(false);
        }
    };

    const handleEdit = (input: any) => {
        setModalTitel("Edit user")
        setModalAdd(false)

        setModalInitialValues({
            username: input.name,
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
            isActive: input.isActive,
            isAdmin: input.isAdmin,
        })

        setModalId(input.id)
        setModalVisible(true)
    }

    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };


    const handleDelete = (id: Number) => {

        deleteMutation({ variables: { id: id } })
    }

    const formLayout = {
        labelCol: { span: 6 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 12 },
    };


    const TableColumns = [
        {
            title: 'User Name',
            dataIndex: 'username',
            editable: false,
            width: '15%',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            editable: false,
            width: '15%',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            editable: false,
            width: '15%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: false,
            width: '25%',
        },
        /*{   title: 'Last login',
            dataIndex: 'lastLogin',
            editable: false,
            width: '10%',
            render: (text: any, record: any) => {
                if (record.lastLogin == null) {
                    return "Never"
                } else {
                    return moment(record.lastLogin).format('DD.MM.YYYY HH:mm')
                }
            }
        },*/
        {
            title: 'Active',
            editable: false,
            render: (text: any, record: any) => (
                <Space size="middle">
                    {record.isActive ? "Yes" : "No"}
                </Space>
            ),
            width: '10%',
        },
        {
            title: 'Admin',
            editable: false,
            render: (text: any, record: any) => (
                <Space size="middle">
                    {record.isAdmin ? "Yes" : "No"}
                </Space>
            ),
            width: '10%',
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '10%',
            render: (text: String, record: any) => (
                <div>
                    <Space>
                        {/* Edit */}
                        <Button type="link" onClick={() => handleEdit(record)} data-testid={"edit-user-" + record.username}>Edit</Button>

                        <Popconfirm title="Sure to delete user?" onConfirm={() => handleDelete(record.id)} data-testid={"delete-user-" + record.username}>
                            <a data-testid={"delete-user-link-" + record.username }>Delete</a>
                        </Popconfirm>

                    </Space>
                </div>
            ),
        },
    ]

    if (queryLoading) {
        return (
            <Spin />
        )

    }
    if (queryError) return (
        <Card>Error: {queryError.message} </Card>
    );

    return (

        <div>

                <Button
                    style={{ marginBottom: 16 }}
                    onClick={showModal}
                    data-testid="create-user"
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                >
                    Create a user
                </Button>


                <Table
                    loading={queryLoading}
                    dataSource={queryData.organizationUsers}
                    columns={TableColumns}
                    bordered
                    rowKey={record => record.id}
                    size="small"
                    pagination={false}
                    scroll={{ y: height - 320 }}

                />



            <Modal
                title={modalTitel}
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                footer={[
                    <Button type="primary" form="TeamUserForm" key="submit" htmlType="submit" data-testid="submit-button">
                        Submit
                    </Button>
                ]}
            >
                <Form
                    {...formLayout}
                    id="TeamUserForm"
                    onFinish={handleModalOk}
                    initialValues={modalInitialValues}
                >
                    {isModalAdd &&
                        <Form.Item label="Username:" name="username" rules={[{ required: true, message: 'Please enter a username.' }]} >
                            <Input data-testid="username"/>
                        </Form.Item>
                    }

                    <Form.Item label="First Name:" name="firstName" rules={[{ required: true, message: 'Please enter a first name.' }]} >
                        <Input data-testid="firstname" />
                    </Form.Item>

                    <Form.Item label="Last Name:" name="lastName" rules={[{ required: true, message: 'Please enter a last name.' }]} >
                        <Input data-testid="lastname" />
                    </Form.Item>
                    
                    <Form.Item label="Email:" name="email" rules={[{ required: true, message: 'Please enter an email.' }]} >
                        <Input data-testid="email"/>
                    </Form.Item>
                

                    {isModalAdd &&
                        <Form.Item label="Password:" name="password" rules={[{ required: true, message: 'Please enter a password.' }]} >
                            <Input.Password data-testid="password"/>
                        </Form.Item>
                    }
                    
                    {!isModalAdd &&
                        <Tooltip title="Enter a password to reset or leave empty to keep the old password.">
                        <Form.Item label="New password:" name="password" rules={[{ required: false }]} >
                            <Input.Password data-testid="password-new"/>
                        </Form.Item>
                        </Tooltip>
                    }

                    {!isModalAdd &&
                        <Form.Item label="Active:" name="isActive" valuePropName="checked" >
                            <Switch data-testid="is-active" />
                        </Form.Item>
                    }

                    <Form.Item label="Admin:" name="isAdmin" valuePropName="checked">
                        <Switch data-testid="is-admin"/>
                    </Form.Item>

                </Form>

            </Modal>
        </div>

    );
}

export default OrganizationUsers;

