import React, {useState, useEffect} from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { Select, Collapse, Button, Form, Skeleton, Modal, Input, message, Tooltip} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
const {Option} = Select;
const { Panel } = Collapse;

const GET_CALC_SAVE_INTERFACE = gql`
    query calcSaveInterfacesByCalculatorId ($id:ID!) {  
        calcSaveInterfacesByCalculatorId(id:$id) {
            id
            name
            section
            order
            description
            units
            interfaceType
            direction
            required
            altNames
            optionType
            listValues
            valueFloat
            valueChar
            status
        }
    }
`;

const GET_CALC_INTERFACE  = gql`
    query calcInterfacesByCalculatorId ($id:ID!) {  
        calcInterfacesByCalculatorId(id:$id) {
            id
            name
            section
            order
            description
            units
            interfaceType
            direction
            required
            altNames
            optionType
            listValues
            valueFloat
            valueChar
            status
        }
    }
`;

const UPDATE_CALC_INTERFACE  = gql`
    mutation updateCalcInterface($input: UpdateCalcInterfaceInputType!) {
        updateCalcInterface( input:$input) {
            calcInterface {
                id
                name
                section
                order
                description
                units
                interfaceType
                direction
                required
                altNames
                optionType
                valueFloat
                valueChar
                status
            }
        }
    }
`;

const UPDATE_CALC_SAVE_INTERFACE = gql`
    mutation updateCalcSaveInterface($input: UpdateCalcSaveInterfaceInputType!) {
        updateCalcSaveInterface( input:$input) {
            calcSaveInterface {
                id
                name
                section
                order
                description
                units
                interfaceType
                direction
                required
                altNames
                optionType
                valueFloat
                valueChar
                status
            }
        }
    }
`;


interface EditCalculatorInterfacesInputType {
    calcId:any,
    calcfunction:String,
    calcType:String, // "Calculator" or "CalculatorSave"
    visible:boolean,
    setVisible:Function,

};

function EditCalculatorInterfaces(props:EditCalculatorInterfacesInputType) {

    const [interfaceValues, setInterfaceValues] = useState<any>({});

    const [getCalcInterfaces, { loading: calcInterfacesLoading, error: calcInterfacesError, data: calcInterfacesData }] = useLazyQuery(GET_CALC_INTERFACE, {variables: {id:props.calcId} , fetchPolicy: 'cache-and-network'});
    const [getCalcSaveInterfaces, { loading: calcSaveInterfacesLoading, error: calcSaveInterfacesError, data: calcSaveInterfacesData }] = useLazyQuery(GET_CALC_SAVE_INTERFACE, {variables: {id:props.calcId} , fetchPolicy: 'cache-and-network'});

    const [updateCalcInterfaceMutation] = useMutation(UPDATE_CALC_INTERFACE, {
        //onCompleted(data) { message.success("Edited successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    const [updateCalcSaveInterfaceMutation] = useMutation(UPDATE_CALC_SAVE_INTERFACE, {
        //onCompleted(data) { message.success("Edited successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    // use Effect to get the interfaces depending on whether it is a calculator or calculator save
    useEffect(() => {

        if (props.calcId !== null && props.calcId !== undefined && props.calcId !== "") {

            if (props.calcType == "Calculator") {
                getCalcInterfaces();
            } else if (props.calcType == "CalculatorSave") {
                getCalcSaveInterfaces();
            }

            // reset the interface values
            setInterfaceValues({});
        }

    }, [props.calcId, props.calcType]);

    if ((calcInterfacesLoading && !calcInterfacesData) || (calcSaveInterfacesLoading && !calcSaveInterfacesData)) return <div></div>;
    //if (calcInterfacesError || calcSaveInterfacesError) return (<p>Error : {calcInterfacesError} {calcSaveInterfacesError}</p>);
    if (calcInterfacesError) return (<p>Error in getting the interfaces for the calculator: {calcInterfacesError.message}</p>);

    let calcInterfaces = [];
    if (props.calcType == "Calculator") {
        calcInterfaces = calcInterfacesData?.calcInterfacesByCalculatorId;
    } else if (props.calcType == "CalculatorSave") {
        calcInterfaces = calcSaveInterfacesData?.calcSaveInterfacesByCalculatorId;
    }

    if (calcInterfaces == null) {
        return <p></p>
    }

    //console.log(calcInterfaces)

    // loop through the interfaces and create a fields array
    let fields:any = [];
    let sections:any = [];
    let initialValues:any = {};

    for (let i = 0; i < calcInterfaces.length; i++) {

        let field:any = {};
        
        field["id"] = calcInterfaces[i].id;
        field["order"] = calcInterfaces[i].order;
        field["section"] = calcInterfaces[i].section;
        field["name"] = calcInterfaces[i].name;
        field['type'] = calcInterfaces[i].interfaceType;
        field['description'] = calcInterfaces[i].description;
        field['optionType'] = calcInterfaces[i].optionType;

        if (calcInterfaces[i].required == "TRUE") {
            field['required'] = true;
        } else {
            field['required'] = false;
        }

        if (calcInterfaces[i].interfaceType == "PARAMETER" || calcInterfaces[i].interfaceType == "VARIABLE" || calcInterfaces[i].interfaceType == "METADATA") {
            field['initialValue'] = calcInterfaces[i].altNames
        }

        if (calcInterfaces[i].interfaceType == "OPTION" && calcInterfaces[i].optionType == "FLOAT") {
            field['initialValue'] = calcInterfaces[i].valueFloat;
        } else if (calcInterfaces[i].interfaceType == "OPTION" && (calcInterfaces[i].optionType == "STRING" || calcInterfaces[i].optionType == "BOOLEAN")) {
            field['initialValue'] = calcInterfaces[i].valueChar;
        } else if (calcInterfaces[i].interfaceType == "OPTION" && calcInterfaces[i].optionType == "LIST") {
            field['listValues'] = calcInterfaces[i].listValues;
            field['initialValue'] = calcInterfaces[i].valueChar;
        }

        initialValues[calcInterfaces[i].name] = field['initialValue'];


        // capitalize the first letter of the name and remove the underscores
        let displayName = calcInterfaces[i].name.charAt(0).toUpperCase() + calcInterfaces[i].name.slice(1);
        displayName = displayName.replace(/_/g, " ");
        field['displayName'] = displayName;

        fields.push(field);

        // add the section to the sections array
        if (sections.indexOf(calcInterfaces[i].section) == -1) { // if the section is not in the array
            sections.push(calcInterfaces[i].section);
        }
    }

    // TODO: will be needed for the implementation of conditional fields e.g. enable the field if the value of another field is true
    //let formState = {...initialValues, ...interfaceValues}; 

    function onChangeFormField(value:any, name?:any) {

        // if name is not defined
        if (name == undefined) {
            setInterfaceValues({...interfaceValues, [value.target.name]:value.target.value});
        } else {
            setInterfaceValues({...interfaceValues, [name]:value});
        }
    }



    function handleModalOk() {
        
        //console.log('interfaceValues: ', interfaceValues);

        // combine initialValues and interfaceValues
        let combinedValues = {...initialValues, ...interfaceValues}; // this is the same as formState //TODO: should it be formState?
        //console.log('combinedValues: ', combinedValues);

        // loop through combinedValues, get fields and check whether the field is required or not
        let keys = Object.keys(combinedValues);
        for (let i = 0; i < keys.length; i++) {
            let field = fields.find((field:any) => field.name == keys[i]);
            if (field.required == true && (combinedValues[keys[i]] == undefined || combinedValues[keys[i]] == "")) {
                message.error("Please fill in all the required fields.");
                return;
            }
        }

        // loop through the keys of interfacValues
        keys = Object.keys(interfaceValues);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = interfaceValues[key];
            // get the field
            let field = fields.find((field:any) => field.name == key);
            
            // update the interface
            let input:any = {};

            if (field.type == "PARAMETER" || field.type == "METADATA" || field.type == "VARIABLE") {
                input = {
                    id: field.id,
                    altNames: value,
                };
            }

            if (field.type == "OPTION" && field.optionType == "BOOLEAN") {
                input = {
                    id: field.id,
                    valueChar: value,
                };
            }

            if (field.type == "OPTION" && field.optionType == "FLOAT") {
                input = {
                    id: field.id,
                    // convert value to float
                    valueFloat: parseFloat(value),
                };
            }

            if (field.type == "OPTION" && field.optionType == "STRING") {
                input = {
                    id: field.id,
                    valueChar: value,
                };
            }

            if (field.type == "OPTION" && field.optionType == "LIST") {
                input = {
                    id: field.id,
                    valueChar: value,
                };
            }
            
            if (props.calcType == "Calculator") {
                updateCalcInterfaceMutation({variables: {input:input}});
            } else if (props.calcType == "CalculatorSave") {
                updateCalcSaveInterfaceMutation({variables: {input:input}});
            }
        }
        
        props.setVisible(false);
    }

    
    const formLayout = {
        labelCol: { span: 8  },

    }

    return (
        
        <Modal
            title={"Edit Calculator: " + props.calcfunction}
            open={props.visible}
            destroyOnClose={true}
            width={"1000px"}
            footer={[
                <Button type="primary" form="EditCalculatorInterfacesForm" key="submit" htmlType="submit" data-testid="submit-button">
                    Submit
                </Button>
                ]}
            onCancel={() => props.setVisible(false)}

        >

        <Form 
            {...formLayout}
            size="small"
            id="EditCalculatorInterfacesForm" 
            onFinish={handleModalOk} 
        >

            <div style={{height: 'calc(100vh - 400px)', overflowY: 'auto'}}>

                <Collapse defaultActiveKey={['0']} >
                {sections.map((section:any, index:any) => (
                    <Panel header={section==null ? "1. General" : section} key={index} data-testid={"section-panel-" + index}>

                    {/* order in ascending order */ }                  
                    {fields.filter((field:any) => field.section == section).map((field:any) => (
                        
                        <Form.Item 
                            label={field.displayName}
                            //key={field.id}
                            name={field.name}
                            required={field.required}
                            //initialValue={field.initialValue}
                            //rules={[{ required: field.required, message: 'Please enter a value.' }]}
                            style={{display: false ? 'none' : 'block'}}
                        >
                            <div style={{width: 'calc(100% - 200px)'}}>
                            
                                {field.type == "OPTION" && field.optionType == "STRING" && 
                                    <Input style={{float:"left" , width:'calc(100% - 30px)'}}  placeholder="String" onChange={onChangeFormField} name={field.name} defaultValue = {field.initialValue} data-testid="string-input"/>
                                }

                                {field.type == "OPTION" && field.optionType == "FLOAT" && 
                                    <Input style={{float:"left" , width:'calc(100% - 30px)'}}  placeholder="Number" onChange={onChangeFormField} name={field.name}  defaultValue = {field.initialValue} data-testid="float-input"/>
                                }

                                {field.type == "OPTION" && field.optionType == "BOOLEAN" && 
                                    <Select style={{float:"left" , width:'calc(100% - 30px)'}}  placeholder="True or False" onChange={(value, name) => onChangeFormField(value, field.name)}  defaultValue = {field.initialValue} data-testid="boolean-select">
                                        <Option instance={field.name} value="True">True</Option>
                                        <Option instance={field.name} value="False">False</Option>
                                    </Select>
                                }

                                {field.type == "OPTION" && field.optionType == "LIST" && 
                                    <Select style={{float:"left" , width:'calc(100% - 30px)'}}  placeholder="Select an option" onChange={(value, name) => onChangeFormField(value, field.name)}  defaultValue = {field.initialValue} data-testid="list-select">
                                        {field.listValues.map((listValue:any, index:any) => (
                                            <Option instance={field.name} value={listValue}>{listValue}</Option>
                                        ))}
                                    </Select>
                                }

                                {field.type == "PARAMETER" &&
                                    <Input style={{float:"left" , width:'calc(100% - 30px)'}} placeholder="Name of the parameter"   onChange={onChangeFormField} name={field.name}  defaultValue = {field.initialValue} data-testid="parameter-input"/>
                                }
                                {field.type == "VARIABLE" &&
                                    <Input style={{float:"left" , width:'calc(100% - 30px)'}} placeholder="Name of the variable"  onChange={onChangeFormField} name={field.name}  defaultValue = {field.initialValue} data-testid="variable-input"/>
                                }

                                {field.type == "METADATA" &&
                                    <Input style={{float:"left" , width:'calc(100% - 30px)'}} placeholder="Name of the metadata"   onChange={onChangeFormField} name={field.name} defaultValue = {field.initialValue} data-testid="metadata-input"/>
                                    
                                }     

                                {field.description &&
                                    <Tooltip title={field.description}>
                                        <InfoCircleOutlined style={{  float:"right", font:"20px", marginTop:"5px"}} />
                                    </Tooltip>
                                }

                            </div>

                        </Form.Item>
                    ))}

                    </Panel>

                ))}
                </Collapse>
            </div>
        
        </Form>
        </Modal>
    )

    
}

export default EditCalculatorInterfaces;