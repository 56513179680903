import React from "react";
import AddProcess from '../components/AddProcess';
import { Breadcrumb , Card} from 'antd';
import PageHeader from "../components/PageHeader";
import {Link} from "react-router-dom";
import HeaderExtra from "../components/HeaderExtra";

function CreateProcess() {
  
  const routes = [
    {
      path: 'processes',
      breadcrumbName: 'Processes',
    },
    {
      path: '',
      breadcrumbName: "Add process" ,
    }
  ];
  
  return(  
    <div>

      <PageHeader
        title="Add process"
        extra={<HeaderExtra/>}
      />

        {/*<AddProcess />*/}


    </div>
  )
}

export default CreateProcess;
