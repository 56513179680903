import {useEffect} from 'react';
import { useQuery, useLazyQuery, gql, useReactiveVar } from '@apollo/client';
import { Space, Spin, Select, Card } from 'antd';
import {Process} from '../../models/Process';
import {calculatorsSelectedProcess, globalSelectedTopic } from '../../Apollo';

import { pollInterval } from '../..';

const { Option } = Select;

const GET_PROCESSES = gql`
    query processesByTopicId ($topicId:ID!) {   
        processesByTopicId (topicId:$topicId) {
            id
            name
        }
    }
`;


interface CalculatorsProcessInputType {
}

function CalculatorsProcess(props: CalculatorsProcessInputType) {

    const calculatorsSelectedProcessValue = useReactiveVar(calculatorsSelectedProcess);
    const globalSelectedTopicValue = useReactiveVar(globalSelectedTopic);

    /*
    let topicId = "-1";
    if (clientVars.globalSelectedTopic && clientVars.globalSelectedTopic != "") {
        topicId = clientVars.globalSelectedTopic
    }
    */

    const { loading:queryLoading, error:queryError, data:queryData } = useQuery(GET_PROCESSES , {
        variables: { topicId: globalSelectedTopicValue },
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    const handleProcessSelect = (input:any)=>{
        calculatorsSelectedProcess(input)
    }

    // set the initial process
    useEffect(() => {
        if (!queryError && (!queryLoading || queryData)) {
            if (calculatorsSelectedProcessValue == "" && queryData.processesByTopicId.length>0) {
                calculatorsSelectedProcess(queryData.processesByTopicId[0].id)
            }

            if ( !queryData.processesByTopicId.find((p: any) => p.id == calculatorsSelectedProcessValue) ) {
                if (queryData.processesByTopicId.length>0) {
                    calculatorsSelectedProcess(queryData.processesByTopicId[0].id)
                } else {
                    calculatorsSelectedProcess("")
                }
            }
            
        }
    }, [queryData]);

    if (queryError) return (
        <div>Error: {queryError.message} </div>
        
    );

    if (queryLoading && !queryData) return (
        <Spin />
    );

    return (
        <div style={{ width: '100%' }} >

            <Space direction="vertical" size="small" style={{ width: '100%' }} >

                <Select
                    style={{ width: 300 }}
                    placeholder="Please select"
                    onChange={handleProcessSelect}
                    value={ calculatorsSelectedProcessValue }
                    showSearch
                    optionFilterProp="title"
                    data-testid="process-select"
                    
                >

                    {queryData.processesByTopicId.map( (process:Process) => { return <Option key={process.name} value={process.id} title={process.name}>{process.name}</Option> })}

                </Select>

            </Space>
        </div>
    );

}

export default CalculatorsProcess;