import React, { FunctionComponent } from 'react';
import SideMenu from '../components/SideMenu';
import { Layout, Card, Result } from 'antd';
import { useQuery, gql } from '@apollo/client';
import { ConfigProvider, theme } from "antd";
import connection_error from '../images/connection_error_3.png';
import { pollInterval } from "..";

const { defaultAlgorithm, darkAlgorithm } = theme;
const { Header, Footer, Content } = Layout;


const GET_USER_PROFILE = gql`
    query userProfile {  
        userProfile {
            id
            avatarB64
            darkMode
            leftMenuCollapsed
        }  
    }
`;

const SidebarPage: FunctionComponent<any> = ({ children }) => {

    const { loading:queryLoading, error:queryError, data:queryData } = useQuery(GET_USER_PROFILE, { 
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    if (queryError) return (
        <ConfigProvider theme={{ algorithm : darkAlgorithm }}>
            <Content>
                <Card style={{height:"100vh"}}>
                    <Result
                        //status="500"
                        icon={<img src={connection_error} style={{width:"400px"}}/>}
                        title="Connection Error"
                        subTitle="Could not establish a connection to the server. Please try again later."
                    />
                </Card>
            </Content>
        </ConfigProvider>
    )

    return (
        <ConfigProvider theme={{ algorithm : queryData?.userProfile?.darkMode ? darkAlgorithm : defaultAlgorithm }}>
            <Layout style={{ overflow:"hidden"}}>
                <SideMenu/>
                <Layout style={{ padding: '10px 10px 10px' }}>
                <Content className="content">
                    {children}
                </Content>
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}

export default SidebarPage;
