import { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Collapse, Button, Space, Switch, InputNumber, Card, Spin, message, Select, Form , Input, Modal} from 'antd';


const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;


interface OptimizationMenuInputType {

}

function OptimizationMenu(props: OptimizationMenuInputType) {

    return (

        <div style={{ overflow: 'auto', width: '220px' }} >


            <Collapse defaultActiveKey={["1", "2", "3", "4", "3", "5", "6", "7"]}  >


                <Panel header={"Processes"} key="1" data-testid="process-panel">
                    {/*
                    <SelectProcesses
                        modelId={props.modelId}
                        availableProcesses={data.statModel.analysis.processes}
                        selectedProcesses={selectedProcesses}
                        setSelectedProcesses={setSelectedProcesses}
                        setRecomputeNeeded={setRecomputeNeeded}
                        editable = {data.statModel.analysis.userPermissions != "read"}
                    />
                    */}
                </Panel>

                <Panel header="Variables" key="4" data-testid="kpi-panel">

                </Panel>

                { /* if modelfunction is MLR */}

                <Panel header={"Target"} key="7" data-testid="y-panel">

                    {/* create an antd dropdown to select one KPI */}
                    {/*
                    <Select 
                        style={{ marginLeft: 20, width: 150 }} 
                        onChange={onChangeSelectedY} 
                        defaultValue={data.statModel.yName} 
                        disabled = {data.statModel.analysis.userPermissions == "read"}
                        showSearch
                        optionFilterProp='title'
                        data-testid="ySelect"

                    >
                        {data.statModel.analysis.kpiSet.map((kpi: any) => {
                            return (
                                <Option key={kpi.name} value={kpi.name} title={kpi.name}>{kpi.name}</Option>
                            )
                        }
                        )}
                    </Select>
                    */}

                </Panel>

            </Collapse>

        </div>

    )

}

export default OptimizationMenu;