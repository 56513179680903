

import { Card } from 'antd';
import { gql, useQuery } from '@apollo/client';
import HeaderExtra from "../components/HeaderExtra";
import PageHeader from "../components/PageHeader";
import useWindowDimensions from '../components/useWindowDimensions';
import OptimizationMenu from '../components/optimization/OptimizationMenu';

export const GET_USER_PROFILE = gql`
    query userProfile {
        userProfile {
            id
            selectedAnalysis {
              id
              name
            }
        }
    }
`


function Optimization() {

  const { height, width } = useWindowDimensions();

  const { loading, error, data } = useQuery(GET_USER_PROFILE);

  const routes = [
    {
      path: '',
      breadcrumbName: "Analysis: " + data.userProfile.selectedAnalysis.name ,
    }
  ];

  return(

    <div>

        <PageHeader
          title="Bayesian Optimization"
          extra={<HeaderExtra/>}
          breadcrumb={{ routes: routes }}
        />

          <div style={{ display:'flex' }}>

            <div style = {{ flex:'0 0 220px' }}>
                <div style={{height:height-165 , overflow:"auto"}} >
                    <OptimizationMenu />
                    
                </div>
                
            </div>

            <div style={{ overflow:'auto' , width:"100%"}} >
            
            </div>
            </div>


    </div>

 

  )
}

export default Optimization;