// Component to add a parameter to all topic processes

import { useState, useEffect } from "react";
import { useMutation, gql } from '@apollo/client';
import { Button,Modal, Form, Input, InputNumber, message , Radio, Alert, Popconfirm} from 'antd';
import { CORE_PARAMETER_FIELDS, CORE_PROCESS_FIELDS } from "../fragments";
import { PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;


const CREATE_PARAMETER = gql`
    ${CORE_PARAMETER_FIELDS}
    ${CORE_PROCESS_FIELDS}
    mutation createParameter($input: CreateParameterInputType!) {
        createParameter( input:$input) {
            process {
                ...CoreProcessFields
                parameterSet {
                    ...CoreParameterFields
                }
            }
        }
    }
`;

const UPDATE_PARAMETER = gql`
    ${CORE_PARAMETER_FIELDS}
    ${CORE_PROCESS_FIELDS}
    mutation updateParameter($input: UpdateParameterInputType!) {
        updateParameter( input:$input) {
            process {
                ...CoreProcessFields
                parameterSet {
                    ...CoreParameterFields
                }
            }
        }
    }
`;

const DELETE_PARAMETER = gql`
    mutation deleteParameter($id: ID!) {
        deleteParameter( id:$id) {
            id
            ok
        }
    }
`;

const GET_PROCESS_PARAMETER = gql`
    ${CORE_PARAMETER_FIELDS}
    ${CORE_PROCESS_FIELDS}
    query process ($id:ID!) {  
        process(id:$id) {
            ...CoreProcessFields
            parameterSet {
                ...CoreParameterFields
            }
        }
    }
`;

interface AddParameterInputType {
    mode : string
    setMode: any
    topicId: string
    processId: number | string
    parameterId: number
    isModalVisible: boolean
    setIsModalVisible: any
    modalInitialValues: any
    setModalInitialValues: any
    editDisabled: boolean
    setEditDisabled: any
    userPermissions: string

}

const defaultProps:AddParameterInputType = {
    mode : "add",               // add or edit
    setMode: () => {},
    topicId: "",
    processId: 0,
    parameterId: 0,
    isModalVisible: false,
    setIsModalVisible: () => {},
    modalInitialValues: { scope:"process", name:"", value:0, units:"", description:"" },
    setModalInitialValues: () => {},
    editDisabled: false,
    setEditDisabled: () => {},
    userPermissions: "read",
}

function AddParameter(props:AddParameterInputType) {

    const [scope, setScope] = useState("process");

    const [createMutation, { loading: createMutationLoading, error: createMutationError } ] = useMutation(CREATE_PARAMETER, {
        refetchQueries: [ { query: GET_PROCESS_PARAMETER, variables: { id: props.processId } } ], 
        onCompleted(data) { message.success( "Parameter was added successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    
    const [updateMutation] = useMutation(UPDATE_PARAMETER, {
        onCompleted(data) { message.success("Parameter was updated successfully.") },
        onError(error) {message.error(error.message)}, 
    });

    const [deleteMutation] = useMutation(DELETE_PARAMETER, {
        refetchQueries: [ { query: GET_PROCESS_PARAMETER, variables: { id: props.processId }}  ],
        onCompleted(data) { message.success("Parameter was deleted successfully.") },
        onError(error) {message.error(error.message)}, 

    });

    // useEffect to set the scope=process the first time the modal is opened
    useEffect(() => {
        if (props.isModalVisible) {
            setScope("process");
        }
    }, [props.isModalVisible]);

    function handleAdd() {

        props.setMode("add");
        props.setEditDisabled(false);
        props.setModalInitialValues( { scope:"process", name:"", value:0, units:"", description:"" } );
        props.setIsModalVisible(true);
    }

    const handleDelete = (id:Number) => {   
        deleteMutation({ variables: { id: id  } } )
        props.setIsModalVisible(false);
    }

    const handleOk = (values: any) => {
    
        // adding
        if (props.mode == "add") {
            if (scope == "process") {
                let input = {
                    name : values['name'], 
                    value : values['value'],
                    units: values['units'],
                    description: values['description'],
                    processId:props.processId
                };
                
                createMutation( { variables: { input: input  } } );
            }
            else if (scope == "topic") {
                let input = {
                    name : values['name'], 
                    value : values['value'],
                    units: values['units'],
                    description: values['description'],
                    topicId:props.topicId,
                    scope: "topic"
                };
                
                createMutation( { variables: { input: input  } } );
            }
            
        // editing

        } else {
    
            let input = {
                id: props.parameterId,
                name : values['name'], 
                value : values['value'],
                units: values['units'],
                description: values['description'],
                processId:props.processId
            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        props.setIsModalVisible(false);
    };

    function handleCancel() {
        props.setIsModalVisible(false);
    }

    const formLayout = {
        labelCol: { span: 4 },
    };

    return(
        <div>

            <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                style = {{ marginBottom: 16 }}
                data-testid="add-parameter-button"  
                onClick={handleAdd}
                disabled={props.userPermissions == "read"}
                title = {props.userPermissions == "read" ? "You do not have permission to create parameters." : "Create a parameter"}
            >
                Create a parameter
            </Button>

            <Modal 
                    title= { props.mode == "add" ? "Add parameter" : "Edit parameter" }
                    open={ props.isModalVisible }
                    onOk = { handleOk }
                    onCancel = { handleCancel }
                    destroyOnClose = {true}
                    data-testid="parameter-modal"
                    footer={[
                                            // if the modal is in edit mode, add a delete button to the footer
                        props.mode == "edit" ?
                        <Popconfirm title="Are you sure you want to delete this parameter?" onConfirm={() => handleDelete(props.parameterId)} disabled={props.editDisabled || props.userPermissions == "read"}>
                            <Button 
                                type="primary" 
                                ghost 
                                data-testid="delete-note-button"
                                disabled={props.editDisabled || props.userPermissions == "read"}
                                style={{ marginRight: 5 }}
                                title={props.editDisabled ? "The parameter is the output of a calculator and cannot be edited." : "Delete the parameter"}
                                
                            >
                                    Delete
                                </Button>
                        </Popconfirm>
                        : null
                        ,

                        <Button 
                            type="primary" 
                            form="ParameterForm"
                            key="submit" 
                            htmlType="submit"
                            data-testid="parameter-modal-submit"
                            disabled={props.editDisabled || props.userPermissions == "read"}
                            title={props.editDisabled ? "The parameter is the output of a calculator and cannot be edited." : ""}
                            
                        >
                            Submit
                        </Button>
                        ]}
                >
                    <Form
                        {...formLayout}  
                        id = "ParameterForm"
                        onFinish={handleOk} 
                        initialValues={ props.modalInitialValues }
                    > 

                    { props.mode == "add" &&
                        <Form.Item label="Scope:" name="scope" rules={[{ required: true, message: 'Please select a scope.' }]}>
                            <Radio.Group onChange={(e) => setScope(e.target.value)}>
                                <Radio value={"process"}>Add parameter to this process</Radio>
                                <Radio value={"topic"} >Add parameter to all processes of the collection</Radio>
                            </Radio.Group>
                        </Form.Item>
                    }

                    { scope == "topic" && props.mode == "add" &&
                        
                        <Alert
                            message="Warning"
                            description="Parameter will be added to all processes of the topic. Any existing parameters with the same name will be overwritten."
                            type="warning"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                    }

                        <Form.Item 
                            label="Name:" 
                            name="name" 
                            rules={[
                                { required: true, message: 'Please enter a name.' }, 
                                { max: 200, message: 'Name must be 200 characters or less.' },
                                { pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/, message: 'Name must start with a letter or underscore and can only contain letters, numbers, and underscores.' }
                            ]} 
                            validateFirst={true}
                            validateTrigger={"onBlur"}
                        >

                            <Input 
                                maxLength={200}
                                //showCount
                                data-testid="parameter-name-input"
                                // disable if the parameter has a calculator    
                                disabled={props.editDisabled}
                                title={props.editDisabled ? "The parameter is the output of a calculator and cannot be edited." : ""}
                            />
                        </Form.Item>

                        <Form.Item label="Value:" name="value" rules={[{ required: true, message: 'Please enter a numeric value.' }]}>
                            <InputNumber 
                                data-testid="parameter-value-input"
                                disabled={props.editDisabled}
                                title={props.editDisabled ? "The parameter is the output of a calculator and cannot be edited." : ""}
                            />
                        </Form.Item>

                        <Form.Item label="Units:" name="units" rules={[{ required: true, message: 'Please enter a unit.' }]} >
                            <Input
                                maxLength={200}
                                //showCount
                                data-testid="parameter-units-input"
                                disabled={props.editDisabled}
                                title={props.editDisabled ? "The parameter is the output of a calculator and cannot be edited." : ""}
                            />
                        </Form.Item>

                        <Form.Item label="Description:" name="description" rules={[{ required: false }]} >
                            <TextArea
                                maxLength={1000}
                                showCount
                                rows={4}
                                data-testid="parameter-description-input"
                                disabled={props.editDisabled}
                                // add a tooltip to the description input if the parameter has a calculator
                                title={props.editDisabled ? "The parameter is the output of a calculator and cannot be edited." : ""}
                            />
                        </Form.Item>

                    </Form>

                </Modal>

        </div>
    )
}

AddParameter.defaultProps = defaultProps;
export default AddParameter;