import React, {useState} from 'react';
import { useQuery, useMutation, gql, useLazyQuery } from '@apollo/client';
import { Table, Space, Button, Form, Skeleton, Modal, Input, message, Select, InputNumber, Tabs, Alert, Tooltip, Collapse} from 'antd';
import { SearchOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons';
import DataStandardOtherName from './DataStandardOtherNames';
import useWindowDimensions from '../useWindowDimensions';
import CopyDataStandards from './CopyDataStandards';
import DataStandardAssociated from './DataStandardAssociated';
import { pollInterval } from '../..';
import { CORE_DATA_STANDARD_FIELDS, CORE_VARIABLE_FIELDS, CORE_PARAMETER_FIELDS, CORE_METADATA_FIELDS } from '../fragments';

const { TextArea } = Input;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const CREATE_DATA_STANDARD = gql`
    ${CORE_DATA_STANDARD_FIELDS}
    mutation createDataStandard($input: CreateDataStandardInputType!) {
        createDataStandard( input:$input) {
            dataStandard {
                ...CoreDataStandardFields
            }
        }
    }
`;

const UPDATE_DATA_STANDARD = gql`
    ${CORE_DATA_STANDARD_FIELDS}
    mutation updateDataStandard($input: UpdateDataStandardInputType!) {
        updateDataStandard( input:$input) {
            dataStandard {
                ...CoreDataStandardFields
            }
        }
    }
`;

const DELETE_DATA_STANDARDS = gql`
    mutation deleteDataStandards($ids: [ID!]!) {
        deleteDataStandards( ids:$ids) {
            ok
        }
    }
`;

const GET_DATA_STANDARDS_EXPORT = gql`
    query dataStandardsExport($ids: [ID!]!) {
        dataStandardsExport(ids: $ids)
    }
`;

const GET_DATA_STANDARDS = gql`
    ${CORE_DATA_STANDARD_FIELDS}
    query dataStandards($topicId: ID, $teamId: ID, $organization: Boolean) {
        dataStandards(topicId: $topicId, teamId: $teamId, organization: $organization) {
            ...CoreDataStandardFields

            team {
                id
                owner {
                    id
                    username
                }
            }
        }

        userProfile {
            id
            admin
            user {
                id
                username
            }
        }
    }
`;


const GET_DATA_STANDARD = gql`

    ${CORE_DATA_STANDARD_FIELDS}
    ${CORE_VARIABLE_FIELDS}
    ${CORE_PARAMETER_FIELDS}
    ${CORE_METADATA_FIELDS}

    query dataStandard($id: ID!) {
        dataStandard(id: $id) {
            ...CoreDataStandardFields
            variableSet {
                ...CoreVariableFields
                process {
                    id
                    name
                }
            }
            parameterSet {
                ...CoreParameterFields
                process {
                    id
                    name
                }
            }
            metadataSet {
                ...CoreMetadataFields
                process {
                    id
                    name
                }
            }
        }
    }
`;


const GET_PROCESSES = gql`
    query processes($topicId:ID){  
        processes (topicId:$topicId) {
            processes {
                id
                qualityScore
                qualityMessages
            }
        }
    } 
`;



const objectName = "Lexicon entry";

interface DataStandardTableInputType {
    topicId?: String;
    teamId?: String;
    organization?: Boolean;
    editable: boolean;
};

// default props
DataStandardTable.defaultProps = {
    topicId: "",
    teamId: "",
    organization: false,
    editable: true,
};

function DataStandardTable(props:DataStandardTableInputType) {

    const { height, width } = useWindowDimensions();


    // ----------------------------------------------------------------------------------------
    // states

    const [isModalVisible, setModalVisible] = useState(false);
    const [isCopyModalVisible, setCopyModalVisible] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);

    const [modalInitialValues, setModalInitialValues] = useState( 
        { 
            name:"", 
            description:"", 
            required:false,
            type:"variable",
            units:"",
            valueLb:"",
            valueUb:"",
            minDataPoints:"",
            metaDataRegex:"",
            downsample: true,
            percentChange: 1,
            deadband: 0.001,
            minDataFreq: 1,
            maxDataFreq: 360,
            enabled: true,
        }
    );
    const [modalTitel, setModalTitel] = useState("");
    const [modalId, setModalId] = useState();
    const [dataStandardType , setDataStandardType] = useState("variable");
    const [isModalAdd, setModalAdd] = useState(true);
    const [isEditEnabled, setIsEditEnabled] = useState(true);
    const [currentLevel, setCurrentLevel] = useState("Topic");

    const [nameSearchText, setNameSearchText] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

    

    // ----------------------------------------------------------------------------------------
    // apollo queries and mutations

    let loadingTitle = "Loading..."

    const { loading:queryLoading, error: queryError, data: queryData } = useQuery(GET_DATA_STANDARDS , { 
        variables:{topicId:props.topicId, teamId:props.teamId, organization:props.organization},
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    } );

    // use a lazy query to get the datastandardexport
    const [getDataStandardsExport, { loading: queryLoadingExport, error: queryErrorExport, data: queryDataExport }] = useLazyQuery(GET_DATA_STANDARDS_EXPORT, {
        //variables: { ids: selectedRowKeys },
        onCompleted(data) {
            // get the base64 encoded YAML file
            const base64 = data.dataStandardsExport;
    
            // download the file
            const link = document.createElement('a');
            link.href = 'data:text/yaml;base64,' + base64;
            link.setAttribute('download', 'data_standards.yaml');
            // simulate click
            const event = new MouseEvent('click');
            link.dispatchEvent(event);
        },
        onError(error) {
            message.error(error.message);
        },
    });

    const [createMutation, { loading: mutationLoading1, error: createMutationError } ] = useMutation( CREATE_DATA_STANDARD, {
        refetchQueries: [ 
            { query: GET_DATA_STANDARDS, variables:{topicId:props.topicId, teamId:props.teamId, organization:props.organization} },
            { query: GET_PROCESSES, variables:{topicId:props.topicId} }, 
            { query: GET_DATA_STANDARD, variables:{id:modalId} },
        ], 
        onCompleted(data) { 
            message.success( objectName + " was added successfully.") 
            setModalId(data.createDataStandard.dataStandard.id)
            setModalAdd(false)
            handleEdit(data.createDataStandard.dataStandard)
            
        } ,

        onError(error) {message.error(error.message)},
    });

    if (mutationLoading1) {
        loadingTitle = "Creating..."
    }
    
    const [updateMutation, {loading:mutationLoading2}] = useMutation( UPDATE_DATA_STANDARD , {
        refetchQueries: [ 
            { query: GET_DATA_STANDARD, variables:{id:modalId} },
         ],
        onCompleted(data) { message.success( objectName + " was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });

    if (mutationLoading2) {
        loadingTitle = "Updating..."
    }
    
    const [deleteMutation, {loading:mutationLoading3}] = useMutation( DELETE_DATA_STANDARDS , {
        refetchQueries: [ 
            { query: GET_DATA_STANDARDS, variables:{topicId:props.topicId, teamId:props.teamId, organization:props.organization} },
            { query: GET_PROCESSES, variables:{topicId:props.topicId} },
        ],
        onCompleted(data) { message.success("Deleting lexicon entries will run in the background.") } ,
        onError(error) {message.error(error.message)}, 
    });

    if (mutationLoading3) {
        loadingTitle = "Deleting..."
    }

    // useEffec to set the current level
    React.useEffect(() => {
        if (props.topicId != "") {
            setCurrentLevel("Topic")
        } else if (props.teamId != "") {
            setCurrentLevel("Team")
        } else {
            setCurrentLevel("Organization")
        }
    }, [props.topicId, props.teamId, props.organization])

    // use effect to set the isEditEnabled state
    React.useEffect(() => {
        if (queryData?.userProfile.admin) {
            setIsEditEnabled(true)
        } else {
            setIsEditEnabled(props.editable)
        }
    }
    , [queryData?.userProfile.admin, props.editable])


    // ----------------------------------------------------------------------------------------
    
    const showModal = (values:any) => {
        setModalTitel("Add")
        setModalAdd(true);
        setModalInitialValues(
            {  
                name:"", 
                description:"", 
                required:false,
                type:"variable",
                units:"",
                valueLb:"",
                valueUb:"",
                minDataPoints:"",
                metaDataRegex:"",
                downsample: true,
                percentChange: 1,
                deadband: 0.001,
                minDataFreq: 1,
                maxDataFreq: 360,
                enabled: true,

                 
            })
        
        setDataStandardType("variable")
        setModalVisible(true)
    }

    function showCopyModal (values:any) {
        setCopyModalVisible(true)
    }

    function handleClickExport() {
        
        if (selectedRowKeys.length == 0) {
            message.error("Please select at least one lexicon entry to export.")
            return
        }

        getDataStandardsExport({ variables: { ids: selectedRowKeys } });
    }
    
    const handleModalOk = (values: any) => {

        let valueLb = values['valueLb']
        if (valueLb == "") {
            valueLb = null
        }

        let valueUb = values['valueUb']
        if (valueUb == "") {
            valueUb = null
        }

        let minDataPoints = values['minDataPoints']
        if (minDataPoints == "") {
            minDataPoints = null
        }
    
        // adding
        if (modalTitel == "Add")  {
    
            let input = {
                topicId: props.topicId,
                teamId: props.teamId,
                organization: props.organization,
                name : values['name'], 
                description: values['description'],
                required: values['required'],
                type: dataStandardType,
                valueLb: valueLb,
                valueUb: valueUb,
                units: values['units'],
                minDataPoints: minDataPoints,
                metaDataRegex: values['metaDataRegex'],
                downsample: values['downsample'],
                percentChange: values['percentChange']/100,
                deadband: values['deadband'],
                minDataFreq: values['minDataFreq'],
                maxDataFreq: values['maxDataFreq'],


            };
            
            createMutation( { variables: { input: input  } } );

        // editing
        } else {
    
            let input = {
                id: modalId,
                name : values['name'], 
                enabled: values['enabled'],
                description: values['description'],
                required: values['required'],
                type: dataStandardType,
                valueLb: valueLb,
                valueUb: valueUb,
                units: values['units'],
                minDataPoints: minDataPoints,
                metaDataRegex: values['metaDataRegex'],
                downsample: values['downsample'],
                percentChange: values['percentChange']/100,
                deadband: values['deadband'],
                minDataFreq: values['minDataFreq'],
                maxDataFreq: values['maxDataFreq'],

            };
    
            updateMutation( { variables: { input: input  } } );
        }
        
        //setModalVisible(false);
    };
    
    const handleModalCancel = (values: any) => {
        setModalVisible(false);
    };
    
    const handleEdit = (input:any) => {

        setModalTitel("Edit")

        // allow editing of organization entries only for admins
        if (input.level == "Organization") {
            if (queryData.userProfile.admin) {
                setIsEditEnabled(true)
            } else {
                setIsEditEnabled(false)
            }
        } else if (input.level == "Team") {
            // if the user is not the owner of the team, disable editing
            //setIsEditEnabled(props.editable)

            // owner of the team and admins can edit team entries
            /*if (queryData.userProfile.admin || queryData.userProfile.user.id == input.team.owner.id) {
                setIsEditEnabled(true)
            } else {
                setIsEditEnabled(false)
            }*/
        }
        
        setModalAdd(false)
        setModalInitialValues({ 
            name:input.name,
            description:input.description, 
            required:input.required,
            type:input.type,
            units:input.units,
            valueLb: input.valueLb,
            valueUb: input.valueUb,
            minDataPoints: input.minDataPoints,
            metaDataRegex: input.metaDataRegex,
            downsample: input.downsample,
            percentChange: input.percentChange*100,
            deadband: input.deadband,
            minDataFreq: input.minDataFreq,
            maxDataFreq: input.maxDataFreq,
            enabled: input.enabled,
        })
        
        setModalId(input.id)
        setDataStandardType(input.type)
        setModalVisible(true)
    } 

    const onChangeDataStandardType = (value:any) => {
        setDataStandardType(value);
    }

    const formLayout = {
        labelCol: { span: 6 },
    };

    // return a list of unique units
    const getUnitFilters = () => {
        let uniqueUnits:any = []
        let unitFilters:any = []
        queryData?.dataStandards.forEach((dataStandard:any) => {
            if (dataStandard.units != null) {
                if (!uniqueUnits.includes(dataStandard.units)) {
                    uniqueUnits.push(dataStandard.units)
                    unitFilters.push( {text: dataStandard.units, value: dataStandard.units} )
                }
            }
        })
        return unitFilters.sort((a:any, b:any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()))
    }


    const handleSearch = (selectedKeys:any, confirm:any) => {
        confirm();
        setNameSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters:any) => {
        clearFilters();
        setNameSearchText('');
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    function handleClickDelete() {
        // open the confirm delete modal
        setOpenConfirmDeleteModal(true)
    }

    function handleConfirmDeleteModalOk() {
        // perform the mutation to delete
        deleteMutation( { variables: { ids: selectedRowKeys  } } );
        // close the confirm delete modal
        setOpenConfirmDeleteModal(false)
        // clear the selected row keys
        setSelectedRowKeys([])
    }


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            //width: '20%',
            /*render: (text: any, record: any) => (
                <a onClick={() => handleEdit(record)}>{record.name}</a>
            ),*/
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
                
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search name"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                        data-testid="lexicon-name-search-input"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm)}
                            size="small"
                            style={{ width: 90 }}
                            data-testid="lexicon-name-search-button"
                        >
                            Ok
                        </Button>
                        <Button 
                            onClick={() => clearFilters && handleReset(clearFilters)} 
                            size="small" style={{ width: 90 }}
                            data-testid="lexicon-name-reset-button"
                        >
                            Reset
                        </Button>
                    </Space>
                </div>

            ),
    
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            title: 'Units',
            dataIndex: 'units',
            editable: false,
            //width: '10%',
            //filterSearch: true,
            filters: getUnitFilters(),
            onFilter: (value: any, record: any) =>  record.units == value,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            editable: false,
            render: (text:any, record:any) => (
                <span>
                    {record.enabled ? "Yes" : "No"}
                </span>
            ),
            //filterSearch: true,
            filters: [
                {
                    text: 'Yes',
                    value: true,
                },
                {
                    text: 'No',
                    value: false,
                }],

            onFilter: (value: any, record: any) =>  record.enabled == value,

        },
        {
            title: 'Type',
            dataIndex: 'type',
            editable: false,
            filters: [
                {
                    text: 'variable',
                    value: 'variable',
                },
                {
                    text: 'parameter',
                    value: 'parameter',
                },
                {
                    text: 'metadata',
                    value: 'metadata',
                }],
        

            onFilter: (value: any, record: any) =>  record.type == value,

        },
        {
            title: 'Required',
            editable: false,
            render: (text:any, record:any) => (
                <span>
                    {record.required ? "Yes" : "No"}
                </span>
            ),
            //filterSearch: true,
            filters: [
                {
                    text: 'Yes',
                    value: true,
                },
                {
                    text: 'No',
                    value: false,
                }],
        

            onFilter: (value: any, record: any) =>  record.required == value,
        },
        
        /*{
            title: 'Actions',
            key: 'actions',
            width: '5%',

            render: (text:String, record:any) => (
                
                    <Popover content={(
                        <Space direction="vertical">
                        <Button type="link" onClick={()=> handleEdit(record)} data-testid="edit-lexicon">
                            Edit
                        </Button>
                        <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                            <Button type="link" data-testid="delete-lexicon">Delete</Button>
                        </Popconfirm>
                    </Space>
                    )}>
                    <Button size="large" type="link" style={{paddingLeft:20 }} data-testid={"lexicon-actions-button-" + record.name}>
                        <EllipsisOutlined />
                    </Button>
                </Popover>

                
            ),
        },*/
    ]


    const notEditableTooltipMessage = "You do not have permission to edit the lexicon. Only team owner or admins can edit the lexicon."

    let tabItems = [
        {
            tab: "Lexicon entry",
            key: "lexicons",
            dataTestId: "lexicon-entry-tab",
            content: (
                <div>
                    <Form
                        {...formLayout}  
                        id = "LexiconForm"
                        onFinish={handleModalOk} 
                        initialValues={ modalInitialValues }
                    > 
                        <Collapse defaultActiveKey={"1"} accordion >

                        <Collapse.Panel header="General" key="1" data-testid="general-panel" >
                            {/* name */}
                            <div>
                                <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                    <Form.Item 
                                        label="Name:"
                                        name="name" 
                                        rules={[
                                            { required: true, message: 'Please enter a name.' }, 
                                            { max: 200, message: 'Name must be 200 characters or less.' },
                                            { pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/, message: 'Name must start with a letter or underscore and can only contain letters, numbers, and underscores.' }
                                        ]} 
                                        validateFirst={true}
                                        validateTrigger={"onBlur"}
                                        //tooltip="Name of the lexicon entry."
                                        
                                    >
                                        <Input 
                                            maxLength={200}
                                            //showCount
                                            data-testid = "lexicon-name-input"
                                            disabled={isEditEnabled ? false : true}
                                            title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            
                                        />

                                    </Form.Item>
                                </div>
                                <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                    <Tooltip title="Name of the lexicon entry." >
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </div>
                            </div>
                            
                            <div style={{clear:"both"}}></div>

                            {/* enabled */}

                            <div>
                                <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                    <Form.Item label="Enabled:" name="enabled" rules={[{ required: true, message: 'Please select a value.' }]} >
                                        <Select
                                            placeholder="Select a value"
                                            allowClear
                                            data-testid = "lexicon-enabled-input"
                                            disabled={isEditEnabled ? false : true}
                                            title={isEditEnabled ? "" : notEditableTooltipMessage}
                                        >
                                            <Option value={true}>Yes</Option>
                                            <Option value={false}>No</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                    <Tooltip title="If enabled is set to yes, the lexicon entry will be used in the system.">
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </div>
                            </div>

                            {/* type */}
                            <div>

                                <div style={{float:"left", width:"calc(100% - 30px)"}}>

                                    {/* select type with choices: variable, parameter, metadata */}
                                    <Form.Item label="Type:" name="type" rules={[{ required: true, message: 'Please select a type.' }]} >
                                        <Select
                                            placeholder="Select a type"
                                            allowClear
                                            data-testid = "lexicon-type-input"
                                            onChange={onChangeDataStandardType}
                                            disabled={isModalAdd ? false : true}
                                            title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            
                                        >
                                            <Option value="variable">Variable</Option>
                                            <Option value="parameter">Parameter</Option>
                                            <Option value="metadata">Metadata</Option>
                                        </Select>
                                        
                                    </Form.Item>
                                </div>
                                <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                    <Tooltip title="Type of the lexicon entry.">
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </div>
                            </div>

                            <div style={{clear:"both"}}></div>

                            {/* units */}
                            { dataStandardType == "variable" || dataStandardType == "parameter"  ?
                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                        
                                        <Form.Item label="Units:" name="units" rules={[{ required: true, message: 'Please enter a unit. Examples: dimensionless, percent, kg,...' }]} >
                                            <Input
                                                maxLength={30}
                                                showCount
                                                data-testid = "lexicon-units-input"
                                                disabled={isEditEnabled ? false : true}
                                                title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            />

                                        </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="Units of the lexicon entry. Examples: dimensionless, percent, kg,... Please refer to the documentation for a list of supported units.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>
                            : null }

                            <div style={{clear:"both"}}></div>

                            {/* description */}
                            <div>
                                <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                    <Form.Item label="Description:" name="description" >
                                        <Input.TextArea
                                            maxLength={1000}
                                            showCount
                                            data-testid = "lexicon-description-input"
                                            disabled={isEditEnabled ? false : true}
                                            title={isEditEnabled ? "" : notEditableTooltipMessage}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                    <Tooltip title="Description of the lexicon entry.">
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </div>
                            </div>

                            <div style={{clear:"both"}}></div>

                        </Collapse.Panel>

                        <Collapse.Panel header="Data Quality Score" key="2" data-testid="data-quality-score-panel" >

                            {/* required */}
                            <div>
                                <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                    <Form.Item label="Required:" name="required" rules={[{ required: true, message: 'Please select a value.' }]} >
                                        <Select
                                            placeholder="Select a value"
                                            allowClear
                                            data-testid = "lexicon-required-input"
                                            disabled={isEditEnabled ? false : true}
                                            title={isEditEnabled ? "" : notEditableTooltipMessage}
                                        >
                                            <Option value={true}>Yes</Option>
                                            <Option value={false}>No</Option>
                                        </Select>

                                    </Form.Item>
                                </div>
                                <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                    <Tooltip title="If required is set to yes, the lexicon entry will be used for the calculation of data quality scores of processes.">
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </div>
                            </div>

                            <div style={{clear:"both"}}></div>

                            {/* if type is variable or parameter, show value_lb float input*/}

                            { dataStandardType == "variable" || dataStandardType == "parameter" ?
                                <div>
                                    <div>
                                        <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                            <Form.Item label="Min:" name="valueLb" rules={[{ type: 'number', message: 'The input is not a valid number!' }]} >
                                                <InputNumber
                                                    style={{width:"100%"}}
                                                    data-testid = "lexicon-value-lb-input"
                                                    disabled={isEditEnabled ? false : true}
                                                    title={isEditEnabled ? "" : notEditableTooltipMessage}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                            <Tooltip title="Minimum accepted value for a variable or paremeter to satisfy data quality condition.">
                                                <InfoCircleOutlined  />
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div style={{clear:"both"}}></div>
                                    
                                    <div>
                                        <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                            <Form.Item label="Max:" name="valueUb" rules={[{ type: 'number', message: 'The input is not a valid number!' }]} >
                                                <InputNumber
                                                    style={{width:"100%"}}
                                                    data-testid = "lexicon-value-ub-input"
                                                    disabled={isEditEnabled ? false : true}
                                                    title={isEditEnabled ? "" : notEditableTooltipMessage}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                            <Tooltip title="Maximum accepted value for a variable or paremeter to satisfy data quality condition.">
                                                <InfoCircleOutlined  />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            : null }

                            <div style={{clear:"both"}}></div>

                            { dataStandardType == "variable" ?
                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                        <Form.Item label="Min data-points:" name="minDataPoints" rules={[{ type: 'number', message: 'The input is not a valid number!' }]} >
                                            <InputNumber
                                                style={{width:"100%"}}
                                                data-testid = "lexicon-min-data-points-input"
                                                disabled={isEditEnabled ? false : true}
                                                title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="Minimum number of data points that a variable should have to satisfy the data quality condition.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>
                            : null }

                            <div style={{clear:"both"}}></div>

                                { dataStandardType == "metadata" ?
                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                    <Form.Item label="Regex:" name="metaDataRegex" >
                                        <Input
                                            maxLength={30}
                                            showCount
                                            data-testid = "lexicon-regex-input"
                                            disabled={isEditEnabled ? false : true}
                                            title={isEditEnabled ? "" : notEditableTooltipMessage}
                                        />
                                    </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="Regular expression to validate the value of metadata.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>
                            : null }

                            <div style={{clear:"both"}}></div>

                        </Collapse.Panel>

                        { dataStandardType == "variable" && 

                            <Collapse.Panel header="Downsampling" key="3" data-testid="downsampling-panel" >
                                
                                { !isModalAdd &&
                                    <Alert message="Changing downsampling settings does not affect already imported variables." type="info" showIcon style={{marginBottom:20, marginLeft:40}}/>
                                }

                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                        <Form.Item label="Downsample:" name="downsample" >
                                            <Select
                                                placeholder="Select a value"
                                                allowClear
                                                data-testid = "lexicon-downsample-input"
                                                disabled={isEditEnabled ? false : true}
                                                title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            >
                                                <Option value={true}>Yes</Option>
                                                <Option value={false}>No</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="If downsample is set to yes, the data will be downsampled at the time of entry into the system.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>

                                <div style={{clear:"both"}}></div>

                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                        <Form.Item label="Percent change:" name="percentChange" >
                                            <InputNumber
                                                style={{width:"100%"}}
                                                data-testid = "lexicon-percent-change-input"
                                                disabled={isEditEnabled ? false : true}
                                                title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="Percent change in the value of the variable to trigger the recording of a new data point.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>

                                <div style={{clear:"both"}}></div>

                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                        <Form.Item label="Deadband:" name="deadband" >
                                            <InputNumber
                                                style={{width:"100%"}}
                                                data-testid = "lexicon-deadband-input"
                                                disabled={isEditEnabled ? false : true}
                                                title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="Deadband to prevent recording of new data points when the absolute change in the value of the variable is less than the deadband.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>

                                <div style={{clear:"both"}}></div>

                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                        <Form.Item label="Min data frequency:" name="minDataFreq" >
                                            <InputNumber
                                                style={{width:"100%"}}
                                                data-testid = "lexicon-min-data-freq-input"
                                                disabled={isEditEnabled ? false : true}
                                                title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="Minimum data frequency in data points per hour. Downsampling will not be performed if the data frequency is less than this value.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>
                                

                                <div style={{clear:"both"}}></div>

                                <div>
                                    <div style={{float:"left", width:"calc(100% - 30px)"}}>
                                        <Form.Item label="Max data frequency:" name="maxDataFreq" >
                                            <InputNumber
                                                style={{width:"100%"}}
                                                data-testid = "lexicon-max-data-freq-input"
                                                disabled={isEditEnabled ? false : true}
                                                title={isEditEnabled ? "" : notEditableTooltipMessage}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{  float:"right", font:"20px", marginTop:"7px"}} >
                                        <Tooltip title="Maximum data frequency in data points per hour. Downsampling will be performed if the data frequency is greater than this value.">
                                            <InfoCircleOutlined  />
                                        </Tooltip>
                                    </div>
                                </div>

                                <div style={{clear:"both"}}></div>

                            </Collapse.Panel>

                        }
                        </Collapse>

                    </Form>
                </div>
            ),
        },
        {
            tab: "Other names",
            key: "other-names",
            dataTestId: "alternative-names-tab",
            content: (
                <div>
                    <DataStandardOtherName dataStandardId={modalId} editable={isEditEnabled} />
                </div>
            ),
        },
        {
            tab: "Linked",
            key: "linked",
            dataTestId: "linked-tab",
            content: (
                <div>
                    <DataStandardAssociated dataStandardId={modalId} />
                </div>
            ),
        },
    ]

    let panelHeader = "Collection lexicon"
    if (currentLevel == "Team") {
        panelHeader = "Team lexicon"
    } else if (currentLevel == "Organization") {
        panelHeader = "Organization lexicon"
    }

    let tableHeight = height - 520
    
    if (currentLevel=="Topic") {
        tableHeight = height - 515
    } else if (currentLevel=="Team") {
        tableHeight = height - 380
    } else if (currentLevel=="Organization") {
        tableHeight = height - 320
    }


    if (queryLoading && !queryData){
        return (
            <Skeleton active/>
        )
    } else {
        return (

            <div>
                { (currentLevel == "Organization") &&
                    <div>
                        <Space direction="horizontal" style={{ width: '100%' }}>
                            <Button key={1}
                                style = {{ marginBottom: 16 }}
                                onClick = {  showModal  } 
                                data-testid = "add-lexicon-button"
                                icon={<PlusOutlined />}
                                shape='round'
                                type="primary"
                                disabled = { !isEditEnabled }
                                title = { isEditEnabled ? "" : notEditableTooltipMessage }
                            >
                                Create a lexicon entry
                            </Button>
                            
                            <Button key={2}
                                style = {{ marginBottom: 16 }}
                                onClick = {  showCopyModal  } 
                                data-testid = "copy-lexicon-button"
                                disabled = { !isEditEnabled }  
                                title = { isEditEnabled ? "" : notEditableTooltipMessage }                
                            >
                                Import
                            </Button>

                            <Button key={3}
                                style = {{ marginBottom: 16 }}
                                onClick = {  handleClickExport  }
                                data-testid = "export-lexicon-button"
                                title="Export the selected lexicon entries to a yaml file."
                            >
                                Export
                            </Button>
                        

                            <Button key={4}
                                style = {{ marginBottom: 16 }}
                                onClick = {  handleClickDelete  }
                                disabled = { !hasSelected || !isEditEnabled }
                                title = { isEditEnabled ? "" : notEditableTooltipMessage }
                                data-testid = "delete-lexicon-button"
                            >
                                Delete
                            </Button>

                            {/*<Search placeholder="input search text" allowClear style={{ width: 200 }} />'*/}

                        </Space>
                        
                        <Table
                            loading={queryLoading  && !queryData}
                            dataSource={queryData?.dataStandards?.filter((dataStandard:any) => dataStandard.level == currentLevel) }
                            columns = { columns } 
                            bordered
                            rowKey={record => record.id} 
                            rowSelection={rowSelection}
                            size="small"
                            pagination={false}
                            scroll={{ y: tableHeight }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {handleEdit(record)}, // click row
                                    // change the mouse cursor to pointer when hovering over the row
                                    onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                                    onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                                };
                            }}
                        />
                    </div>
                }

                { (currentLevel == "Team" || currentLevel == "Topic") &&
                    <Collapse defaultActiveKey={["1"]} size="small" accordion destroyInactivePanel={true} >

                        <Panel header={panelHeader} key="1">
                            <Space direction="horizontal" style={{ width: '100%' }}>
                                <Button key={1}
                                    style = {{ marginBottom: 16 }}
                                    onClick = {  showModal  } 
                                    data-testid = "add-lexicon-button"
                                    icon={<PlusOutlined />}
                                    shape='round'
                                    type="primary"
                                    disabled = { !isEditEnabled }
                                    title = { isEditEnabled ? "" : notEditableTooltipMessage }
                                >
                                    Create a lexicon entry
                                </Button>
                                
                                <Button key={2}
                                    style = {{ marginBottom: 16 }}
                                    onClick = {  showCopyModal  } 
                                    data-testid = "copy-lexicon-button"
                                    disabled = { !isEditEnabled }  
                                    title = { isEditEnabled ? "" : notEditableTooltipMessage }                
                                >
                                    Import
                                </Button>

                                <Button key={3}
                                    style = {{ marginBottom: 16 }}
                                    onClick = {  handleClickExport  }
                                    data-testid = "export-lexicon-button"
                                    title="Export the selected lexicon entries to a yaml file."
                                >
                                    Export
                                </Button>
                            

                                <Button key={3}
                                    style = {{ marginBottom: 16 }}
                                    onClick = {  handleClickDelete  }
                                    disabled = { !hasSelected || !isEditEnabled }
                                    title = { isEditEnabled ? "" : notEditableTooltipMessage }
                                    data-testid = "delete-lexicon-button"
                                >
                                    Delete
                                </Button>

                                {/*<Search placeholder="input search text" allowClear style={{ width: 200 }} />'*/}

                            </Space>
                            
                            <Table
                                loading={queryLoading  && !queryData}
                                dataSource={queryData?.dataStandards?.filter((dataStandard:any) => dataStandard.level == currentLevel) }
                                columns = { columns } 
                                bordered
                                rowKey={record => record.id} 
                                rowSelection={rowSelection}
                                size="small"
                                pagination={false}
                                scroll={{ y: tableHeight }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {handleEdit(record)}, // click row
                                        // change the mouse cursor to pointer when hovering over the row
                                        onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                                        onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                                    };
                                }}
                            />
                        </Panel>

                        { (currentLevel == "Topic") &&
                    
                            <Panel header="Team lexicon" key="2">
                                <Table
                                    loading={queryLoading  && !queryData}
                                    dataSource={queryData.dataStandards.filter((dataStandard:any) => dataStandard.level == "Team") }
                                    columns = { columns }
                                    bordered
                                    rowKey={record => record.id}
                                    size="small"
                                    pagination={false}
                                    scroll={{ y: tableHeight }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {handleEdit(record)}, // click row
                                            // change the mouse cursor to pointer when hovering over the row
                                            onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                                            onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                                        };
                                    }}
                                />
                            </Panel>
                        }

                        { (currentLevel == "Team" || currentLevel == "Topic") &&
                        
                            <Panel header="Organization lexicon" key="3">
                                <Table
                                    loading={queryLoading  && !queryData}
                                    dataSource={queryData?.dataStandards?.filter((dataStandard:any) => dataStandard.level == "Organization") }
                                    columns = { columns }
                                    bordered
                                    rowKey={record => record.id}
                                    size="small"
                                    pagination={false}
                                    scroll={{ y: tableHeight }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {handleEdit(record)}, // click row
                                            // change the mouse cursor to pointer when hovering over the row
                                            onMouseEnter: event => {event.currentTarget.style.cursor = "pointer"}, // mouse enter row
                                            onMouseLeave: event => {event.currentTarget.style.cursor = "default"}, // mouse leave row
                                        };
                                    }}
                                />
                            </Panel>
                        }

                    </Collapse>
                }

                            
                <Modal 
                    open={ isModalVisible } 
                    onOk = { handleModalOk }
                    onCancel = { handleModalCancel }
                    destroyOnClose = {true}
                    data-testid = "lexicon-modal"
                    width = {800}
                    
                    footer={[
                        <Button key="back" onClick={handleModalCancel} data-testid="cancel-lexicon-button">
                            Close
                        </Button>,
                        <Button type="primary" form="LexiconForm" key="submit" htmlType="submit" data-testid="submit-lexicon-button" disabled={!isEditEnabled}>
                            Apply
                        </Button>
                        ]}
                >

                    <Tabs>
                  
                            <TabPane tab= { tabItems[0].tab } key={ tabItems[0].key }>
                                {tabItems[0].content}
                            </TabPane>
                            
                            <TabPane tab= { tabItems[1].tab } key={ tabItems[1].key } disabled={isModalAdd}>
                                {tabItems[1].content}
                            </TabPane>
                            <TabPane tab= { tabItems[2].tab } key={ tabItems[2].key } disabled={isModalAdd}>
                                {tabItems[2].content}
                            </TabPane>
  
                    </Tabs>
                    
                    <Modal title={loadingTitle} open={showLoadingModal || mutationLoading2 || mutationLoading1 || mutationLoading3} footer={null} closable={false} >
                        <Skeleton active/>
                    </Modal>
                    
                    
                </Modal>
                <Modal
                
                    open={openConfirmDeleteModal}
                    onOk={handleConfirmDeleteModalOk}
                    onCancel={ () => setOpenConfirmDeleteModal(false) }
                    okText="Yes, delete!"
                    cancelText="No, get me out of here!"
                    okButtonProps={{ danger: true }}
                    width={"40%"}
                    
                >
                <Alert
                    message={"Are you sure you want to delete " + selectedRowKeys.length + " entries?"}
                    description={(
                        <div>
                            <br/>
                            <p>This action cannot be undone. </p>
                            <p>Entities associated with the lexcion entry (e.g. process variables, parameters, metadata) will not be deleted.</p>
                        </div>
                        )}
                    type="warning"
                    showIcon
                    style={{marginBottom:20}}
                />
               

                </Modal>

                
                    <CopyDataStandards 
                        visible={ isCopyModalVisible }
                        setVisible = { setCopyModalVisible }
                        targetTopicId = { props.topicId}
                        targetTeamId = { props.teamId}
                        isTargetOrganization = { props.organization}
                    />

               
                

            </div>

        );
    }

}

export default DataStandardTable;

