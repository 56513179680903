import {useState, useEffect} from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Select, Button, Form, Skeleton, Modal, message, Radio, Upload} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import SelectCollection from '../SelectCollection';
//import { pollInterval } from '../..';

const {Option} = Select;

const COPY_CALCULATOR_SET = gql`
    mutation copyCalculatorSet($input: CopyCalculatorSetInputType!) {
        copyCalculatorSet( input:$input) {
            calculatorSet {
                id
                name
            }
        }
    }
`;

// gets also all of the topics
const ALL_CALCULATOR_SETS = gql`
    query allTopicsCalculatorSets  {
        topics {
            id
            name
            calculatorsetSet {
                id
                name
            }
        }
    }
`

interface ImportCalculatorSetModalInputType {
    topicId:String,
    isImportModalVisible:boolean,
    setImportModalVisible:Function
};

// importSource can be either "collection" or "yaml"
type ImportSource = "collection" | "yaml";

function ImportCalculatorSet(props:ImportCalculatorSetModalInputType) {

    const [selectedTopicForImport, setSelectedTopicForImport] = useState<any>("");
    const [selectedCalculatorSet, setSelectedCalculatorSet] = useState<any>("");
    const [selectCalcultorSetOptions, setSelectCalcultorSetOptions] = useState<any>([]);
    const [importSource, setImportSource] = useState<ImportSource>("collection");
    const [yamlFile, setYamlFile] = useState<any>();

    const { loading:queryLoading, error: queryError, data: allCalculatorSets } = useQuery( ALL_CALCULATOR_SETS , { 
        fetchPolicy: 'cache-and-network',
    } );

    const [copyMutation] = useMutation(COPY_CALCULATOR_SET, {
        onCompleted(data) { message.success("Calculator set was imported successfully.") },
        onError(error) {message.error(error.message)},
        refetchQueries: [ { query: ALL_CALCULATOR_SETS } ],
    });

    // use effect to reset selectedCalculatorSet when selectedTopicForImport changes
    useEffect(() => {
        setSelectedCalculatorSet(null);
    }, [selectedTopicForImport]);

    // use effect to create the options for the select based on the selected topic
    useEffect(() => {

        let selectCalcultorSetOptionsLocal: any = [];

        if (allCalculatorSets) {

            // get the calculator sets for the selected topic
            let calculatorSets: any = [];
            for (let i = 0; i < allCalculatorSets.topics.length; i++) {
                if (allCalculatorSets.topics[i].id == selectedTopicForImport) {
                    calculatorSets = allCalculatorSets.topics[i].calculatorsetSet;
                }
            }

            for (let i = 0; i < calculatorSets.length; i++) {
                selectCalcultorSetOptionsLocal.push({ label: calculatorSets[i].name, value: calculatorSets[i].id });
            }
            setSelectCalcultorSetOptions(selectCalcultorSetOptionsLocal);
            
        }
    }, [selectedTopicForImport, allCalculatorSets]);

    const formLayout = {
        labelCol: { span: 6 },
    };

    const handleImportModalOk = (values:any) => {   
        props.setImportModalVisible(false)

        let input:any = {
            //id: selectedCalculatorSet,
            targetId: props.topicId
        };

        if (importSource == "collection") {

            input.id = selectedCalculatorSet;
            copyMutation({ variables: { input: input  } } )

        } else if (importSource == "yaml") {

            input.yamlFile = yamlFile;
            copyMutation({ variables: { input: input  } } )

        } else {
            message.error("Invalid import source");
        }

    }
    const handleImportModalCancel = (values:any) => {
        props.setImportModalVisible(false)
        //setImportSource("collection");
    }

    function handleImportSourceChange(e:any) {
        setImportSource(e.target.value);
    }

    function onSelectYamlFile(value: any) {
        setYamlFile(value.file);
    }

    if (queryLoading && !allCalculatorSets) return <Skeleton active />;

    return (
        <Modal 
        title= "Import Calculator Set"
        open={ props.isImportModalVisible } 
        onOk = { handleImportModalOk }
        onCancel = { handleImportModalCancel }
        destroyOnClose = {true}
        
        footer={[
            <Button type="primary" form="CalculatorSetImport" key="submit" htmlType="submit" data-testid="submit-button">
                Submit
            </Button>
            ]}
    >
        <br/>
        <Form
            {...formLayout}  
            id = "CalculatorSetImport"
            onFinish={handleImportModalOk} 
        > 

            <Form.Item label="Source:" name="source-0" rules={[{ required: false}]} >
                <Radio.Group onChange={handleImportSourceChange} data-testid="sourceRadioGroup" value={importSource} defaultValue={"collection"}>
                    <Radio value="collection" data-testid="collectionRadio">Collection</Radio>
                    <Radio value="yaml" data-testId="yamlRadio">YAML file</Radio>
                </Radio.Group>
            </Form.Item>

            {importSource == "collection" &&
                <div>
                    <Form.Item label="Collection:" name="collection" >
                        
                        <SelectCollection setSelectedCollection={setSelectedTopicForImport} />

                    </Form.Item>

                    <Form.Item label="Calculator Set:" name="calculatorSet" rules={[{ required: true}]} >
                        <Select 
                            showSearch 
                            placeholder="Select a calculator set"
                            optionFilterProp="children"
                            onChange={(value: any) => setSelectedCalculatorSet(value)}
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={selectedCalculatorSet}
                            options = {selectCalcultorSetOptions}   
                            data-testid="calculator-set-select"

                        />
                        
                    </Form.Item>
                </div>
            }

            {importSource == "yaml" &&

                <Form.Item label="YAML file:" required>
                        <Upload 
                            type="select" 
                            accept='.yaml' 
                            maxCount={1} 
                            customRequest={onSelectYamlFile}
                            showUploadList={{
                                showDownloadIcon: false,
                                showRemoveIcon: true,
                                showPreviewIcon: false, 
                                }}

                            // in beforeUpload set the status to done 
                            beforeUpload={(file) => {
                                setYamlFile(file);
                                return false;
                            }}
                            data-testid="select-file-upload"
                        >
                            <Button icon={<UploadOutlined />} data-testid="select-file-button">Select a .yaml file</Button>
                        </Upload>
                    </Form.Item>

            }
        </Form>


    </Modal>
    )
}

export default ImportCalculatorSet;