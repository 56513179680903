import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import {  Form, Input, Card, Button, Tabs, message, ConfigProvider, theme} from 'antd';
import { useNavigate } from 'react-router-dom';
import client from '../Apollo'
import logo from '../images/bpi_logo.png'
import ReactGA from 'react-ga';

const { defaultAlgorithm, darkAlgorithm } = theme;
const { TabPane } = Tabs;

const LOGIN = gql`
    mutation ($username: String!, $password: String!) {
        tokenAuth(username:$username, password:$password) {
            token
            payload
        }
    }
`;

function Login() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
    //ReactGA.pageview('/login/');
    
    const [username, setUsername] = useState(sessionStorage.getItem('username'));
    const navigate = useNavigate()
    
    const [login, { loading, error }] = useMutation(LOGIN,
        {
          onCompleted(data) {
            if (data) {
              sessionStorage.setItem('token', 'JWT ' + data.tokenAuth.token as string);
              sessionStorage.setItem('username', data.tokenAuth.payload.username as string);
              setUsername(sessionStorage.getItem('username'));
              ReactGA.event({ category: 'Login', action: 'Success' });
              navigate('/collections');
              //window.location.reload();
              
            }
          },
          onError(error) {
              message.error(error.message)
              ReactGA.event({ category: 'Login', action: 'Failed' });
            },
        });

      

    const formLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14},
    };
    
    const tailLayout = {
        wrapperCol: { offset: 6, span: 14 },
    };

    const onLogin = (values: any) => {
        let username = values['username'];
        let password = values['password'];
        const out = login({ variables: { username, password } });
        client.resetStore()
    };
     

    return (
            <ConfigProvider theme={{ algorithm : defaultAlgorithm }}>
                <div className="login-content">

                    
                    <Card style={{width:600, margin:"0 auto"}} >
                        <img src={logo} alt="Bioprocess Intelligence"  style={{width:220, marginBottom:20, marginTop:20, marginLeft:160}} />    
                        <Tabs>
                        
                            <TabPane tab="Login" key="1" data-testid="login-tab">

                                <Card >
                                    <Form 
                                        {...formLayout} 
                                        onFinish={onLogin}
                                        
                                    >
                                        <Form.Item 
                                                label="Username:"
                                                name="username"
                                                rules={[{ required: true, message: 'Please enter your username.' }]} 
                                        >
                                            <Input data-testid="username-input"/>
                                        </Form.Item>
                                        <Form.Item 
                                                label="Password:"
                                                name="password"
                                                rules={[{ required: true, message: 'Please enter your password.' }]} 
                                        >
                                            <Input.Password data-testid="password-input"/>
                                        </Form.Item>

                                        <Form.Item {...tailLayout}>
                                            <Button type="primary" htmlType="submit" id="submit" data-testid="login-button" >Login</Button>
                                        </Form.Item>

                                    </Form>
                                </Card>
                                    
                            </TabPane>
                            {/*<TabPane tab="Register" key="2">
                                <Card>Coming soon</Card>

                            </TabPane>
                            */}
                            
                        </Tabs>

                    </Card>

                </div>
            </ConfigProvider>

              
        
        
    )

}

export default Login
