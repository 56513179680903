import { useEffect } from 'react';
import { Space, Switch, InputNumber, Skeleton} from 'antd';
import { useQuery, useMutation, gql, useReactiveVar } from '@apollo/client';
import { processExplorerSidebarVisible } from '../../Apollo';
import useWindowDimensions from "../useWindowDimensions";
import { kpiAnalysisSelectedViewPageVar } from '../../Apollo';
import { createOptimisticResponse } from '../createOptimisticResponse';
import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';

import { CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS } from '../fragments';

const UPDATE_VIEW_PAGE = gql`
    ${CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS}
    mutation updateViewPage ($input: UpdateViewPageInputType!) {
        updateViewPage( input:$input) {
            viewPage {
                ...CoreKpiAnalysisViewPageFields
            }
        }
    }
`;

export const GET_CLIENT_DATA = gql`
    query {
        processExplorerSidebarVisible @client
    }
        
`

interface KPIAnalysisOptionsInputType {
    analysis:any
}
  
function KPIAnalysisOptions(props:KPIAnalysisOptionsInputType) {

    const { height, width } = useWindowDimensions();
    const kpiAnalysisViewSelectedViewPage = useReactiveVar(kpiAnalysisSelectedViewPageVar);
    const { data: clientData } = useQuery(GET_CLIENT_DATA);
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

    // perform the mutation
    const [updateViewPage] = useMutation(UPDATE_VIEW_PAGE)

    // useEffect to set sidebar visible to false is width is less than 1200
    useEffect(() => {
        if (width < 1200) {
            processExplorerSidebarVisible(false)
        }
    }, [width])

    let selectedViewPage = props.analysis.kpiAnalysisView.viewpageSet.find((viewpage:any) => viewpage.id === kpiAnalysisViewSelectedViewPage)

    if (selectedViewPage === undefined) {
        return (<div><Skeleton active/></div>)
    }


    function onChangeNumplots(numplots:any) {
        let input = {
            id: selectedViewPage.id,
            kpiAnalysisNumplots: numplots
        }

        const optimisticResponse = createOptimisticResponse(
            client,
            CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS,
            selectedViewPage.id,
            {kpiAnalysisNumplots: numplots},
            "CoreKpiAnalysisViewPageFields"
        )

        // perform the mutation to update the value of the Y-Axis in the view
        updateViewPage( { 
            variables: { input: input  } ,
            optimisticResponse: {
                updateViewPage: {
                    __typename: 'Mutation',
                    viewPage: {
                        __typename: 'ViewPageType',
                        ...optimisticResponse
                    }
                }
            }
        } );
        
    }

    function toggleSidebarVisible() {
        processExplorerSidebarVisible(!clientData.processExplorerSidebarVisible)
    }

    return(
        <div>
            <Space direction="vertical">
                
                <Space>
                    <Switch onChange={toggleSidebarVisible} checked={ clientData.processExplorerSidebarVisible } />
                    <div>Sidebar</div>
                </Space>
                

                <Space>
                        <InputNumber size="small" value={ selectedViewPage.kpiAnalysisNumplots } onChange={onChangeNumplots} min={1} max={6} data-testid="input-number-numplots" />
                        <div>N plots</div>
                </Space>
            </Space>

        </div>
        )

}


export default KPIAnalysisOptions