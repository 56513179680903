import { useState, useEffect } from "react";
import { gql, useMutation, useLazyQuery, useQuery } from "@apollo/client";
import HeaderExtra from "../components/HeaderExtra";
import { Card, Steps, Button, message, Form, Input, Upload, Checkbox, Result, Table, Skeleton, Alert, Tooltip, Space, Statistic, Select, Progress } from 'antd';
import ImportHistoryTable from "../components/import/ImportHistoryTable";
import PageHeader from "../components/PageHeader";
import { InfoCircleOutlined, LineChartOutlined } from '@ant-design/icons';
import timezones from "../components/timezones";
import moment from 'moment-timezone';

import ambr from '../images/ambr.png'
import excel from '../images/Excel.png'
import matlab from '../images/Matlab_Logo.png'
import AddProcessestoAnalysis from "../components/import/AddProcessesToAnalysis";

//import bioreactor from '../images/bioreactor.png'
import ImportTopic from "../components/import/ImportTopic";
import ImportProcess from "../components/import/ImportProcess";
import { UploadOutlined } from '@ant-design/icons';
import useWindowDimensions from "../components/useWindowDimensions";
import { pollInterval } from "..";
import { GET_IMPORTS } from "../components/import/ImportHistoryTable";

//import ReactGA from 'react-ga';
import { CORE_BACKGROUND_TASK_FIELDS, EXTENDED_PROCESS_FIELDS_WITH_DATA, CORE_PROCESS_FIELDS, CORE_USER_PROFILE_FIELDS } from "../components/fragments";


const { TextArea } = Input;
const { Option } = Select;
const { Step } = Steps;
const { Meta } = Card;

const IMPORT_EXCEL = gql`
  ${EXTENDED_PROCESS_FIELDS_WITH_DATA}
  mutation importExcel ($input:ImportExcelInputType!) {  
    importExcel(input:$input) {
        ok
        messages
        nOnlineVariablesImported
        nOfflineVariablesImported
        nMetadataImported
        nParametersImported
        process {
            ...ExtendedProcessFieldsWithData
        }
    }
  }
`;

const GET_BACKGROUND_TASK = gql`
    ${CORE_BACKGROUND_TASK_FIELDS}
    query backgroundTask ($id:ID!) {  
        backgroundTask (id:$id) {
            ...CoreBackgroundTaskFields
        }
    }
`;

const IMPORT_EXCEL_INFO = gql`
  mutation importExcelInfo ($input:ImportExcelInfoInputType!) {  
    importExcelInfo(input:$input) {
      names
      overwrite
    }   
  }
`;

const IMPORT_EXCELS = gql`
  mutation importExcels ($input:ImportExcelsInputType!) {  
    importExcels(input:$input) {
        ok
        backgroundTaskId
    }
  }
`;

const IMPORT_MATLAB = gql`
  mutation importMatlab ($input:ImportMatlabInputType!) {
    importMatlab(input:$input) {
        ok
    }
  }
`;

const IMPORT_AMBR = gql`
  mutation importAmbr ($input:ImportAmbrInputType!) {
    importAmbr(input:$input) {
        ok
    }
  }
`;

const GET_PROCESSES = gql`
    ${CORE_PROCESS_FIELDS}
    query processes($topicId:ID){  
        processes (topicId:$topicId) {
          processes {
            ...CoreProcessFields
          }
        }
    } 
`;

const GET_PROCESSES_IN_MAT_FILE = gql`
    query ProcessesInMatFile($file:Upload!){
        processesInMatFile(file:$file)
    }
`;

export const GET_USER_PROFILE = gql`
    ${CORE_USER_PROFILE_FIELDS}
    query userProfile {
        userProfile {
            ...CoreUserProfileFields
        }
    }
`

function Import() {

  //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
  //ReactGA.pageview('/import/');

  // window dimensions
  const { height, width } = useWindowDimensions();

  // page number of the wizard
  const [current, setCurrent] = useState(0);

  const [messages, setMessages] = useState("");
  const [nOnlineVariablesImported, setNOnlineVariablesImported] = useState(0)
  const [nOfflineVariablesImported, setNOfflineVariablesImported] = useState(0)
  const [nVariablesImported, setNVariablesImported] = useState(0)
  const [nMetadataImported, setNMetadataImported] = useState(0)
  const [nParametersImported, setNParametersImported] = useState(0)
  const [nProcessesImported, setNProcessesImported] = useState(0)
  const [nNotesImported, setNNotesImported] = useState(0)
  const [importedProcessIds, setImportedProcessIds] = useState<any>([])
  const [tabKey, setTabKey] = useState("import-tab")

  const [importMethod, setImportMethod] = useState("Excel");

  const [topic, setTopic] = useState("");
  const [topicName, setTopicName] = useState("");

  const [processId, setProcessId] = useState("");
  const [existingPrrocessName, setExistingProcessName] = useState("");

  const [isProcessNew, setIsProcessNew] = useState(1);

  const [processName, setProcessName] = useState("");
  const [processDescription, setProcessDescription] = useState("");

  const [downsample, setDownsample] = useState(true);
  const [downsamplePercentChange, setDownsamplePercentChange] = useState(1);
  const [downsampleMinDataFreq, setDownsampleMinDataFreq] = useState(1);

  const [importCalculated, setImportCalculated] = useState(false);

  const [uploadFile, setUploadFile] = useState<any>("");
  const [uploadFile2, setUploadFile2] = useState<any>(""); // for the offline data file in the Ambr import

  const [excelUploadFiles, setExcelUploadFiles] = useState<any>([]); // for excel multiple files can be uploaded

  const [timezone, setTimezone] = useState("UTC");

  const [processesInMatFile, setProcessesInMatFile] = useState<any>([]);
  const [selectedProcessesInMatFile, setSelectedProcessesInMatFile] = useState<any>([]);

  const [selectedReactors, setSelectedReactors] = useState<any>([]);
  const [namePrefix, setNamePrefix] = useState("Ambr_");
  const [importExcelInfoData, setImportExcelInfoData] = useState<any>([]);
  const [importExcelDuplicateError, setImportExcelDuplicateError] = useState(false);
  const [importExcelFileFormatError, setImportExcelFileFormatError] = useState(false);
  const [importExcelBackgroundTaskId, setImportExcelBackgroundTaskId] = useState(0);

  // query to the user profile
  const { loading: userProfileLoading, error: userProfileError, data: userProfileData } = useQuery(GET_USER_PROFILE, {
    //pollInterval: pollInterval,
    fetchPolicy: "cache-and-network"
  });

  const [importExcel, { loading: loadingExcel, error: errorExcel }] = useMutation(IMPORT_EXCEL, {
    refetchQueries: [{ query: GET_PROCESSES, variables: { topicId: topic } }],
    onCompleted(data) {
      setCurrent(current + 1)
    },
    onError(error) { message.error(error.message) },
  });

  const [importExcels, { loading: loadingExcels, error: errorExcels }] = useMutation(IMPORT_EXCELS, {
    //refetchQueries: [ { query:  GET_PROCESSES, variables: { topicId:topic }}  ],
    refetchQueries: [{ query: GET_IMPORTS, variables: { teamId: userProfileData?.userProfile?.selectedTeam?.id } }],
    onCompleted(data) {
      setCurrent(current + 1)
      setImportExcelBackgroundTaskId(data.importExcels.backgroundTaskId)
    },
    onError(error) { message.error(error.message) },
  });

  const [importExcelInfo, { loading: loadingExcelInfo, error: errorExcelInfo }] = useMutation(IMPORT_EXCEL_INFO, {
    //refetchQueries: [ { query:  GET_PROCESSES, variables: { topicId:topic }}  ],
    onCompleted(data) {
      setImportExcelInfoData(data.importExcelInfo)
      setCurrent(current + 1)
    },
    onError(error) { message.error(error.message) },
  });

  const [importMatlab, { loading: loadingMatlab, error: errorMatlab }] = useMutation(IMPORT_MATLAB, {
    //refetchQueries: [ { query:  GET_PROCESSES, variables: { topicId:topic }}  ],
    refetchQueries: [{ query: GET_IMPORTS, variables: { teamId: userProfileData?.userProfile?.selectedTeam?.id } }],
    onCompleted(data) {
      setCurrent(current + 1)
    },
    onError(error) { message.error(error.message) },
  });

  const [importAmbr, { loading: loadingAmbr, error: errorAmbr }] = useMutation(IMPORT_AMBR, {
    //refetchQueries: [ { query:  GET_PROCESSES, variables: { topicId:topic }}  ],
    refetchQueries: [{ query: GET_IMPORTS, variables: { teamId: userProfileData?.userProfile?.selectedTeam?.id } }],
    onCompleted(data) {
      setCurrent(current + 1)
    },
    onError(error) { message.error(error.message) },
  });


  // use a lazy query to get the processes in the matlab file
  const [getProcessesInMatFile, { loading: loadingGetProcessesInMatFile, error: errorGetProcessesInMatFile, data: dataGetProcessesInMatFile }] = useLazyQuery(GET_PROCESSES_IN_MAT_FILE, {
    onCompleted(data) {
      setProcessesInMatFile(data.processesInMatFile)
      setSelectedProcessesInMatFile([])
    },
    onError(error) { message.error(error.message) },
  });

  // refetch the background tasks every 5 seconds
  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_BACKGROUND_TASK, {
    variables: { id: importExcelBackgroundTaskId },
    pollInterval: 5000,
    onCompleted(data) {

      // convert outputParameters to JSON
      let outputParameters = JSON.parse(data.backgroundTask.outputParameters)

      setNProcessesImported(outputParameters.n_processes_imported.value)
      setNOnlineVariablesImported(outputParameters.n_online_variables_imported.value)
      setNOfflineVariablesImported(outputParameters.n_offline_variables_imported.value)
      setNMetadataImported(outputParameters.n_meta_data_imported.value)
      setNParametersImported(outputParameters.n_parameters_imported.value)
      setMessages(data.backgroundTask.outputMessage)
      setNNotesImported(outputParameters.n_notes_imported.value)
      setImportedProcessIds(outputParameters.imported_process_ids.value)

    }

  });

  // ###################################################################################################################################4
  // ###################################################################################################################################4
  // ###################################################################################################################################4

  // use Effect to get processes in mat file whenever the file changes and it is a .mat file
  useEffect(() => {
    if (uploadFile != "" && uploadFile?.name.endsWith(".mat")) {
      getProcessesInMatFile({ variables: { file: uploadFile } })
    }
  }, [uploadFile]);

  // useEffect to set the timzone to the user's timezone
  useEffect(() => {
    let user_tz = moment.tz.guess()
    // find the timezone in the list timezones list
    // timezones is an array of strings
    let index = timezones.indexOf(user_tz)
    if (index == -1) {
      setTimezone("UTC")
    } else {
      setTimezone(user_tz)
    }
  }, []);

  // useEffect to set the state variable importExcelDuplicateError if the importExcelInfoData?.names has duplicates
  useEffect(() => {
    if (importExcelInfoData?.names) {
      setImportExcelDuplicateError(false)
      let uniqueNames = new Set(importExcelInfoData?.names)
      if (uniqueNames.size != importExcelInfoData?.names.length) {
        setImportExcelDuplicateError(true)
      }
    }
  }, [importExcelInfoData?.names])

  // useEffect to set the state variable importExcelFileFormatError if the importExcelInfoData?.names has ""
  useEffect(() => {
    if (importExcelInfoData?.names) {
      setImportExcelFileFormatError(false)
      if (importExcelInfoData?.names.includes("")) {
        setImportExcelFileFormatError(true)
      }
    }
  }, [importExcelInfoData?.names])


  let loading = loadingExcel || loadingMatlab || loadingAmbr || (userProfileLoading && !userProfileData ) // || loadingGetProcessesInMatFile //TODO: do we need this loading for the mat file?

  const next = () => {

    if (importMethod == "Excel") {

      if (current == 1) {

        if (topic == "") {
          message.error("Please select a collection.")
          return
        }

        /*
        if (isProcessNew==1) {
          if (processName=="") {
            message.error("Please fill out process name")
            return
          }
        }
        
        if (isProcessNew==0) {
          if (processId=="") {
            message.error("Please select a process")
            return
          }
        }
        */
        setCurrent(current + 1);
      }

      if (current == 2) {
        if (excelUploadFiles.length == 0) {
          message.error("Please select one or more files.")
          return
        }

        // get the ImportExcelInfo

        let excelFileList = excelUploadFiles.map((file: any) => file.originFileObj)
        //console.log(excelFileList)

        let input = {
          files: excelFileList,
          topicId: topic,
          timezone: timezone,
        }

        importExcelInfo({ variables: { input: input } })


      }

    } else if (importMethod == "Matlab") {

      if (current == 1) {
        if (topic == "") {
          message.error("Please select a collection.")
          return
        }
      }

      if (current == 2) {
        if (uploadFile == "") {
          message.error("Please select a file.")
          return
        }
        if (selectedProcessesInMatFile.length == 0) {
          message.error("Please select at least one process to import.")
          return
        }
      }

      setCurrent(current + 1);


    } else if (importMethod == "Ambr") {
      if (current == 1) {
        if (topic == "") {
          message.error("Please select a collection.")
          return
        }
      }
      if (current == 2) {
        if (uploadFile == "") {
          message.error("Please select a file.")
          return
        }
        if (selectedReactors.length == 0) {
          message.error("Please select at least one reactor to import.")
          return
        }
      }
      setCurrent(current + 1);
    }



  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function onClickExcel() {
    
    if (userProfileData?.userProfile?.selectedTeam?.userPermissions == "read") {
      message.error("You do not have permission to import data in this team.")
      return
    }

    setImportMethod("Excel")
    setCurrent(current + 1);
  }

  function onClickMatlab() {
    
    if (userProfileData?.userProfile?.selectedTeam?.userPermissions == "read") {
      message.error("You do not have permission to import data in this team.")
      return
    }

    setImportMethod("Matlab")
    setCurrent(current + 1);
  }

  function onClickAmbr() {
    
    if (userProfileData?.userProfile?.selectedTeam?.userPermissions == "read") {
      message.error("You do not have permission to import data in this team.")
      return
    }

    setImportMethod("Ambr")
    setCurrent(current + 1);
  }

  let opacity = "100%"
  if (userProfileData?.userProfile?.selectedTeam?.userPermissions == "read") {
    opacity = "50%"
  }

  const firstPage = (
    <div>

      <Card
        hoverable={ userProfileData?.userProfile?.selectedTeam?.userPermissions != "read"  }
        onClick={onClickExcel}
        style={{ width: 200, height: 320, float: "left", marginRight: 10 , opacity:opacity }}
        cover={<img alt="" src={excel} style={{ height: 110, width: 110, margin: 45 }} />}
        data-testid="excel"
        
      >
        <Meta title="Excel" description="Import data using a generic template." />
      </Card>

      {/*
      <Card
        hoverable = {false}
        style={{ width: 200, height:320 , float:"left" , marginRight:10 , opacity:"50%" }}
        cover={<img alt="" src={csv} style={{height:90, width:90, margin:55}}/>}
      >
        <Meta title="CSV" description="Import data from CSV files." />
      </Card>
      */}

      <Card
        hoverable={userProfileData?.userProfile?.selectedTeam?.userPermissions != "read"}
        onClick={onClickAmbr}
        style={{ width: 200, height: 320, float: "left", marginRight: 10 , opacity:opacity }}
        cover={<img alt="" src={ambr} style={{ width: 80, marginLeft: 60, paddingBottom: 50, paddingTop: 60 }} />}
        data-testid="ambr"
      >
        <Meta title="Ambr 250" description="Import online data from the Ambr 250 system." />
      </Card>

      <Card
        hoverable={userProfileData?.userProfile?.selectedTeam?.userPermissions != "read"}
        onClick={onClickMatlab}
        style={{ width: 200, height: 320, float: "left", marginRight: 10 , opacity:opacity }}
        cover={<img alt="" src={matlab} style={{ width: 120, marginLeft: 40, marginTop: 40, marginBottom: 60 }} />}
        data-testid="matlab"
      >
        <Meta title="Matlab" description="Import data from Matlab .mat files." />
      </Card>

      {/*
      <Card
        hoverable = {false}
        style={{ width: 200 , height:320, float:"left" , marginRight:10 , opacity:"50%"}}
        cover={<img alt="" src={database}  style={{width:70, marginLeft:70, paddingBottom:40, paddingTop:60}} />}
      >
        <Meta title="Database" description="Import data from other database systems." />
      </Card>
      */}

      {/*
      <Card
          hoverable = {false}
          
          style={{ width: 200 , height:320, float:"left" , marginRight:10 , opacity:"30%"}}
          cover={<img alt="" src={bioreactor}  style={{width:120, marginLeft:40}} />}
      >
          <Meta title="DasGip" description="Import from DasGip bioreactor system." />

      </Card>
      */}

    </div>
  );

  const formLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 6 },
  };

  function onChangeIsProcessNew(input: any) {
    setIsProcessNew(input.target.value)
  }

  function onChangeProcessName(input: any) {
    setProcessName(input.target.value)
  }

  function onChangeProcessDescription(input: any) {
    setProcessDescription(input.target.value)
  }

  const secondPageExcel = (
    <Card>
      <Alert
        message="Lexicon"
        description="Only variables, parameters, and metadata that are defined in the lexicon will be imported. If a variable, parameter, or metadata is not defined in the lexicon, then it will be ignored."
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />

      {/*
        <Alert
          message="Generic template in Excel format"
          description={(
            <div>
              <a href="/import_template_v4.xlsx" download="import_template_v4.xlsx" data-testid="download-template"> Download </a>
            </div>
          )}
          type="info"
          showIcon
          style={{ marginBottom: 32 }}
        />
        */}

      <Form
        {...formLayout}
        labelAlign="left"
        style={{ width: "100%" }}
      >

        {/*
        <Form.Item label="Process:" required >
          <Radio.Group value={isProcessNew} onChange={onChangeIsProcessNew} data-testid="process-radio-group">
            <Radio value={1} data-testid="new-process-radio">Create a new process</Radio>
            <Radio value={0} data-testid="existing-process-radio">Import data into an existing process</Radio>
          </Radio.Group>
        </Form.Item>
        */}

        <Form.Item label="Collection:" required>
          <ImportTopic topic={topic} setTopic={setTopic} setTopicName={setTopicName} setProcess={setProcessId} teamId={userProfileData?.userProfile.selectedTeam.id} />
        </Form.Item>

        {isProcessNew == 0 && false &&
          <Form.Item label="Select a process:" required rules={[{ required: true }]}>
            <ImportProcess process={processId} setProcess={setProcessId} setProcessName={setExistingProcessName} topic={topic} />
          </Form.Item>
        }

        {isProcessNew == 1 && false &&
          <div>
            <Form.Item label="Process name:" required rules={[{ required: true }]} >
              <Input value={processName} onChange={onChangeProcessName} data-testid="process-name-input">
              </Input>
            </Form.Item>
            <Form.Item label="Description:" >
              <TextArea value={processDescription} onChange={onChangeProcessDescription} data-testid="process-description-input" />
            </Form.Item>

          </div>

        }

      </Form>
    </Card>
  )

  const secondPageMatlab = (
    <Card>

      <Alert
        message="Lexicon"
        description="Only variables, parameters, and metadata that are defined in the lexicon will be imported. If a variable, parameter, or metadata is not defined in the lexicon, then it will be ignored."
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />


      <Form
        {...formLayout}
        labelAlign="left"
      >

        <Form.Item label="Collection:" required >
          <ImportTopic topic={topic} setTopic={setTopic} setTopicName={setTopicName} setProcess={setProcessId} teamId={userProfileData?.userProfile.selectedTeam.id} />
        </Form.Item>

      </Form>
    </Card>
  )

  const secondPageAmbr = (
    <Card>

      <Alert
        message="Lexicon"
        description="Only variables, parameters, and metadata that are defined in the lexicon will be imported. If a variable, parameter, or metadata is not defined in the lexicon, then it will be ignored."
        type="info"
        showIcon
        style={{ marginBottom: 16 }}
      />


      <Form
        {...formLayout}
        labelAlign="left"
      >

        <Form.Item label="Collection:" required >
          <ImportTopic topic={topic} setTopic={setTopic} setTopicName={setTopicName} setProcess={setProcessId} teamId={userProfileData?.userProfile.selectedTeam.id} />
        </Form.Item>

      </Form>
    </Card>
  )


  // set the file as state for mutation
  function onSelectUploadFile(value: any) {
    setUploadFile(value.file)
  }

  function onSelectUploadFile2(value: any) {
    setUploadFile2(value.file)
  }


  const onSelectExcelUploadFiles = ({ file, fileList: currentFileList }: any) => {
    if (file.status === 'done') {
      // When the status of a file changes to "done", it means all files have been selected
      // Clear the fileList to reset it
      setExcelUploadFiles([]);
    } else {
      // Otherwise, just update the fileList with current selection
      setExcelUploadFiles(currentFileList);
    }
  };

  const handleBeforeExcelUpload = (file: any) => {
    // Clear the excelUploadFiles state whenever a new file is selected
    //setExcelUploadFiles([]);

    // If you're not uploading here, return false to stop the default upload process
    return false;
  };


  function onChangeDownsample(value: any) {
    setDownsample(value.target.checked)
  }

  function onChangePercentChange(value: any) {
    setDownsamplePercentChange(value)
  }

  function onChangeMinDataFreq(value: any) {
    setDownsampleMinDataFreq(value)

  }

  function onChangeTimezone(value: any) {
    setTimezone(value)
  }

  function beforeUpload(file: any, fileSizeLimitMB: number) {
    // TODO: check if the file is an xlsx file 

    // convert fileSizeLimitMB to bytes
    const fileSizeLimit = fileSizeLimitMB * 1024 * 1024;

    // if file size is larger than 10 MB, show an error message and return false
    if (file.size > fileSizeLimit) {
      message.error('File size must be smaller than ' + fileSizeLimitMB + ' MB!');
      return false;
    }
    return true;
  };

  const timeZoneSelect = (
    <div>
      <Select
        style={{ float: "left", width: 200 }}
        showSearch
        value={timezone}
        placeholder="Select a timezone"
        optionFilterProp="children"
        onChange={onChangeTimezone}
        filterOption={(input: any, option: any) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        data-testid="timezone-select"
      >

        {timezones.map(option => (
          <Option key={option} value={option} data-testid={option}>
            {option}
          </Option>
        ))}

      </Select>

      <Tooltip title="Timezone of the data.">
        <InfoCircleOutlined style={{ float: "left", font: "20px", marginTop: "7px", marginLeft: "10px" }} />
      </Tooltip>
    </div>
  )

  const thirdPageExcel = (
    <Card>
      <Form {...formLayout} labelAlign="left">
        <Form.Item label="Select one or more files:" required>

          <Upload
            type="select"
            accept='.xlsx'
            multiple={true}
            maxCount={100}
            //customRequest={onSelectExcelUploadFiles} 
            onChange={onSelectExcelUploadFiles}
            // clear the file list every time the user selects a new file
            fileList={excelUploadFiles}


            showUploadList={false}
            //beforeUpload={(file) => beforeUpload(file, 100)} 
            beforeUpload={handleBeforeExcelUpload}
            data-testid="select-file"
          >
            <Button icon={<UploadOutlined />} data-testid="select-file-button">Select .xlsx files</Button>
          </Upload> 
        </Form.Item>

        {/* 
        <Form.Item label="Selected file(s):">
          <Input value={excelUploadFiles.map((file: any) => file.name).join(", ")} />
        </Form.Item>
        */}

        <Form.Item label="Selected file(s):">
          <div>
            {excelUploadFiles.map((file: any) => (
              <div >
                <div key={file.name} data-testid={"file-" + file.name} style={{ float: "left" }}>{file.name} <Button type="link"  onClick={() => setExcelUploadFiles(excelUploadFiles.filter((f: any) => f.name != file.name))} data-testid={"remove-" + file.name}>Remove</Button></div>
                <div></div>
              </div>
            ))}
          </div>
        </Form.Item>

        <Form.Item label="Timezone of data:">
          {timeZoneSelect}
        </Form.Item>

        {/* Add a checkbox asking the user whether to downsample or not */}
        {/*
        <Form.Item label="Downsampling:">
          <Checkbox value={downsample} onChange={onChangeDownsample} defaultChecked={downsample} />
        </Form.Item>
        */}

        {/* We leave downsampling on by default */}
        {/* Ask user to enter an integer between 0 and 100 for percent change of downsampling */}
        {downsample &&

          <div>
            {/* 

            <Alert
              message="Downsampling of variables"
              description="Downsampling reduces the number of data points in time-series variables to improve software performance and user experience. Often variables are logged at data frequencies that are unnecessarily high and can negatively impact performance. For example, a temperature sensor may be logged every 10 seconds, but the temperature may not change significantly during this time. In this case, the data can be downsampled by only logging the data points where the temperature changes by a certain percentage. The minimum data frequency is the minimum number of data points that should be logged per hour. For example, if the minimum data frequency is 1, then the data should be logged at least once per hour.  The downsampling algorithm will use a combination of the percent change and minimum data frequency to determine the number of data points to keep."
              type="info"
              showIcon
              style={{ marginBottom: 16 }}
            />

            <Form.Item label="Change in data magnitude [%]:">
              <InputNumber min={0} onChange={onChangePercentChange} style={{float:"left"}} defaultValue={downsamplePercentChange}/>

              <Tooltip title="The percent change in data magnitude that should be used to determine whether to keep a data point. For example, if the percent change is 1, then the data point will be kept if the data magnitude changes by at least 1%.">
                <InfoCircleOutlined style={{ float:"left", font:"20px", marginTop:"7px", marginLeft:"10px" }} />
              </Tooltip>

            </Form.Item>

            <Form.Item label="Minimum data frequency [data points / hour]:" >
              <InputNumber min={0} onChange={onChangeMinDataFreq} style={{float:"left"}} defaultValue={downsampleMinDataFreq}/>

              <Tooltip title="The minimum number of data points that should be logged per hour. For example, if the minimum data frequency is 1, then the data should be logged at least once per hour.">
                <InfoCircleOutlined style={{ float:"left", font:"20px", marginTop:"7px", marginLeft:"10px" }} />
              </Tooltip>

            </Form.Item>
            */}
          </div>


        }

      </Form>
      <br />
    </Card>
  );

  function onChangeImportCalculated(value: any) {
    setImportCalculated(value.target.checked)
  }

  function onChangeSelectedProcessesInMatFile(value: any) {
    setSelectedProcessesInMatFile(value)
  }

  const thirdPageMatlab = (
    <Card>
      <Form {...formLayout} labelAlign="left">

        <Alert
          message="Matlab File Format"
          description="This import method accepts .mat files that are generated by BIOPRO >= v. 1.52."
          type="info"
          showIcon
          style={{ marginBottom: 32 }}
        />

        <Form.Item label="Select a file:" required>
          <Upload type="select" accept='.mat' maxCount={1} customRequest={onSelectUploadFile} showUploadList={false} beforeUpload={(file) => beforeUpload(file, 2000)} data-testid="select-file">
            <Button icon={<UploadOutlined />} data-testid="select-file-button">Select a .mat file</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Selected file:">
          <div>{uploadFile?.name} </div>
        </Form.Item>

        {/* if dataGetProcessesInMatFile is not empty, then show the processes dropdown */}


        <Form.Item label="Processes:">

          <Space direction="vertical" style={{ width: "400" }}>
            <div>
              <Select
                style={{ float: "left", width: 300, maxHeight: 200, overflow: 'auto' }}
                showSearch
                mode="multiple"
                loading={loadingGetProcessesInMatFile}
                allowClear
                value={selectedProcessesInMatFile}
                onChange={onChangeSelectedProcessesInMatFile}
                data-testid="processes-select"
              >
                {processesInMatFile.map((option: any) => (
                  <Option key={option} value={option} data-testid={option}>
                    {option}
                  </Option>
                ))}
              </Select>

              <Tooltip title="Select the processes to import.">
                <InfoCircleOutlined style={{ float: "right", font: "20px", marginTop: "4px", marginLeft: "10px" }} />
              </Tooltip>
              
            </div>
            {/* select all button */}
            <Button type="link" onClick={() => setSelectedProcessesInMatFile(processesInMatFile)} data-testid="select-all-button">Select all</Button>
          </Space>


        </Form.Item>

        <Form.Item label="Timezone of data:">
          {timeZoneSelect}
        </Form.Item>
        
        {/*
        <Form.Item label="Import calculated variables:">
          <Checkbox value={importCalculated} onChange={onChangeImportCalculated} defaultChecked={importCalculated} style={{ float: "left" }} data-testid="import-calculated-checkbox" />
          <Tooltip title="This option will allow to import calculated variables from the mat-file for e.g. archiving purposes. Note that these variables will be imported as type measured and the calculators from BIOPRO will not be imported. It is best-practice not to import the calculated variables and setup new calculations here.">
            <InfoCircleOutlined style={{  float: "left", font: "20px", marginTop: "4px", marginLeft: "10px" }} />
          </Tooltip>
        </Form.Item>
        */}
        
      </Form>
    </Card>
  )

  function onChangeSelectedReactors(value: any) {
    setSelectedReactors(value)
  }

  function onChangeNamePrefix(value: any) {
    setNamePrefix(value.target.value)
  }

  const thirdPageAmbr = (
    <Card>
      <Form {...formLayout} labelAlign="left">

        <Alert
          message="Ambr file format"
          description="This import method accepts a .zip file of the Ambr data folder."
          type="info"
          showIcon
          style={{ marginBottom: 32 }}
        />

        <Form.Item label="Ambr export file (zip):" required>
          <Upload type="select" accept='.zip' maxCount={1} customRequest={onSelectUploadFile} showUploadList={false} beforeUpload={(file) => beforeUpload(file, 2000)} data-testid="select-file">
            <Button icon={<UploadOutlined />} data-testid="select-file-button">Select file</Button>
          </Upload>
        </Form.Item>

        { uploadFile != "" &&
          <Form.Item label="Selected file:">
            <Space direction="horizontal">
              <div>{uploadFile?.name} </div>
                <Button title="Clear" type="link" onClick={() => setUploadFile("")} data-testid="clear-file-button">Clear</Button>
            </Space>
          </Form.Item>
        }

        <Form.Item label="Offline data file (Excel):">
          <Upload type="select" accept='.xlsx , .xlsm' maxCount={1} customRequest={onSelectUploadFile2} showUploadList={false} beforeUpload={(file) => beforeUpload(file, 2000)} data-testid="select-offline-file">
            <Button icon={<UploadOutlined />} data-testid="select-file-button">Select file</Button>
          </Upload>
        </Form.Item>
        
        { uploadFile2 != "" &&
          <Form.Item label="Selected file:">
            <Space direction="horizontal">
              <div>{uploadFile2?.name} </div>
                <Button title="Clear" type="link" onClick={() => setUploadFile2("")} data-testid="clear-file-button">Clear</Button>
            </Space>
          </Form.Item>
        }


        <Form.Item label="Reactors:" required>

          <Space direction="vertical" style={{ float: "left" }}>
            <Space direction="horizontal">
              <Select
                style={{ float: "left", width: 400 }}
                showSearch
                mode="multiple"
                allowClear
                value={selectedReactors}
                onChange={onChangeSelectedReactors}
                data-testid="reactors-select"
              >
                {/* create reactors 1 to 24 */}
                {Array.from(Array(24).keys()).map((option: any) => (
                  <Option key={option} value={option + 1} data-testid={option + 1}>
                    {option + 1}
                  </Option>
                ))}

              </Select>

              <Tooltip title="Select the Ambe reactors to import.">
                <InfoCircleOutlined style={{  float: "right", font: "20px", marginTop: "0px", marginLeft: "0px" }} />
              </Tooltip>
            </Space>

            <Button type="link" onClick={() => setSelectedReactors(Array.from(Array(24).keys()).map((option: any) => option + 1))} data-testid="select-all-button">Select all</Button>

          </Space>

          


        </Form.Item>

        <Form.Item label="Name prefix:" required>
          <Space direction="horizontal">
            <Input style={{ float: "left", width: 400 }} onChange={onChangeNamePrefix} value={namePrefix} data-testid="name-prefix-input" />
            <Tooltip title="The name prefix will be added to the name of the imported processes. For example, if the name prefix is 'Ambr_', then the process names will be 'Ambr_01', 'Ambr_02', etc.">
              <InfoCircleOutlined style={{ float: "left", font: "20px", }} />
            </Tooltip>
          </Space>
        </Form.Item>

      </Form>
    </Card>
  )


  // perform the upload muation

  function uploadExcel() {

    if (isProcessNew) {
      importExcel({
        variables: {
          input: {
            file: uploadFile,
            topicId: topic,
            processName: processName,
            processDescription: processDescription,
            performDownsampling: downsample,
            downsamplePercentChange: downsamplePercentChange / 100,
            downsampleMinDataFreq: downsampleMinDataFreq,
            downsampleThreshold: 10,
            timezone: timezone,

          }
        }
      })

    } else {
      // importing into an existing process

      importExcel({
        variables: {
          input: {
            file: uploadFile,
            processId: processId,
            performDownsampling: downsample,
            downsamplePercentChange: downsamplePercentChange / 100,
            downsampleMinDataFreq: downsampleMinDataFreq,
            downsampleThreshold: 10,
            timezone: timezone,

          }
        }
      })
    }

  }

  function uploadExcels() {
    let input = {
      files: excelUploadFiles.map((file: any) => file.originFileObj),
      topicId: topic,
      timezone: timezone,
    }

    importExcels({ variables: { input: input } })
  }

  function uploadMatlab() {
    importMatlab({
      variables: {
        input: {
          file: uploadFile,
          topicId: topic,
          importCalculated: importCalculated,
          timezone: timezone,
          processes: selectedProcessesInMatFile
        }
      }
    })
  }

  function uploadAmbr() {
    importAmbr({
      variables: {
        input: {
          file: uploadFile,
          offlineFile: uploadFile2,
          topicId: topic,
          reactors: selectedReactors,
          namePrefix: namePrefix,
        }
      }
    })
  }

  function upload() {
    if (importMethod == "Excel") {
      //uploadExcel()
      uploadExcels()
    } else if (importMethod == "Matlab") {
      uploadMatlab()
    } else if (importMethod == "Ambr") {
      uploadAmbr()
    }
  }

  function restart() {
    // reset the state variables and go back to the first page
    setImportMethod("")
    setTopic("")
    setTopicName("")
    setProcessId("")
    setProcessName("")
    setProcessDescription("")
    setUploadFile("")
    setExcelUploadFiles([])
    //setDownsample(false)
    //setDownsamplePercentChange(0)
    //setDownsampleMinDataFreq(0)
    setImportExcelInfoData({})
    setImportExcelDuplicateError(false)
    setImportExcelFileFormatError(false)
    //setImportCalculated(false)
    setSelectedProcessesInMatFile([])
    setSelectedReactors([])
    //setNamePrefix("")
    setCurrent(0)

    // reset the number of processes imported and other variables
    setNProcessesImported(0)
    setNOnlineVariablesImported(0)
    setNOfflineVariablesImported(0)
    setNMetadataImported(0)
    setNParametersImported(0)
    setMessages("")
    setNNotesImported(0)
    setImportedProcessIds([])

    

  }

  // create a table, in one column the file name, in the other column the suggested file name

  /*let fileNameInfo = []
  for (let i=0; i<importExcelInfoData?.names?.length; i++) {
    fileNameInfo.push(
      <div key={i}>
        <Space direction="horizontal">
          <div >
            { excelUploadFiles[i]?.name }
          </div>
          <div>
            { importExcelInfoData?.names[i] }
          </div>
        </Space>
      </div>
    )
  }*/

  /* instead of fileNameInfo the way it is, create an object that can be used in a a table */
  let fileNameInfo = []
  for (let i = 0; i < importExcelInfoData?.names?.length; i++) {
    fileNameInfo.push({
      key: i,
      fileName: excelUploadFiles[i]?.name,
      suggestedFileName: importExcelInfoData?.names[i],
      overwrite: importExcelInfoData?.overwrite[i],
    })
  }

  let title = "File"
  if (excelUploadFiles.length > 1) {
    title = "Files"
  }

  const columns = [
    {
      title: title + ' (N=' + excelUploadFiles.length + ')',
      dataIndex: 'fileName',
      key: 'fileName',
      width: '50%'
    },
    {
      title: 'Process name',
      dataIndex: 'suggestedFileName',
      key: 'suggestedFileName',
      width: '50%',
      render: (text: any) => (
        <div>
          {/* if the suggested file name exists more than once, then show it in red */}
          {importExcelInfoData?.names?.filter((name: any) => name == text).length > 1 && <div style={{ color: "red" }} title="Name is not unique">{text}</div>}
          {importExcelInfoData?.names?.filter((name: any) => name == text).length == 1 && <div>{text}</div>}
          {/* if the suggested file name is "", then show file format not supported */}
          {text == "" && <div style={{ color: "red" }}>File format not supported</div>}
        </div>
      )

    },
    {
      title: 'Exists in collection',
      dataIndex: 'overwrite',
      key: 'overwrite',
      width: '50%',
      render: (text: any) => (
        <div>
          {text && <div style={{ color: "red" }} title="A process with the same name exists in the collection and will be overwritten.">Yes</div>}
          {!text && <div>No</div>}
        </div>
      )
    }
  ];

  const duplicateNameError = (
    <Alert
      message="Process names are not unique"
      description="Please change the process names in the Excel files and try again. You can add ProcessName to the Metadata sheet or change the StartTime and Reactor attributes in the Metadata sheet to make the process names unique."
      type="error"
      showIcon
      style={{ marginBottom: 16 }}
    />
  )

  const fileFormatError = (
    <Alert
      message="File format is not supported"
      description="One or more files are not in the correct format i.e. Metadata sheet is missing or the sheet names are not correct. Please check the file format and try again."
      type="error"
      showIcon
      style={{ marginBottom: 16 }}
    />
  )

  const fourthPageExcel = (
    <Card>
      <Form {...formLayout} labelAlign="left" >
        {/*<Form.Item label = "Import method:"> { importMethod } </Form.Item>*/}

        <Form.Item label="Collection:"> {topicName} </Form.Item>
        <Form.Item label="Timezone of data:"> {timezone} </Form.Item>

        {importExcelDuplicateError && !importExcelFileFormatError && duplicateNameError}
        {importExcelFileFormatError && fileFormatError}

        <Table dataSource={fileNameInfo} columns={columns} pagination={false} style={{ marginBottom: 20 }} scroll={{ y: 240 }} />


        {/*<Form.Item label="Downampling:">
          <div>{downsample.toString()}</div>

        {/*
        {isProcessNew == 0 && 
          <Form.Item label = "Existing process:"> {existingPrrocessName} </Form.Item> 
        }
        {isProcessNew == 1 && 
          <div>
            <Form.Item label = "New process:"> { processName } </Form.Item>
            <Form.Item label = "Process description:"> { processDescription } </Form.Item>
              
          </div>
        }
        <Form.Item label="File:">
            <div>{uploadFile?.name} </div>
        </Form.Item>
        */}



        {/*
        <Form.Item label="Downampling:">
          <div>{downsample.toString()}</div>
        </Form.Item>

        { downsample &&
          <div>
            <Form.Item label="Percent change [%]:">
              <div>{downsamplePercentChange.toString()}</div>
            </Form.Item>

            <Form.Item label="Min. data frequency [data points / h]:">
              <div>{downsampleMinDataFreq.toString()}</div>
            </Form.Item>
        </div>
        }
        */}

      </Form>
    </Card>
  );

  const fourthPageMatlab = (
    <Card>
      <Form {...formLayout} labelAlign="left" >
        <Form.Item label="Import method:"> {importMethod} </Form.Item>
        <Form.Item label="Collection:"> {topicName} </Form.Item>
        {/*<Alert message="Existing processes in the topic will be overwritten." type="info" showIcon style={{ marginBottom: 16 }} />*/}
        <Form.Item label="File:"> {uploadFile?.name} </Form.Item>
        <Form.Item label="Processes:"> {selectedProcessesInMatFile.join(", ")} </Form.Item>
        <Form.Item label="Timezone:"> {timezone} </Form.Item>
        <Form.Item label="Import calculated variables:"> {importCalculated.toString()} </Form.Item>
      </Form>
    </Card>
  )

  const fourthPageAmbr = (
    <Card>
      <Form {...formLayout} labelAlign="left" >
        <Form.Item label="Import method:"> {importMethod} </Form.Item>
        <Form.Item label="Collection:"> {topicName} </Form.Item>
        <Form.Item label="Ambr data file:"> {uploadFile?.name} </Form.Item>
        <Form.Item label="Offline data file:"> {uploadFile2?.name} </Form.Item>
        <Form.Item label="Reactors:"> {selectedReactors.join(", ")} </Form.Item>
        <Form.Item label="Name prefix:"> {namePrefix} </Form.Item>

      </Form>
    </Card>
  )

  // split the string in messages using \n
  let messages_split = messages.split("\n");

  // remove empty strings
  messages_split = messages_split.filter(function (el) {
    return el != "";
  });

  const n_warnings = messages_split.length;

  const fifthPageExcel = (
    <div>

      <Card style={{ height: height - 300, overflow: "scroll" }}>


        {queryData?.backgroundTask?.status === "RUNNING" &&
          <Result
            status="info"
            title={queryData?.backgroundTask?.statusMessage}
          />
        }

        {n_warnings == 0 && queryData?.backgroundTask?.status === "COMPLETED" &&
          <Result
            status="success"
            title="Data was imported successfully."
          />
        }

        {nMetadataImported + nOfflineVariablesImported + nOnlineVariablesImported + nParametersImported > 0 && n_warnings > 0 && queryData?.backgroundTask?.status === "COMPLETED" &&
          <Result
            status="success"
            title="Data was imported with some warnings."
          />
        }

        {nMetadataImported + nOfflineVariablesImported + nOnlineVariablesImported + nParametersImported == 0 && n_warnings > 0 && queryData?.backgroundTask?.status === "COMPLETED" &&
          <Result
            status="error"
            title="Data was not imported."
          />
        }

        <Progress percent={queryData?.backgroundTask?.progress} status={queryData?.backgroundTask?.status === "FAILED" ? "exception" : queryData?.backgroundTask?.status === "COMPLETED" ? "success" : "active"} />

        { queryData?.backgroundTask?.status === "COMPLETED" &&

          <Card title="Add imported processes to an analysis:" style={{ marginBottom: 20 }}>
            <AddProcessestoAnalysis processIds={importedProcessIds} teamId={userProfileData?.userProfile.selectedTeam.id} />
          </Card>
        }

        <Card style={{ marginBottom: 20 }} title="Import statistics:">

          <Space direction="horizontal">

            <Card style={{ width: 200, float: "left", marginRight: 10 }}>
              <Statistic
                title="Processes"
                value={nProcessesImported}
                precision={0}
                prefix={<LineChartOutlined />}
                suffix=""
              />
            </Card>

            <Card style={{ width: 200, float: "left", marginRight: 10 }}>
              <Statistic
                title="Online variables"
                value={nOnlineVariablesImported}
                precision={0}
                prefix={<LineChartOutlined />}
                suffix=""
              />
            </Card>

            <Card style={{ width: 200, float: "left", marginRight: 10 }}>
              <Statistic
                title="Offline variables"
                value={nOfflineVariablesImported}
                precision={0}
                prefix={<LineChartOutlined />}
                suffix=""
              />
            </Card>

            <Card style={{ width: 200, float: "left", marginRight: 10 }}>
              <Statistic
                title="Metadata"
                value={nMetadataImported}
                precision={0}
                prefix={<LineChartOutlined />}
                suffix=""
              />
            </Card>

            <Card style={{ width: 200, float: "left", marginRight: 10 }}>
              <Statistic
                title="Parameters"
                value={nParametersImported}
                precision={0}
                prefix={<LineChartOutlined />}
                suffix=""
              />
            </Card>

            <Card style={{ width: 200, float: "left", marginRight: 10 }}>
              <Statistic
                title="Notes"
                value={nNotesImported}
                precision={0}
                prefix={<LineChartOutlined />}
                suffix=""
              />
            </Card>

          </Space>
        </Card>

        <Card title="Warnings">
          {messages_split.map((item, i) => (
            <div key={i}>
              <Alert
                style={{ marginBottom: 10 }}
                message={item}
                type="warning"
                showIcon
              />
            </div>
          ))}
        </Card>


      </Card>

    </div>
  );

  const fifthPageMatlab = (
    <div>
      <Card style={{ height: height - 300, overflow: "scroll" }}>
        <Result
          title="Data import started..."
          subTitle="Data import will run in the background. Check the background tasks sidebar to see the progress."
        />
      </Card>
    </div>
  )

  const fifthPageAmbr = (
    <div>
      <Card style={{ height: height - 300, overflow: "scroll" }}>
        <Result
          title="Data import started..."
          subTitle="Data import will run in the background. Check the background tasks sidebar to see the progress."
        />
      </Card>
    </div>
  )

  let secondPage: any = secondPageExcel;
  if (importMethod == "Matlab") {
    secondPage = secondPageMatlab;
  } else if (importMethod == "Ambr") {
    secondPage = secondPageAmbr;
  }

  let thirdPage = thirdPageExcel;
  if (importMethod == "Matlab") {
    thirdPage = thirdPageMatlab;
  } else if (importMethod == "Ambr") {
    thirdPage = thirdPageAmbr;
  }

  let fourthPage = fourthPageExcel;
  if (importMethod == "Matlab") {
    fourthPage = fourthPageMatlab;
  } else if (importMethod == "Ambr") {
    fourthPage = fourthPageAmbr;
  }

  let fifthPage: any = fifthPageExcel;
  if (importMethod == "Matlab") {
    fifthPage = fifthPageMatlab;
  } else if (importMethod == "Ambr") {
    fifthPage = fifthPageAmbr;
  }


  const steps = [
    {
      title: 'Import method',
      content: firstPage,
    },
    {
      title: 'Collection',
      content: secondPage,
    },
    {
      title: 'Data file and options',
      content: thirdPage,
    },
    {
      title: 'Review',
      content: fourthPage,
    },
    {
      title: 'Done',
      content: fifthPage,
    },
  ];

  const routes = [
    {
      path: '',
      breadcrumbName: "Team: " + userProfileData?.userProfile.selectedTeam.name,
    }
  ];

  const tabList = [
    {
      key: 'import-tab',
      tab: 'Import',
    },
    {
      key: 'import-history-tab',
      tab: 'Import history',
    },
  ];

  const contentList: { [key: string]: any } = {
    
    'import-tab': (
      <div>
        <Steps current={current}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <br />

      {(loading || loadingExcelInfo) &&
        <div style={{ minHeight: 300 }}><Skeleton active paragraph={{ rows: 7 }} /></div>
      }

      {!loading &&
        <div style={{ minHeight: 300 }}>{steps[current].content}</div>
      }

      <br />


      <div className="steps-action">

        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()} data-testid="previous-button">
            Previous
          </Button>
        )}

        {current < 3 && current != 0 && (
          <Button type="primary" onClick={() => next()} data-testid="next-button">
            Next
          </Button>
        )}
        {current === 3 && !importExcelDuplicateError && !importExcelFileFormatError && !loading && (

          <Button type="primary" onClick={upload} data-testid="submit-button">
            Submit
          </Button>
        )}

        {current === 4 && (
          <Button type="primary" onClick={restart} data-testid="restart-button">
            Restart
          </Button>
        )}

      </div>
      </div>
    ),

    'import-history-tab': (<ImportHistoryTable teamId={userProfileData?.userProfile.selectedTeam.id} />),
  
  };

  return (

    <div>
      <PageHeader
        title="Import"
        extra={<HeaderExtra />}
        breadcrumb={{ routes }}
      />

      <Card 
        style={{ minHeight: height - 130 }}
        tabList={tabList}
        activeTabKey={tabKey}
        onTabChange={key => setTabKey(key)}

      >
        {contentList[tabKey]}

      </Card>

    </div>
  )
}

export default Import;

