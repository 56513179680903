//import React from "react";
import { Input , Card, Form, Button, message} from 'antd';
import PageHeader from '../components/PageHeader';
import HeaderExtra from "../components/HeaderExtra";
import { useMutation, gql } from '@apollo/client';
import ReactGA from 'react-ga';

const RESET_PASSWORD = gql`
  mutation resetPassword ($input:ResetPasswordInputType!) {  
    resetPassword(input:$input) {
        ok
    }
  }
`;

function Profile() {

  //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });
  //ReactGA.pageview('/profile');

  const [resetPassword, { loading: Loading, error: Error } ] = useMutation(RESET_PASSWORD, {
    //refetchQueries: [ { query: READ } ], 
    onCompleted(data) { 
        message.success( "Password was changed successfully.")
        ReactGA.event({ category: 'Profile', action: 'Updated password.' })
    } ,
    onError(error) {message.error(error.message)},
});

  // process info tab
  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 6},
    
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 6 },
  };

  const onFinish = (values: any) => {
    
    if (values.new_password_1 != values.new_password_2) {
      message.error('Mismatch between new password and its repeat.')
      return
    }

    if (values.new_password_1.length < 8 ) {
      message.error('Password must be at least 8 characters.')
      return
    }
    
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if(!values.new_password_1.match(passw)) {
      message.error('Password must contain at least one numeric digit, one uppercase and one lowercase letter.')
      return
    }
    
    resetPassword({ variables: { input: {'oldPassword': values.old_password ,'newPassword': values.new_password_1 }  } } )

};

    return(  
      <div>

        <PageHeader
          title="Profile"
          extra={<HeaderExtra/>}
        />

        <Card title="Change password">

          <Form 
            {...formLayout} 
            onFinish={onFinish}
          >

            <Form.Item label="Old password:" name="old_password" rules={[{ required: true, message: 'Please enter your old password.' }]}   >
              <Input.Password placeholder="" data-testid="old-password-input"/>
            </Form.Item>

            <Form.Item label="New password:" name="new_password_1" rules={[{ required: true, message: 'Please enter your new password.' }]} >
              <Input.Password placeholder="" data-testid="new-password-input"/>
            </Form.Item>

            <Form.Item label="Repeat new password:" name="new_password_2" rules={[{ required: true, message: 'Please repeat your new password.' }]}  >
              <Input.Password placeholder="" data-testid="repeat-new-password-input"/>
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" data-testid="submit-button">Update</Button>
            </Form.Item>

         </Form>

        </Card>

      </div>
    )
  }
  
  export default Profile;

