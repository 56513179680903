import { useState } from 'react';
import { Layout, Menu, Button , Card, Modal, Typography} from 'antd';
import logo_dark from '../images/bpi_logo_dark.png'
import Login from '../components/Login_new';
import useWindowDimensions from '../components/useWindowDimensions';
import { ConfigProvider, theme } from "antd";
const { defaultAlgorithm, darkAlgorithm } = theme;
const { Text } = Typography;
const { Title } = Typography;

const { Header, Content, Footer } = Layout;


function Landing() {

    const { height, width } = useWindowDimensions();
    const [pageNumber, setPageNumber] = useState(0)
    const [bookAppointmentVisible, setBookAppointmentVisible] = useState(false)

    const pageContent = [
        (
            <div>
                {/* Section 1: Software Introduction */}
                <section style={{marginTop: 20, width: "50%", marginLeft:"25%", marginBottom:"30px"}}>
                    <Title level={1}>Bioprocess Intelligence</Title>
                    <Text style={{fontSize: 16}}>
                    Bioprocess Intelligence by Wisdom Engine is a comprehensive software solution designed to optimize bioprocesses through data integration, advanced bioprocess-specific calculations, and AI-driven analysis. It helps teams streamline workflows, track KPIs, and make informed decisions to improve process performance. Bioprocess Intelligence can be effectively used in both R&D and manufacturing environments to enhance efficiency, reduce costs, and shorten time-to-market.
                    <p>Please watch the video below for an overview of the software:</p>
                    </Text>
                    
                </section>

                {/* Section 2: Vide */}
                <section style={{marginTop: 20, width: "50%", marginLeft:"25%"}}>
                  
                <div
                    style={{position: "relative", overflow: "hidden", aspectRatio: "16 / 9", width: "70%", marginLeft: "15%"}} 
                >
                        <iframe src="https://share.synthesia.io/embeds/videos/0d357a18-0371-4b30-9174-f0b6319e4b42"
                            loading="lazy" 
                            title="Overview of the Software" 
                            //allowfullscreen 
                            allow="encrypted-media; fullscreen;" 
                            //style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"
                            style = {{position: "absolute", width: "100%", height:"100%", top: 0, left: 0, border: "none", padding: 0, margin: 0, overflow:"hidden"}}
                        >
                    </iframe>        
                </div>
                

                </section>

                {/* Section 3: Link to book an appointment */}
                <section style={{marginTop: 50, width: "50%", marginLeft:"25%" }}>
                    <Title level={3}>Interested in learning more?</Title>
                    <Text style={{fontSize:16}}>Book an appointment with us to learn more about Bioprocess Intelligence and discuss how it can be tailored to meet your specific needs.</Text>
                    
                    <p><Button type="primary" onClick={() => setBookAppointmentVisible(true)}>Book an appointment</Button></p>
                </section>
            </div>
        ),

        ( 
            // center the div
            <div style = {{marginLeft: "30%", marginTop: "10%", marginRight: "30%"}}>
                <Card title="Login"><Login /></Card>
                
            </div>

        )
    ]

    return(
        <ConfigProvider theme={{ algorithm : defaultAlgorithm }}>
        <Layout style={{}}>
            <Header style={{ height: '65px'}}>
                <img alt="" src={logo_dark} style={{height:65,marginRight:40, float:'left'}}/>
                
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['0']} >
                    
                    <Menu.Item key="0" onClick={() => setPageNumber(0)}>Home</Menu.Item>
                    <Menu.Item key="1" onClick={() => setPageNumber(1)}>Login</Menu.Item>
                    
                </Menu>

            </Header>

            <Content style={{ padding:20}}>

                {pageContent[pageNumber]}

                <Modal 
                    width={width-500}
                    footer  = {null}
                    open={bookAppointmentVisible} onCancel={() => setBookAppointmentVisible(false)} >
                

                    <div>
                        <iframe 
                            src='https://outlook.office365.com/owa/calendar/BioprocessIntelligence@wisdomengine.de/bookings/'
                            style={{height: height-400, width: width-550, border:0 }}
                        />
                        
                    </div>

                </Modal>

            </Content>

            <Footer style={{ textAlign: 'center' , backgroundColor: '#f0f2f5'}}>
                <p>Copyright © 2024 WisdomEngine GmbH – All rights reserved.</p>
                <p><a href="https://wisdomengine.de/impressum" target="_blank">Impressum</a> & <a href="https://wisdomengine.de/privacy-policy" target="_blank">Privacy Policy</a>
                </p>
            </Footer>
        </Layout>
        </ConfigProvider>
    )
}

export default Landing