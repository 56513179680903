import {useState, useEffect} from 'react';
import { Select, InputNumber, Button, Space, AutoComplete, Modal, Form, Input, message, Skeleton} from 'antd';
import { useReactiveVar, useMutation, gql } from '@apollo/client';
import { CirclePicker } from 'react-color';
import { CORE_ANNOTATION_FIELDS, CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS } from '../fragments';
import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';
import { createOptimisticResponse } from '../createOptimisticResponse';
import { processExplorerSelectedViewPageVar } from '../../Apollo';

const { Option } = Select;

const GET_USER_PROFILE = gql`
    ${CORE_ANNOTATION_FIELDS}
    query getUserProfile {
        userProfile {
            id
            selectedAnalysis {
                id
                processExplorerView {
                    processexplorerannotationSet {
                        ...CoreAnnotationFields
                    }
                }
            }
        }
    }
`;

const UPDATE_VIEW_PAGE = gql`
    ${CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS }
    mutation updateViewPage ($input: UpdateViewPageInputType!) {
        updateViewPage( input:$input) {
            viewPage {
                ...CoreProcessExplorerViewPageFields
            }
        }
    }
`;

const CREATE_PROCESS_EXPLORER_ANNOTATION = gql`
    ${CORE_ANNOTATION_FIELDS}
    mutation createProcessExplorerAnnotation ($input: CreateProcessExplorerAnnotationInputType!) {
        createProcessExplorerAnnotation (input:$input) {
            annotation {
                ...CoreAnnotationFields
            }
        }
    }
`;

const DELETE_PROCESS_EXPLORER_ANNOTATIONS = gql`
    mutation deleteProcessExplorerAnnotations ($input: DeleteProcessExplorerAnnotationsInputType!) {
        deleteProcessExplorerAnnotations (input:$input) {
            ok
        }
    }
`;

interface ProcessExplorerXaxisInputType {
    analysis:any
    uniqueVars:any
    processes:any
    selectedProcesses:any
}

function ProcessExplorerXaxis(props:ProcessExplorerXaxisInputType) {

    // ///////////////////////// STATES /////////////////////////

    const [availableXUnits, setAvailableXUnits] = useState<any>([])
    const [xUnitsAutoComplete, setXUnitsAutoComplete] = useState("")
    const [previousXAxis, setPreviousXAxis] = useState<any>("")
    const [createAnnotationModalVisible, setCreateAnnotationModalVisible] = useState(false)
    const [annotationColor, setAnnotationColor] = useState("#F44336")
    const processExplorerSelectedViewPage = useReactiveVar(processExplorerSelectedViewPageVar);
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

    //const processExplorerView = useReactiveVar(processExplorerViewVar)

    // ///////////////////////// CONSTANTS /////////////////////////
    const selectedViewPage = props.analysis.processExplorerView.viewpageSet.find((viewpage:any) => viewpage.id === processExplorerSelectedViewPage)

    const xAxisName = selectedViewPage?.xaxis
    const viewPage = selectedViewPage
    //const xAxisName = processExplorerView?.selectedViewPage?.xaxis
    //const viewPage:any = processExplorerView?.selectedViewPage
    const xAxisUnits = viewPage?.xaxisUnits // note the difference in case between xaxis and xAxis

    

    // ///////////////////////// MUTATIONS /////////////////////////

     const [ updateViewPage ] = useMutation(UPDATE_VIEW_PAGE, {
        refetchQueries: [ { query: GET_USER_PROFILE} ], 
        onError(error) {
            //console.log(error)
        },
    });

    const [ createProcessExplorerAnnotation ] = useMutation(CREATE_PROCESS_EXPLORER_ANNOTATION, {
        refetchQueries: [ { query: GET_USER_PROFILE} ],
        onCompleted(data) {message.success("Annotation created")},
        onError(error) {message.error(error.message)},
    });

    const [ deleteProcessExplorerAnnotations ] = useMutation(DELETE_PROCESS_EXPLORER_ANNOTATIONS, {
        refetchQueries: [ { query: GET_USER_PROFILE} ],
        onCompleted(data) {message.success("Annotations deleted")},
        onError(error) {message.error(error.message)},
    });

    // ///////////////////////// FUNCTIONS /////////////////////////

    function onXaxisChange(newXAxisName:string) {

        if (viewPage == null) {
            return
        }

        // remembering the previous x-axis so that we know when to reset the units to the first available unit
        setPreviousXAxis(xAxisName)
        
        
        // create a variable for mutation input
        let input = {
            id:viewPage.id,
            xaxis:newXAxisName,
            xaxisMin: -9999,
            xaxisMax: -9999,
        }

        const optimisticResponse = createOptimisticResponse(
            client,
            CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
            viewPage.id,
            {xaxis: newXAxisName, xaxisMin: -9999, xaxisMax: -9999},
            "CoreProcessExplorerViewPageFields"
        )

        // perform the mutation
        if (props.analysis.userPermissions != "read") {
            updateViewPage( {
                variables: { input: input  } ,
                optimisticResponse: {
                    updateViewPage: {
                        __typename: 'Mutation',
                        viewPage: {
                            __typename: 'ViewPageType',
                            ...optimisticResponse
                        }
                    }
                }
            } );
        }
    }

    function onChangeXaxisMin(xaxisMin:number | null) {

        if (xaxisMin === null) {
            return
        }

        // create a variable for mutation input
        let input = {
            id:viewPage.id ,
            xaxisMin : xaxisMin,
        }

        const optimisticResponse = createOptimisticResponse(
            client,
            CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
            viewPage.id,
            {xaxisMin: xaxisMin},
            "CoreProcessExplorerViewPageFields"
        )
        if (props.analysis.userPermissions != "read") {
            updateViewPage( {
                variables: { input: input  } ,
                optimisticResponse: {
                    updateViewPage: {
                        __typename: 'Mutation',
                        viewPage: {
                            __typename: 'ViewPageType',
                            ...optimisticResponse
                        }
                    }
                }
            } );
        }
    }

    function onChangeXaxisMax(xaxisMax:number | null) {

        if (xaxisMax === null) {
            return
        }

        // create a variable for mutation input
        let input = {
            id:viewPage.id ,
            xaxisMax:xaxisMax,
        }

        const optimisticResponse = createOptimisticResponse(
            client,
            CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
            viewPage.id,
            {xaxisMax: xaxisMax},
            "CoreProcessExplorerViewPageFields"
        )
        if (props.analysis.userPermissions != "read") {
            updateViewPage( {
                variables: { input: input  } ,
                optimisticResponse: {
                    updateViewPage: {
                        __typename: 'Mutation',
                        viewPage: {
                            __typename: 'ViewPageType',
                            ...optimisticResponse
                        }
                    }
                }
            } );
        }
    }

    function onClickClear() {

        // create a variable for mutation input
        let input = {
            id:viewPage.id ,
            xaxisMin:-9999,
            xaxisMax:-9999,
        }

        const optimisticResponse = createOptimisticResponse(
            client,
            CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
            viewPage.id,
            {xaxisMin: -9999, xaxisMax: -9999},
            "CoreProcessExplorerViewPageFields"
        )
        if (props.analysis.userPermissions != "read") {
            updateViewPage( {
                variables: { input: input  } ,
                optimisticResponse: {
                    updateViewPage: {
                        __typename: 'Mutation',
                        viewPage: {
                            __typename: 'ViewPageType',
                            ...optimisticResponse
                        }
                    }
                }
            } );
        }
      

    }

    function onChangeXUnits(value: any) {
        setXUnitsAutoComplete(value)
    }

    function onSubmitChangeXUnits() {

        // if for ProcessTime anything other than hours, minutes, or days is selected, display an error message and reset the units to hours
        if (xAxisName == "ProcessTime" && 
            (   xUnitsAutoComplete != "hours" && 
                xUnitsAutoComplete != "minutes" && 
                xUnitsAutoComplete != "days" && 
                xUnitsAutoComplete != "weeks" &&
                xUnitsAutoComplete != "seconds"&& 
                xUnitsAutoComplete != "years" )) {

            message.error("Invalid units for ProcessTime. Allowed units are seconds, minutes, hours, days, weeks, and years.")
            setXUnitsAutoComplete("hours")
            return
        }

        // remembering the previous x-axis so that we know when to reset the units to the first available unit
        setPreviousXAxis(xAxisName)

        // create a variable for mutation input
        let input = {
            id:viewPage.id ,
            xaxisUnits: xUnitsAutoComplete,
        }

        const optimisticResponse = createOptimisticResponse(
            client,
            CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
            viewPage.id,
            {xaxisUnits: xUnitsAutoComplete},
            "CoreProcessExplorerViewPageFields"
        )

        // perform the mutation
        if (props.analysis.userPermissions != "read") {
            updateViewPage( {
                variables: { input: input  } ,
                optimisticResponse: {
                    updateViewPage: {
                        __typename: 'Mutation',
                        viewPage: {
                            __typename: 'ViewPageType',
                            ...optimisticResponse
                        }
                    }
                }
            } );
        }
    }

    function handleDeleteAllAnnotations() {
            
            // create a variable for mutation input
            let input = {
                processExplorerViewId: props.analysis.processExplorerView.id,
                xAxis: xAxisName,
            }
    
            // perform the mutation
            deleteProcessExplorerAnnotations( { variables: { input: input  } } );
        }

    // ///////////////////////// USE EFFECTS /////////////////////////

     // use Effect to set the x-axis units to the first available unit
    useEffect(() => {

        //console.log('previousXAxis', previousXAxis)     // previous x-axis
        //console.log('xAxisName', xAxisName)             // current x-axis
        //console.log('xAxisUnits', xAxisUnits)           // current x-axis units
        
        // 1) Changing the x-axis e.g. from Mass to NSt
        //    - this will reset the units to the first available unit
        //    - the previous x-axis and current x-axis will be different

        // 2) Changing the units of the x-axis e.g. from kg to g
        //   - this should not reset the units to the first available unit
        //   - the previous x-axis and current x-axis will be the same

        // 3) Navigating from another page to the process explorer page
        //   - this should not reset the units to the first available unit
        //   - the previous x-axis will be empty

        // case 1: changing the x-axis
        if (previousXAxis != xAxisName && previousXAxis != "") {
            //console.log('Case 1: changing the x-axis')
            setXUnitsAutoComplete(availableXUnits[0])
            setFirstAvailableXUnits()

        // case 2: changing the units of the x-axis
        } else if (previousXAxis != xAxisName ) {
            //console.log('Case 2: changing the units of the x-axis')
            setXUnitsAutoComplete(xAxisUnits)
        
        // case 3: navigating from another page to the process explorer page
        } else if (previousXAxis == "") {
            //console.log('Case 3: navigating from another page to the process explorer page')
            if (xAxisUnits == "" || xAxisUnits == null || xAxisUnits == undefined) {
                setXUnitsAutoComplete(availableXUnits[0])
                setFirstAvailableXUnits()
            } else {
                setXUnitsAutoComplete(xAxisUnits)
            }

        }

    }, [availableXUnits, xAxisName, xAxisUnits, previousXAxis])

    
    function setFirstAvailableXUnits() {
        if (availableXUnits.length > 0 && viewPage != null) {
            setXUnitsAutoComplete(availableXUnits[0])

            // create a variable for mutation input
            let input = {
                id:viewPage.id ,
                xaxisUnits: availableXUnits[0],
            }

            const optimisticResponse = createOptimisticResponse(
                client,
                CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
                viewPage.id,
                {xaxisUnits: availableXUnits[0]},
                "CoreProcessExplorerViewPageFields"
            )
            
            if (props.analysis.userPermissions != "read") {
                updateViewPage( {
                    variables: { input: input  } ,
                    optimisticResponse: {
                        updateViewPage: {
                            __typename: 'Mutation',
                            viewPage: {
                                __typename: 'ViewPageType',
                                ...optimisticResponse
                            }
                        }
                    }
                } );
            }
        }
    }

    // useEffect hook that runs when props.selectedProcesses, xAxisName, or xAxisUnits change.
    // The main objective of this hook is to update the available units for the x-axis based on 
    // the provided processes and the current x-axis name.
    useEffect(() => {

        // Initialize variables to store interim data for querying and result accumulation.
        let key: any = ''
        let process: any = {}
        let variable_data_query_ids: any = []
        let variable_data_query_units: any = []
        let availableXUnitsLocal: any = []

        // Check if no processes are selected, then set a default behavior.
        if (props.selectedProcesses.length === 0) {
            availableXUnitsLocal.push("hours");
            availableXUnitsLocal.push("minutes");
            availableXUnitsLocal.push("days");
        }

        // Iterate over the processes provided in the props.
        for ([key, process] of Object.entries(props.processes)) {

            // Check if the current process ID is one of the selected processes.
            if (props?.selectedProcesses.find((x: any) => x.id == process.id)) {

                // Handle the case when x-axis represents 'ProcessTime'.
                if (xAxisName == "ProcessTime") {
                    
                    // If the local unit list doesn't include 'hours' and 'days', add them.
                    if (!availableXUnitsLocal.includes("minutes") && !availableXUnitsLocal.includes("hours") && !availableXUnitsLocal.includes("days")) {
                        
                        availableXUnitsLocal.push("hours")
                        availableXUnitsLocal.push("minutes")
                        availableXUnitsLocal.push("days")
                    }

                // Handle cases where x-axis does not represent 'Date' or 'ProcessTime'.
                } else if (xAxisName != "Date" && xAxisName != "ProcessTime") {

                    // Search for a variable within the current process that matches the desired x-axis name.
                    const x_var = process.variableSet.find((v: any) => v.name == xAxisName)
                    let xData = x_var?.data[0]

                    // If the variable or its data is not found, move to the next iteration.
                    if (xData == null) {
                        continue
                    }

                    // If the variable has defined units and they aren't in the availableXUnitsLocal list, add them.
                    if (x_var?.units != null) {
                        if (!availableXUnitsLocal.includes(x_var?.units)) {
                            availableXUnitsLocal.push(x_var?.units)
                        }
                    }

                    // If the selected x-axis units differ from the variable's units, gather data for a potential unit conversion request.
                    if (xAxisUnits != x_var?.units) {
                        variable_data_query_ids.push(x_var?.id)
                        variable_data_query_units.push(xAxisUnits)
                    }
                }
            }
        }

        // Update the main state with the local list of available units.
        setAvailableXUnits(availableXUnitsLocal)

        // console.log(' > executed use effect to set available x-units to ', availableXUnitsLocal)

    }, [props.selectedProcesses, xAxisName, xAxisUnits, props.processes])

    // use effect to select ProcessTime as the x-axis if nothing is selected and ProcessTime is available in props?.uniqueVars?
    useEffect(() => {
        // if the x-axis is not selected and ProcessTime is available in props?.uniqueVars?
        //console.log('props?.uniqueVars', props?.uniqueVars)
        //console.log('xAxisName', xAxisName)

        if (xAxisName == null) {
            //console.log(' > executed use effect to set x-axis to ProcessTime')
            onXaxisChange("ProcessTime")
        }
    }, [props.uniqueVars])

    const formLayout = {
        labelCol: { span: 5 },
    };

    function handleSubmitCreateAnnotation(values:any) {

        // create a variable for mutation input
        let input = {
            processExplorerViewId: props.analysis.processExplorerView.id,
            xAxis: xAxisName,
            xValue: values["x-axis-value"],
            xUnits: xAxisUnits,
            color: annotationColor,
            label: values["label"],
        }
        //console.log('input', input)

        // perform the mutation
        createProcessExplorerAnnotation( { variables: { input: input  } } );

        // close the modal
        setCreateAnnotationModalVisible(false)
    }

    if (viewPage == null) {
        return (<div><Skeleton active/></div>)
    }

    return (

        <div style={{ width: '100%' }} >

            <Space direction="vertical">

                <Select 
                    showSearch
                    defaultValue="ProcessTime"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    onChange={onXaxisChange} 
                    value={viewPage?.xaxis}
                    //value={ processExplorerView?.selectedViewPage?.xaxis }
                    data-testid = "x-axis-selection"
                >
                    
                    <Option key="ProcessTime" value="ProcessTime">ProcessTime</Option>
                    <Option key="Date" value="Date">Date</Option>

                    {props?.uniqueVars?.map( (uniqueVar:any) =>{ return ( <Option key={uniqueVar} value={uniqueVar}>{uniqueVar}</Option>) } )}

                </Select>

                {/* if the length of the available x-units is greater than 1, show the selection */}
        
                
                
                <Space direction="horizontal">

                    <AutoComplete
                        value={xUnitsAutoComplete}
                        defaultValue={xUnitsAutoComplete}
                        onChange={onChangeXUnits}
                        placeholder="Units"
                        data-testid="xaxis-units-selection"
                        size="small"
                        style={{ width: 130 }}
                        // disable it if Date is selected as the x-axis
                        disabled={xAxisName == "Date"}
                    >
                    {/* loop over available y-units and create an option for each */}
                        {availableXUnits.map((unit: any) => { return (<Option key={unit} value={unit}>{unit}</Option>) })}
                    </AutoComplete>

                    <Button
                        type="default"
                        size="small"
                        onClick={onSubmitChangeXUnits}
                        disabled={xAxisName == "Date"}
                        data-testid="xaxis-units-set-button"
                    >
                    Set
                    </Button>

                </Space>
                

                <Space>
                    <InputNumber size="small" onChange={onChangeXaxisMin} value={ viewPage?.xaxisMin } defaultValue={viewPage?.xaxisMin} disabled={xAxisName == "Date"} data-testid="x-axis-min-input" />
                    <div>X-min</div>
                </Space>
                <Space>
                    <InputNumber size="small" onChange= {onChangeXaxisMax} value={viewPage?.xaxisMax} defaultValue={viewPage?.xaxisMax} disabled={xAxisName == "Date"} data-testid="x-axis-max-input" />
                    <div>X-max</div>
                </Space>

                <Button size="small" type="link" onClick={onClickClear} data-testid="x-axis-clear-button">Clear X-range</Button>

                <Button type="link" size="small" onClick={() => setCreateAnnotationModalVisible(true)} data-testid="add-annotation-button">
                    Add line annotation
                </Button>

                <Button type="link" size="small" onClick={handleDeleteAllAnnotations} data-testid="delete-all-annotations-button">
                    Delete all annotations
                </Button>

            </Space>

            <Modal 
                title="Add a line annotation" 
                open={createAnnotationModalVisible} 
                onOk={handleSubmitCreateAnnotation}
                onCancel={() => setCreateAnnotationModalVisible(false)}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={() => setCreateAnnotationModalVisible(false)} data-testid="cancel-annotation-button">
                        Cancel
                    </Button>,
                    <Button type="primary" form="AnnotationForm" key="submit" htmlType="submit" data-testid="submit-annotation-button">
                        Submit
                    </Button>
                    ]}
            >

                <br />
                <Form 
                    {...formLayout}  
                    id = "AnnotationForm"
                    onFinish={handleSubmitCreateAnnotation}
                >
                    <Form.Item name="x-axis" label="X-axis">
                        {xAxisName + " [" + xAxisUnits + "]"}
                    </Form.Item>

                    <Form.Item name="x-axis-value" label="X-axis value">
                        <InputNumber data-testid="x-axis-value-input" />
                    </Form.Item>

                    <Form.Item name="label" label="Label">
                        <Input.TextArea maxLength={100} showCount rows={3} data-testid="annotation-label-input" />
                    </Form.Item>

                    <Form.Item name="color" label="Color" style={{marginTop:40}}>
                        <CirclePicker 
                            color={annotationColor} 
                            onChangeComplete={(color) => setAnnotationColor(color.hex)} 
                            width="100%"
                            circleSize={ 35 }
                            data-testid="color-picker"
                            colors = {[
                                "#F44336", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5",
                                "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50",
                                "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800",
                                "#FF5722", "#795548", "#9E9E9E", "#607D8B", "#000000", "#FFFFFF"
                            ]}
                        />
                    </Form.Item>

                </Form>
                <br />
            </Modal>
            
        </div>
    );

}

export default ProcessExplorerXaxis;